import React, { useEffect } from 'react';
import socket from '../../services/socket';
import { sessionGet } from '../../session';

function SocketPostsFeed({ setUpdateFeedButton }) {
    useEffect(() => {
        socket.on('manage_post', (idAutor) => {
            if (sessionGet('idUser') === idAutor) {
                setUpdateFeedButton(false);
            } else {
                setUpdateFeedButton(true);
            }
        });

        return () => {
            socket.off('manage_post');
        }
    }, []);

    return <></>;
}

export default SocketPostsFeed;
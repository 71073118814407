import styled from 'styled-components';

import { Link } from 'react-router-dom';
import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Wrap = styled.nav`
  width: 110px;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: ${colors.bgprimary};
  box-shadow: ${effects.boxshadow};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2000;
  /* display: none; */

  @media (max-width: 1024px) {
    justify-content: unset;
  }

  span {
    text-align: center;
    font-weight: 400;
    font-size: 12px;
  }

  .wrap-icone {
    width: 100%;
    background-color: ${colors.bgprimary};
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media (max-width: 1440px) {
      height: 70px;
    }

    img { 
      width: 75px; 
      height: 75px;

      @media (max-width: 1440px) {
        width: 50px;
        height: 50px;
      } 
    }
  }

  .wrap-buttons {
    width: 100%;
    border-top: 1px solid ${colors.border};

    &::-webkit-scrollbar {
      width: 2px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: ${colors.border};
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: white;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #4f5e85;
    }
  }

  @media (max-width: 1440px) {
    width: 110px;
  
    @media (max-width: 1024px) {
      height: 100%;
      left: 0;
      top: 70px;
      width: 100%;
      z-index: 3000;
      overflow-y: scroll;
      //height: calc(100vh - 70px);
    }
  } 
`;

export const Button = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90px;
  border-bottom: 1px solid ${colors.border};
  text-decoration: none;
  color: ${colors.texton};
  font-size: 14px;
  position: relative;

  @media (max-height: 675px) and (max-width: 400px) {
    flex-direction: row;
    height: 20px;
    place-content: start;
  }

  .notification-menu {
    top: 30px;
    left: 60px;
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #fc3503;
    border-radius: 50%;
    display: none;

    &.atendimento {
      top: 27px;

      @media (max-width: 1024px) {
        left: calc(50% + 5px);
      }

      @media (max-width: 1600px) {
        top: 22px;
      }
    }

    @media (max-width: 1024px) {
      left: calc(50% + 5px);
    }

    @media (min-width: 1600px) {
      top: 37px;
    }
  }

  .notification-menu-mais {
    top: 50px;
    left: 60px;
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #fc3503;
    border-radius: 50%;
    display: none;

    @media (max-width: 1024px) {
      left: calc(50% + 5px);
    }

    @media (min-width: 1600px) {
      top: 50px;
    }
  }

  .notification-menu-into-more {
    top: 26px;
    left: 70px;
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #fc3503;
    border-radius: 50%;
    display: none;

    @media (max-width: 1024px) {
      left: calc(50% + 5px);
    }

    @media (max-width: 1600px) {
      top: 26px;
    }

    @media (max-width: 1440px) {
      top: 37px;
    }
  }

  .notification-menu-into-atendimento {
    top: 34px;
    left: 18px;
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #fc3503;
    border-radius: 50%;
    display: none;

    @media (max-width: 1024px) {
      left: calc(50% + 5px);
    }

    @media (max-width: 1440px) {
      top: 22px;
      left: 12px;
    }
  }

  @media (max-width: 1600px) {
    height: 80px;
  }
  
  @media (max-width: 1440px) {
    height: 75px;
    font-size: 11px;
  }

  @media (max-height: 900px) {
    height: 80px;
  }

  @media (max-height: 800px) {
    height: 75px;
  }

  @media (max-height: 750px) {
    height: 70px;
  }
  
  @media (max-height: 700px) {
    height: 65px;
  }

  @media (max-width: 1024px) {
    height: 55px !important;
  }

  @media (max-height: 600px) {
    height: 50px !important;
  }

  /*@media (min-width: 1281px) and (max-width: 1366px) and (max-height: 620px) {
    height: 65px;
  }

  @media (min-width: 1201px) and (max-width: 1280px) {
    height: 60px;
  }

  @media (min-width: 1025px) and (max-width: 1200px) {
    height: 55px;
  }

  @media (max-height: 675px) and (max-width: 400px) {
    height: 55px;
  }

  @media (max-height: 675px) {
    height: 55px;
  }

  @media (min-height: 800px) {
    height: 75px;
  }*/

  &.dropdown {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: 13px;
    height: 70px;

    span { font-size: 15px; }
    svg { font-size: 25px; margin-right: 10px;}
    img { width: 25px; margin-right: 10px;}

    @media (max-width: 1440px) {
      height: 50px;
      font-size: 12px;

      svg {
        font-size: 18px;
      }
    }

    .wrap-for-warning{
      display: flex;
      justify-content: flex-start;
      align-items: center;
  }
  small{
    margin-left: 1.75rem;
  }
  }

  .seta-active {
      display: none;
    }

  &.active {
    svg {
      color: ${colors.primary};
    }

    .seta-active {
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 10px 10px;
      border-color: transparent transparent ${colors.primary} transparent;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    &:before {
      content: '';
      width: 6px;
      height: 80%;
      border-radius: 10px;
      background-color: ${colors.primary};
      position: absolute;
      top: 10%;
      left: 0;
    }
  }

  img {
    width: 35px;
    height: 35px;
    margin-bottom: 5px;

  }

  svg {
    font-size: 30px;
    margin-bottom: 5px;
    
    @media (max-width: 1368px) {
      font-size: 25px;
    }

    @media (max-height: 675px) and (max-width: 400px) {
      margin-bottom: 0;
      margin-right: 5px;
      margin-left: 20px;
    }
  }
`;

export const Dropdown = styled.div`
  width: 285px;
  height: calc(100vh - 90px);
  position: absolute;
  left: 110px;
  top: 90px;
  background-color: ${colors.bgprimary};
  z-index: 1080;
  box-shadow: ${effects.boxshadow};
  border-top-right-radius: 10px;
  padding: 20px;

  .btn-fechar {
    background-color: transparent;
    font-size: 25px;
    border: 0;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1050;
  }

  @media (max-width: 1440px) {
    width: 280px;
    left: 110px;
    top: 70px;
    height: calc(100vh - 70px);

    @media (max-width: 1024px) {
      z-index: 3005;
      width: ${({ subMenu }) => subMenu === 'Facebook' ? '50%' : '100%'};
      left: 0;
      border-radius: 0;
    }
  }
`;

export const WrapOptions = styled.div`
  background-color: ${colors.bgprimary};
  position: absolute;
  width: 300px;
  height: calc(100% - 90px);
  left: 110px;
  top: 90px;
  padding: 40px 10px 20px;
  z-index: 1080;
  box-shadow: ${effects.boxshadow};
  border-top-right-radius: ${effects.radius};
  border-bottom-right-radius: ${effects.radius};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;


  button {
    background-color: transparent;
    border: 0;
    font-size: 25px;
    color: ${colors.primary};
    position: absolute;
    top: 10px;
    right: 20px;
  }

  ul {
    width: 100%;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px;

    li {
      border: 1px solid ${colors.border};
      margin-left: 12px;
      margin-bottom: 12px;
    }

    a {
      width: 125px;
      height: 90px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      background-color: transparent;
      

      svg {
        font-size: 30px;
        color: ${colors.primary};
      }
      
      span {
        text-align: center;
      }
    }
  }

  @media (max-width: 1440px) {
    width: 300px;
    left: 100px;
    top: 70px;
    height: calc(100% - 70px);      

    @media (max-width: 1024px) {
        z-index: 3005;
        width: 100%;
        left: 0;
        border-radius: 0;
    }
  }
`;

import styled from 'styled-components'

export const Container = styled.div`
    height: 100%;

    .painel-contato {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
`;

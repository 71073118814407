import { useState } from "react"
import { BorderBottom, Container, TextContainer, Title, Triangle } from "./styles"
import React from "react"


export const TitleSwitch = ({text, setSection, section, classCustom}) => {

   const handleSwitchSection = (index) => {
    setSection(index + 1)
   }

    return (
        <Container>

            {text.map((title, index) => (
            <TextContainer key={index} className={section - 1 === index && "active" || !section && "active"} onClick={() => setSection ? handleSwitchSection(index) : null}>
                <TextContainer>
                <Title className={section - 1 === index ? "active " + classCustom : !section ? " active " + classCustom : classCustom}>
                        {title.title} <span>{title.span}</span>
                    </Title>
                </TextContainer>
            </TextContainer>
            ))}
            <BorderBottom></BorderBottom>
        </Container>
    )
}
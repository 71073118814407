import React, { useEffect, useState, createRef, useRef } from 'react';
import Dropzone from 'react-dropzone';
import { format } from 'date-fns';
import { RiCloseLine, RiImageLine } from 'react-icons/ri';
import { TiUserAddOutline } from 'react-icons/ti';
import Compressor from 'compressorjs';
import Swal from 'sweetalert2';
import NEditorPost from '../NEditorPost';

import { sessionGet } from '../../session';

import api from '../../services/api-geral';
import apiUpload from '../../services/api-upload';

import getFiles from '../../util/getFiles';
import { FaRedo } from "react-icons/fa";
import { setIdWithCripto, getIdWithoutCripto } from '../../util/formatParamsUrl';
import { pToAux, auxToP, rmAux, endCursor } from '../../util/markfeed';
import log from '../../util/log';

import Default from '../../assets/default.png';

import FormMentionsFeed from '../FormMentionsFeed';
import ModalMentionsFeed from '../ModalMentionsFeed';
import ListMarkedUsers from '../ListMarkedUsers';

import { Container, Content, DropzoneContainer, AreaToClose, LoadingWrap } from './styles';

function ModalPostFeed({ post, image = null, closeModal, reload, type, getPosts, showExplorer = false, setShowExplorer, hasChangesModal, setHasChangesModal, setImageId, setImagePath }) {
    const [content, setContent] = useState('');
	const [ckEditor, SetCkEditor] = useState('');

	const [imgPerfil, setImgPerfil] = useState('');
	const [hasPhoto, setHasPhoto] = useState(false);

	const [pathImg, setPathImg] = useState('');
	const [idImg, setIdImg] = useState('');

	const [users, setUsers] = useState([]);
	const [markedUsers, setMarkedUsers] = useState([]);
	const [showMentions, setShowMentions] = useState(false);
	const [showModalMarcacoes, setShowModalMarcacoes] = useState(false);
	const [nameSearch, setNameSearch] = useState('');
	const [isSearch, setIsSearch] = useState(false);
	const [isMarked, setIsMarked] = useState(false);

	const [mentions, setMentions] = useState([]);
	const [loading, setLoading] = useState(false);

	const dropzoneRef = createRef();
	const previewRef = useRef(null);



	useEffect(() => { setPathImg(''); }, []);

	useEffect(() => {
		if (showExplorer) {
			document.getElementById('fileinput').click();
			setShowExplorer(false);
		}
	}, [showExplorer]);

	useEffect(() => {
		setContent(post.content);
		if (type === 'edit') {
			getMentions();
		}
		if (type === 'edit' && image != null) {
			setIdImg(image.id);
			setPathImg(post.path_img);
		}
	}, [post, image]);

	useEffect(() => {
		getUsers();
		getFotoPerfil();
	}, []);

	useEffect(() => {
		if ((content === "" || content === null) && pathImg === '') {
			document.getElementById("submit-button").disabled = true;
		} else {
			document.getElementById("submit-button").disabled = false;
		}

		if (type === 'new') setContent(content);

		tratarString();
	}, [content, isSearch, pathImg]);

	useEffect(() => {
		if ((content !== '' && content !== null) || pathImg !== '' || markedUsers.length !== 0) {
			setHasChangesModal(true);

			if (pathImg) {
				setImageId(idImg);
				setImagePath(pathImg);
			}
		} else {
			setHasChangesModal(false);
		}
	}, [type, content, idImg, pathImg, markedUsers]);

	let usuarios = [];

	const tratarString = () => {
		if (type === 'new') {
			var split = content.split(' ');
			let filtred = split.filter(item => {
				return item.includes('data-mention-merx=\"@');
			})
			let array = [];
			filtred.map(mention => {
				let m = mention.split('"');
				m = m[1].replace('@', '');
				m = m.split('/id:');
				array = [...array, { name: m[0], id: Number(m[1]), modal: false }]
			})
			setMentions(array);
		} 
		else {
			var split = content.split(' ');
			let filtred = split.filter(item => {
				return item.includes('https://painel.merx.app.br/teste/');
			})
			let array = [];
			filtred.map(mention => {
				let m = mention.split("/");
				let info = m[4];
				let userMention = info.split("\">");
				userMention[1] = userMention[1].replace('<', '');
				if (userMention[0] !== "") {
					array = [...array, { name: userMention[1].replace('@', ''), id: Number(getIdWithoutCripto(userMention[0])), modal: false }]
				}
			})
			setMentions(array);
		}
	}

	const getUsers = async () => {
		const response = (await api.get('/users?usuarios')).data;
		response.map(user => {
			usuarios.push({

				id: '@' + user.name.split(' ').join(' '), //Substitui o espaço em branco por um caractere invisível
				userId: user.id,
				name: user.name,
				link: `https://api.merx.app.br/perfil?id=${setIdWithCripto('user', user.id)}`
			})
		})
		setUsers(usuarios);
	};

	const updatePost = async e => {
		e.preventDefault();

		const response = (await api.put(`/posts/${post.id}`, { content, pathImg })).data;

		log('editou um post no feed.');

		// recarregando posts
		if (idImg !== '') {
			relacionarImg(post.id);
		}

		markUsers(post.id);

		reload();
		setHasChangesModal(false);
		closeModal();
	};

	const createPost = async e => {
		e.preventDefault();

		const panotra = content + ' ';

		const response = (await api.post('/posts', {
			content: panotra, pathImg
		})).data;

		log('criou um post no feed.');

		if (idImg !== '') {
			log('realizou um upload de imagem no feed.');
			relacionarImg(response.id);
		}

		if (mentions.length > 0 || markedUsers.length > 0) {
			markUsers(response.id);
		}

		setContent('');
		setPathImg('');
		setIdImg('');
		closeModal();
		getPosts();
	};

	const markUsers = async id_post => {
		let newArrayMarkUsers = [];

		markedUsers.map(mark => {
			newArrayMarkUsers.push({
				id: mark.id,
				name: mark.name,
				modal: true
			})
		})

		mentions.map(mark => {
			newArrayMarkUsers.push({
				id: mark.id,
				name: mark.name,
				modal: false
			})
		})

		const response = (await api.post('/marks', {
			id_post,
			users: newArrayMarkUsers,
			type: 'post'
		})).data;
	}

	const relacionarImg = async id_post => {
		const response = (await api.put(`/upload/${idImg}`, {
			id_post,
		}));
	};

	const getFotoPerfil = async () => {
		const response = (await api.get(`/users/${sessionGet('idUser')}`)).data;

		if (response.id_file) {
			setHasPhoto(true);
			setImgPerfil(response.files.path);
		} else {
			setHasPhoto(false);
			setImgPerfil(Default);
		}
	};

	const checkSize = (file) => {
		let img = new Image()
		img.src = window.URL.createObjectURL(file)
		const promise = new Promise((resolve, reject) => {
			img.onload = () => {
				let multiplier;
				if (img.width > 3000 || img.height > 3000) {
					multiplier = 0.2
				} else {
					multiplier = 0.6
				}
				resolve({ width: img.width, height: img.height, m: multiplier });
				img.onerror = reject;
			}
		})
		return promise;
	}

	const upload = async files => {
		if (files) {
			const dados = new FormData();

			const imgSizes = await checkSize(files[0]);

			dados.append('modulo', 'feed');


			const size = parseFloat((files[0].size / 1024).toFixed(2));

			var quality = 1

			if(size > 200) {
				quality = 0.9
			}

			if(size > 600) {
				quality = 0.8
			}

			if(size > 1024) {
				quality = 0.6
			}

			new Compressor(files[0], {
				quality: quality,
				convertSize: 0,
				width: imgSizes ? imgSizes.width * imgSizes.m : 1,
				height: imgSizes ? imgSizes.height * imgSizes.m : 1,
				async success(result) {
					dados.append('file', result, result.name);
					const response = (await apiUpload.post('/upload', dados)).data;
					setPathImg(response.path);
					// ckEditor.editing.view.focus();
					setIdImg(response.id);
					setLoading(false);
				}
			});
		}
	};

	const getMentions = async () => {
		const response = (await api.get(`/marks/post/${post.id}`)).data;

		var marks = [];
		var modalMarks = []

		response.marcacoes.map(mark => {
			if (mark.modal === true) {
				modalMarks.push({
					...mark.user,
					modal: mark.modal
				});
			} else {
				marks.push({
					...mark.user,
					modal: mark.modal
				})
			}
		});

		setMentions(marks);
		setMarkedUsers(modalMarks);
	};

	const deleteImage = async (id) => {
		const response = (await apiUpload.delete(`/upload/${id}/${pathImg}`)).data;
		const sResponse = (await api.put(`/posts-delete-image/${post.id}`)).data;

		setIdImg('');
		setPathImg('');

		if (previewRef) { previewRef.current = null; }

		const el = document.getElementById('fileinput');
		if (el) {
			el.value = null;
		}
		ckEditor.editing.view.focus();
	};

	const trocarNomeMarcacao = (id, nome) => {
		/*setIsMarked(true);
		const str = pToAux(content);
		const subStr = str.split('@')[0];
		const newStr = subStr.replace(subStr, `${subStr} <a href='/conversas/${id}'>${nome}</a>&nbsp;</p>`);
		setIsSearch(false);
		setContent(auxToP(newStr));*/
	}

	//FUNÇÃO DO PRÓPERIO CKEDITOR PARA LISTAR OS USUÁRIOS NO MODAL DE MENÇÃO
	function getFeedItems(queryText) {
		return new Promise(resolve => {
			setTimeout(() => {
				const itemsToDisplay = usuarios
					.filter(isItemMatching)
					.slice(0, usuarios.length);
				resolve(itemsToDisplay);
			}, 100);
		});

		function isItemMatching(item) {
			const searchString = queryText.toLowerCase();
			return (
				item.name.toLowerCase().includes(searchString) ||
				item.id.toLowerCase().includes(searchString)
			);
		}
	}

	// FUNÇÃO SAIR COM ESC
	const pressEsc = (e) => {
		if (e.key === 'Escape') {
			showSwal();
		}
	}

	const clickToExit = (e) => {
		showSwal()
	}

	const showSwal = () => {
		if (hasChangesModal) {
			Swal.fire({
				title: 'Atenção',
				text: 'Você possui alterações não publicadas, continuar mesmo assim?',
				icon: 'question',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Descartar',
				cancelButtonText: 'Não'
			}).then(async (result) => {
				if (result.isConfirmed) {
					if (idImg !== '') {
						deleteImage(idImg, pathImg)
					}
					closeModal();
				}
			});
		} else {
			closeModal();
		}
	}

	const handlePasteUpload = async (imageData) => {
		if (previewRef.current === null) {
			setLoading(true);
			upload(imageData);
		}
	}

	return (
		<>
			<Container>
				<Content onKeyUp={(e) => { pressEsc(e) }}>
					{showMentions && <FormMentionsFeed markedUsers={markedUsers} setMarkedUsers={setMarkedUsers} setShowMentions={setShowMentions} />}
					<>
						<div className="head">
							<h1>{type === "new" ? 'Criar publicação' : 'Editar Publicação'}</h1>
							<button className="btn-close" onClick={showSwal}>
								<RiCloseLine />
							</button>
						</div>
						<div className="body">
							<div className="autor">
								<img src={hasPhoto ? getFiles(imgPerfil) : imgPerfil} onClick={() => document.getElementById("input_file").click()} />
								<div className="desc">
									<strong>{type === "new" ? sessionGet('nomeUser') : post.autor.name}</strong>
									{type === "edit" ? <small>{format(new Date(post.createdAt), 'dd/MM/yyyy - HH:mm')}</small> : ''}
								</div>
							</div>
							<form onSubmit={type === "new" ? createPost : updatePost}>
								<div className="content-form">
									<Dropzone accept="image/*" onDrop={files => upload(files)} noClick={true}>
										{({ getRootProps, getInputProps, isDragActive, isDragReject, open }) => (
											<section>
												<div {...getRootProps()}>
													{(!isDragActive) ?
														<NEditorPost
															id='editorTexto'
															content={content}
															setContent={setContent}
															type={type}
															SetCkEditor={SetCkEditor}
															getFeedItems={getFeedItems}
															handlePasteUpload={handlePasteUpload}
														/> : (isDragReject) ?
															<DropzoneContainer isDragActive={isDragActive} isDragReject={isDragReject}>
																<p className="file-reject">Arquivo não suportado</p>
															</DropzoneContainer> :
															<DropzoneContainer isDragActive={isDragActive} isDragReject={isDragReject}>
																<p>Solte os arquivos aqui</p>
															</DropzoneContainer>
													}
													<input id="fileinput" type="file" {...getInputProps()} multiple />
												</div>
											</section>
										)}
									</Dropzone>
								</div>

								{/* {showModalMarcacoes && <ModalMentionsFeed
									markedUsers={mentions}
									setMarkedUsers={setMarkedUsers}
									search={nameSearch}
									newSearch={trocarNomeMarcacao}
									setShowModalMarcacoes={setShowModalMarcacoes} />} */}
								<ListMarkedUsers mentions={mentions} markedUsers={markedUsers} setMarkedUsers={setMarkedUsers} />
								{(loading) &&
									<LoadingWrap><FaRedo className="fa-spin" /><h3>Carregando...</h3></LoadingWrap>
								}
								{(pathImg != '') &&
									<>
										<div className="image-preview" ref={previewRef}>
											<div className="content-image-preview">
												<img className="img-preview" src={getFiles(pathImg)} />
												<button className="btn-delete-img" type="button" onClick={() => deleteImage(idImg, pathImg)}><RiCloseLine /></button>
											</div>
										</div>
									</>
								}

								<div className="wrap-utils">
									{(pathImg == '' || pathImg == null) && (
										<button onClick={() => document.getElementById('fileinput').click()} type="button">
											<div className="icon">
												<RiImageLine />
											</div>
											<span>Adicionar imagem</span>
										</button>
									)}

									<button onClick={() => setShowMentions(true)} type="button">
										<div className="icon">
											<TiUserAddOutline />
										</div>
										<span>{markedUsers.length > 0 ? 'Editar marcações' : 'Incluir marcações'}</span>
									</button>
								</div>
								<button id="submit-button" className="form-button" type="submit">
									{type == "new" ? 'Publicar' : 'Salvar alterações'}
								</button>
							</form>
						</div>
					</>

				</Content>
				<AreaToClose onClick={(e) => { clickToExit(e) }} />
			</Container >
		</>
	);
}

export default ModalPostFeed;
import styled from 'styled-components';

import colors from '../../../presets/colors';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;

  .backdrop {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);;
    width: 100%;
    height: 100%;
    z-index: 1049;
  }

  .modal {
    position: absolute;
    background-color: white;
    border-radius: 15px;
    width: 425px;
    z-index: 1050;

    @media (max-width: 475px) {
      width: 350px;
    }

    @media (max-width: 400px) {
      width: 300px;
    }

    @media (max-width: 300px) {
      width: 280px;
    }

    hr {
      width: 100%;
    }

    .head {
      padding: 15px;
    }

    .content {
      padding: 0 15px;
      margin: -15px 0px;
    }

    .save {
      padding: 10px;
      width: 100%;
      text-align: center;

      button {
        height: 40px;
        padding: 0 20px;
        border: none;
        color: white;
        background-color: ${colors.primary};
        border-radius: 25px;
      }
    }
  }
`;

export const Button = styled.button`
  border: none;
  background: white;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  top: 5px;

  svg {
    font-size: 25px;
  }
`

import React, { useState, useEffect } from 'react';
import VizSensor from 'react-visibility-sensor';

import api from '../../../services/api-geral'
import { sessionGet } from '../../../session';

import { FaRegClipboard } from 'react-icons/fa';

import { Backdrop } from '../../../styles/content';
import { Wrap, Notes } from './styles';

import Lembretes from '../ListaLembretes';


export default function ViewLembretes({ setShowNotes, lembretes, reload, flag}) {
  const [viwed, setView] = useState(false)

  const visualizarPostagem = async (visibilidade, data) => {
    if (visibilidade === true) {
      const response = await api.post('/lembrete-view', { id_note: data.id, id_empresa: data.id_empresa, id_user: sessionGet('idUser')});
      setTimeout(() => { reload() }, 100)
      setView(true)
    }
  }


  return (
    <>
  
      <Backdrop onClick={() => setShowNotes(false)} />
      <Wrap className={lembretes.length > 0 ? 'full' : 'empty'}>
      <h3>Lembretes</h3>
        {lembretes.length === 0 && ( 
           <div className='empty-container'>
                 <div className="icon-wrap" >
                    <FaRegClipboard  />
                 </div>
                  <div className="empty-content" >
                    <p>Não há lembretes no momento</p>
                  </div>
          </div>)}
          
        {lembretes.length > 0 && (
          <>
            <div className="header-notifications">
            </div>
            <Notes>
            {lembretes.map((data) => (
                  <VizSensor key={data.id} onChange={(isVisible) => visualizarPostagem(isVisible, data)}>
                  <Lembretes key={data.id} lembrete={data} dates={false} icons={false} lido={viwed} />
                </VizSensor>
             ))}
            </Notes>
          </>
        )}
      </Wrap>
    </>
  );
}

import styled from 'styled-components';

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1052;
`;

export const WrapModal = styled.div`
  background-color: white;
  width: 30%;
  border-radius: 10px;
  max-height: calc(100vh - 140px);
  position: absolute;
  left: 35%; 
  top: 110px;
  z-index: 1053;

  .closebutton {
    position: absolute;
    top: 27px;
    right: 0;

    svg {
      width: 30px;
      height: 30px;
      margin: 0;

      @media (max-width: 575px) {
        width: 20px;
        height: 20px;
      }
    }

    @media (max-width: 575px) {
      top: 5px;
    }
  }

  @media (max-width: 1024px) {
    width: 90%;
    left: 5%;
    top: 100px;
    height: calc(100% - 100px);
  }
`;

export const WrapModalView = styled.div`
  background-color: #EFEFEF;
  width: 85vw;
  height: 80vh;
  position: absolute;
  top: 100px;
  right: 40px; 
  padding: 30px 25px;
  z-index: 1053;

  .closebutton {
    position: absolute;
    top: 10px;
    right: 0;
    padding-right: 10px !important;

    svg {
      width: 30px;
      height: 30px;
      margin: 0;
    }
  }

  & > button {
    z-index: 1000;
  }

  @media (max-width: 1366px) {
    padding: 20px 15px;
  }

  @media (max-width: 1024px) {
    top: 70px;
    right: 0;
    height: 100vh;
    padding: 20px 20px 80px 20px;
    width: 100%;
    overflow-y: scroll;
  }
`;

export const AreaToClose = styled.div`
  width: calc(100% - 110px);
  height: calc(100% - 90px);
  position: fixed;
  top: 90px;
  left: 110px;
  z-index: 1052;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1440px) {
    width: calc(100% - 110px);
    height: calc(100% - 70px);
    top: 70px;
    left: 100px;
  }

  @media (max-width: 1024px) {
    width: 100%;
    left: 0;
  }
`;

import styled from 'styled-components';

import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Form = styled.form`
  background-color: rgba(255, 255, 255, 0.1);
  width: 230px;
  height: 40px;
  border-radius: 20px;
  // box-shadow: 0 1px 15px rgba(0,0,0,.04), 0 1px 6px rgba(0,0,0,.04);
  box-shadow: ${effects.boxshadow};
  display: flex;
  align-items: center;
  justify-content: flex-start;

  svg {
    color: rgba(255, 255, 255, 0.2);
    font-size: 22px;
    margin-left: 5px;
  }

  &.dark {
    background-color: ${colors.bgprimary};
    color: #303030;
    border-radius: ${effects.radius};

    svg {
      color: #333;
    }
  }

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const Input = styled.input`
  background-color: transparent;
  border-radius: 20px;
  width: 83%;
  height: 100%;
  padding-left: 15px;
  border: 0;
  outline: none;
  color: #000;

  &.dark {
    color: #303030;
  }
`;

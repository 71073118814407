import React from 'react';

import { WrapNotificacao } from './styles';

function NotificationOnOff({ imageProfile, nameUser, contentStatus }) {
  return (
    <WrapNotificacao>
      <img src={imageProfile} />

      <div className="description">
        <strong>{nameUser} </strong>
        <span>{contentStatus}</span>
      </div>
    </WrapNotificacao>
  );
}

export default NotificationOnOff;
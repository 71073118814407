import React, { useState, useEffect } from 'react';
import urlToEmbed from '../../util/urlToEmbed';

import { RiCloseLine } from 'react-icons/ri';

import { Container, Form } from './styles';

function ModalVideo({ urlVideo, setIdVideo, setUrlVideo, closeModal }) {
  const [url, setUrl] = useState('');
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    setUrl(urlVideo);
  }, []);

  const validarVideo = e => {
    e.preventDefault();

    const urlValidada = urlToEmbed(url);

    if (urlValidada !== null) {
      setIdVideo(urlValidada);
      setUrlVideo(url);
      closeModal(false);
    }
    else {
      setUrl('')
      setShowError(true);
    }
  };

  const limparVideo = () => {
    setIdVideo('');
    setUrlVideo('');
    closeModal(false);
  };

  return (
    <Container>
      <div className='backdrop' onClick={() => closeModal()}></div>
      <Form onSubmit={validarVideo}>
        <button type="button" className='btnfechar' onClick={() => closeModal()}><RiCloseLine /></button>
        <p>
          Insira abaixo a url do vídeo do <b>youtube</b> que será incorporado:
        </p>
        <input type='text' value={url} onChange={e => setUrl(e.target.value)} placeholder='Ex: youtube.com/...' />
        {/* <input type='text' value={url} disabled={urlVideo !== ''} onChange={e => setUrl(e.target.value)} placeholder='Ex: youtube.com/...' /> */}
        {showError && (
          <small>Você precisa adicionar um vídeo do youtube! Vídeos de outras plataformas não são permitidos.</small>
        )}
        <div className="buttons">
          <button type="submit" className='btnsubmit'>Anexar vídeo</button>
          {/* {urlVideo === '' && <button type="submit" className='btnsubmit'>Anexar vídeo</button>} */}
          {/* {urlVideo !== '' && <button onClick={() => limparVideo()} type="button" className='btnlimpar'>Retirar vídeo</button>} */}
        </div>
      </Form>
    </Container>
  );
}

export default ModalVideo;

import React, { useEffect, useState } from 'react';
import { MdKeyboardArrowRight } from "react-icons/md";

import ModalQRcode from '../../ModalQRcode';

import ViewForm from '../../ViewForm';
import CButton from '../../ComponentButton';

import { Container, Link, ButtonLink } from './styles';

function LinkPaginaEmpresarial({ linkPage }) {

  const [showModal, setShowModal] = useState('');
  const [item, setItem] = useState('');

  useEffect(() => {
    let textInput;
    let copyButton;

    setTimeout(function () {
      textInput = document.getElementById('text');
      copyButton = document.getElementById('copy-button');
      if (textInput && copyButton) {
        copyButton.addEventListener('click', () => {
          textInput.select();
          document.execCommand('copy');
          changeButtonColor();
        });
      }
    }, [1000])
  }, [])

  function changeButtonColor() {
    const el = document.getElementById('copy-button');

    if (el) {
      el.style.backgroundColor = '#60c045';
      el.innerText = "Copiado!"
    }

    setTimeout(function () {
      if (el) {
        el.style.backgroundColor = '#8a2387';
        el.innerText = "Copiar"
      }
    }, [3000])
  }

  const closeModal = () => {
    setShowModal('');
    setItem({});
  }

  const mostrarModal = (val) => {
    setShowModal(val);
    setItem({});
  }

  return (
    <Container>
      {(showModal !== '') ? (
        <ModalQRcode
          view={'view'}
          close={closeModal}
          linkPage={linkPage}
        />
      ) : ''}
      <Link>
        <input id="text" type="text" value={(linkPage) ? linkPage : 'Link da página'} />
        <div className="buttons" >
        <ButtonLink id="copy-button" type="button">
          Copiar
        </ButtonLink>

        <ButtonLink className="big" id="copy-button" type="button" onClick={() => { mostrarModal('view') }}>
          Abrir QR Code
        </ButtonLink>
        </div>
      </Link>
    </Container>
  );
}

export default LinkPaginaEmpresarial;
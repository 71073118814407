import styled from 'styled-components';

export const Wrap = styled.div`
    width: 80%;
    margin-bottom: 20px;

    em {
        color: white;
        padding-left: 10px;
        margin-bottom: 5px;
        display: block;
    }

    .custom-select {
        width: 100%;
        height: 40px;
        border-radius: 20px;
        border: 0;
        color: white;
        padding: 0 20px;
        background-color: rgba(0, 0, 0, 0.3);

        &:after, &:before {
            content: 0;
            border: 0;
            background: transparent;
            width: 0;
            height: 0;
        }
    }
`;

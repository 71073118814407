import styled from 'styled-components';

import colors from "../../presets/colors";
import effects from "../../presets/effects";

export const Content = styled.div`
  max-height: 80%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  z-index: 1060;
  width: 30%;
  background-color: white;
  border: 1px solid ${colors.border};
  box-shadow: ${effects.boxshadow};
  border-radius: 15px;

  .subtitle {
    color: ${colors.primary};
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 20px;
  }

  @media (max-width: 1600px) {
    width: 40%;
  }

  @media (max-width: 1275px) {
    width: 50%;
  }

  @media (max-width: 1000px) {
    width: 60%;
  }

  @media (max-width: 740px) {
    width: 65%;
  }

  @media (max-width: 678px) {
    width: 70%;
  }

  @media (max-width: 550px) {
    width: 80%;
  }

  @media (max-width: 420px) {
    width: 85%;
  }

  ::-webkit-scrollbar {
    width: 0px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.border};
    border-radius: ${effects.radius};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .head {
    position: relative;
    display: flex;
    height: 75px;
    padding: 15px 0;
    border-bottom: 1px solid ${colors.border};

    @media (max-width: 450px) {
      height: 60px;
    }

    h1 {
      font-size: 20px;
      letter-spacing: 2px;
      color: ${colors.text_primary};

      @media (max-width: 450px) {
        font-size: 16px;
      }
    }

    .btn-close {
      position: fixed;
      top: 10px;
      right: 10px;
      background-color: ${colors.bgsecondary};
      border: 0;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 450px) {
        height: 30px;
        width: 30px;
      }

      svg {
        font-size: 20px;
      }
    }
  }
`;

export const WrapForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  z-index: 1060;

  ::-webkit-scrollbar {
      width: 0px;
  }

  .form-header {
    border-bottom: 1px solid #d7d7d7;
    padding: 25px;
    /* text-align: center; */

    h2 {
      color: ${colors.texts};
    }

    label {
      margin: 10px;
    }

  }

  .form-body {
    height: 100%;
    padding: 25px;

    .form-body-header {
      justify-content: space-between;
      display: flex;

      .closebutton {
        margin: 0;
        padding: 0;
      }
    }

    .status-box {
      width: 100%;
      border: solid 0.1rem;
      border-radius: 15px;
      padding: 10px;
      margin-bottom: 10px;
      border-color: rgba(152,152,152,0.35);
    }

    .custom-status {
      cursor: pointer;
      margin: 10px 0px;
      &:focus-within {
        border-color: ${colors.primary};

        label {
          color: ${colors.primary};
        }
      }
    }
  }

  .form-footer {
    border-top: 1px solid #d7d7d7;
    padding: 25px;
    display: flex;
    justify-content: center;
  }
  
`;

export const Cards = styled.div`
  width: 100%;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 20px;

  ::-webkit-scrollbar {
      width: 0px;
  }

  ::-webkit-scrollbar-track {
      background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
      background: ${colors.border};
      border-radius: ${effects.radius};
  }

  ::-webkit-scrollbar-thumb:hover {
      background: #555;
  }
`;

import React from 'react';

import emojis from '../../util/emojis';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { MentionCustomization } from '../../util/formatMention'

function NEditorPost({ content, setContent, type, SetCkEditor, getFeedItems, handlePasteUpload }) {

    return (
        <CKEditor
            className="text-input"
            editor={Editor}
            onReady={(editor) => {
                editor.editing.view.document.on('paste', (evt, data) => {
                    if (data.dataTransfer.files[0]) {
                        const imageData = data.dataTransfer.files;
                        handlePasteUpload(imageData);
                    }
                });
                MentionCustomization(editor);
                editor.plugins.get('SpecialCharacters').addItems('Emoji', emojis);
                editor.model.change(writer => {
                    writer.setSelection(writer.createPositionAt(editor.model.document.getRoot(), 'end'));
                });
                editor.editing.view.focus();
                SetCkEditor(editor);
            }}
            config={{
                placeholder: 'Digite uma mensagem. Caso queira, anexe ou arraste uma imagem para compartilhar.',
                toolbar: ['SpecialCharacters', 'bold', 'italic', 'bulletedList', 'numberedList', 'heading'],
                removePlugins: ['TextTransformation'],
                mediaEmbed: {
                    previewsInData: true,
                    removeProviders: ['instagram', 'twitter', 'googleMaps', 'flickr', 'facebook']
                },
                mention: {
                    feeds: [
                        {
                            marker: '@',
                            feed: getFeedItems,
                            minimumCharacters: 0
                        }
                    ]
                },
                link: {
                    addTargetToExternalLinks: {
                        attributes: {
                            target: "_blank",
                            rel: "noopener noreferrer",
                        }
                    }
                }
            }}
            data={type === "new" && content === "" ? "" : content}
            onChange={(event, editor) => {
                setContent(editor.getData());
                setContent(editor.getData());
            }}
        />
    );
}

export default NEditorPost;
import React, { useState, useEffect } from 'react';

import api from '../../services/api-geral';

import Page from '../../components/Page';
import TitleAndBreadcrumb from '../../components/TitleAndBreadcrumb';
import ListaNotificacoes from '../../components/ListaNotificacoes';

import { Painel } from '../../styles/content';

import { Container } from './styles';

export default function MostrarNotificacoes() {
    const [notifications, setNotifications] = useState([]);
    const [page, setPage] = useState(0);
    const [countNot, setCountNot] = useState(0);
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        getNotifications();
    }, [page]);

    useEffect(() => {
        verifyPagination();
    }, [countNot, page])

    const getNotifications = async () => {
        const response = (await api.get(`/notifications/15/${page}`)).data;
        setNotifications([...notifications, ...response.notificacoes]);
        setCountNot(response.count);
    };

    const verifyPagination = () => {
        const numberPages = (countNot / 15);

        var lastPage = 0;
        var pagina = page;

        if (numberPages % 1 === 0) {
            pagina = pagina + 1;
            lastPage = numberPages;
        } else lastPage = parseInt(numberPages);


        if (pagina === lastPage) setShowButton(false);
        else setShowButton(true);
    }

    return (
        <Page content={(
            <Container>
                <TitleAndBreadcrumb title='Suas notificações' breadcrumbs={[]} />
                <Painel className="painel-notificacoes">
                    <ul>
                        <ListaNotificacoes notifications={notifications} />
                    </ul>
                    {showButton && <button onClick={() => setPage(page + 1)} className="cta-carregar-notificacoes">Ver mais notificações</button>}
                </Painel>
            </Container>
        )} />
    );
}

import React, { useState, useEffect } from 'react';

import ApiGeral from '../../services/api-geral';
import Swal from 'sweetalert2';

import { RiArrowDownSLine } from 'react-icons/ri';

import { Container } from './styles';

import CInput from '../ComponentInput';
import CSelect from '../ComponentSelect';
import CButton from '../ComponentButton';

function CardStatus({ id, title, deletar, setShowModalStatus }) {
  const [show, setShow] = useState(false);
  const [customStatus, setCustomStatus] = useState('');
  const [color, setColor] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [userListaStatus, setUserListaStatus] = useState([]);

  const showOnOff = () => {
    var card = document.getElementById(`card${id}`);
    if (show === false) {
      card.classList.add('card-content-active');
      setShow(true);
    } else {
      card.classList.remove('card-content-active');
      setShow(false);
    }
  }

  const getUserStatus = async () => {
    const response = (await ApiGeral.get('/status-user')).data;
    let statusIcon = '';
    for (let i = 0; i < response.length; i++) {
      switch (response[i].status_color) {
        case "online":
          statusIcon = '🟢 ';
          break;
        case "ausente":
          statusIcon = '🟡 ';
          break;
        case "naoPerturbe":
          statusIcon = '🔴 ';
          break;
      }
      let jsonData = {
        "id": response[i].id,
        "status": statusIcon + response[i].status,
        "status_color": response[i].status_color,
      };
      userListaStatus.push(jsonData);
    }
  }

  useEffect(() => {
    getUserStatus();
  }, []);

  const changeStatusAfterCreate = async (id) => {
    const response = await ApiGeral.put('/select-status', { id: id });
  }

  const submitForm = async e => {
    e.preventDefault();
    if (!deletar) {
      // Criação de status:
      const response = (await ApiGeral.post('/status', { status: customStatus, status_color: color })).data;
      changeStatusAfterCreate(response.status.id);
      if (response.type === "success") {
        Swal.fire({
          title: 'Sucesso!',
          text: response.msg + 'Status alterado para o recém-criado.',
          icon: 'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok',
          timer: 2500,
          willClose: () => {
            setShowModalStatus(false);
          }
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            setShowModalStatus(false);
          }
        });
      }
    } else {
      // Remoção de status:      
      const response = (await ApiGeral.get('/status-atual')).data;
      if (response !== null && response.id == selectedStatus) {
        const changeStatusToDefault = await ApiGeral.put('/default-status');
        const remove = (await ApiGeral.delete(`/status/${selectedStatus}`)).data;
      } else {
        const remove = (await ApiGeral.delete(`/status/${selectedStatus}`)).data;
      }
      Swal.fire({
        title: 'Sucesso!',
        text: 'Status removido com sucesso!',
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
        timer: 2500,
        willClose: () => {
          setShowModalStatus(false);
        }
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.timer) {
          setShowModalStatus(false);
        }
      });
    }
  }

  return (
    <Container>
      <div className="card-content" id={`card${id}`}>
        <div className='card-title' onClick={() => { showOnOff() }}>
          <h3>{title}</h3>
          <span><RiArrowDownSLine /></span>
        </div>
        <form className='card-answer' onSubmit={submitForm}>
          {(deletar) ?
            <>
              <CSelect label='Selecionar status' val={selectedStatus} change={e => setSelectedStatus(e)} items={userListaStatus} customCase={true} />
              <div className='button-area'>
                <CButton type="submit" title='Remover' cstyle='primary small' />
              </div>
            </>
            :
            <>
              <CInput style='radius' val={customStatus} change={e => setCustomStatus(e)} type='text' label='Status personalizado' maxLength={12} required={true} />
              <div className="radio-buttons">
                <div className="radio-label">
                  <input type='radio' name='color' value='online' onChange={e => setColor(e.target.value)} required={true} />
                  <label>Disponível</label><br></br>
                </div>
                <div className="radio-label">
                  <input type='radio' name='color' value='ausente' onChange={e => setColor(e.target.value)} required={true} />
                  <label>Ausente</label><br></br>
                </div>
                <div className="radio-label">
                  <input type='radio' name='color' value='naoPerturbe' onChange={e => setColor(e.target.value)} required={true} />
                  <label>Não perturbe</label><br></br>
                </div>
              </div>
              <div className='button-area'>
                <CButton type="submit" title='Salvar' cstyle='primary small' />
              </div>
            </>}
        </form>
      </div>
    </Container>
  );
}

export default CardStatus;
import React, { useEffect, useState } from 'react';

import CCheckbox from '../ComponentCheckBox';
import { RiCloseLine } from 'react-icons/ri';

import { Wrap } from './styles';

function SetorChatEspalhar({ setores, setorAtivo, selects, setDisplay }) {

    const [selectedItems, setSelectedItems] = useState([]);
    const [allSelect, setAllSelect] = useState('');
    const [totalSelect, setTotalSelect] = useState(0);

    const [selectedSetores, setSelectedSetores] = useState([]);
    const [totalSelectSetores, setTotalSelectSetores] = useState(0)

    useEffect(() => {
        let usuariosSelecionados = [];
        let setoresSelecionados = [];
        setores.map(setor => {
            if (setor.setor === setorAtivo) {
                setoresSelecionados.push(setor.setor);
                setor.usuarios.map(usuario => usuariosSelecionados.push(usuario.id))
            }
        })

        setSelectedItems(usuariosSelecionados);
        setSelectedSetores(setoresSelecionados)
    }, [setorAtivo]);

    useEffect(() => {
        selects(selectedItems);
    }, [selectedItems])

    const toggleSelectedItems = (id) => {
        if (selectedItems.includes(id)) {
            let itemIndex = selectedItems.filter(v => v !== id);
            setSelectedItems(itemIndex);

            if (itemIndex.length < totalSelect) {
                setAllSelect('');
                setTotalSelect(0)
            }

        } else {
            setSelectedItems([...selectedItems, id]);
        }
    };

    const toggleSelectedSetores = (setorSelecionado) => {

        let usuariosSetor = []
        let auxLista = selectedItems;
        setores.map(setor => {
            if (setor.setor === setorSelecionado) {
                setor.usuarios.map(usuario => usuariosSetor.push(usuario.id))
            }
        })

        if (selectedSetores.includes(setorSelecionado)) {
            let itemIndex = selectedSetores.filter(v => v !== setorSelecionado);
            setSelectedSetores(itemIndex);

            if (itemIndex.length < totalSelectSetores) {
                setAllSelect('');
            }

            auxLista = auxLista.filter(item => !usuariosSetor.includes(item))
            setSelectedItems(auxLista);

        } else {
            setSelectedSetores([...selectedSetores, setorSelecionado]);

            if ([...selectedSetores, setorSelecionado].length === totalSelectSetores) {
                setAllSelect('in');
            }

            usuariosSetor.map(usuario => {
                auxLista.push(usuario)
            });

            setSelectedItems(auxLista);
        }
    }

    const selectAllItems = () => {
        if (allSelect === 'in') {
            setSelectedItems([]);
            setSelectedSetores([]);
            setAllSelect('');
            setTotalSelect(0)
            setTotalSelectSetores(0)
        } else {

            let usuariosSelecionados = [];
            let setoresSelecionados = [];

            setores.map(setor => {
                setoresSelecionados.push(setor.setor)
                setor.usuarios.map(usuario => { usuariosSelecionados.push(usuario.id) })
            })

            setAllSelect('in');
            setSelectedItems(usuariosSelecionados);
            setSelectedSetores(setoresSelecionados);
            setTotalSelect(usuariosSelecionados.length);
            setTotalSelectSetores(setoresSelecionados.length);
        }
    };


    return (
        <Wrap>
            <button onClick={() => setDisplay(false)} className="btn-close"><RiCloseLine /></button>
            <div className="box-all">
                <CCheckbox
                    classWrap='checkbox-espalhar'
                    active={allSelect}
                    click={() => selectAllItems()}
                />
                <span>Marcar todos</span>
            </div>
            {
                setores.map((setor, index) => (
                    <>
                        <div className="box-setores">
                            <CCheckbox
                                classWrap='checkbox-espalhar'
                                active={(selectedSetores.includes(setor.setor)) ? 'in' : ''}
                                click={() => toggleSelectedSetores(setor.setor)}
                            />
                            <span>{setor.setor}</span>
                        </div>
                        {
                            setor.usuarios.map(usuario => (
                                <div className="box-usuarios">
                                    <CCheckbox
                                        classWrap='checkbox-espalhar'
                                        active={(selectedItems.includes(usuario.id)) ? 'in' : ''}
                                        click={() => toggleSelectedItems(usuario.id)}
                                    />
                                    <span>{usuario.name}</span>
                                </div>
                            ))
                        }
                    </>
                ))
            }
        </Wrap>
    );
}

export default SetorChatEspalhar;
import styled from 'styled-components'

import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Container = styled.div`
  
    width: 100%;
    display: flex;
    place-content: center;
    overflow-y: scroll;

    &::-webkit-scrollbar {
		width: 4px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: #f1f1f1;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: ${colors.border};
		border-radius: ${effects.radius};
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #555;
    }

    .content {
        width: 55%;
        height: 85vh;

        @media (max-width: 1270px) {
            width: 65%;
        }
        @media (max-width: 1150px) {
            width: 75%;
        }
        @media (max-width: 835px) {
            width: 85%;
        }
        @media (max-width: 600px) {
            width: 95%;
        }
    }

    .scrolled {
        overflow-y: unset !important;
        height: unset;
    }

    .comment-blink {
        animation:blink 1.0s linear infinite;
        animation-timing-function: step-end; 
    }

    @keyframes blink {
        0% {background-color: #ebeff5}
        50% {background-color: white}
        100% {background-color: #ebeff5}
    }

`;
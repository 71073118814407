import React, { useEffect, useState, useRef } from 'react';
import api from '../../services/api-geral';
import apiUpload from '../../services/api-upload';
import Default from '../../assets/default.png';
import Compressor from 'compressorjs';
import Swal from 'sweetalert2';
import { TiUserAddOutline } from 'react-icons/ti';

import log from '../../util/log';

import { sessionGet } from '../../session';

import { format } from 'date-fns';
import getFiles from '../../util/getFiles';
import { FaRedo } from "react-icons/fa";
import { setIdWithCripto, getIdWithoutCripto } from '../../util/formatParamsUrl';

import { RiCloseLine, RiImageLine } from 'react-icons/ri';
import { Container, Content, DropzoneContainer, AreaToClose, LoadingWrap } from '../ModalPostFeed/styles';

import NEditorTexto from '../NEditorTexto';
import ModalMentionsFeed from '../ModalMentionsFeed';
import ListMarkedUsers from '../ListMarkedUsers';
import FormMentionsFeed from '../FormMentionsFeed';
import Dropzone from 'react-dropzone';

function ModalCommentPost({ comment = null, id_post, reload, type, image = null, closeModal, uploadComment, setUploadComment, setHasChangesModal, setImageId, setImagePath }) {
    const [content, setContent] = useState('');
    const [ckEditor, SetCkEditor] = useState('');

    const [imgPerfil, setImgPerfil] = useState('');
    const [hasPhoto, setHasPhoto] = useState(false);

    const [pathImg, setPathImg] = useState('');
    const [idImg, setIdImg] = useState('');

    const [users, setUsers] = useState([]);
    const [markedUsers, setMarkedUsers] = useState([]);
    const [showMentions, setShowMentions] = useState(false);
    const [showModalMarcacoes, setShowModalMarcacoes] = useState(false);
    const [nameSearch, setNameSearch] = useState('');
    const [isSearch, setIsSearch] = useState(false);
    const [isMarked, setIsMarked] = useState(false);
    const [hasChangesModal, setHasChangesOnModal] = useState(false);

    const [mentions, setMentions] = useState([]);

    const previewRef = useRef(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => { setPathImg(''); }, []);

    useEffect(() => {
        getFotoPerfil();
        getUsers();
    }, []);

    useEffect(() => {
        if (uploadComment) {
            document.getElementById('fileinput').click();
            setUploadComment(false);
        }
    }, [uploadComment]);

    useEffect(() => {
        if (type == 'edit') getMentions();
        if (type === 'edit' && image != null) {
            setIdImg(image.id);
            setPathImg(comment.path_img);
        }
    }, [comment, image]);

    useEffect(() => {
        if (type == 'edit') setContent(comment.content);
    }, [comment]);

    useEffect(() => {
        tratarString();
    }, [content, isSearch]);

    useEffect(() => {
        if ((content !== '' && content !== null) || pathImg !== '' || markedUsers.length !== 0) {
            setHasChangesModal(true);
            setHasChangesOnModal(true);

            if (pathImg) {
                setImageId(idImg);
                setImagePath(pathImg);
            }
        } else {
            setHasChangesModal(false);
            setHasChangesOnModal(false);
        }
    }, [content, idImg, pathImg, markedUsers]);

    let usuarios = [];

    const tratarString = () => {
  
            var split = content.split(' ');
            let filtred = split.filter(item => {
                return item.includes('data-mention-merx=\"@');
            })
            let array = [];
            filtred.map(mention => {
                let m = mention.split('"');
                m = m[1].replace('@', '');
                m = m.split('/id:');
                array = [...array, { name: m[0], id: Number(m[1]), modal: false }]
            })
            setMentions(array);
  
            // var split = content.split(' ');
            // let filtred = split.filter(item => {
            //     return item.includes('localhost:3333/conversas/');
            // })
            // let array = [];
            // filtred.map(mention => {
            //     let m = mention.split("/");
            //     let info = m[4];
            //     let userMention = info.split("\">");
            //     userMention[1] = userMention[1].replace('<', '');
            //     if (userMention[0] !== "") {
            //         array = [...array, { name: userMention[1].replace('@', ''), id: Number(getIdWithoutCripto(userMention[0])), modal: false }]
            //     }
        
            // setMentions(array);
        
    }

    const getUsers = async () => {
        const response = (await api.get('/users?usuarios')).data;
        response.map(user => {
            usuarios.push({
                id: '@' + user.name.split(' ').join(' '), //Substitui o espaço em branco por um caractere invisível
                userId: user.id,
                name: user.name,
                link: `https://painel.merx.app.br/perfil?id=${setIdWithCripto('user', user.id)}`
            })
        })
        setUsers(usuarios);

    };

    const updateComment = async e => {
        e.preventDefault();

        const response = (await api.put(`/comments/${comment.id}`, { content, pathImg })).data;

        log('editou um comentário em um post no feed.')

        // recarregando comentário
        if (idImg !== '') {
            await relacionarImg(comment.id);
        }

        markUsers(comment.id);
        setHasChangesModal(false);
        setHasChangesOnModal(false);
        reload();
        closeModal();
    };

    const relacionarImg = async (id_comment) => {
        const response = (await api.put(`/upload/${idImg}`, {
            id_comment,
            id_post,
        }));
    };

    const getFotoPerfil = async () => {
        const response = (await api.get(`/users/${sessionGet('idUser')}`)).data;

        if (response.id_file) {
            setHasPhoto(true);
            setImgPerfil(response.files.path);
        } else {
            setHasPhoto(false);
            setImgPerfil(Default);
        }
    };

    const upload = async files => {

        if (files) {
            const dados = new FormData();

            dados.append('modulo', 'comments_feed');

            new Compressor(files[0], {
                quality: 0.6,
                convertSize: 0,
                async success(result) {
                    dados.append('file', result, result.name);
                    const response = (await apiUpload.post('/upload', dados)).data;

                    setPathImg(response.path);
                    // ckEditor.editing.view.focus();
                    setIdImg(response.id);
                    setLoading(false);
                }
            });
        }
    };

    const deleteImage = async (id) => {
        const response = (await apiUpload.delete(`/upload/${id}/${pathImg}`)).data;
        const sResponse = (await api.put(`/comments-delete-image/${comment.id}`)).data;

        setIdImg('');
        setPathImg('');
        const el = document.getElementById('fileinput');
        if (el) {
            el.value = null;
        }

        setIdImg('');
        setPathImg('');
        ckEditor.editing.view.focus();
    };

    const getMentions = async () => {
        const response = (await api.get(`/marks/comment/${comment.id}`)).data;

        var marks = [];
        var modalMarks = []

        response.marcacoes.map(mark => {
            if (mark.modal === true) {
                modalMarks.push(mark.user);
            } else {
                marks.push(mark.user)
            }
        });

        setMentions(marks);
        setMarkedUsers(modalMarks);
    };

    const markUsers = async id_comment => {

        let newArrayMarkUsers = [];

        markedUsers.map(mark => {
            newArrayMarkUsers.push({
                id: mark.id,
                name: mark.name,
                modal: true
            })
        })

        mentions.map(mark => {
            newArrayMarkUsers.push({
                id: mark.id,
                name: mark.name,
                modal: false
            })
        })

        const response = (await api.post('/marks', {
            id_comment,
            users: newArrayMarkUsers,
            type: 'comment',
            id_post
        })).data;
    }

    /*const tratarString = () => {
        // Remove a tag <p> e </p> da string principal
        const str = content.replace('<p>', '').replace('</p>', '');

        // Seleciona tudo o que tem entre um @ e um espaço, ou seja, vai selecionar o nome a ser marcado
        const subStr = str.split('@').pop().split(' ')[0];

        // Verifica se o primeiro elemento da string é um @
        if (str.charAt(0) == '@') {
            setNameSearch(subStr);
            setIsMarked(true);
            setShowModalMarcacoes(true);
        } else {
            // Verifica se há um espaço antes ou depois do @, isso é necessário para sabermos se o que foi digitado é uma marcação, um e-mail ou somente o caractere @ sozinho
            if (!str.match(/ @/) || str.match(/@ /) || str.match("@&nbsp;")) {
                setShowModalMarcacoes(false);
                setIsMarked(false);
            } else {
                if (isSearch) {
                    setNameSearch(subStr);
                    setIsMarked(true);
                    setShowModalMarcacoes(true);
                }
            }
        }
        setIsSearch(true);
    }*/

    let mark = false;

    const trocarNomeMarcacao = (id, nome) => {
        mark = true;
        const str = content.replace('<p>', '').replace('</p>', '');
        const subStr = str.split('@')[0];
        const newStr = subStr.replace(subStr, `${subStr} <a href='/conversas/${id}'>${nome}</a>&nbsp;`);
        setIsSearch(false);
        setContent(newStr);
    }

    //FUNÇÃO DO PRÓPERIO CKEDITOR PARA LISTAR OS USUÁRIOS NO MODAL DE MENÇÃO
    function getFeedItems(queryText) {
        return new Promise(resolve => {
            setTimeout(() => {
                const itemsToDisplay = usuarios
                    .filter(isItemMatching)
                    .slice(0, usuarios.length);
                resolve(itemsToDisplay);
            }, 100);
        });

        function isItemMatching(item) {
            const searchString = queryText.toLowerCase();
            return (
                item.name.toLowerCase().includes(searchString) ||
                item.id.toLowerCase().includes(searchString)
            );
        }
    }

    //FUNÇÃO SAIR COM ESC
    const pressEsc = (e) => {
        if (e.key === 'Escape') {
            showSwal();
        }
    }

    const clickToExit = (e) => {
        showSwal()
    }

    const showSwal = () => {
        if (hasChangesModal) {
            Swal.fire({
                title: 'Atenção',
                text: 'Você possui alterações não publicadas, continuar mesmo assim?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Descartar',
                cancelButtonText: 'Não'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    if (idImg !== '') {
                        deleteImage(idImg, pathImg)
                    }
                    closeModal();
                }
            });
        } else {
            closeModal();
        }
    }

    const handlePasteUpload = async (imageData) => {
        if (previewRef.current === null) {
            setLoading(true);
            upload(imageData);
        }
    }

    return (
        <Container>
            <Content onKeyUp={(e) => { pressEsc(e) }}>
                {showMentions && <FormMentionsFeed markedUsers={markedUsers} setMarkedUsers={setMarkedUsers} setShowMentions={setShowMentions} />}
                <div className="head">
                    <h1>Editar comentário</h1>
                    <button className="btn-close" onClick={showSwal}>
                        <RiCloseLine />
                    </button>
                </div>
                <div className="body">
                    <div className="autor">
                        <img src={comment != null && comment.autor.files ? getFiles(comment.autor.files.path) : Default} />

                        <div className="desc">
                            <strong>{comment.autor.name}</strong>
                            {<small>{format(new Date(comment.createdAt), 'dd/MM/yyyy - HH:mm')}</small>}
                        </div>

                    </div>
                    <form onSubmit={updateComment}>
                        <div className="content-form">
                            <Dropzone accept="image/*" onDrop={files => upload(files)} noClick={true}>
                                {({ getRootProps, getInputProps, isDragActive, isDragReject, open }) => (
                                    <section>
                                        <div {...getRootProps()}>
                                            {(!isDragActive) ?
                                                <NEditorTexto
                                                    content={content}
                                                    setContent={setContent}
                                                    type={type}
                                                    SetCkEditor={SetCkEditor}
                                                    getFeedItems={getFeedItems}
                                                    handlePasteUpload={handlePasteUpload}
                                                /> : (isDragReject) ?
                                                    <DropzoneContainer isDragActive={isDragActive} isDragReject={isDragReject}>
                                                        <p className="file-reject">Arquivo não suportado</p>
                                                    </DropzoneContainer> :
                                                    <DropzoneContainer isDragActive={isDragActive} isDragReject={isDragReject}>
                                                        <p>Solte os arquivos aqui</p>
                                                    </DropzoneContainer>
                                            }
                                            <input id="fileinput" type="file" {...getInputProps()} multiple />
                                        </div>
                                    </section>
                                )}
                            </Dropzone>

                            {showModalMarcacoes && <ModalMentionsFeed markedUsers={markedUsers} setMarkedUsers={setMarkedUsers} search={nameSearch} newSearch={trocarNomeMarcacao} setShowModalMarcacoes={setShowModalMarcacoes} />}

                            <ListMarkedUsers mentions={mentions} markedUsers={markedUsers} setMarkedUsers={setMarkedUsers} />

                            {(loading) &&
                                <LoadingWrap><FaRedo className="fa-spin" /><h3>Carregando...</h3></LoadingWrap>
                            }
                            {(pathImg != '' && pathImg != null) &&
                                <div className="image-preview" ref={previewRef}>
                                    <div className="content-image-preview">
                                        <img className="img-preview" src={getFiles(pathImg)} />
                                        <button className="btn-delete-img" type="button" onClick={() => deleteImage(idImg, comment.path_img)}><RiCloseLine /></button>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className="wrap-utils">
                            {(pathImg == '' || pathImg == null) && (
                                <button onClick={() => document.getElementById('fileinput').click()} type="button">
                                    <div className="icon">
                                        <RiImageLine />
                                    </div>
                                    <span>Adicionar imagem</span>
                                </button>
                            )}
                            <button onClick={() => setShowMentions(true)} type="button">
                                <div className="icon">
                                    <TiUserAddOutline />
                                </div>
                                <span>{markedUsers.length > 0 ? 'Editar marcações' : 'Incluir marcações'}</span>
                            </button>
                        </div>
                        <button id="button-post" className="form-button" type="submit"><text>Salvar alterações</text></button>
                    </form>
                </div>
            </Content>
            <AreaToClose onClick={(e) => { clickToExit(e) }} />
        </Container>
    );
}

export default ModalCommentPost;
import React, { useState, useEffect } from 'react';
import { RiCloseLine, RiImageLine } from 'react-icons/ri';
import { MdClose } from 'react-icons/md';

import log from '../../util/log';

import socket from '../../services/socket';
import Swal from 'sweetalert2';

import api from '../../services/api-geral';

import CInput from '../ComponentInput';
import CSelect from '../ComponentSelect';
import CButton from '../ComponentButton';

import CardStatus from '../../components/CardStatus';
import { BackdropCenter } from '../../styles/content';

import { WrapForm, Content, Cards } from './styles';

export default function ModalStatus({ setShowModalStatus, custom }) {

  const [listaStatus, setListaStatus] = useState([]);
  const [listaPersonalizada, setListaPersonalizada] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [statusAtual, setStatusAtual] = useState('');
  const [visibility, setVisibility] = useState();

  const getStatus = async () => {
    const response = (await api.get('/status')).data;

    for (let i = 0; i < response.length; i++) {
      let statusIcon = '';
      if (response[i].flag_default == 1) {
        switch (response[i].status_color) {
          case "online":
            statusIcon = '🟢 ';
            break;
          case "ausente":
            statusIcon = '🟡 ';
            break;
          case "naoPerturbe":
            statusIcon = '🔴 ';
            break;
        }
      } else { statusIcon = '⠀⠀⠀' }

      let jsonData = {
        "id": response[i].id,
        "status": statusIcon + response[i].status,
        "status_color": response[i].status_color,
      };
      listaPersonalizada.push(jsonData);
    }
  };

  const getStatusAtual = async () => {
    const response = (await api.get('/status-atual')).data;
    if (response !== null) {
      let statusIcon = '';
      switch (response.status_color) {
        case "online":
          statusIcon = '🟢 ';
          break;
        case "ausente":
          statusIcon = '🟡 ';
          break;
        case "naoPerturbe":
          statusIcon = '🔴 ';
          break;
      }
      let statusAtual = statusIcon + response.status;

      setStatusAtual(statusAtual);
    } else {
      const changeStatusToDefault = await api.put('/default-status');
      setStatusAtual('🟢 Online');
    }
  };

  const selecionarStatusAtual = (idFromInput, index) => {
    if (idFromInput === statusAtual) {
      document.getElementById(index).checked = true;
    }
  }

  const visibilidadeDeletar = async () => {
    const response = (await api.get('/status-user')).data;
    if (response.length != 0) {
      setVisibility(true);
    } else {
      setVisibility(false)
    }
  }

  useEffect(() => {
    visibilidadeDeletar();
    getStatus();
    getStatusAtual();
  }, []);

  const changeStatus = async (e, index) => {
    setSelectedStatus(e);
    Swal.fire({
      title: 'Sucesso!',
      text: 'Status alterado com sucesso!',
      icon: 'success',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok'
    });
    if (statusAtual !== e) {
      const response = await api.put('/select-status', { id: e });
      socket.emit('customStatusChanged');
    }
  }

  const closeModal = () => {
    setShowModalStatus(false)
  }

  return (
    <BackdropCenter className={window.location.pathname !== '/pagina-empresarial' ? (custom && 'alter') : ('alter-no-background')}>
      <Content>
        <WrapForm>
          <div className="form-body">
            <div className='form-body-header'>
              <span className="subtitle">Selecionar status</span>
              <CButton
                cstyle='default small closebutton'
                title={(<MdClose />)}
                click={closeModal}
              />
            </div>
            <div className="status-box">
              <CSelect cstyle='custom-status' label='Alterar status' val={selectedStatus} change={e => changeStatus(e)} items={listaPersonalizada} customCase={true} customSelectedValue={statusAtual} />
            </div>
            <Cards>
              <CardStatus
                id={0}
                title={'Criar status'}
                setShowModalStatus={setShowModalStatus}
              />
              {(visibility) &&
                <CardStatus
                  id={1}
                  title={'Remover status'}
                  setShowModalStatus={setShowModalStatus}
                  deletar={true}
                />
              }
            </Cards>
          </div>
        </WrapForm>
      </Content>
    </BackdropCenter >
  );
}

import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import log from '../../../util/log';
import ApiGeral from '../../../services/api-geral';

import Swal from 'sweetalert2';

import { WrapForm } from './styles';

import CInput from '../../ComponentInput';
import CButton from '../../ComponentButton';

import AlertaMerx from '../../AlertaMerx';

export default function Form({ item, success, close }) {
  const [name, setName] = useState('');
  const [showAlertMerx, setShowAlertMerx] = useState(false);
  const [msgAlertMerx, setMsgAlertMerx] = useState('');

  useEffect(() => {
    setName(item.name);
  }, [item]);

  const submitForm = async e => {
    e.preventDefault();

    if (item.id) {
      const response = (await ApiGeral.put(`/formulario-assunto/${item.id}`, { name })).data;

      if (response.type === "success") {
        Swal.fire({
          title: 'Sucesso!',
          text: response.msg,
          icon: 'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok'
        })
        log(`editou as informações do assunto com nome ${name}.`);
        success();
        close();
      } else {
        setMsgAlertMerx(response.msg);
        setShowAlertMerx(true);
        setTimeout(() => { setShowAlertMerx(false) }, 5000)
        log(`tentou editar as informações do assunto com nome ${name}, mas houve um erro.`);
      }
    } else {
      const response = (await ApiGeral.post('/formulario-assunto/', { name })).data;

      if (response.type === "success") {
        log(`cadastrou um novo assunto com nome ${name.toUpperCase()}. (ID: ${response.assunto.id})`);
        success();
        close();
      } else {
        setMsgAlertMerx(response.msg);
        setShowAlertMerx(true);
        setTimeout(() => { setShowAlertMerx(false) }, 5000)
        log(`tentou cadastrar um novo assunto mas houve um erro.`);
      }
    }
  };

  return (
    <WrapForm onSubmit={submitForm}>
      <div className="form-header">
        <h2>{`${(item.id) ? 'Edição' : 'Cadastro'} de assunto`}</h2>
      </div>
      <div className="form-body">
        <CInput val={name} change={e => setName(e)} type='text' label='Nome' required={true} />
      </div>
      <div className="form-footer">
        <CButton title='Salvar' cstyle='primary small' />
      </div>
    </WrapForm>
  );
}

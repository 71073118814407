import styled from 'styled-components';

import colors from '../../../presets/colors';
import effects from '../../../presets/effects';

export const Container = styled.div`
    width: 80%;
    height: 98%;
    box-shadow: 0 1px 15px rgb(0 0 0 / 20%), 0 1px 6px rgb(0 0 0 / 20%);
    border-radius: 10px;
    box-align: center;
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    position: absolute;
    padding-bottom: 10px;
    justify-content: center;

    ::-webkit-scrollbar {
      width: 4px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
      background: ${colors.border};
      border-radius: ${effects.radius};
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

  &.perfil {
    border: 0px;
  }

  @media (max-width: 1280px) {
      width: 70%;
  }
  @media (max-width: 1150px) {
      width: 75%;
  }
  @media (max-width: 835px) {
      width: 85%;
  }
  @media (max-width: 600px) {
      width: 90%;
      height: 100%;
      overflow-x: hidden;
      overflow-y: hidden;
  }
  @media (max-width: 500px) {
      width: 99%;
  }

`;

export const Emoji = styled.div`
    width: 300px;
    background-color: white;
    position: absolute;
    right: 7%;
    top: 23%;
    z-index: 1500;
    box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,0.075);
    border: 1px solid rgba(48,48,48,.15);
    div{
      display: flex;
      flex-direction: row-reverse;

        button{
        border: none;
        background-color: white;
        margin-right: 5px;
        font-weight: 600;
        cursor: pointer;
        }
    }
    ul{
      display: flex;
      flex-wrap: wrap;
      list-style-type: none;
      gap: 3px;
      padding: 5px;
        li{
          cursor: pointer;
        }
      }
    
`;

export const AreaToClose = styled.div`
  width: calc(100% - 110px);
  height: calc(100% - 90px);
  position: fixed;
  right: 7%;
  top: 23%;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .wrap-content{
    display: flex;
    justify-content: center;

    .wrap-for-merxgratis{
      display: flex;
      justify-content: space-between;
        @media (max-width: 600px) {
          margin-top: 2rem;
      }
    }
  }

  .wrap-notes{
    display: flex;
    justify-content: center;

      @media (max-width: 600px) {
        width: 100%;
        height: 100%;
    }
  }


`;

export const Title = styled.p`
  font-size: 18px;
  margin-left: 5px;

`;

export const NoteContainer = styled.div`
  width: 90%;
  padding-top: 1rem;

  .text-input {
    width: 100%;     
    display: flex;
    align-items: center;
    margin-top: 0.5rem;

    .text{
        resize: none; 
        overflow-y: hidden; 
        height: auto; 
        margin-left: 1rem;
        padding: 10px;
        padding-right: 1.5rem;
        border: 1px solid rgba(48,48,48,.15) ;
        border-radius:10px ;
        font: 15px 'Poppins',sans-serif;
        width:110%;
        display: flex;           
    }

    button{
      background-color: white;
      border: none;
      position: relative;
      right: 1.5rem;
  }
}


  .footer{
    margin: 1rem 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0px 2rem;
    gap: 2rem;

    @media (max-width: 1300px) {
      display: flex;
      justify-content: space-around;
    }

    @media (max-width: 600px) {
      display: flex;
      flex-direction: column;
       gap: 0rem;
    }

    .time{
      width: 50%;
        @media (max-width: 600px) {
          width: 15rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin: auto;
        }

      .date-wrap{
        display: flex;
     
        @media (max-width: 1300px) {
        display: flex;
        flex-direction: column;
       }
       .date-container{
        display: flex;
     

        label{
        color: #727272;
        display: flex;
        align-items: center;
        .info-wrap{
          display: flex;
          align-items: center;
          svg{
          margin: 0px 5px;
          cursor: pointer;
         }

        .date-info{
          display: none;
          background-color: ${colors.bgprimary};
          box-shadow: ${effects.boxshadowsm};
          border: 1px solid ${colors.border};
          padding: 12px;
          margin-right: 5px;
          position: absolute;
          transform: translate(15%, 45%);
          z-index: 1501;
          max-width: 170px;
          p{
            font-size: 12px;
       
         }
       }

            &:hover{
              .date-info{
              display: flex;
            }
          }
        }


      }
      .input-date{
        width: 8rem;
        padding:3px;
        border: 1px solid #c4c4c4;
        border-radius: 5px;
        margin-right: 1rem;

        @media (max-width: 1300px) {
          width:10rem;
       }
       @media (max-width: 1000px) {
          width:80%;
       }
       @media (max-width: 600px) {
        width: 100%;
    
    }
      }
      @media (max-width: 1320px) {
        display: flex;
        flex-direction: column;
       }
        
       }
      }


    }
    .wrap{ 
      display: flex;
      height: auto;
      justify-content: space-around;
      flex-direction: row;
      width: 50%;

        @media (max-width: 1300px) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 15rem;  
        }

        @media (max-width: 600px) {
            width: 15rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: auto;
          }

        .type{
          width: 50%;
          margin-right: 1rem;
          margin-top: 2rem;
          display: flex;
          flex-direction: column;
          justify-content: center;   
        
          @media (max-width: 1300px) {
          width: 100%;
          }
          @media (max-width: 600px) {
            width: 100%;
            margin-top: 1rem;
          }
          & > div{
            margin-top: 1rem;          
        }

          input{
            padding: 5px;
            border: 1px solid rgba(48,48,48,.15) ;
            border-radius:10px ;
            margin: auto;
            margin-top: 5px;
            margin-bottom: 5px;

            width: 90%;

            display: flex; 
          }

          .input-nada{
            background-color: transparent;
            border: transparent;
          }
      }

      .button-div{
        display: flex;
        justify-content:center ; 
        margin-top: 2rem;
        @media (max-width: 1300px) {
          display: flex;
          flex-direction: column;
          justify-content: center; 
          margin-top: 0rem;
        }
    
        button {
          border: none;
          color: white;
          width: 7rem;
          height: 2rem;
          border-radius: 5px;
          font-size: 16px;
          margin: auto;
  
          @media (max-width: 600px) {
              margin: auto;
              margin-top: 0.5rem;
            }
  
        }
        .create-button{
          background-color: #8a2387;
          opacity: 1;
          cursor: pointer;
        }
        .disabled{
          background-color: #8a2387;
          opacity: 0.5;
          cursor: not-allowed;
        }

      }

    }

  }


`;

export const Noteviewer = styled.div`
 width: 90%;
 display: flex;
 flex-direction: column;
 padding: 0.5rem;
 height: 15rem;
 overflow-y: scroll;
 
 .container{
    padding:  0.5rem;
    width: 100%;
    height: auto;
    background-color: #f4f4f6;
    border-radius: 10px;
    box-shadow: ${effects.boxshadow};
    margin-bottom: 15px;
    position: relative;
    display: flex;
    align-items: center;
  }

  .icon{
    width: 10%;
    background-color: ${colors.primary};
    width: 36px;
    height: 30px;
    color: white;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 0.5rem;
    margin-left: 0;
      
    img{
        height: 21px;
        width: 16px;
    }
    svg{
        color: white;
    }

    @media (max-width: 600px) {
      width: 38px;
      height: 30px;
    }
  }

  .content{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    overflow: hidden; 

    .title{
      text-transform: uppercase;
      margin-right: 5px;
      color: ${colors.primary};
      font-size: 12px;
      width: auto;
    }
    .note-text{
        flex-wrap: wrap;
         overflow-wrap: break-word; 
      }

          

            
    .footer{
      display: flex;
      justify-content: space-between;

      @media (max-width: 600px) {
           display: flex;
           flex-direction: column;
       }

      .author-name{
        color: #555;
        font-size: 12px;
        font-weight: 300;
      }

      .date{
        display: flex;

        small{
          color: #555;
          font-size: 12px;
          font-weight: 300; 
          margin-left: 5px;
        }
      }
    }
  }

  

  .buttons {
    display: flex;
    background: white;

    .btn-edit {
      width: 30px;
      height: 30px;
      background-color: #f4f4f6;
      border: 0;
      color: ${colors.primary};
      display: flex;
      align-items: center;
      place-content: center;

      svg {
        font-size: 18px;
      }
    }

    .btn-delete {
      width: 30px;
      height: 30px;
       background-color: #f4f4f6;
      border: 0;
      color: ${colors.primary};
      display: flex;
      align-items: center;
      place-content: center;

      svg {
        font-size: 25px;
      }
    }
  }



`;



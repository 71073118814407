import styled from 'styled-components';

import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Container = styled.div`
    width: 55%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const Head = styled.div`
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-shadow: ${effects.boxshadow};
    border-radius: 10px;

    .topbar {
        background-color: white;
        width: 100%;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid ${colors.border};

        img {
            height: 30px;
        }

        .btn-deslogar {
            background-image: ${colors.instagram};
            height: 30px;
            border: 0;
            padding: 0 10px;
            border-radius: 10px;
            box-shadow: ${effects.boxshadow};
            color: white;
        }
    }


    .info {
        background-color: white;
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 20px 0;

        img {
            width: 125px;
            height: 125px;
            border-radius: 50%;
            margin-right: 50px;
        }

        .desc {
            margin-left: 50px;

            h1 {
                margin-bottom: 10px;
                font-weight: normal;
            }

            ul {
                list-style: none;
                padding: 0;
                display: flex;

                li {
                    margin-right: 20px;
                }
            }

            h3 {
                margin-top: 10px;
            }
        }
    }
`;

export const Body = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    .post {
        width: 30%;
        height: 300px;
        position: relative;
        border: 1px solid ${colors.border};
        cursor: pointer;

        img {
            width: 100%;
            height: 100%;
        }

        ul {
            display: flex;
            justify-content: center;
            width: 100%;
            height: 30px;
            background-color: rgba(0, 0, 0, 0.4);
            position: absolute;
            left: 0;
            padding: 0;
            list-style: none;
            bottom: 0;

            li {
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    color: white;
                    font-size: 20px;
                    margin-right: 10px;
                }

                span {
                    color: white;
                }
            }
        }
    }
`;
import React, { useEffect, useState } from 'react';
import api from '../../services/api-geral';
import {useParams} from "react-router-dom";


import history from '../../services/history';
import { toast } from 'react-toastify';
import {Body} from './styles'

import Rating from '@material-ui/lab/Rating';

import Logo from '../../assets/images/logotask.png'

export default function PesquisaSatisfacao({ history }) {

    const [valorRating, setValorRating] = useState(2);
    const [comentarioAtendimento, setComentarioAtendimento] = useState('') 
    const [dadosAtendimento, setDadosAtendimento] = useState({});
    const [avaliacaoFinalizada, setAvaliacaoFinalizada] = useState(false) ;

    
    const {data} = useParams();
    useEffect(() =>{
        setDadosAtendimento(JSON.parse(atob(data)));

    },[])

    const validaComentarioAtendimento = (value) =>  {

        if(value.length <= 250){
            setComentarioAtendimento(value);
        }
    }

    const finalizarAvaliacao = async () => {
        const dados = {
            avaliacao: valorRating,
            comentario: comentarioAtendimento
        };
        const responseFinalizacaoAvaliacao = await api.post(`/avaliacaoAtendimento/${dadosAtendimento.idAtendimento}`, dados).data;
        setAvaliacaoFinalizada(true);
       
    }

  return (
      <Body>
          {(avaliacaoFinalizada) ? 
          (
            <div className="texto">
                <p className="um">Obrigado por responder a avaliação do seu atendimento!</p>
            </div>
          )
          :
          (
            <>
            <div className="texto">
                <p className="um">Olá, {dadosAtendimento.nomeContato}!</p>
                <p className="dois">Foi um prazer lhe ajudar aqui na {dadosAtendimento.nomeEmpresa}.</p>
                <p className="tres">Gostaríamos de ouvir sua opinião sobre nosso atendimento.</p>
            </div>
            <div className="input-avaliacao">
                <p>No geral, de 0 a 5, qual nota você daria ao nosso atendimento?</p>
                <Rating
                    name="simple-controlled"
                    value={valorRating}
                    onChange={(event, newValue) => {
                        setValorRating(newValue);
                    }}
                />
                <textarea placeholder="Descrição (Opcional)" type="text" rows="5" cols="50" value={comentarioAtendimento} onChange={e => validaComentarioAtendimento(e.target.value) }/>
                <p>{comentarioAtendimento.length}/250</p>
                <button onClick={() => finalizarAvaliacao()}> Finalizar avaliação </button>
            </div>
            </>
          )}
      </Body>
    
  );
}

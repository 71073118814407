import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

class Alerta {
  customSwal = withReactContent(Swal);

  success = (title, text) => {
    this.customSwal.fire({
      title: `<small>${title}</small>`,
      text,
      icon: 'success',
      showConfirmButton: false,
      timer: 2000,
    });
  };

  error = (title, text) => {
    this.customSwal.fire({
      title: `<small>${title}</small>`,
      text,
      icon: 'error'
    });
  };

  warning = (title, text) => {
    this.customSwal.fire({
      title: `<small>${title}</small>`,
      text,
      icon: 'warning'
    });
  };

  info = (title, text) => {
    this.customSwal.fire({
      title: `<small>${title}</small>`,
      text,
      icon: 'info'
    });
  };
}

export default new Alerta();
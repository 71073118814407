import React from 'react';

import { Container, Button } from './styles';

import CInput from '../../../components/ComponentInput';

import { MdClose } from 'react-icons/md';

function ModalEdit({ setShowEdit, id, url, setUrl, editText, setText, type, submit }) {
  return (
    <Container>
      <div className="modal">
        <Button onClick={() => { setShowEdit(false) }}><MdClose /></Button>
        <div className="head">
          <h2>{type === 'Links' ? 'Editar link' : 'Editar rede social'}</h2>
        </div>
        <div className="content">
          <CInput classCustom="company-phone" val={editText} change={e => setText(e)} type='text' label='Link' style='radius' focused={true} />
          {type === 'Links' && (
            <CInput classCustom="company-phone" val={url} change={e => setUrl(e)} type='text' label='Endereço (URL)' style='radius' focused={true} />
          )}
        </div>
        <div className="save">
          <button onClick={() => { submit() }}>SALVAR</button>
        </div>
      </div>
      <div className="backdrop" onClick={() => { setShowEdit(false) }}></div>
    </Container>
  );
}

export default ModalEdit;
import React, { useEffect, useState } from 'react';
import { RiMoreLine, RiCloseLine, RiEditLine, RiDeleteBin6Line, RiThumbUpLine, RiThumbUpFill, RiDiscussFill, RiMore2Line } from 'react-icons/ri';
import ReactHtmlParser from 'react-html-parser';

import SocketLikesFeed from '../../../socket/SocketLikesFeed';

import { sessionGet } from '../../../session';

import api from '../../../services/api-geral';

import Default from '../../../assets/default.png';

import getFiles from '../../../util/getFiles';
import translateDate from '../../../util/translateDate';
import { setIdWithCripto } from '../../../util/formatParamsUrl';
import log from '../../../util/log';

import NModalViewLike from '../../NModalViewLike';
import MentionsFeed from '../../MentionsFeed';
import ModalConfirma from '../../ModalConfirma';
import ModalUser from '../../ModalUser';
import Editor from 'ckeditor5-custom-build/build/ckeditor';


import { Container, Head } from './styles';

function Comment({ comment, editar, deletar, openImg, autorName, bellRing, reload}) {
    const [showOptions, setShowOptions] = useState(false);
    const [idImg, setIdImg] = useState('');
    const [pathImg, setPathImg] = useState('');

    const [showModalVL, setShowModalVL] = useState(false);
    const [confirmaDelete, setConfirmaDelete] = useState(false);
    const [markedUsers, setMarkedUsers] = useState([]);

    const [likes, setLikes] = useState([]);
    const [liked, setLiked] = useState(false);
    const [countLikes, setCountLikes] = useState(0);

    const [editado, setEditado] = useState(false);
    const [commentBellRing, setCommentBellRing] = useState(true);
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const editComment = () => { editar({ id: idImg, path: comment.path_img }); setShowOptions(false); }
    const deleteComment = () => {
        // console.log('componente comment', comment)
         deletar(); setShowOptions(false); };

    const handleComments = () => {
        const currentIdUSer = sessionGet('idUser');
        if(currentIdUSer === comment.id_autor){
            return
        }else{
            // console.log("comment.autor", comment.id_autor)
            autorName(comment.autor.name);
            setCommentBellRing(!commentBellRing)
            bellRing(commentBellRing);

            const editorTextoCommentDiv = document.getElementById('editorTextoComment');
     
            
        }
    }

    useEffect(() => { getLikes(); getMentions(); }, [comment]);
    useEffect(() => { verifyLike() }, [likes]);
    useEffect(() => { getImage(comment.id); }, [comment]);

    useEffect(() => {
        //comment.content = comment.content.replace(/&nbsp;/g, ' ');
        comment.content = comment.content.replace(/<p> <\/p>/g, '<p>&nbsp;</p>');
        comment.content = comment.content.replace(/<br> <\/p>/g, '<br>&nbsp;</p>');

        var aux = comment.content.split(' ');

        aux.map((word, index) => {
            if (word.includes('https://')) {
                if (word.includes('href="')) {
                    var part1 = word.split('">');

                    var part2 = part1[1].split('</a>');

                    if (part2[0].length > 30) {
                        var res = part2[0].substr(0, 30) + "...";
                        const text = part1[0] + '">' + res + "</a>" + part2[1];
                        aux[index] = text;
                    }
                } else if (!word.includes('youtube.com') && !word.includes('youtu.be')) {
                    var link = '';
                    var pre = '';
                    var auxPos = '';
                    var pos = '';
                    var href = '';

                    pre = word.split('https');
                    auxPos = pre[1];
                    pre = pre[0];

                    auxPos = auxPos.split('<');

                    link = 'https' + auxPos[0];
                    href = link;

                    for (let i = 1; i < auxPos.length; i++) {
                        pos = pos + '<' + auxPos[i];
                    }

                    if (link.length > 30) {
                        link = link.substr(0, 30) + "...";
                    }

                    var finalLink = `<a target="_blank" rel="noopener noreferrer" href="${href}">${link}</a>`;

                    finalLink = finalLink.replace(/&nbsp;/g, '');

                    aux[index] = pre + finalLink + pos;
                }
            } else if (word.includes('href=') && word.includes('conversas') && word.includes('@')) {
                //const replacer = word.replace('@', '');
                //aux[index] = 'class="marcacao-link" ' + replacer;
            }
        })

        var textFinal;

        aux.map((word, index) => {
            if (index == 0) {
                textFinal = word;
            } else {
                textFinal = textFinal + " " + word;
            }
        })

        comment.content = textFinal;
    }, []);

    const getImage = async id => {
        const response = (await api.get(`/upload/comments_feed/${id}`)).data;
        if (response.length > 0) {
            setIdImg(response[0].id);
            setPathImg(comment.path_img);
        }
    };


    const like = async id => {
        const response = await api.post('/likes_comment', {
            id_comment: id
        });

        if (response.data.func == 'like') {
            setLiked(true);
            log('curtiu um comentário de um post no feed.')
        } else {
            setLiked(false);
            log('removeu a curtida de um comentário de um post no feed.')
        }

        getLikes();
    }

    const getLikes = async (method) => {

        if (method === 'socket') {
            const response = (await api.get(`/likes_comment/${comment.id}`)).data;

            setLikes(response.likes);
            setCountLikes(response.count);
        } else {
            if (comment.likes) {
                setLikes(comment.likes);
                setCountLikes(comment.likes.length);
            }
        }
    }

    const verifyLike = () => {
        setLiked(false);

        likes.map(like => {
            if (like.user.id === sessionGet('idUser')) {
                setLiked(true);
            }
        });
    }

    const getMentions = async () => {
        var array_marks = [];

        comment.marks.map(mark => {
            array_marks.push({ ...mark.user, modal: mark.modal });
        });

        setMarkedUsers(array_marks);
    };

    const markRead = async () => {
        await api.put(`/markread/${comment.id}`, {
            method: 'likecommentfeed'
        });
    }

    useEffect(() => {
        if (comment.createdAt === comment.updatedAt) {
            setEditado(false);
        } else {
            setEditado(true);
        }
    }, [])

    return (
        <>
            <SocketLikesFeed getLikes={getLikes} idComment={comment.id}></SocketLikesFeed>
            <SocketLikesFeed getLikes={getLikes} idComment={comment.updatedAt}></SocketLikesFeed>
            <Container id={comment.id}>
            <div className='content-wrapper'>
              <img src={comment.autor.files ? getFiles(comment.autor.files.path) : Default} />
              <div className='wrap'>
                <div className='content-gray-comment'> 
                <div className="autor">
                    <div className="user-comment">
                        {editado === true
                            ?
                            <strong className="comment-edited"><a href={`/perfil?id=${setIdWithCripto('user', comment.id_autor)}`}>{comment.autor.name}</a>
                            <span> | </span><small>{translateDate(comment.createdAt)}</small>&nbsp;<span>&bull; 
                            Editado {(translateDate(comment.updatedAt)).toLowerCase()}</span></strong>
                            :
                            <strong ><a href={`/perfil?id=${setIdWithCripto('user', comment.id_autor)}`}>{comment.autor.name}</a>
                            <span> | </span><small>{translateDate(comment.createdAt)} </small></strong>
                        }
                        <ModalUser idUser={comment.id_autor} user={comment.autor} photoUser={comment.autor.files} />
                    </div>

                    {comment.id_autor === sessionGet('idUser') && (
                        <Head>
                            <div className="options-comment" onClick={() => setShowOptions(!showOptions)}>
                                <button className="btn-options">
                                    {showOptions ? <RiCloseLine /> :   
                                     <>
                                    {width < 450 ? <RiMore2Line /> : <RiMoreLine />}
                                     </>  
                                     }
                              
                                </button>

                                {showOptions && (
                                    <div className="dropdown">
                                        <div className="option" onClick={editComment}>
                                            <div className="icon">
                                                <RiEditLine />
                                            </div>
                                            <span>Editar comentário</span>
                                        </div>
                                        <hr />
                                        <div className="option" onClick={() => setConfirmaDelete(true)}>
                                            <div className="icon">
                                                <RiDeleteBin6Line />
                                            </div>
                                            <span>Excluir comentário</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {confirmaDelete && (<ModalConfirma type='comentário' setConfirmaDelete={setConfirmaDelete} deletar={() =>{ deleteComment(); setTimeout(() => { reload() }, 100) }} setShowOptions={setShowOptions} />)}
                        </Head>
                    )}
                </div>
                <p>
                    {ReactHtmlParser(comment.content)}
                </p>
                {markedUsers.length > 0 && (
                    <MentionsFeed markedUsers={markedUsers} />
                )}
                {(comment.path_img != '' && comment.path_img !== null) && <img className="img-comment" onClick={() => openImg(getFiles(comment.path_img))} src={getFiles(comment.path_img)} />}
                {showModalVL && <NModalViewLike closeModal={() => setShowModalVL(false)} interactions={likes} type={'like'} />}
               
                </div>
                <div className="actions-on-comment">
                    <div className="left">
                        <div className="cta-like" onClick={() => like(comment.id)}>
                            {liked ? <RiThumbUpFill /> : <RiThumbUpLine />}

                            {liked ? <span>Curtido</span> : <span>Curtir</span>}
                        </div>

                        <div  className="open-comment" onClick={handleComments}>     
                            
                                    <RiDiscussFill/> <span>Comentar</span> 
                        
                        </div>
                    
                    </div>
                    <div className="right">
                        {countLikes > 0 &&   
                            <div className='count-likes'>
                                <span className="likes-comentarios" onClick={() => { setShowModalVL(true); markRead(); }}>{countLikes}</span>
                                <div className='wrap-icon'>
                                    <RiThumbUpFill />
                                </div>
                            </div>
                        }
                            <div className="show-likes-on-comment">
                                <div className="likes-on-comment">
                                    {countLikes > 0 ? (
                                        <>
                                            {likes.map((like, index) => index <= 4 ? <span key={like.id}>{like.user.name}</span> : "")}
                                        </>
                                    ) : <span>Sem curtidas</span>}
                                    {countLikes > 5 && <span>e mais {countLikes - 5} curtidas</span>}
                                </div>
                            </div>
                    </div>
                </div>
                {/* <div className="actions-on-comment">
                    <div className="left">
                        <div className="cta-like" onClick={() => like(comment.id)}>
                            {liked ? <RiThumbUpFill /> : <RiThumbUpLine />}

                            {liked ? <span>Curtido</span> : <span>Curtir</span>}
                        </div>
                    </div>
                    <div className="right">
                        {
                            <div onClick={handleComments}>     
                                <RiDiscussFill/> <span>Comentar</span> 
                            </div>
                        }
                    </div>
                </div> */}
              </div>
            </div>
            </Container>
        </>
    );
};

export default Comment;
import React, { useState, useEffect } from 'react';

import api from '../../services/api-geral';
import { formatMinDate } from '../../util/formats';
import Swal from 'sweetalert2';

import CTable from '../../components/ComponentTable';

import Page from '../../components/Page';
import { Painel } from '../../styles/content';
import { FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";

import { Container } from './styles';
import { Paginas, PaginaButton } from '../../components/BotoesPaginacao/styles';

export default function LogsEmpresas() {
  const [dadosLogs, setDadosLogs] = useState([]);
  const [allLogs, setAllLogs] = useState([]);
  const [offsetLog, setOffsetLog] = useState(0);
  const [totalLogs, setTotalLogs] = useState(0);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [pagesTotal, setPagesTotal] = useState(1);
  const [valueSearch, setValueSearch] = useState('');
  const [relatorio, setRelatorio] = useState([]);
  const [pesquisa, setPesquisa] = useState('name');


  const getLogEmpresas = async () => {
    const response = (await api.get(`empresas-log/${offsetLog}`)).data;
    let dataUltimoPost;
    for (let i = 0; i < response.logs.length; i++) {
      const feedPath = response.logs[i].feed;
      const path = response.logs[i];
      path.plano_escolhido = path.plano_escolhido.charAt(0).toUpperCase() + path.plano_escolhido.slice(1);
      path.created_at = formatMinDate(path.created_at)

      path.state === "New" || path.state === "Update" || path.state === "Ok" ? path.state = "Ativo" : path.state = "Inativo";
      path.page === null ? path.flag_form = "Inativo" : path.page.flag_form === 1 ? path.flag_form = "Ativo" : path.flag_form = "Inativo"
      path.page === null ? path.flag_chat = "Inativo" : path.page.flag_chat === 1 ? path.flag_chat = "Ativo" : path.flag_chat = "Inativo"
      // verificando atividade no feed
      if (feedPath.length > 0) {
        const ultimoPost = new Date(new Date(feedPath[0].createdAt.toString().substring(0, 10)).toISOString().split('T')[0].replace(/-/g, "/"));
        const now = new Date(new Date().toISOString().split('T')[0].replace(/-/g, "/"));
        var timeDiff = Math.abs(now.getTime() - ultimoPost.getTime());
        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        diffDays <= 30 ? path.feed = "Ativo" : path.feed = "Inativo"
      } else
        path.feed = "Inativo"
    }
    setDadosLogs(response.logs);
    setTotalLogs(response.count);
    const pagesCount = calculatePagesCount(10, response.count);
    setPagesTotal(pagesCount);
  };

  useEffect(() => {
    getAllLogs();
  }, [])

  const getAllLogs = async () => {
    const response = (await api.get(`empresas-log-all`)).data;
    for (let i = 0; i < response.logs.length; i++) {
      const feedPath = response.logs[i].feed
      const path = response.logs[i];
      path.plano_escolhido = path.plano_escolhido.charAt(0).toUpperCase() + path.plano_escolhido.slice(1);
      path.state === "New" || path.state === "Update" || path.state === "Ok" ? path.state = "Ativo" : path.state = "Inativo";
      path.page === null ? path.flag_form = "Inativo" : path.page.flag_form === 1 ? path.flag_form = "Ativo" : path.flag_form = "Inativo"
      path.page === null ? path.flag_chat = "Inativo" : path.page.flag_chat === 1 ? path.flag_chat = "Ativo" : path.flag_chat = "Inativo"
      // verificando atividade no feed
      if (feedPath.length > 0) {
        const ultimoPost = new Date(new Date(feedPath[0].createdAt.toString().substring(0, 10)).toISOString().split('T')[0].replace(/-/g, "/"));
        const now = new Date(new Date().toISOString().split('T')[0].replace(/-/g, "/"));
        var timeDiff = Math.abs(now.getTime() - ultimoPost.getTime());
        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        diffDays <= 30 ? path.feed = "Ativo" : path.feed = "Inativo"
      } else
        path.feed = "Inativo"
    }
    setAllLogs(response.logs);
  }

  const calculatePagesCount = (pageSize, totalCount) => {
    // we suppose that if we have 0 items we want 1 empty page
    return totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize);
  };

  useEffect(() => {
    getLogEmpresas();
  }, [offsetLog]);

  const setaOffset = (acao) => {
    if (acao === 'proximo') {
      if (Math.floor(totalLogs / 10) !== (offsetLog / 10)) {
        setOffsetLog(offsetLog + 1);
        setPaginaAtual(paginaAtual + 1);
      }
    }

    if (acao === 'anterior') {
      if (offsetLog > 0) {
        setOffsetLog(offsetLog - 1);
        setPaginaAtual(paginaAtual - 1);
      }
    }
  }

  const refresh = () => {
    getLogEmpresas();
    getAllLogs();
  }

  // relatório dos tipos de contas
  useEffect(() => {
    relatorioTipoConta();
  }, [])

  const relatorioTipoConta = async () => {
    const response = (await api.get(`empresas-log-all`)).data;
    const objeto = [0, 0, 0, 0, 0, 0, 0, 0, 0]
    objeto[4] = null;
    let dataUltimoPost;
    for (var i = 0; i < response.logs.length; i++) {

      const feedPath = response.logs[i].feed

      const estadoConta = response.logs[i].active;
      const tipoPlano = response.logs[i].plano_escolhido;
      const path = response.logs[i];

      // verificando atividade no feed
      if (feedPath.length > 0) {
        const ultimoPost = new Date(new Date(feedPath[0].createdAt.toString().substring(0, 10)).toISOString().split('T')[0].replace(/-/g, "/"));
        const now = new Date(new Date().toISOString().split('T')[0].replace(/-/g, "/"));
        var timeDiff = Math.abs(now.getTime() - ultimoPost.getTime());
        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        diffDays <= 30 ? objeto[6] += 1 : objeto[6] += 0;
      }

      if (estadoConta == 1) {
        objeto[0] += 1;
        path.state === 'New' || path.state === 'Update' || path.state === 'Ok' ? objeto[5] += 1 : objeto[5] += 0;
        if (path.page === null) {
          objeto[7] += 0;
          objeto[8] += 0;
        } else {
          path.page.flag_form === 1 ? objeto[7] += 1 : objeto[7] += 0;
          path.page.flag_chat === 1 ? objeto[8] += 1 : objeto[8] += 0;
        }
        switch (tipoPlano) {
          case 'merxUltra':
            objeto[1] += 1
            break;
          case 'merxGratis':
            objeto[2] += 1
            break;
          case 'merxSuper':
            objeto[3] += 1
            break;
          case 'merxGrátis':
            objeto[2] += 1
            break;
          default:
            Swal.fire({
              title: 'Aviso!',
              text: "Nome dos planos não condizem com os esperados. Os dados do relatório podem ser inverídicos. Atualização necessária!",
              icon: 'error',
              confirmButtonColor: '#992795',
              confirmButtonText: 'Entendido'
            })
        }
      }
    }
    setRelatorio(objeto);
  };

  return (
    <Page content={(
      <Container>

        <Painel className="painel-log">
          <CTable
            ambiente='tabelaLogEmpresa'
            titles={['ID', 'Empresa', 'Página da empresa', 'Plano', 'Cadastro', 'Plano ativo', 'Feed', 'Formulário contato', 'Chat externo']}
            values={dadosLogs}
            allValues={allLogs}
            indexes={['id', 'name', 'endpoint', 'plano_escolhido', 'created_at', 'active', 'feed', 'flag_chat', 'flag_form']}
            indexesSearch={[['name'], ['plano_escolhido']]}
            setValueSearch={setValueSearch}
            load={refresh}
            editable={false}
            noFurtherInformation={false}
            textoRelatorio={"Contagem de usuários:"}
            itensRelatorio={['Total', 'MerxUltra', 'MerxGrátis', 'MerxSuper', 'Relatório de utilização', 'Mini site', 'Feed', 'Formulário', 'Chat externo']}
            relatorio={relatorio}
            titlePage='Registro de cadastro de empresas'
            breadcrumbPage={[{ title: 'Listas' }]}
            emptyPage='Nenhum registro foi encontrado!'
          />

          {dadosLogs.length != 0 && valueSearch === '' &&
            <Paginas>
              <PaginaButton disabled={paginaAtual > 1 ? false : true} onClick={() => { setOffsetLog(0); setPaginaAtual(1) }}>
                <FaAngleDoubleLeft className="icon" size="20px" />
              </PaginaButton>

              <PaginaButton disabled={paginaAtual > 1 ? false : true} onClick={() => setaOffset('anterior')}>
                <FaAngleLeft className="icon" size="20px" />
              </PaginaButton>

              <span>{paginaAtual} de {pagesTotal} {pagesTotal > 1 ? 'páginas' : 'página'}</span>

              <PaginaButton disabled={(paginaAtual < Math.ceil(totalLogs / 10)) ? false : true} onClick={() => setaOffset('proximo')}>
                <FaAngleRight className="icon" size="20px" />
              </PaginaButton>

              <PaginaButton disabled={paginaAtual !== pagesTotal ? false : true} onClick={() => { setOffsetLog((pagesTotal - 1)); setPaginaAtual(pagesTotal) }}>
                <FaAngleDoubleRight className="icon" size="20px" />
              </PaginaButton>
            </Paginas>
          }
        </Painel>
      </Container>
    )} />
  );
}



import styled from 'styled-components';

import colors from '../../presets/colors';

export const Container = styled.div`
  .card-content {
    width: 100%;
    border: solid 0.1rem;
    border-radius: 15px;
    padding: 20px;
    margin-bottom: 20px;
    border-color: rgba(152,152,152,0.35);
    cursor: pointer;

    hr {
      margin-top: 10px;
      border: 1px solid #c6c6c6;
      height: 1px;
    }

    .card-title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h3 {
        font-size: 1.2rem;
        text-align: left;
        color: #5E5E5E;
        font-weight: 500;

        @media (max-width: 900px) {
          font-size: 1rem;
        }
      }

      button {
        border: none;
        background: unset;
        width: 30px;
        height: 30px;
        color: #5E5E5E;
        display: flex;
        align-items: center;
        transition: all .3s ease-in;

        svg {
          background-color: unset;
          font-size: 40px;
        }
      }
    }

    .card-answer {
      color: var(--gray-600);
      text-align: justify;
      max-height: 0;
      overflow-x: hidden;
      overflow-y: hidden;
      transition: all .5s;
      //transition: max-height 0.15s ease-out;

      ul {
        margin-left: 15px;
        list-style: none;
      }

      p {
        font-size: 1rem;
        margin-top: 10px;
        padding-right: 3px;

        @media (max-width: 900px) {
          font-size: 0.9rem;
        }

        &::-webkit-scrollbar {
          width: 3px;
        }

        &::-webkit-scrollbar-thumb {
          background: #e64058; 
        }
      }
    }
  }

  .card-content-active {
    width: 100%;
    border: solid 0.1rem ${colors.primary};
    border-radius: 15px;
    padding: 20px;
    margin-bottom: 20px;

    hr {
      margin-top: 10px;
      border: 1px solid #c6c6c6;
      height: 1px;
    }

    .card-title {
      display: flex;
      place-content: space-between;

      h3 {
        color: ${colors.primary};
        font-weight: bold;
      }

      button {
        transform: rotate(-180deg);
        transition: all .3s ease-in;
        border: none;
        width: 30px;
        height: 30px;
        color: ${colors.primary};
        display: flex;
        align-items: center;

        svg {
          font-size: 40px;
        }
      }
    }

    .card-answer {
      display: block;
      color: black;
      text-align: justify;
      max-height: 400px;
      transition: max-height 0.4s ease-in;

      p {
        //height: 100%;
        max-height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
      }
    }
  }
`;

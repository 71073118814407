import React from 'react';
import { FaYoutube, FaImage, FaTrashAlt } from 'react-icons/fa';

import {Wrap} from './styles';

export default function Preview({ type, title, action }) {
  return (
    <Wrap>
      <div className='box-image'>
        <div className="title">
            {type === 'imagem' ? <FaImage /> : <FaYoutube />}
            <span>{title}</span>
        </div>
        <div className="action" onClick={() => action()}>
            <FaTrashAlt />
        </div>
    </div>
    </Wrap>
  );
}

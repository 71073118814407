import styled from 'styled-components';

import colors from '../../../presets/colors';
import effects from '../../../presets/effects';


export const Container = styled.div`
    width: 100%;
    padding: 10px 0px;
    padding-left: 60px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media (max-width: 450px) {
        width: calc(100% - 25px);
       padding-left: 10px;
    }

    .content-wrapper{
        display: flex;
        width: 100%;
    }

    .wrap{
         width: auto; 
        max-width: 90%;
        display: flex;
        flex-direction: column;
    
    }

    .content-gray-comment{
        background-color: ${colors.bgsecondary};
        min-width:13rem;
        width: auto;
        max-width: 100%;
        border-radius: 20px;
        padding: 10px;

        padding-right: 35px;
          
   

        @media (max-width: 450px) {
          padding-right: 30px;
       
        }
    }

    a {
        text-decoration: none;
        color: #551aa5;
    }

    .img-comment {
        width: fit-content;
        height: 250px;
        max-width: 100%;
        object-fit: cover;
        object-position: center;
        margin-top: 10px;
        cursor: pointer;
        border: 2px solid ${colors.border};
        border-radius: 5px;
    }

    .mention {
        word-break: unset;
        color: #551aa5;
    }

    .autor {
        display: flex;
        align-items: center;
        position: relative;

        .user-comment {
            display: flex;
            align-items: center;
        
            &:hover {
                .modal-user {
                    padding: 10px;
                    display: flex;
                    top: 42px;
                }
            }

            strong {
                a {
                    color: black;
                }
            }
            .comment-edited{
              margin-right: 30px;
            }
        }

        img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin-right: 10px;
            object-fit: cover;
        }

        strong {
            font-size: 14px;

            small {
                font-weight: normal;
                font-size: 12px;
            }

            span {
                color: #878787;
                font-weight: normal;
                font-size: 12px;
            }
        }

        .modal-user {
            display: none;

            p {
                font-size: .9rem;
                margin-top: 0;
                margin-bottom: 5px;
            }

            a {
                font-size: .9rem;
            }
        }
    }

    p {
        flex-wrap: wrap;
        text-align: justify;
      
        font-size: 14px;
        word-wrap: break-word;
    }

    .actions-on-comment {
        width: 100%;
        font-size: 14px;
        display: flex;
        align-items: center;
        /* margin-top: 10px;
        margin-bottom: 10px; */
        justify-content: space-between;
        margin-left: 0px;
        /* background-color: rgba(0, 0, 0, 0.03); */
        padding: 5px 10px;
        border-radius: 10px;
        @media (max-width: 400px) {
          padding: 5px 10px;
     
        }

        .left {
            display: flex;

            .open-comment{
              display: flex;
                  align-items: center;
                  margin-right: 20px;
                  background-color: transparent;
                  border: 0;
                  cursor: pointer;

                  a{
                    color: none;
                    text-decoration: none;
                  }
  
                  &:hover {
                      font-weight: bold;
                  }
                  
                  svg {
                      margin-right: 5px;
                      color: ${colors.primary};
                  }
  
                  span {
                      font-size: 13px;
                      color: ${colors.primary};
                      @media (max-width: 450px) {
                        font-size: 12px;
                    }
                  }
            }
        }

        .cta-like {
            display: flex;
            align-items: center;
            margin-right: 20px;
            background-color: transparent;
            border: 0;
            cursor: pointer;

            &:hover {
                font-weight: bold;
            }
            
            svg {
                margin-right: 5px;
                color: ${colors.primary};
            }

            span {
                font-size: 13px;
                color: ${colors.primary};
                @media (max-width: 450px) {
                        font-size: 12px;
                    }
            }
        }

        .right{
            display: flex;
            justify-content: center;
            .count-likes{
                display: flex;
                align-items: center;
                  .likes-comentarios{
                      color: ${colors.primary};
                      margin-right: 2px;
                  }
                  .wrap-icon{
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      background-color: ${colors.primary};
                      border-radius: 50%;
                      width: 19px;
                      height: 19px;
      
                        svg {
                        font-size: 12px;
                        color: ${colors.bgprimary};
                    }
                }
            }
            
            .show-likes-on-comment {
                margin-top: 5px;
                position: relative;
              
                span {
                    color: ${colors.primary};
                    font-size: 13px;
                }   
              }
            .likes-on-comment{
                position: absolute;
                right: 0;
                display: none;
                /* bottom: 20px; */
                z-index: 1550;
                background-color: rgba(0, 0, 0, 0.8);
                padding: 10px;
                border-radius: 5px;
                min-width: 180px;
                flex-direction: column;
                
                /* &:hover {
                  display: flex;
                } */

                & > span {
                  color: white;
                  font-size: 12px;
                  letter-spacing: 1px;
                  margin-bottom: 3px;
                }
              }
               &:hover .likes-on-comment {
                  display: flex;
                }
        }
    }


`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  

  .options-comment {
    position: absolute;

        @media (max-width: 1450px) {
          right: -27px;
          top: -1px;
        }

        @media (max-width: 1000px) {
          right: -25px;
        }

    button {
      color: ${colors.textoff};
      /* background: ${colors.bgsecondary}; */
      background: none !important;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        font-size: 25px;
        color: ${colors.primary};
        
        @media (max-width: 450px) {
        font-size: 22px;
        }
            
      }

      span {
        font-size: 16px;
        color: ${colors.secondary};
      }
    }

    .dropdown {
      width: 250px;
      padding: 10px;
      background-color: white;
      border: 1px solid ${colors.border};
      position: absolute;
      right: 0px;
      top: 50px;
      border-radius: 5px;
      z-index: 1050;

      .option {
        display: flex;
        align-items: center;
        transition: all 0.3s;
        padding: 5px;
        border-radius: 5px;
        margin: 5px 0;
        cursor: pointer;

        &:hover {
          background-color: ${colors.bgsecondary};
        }

        .icon {
          background-color: ${colors.bgsecondary};
          width: 35px;
          height: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          margin-right: 10px;

          svg {
            color: ${colors.textoff};
            font-size: 20px;
          }
        }

        .option-saved-info{
          display: none;
          background-color: ${colors.bgprimary};
          box-shadow: ${effects.boxshadowsm};
          border: 1px solid ${colors.border};
          padding: 12px;
          margin-right: 5px;
          position: absolute;
          transform: translate(10%, 80%);
          z-index: 1;
          max-width: 180px;
          p{
            font-weight: 600;
            font-size: 14px;
            text-align: justify;
        }
     }

        &:hover{
          .option-saved-info{
          display: flex;
        }

      }

    }
      
      hr {
        border-top: 1px solid ${colors.border};
        height: 1px;
        width: 100%;
      }
    }
  }
  .saved-container{
    .icon-bookMark{
      cursor: pointer;
      svg {
      font-size: 25px;
      color: ${colors.primary};
     }
    }
    .saved-info{
      display: none;
      background-color: ${colors.bgprimary};
      box-shadow: ${effects.boxshadowsm};
      border: 1px solid ${colors.border};
      padding: 12px;
      margin-right: 5px;
      position: absolute;
      right: 0;
      z-index: 1;
      max-width: 190px;
      p{
        font-weight: 600;
        font-size: 14px;
        text-align: justify;
      }

      
     }

     &:hover{
      .saved-info{
      display: flex;
     }
   }
   }
`;
import styled from 'styled-components';

import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const ButtonScroll = styled.div`
    width: 40px;
    height: 40px;
    z-index: 1050;
    font-size: 20px;
    color: ${colors.btn_text_primary};
    background-color: ${colors.btn_primary};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 25px;
    bottom: 15px;
    cursor: pointer;
`;

export const Container = styled.div`
    width: 99%;
    height: 100%;
    display: flex;
    position: absolute;
    padding-bottom: 10px;
    overflow-x: hidden;
    overflow-y: auto;
    justify-content: center;

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: ${colors.border};
        border-radius: ${effects.radius};
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`;

export const Content = styled.div`
    width: 55%;
    position: relative;
    height: fit-content;
    padding-right: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: column nowrap;

    @media (max-width: 1270px) {
        width: 65%;
    }
    @media (max-width: 1150px) {
        width: 75%;
    }
    @media (max-width: 835px) {
        width: 85%;
    }
    @media (max-width: 600px) {
        width: 95%;
    }

    .btn-carregar-posts {
        margin-bottom: 50px;
        background-color: ${colors.bgprimary};
        border: 1px solid ${colors.border};
        border-radius: 5px;
        padding: 10px 20px;
        color: ${colors.secondary};
        font-weight: bold;
        letter-spacing: 1px;
        border-radius: 5px;
        font-size: 16px;
        display: flex;
        align-items: center;
        transition: box-shadow .2s;

        svg {
            font-size: 25px;
            margin-right: 10px;
            color: ${colors.primary};
        }

        &:hover {
            box-shadow: ${effects.boxshadow};
        }
    }

`;

export const InputSearch = styled.div`
    width: 100%;
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
    border: 1px solid rgba(48,48,48,.15);
    border-radius: 5px;
    background-color: white;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    padding: 15px;
    position: sticky;
    top: 0px;
    z-index: 1700;

    input {
        width: 100%;
        height: 40px;
        padding-left: 50px;
        border: none;
        background-color: #f4f4f6;
        border-radius: 25px;
    }

    svg {
        position: absolute;
        font-size: 32px;
        margin-left: 10px;
        color: #333;
    }
`

export const LoadingWrap = styled.div`
  width: 100%;
  margin-top: 120px;
  margin-bottom: 200px;
  background-color: none;
  border-radius: 0.75rem;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
	color: ${colors.primary};

  h3 {
    margin-top: 15px;
  }

  svg {
    font-size: 25px;
  }

  .fa-spin {
    -webkit-animation: icon-spin 1s infinite linear;
    animation: icon-spin 1s infinite linear;
  }

  @-webkit-keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }

  @keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
`;

export const UpdateFeedButton = styled.button`
    z-index: 1050;
    height: 40px;
    color: ${colors.btn_text_primary};
    background-color: ${colors.btn_primary};
    border-radius: 20px;
    border: none;
    margin-top: 25px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      margin-right: 5px;
    }
`;

import React, { useState, useEffect } from 'react';

import { Tools, Table, WrapPesquisa, WrapCheckBox, Divscroll, WrapRelatorio, WrapRelatorioInferior, MiniScroll } from './styles';
import { MdEdit, MdDelete, MdInsertDriveFile, MdOutlineMinimize } from 'react-icons/md';
import { RiAddLine } from 'react-icons/ri';
import { FaRegEye } from 'react-icons/fa';

import Swal from 'sweetalert2';

import CButton from '../ComponentButton';
import CFormSearch from '../ComponentFormSearch';
import CModal from '../ComponentModal';
import TitleAndBreadcrumb from '../../components/TitleAndBreadcrumb';
import { TituloPagina } from '../../styles/content';
import ConfirmForm from '../ConfirmForm';
import ViewForm from '../ViewForm';
import ViewHistorico from '../ViewHistorico';
import CCheckbox from '../ComponentCheckBox';
import CSelect from '../ComponentSelect';
import InfoMensagem from '../InfoMensagem';
import { Container, WrapAlert } from './styles';
import { sessionGet } from '../../session';
import getParams from '../../util/params';
import { getIdWithoutCripto } from '../../util/formatParamsUrl';
import api from '../../services/api-geral';
import { add } from 'date-fns';


export default function CTable({ ambiente = "", titles, values, allValues, indexes, indexesSearch, setValueSearch, FormCustom, actionDelete, load, editable, noFurtherInformation, textoRelatorio, itensRelatorio, relatorio, titlePage, breadcrumbPage, emptyPage, totalUsers, hasChangesModal, setHasChangesModal }) {
  const [search, setSearch] = useState('');
  const [handleSearch, setHandleSearch] = useState('');
  const [showModal, setShowModal] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [allSelect, setAllSelect] = useState('');
  const [item, setItem] = useState('');
  const [edit, setEdit] = useState(true);
  const [noComplementaryInfo, setNoComplementaryInfo] = useState(true);
  const [smallWidth, setSmallWidth] = useState(false)
  const [filter, setFilter] = useState('');
  const [options, setOptions] = useState('');
  const [buttonText, setButtonText] = useState('Excluir')
  var [posicaoRelatorio, setPosicaoRelatorio] = useState(0);

  useEffect(() => {
    if (window.location.href.includes('/usuarios')) {
      setButtonText('Desativar');
    }

    let optionsFilter = [];

    indexes.map((item, index) => {
      titles.map((title, indexTitle) => {
        if (index === indexTitle) {
          optionsFilter.push({ id: index, name: item, nameTitle: title });
        }
      })
    });

    setOptions(optionsFilter)

    if (filter !== '') {
      if (filter === 'id' || filter === 'phone' || filter === 'responsavel' || filter === 'Ordenar por') {
        values.sort((a, b) => (a[filter] > b[filter]) ? 1 : -1);
      } else {
        values.sort((a, b) => (a[filter].toLowerCase() > b[filter].toLowerCase()) ? 1 : -1);
      }
      //values.sort((a, b) => a[filter].localeCompare(b[filter]));
    }

    if (editable === false) {
      setEdit(false)
    }

    if (noFurtherInformation === false) {
      setNoComplementaryInfo(false)
    }

    if (window.screen.width >= 320 && window.screen.width <= 575) {
      setSmallWidth(true);
    }

  }, [filter, load]);

  useEffect(() => {
    if (window.location.href.includes('formulario-chamado')) {
      const id_chamado = getIdWithoutCripto(getParams('id'));
      if (id_chamado) {
        setTimeout(function () {
          var teste = allValues.filter(v => v.id === Number(id_chamado));
          if (teste[0] !== undefined) {
            setItem(teste[0]); setShowModal('view');
          }
        }, 500)
        readNotf(id_chamado);
      }
    }
  }, [values])

  const readNotf = async (idForm) => {
    const response = (await api.put(`notifications-form/${idForm}`));
  }

  useEffect(() => {
    let newSearch = search;
    newSearch = newSearch.toLowerCase();
    newSearch = newSearch.replace(new RegExp('[ÁÀ Ã]', 'gi'), 'a');
    newSearch = newSearch.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
    newSearch = newSearch.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
    newSearch = newSearch.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
    newSearch = newSearch.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
    setSearch(newSearch);
  }, [search])

  const formatName = (name) => {
    var newName = name;

    newName = newName.replace(new RegExp('[ÁÀ Ã]', 'gi'), 'a');
    newName = newName.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
    newName = newName.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
    newName = newName.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
    newName = newName.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');

    return newName;
  }

  useEffect(() => {
    setValueSearch(handleSearch);
  }, [handleSearch])

  const toggleSelectedItems = id => {
    if (selectedItems.includes(id)) {
      let itemIndex = selectedItems.filter(v => v !== id);
      setSelectedItems(itemIndex);
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const selectAllItems = () => {
    if (allSelect === 'in') {
      setSelectedItems([]);
      setAllSelect('');
    } else {
      let items = [];
      values.map(v => {
        items.push(v.id);
      });
      setSelectedItems(items);
      setAllSelect('in');
    }
  };

  const closeModal = () => {
    setShowModal('');
    setItem({});
    if (window.location.href.includes('formulario-chamado?id=')) {
      window.history.replaceState(null, null, window.location.pathname);
    }
  }

  const verifyLimitOfUsers = async () => {
    const response = (await api.get(`/empresa/${sessionGet('idEmpresa')}`)).data;

    if (totalUsers < response[0].users_limit) {
      setShowModal('add');
      setItem({});
    } else {
      Swal.fire({
        title: 'Aviso!',
        text: "Limite de cadastro de usuário atingido. Entre em contato conosco para alterar o seu plano.",
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Quero alterar o meu plano',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Sair'
      }).then((result) => {
        if (result.isConfirmed) {
          window.open('https://merx.app.br/', '_blank');
        }
      });
    }
  };

  return (
    <>
      {(showModal !== '') ? (
        <CModal
          view={(showModal === 'view' || showModal === 'viewHistorico') ? true : false}
          close={closeModal}
          hasChangesModal={hasChangesModal}
          setHasChangesModal={setHasChangesModal}
          form={(showModal === 'add')
            ? <FormCustom
              item={item}
              success={load}
              close={closeModal}
              setHasChangesModal={setHasChangesModal}
            />
            : (showModal === 'view')
              ? <ViewForm
                item={item}
                success={load}
                close={closeModal}
              />
              : (showModal === 'viewHistorico')
                ? <ViewHistorico
                  item={item}
                  success={load}
                  close={closeModal}
                />
                : <ConfirmForm action={actionDelete}
                  items={selectedItems}
                  close={closeModal}
                  success={load}
                />}
        />
      ) : ''}

      <Tools>
        <TitleAndBreadcrumb title={titlePage} breadcrumbs={breadcrumbPage} format={(breadcrumbPage[0].title === "Listas") ? 'config-list' : ''} />

        {(edit) &&
          <>
            <div className='wrapbuttons'>
              {
                totalUsers ? <CButton click={() => { verifyLimitOfUsers(); }} cstyle='success small' title={(<><RiAddLine /> {(smallWidth) ? '' : 'Adicionar'}</>)} /> : <CButton click={() => { setShowModal('add'); setItem({}); }} cstyle='success small' title={(<><RiAddLine /> {(smallWidth) ? '' : 'Adicionar'}</>)} />
              }

              <CButton
                disabled={!(selectedItems.length > 0)}
                click={() => setShowModal('delete')}
                cstyle='info small outline'
                title={(<><MdDelete /> {(smallWidth) ? '' : buttonText}</>)}
              />
            </div>
          </>
        }
      </Tools>
      {(values.length != 0) &&
        <>
          {(noComplementaryInfo) ?
            <WrapPesquisa id="WrapPesquisa">
              <CFormSearch cstyle='dark' value={handleSearch} change={value => { setSearch(value); setHandleSearch(value) }} placeholder="Pesquisar" />
              <CSelect val={filter} change={e => setFilter(e)} items={options} cstyle='select-ordenacao' label='Ordenação' arrayFixo={true} usingTable={true} />
            </WrapPesquisa>
            :
            <MiniScroll>
              <WrapPesquisa>
                <CFormSearch cstyle='dark' value={handleSearch} change={value => { setSearch(value); setHandleSearch(value) }} placeholder="Pesquisar" />
                <CSelect val={filter} change={e => setFilter(e)} items={options} cstyle='select-ordenacao' label='Ordenação' arrayFixo={true} usingTable={true} />
                {/* Para uma melhor organização o relatório aceitará até 8 itens, sendo divididos em 4 itens por linha */}
                <WrapRelatorio>
                  <strong>{textoRelatorio}  </strong>
                  {
                    itensRelatorio.slice(0, 4).map(itensRelatorio =>
                      <span> <strong>{itensRelatorio + ": "} </strong><a> {relatorio[(posicaoRelatorio)]} </a>  <a hidden>{posicaoRelatorio += 1}</a></span>,
                    )
                  }
                </WrapRelatorio>
              </WrapPesquisa>

              <WrapPesquisa>
                <WrapRelatorioInferior>
                  {
                    itensRelatorio.slice(4, 10).map(itensRelatorio =>
                      <span> <strong>{itensRelatorio + ": "} </strong><a> {relatorio[(posicaoRelatorio)]} </a>  <a hidden>{posicaoRelatorio += 1}</a></span>,
                    )
                  }
                </WrapRelatorioInferior>
              </WrapPesquisa>
            </MiniScroll>
          }
          {(edit) &&
            search === '' &&
            <WrapCheckBox>
              <CCheckbox
                active={allSelect}
                click={() => selectAllItems()}
                classWrap='checkbox-select-all'
              />
              <span>Todos</span>
            </WrapCheckBox>
          }
        </>
      }

      {(values.length == 0) ?
        <Container>
          <WrapAlert className="teste">
            <InfoMensagem tipoAlerta="info" conteudoAlerta={emptyPage} />
          </WrapAlert>
        </Container>
        :
        <Divscroll>
          <Table className={ambiente != "" ? ambiente : ""}>
            <tr className='headertable'>
              {options.map(options =>
                <th>{options.nameTitle}</th>)
              }
              <th></th>
            </tr>
            {(search === '' ? values : allValues).filter(el => {
              let isSearch = false;
              indexesSearch.map(index => {
                var val = formatName(el[index]);
                if (val.toLowerCase().includes(search.toLowerCase())) {
                  isSearch = true;
                }
              });
              return isSearch;
            }).map(value => (
              <>
                {/* <tr className="item-table" key={value.id} onClick={() => { toggleSelectedItems(value.id) }}> */}
                <tr className={(selectedItems.includes(value.id)) ? 'item-table select' : 'item-table'} key={(value.id + Math.random())} onClick={(ambiente === 'formulario-chamado') ? () => { setItem(value); setShowModal('view') } : ''}>
                  {(edit) &&
                    <td className='actionscheckbox'>
                      <CCheckbox
                        active={(selectedItems.includes(value.id)) ? 'in' : ''}
                        click={() => toggleSelectedItems(value.id)}
                      />
                    </td>
                  }
                  {Object.keys(value).filter((v) => indexes.includes(v)).map(el =>
                    <td key={(value[el] + Math.random())} className="content-item">{value[el]}</td>)
                  }
                  <td className='actionstable'>
                    {(edit) ?
                      <CButton click={() => { setItem(value); setShowModal('add'); }} cstyle="default small" title={(<>Editar <MdEdit /></>)} />
                      :
                      (noFurtherInformation) ?
                        <CButton click={() => { setItem(value); setShowModal('view'); }} cstyle="default small" title={(<>Visualizar<FaRegEye /></>)} />
                        :
                        <></>
                    }
                    {(value.font === 'chat') &&
                      <CButton click={() => { setItem(value); setShowModal('viewHistorico'); }} cstyle="default small" title={(<><MdInsertDriveFile /> Histórico</>)} />
                    }
                  </td>
                </tr>
              </>
            ))}
          </Table>
        </Divscroll>
      }

    </>
  );
}


import styled from 'styled-components';
import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Topbar = styled.div`
  background-color: white;
  width: 100%;
  height: 60px;
  box-shadow: ${effects.boxshadow};
  display: flex;
  justify-content: center;

  .content {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media(max-width: 575px) {
      width: 85%;

      .clear {
        display: none;
      }
    }

    a {
      color: ${colors.primary};
      text-decoration: none;
      display: flex;
      align-items: center;

      svg {
          margin-right: 5px;
      }
    }

    .wrap-title {
      display: flex;
      align-items: center;

      img {
        height: 50px;
        margin-right: 10px;
      }
    }

    .clear {
      width: 125px;
    }
  }
`;

export const Tabs = styled.div`
  width: 100%;
  height: 75px;
  display: flex;
  align-items: center;
  background-color: ${colors.bgsecondary};
  display: flex;
  justify-content: center;
  align-items: center;

  @media(max-width: 575px) {
    height: 60px;
  }

  & > .content {
    height: 75px;
    display: flex;
    justify-content: center;

    @media (max-width: 1440px) {
      width: 100%;
    }

    @media (max-width: 800px) {
      display: none;
    }

    @media(max-width: 575px) {
      width: 85%;
      height: 60px;
    }

    button {
      background-color: transparent;
      height: 75px;
      border: 0;
      color: ${colors.primary};
      padding: 0 20px;
      margin-right: 10px;

      @media(max-width: 575px) {
        height: 60px;
        padding: 0 15px;

        svg {
          font-size: 20px;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      &.active {
        border-bottom: 2px solid ${colors.primary};
      }
    }
  }

  & > .content-mobile {
    display: flex;
    align-items: center;

    h1 {
      @media (max-width: 600px) {
        font-size: 18px;
      }

      @media (max-width: 450px) {
        font-size: 16px;

        &.small {
          font-size: 14px;
        }
      }
    }

    @media (min-width: 801px) {
      display: none;
    }

    button {
      width: 30px;
      height: 30px;
      padding: 4px;
      margin-left: 10px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
      border: 2px solid #922c88;
      transition: background 0.2s linear;
      transition: color 0.2s linear;
      margin: 0 10px;
      position: absolute;

      &.left {
        left: 30px;

        @media (max-width: 430px) {
          left: 0;
        }
      }

      &.right {
        right: 30px;

        @media (max-width: 430px) {
          right: 0;
        }
      }

      svg {
        font-size: 20px;
      }
    
      &:hover{
        background: #922c88;
        color: white;
      }

      &:disabled {
        display: none;
      }
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  //padding-bottom: 90px;
  overflow-y: auto;
  position: relative;
  height: calc(100vh - 90px);
  width: calc(100vw - 110px);
  top: 90px;
  left: 110px;

  @media (max-width: 1440px) {
    top: 70px;
    height: calc(100vh - 70px);
    width: calc(100vw - 110px);
  }

  @media (max-width: 1024px) {
    height: unset;
    left: 0;
    width: 100%;
    margin-left: 0;
    padding-bottom: 0;
  }

  h2 { color: ${colors.text_primary} }

  & > h2 {
    width: 60%;
    margin-top: 25px;
  }

  .label-text-area {
    width: unset !important;
    background-color: white;
    position: absolute;
    top: 47px;
    left: 10px;
    font-size: 14px;
    padding: 0 5px;
    z-index: 1050;
    color: rgba(0, 0, 0, 0.5);
    transition: color .5s;
  }

  .wrap-tabs {
    width: 80%;
    //height: calc(100vh - 135px);
    padding: 30px 0;
    display: flex;
    flex-direction: column;

    @media(max-width: 1800px) {
      width: 90%;
    }

    @media(max-width: 1600px) {
      width: 100%;
    }

    @media(max-width: 1024px) {
      height: 100%;
      align-items: center;
    }

    @media (max-width: 400px) {
      padding: 30px 10px;
    }

    .title-tabs {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media(min-width: 576px) {
        display: none;
      }

      h1 {
        margin-bottom: 40px;
        position: relative;

        &::before {
          content: "";
          width: 200px;
          height: 2px;
          background-color: #d0d0d0;
          position: absolute;
          bottom: -15px;
          left: calc(50% - 100px);
        }
      }

      .separator {
        width: 50%;
        border: 1px solid ${colors.bg_from_primary};
        margin: 10px 0 30px;
      }
    }

    .publicar-content {
      display: flex;
      width: 100%;
      margin-bottom: 45px;
      padding: 0 20px;
      place-content: end;
      text-align: end;
      flex-flow: row-reverse;

      @media (max-width: 1600px) {
        padding: 0 100px;
      }

      @media (max-width: 900px) {
        padding: 0 20px;
      }

      @media (max-width: 610px) {
        flex-direction: column;
        flex-flow: column;
      }

      @media (max-width: 400px) {
        padding: 0;
      }

      button {
        color: white;
        border: none;
        border-radius: 10px;
        padding: 5px 12px;
      }

      a {
        height: 33px;
        color: white;
        border: none;
        border-radius: 10px;
        padding: 6px 12px;
        cursor: pointer;
        text-decoration: none;

        &.site {
          margin-left: 10px;

          @media (max-width: 330px) {
            margin-left: 5px;
          }
        }
      }

      .btn-reverse-changes {
        color: white;
        border: none;
        border-radius: 10px;
        padding: 5px 12px;
        cursor: pointer;
        background-color: #e94057;
        margin-right: 10px;

        &:disabled {
          background-color: gray;
        }

        @media (max-width: 400px) {
          font-size: 13px;
        }

        @media (max-width: 360px) {
                  font-size: 11px;
                }

      }

      .save-changes {
        color: white;
        border: none;
        border-radius: 10px;
        padding: 5px 12px;
        cursor: pointer;
        background-color: ${colors.primary};
        margin-right: 10px;

        @media (max-width: 610px) {
          margin-right: 0;
          margin-top: 10px;
        }

        @media (max-width: 400px) {
          font-size: 13px;
        }

        @media (max-width: 360px) {
          font-size: 11px;
        }
      }

      .visualizar {
        background-color: ${colors.primary};
        
        @media (max-width: 400px) {
          font-size: 13px;
        }

        @media (max-width: 360px) {
          font-size: 11px;
        }
      }

      .publicar {
        margin-left: 10px;
        background-color: #67bb49;
        border-radius: 10px;

        @media (max-width: 400px) {
          font-size: 13px;
        }

        @media (max-width: 360px) {
          font-size: 11px;
        }

        @media (max-width: 330px) {
          margin-left: 5px;
        }
      }
    }

    .wrap-panels {
      display: flex;
      justify-content: center;
      position: relative;

      &.chat {
        @media (max-width: 1024px) {
          flex-direction: unset;
        }

        @media (max-width: 700px) {
          flex-direction: column;
        }
      }

      @media (max-width: 1024px) {
        width: 80%;
        padding-bottom: 60px;
      }

      @media (max-width: 900px) {
        width: 90%;
      }

      @media (max-width: 800px) {
        width: 95%;
      }

      @media (max-width: 400px) {
        width: 100%;
        padding-bottom: 120px;
      }

      .mobile-preview {

        @media (max-width: 750px) {
          width: 100%;
          display: flex;
          place-content: center;
        }
      }

      .painel-configuracao-site {
        width: calc(40% - 10px);
        height: 600px;
        padding: 0px 20px;
        margin: 0;
        justify-content: flex-start;
        flex-direction: column;
        border-radius: unset;
        box-shadow: none;
        background-color: unset;

        @media(max-width: 575px) {
          padding: 10px;
        }

        &.metadados {
          height: auto;
        }

        &.aparencia {
          width: 38%;
          height: 600px;
          padding: 0px 0px 0px 45px;
          margin: 0px 30px 0px 0px;
          justify-content: center;

          @media(max-width: 1400px) {
            height: 550px;
            margin: 0;
          }

          .link-company {
            width: 100%;
          }

          form {
            height: 100%;
            margin: 0;

            

            .change-actions {
              width: 100%;
              display: flex;
              justify-content: space-around;

              @media(max-width: 1440px) {
                button {
                  font-size: 12px;
                }
              }

              @media (max-width: 1024px) {
                position: absolute;
                bottom: 0;
              }

              @media (max-width: 400px) {
                flex-direction: column;
                align-items: center;

                button {
                  width: 75%;
                }
              }

              .btn-reverse-changes {
                height: 33px;
                padding: 5px 12px;
                //font-weight: 700;
                border: none;
                color: white;
                border-radius: 10px;
                background-color: #e94057;

                @media (max-width: 400px) {
                  margin-bottom: 10px;
                  font-size: 13px;
                }

                @media (max-width: 360px) {
                  font-size: 11px;
                }

                &:disabled {
                  background-color: gray;
                }
              }
            }
          }
        }

        h2 {
          text-align: center;
        }

        form {
          width: 100%;
          margin: 0px 0px 25px 0px;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;

          @media (max-width: 1024px) {
            position: unset;
          }

          & > label {
            width: 100%;
          }

          h2 {
            margin-bottom: 25px;
          }

          .wrap-logo {
            width: 100%;
            margin-bottom: 25px;
            display: flex;
            align-items: center;

            @media(max-width: 575px) {
              flex-direction: column;
            }

            .info-modal-painel {
              position: relative;
              top: 85px;
              left: -85px;

              @media (max-width: 1440px) {
                top: 70px;
                left: -56px;
              }

              @media (max-width: 1024px) {
                top: 51px;
                left: -14px;
              }

              @media (max-width: 575px) {
                top: -4px;
                left: 1px;
              }

              svg {
                font-size: 22px;
                color: #5E5E5E;
              }
            }

            .company-profile-settings {
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              place-content: center;

              .wrap-preferences {
                width: 100%;
                margin-top: 20px;
                display: flex;
                align-items: center;

                .border-color {
                  width: 140px;
                  margin-left: 15px;

                  @media (min-width: 1024px) and (max-width: 1350px) {
                    width: 100px;
                  }
                }

                .toggle-preference {
                  width: 180px;
                  background-color: white;
                  height: 40px;
                  padding: 0 8px;
                  border: 1px solid #d0d0d0;
                  border-radius: 20px;
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                  color: ${colors.text_primary};

                  @media(max-width: 1440px) {
                    width: 160px;
                  }

                  @media (min-width: 1024px) and (max-width: 1250px) {
                    width: 120px;
                  }

                  .title {
                    font-size: 13px;
                    font-weight: 500;
                    display: flex;
                    height: 100%;
                    align-items: center;
                    text-align: center;
                    line-height: 16px;

                    .wrap-icon {
                      background-color: white;
                      width: 20px;
                      height: 20px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      border-radius: 50%;
                      margin-right: 5px;
                      //box-shadow: ${effects.boxshadow};

                      svg {
                        color: ${colors.primary};
                        font-size: 25px;
                      }
                    }
                    
                    svg {
                      position: absolute;
                    }
                  }
          
                  .toggle-funcionalidade {
                    position: relative;
                    right: -30px;
                    width: 35px;
                    height: 20px;
                    //box-shadow: ${effects.boxshadow};
                    background-color: white;
                    border: 1px solid #d0d0d0;
                    border-radius: 15px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 0 5px;
                    cursor: pointer;

                    @media(max-width: 1440px) {
                      right: -11px;
                    }

                    @media (min-width: 1024px) and (max-width: 1250px) {
                      right: -1px;
                    }

                    .indicador {
                      width: 15px;
                      height: 15px;
                      background-color: #999;
                      border-radius:  10px;
                    }

                  &.loading {
                    justify-content: center;
                  }
            
                  &.active {
                    justify-content: flex-end;

                  .indicador {
                    background-color: #00cc66;
                  }
                }
              }
            }
          }
        }

            .image {
              width: 125px;
              height: 125px;
              min-width: 125px;
              min-width: 125px;
              background-color: ${colors.bgsecondary};
              border-radius: 50%;
              position: relative;
              cursor: pointer;
              overflow: hidden;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 15px;

              @media (max-width: 1440px) {
                width: 95px;
                height: 95px;
                min-width: 95px;
                min-width: 95px;
                margin-right: 0px;
              }

              @media(max-width: 575px) {
                margin-bottom: 10px;
              }

              svg { 
                font-size: 30px; 
              }

              .image-filter {
                width: 100%;
                height: 100%;
                background-color: ${colors.bgsecondary};
                opacity: 0;
                transition: opacity 0.2s ease-in-out;
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;

                &:hover {
                  opacity: .75;
                }
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            #input-company-profile-image {
              display: none;
            }

            .wrap-buttons {
              width: 100%;
              display: flex;
              justify-content: space-around;


              @media(max-width: 575px) {
                width: 100%;

                button {
                  width: 100%;
                }
              }

              button {
                height: 40px;
                padding: 0 20px;
                border-radius: 25px;
                border: 2px solid ${colors.primary};
                line-height: 16px;
                text-align: center;

                &.btn-primary {
                  width: 180px;
                  background-color: ${colors.primary};
                  color: white;

                  @media(max-width: 1440px) {
                    width: 160px;
                    font-size: 13px;
                  }

                  @media (min-width: 1024px) and (max-width: 1250px) {
                    width: 120px;
                    height: 45px;
                  }
                }

                &.btn-secondary {
                  width: 140px;
                  margin-left: 15px;
                  background-color: white;
                  color: ${colors.primary};
                  font-weight: bold;

                  @media(max-width: 1440px) {
                    font-size: 13px;
                  }

                  @media (min-width: 1024px) and (max-width: 1350px) {
                    width: 100px;
                    font-size: 13px;
                  }
                }
              }
            }
          }

          .infos {
            position: relative;
            width: 100%;

            .label-description {
              width: unset;
              top: 110px;
              left: 10px;
              background-color: white;
              position: absolute;
              font-size: 14px;
              padding: 0 5px;
              z-index: 1050;
              color: rgba(0,0,0,0.5);
              -webkit-transition: color .5s;
              transition: color .5s;

              @media (max-width: 1150px) {
                font-size: 11px;
              }

              @media (max-width: 450px) {
                font-size: 10px;
              }
            }

            .label-infos {
              margin-bottom: 25px;
            }

            .input-style {

              label {
                @media (max-width: 1150px) {
                  font-size: 10px;
                }
              }
            }

            & > label { margin-bottom: 10px; display: block; width: 100%; }

            .input-style {
              height: 40px;
              border-radius: 5px;
              margin: 0 0 20px;
            }

            textarea {
              border: 1px solid rgba(0,0,0,0.2);
              height: 150px;
              border-radius: 5px;
              width: 100%;
              padding: 10px;
              font-size: .8rem;
              margin-bottom: 20px;
              font-weight: 500;
              font-family: 'Poppins',sans-serif;
            }

            h3 {
              margin-bottom: 25px;
              color: ${colors.text_primary};
            }
          }

          .type-link {
            width: 100%;
            list-style: none;
            display: flex;
            justify-content: flex-start;
            padding: 0;
            margin-bottom: 25px;

            li {
              width: 30px;
              height: 30px;
              color: ${colors.text_primary};
              background-color: #f0f0f0;
              box-shadow: ${effects.boxshadow};
              border-radius: 5px;
              margin-right: 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;

              svg { font-size: 20px; }

              &.active { border: 2px solid gray; padding: 2px; }
              &.facebook { background-color: #0d88f0; color: white; }
              &.instagram { background-color: #ed4956; color: white; }
              &.twitter { background-color: #1da1f2; color: white; }
              &.whatsapp { background-color: #34af23; color: white; }
              &.youtube { background-color: #ff0000; color: white; }
              &.linkedin { background-color: #0a66c2; color: white; }
              &.mail {background-color: ${colors.primary}; color: white}
            }
          }

          label {
            margin-bottom: 10px;
          }

          .input-style {
            height: 40px;
            border-radius: 5px;
            margin: 0 0 20px;
          }

          .btn-link {
            width: auto;
            height: 33px;
            border-radius: 10px;
            padding: 5px 12px !important;
            display: flex;
            justify-content: center;
            font-weight: normal !important;
          }
        }

        .list-links {
          width: 100%;
          list-style: none;
          display: flex;
          flex-direction: column;
          padding-right: 5px;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 20px;
            background-color: #d0d0d0;
          }

          li {
            display: flex;
            margin-bottom: 10px;

            .arrow-items {
              margin-right: 5px;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              align-items: center;

              @media(max-width: 575px) {
                width: 20px;
              }

              svg {
                font-size: 20px;
                color: ${colors.primary};
                cursor: pointer;
              }
            }

            .content-item {
              background-color: white;
              width: 100%;
              border-radius: 5px;
              border: 1px solid #d0d0d0;
              padding: 10px;
              position: relative;
              display: flex;
              align-items: center;

              @media(max-width: 575px) {
                width: calc(100% - 20px);
              }

              .buttons {
                display: flex;
                position: absolute;
                right: 10px;
                background: white;

                .btn-edit {
                  width: 30px;
                  height: 30px;
                  background: none;
                  border: 0;
                  color: #333333;
                  display: flex;
                  align-items: center;
                  place-content: center;

                  svg {
                    font-size: 18px;
                  }
                }

                .btn-delete {
                  width: 30px;
                  height: 30px;
                  background: none;
                  border: 0;
                  color: ${colors.btn_danger};
                  display: flex;
                  align-items: center;
                  place-content: center;

                  svg {
                    font-size: 25px;
                  }
                }
              }
              
              .wrap-icon {
                background-color: #f0f0f0;
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                margin-right: 10px;
                box-shadow: ${effects.boxshadow};

                @media(max-width: 575px) {
                  display: none;
                }
                
                svg { font-size: 20px; }

                &.facebook { background-color: #0d88f0; color: white; }
                &.instagram { background-color: #ed4956; color: white; }
                &.twitter { background-color: #1da1f2; color: white; }
                &.whatsapp { background-color: #34af23; color: white; }
                &.youtube { background-color: #ff0000; color: white; }
                &.linkedin { background-color: #0a66c2; color: white; }
                &.mail {background-color: ${colors.primary}; color: white}
              }

              .info {
                display: flex;
                flex-direction: column;
                
                strong { font-size: 14px;}
                small { font-size: 12px; color: gray; }

                @media(max-width: 575px) {
                  overflow: hidden;

                  small {
                    overflow-wrap: break-word;
                  }
                }
              }
            }
          }
        }
      }

      @media(max-width: 1024px) {
        flex-direction: column;

        .painel-configuracao-site {
          width: 100% !important;
          height: 100% !important;
          padding: 20px !important;
          
          &.aparencia, &.metadados {
            margin-bottom: 30px;
          }
        }

        .painel-aparencia {
          width: 100%;
          height: 100%;
          margin-left: 0;
        }
      }
    }

    .aparencia-definicoes {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      &.disabled {
        li {
          background-color: #c9c9c9 !important;
        }
      }

      .lista-funcionalidades {
        width: 100%;
        list-style: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .info-modal-painel {
          display: flex;
          align-items: center;
          position: relative;
          top: -1px;

          svg {
            font-size: 20px;
            color: #6E6E6E;
            margin-right: 5px;
          }
        }

        li {
          width: 100%;
          background-color: white;
          height: 45px;
          padding: 0 10px;
          border: 1px solid #d0d0d0;
          border-radius: 10px;
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          cursor: pointer;
          color: ${colors.text_primary};

          .title {
            font-size: 14px;
            font-weight: 500;
            display: flex;
            width: calc(100% - 50px);
            height: 100%;
            align-items: center;

            @media (max-width: 1200px) {
              font-size: 11px;
            }

            .wrap-icon {
              background-color: white;
              width: 30px;
              height: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              margin-right: 10px;
              box-shadow: ${effects.boxshadow};

              svg {
                color: ${colors.primary};
                font-size: 25px;
              }
            }

            svg {
              position: absolute;
            }
          }
          
          .toggle-funcionalidade {
            position: relative;
            right: 10px;
            width: 45px;
            height: 25px;
            box-shadow: ${effects.boxshadow};
            background-color: white;
            border-radius: 15px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0 5px;
            cursor: pointer;

            .indicador {
              width: 17px;
              height: 17px;
              background-color: #999;
              border-radius:  10px;
            }

            &.loading {
              justify-content: center;
            }
            
            &.active {
              justify-content: flex-end;

              .indicador {
                background-color: #00cc66;
              }
            }
          }
        }
      }
    }
 
    .definicoes {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      &.chat {
        width: calc(50% - 10px);

        @media (max-width: 400px) {
          padding: 0 10px;

          .wrap-icon {
            width: 30px !important;
            height: 30px !important;

            svg {
              font-size: 20px !important;
            }
          }

          strong {
            font-size: 14px;
          }
        }
        
        .lista-funcionalidades {
          width: 100%;
        }
      }

      .lista-funcionalidades {
        list-style: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        width: calc(50% - 10px);
        justify-content: space-between;

        li {
          width: 100%;
          background-color: #fff;
          height: 60px;
          padding: 0 10px;
          box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
          border-radius: 5px;
          display: flex;
          align-items: center;
          margin-bottom: 25px;
          cursor: pointer;
          color: ${colors.text_primary};

          &:last-child {
            margin: 0;
          }

          .title {
            display: flex;
            width: calc(100% - 50px);
            height: 100%;
            align-items: center;

            .wrap-icon {
              background-color: white;
              width: 40px;
              height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              margin-right: 10px;
              box-shadow: ${effects.boxshadow};

              svg {
                color: ${colors.primary};
                font-size: 30px;
              }
            }

            svg {
              position: absolute;
            }
          }
          
          .toggle-funcionalidade {
            width: 50px;
            height: 30px;
            box-shadow: ${effects.boxshadow};
            background-color: white;
            border-radius: 15px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0 5px;
            cursor: pointer;

            .indicador {
              width: 20px;
              height: 20px;
              background-color: #999;
              border-radius:  10px;
            }

            &.loading {
              justify-content: center;
            }
            
            &.active {
              justify-content: flex-end;

              .indicador {
                background-color: #00cc66;
              }
            }
          }
        }
      }

      @media(max-width: 1024px) {
        width: 100% !important;
        flex-direction: column;

        .lista-funcionalidades {
          width: 100%;
          margin-bottom: 30px;
        }

        .painel-configuracao-site {
          width: 100%;
        }
      }
    }
    
    .redes-sociais {
      width: 100%;
      display: flex;
      justify-content: center;

      @media (max-width: 700px) {
        flex-direction: column;

        .mobile-preview {
          width: 100%;
          display: flex;
          place-content: center;
        }
      }
    }

    .painel-aparencia {
      width: 360px;
      height: 600px;
      display: flex;
      flex-direction: column;
      padding: 20px;
      margin: 0;
      margin-left: 10px;
      border-radius: unset;
      box-shadow: none;
      background-color: unset;

      @media (max-width: 1400px) {
        height: 550px;
      }

      .link-company {
        width: 100%;
      }

      .aparencia-preview {
        width: 100%;
        display: flex;

        @media (max-width: 700px) {
          flex-direction: column;
        }
      }

      .mobile-preview {
        @media (max-width: 700px) {
          display: flex;
          place-content: center;
        }
      }

      .color-and-preferences {
        width: 100%;

        .colorbox {
          padding: 10px 15px;
          border-radius: 20px;
          background-color: rgba(142, 142, 142, 0.1);
          box-shadow: ${effects.boxshadow};

          &.first {
            margin-bottom: 10px;
          }
        }
      
        .color-row {
          display: flex;

          .separator {
            width: 40px;

            @media (max-width: 450px) {
              width: 15px;
            }
          }
        }

        .color-row-radio {
          .radio-row {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            label {
              margin-left: 5px;
            }
          }
        }

        h5 {
          font-size: 14px;
          margin-bottom: 10px;
          font-weight: bold;
        }
      }

      small {
        margin-top: 10px;
        display: block;
        margin-bottom: 10px;
        color: ${colors.textoff};
        font-weight: bold;
      }

      .content-painel-holder {
        width: 100%;
        display: flex;
        place-content: center;
        margin-bottom: 15px;
      }

      .content-painel {
        width: 75%;
        height: 165px;
        position: relative;

        @media (max-width: 1440px) {
          width: 65%;
          height: 165px;
        }

        @media (max-width: 1400px) {
          width: 60%;
          height: 120px;
        }

        .btn-background {
          width: 100%;
          height: 100%;
          font-size: 50px;
          background-color: ${colors.bgsecondary};
          border: 1px solid #d0d0d0;
          border-radius: 10px;
          position: relative;
          overflow: hidden;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;

          .image-filter {
            width: 100%;
            height: 100%;
            background-color: ${colors.bgsecondary};
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
              opacity: .75;
            }
          }

          .filter-in-image {
            position: absolute;
            width: 100%;
            height: 100%;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .remove-background {
          width: 25px;
          height: 25px;
          color: ${colors.btn_text_ok};
          background-color: ${colors.secondary};
          border-radius: 50%;
          position: absolute;
          top: -12.5px;
          right: -12.5px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .filtered {
        background-color: blue;
      }

      #input-company-background-image {
        display: none;
      }
    }
  }
`;

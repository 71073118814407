import React, { useEffect, useState } from 'react';
import api from '../../services/api-geral';
import { sessionGet } from '../../session';

import { Container, Content, LoadingWrap, Empty } from './styles';
import { FaRedo, FaHornbill } from "react-icons/fa";
import { RiRefreshLine } from 'react-icons/ri';

import NPostFeed from '../NPostFeed';

import ModalPostFeed from '../ModalPostFeed';

import log from '../../util/log';
import ModalShowImage from '../ModalShowImage';

function SavePosts({ hasChangesModal, setHasChangesModal, setImageId, setImagePath }) {
  const [posts, setPosts] = useState([]);

  const [images, setImages] = useState([]);
  const [page, setPage] = useState(0);
  const [countPost, setCountPost] = useState(0);
  const [showExplorer, setShowExplorer] = useState(false);
  const [showBtnLoadPost, setShowBtnLoadPost] = useState(false);

  const [showBtnScroll, setShowBtnScroll] = useState(0);
  const [elementFeed, setElementFeed] = useState('');

  const [postType, setPostType] = useState();

  const [editedImage, setEditedImage] = useState({});
  const [editedPost, setEditedPost] = useState({});

  const [showModalEdit, setShowModalEdit] = useState(false);

  const [showModalImage, setShowModalImage] = useState(false);
  const [pathImage, setPathImage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [savedPostChange, setSavedPostChange] = useState(false);

  useEffect(() => { changePage(0); }, []);
  
  useEffect(() => {loadPosts();}, [page]);

  useEffect(() => { loadPosts(); }, [savedPostChange]);

  useEffect(() => { verifyPaginationButton() }, [countPost, page]);
 
  const idEmpresa = sessionGet('idEmpresa');
  const idUser = sessionGet('idUser')

  const loadPosts = async () => {
    setIsLoading(true);

    try{
        const response = (await api.get(`/posts-salvos/${page}/${idUser}/${idEmpresa}`)).data;

        const postData = response.posts;
        const postCount = response.countPosts;
        const postImage = response.images;
            
        const postsTreatement = postData.map((post) => ({
          autor: post.PostFeed.autor,
          content: post.PostFeed.content,
          createdAt: post.PostFeed.created_at,
          id:post.id_post,
          id_autor: post.PostFeed.id_autor,
          id_empresa:post.id_empresa,
          likes: post.PostFeed.likes,
          marks:post.PostFeed.marks,
          path_img: post.PostFeed.path_img,
          updatedAt: post.PostFeed.updated_at,
          views:post.PostFeed.views,          
        }))

        setImages(postImage);
        setCountPost(postCount);

        if (page === 0) {
          setPosts([]); setPosts(postsTreatement);
          setImages([]); setImages(postImage);
        }
        else {
          setPosts([...posts, ...postsTreatement]);
          setImages([...images, ...postImage]);   
        }; 
        
    } catch (error){
        console.error("Error loading posts:", error);
    } finally {
        setIsLoading(false);
    }
    
  }

  const deletePost = async (post) => {
    const responsePostSalvos = (await api.delete(`posts-salvos/${idUser}/${idEmpresa}/${post.id}`)).data;
    const response = (await api.delete(`/posts/${post.id}`)).data;
    loadPosts();
    log('excluiu um post na página de perfil.');
  }

  const verifyPaginationButton = () => {
    const numberPages = (countPost / 4);
    var lastPage = 0;
    var pagina = page;

    if (numberPages % 1 === 0) {
        pagina = pagina + 1;
        lastPage = numberPages;
    } else lastPage = parseInt(numberPages);

    if (pagina === lastPage) setShowBtnLoadPost(false);
    else setShowBtnLoadPost(true);
  }

  const changePage = numberPage => setPage(numberPage);

  const getElementFeed = (e) => {
  setElementFeed(e);
  
    if (e.scrollTop > 200) {
      setShowBtnScroll(true);
    } else {
      setShowBtnScroll(false);
    }
  }

  // efeito de Carregando...
  useEffect(() => {
    setLoading(true);
    const debounce = setTimeout(() => {
      setLoading(false);
    }, 450)
    return () => clearTimeout(debounce)
  }, []);

  return (
    <>
      {showModalEdit &&
        <ModalPostFeed
          post={editedPost}
          showExplorer={showExplorer}
          setShowExplorer={setShowExplorer}
          image={editedImage}
          closeModal={() => { setShowModalEdit(false) }}
          reload={loadPosts}
          getPosts={() => { setPage(0); loadPosts(); }}
          type={postType}
          hasChangesModal={hasChangesModal}
          setHasChangesModal={setHasChangesModal}
          setImageId={setImageId}
          setImagePath={setImagePath}
        />
      }

      {showModalImage && <ModalShowImage img={pathImage} close={() => setShowModalImage(false)} />}
      <Container onScroll={e => getElementFeed(e.target)}>
        <Content>
          <div className='posts'>
            {
              loading === true ? <LoadingWrap><FaRedo className="fa-spin" /><h3>Carregando...</h3></LoadingWrap> :
                posts.length > 0 ?
                  posts.map((post, index) => (
                    <NPostFeed
                      key={post.id}
                      image={images[index]}
                      post={post}
                      editar={() => { setEditedPost(post); setEditedImage(images[index]); setShowModalEdit(true); setPostType("edit") }}
                      deletar={() => { deletePost(post) }}
                      modalImage={path => { setPathImage(path); setShowModalImage(true); }}
                      setHasChangesModal={setHasChangesModal}
                      setImageId={setImageId}
                      setImagePath={setImagePath}
                      perfil={true}
                      reload={loadPosts}                 
                    />
                  ))
                  :
                  <Empty><FaHornbill className="fa-spin" /><h3>Nenhuma publicação até o momento</h3></Empty>
            }
          </div>
           {   
              loading === false  && countPost > 0 &&
                (showBtnLoadPost ) && (
                    <div>
                        <button className="btn-carregar-posts" disabled={isLoading} onClick={() => changePage(page + 1)}>
                            <RiRefreshLine /> <span>Ver mais publicações</span>
                        </button>
                    </div>
                )
            }
        </Content>
      </Container>
    </>
  );
}

export default SavePosts;
import styled from "styled-components";

import colors from "../../presets/colors";
import effects from "../../presets/effects";

import bgimgpng from "../../assets/bgimgpng.png";

export const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${colors.bgsecondary};
    box-shadow: ${effects.boxshadow};
    border-radius: ${effects.radius};
    position: relative;

    &.page {
        overflow: hidden;
    }

    &.plugin {
        overflow: hidden;
        width: 80%;
        height: 80%;
        position: fixed;
        z-index: 3060;
        top: 10%;
        left: 10%;

        .btn-fechar-plugin {
            background-color: transparent;
            border: 0;
            position: absolute;
            top: 1px;
            right: 1px;
            font-size: 25px;
            color: ${colors.texton};
        }
    }

    .topbar {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        background-color: ${colors.bgprimary};
        padding: 20px;
        border-radius: ${effects.radius};

        @media (max-width: 1400px) {
            padding: 10px 20px;
        }

        h2 {
            font-size: weight;
            color: ${colors.primary};

            @media (max-width: 1400px) {
                font-size: 17px;
            }

            @media (max-width: 575px) {
                font-size: 15px;
            }
        }

        @media (min-width: 753px) {
            height: 20%;
        }

        @media (max-width: 1024px) {
            .li-separator {
                display: none;
            }
        }

        .separator {
            width: 100%;
            height: 1px;
            border: 1px solid ${colors.border};
        }

        .separator-btn {
            width: 100%;
            height: 1px;
            border: 1px solid ${colors.border};
        }

        form {
            display: flex;
        }
    }

    .files {
        height: 70%;
        overflow-y: auto;
        scrollbar-width: thin;
        position: relative;
        padding-bottom: 50px;

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: ${colors.border};
            border-radius: ${effects.radius};
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

        .content {
            width: 100%;
            display: flex;
            list-style: 0;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;
        }
    }

    .footer {
        width: 100%;
        height: 10%;
        background-color: white;
        display: flex;
        align-items: center;
        padding: 0 20px;
        border-radius: ${effects.radius};
    }

    .tabs {
        list-style: none;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;

        .left {
            display: flex;
            align-items: center;
            @media (max-width: 750px) {
                padding: 10px;
            }
            /*@media (max-width: 575px) {
          li {
            margin-right: 5px;
          }
          .li-input {
            margin-right: 5px;
          }
        }*/

            @media (min-width: 752px) {
                .separator-btn {
                    display: none;
                }
            }

            @media (max-width: 752px) {
                width: 100%;
                flex-direction: column;

                .primary {
                    width: 100%;
                    justify-content: center;
                }

                .separator-btn {
                    border-radius: 50%;
                    width: 100%;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }

                form {
                    width: 100%;
                    flex-direction: column;
                    .li-input {
                        margin-bottom: 5px;
                        width: 100%;
                        height: 32px;
                    }
                }

                li {
                    margin-right: 0px;
                }
            }
        }

        li {
            margin-right: 30px;
            font-size: 16px;
            //height: 35px;
            padding: 5px 10px;
            font-weight: bold;
            border: 1px solid ${colors.primary};
            border-radius: 5px;
            transition: all 0.5s;
            cursor: pointer;
            color: ${colors.primary};
            display: flex;
            align-items: center;
            box-shadow: ${effects.boxshadow};

            @media (max-width: 1400px) {
                //height: 30px;
                font-size: 14px;
            }

            input[type="file"] {
                display: none;
            }

            &.li-input {
                padding: 0;
                margin-right: 15px;

                input {
                    border: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 5px;
                    padding: 0 10px;

                    @media (max-width: 1400px) {
                        font-size: 14px;
                    }
                }
            }

            /*@media (max-width: 520px) {
          .txtbtn-midia {
            display: none;
          }
        }*/

            &.primary {
                background-color: ${colors.primary};
                color: white;

                button {
                    color: white;
                    border: 0;
                    background-color: transparent;
                    display: flex;
                    align-items: center;
                    font-weight: bold;

                    @media (max-width: 1400px) {
                        font-size: 14px;
                    }
                }
            }

            &.danger {
                background-color: ${colors.featured};
                border-color: ${colors.featured};
                color: white;
            }

            &.li-separator {
                width: 10px;
                height: 10px;
                padding: 0;
                background-color: ${colors.border};
                border: 1px solid ${colors.border};
                cursor: default;

                &:hover {
                    background-color: ${colors.border};
                }
            }

            svg {
                font-size: 20px;
                margin-right: 10px;
            }

            &:hover {
                background-color: ${colors.primary};
                color: white;
            }
        }
    }
`;

export const Sidebar = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background-color: ${colors.bgsecondary};
    border-radius: ${effects.radius};
    z-index: 1005;
    display: flex;

    @media (max-width: 636px) {
        flex-direction: column;
    }

    .viewer {
        width: 75%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 20px;

        @media (max-width: 1024px) {
            width: 65%;
        }

        @media (max-width: 636px) {
            height: 30%;
            width: 100%;
        }

        .loader {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        img {
            box-shadow: ${effects.boxshadow};
            background: url(${bgimgpng});

            @media (max-width: 636px) {
                height: 100%;
            }

            @media (min-height: 900px) {
                height: auto;
            }
        }

        embed {
            width: 70%;
            height: 100%;
            background-color: white;
            box-shadow: ${effects.boxshadow};
        }

        pre {
            width: 70%;
            height: 100%;
            box-shadow: ${effects.boxshadow};

            & > .hljs {
                /* overflow: hidden; */
                padding: 10px;
            }

            * {
                margin: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    .description-holder {
        @media (max-width: 1024px) {
            width: 100%;
        }

        @media (max-width: 636px) {
            width: 100%;
        }
    }

    .description {
        width: 25%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${colors.bgprimary};
        border-top-right-radius: ${effects.radius};
        border-bottom-right-radius: ${effects.radius};
        border-left: 1px solid ${colors.border};
        padding: 40px;
        position: relative;

        @media (max-width: 1024px) {
            width: 35%;
        }

        @media (max-width: 636px) {
            width: 100%;
        }

        button.btn-fechar {
            position: absolute;
            border: 0;
            background-color: transparent;
            top: 20px;
            right: 20px;
            font-size: 20px;
            color: ${colors.texton};
        }

        h2 {
            border-bottom: 1px solid ${colors.border};
            margin-bottom: 25px;

            @media (max-width: 1400px) {
                font-size: 17px;
            }
        }

        form {
            display: flex;
            flex-direction: column;

            label {
                margin-bottom: 10px;
                font-weight: bold;
            }

            input {
                height: 40px;
                border-radius: 5px;
                border: 1px solid ${colors.primary};
                padding: 0 10px;
                /* margin-bottom: 25px; */
            }
        }

        button.cta {
            width: 100%;
            height: 40px;
            background-color: ${colors.btnsuccess};
            box-shadow: ${effects.boxshadow};
            color: white;
            font-weight: bold;
            letter-spacing: 1px;
            border: 0;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: 1400px) {
                font-size: 14px;
            }

            svg {
                font-size: 20px;
                margin-right: 10px;
            }
        }

        .separator {
            width: 100%;
            height: 1px;
            border-top: 1px solid ${colors.border};
            margin: 25px 0;

            @media (max-width: 636px) {
                margin-top: 15px;
                margin-bottom: 15px;
            }
        }

        button.btn-use {
            background-color: ${colors.btnprimary};
        }

        button.btn-delete {
            background-color: ${colors.btndanger};
            margin-top: 25px;
        }
    }
`;

export const Backdrop = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    position: fixed;
    left: 0;
    z-index: 2005;
`;

import React, { useState, useEffect } from 'react';

import CButton from '../ComponentButton';
import QRcode from '../QRcode';

import { Backdrop, WrapModal, WrapModalView, Container } from './styles';
import { MdClose } from 'react-icons/md';

export default function ModalQRcode({ close, view, linkPage }) {

  return (    
    <Backdrop>
      {(view)
        ? <WrapModalView className="modal-viewer">
            <CButton
              cstyle='default small closebutton'
              title={(<MdClose />)}
              click={close}
            />
            <Container >
              <h3>Escaneie ou baixe o QR code</h3>
              <QRcode linkPage={linkPage}/>                         
              <CButton
              cstyle='default small closebutton'
              title={(<MdClose />)}
              click={close}
            /> 
            </Container>          
          </WrapModalView>
          
        : <WrapModal>                   
        </WrapModal>}
    </Backdrop>
  );
}

import React, { useState, useEffect, useRef } from 'react';
import { FaImage, FaCog, FaCode, FaUsers, FaComments } from 'react-icons/fa';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import Swal from 'sweetalert2';

import api from '../../services/api-geral';

import Alerta from '../../util/Alerta';

import Header from '../../components/ComponentHeader';
import Aparencia from '../../components/ConfiguracaoSite/Aparencia';
import Cores from '../../components/ConfiguracaoSite/Cores';
import Definicoes from '../../components/ConfiguracaoSite/Definicoes';
import Chat from '../../components/ConfiguracaoSite/Chat';
import Metadados from '../../components/ConfiguracaoSite/Metadados';
import RedesSociais from '../../components/ConfiguracaoSite/RedesSociais';
import Mapa from '../../components/ConfiguracaoSite/Mapa';

import { Container, Tabs } from './styles';

export default function ConfiguracaoSite() {
  const [tabs, setTabs] = useState('aparencia');
  const [data, setData] = useState({});
  const [flagSocials, setFlagSocials] = useState(true);
  const [widthScreen] = useState(window.innerWidth);
  const [idFinalPage, setIdFinalPage] = useState();
  const [hrefSite, setHrefSite] = useState('');
  const [published, setPublished] = useState(false);
  const [mobileTab, setMobileTab] = useState(1);
  const [title, setTitle] = useState();
  const [saved, setSaved] = useState(false);
  const [flagMapa, setFlagMapa] = useState(true);

  const [refresh, doRefresh] = useState(0);

  const [switchTab, setSwitchTab] = useState('aparencia');

  const [showMenu, setShowMenu] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [isDropdown, setIsDropdown] = useState(false);

  const [contentStyle, setContentStyle] = useState('menu');

  useEffect(() => {
    loadInfos();
    getPageId();
  }, []);

  useEffect(() => {
    doRefresh(0);
  }, [tabs]);

  useEffect(() => {
    if (data) {
      setFlagSocials(data.flagSocials);
    }
  }, [data]);

  const getPageId = async () => {
    const response = (await api.get('/page')).data;
    if (response) {
      setIdFinalPage(response.id);
    }
  }

  const loadInfos = async () => {
    const response = (await api.get('/page')).data;

    setData(response);

    if (response) {
      setHrefSite(response.link_page)
    }
  };

  const handleSubmitForm = async () => {
    Swal.fire({
      title: 'Tem certeza?',
      text: 'Publicar as alterações atuais?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (tabs === 'aparencia' || tabs === 'metadados' || tabs === 'cores' || tabs === 'chat' || tabs === 'mapa') {
          doRefresh(prev => prev + 1);
        } else {
          Alerta.success("Alterações publicadas!");
        }
      }
    });
  };

  useEffect(() => {
    if (showSubMenu) {
      setContentStyle('submenu');
    } else if (showMenu && !showSubMenu) {
      setContentStyle('menu');
    } else {
      setContentStyle('none');
    }
  }, [showMenu, showSubMenu, isDropdown]);

  const switchTabs = (tab) => {
    setSwitchTab(tab);
  }

  return (
    <>
      <Header
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        showSubMenu={showSubMenu}
        setShowSubMenu={setShowSubMenu}
        isDropdown={isDropdown}
        setIsDropdown={setIsDropdown}
      />
      <Container>
        <Tabs>
          <div className="content">
            <button onClick={() => switchTabs('aparencia')} className={tabs === 'aparencia' ? 'active' : ''}>{widthScreen <= 575 ? <FaImage /> : 'Meu perfil'}</button>
            {(data) &&
              <>
                <button onClick={() => switchTabs('cores')} className={tabs === 'cores' ? 'active' : ''}>{widthScreen <= 575 ? <FaCog /> : 'Aparência'}</button>
                <button onClick={() => switchTabs('definicoes')} className={tabs === 'definicoes' ? 'active' : ''}>{widthScreen <= 575 ? <FaCog /> : 'Meus links'}</button>
                <button onClick={() => switchTabs('mapa')} className={tabs === 'mapa' ? 'active' : ''}>{widthScreen <= 575 ? <FaUsers /> : 'Localização'}</button>
                <button onClick={() => switchTabs('redes-sociais')} className={tabs === 'redes-sociais' ? 'active' : ''}>{widthScreen <= 575 ? <FaUsers /> : 'Redes Sociais'}</button>
                <button onClick={() => switchTabs('chat')} className={tabs === 'chat' ? 'active' : ''}>{widthScreen <= 575 ? <FaComments /> : 'Contato'}</button>
                <button onClick={() => switchTabs('metadados')} className={tabs === 'metadados' ? 'active' : ''}>{widthScreen <= 575 ? <FaCode /> : 'Para ser encontrado no Google'}</button>
              </>
            }
          </div>
          <div className="content-mobile">
            {tabs === "aparencia" &&
              <>
                <button className="left" disabled={true}><MdKeyboardArrowLeft /></button>
                <h1>Meu perfil</h1>
                <button className="right" onClick={() => { switchTabs('cores') }}><MdKeyboardArrowRight /></button>
              </>
            }
            {tabs === "cores" &&
              <>
                <button className="left" onClick={() => { switchTabs('aparencia') }}><MdKeyboardArrowLeft /></button>
                <h1>Aparencia</h1>
                <button className="right" onClick={() => { switchTabs('definicoes') }}><MdKeyboardArrowRight /></button>
              </>
            }
            {tabs === "definicoes" &&
              <>
                <button className="left" onClick={() => { switchTabs('cores') }}><MdKeyboardArrowLeft /></button>
                <h1>Meus links</h1>
                <button className="right" onClick={() => { switchTabs('mapa') }}><MdKeyboardArrowRight /></button>
              </>
            }
            {tabs === "mapa" &&
              <>
                <button className="left" onClick={() => { switchTabs('definicoes') }}><MdKeyboardArrowLeft /></button>
                <h1>Localização</h1>
                <button className="right" onClick={() => { switchTabs('redes-sociais') }}><MdKeyboardArrowRight /></button>
              </>
            }
            {tabs === "redes-sociais" &&
              <>
                <button className="left" onClick={() => { switchTabs('mapa') }}><MdKeyboardArrowLeft /></button>
                <h1>Redes Sociais</h1>
                <button className="right" onClick={() => { switchTabs('chat') }}><MdKeyboardArrowRight /></button>
              </>
            }
            {tabs === "chat" &&
              <>
                <button className="left" onClick={() => { switchTabs('redes-sociais') }}><MdKeyboardArrowLeft /></button>
                <h1>Contato</h1>
                <button className="right" onClick={() => { switchTabs('metadados') }}><MdKeyboardArrowRight /></button>
              </>
            }
            {tabs === "metadados" &&
              <>
                <button className="left" onClick={() => { switchTabs('chat') }}><MdKeyboardArrowLeft /></button>
                <h1 className="small">Para ser encontrado no Google</h1>
                <button className="right" disabled={true} ><MdKeyboardArrowRight /></button>
              </>
            }
          </div>
        </Tabs>
        <div className="wrap-tabs">
          {/* <div className='title-tabs'>
          <h1>{tabs === 'aparencia' ? 'Aparência' : tabs === 'definicoes' ? 'Definições' : tabs === 'metadados' ? 'Metadados' : tabs === 'redes-sociais' ? 'Redes Sociais' : 'Chat'}</h1>
          <div className='separator'></div>
        </div> */}
          <div className="publicar-content">
            <div>
              {(tabs === 'aparencia' || tabs === 'cores' || tabs === 'metadados' || tabs === 'chat' || tabs === 'mapa') &&
                <button onClick={() => { handleSubmitForm() }} className="publicar">Salvar</button>
              }
              <a href={hrefSite} target="_blank" className="visualizar site">Acessar mini site</a>
            </div>
          </div>
          {tabs === 'aparencia' &&
            <Aparencia
              setSwitchTab={setSwitchTab}
              switchTab={switchTab}
              setTabs={setTabs}
              setMobileTitle={setTitle}
              refresh={refresh}
              doRefresh={doRefresh}
              load={loadInfos}
              tab={tabs}
              data={data}
              reload={loadInfos}
              published={published}
              setSaved={setSaved} />}
          {tabs === 'cores' &&
            <Cores
              setSwitchTab={setSwitchTab}
              switchTab={switchTab}
              setTabs={setTabs}
              setMobileTitle={setTitle}
              refresh={refresh}
              doRefresh={doRefresh}
              load={loadInfos}
              tab={tabs}
              data={data}
              reload={loadInfos}
              published={published}
              setSaved={setSaved} />}
          {tabs === 'definicoes' &&
            <Definicoes
              setSwitchTab={setSwitchTab}
              switchTab={switchTab}
              setTabs={setTabs}
              setMobileTitle={setTitle}
              tab={tabs}
              data={data}
              flagSocials={flagSocials}
              setFlagSocials={setFlagSocials}
              idPage={idFinalPage} />}
          {tabs === 'mapa' &&
            <Mapa
              setSwitchTab={setSwitchTab}
              switchTab={switchTab}
              setTabs={setTabs}
              setMobileTitle={setTitle}
              refresh={refresh}
              tab={tabs}
              data={data}
              reload={loadInfos}
              flagMapa={flagMapa}
              setFlagMapa={setFlagMapa}
              idPage={idFinalPage} />}
          {tabs === 'metadados' &&
            <Metadados
              setSwitchTab={setSwitchTab}
              switchTab={switchTab}
              setTabs={setTabs}
              setMobileTitle={setTitle}
              tab={tabs}
              data={data}
              refresh={refresh}
              doRefresh={doRefresh}
              idPage={idFinalPage}
              reload={loadInfos} />}
          {tabs === 'redes-sociais' &&
            <RedesSociais
              setSwitchTab={setSwitchTab}
              switchTab={switchTab}
              setTabs={setTabs}
              setMobileTitle={setTitle}
              tab={tabs}
              data={data}
              idPage={idFinalPage} />}
          {tabs === 'chat' &&
            <Chat
              setSwitchTab={setSwitchTab}
              switchTab={switchTab}
              setTabs={setTabs}
              refresh={refresh}
              doRefresh={doRefresh}
              setMobileTitle={setTitle}
              tab={tabs}
              data={data}
              idPage={idFinalPage}
              reload={loadInfos} />}
        </div>
      </Container>
    </>
  );
}
import React from 'react';
import Page from '../../components/Page';
import { Container } from './styles';
import Tweets from '../../components/Tweets';

function Twitter() {
  return (
    <Page content={(
      <Container>
        <Tweets />
      </Container>
    )} />
  );
}

export default Twitter;
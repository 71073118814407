import styled from 'styled-components';

import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Wrap = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #11151e;
    box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.47);

    .invisible-div  {
        @media (max-width: 1024px) {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 300px;
            z-index: 10000;
        }

        @media (max-width: 500px) {
            display: none !important;
        }
    }
`;

export const Sidebar = styled.div`
    width: 600px;
    height: 100%;
    background-color: white;
    padding: 10px 20px;

    @media (max-width: 1024px) {
        padding: 20px 20px;
        width: 400px;
        z-index: 8000;
        position: absolute;
    }

    @media (max-width: 500px) {
        padding: 20px 15px;
        width: 100%;
    }

    h1 {
        font-size: 2em;
        margin-bottom: 10px;
    }
`;

export const Search = styled.div`
    width: 100%;
    height: 75px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .display-button {
        position: absolute;
        top: 10px;
        right: 10px;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        border: none;
        svg {
            z-index: 1;
            border-radius: 50%;
            color: black;
            height: 20px;
            width: 20px;
            background-color: white;
        }
    }

    .actions-group {
        cursor: pointer;
        color: ${colors.primary};
        height: 30px;
        display: flex;
        align-items: center;
        font-size: 18px;
    }
`;

export const Perfil = styled.div`
    position: relative;

    img {
        width: 50px;
        height: 50px;
        object-fit: cover;
        object-position: top;
        box-shadow: ${effects.boxshadow};
        border-radius: 50%;

        @media (max-width: 1400px) {
            width: 45px;
            height: 45px;
        }
    }

    svg {
        background-color: ${colors.primary};
        width: 50px;
        height: 50px;
        color: white;
        padding: 10px;
        border-radius: 50%;
        border: 2px solid rgba(255, 255, 255, 0.1);

        @media (max-width: 1400px) {
            width: 45px;
            height: 45px;
        }
    }

    .presence {
        position: absolute;
        bottom: 5px;
        right: 0;

        span {
            width: 8px;
            height: 8px;
            display: block;
            border-radius: 50%;
            border: 3px solid white;
            box-sizing: content-box;

            &.offline { background-color: gray; }
            &.online { background-color: green; }
            &.ausente { background-color: #FFBF00; }
            &.naoPerturbe { background-color: #FF0000; }
        }
    }
`;

export const Form = styled.div`
    width: 70%;
    background-color: ${colors.primary_background};
    height: 40px;
    border-radius: 20px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    box-shadow: ${effects.boxshadow};

    @media (max-width: 1400px) {
        height: 30px;
    }

    svg {
        position: absolute;
        top: calc(20px - 11px);
        left: 10px;
        font-size: 22px;
        color: ${colors.primary};

        @media (max-width: 1400px) {
            top: 5px;
        }
    }

    input {
        background-color: transparent;
        width: 85%;
        height: 100%;
        border: 0;
        color: ${colors.primary};
        font-size: 12px;
        padding-left: 10px;
    }
`;

export const ListaConversa = styled.div`
    width: 100%;
    height: calc(100% - 75px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
    position: relative;

    hr {
        width: 97%;
    }

    @media (max-width: 500px) {
        padding: 0;
    }

    &::-webkit-scrollbar {
        width: 2px;
        height: 5px;
    }

    &::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
    }

    &::-webkit-scrollbar-thumb {
        background: ${colors.border};
        border-radius: ${effects.radius};
        border: 0px none #ffffff;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    &::-webkit-scrollbar-thumb:active {
        background: ${colors.border};
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border: 0px none #ffffff;
        border-radius: 50px;
    }

    &::-webkit-scrollbar-track:hover {
        background: #f1f1f1;
    }

    &::-webkit-scrollbar-track:active {
        background: #f1f1f1;
    }
    
    &::-webkit-scrollbar-corner {
        background: transparent;
    }

    .conversaClicker {
        cursor: pointer;
        margin-right: 5px;
    }

    .setorSeparator{
        width: 100%;
        display: flex;
        color: ${colors.primary};
        font-weight: bold;
        font-size: 14px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        @media (max-width: 1400px) {
            font-size: 12px;
        }

        .setor-name {
            text-transform: uppercase;
            position: relative;
            color: ${colors.primary};
        }

        .title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            svg { 
                font-size: 25px;
                
                @media (max-width: 1400px) {
                    font-size: 20px;
                }
            }
        }

        .actions-group {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30px;

            svg { font-size: 17px; }
        }

        .dropdown { cursor: pointer; }
    }

    .box-actions {
        position: relative;
    }

    .close-tools {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
      }

      .tools {
        background-color: ${colors.bgprimary};
        display: flex;
        flex-direction: column;
        padding: 5px;
        box-shadow: ${effects.boxshadow};
        position: absolute;
        width: 215px;
        margin-bottom: 10px;
        border-radius: ${effects.radius};
        z-index: 1200;

        small {
          color: ${colors.primary};
          font-size: 12px;
          cursor: pointer;
          display: flex;
          align-items: center;
          width: 100%;
          border-bottom: 1px solid ${colors.primary};
          padding: 10px 0;

          svg { margin-right: 10px; font-size: 15px; }

          &:last-child { border: 0; }

        }
    }

`;

export const SelectSetor = styled.div`
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;

    .selected {
        width: 100%;
        height: 50px;
        border: 1px solid ${colors.primary};
        border-radius: 25px;
        display: flex;
        justify-content: center;
        align-items: center;

        strong {
            color: ${colors.primary};
        }

        svg {
            font-size: 25px;
            color: ${colors.primary};
        }
    }

    ul {
        display: none;
        color: ${colors.text_primary};
        background-color: #fff;
        list-style: none;
        padding: 0;
        width: 80%;
        position: absolute;
        left: 10%;
        top: 75px;
        z-index: 1050;
        box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);

        li {
            border-bottom: 1px solid #d9d9d9;
            padding: 20px 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            
            .title {
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    margin-right: 10px;
                }
            }

            &:last-child {
              border: none;
            }
        }

        &.show {
            display: block;
        }
    }
`;
import React, { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import { RiInformationLine } from 'react-icons/ri';
import { TiTimes } from 'react-icons/ti';

import ModalInformation from '../ConfiguracaoSite/ModalInformation';

import { Container, Backdrop } from './styles';

function InputColor({ id, opened, setOpened, inside = false, color, setColor, type, text, content, right = false, hasImg }) {
  const [showPicker, setShowPicker] = useState(false);
  const [modalInformation, setModalInformation] = useState('');

  return (
    <>
      {/* {showPicker && (<Backdrop onClick={() => setShowPicker(false)} />)} */}
      <Container color={color} type={type} inside={inside} hasImg={hasImg}>
        {(type !== 'border') && (
          inside === false ? (
            <div className='info-modal'>
              <RiInformationLine onMouseOver={() => setModalInformation('show')} onMouseOut={() => setModalInformation('')} />
              <ModalInformation modalClass={modalInformation} top='0' left={right === true ? '-200px' : '40px'} content={content} />
            </div>
          ) : ''
        )}
        {inside === false ? <span className="color-label">{text}</span> : ''}
        {!hasImg && (
          <div className="wrap-colorpicker">
            <button type="button" onClick={() => setShowPicker(true)}></button>
            {showPicker && (
              <div className={right === false ? "colorpicker" : "colorpicker-right"}>
                <div className="close-input-color" type="button" onClick={() => setShowPicker(false)}>
                  <TiTimes />
                </div>
                <SketchPicker color={color} onChange={setColor} disableAlpha={true} />
              </div>
            )}
          </div>
        )}
      </Container>
    </>
  );
}

export default InputColor;
import React, { useState, useEffect, memo } from 'react';
import api from '../../services/api-geral';
import socket from '../../services/socket';
import { sessionGet } from '../../session';
import SocketCommentsFeed from '../../socket/SocketCommentsFeed';

import log from '../../util/log';

import {
    RiDiscussLine,
    RiThumbUpLine,
    RiArrowDownSLine,
    RiImageLine,
    RiThumbUpFill,
    RiDiscussFill
} from 'react-icons/ri';

import { Container, Infos, Interacoes, Comments, BoxDummy } from './styles';
import { Content, DropzoneContainer, AreaToClose } from '../ModalPostFeed/styles';

import NCommentFeed from '../NCommentFeed';
import ModalCommentPost from '../ModalCommentPost';
import CommentPost from '../CommentPost';
import NModalViewLike from '../NModalViewLike';
import SocketLikesFeed from '../../socket/SocketLikesFeed';
import SocketViewsFeed from '../../socket/SocketViewsFeed';

import ModalShowImage from '../ModalShowImage';

function NCommentsFeed({ id_post, id_autor, post, viewmode = false, setHasChangesModal, setImageId, setImagePath }) {
    const [showComments, setShowComments] = useState(false);
    const [comments, setComments] = useState([]);

    const [page, setPage] = useState(0);
    const [lastPage, setLastPage] = useState(0);
    const [countComment, setCountComment] = useState(0);
    const [showBtnLoadComment, setShowBtnLoadComment] = useState(false);

    const [countLikes, setCountLikes] = useState(0);
    const [likes, setLikes] = useState([]);
    const [liked, setLiked] = useState(false);

    const [countViews, setCountViews] = useState(0);
    const [views, setViews] = useState([]);

    const [showModalEdit, setShowModalEdit] = useState(false);
    const [editedComment, setEditedComment] = useState({});

    const [showModalVL, setShowModalVL] = useState(false);
    const [type, setType] = useState('');
    const [typeModal, setTypeModal] = useState('');

    const [showModalImage, setShowModalImage] = useState(false);

    const [pathImage, setPathImage] = useState('');
    const [editedImage, setEditedImage] = useState({});

    const [uploadComment, setUploadComment] = useState(false);

    useEffect(() => {
        viewPost();
        if (viewmode === true) { setShowComments(true); }
    }, [viewmode]);
    useEffect(() => {
        verifyLike()
    }, [likes]);
    useEffect(() => { loadComments() }, [page]);
    useEffect(() => { verifyPaginationButton(); }, [countComment, page]);

    useEffect(() => {

        getLikes();
        getViews();
    }, [])

    const handleComments = () => {
        if (showComments === false) {
            changePage(0);
        }

        setShowComments(!showComments);
    }

    const loadComments = async () => {
        setTimeout(async () => {
            let response = '';
            if (viewmode === false) {
                response = (await api.get(`/comments/${id_post}/${page}`)).data;
            } else {
                response = (await api.get(`/comments/${id_post}/all`)).data;
            }
            setCountComment(response.countComments);

            if (page === 0) setComments(response.comments);
            else setComments([...comments, ...response.comments]);
        }, 200)

        /*if (viewmode == false) {
            setComments(post.comments);
            setCountComment(post.comments.length)
        } else {
            const response = (await api.get(`/comments/${id_post}/all`)).data;
            setCountComment(response.countComments);

            if (page == 0) setComments(response.comments);
            else setComments([...comments, ...response.comments]);
        }*/

    }

    const deleteComment = async (comment) => {
        const response = (await api.delete(`/comments/${comment.id}`)).data;
        loadComments('socket');
    }

    const verifyPaginationButton = () => {
        if (viewmode === false) {
            const numberPages = (countComment / 3);
            var lastPage = 0;
            var pagina = page;

            if (numberPages % 1 === 0) {
                pagina = pagina + 1;
                lastPage = numberPages;
            } else lastPage = parseInt(numberPages);

            if (pagina === (lastPage)) setShowBtnLoadComment(false);
            else setShowBtnLoadComment(true);
        } else {
            setShowBtnLoadComment(false);
        }
    }

    const changePage = numberPage => setPage(numberPage);

    const likePost = async () => {
        const response = (await api.post('likes', { id_post })).data;

        if (response.func === 'like') {
            setLiked(true);
            log('curtiu um post no feed.');
        } else {
            setLiked(false);
            log('removeu a curtida em um post no feed.')
        }
    }



    const verifyLike = () => {
        setLiked(false);

        likes.map(like => {
            if (like.user.id === sessionGet('idUser')) {
                setLiked(true);
            }
        });
    }

    const viewPost = async () => {
        if (id_autor !== sessionGet('idUser')) {

            const response = (await api.post('/views', { id_post })).data;

            if (response) {
                log('visualizou um post no feed.');
            }
        }
    }

    const getLikes = async (method) => {
        if (method === 'socket') {
            const response = (await api.get(`/likes/${id_post}`)).data;
            setLikes(response.likes);
            setCountLikes(response.count);
        } else {
            if (post.likes) {
                setLikes(post.likes);
                setCountLikes(post.likes.length);
            }
        }
    }

    const getViews = async (method) => {
        if (method === 'socket') {
            const response = (await api.get(`/views/${id_post}`)).data;
            setViews(response.views);
            setCountViews(response.count);
        } else {
            setViews(post.views);
            setCountViews(post.views.length);
        }
    }

    const markRead = async () => {
        await api.put(`/markread/${id_post}`, {
            method: 'likepostfeed'
        });
    }

    return (
        <>
            <SocketCommentsFeed loadComments={loadComments} idPost={post.id} />
            <SocketCommentsFeed loadComments={loadComments} idPost={post.updatedAt} />
            {showModalEdit && <ModalCommentPost
                id_post={id_post}
                type={typeModal}
                image={editedImage}
                comment={editedComment}
                reload={loadComments}
                uploadComment={uploadComment}
                setUploadComment={setUploadComment}
                setHasChangesModal={setHasChangesModal}
                setImageId={setImageId}
                setImagePath={setImagePath}
                closeModal={() => setShowModalEdit(false)}
            />}
            {showModalImage && <ModalShowImage img={pathImage} close={() => setShowModalImage(false)} />}

            {<SocketLikesFeed getLikes={getLikes} idPost={id_post}></SocketLikesFeed>}
            {<SocketViewsFeed getViews={getViews} idPost={id_post}></SocketViewsFeed>}

            <Container>
                <Infos>
                    <div onClick={() => { setShowModalVL(true); setType("like"); markRead() }} className="dropdown">
                        {countLikes} curtidas

                        <div className="likes">
                            {countLikes > 0 ? (
                                <>
                                    {likes.map((like, index) => index <= 4 ? <span key={like.id}>{like.user.name}</span> : "")}
                                </>
                            ) : <span>Sem curtidas</span>}
                            {countLikes > 5 && <span>e mais {countLikes - 5} curtidas</span>}
                        </div>
                    </div>
                    <div onClick={() => { setShowModalVL(true); setType("view") }} className="dropdown">
                        {countViews} visualizações
                        <div className="views">
                            {countViews > 0 ? (
                                <>
                                    {views.map((view, index) => index <= 4 ? <span key={view.id}>{view.user.name}</span> : "")}
                                </>
                            ) : <span>Sem visualizações</span>}
                            {countViews > 5 && <span>e mais {countViews - 5} visualizações</span>}
                        </div>
                    </div>
                    {showModalVL && <NModalViewLike closeModal={() => setShowModalVL(false)} interactions={type === "like" ? likes : views} type={type} />}
                </Infos>
                <hr />
                <Interacoes>
                    <button className={liked ? 'active' : ''} onClick={() => likePost()}>
                        <div className="icon">
                            {!liked && <RiThumbUpLine />}
                            {liked && <RiThumbUpFill />}
                        </div>
                        
                        {liked ? <span>Curtido</span> : <span>Curtir</span>}
                    </button>
                    <button className={showComments ? 'active' : ''} onClick={handleComments}>
                        <div className="icon">
                            {!showComments && <RiDiscussLine />}
                            {showComments && <RiDiscussFill />}
                        </div>
                        <span>
                            Comentários ({countComment})
                        </span>
                    </button>
                </Interacoes>
                </Container >
             
                

                {
                    showComments && (
                     <>
                        <Comments>
                            <div className="box-make-comment">
                                <CommentPost
                                    id_post={id_post}
                                    type={typeModal}
                                    image={editedImage}
                                    comment={editedComment}
                                    closeModal={() => setShowModalEdit(false)}
                                    reload={loadComments}
                                    uploadComment={uploadComment}
                                    setUploadComment={setUploadComment}
                                    setHasChangesModal={setHasChangesModal}
                                    setImageId={setImageId}
                                    setImagePath={setImagePath}
                                />
                            </div>

                            {comments.length > 0 && (
                                <>
                                    {comments.map(comment => <NCommentFeed
                                        key={comment.id}
                                        comment={comment}
                                        post={post} 
                                        setHasChangesModal={setHasChangesModal} 
                                        setImageId={setImageId} 
                                        setImagePath={setImagePath}
                                        openImg={(image) => { setShowModalImage(true); setPathImage(image) }}
                                        editar={(image) => { setEditedComment(comment); setEditedImage(image); setShowModalEdit(true); setTypeModal('edit'); }}
                                        deletar={() => { deleteComment(comment); }} />
                                        )}
                                          
                                    {showBtnLoadComment && <div className="btn-carregar-comments" onClick={() => changePage(page + 1)}>Carregar mais comentários <RiArrowDownSLine /></div>}
                                </>
                            )}
                        </Comments>
                    </>
                    )
                }
        </>
    );
};

export default NCommentsFeed;
import React from "react";

export default function Underline({ fill }) {
  return (
    <svg version="1.1" viewBox="0 0 230 230" enableBackground="new 0 0 230 230" fill={fill}>
      <g>
        <path d="M61.638,164.165C75.236,175.39,93.257,181,115.458,181c21.955,0,39.679-5.61,53.239-16.835   C182.254,152.942,189,137.13,189,116.731V0h-42v116.731c0,11.06-2.501,19.212-8.03,24.454c-5.529,5.244-13.284,7.864-23.524,7.864   c-10.322,0-18.312-2.642-23.965-7.926C85.829,135.841,83,127.711,83,116.731V0H41v116.731C41,137.13,48.039,152.942,61.638,164.165   z" />
        <rect width="230" y="197" height="33" />
      </g>
    </svg>
  )
}
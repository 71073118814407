import styled from 'styled-components';
import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Login = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .panel-login {
        width: 60%;
        height: 70%;
        display: flex;
        box-shadow: ${effects.boxshadow};

        @media (max-width: 1000px) {
            flex-direction: column;
        }

        @media (max-width: 666px) {
            width: 80%;
            height: 80%;
        }

        @media (max-width: 415px) {
            width: 90%;
            height: 90%;
        }

        .banner {
            padding: 10px;
            width: 50%;
            height: 100%;
            background-color: ${colors.twitter};
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: white;

            h1 {
                text-align: center;
            }
        
            svg {
                font-size: 75px;
                margin-bottom: 25px;
            }

            @media (max-width: 1400px) {
                h1 { font-size: 20px }
            }

            @media (max-width: 1000px) {
                height: 40%;
                width: 100%;
            }

            @media (max-width: 415px) {
                height: 35%;
                svg {
                    font-size: 50px;
                    margin-bottom: 15px;
                }
            }
        }

        .form-login {
            width: 50%;
            height: 100%;
            background-color: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @media (max-width: 1000px) {
                height: 60%;
                width: 100%;
            }

            @media (max-width: 415px) {
                height: 65%;
            }

            h1 {
                font-size: 20px;
                width: 80%;
                margin-bottom: 25px;
                text-align: justify;

                @media (max-width: 1400px) {
                    font-size: 16px;
                }

                @media (max-height: 600px) {
                    font-size: 14px;
                    margin-bottom: 10px;
                }

            }

            p {
                width: 80%;
                text-align: justify;
                margin-bottom: 25px;

                @media (max-width: 1400px) {
                    font-size: 14px;
                }

                @media (max-height: 600px) {
                    margin-bottom: 10px;
                }
            }
        }
    }

    button.btn-login {
        border: 0;
        background-color: ${colors.twitter};
        padding: 10px;
        color: white;
        display: flex;
        align-items: center;
        box-shadow: ${effects.boxshadow};

        svg {
            margin-right: 5px;
            font-size: 20px;
        }

        span {
            margin-left: 5px;
        }
    }
`;

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    overflow-y: scroll;

    & > .head {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    @media (max-width: 725px) {
        .head {
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
    }
`;

export const Perfil = styled.div`
    width: 20%;
    height: 160px;
    box-shadow: ${effects.boxshadow};
    border-radius: 5px;
    margin-right: 10px; 
    
    @media (max-width: 1400px) {
        width: 30%;
    }

    @media (max-width: 950px) {
        width: 35%;
    }

    @media (max-width: 825px) {
        width: 37%;
    }

    @media (max-width: 725px) {
        height: 100px;
        width: calc(70% + 20px);
        margin-right: 0px;
        margin-bottom: 20px;
    }

    @media (max-width: 500px) {
        width: 95%;
    }
    
    .head {
        background: ${props => props.bg ? `url(${props.bg})` : colors.twitter};
        width: 100%;
        height: 55%;
        position: relative;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        display: flex;
        align-items: center;

        @media (max-width: 725px) {
            height: 100%;
        }

        .filter {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            background-color: rgba(0, 0, 0, 0.7);
            
            img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                box-shadow: ${effects.boxshadow};
                z-index: 1000;
                margin-left: 10px;
            }
            
            .logout {
                position: absolute;
                top: 55px;
                right: 8px;
                height: 25px;
                padding: 0 10px;
                font-size: 9px;
                text-transform: uppercase;
                font-weight: bold;
                border: 0;
                background-color: #e0245e;
                letter-spacing: 1px;
                color: white;
                border-radius: 5px;
                box-shadow: ${effects.boxshadow};

                @media (max-width: 725px) {
                    top: 65%;
                }
            }
            
            .names {
                margin-left: 10px;
                letter-spacing: 1px;
                
                h2 {
                    font-size: 16px;
                    color: white;
                }
                
                span {
                    font-weight: bold;
                    color: white;
                    font-size: 13px;
                    opacity: 0.5;
                }

                @media (max-width: 1400px) {
                    h2 { font-size: 14px }
                    span { font-size: 12px; }
                }

            }
        }
    }
    
    .details {
        width: 100%;
        height: 45%;
        background-color: white;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        @media (max-width: 725px) {
            height: 0;
        }

        .number {
            display: flex;
            flex-direction: column;
            justify-content: center;
            letter-spacing: 1px;
            text-align: center;

            strong {
                font-size: 15px;
                margin-bottom: 5px;
            }

            span {
                font-size: 10px;
                font-weight: bold;
                text-transform: uppercase;
                color: #999;
            }

            @media (max-width: 725px) {
                display: none;
            }
        }

    }
`;

export const Form = styled.form`
    width: 30%;
    height: 160px;
    background-color: white;
    border-radius: 5px;
    box-shadow: ${effects.boxshadow};
    margin-left: 10px;

    @media (max-width: 1400px) {
        width: 40%;
    }

    @media (max-width: 950px) {
        width: 35%;
    }

    @media (max-width: 825px) {
        width: 33%;
    }

    @media (max-width: 725px) {
        width: calc(70% + 20px);
        margin-left: 0px;
    }

    @media (max-width: 500px) {
        width: 95%;
    }

    .head {
        background-color: white;
        width: 100%;
        height: 40px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid ${colors.border};

        h3 {
            font-weight: bold;
            font-size: 13px;
            margin-left: 10px;
            letter-spacing: 1px;
            color: #333;
            display: flex;
            align-items: center;

            @media (max-width: 725px) {
                margin-left: 0;
            }

            svg {
                font-size: 20px;
                color: ${colors.twitter};
                margin-right: 5px;
            }
        }
    }

    .body {
        width: 100%;
        height: calc(100% - 40px);
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        textarea {
            width: 100%;
            border: 0;
            padding: 10px;
            color: #222;
        }

        .wrap-botoes {
            display: flex;
            align-items: center;
            margin-top: 20px;
            
            button {
                height: 30px;
                font-size: 10px;
                font-weight: bold;
                text-transform: uppercase;
                letter-spacing: 1px;
                line-height: 30px;
                color: white;
                background-color: ${colors.twitter};
                box-shadow: ${effects.boxshadow};
                border: 0;
                border-radius: 5px;
                padding: 0 10px;

                &.addImage {
                    color: #333;
                    background-color: ${colors.bgsecondary};
                    margin-right: 10px;
                }
            }

            #fileinput {
                display: none;
            }
        }
    }
`;

export const ListaTweets = styled.div`
    width: calc(50% + 20px);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    @media (max-width: 1400px) {
        width: calc(70% + 20px);
    }

    @media (max-width: 500px) {
        width: 95%;
    }
`;
import styled, { css } from 'styled-components';
import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Wrap = styled.div`
  ${props => (props.color === 'info') ? css`
    background: ${colors.primary};
  ` : (props.color === 'danger') ? css`
    background: ${colors.btn_danger};
  ` : css`
    background: ${colors.btn_ok};
  `}

  ${props => (props.custom) && css`
    top: ${props => (props.custom[0])};
  `}

  ${props => (props.custom) && css`
    right: ${props => (props.custom[1])};
  `}

  ${props => (props.custom) && css`
    bottom: ${props => (props.custom[2])};
  `}

  ${props => (props.custom) && css`
    left: ${props => (props.custom[3])};
  `}

  @media (min-width: 1200px) and (max-width: 1440px) {
    ${props => (props.custom_large_screen) && css`
      top: ${props => (props.custom_large_screen[0])};
    `}

    ${props => (props.custom_large_screen) && css`
      right: ${props => (props.custom_large_screen[1])};
    `}

    ${props => (props.custom_large_screen) && css`
      bottom: ${props => (props.custom_large_screen[2])};
    `}

    ${props => (props.custom_large_screen) && css`
      left: ${props => (props.custom_large_screen[3])};
    `}
  }

  ${props => (props.context === 'in') ? css`
      width: 280px;
    ` : css`
      width: 280px;
  `}

  ${props => (props.context !== 'in') && css`
    position: absolute;
  `}

  position: absolute;
  display: block;
  z-index: 1050;

  padding: 3px;
  border-radius: 10px;

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1px 0;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;

    @media (max-width: 1024px) {
      left: -250px;
    }

    ${props => (props.variant === 'custom') && css`
      .icon {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        svg { color: #fff; font-size: 20px; }
      }
    `}
  }

  .box-alert {
    display: flex;
    align-items: center;
    background: #f4f4f4;
    border-radius: 10px;
    padding: 10px;

    ${props => (props.variant === 'default') && css`
      .icon {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;

        svg {
          color: ${colors.primary};
          ${props => (props.color === 'info') ? css`
            color: ${colors.primary};
          ` : (props.color === 'danger') ? css`
            color: ${colors.btn_danger};
          ` : css`
            color: ${colors.btn_ok};
          `}
          font-size: 30px;
        }
      }
    `}

    .header {
      ul {
        margin: 10px 0;

        li {
          list-style: none;
        }
      }
    }
  }
`;

import React from 'react';
import Default from '../../assets/default.png';
import { Wrap } from './styles';
import getFiles from '../../util/getFiles';

function ListaAniversariantes({ dataAniversario }) {
  const date = new Date();
  const currentMonth = date.getMonth() + 1;

  return (
    <>
      {
        dataAniversario.map(dataAniversario => {
          let monthsArray = String(dataAniversario.birth_date);
          const birthDateUser = monthsArray.split(/[\s-]+/);

          if (birthDateUser[1] == currentMonth)
            return <>
              <Wrap>
                <li key={dataAniversario}>
                  <div className="icon" title="feed">
                    <img className='image-profile' src={dataAniversario.id_file === null ? Default : getFiles(dataAniversario.files.path)}></img>
                    <span className='name-user'>{dataAniversario.name}</span>
                    <span className='birthdate'> {birthDateUser[2] + "/" + birthDateUser[1]}</span>
                  </div>
                </li>
              </Wrap>
            </>
        })
      }
    </>
  );
}

export default ListaAniversariantes;
import styled from "styled-components";


export const TextContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: .5rem;
    cursor: pointer;


&.active {
    background-color: #CAC6CA;
    display: flex;
    clip-path: polygon(0 0, 85% 0, 100% 100%, 0% 100%);
    padding: 4px 3rem 4px 1rem;
    border-radius: 30px 0px 0px 0px / 30px 0px 0px 0px;


    @media (max-width: 768px) {
        padding: 4px 1.5rem 4px 1rem;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}
`

export const Container = styled.section`
display: flex;
position: relative;
`

export const BorderBottom = styled.div`
position: absolute;
border-radius: 0px 100% 0px 0px / 0px 150px 0px 0px;
height: 8px;
width: 100%;
bottom: 0;
background-color: #CAC6CA;



`

export const Title = styled.h3`

    font-weight: normal;
    font-size: 20px;
    padding: 2px 0;

    span {
        color: #933791;
        font-weight: normal;
    }

    @media (max-width: 1378px) {
        font-size: 14px;
    }

    @media (max-width: 768px) {
        font-size: 12px;
    }

    &.active {
        font-size: 24px;
        font-weight: bold;

        @media (max-width: 1378px) {
        font-size: 18px;
        }

        @media (max-width: 768px) {
        font-size: 14px;
        }
    }

&.sub-section {
    font-weight: normal;
    font-size: 16px;


    @media (max-width: 1378px) {
        font-size: 12px;
    }

    @media (max-width: 768px) {
        font-size: 12px;
    }
}

&.sub-section.active {
    font-size: 18px;
    font-weight: bold;


    @media (max-width: 1378px) {
    font-size: 16px;
    }

    @media (max-width: 768px) {
    font-size: 12px;
    }
}
`
import React, { useEffect, useState } from 'react';

import Page from '../../components/Page';

import { Container } from './styles';

import api from '../../services/api-geral';

export default function CriarEmpresa() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [endpoint, setEndpoint] = useState('');
  const [modulos, setModulos] = useState([1, 2, 3]);
  const [disable, setDisable] = useState(false);

  const submit = async () => {
    const response = (await api.post('/empresa', {
      nameUsuario: name,
      emailUsuario: email,
      senhaUsuario: senha,
      nameEmpresa: empresa,
      endpoint: endpoint,
      limitQuantityUsers: 50,
      modulos,
    })).data;
    if (response.type === 'success') {
      setDisable(true);
    }
  }

  return (
    <Page content={(
      <>
        <Container>
          <input onChange={(e) => { setName(e.target.value) }} placeholder="Nome usuário"></input>
          <input onChange={(e) => { setEmail(e.target.value) }} placeholder="E-mail"></input>
          <input onChange={(e) => { setSenha(e.target.value) }} placeholder="Senha"></input>
          <input onChange={(e) => { setEmpresa(e.target.value) }} placeholder="Nome empresa"></input>
          <input onChange={(e) => { setEndpoint(e.target.value) }} placeholder="Endpoint"></input>
          <button id="button-criar" type="button" onClick={() => submit()} disabled={disable}>Criar</button>
        </Container>
      </>
    )} />
  );
}

import React, { useEffect, useState } from 'react';

import { Emoji, Container, NoteContainer, Content, Title, AreaToClose, AreaToCloseEmoji } from './styles';
import { format } from 'date-fns';
import Option from '../RegistroLembretes/Option';
import api from '../../../services/api-geral'

import emojis from '../../../util/emojis';
import log from '../../../util/log';

import { RiCloseLine } from 'react-icons/ri';
import Swal from 'sweetalert2';


export default function ModalEdit({setConfirmaEdit, closeModal, note}) {  
  const [noteType, setNoteType] = useState(note.type);
  const [extraType, setExtraType] = useState(note.type_outro);
  const [noteState, setNoteState] = useState(note.note);
  const [isOpen, setIsOpen] = useState(false);
  const [hasChangesModal, setHasChangesModal] = useState(false);
  
  const dateValueStart = note.start.split(" ")[0];
  const dateValueEnd = note.end.split(" ")[0];

  const [startDate, setstartDate] = useState(dateValueStart);
  const [endDate, setEndDate] = useState(dateValueEnd);

  useEffect(()=> {
    if( noteType != note.type || startDate != dateValueStart || endDate != dateValueEnd || noteState != note.note ){
      setHasChangesModal(true)
  
    } else {
      setHasChangesModal(false)
    }
  },[noteState, noteType, endDate, noteState])

	const clickToExit = (e) => {
		showSwal()
	}

	const showSwal = () => {
		if (hasChangesModal) {
			Swal.fire({
				title: 'Atenção',
				text: 'Você possui alterações não publicadas, continuar mesmo assim?',
				icon: 'question',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Descartar',
				cancelButtonText: 'Não'
			}).then(async (result) => {
				if (result.isConfirmed) {
          setConfirmaEdit(false)
				}
			});
		} else {
			closeModal();
		}
	}

  const infoOption = (info) => {
    setNoteType(info)
  }

  const dataStart = format(new Date(note.start), 'dd/MM/yyyy');
  const dataEnd = format(new Date(note.end), 'dd/MM/yyyy');

  const insertEmoji = (emoji) => {
      setNoteState((prevMessage) => prevMessage + emoji.character);
      handleClickEmoji()
    };

    const handleClickEmoji = () => {
      setIsOpen(!isOpen);
    };

    const clickToExitEmoji = (e) => {
      setIsOpen(!isOpen);
    }

    const handleStarDate = (event) => {
      const novaData = event.target.value;
      setstartDate(novaData);
    };
    const handleEndDate = (event) => {
      const novaData = event.target.value;
      setEndDate(novaData);
    };

  const updatePost = async e => {
      const response = (await api.put(`/lembrete-edit/${note.id}`, { noteState, noteType, extraType, startDate, endDate})).data;
      log('editou um post no feed.');
  }

  return (
    <Container>
             {isOpen && <Emoji>
                    <div >
                    <button onClick={handleClickEmoji}>x</button>
                    </div>
                    <ul>
                      {emojis.map((emoji, index) => (
                        <li key={index} onClick={() => insertEmoji(emoji)}>
                          {emoji.character}
                        </li>
                      ))}
                    </ul>
                    <AreaToCloseEmoji onClick={(e) => { clickToExitEmoji(e) }} />
              </Emoji>
              }
        <Content>
        <button className="exit" onClick={(e) => { clickToExit(e) }} >
			<RiCloseLine />
		</button>
          <div className='wrap'>
            <NoteContainer>
              <Title>Lembrete</Title>
                <div className="text-input">         
                  <textarea 
                    className='text'
                    value={noteState}
                    onChange={(e) => setNoteState(e.target.value)}
                    maxLength={150}
                  />
                  <button onClick={handleClickEmoji}>
                  🙂
                  </button>
                </div>                    
                
              <div className="footer">
                <div className="time" >
                  <Title>Tempo Ativo </Title>
                  <div className='date-wrap'>
                    <div className='date-container'>
                    <label htmlFor="">inicio definido: 
                        <div className='info-wrap'>         
                          {startDate === note.start ?  <small>{dataStart}</small> : <small>{''}</small>}   
                        </div>    
                      </label>
                      <input type="date" value={startDate} className="input-date" onChange={handleStarDate} />
                    </div>


                    <div className='date-container'>
                      <label htmlFor="">final definido: 
                        <div className='info-wrap'>
                          {endDate === note.end ?  <small>{dataEnd}</small> : <small>{''}</small>}   
                        </div>
                      </label>
                      <input type="date" placeholder='data'  value={endDate} className="input-date" onChange={handleEndDate} />
                    </div>
                   </div>
          
                </div>
                <div className='wrap'> 
                <div className="type" >
                  <Title>Tipo*</Title>
                  {!noteType ?  <small>Defina a opção</small> : <small>{''}</small>}
                
                  <Option TypeOfNote={infoOption} initialValue={noteType}/>
                  {noteType === 'Outro' ? (
                    <> 
                    <input type="text" value={extraType} onChange={(e) => setExtraType(e.target.value)}/>
                    </>  
                  ) :   
                  <> 
                  <input type="text" className="input-nada" value=' '/>
                  </> }
                </div>

                <button className={!noteType ? 'disabled' : 'create-button' } disabled={!noteType} onClick={() => { setConfirmaEdit(false); updatePost() }}>Editar</button>

                </div>

              </div>
            </NoteContainer>
          </div>

     
        </Content>
        <AreaToClose onClick={(e) => { clickToExit(e) }} />
    </Container>
  );
};


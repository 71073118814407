import React, { useEffect, useState } from 'react';

import ItensMenu from '../../contents/menu';
import pushNotification from '../../util/pushNotification';

import api from '../../services/api-geral';
import socket from '../../services/socket';
import { sessionGet } from '../../session';
import SocketNotificacoes from '../../socket/SocketNotificacoes';

import IconeLogo from '../../assets/icone_logo.png';

import { Wrap, Dropdown, Button, WrapOptions } from './styles';

import { RiCloseLine } from 'react-icons/ri';
import { set } from 'date-fns';
import log from '../../util/log';

export default function Menu({ showMenu, setShowSubMenu, showSubMenu, isDropdown, setIsDropdown, showMobileMenu, setShowMobileMenu, hasChanges, setShowModal, setPathAction }) {
  const [path, setPath] = useState(window.location.pathname);
  const [itensDropdown, setItensDropdown] = useState([]);
  const [showWrapOptions, setShowWrapOptions] = useState(false);

  const [notifications, setNotifications] = useState();

  const [itens] = useState(ItensMenu);

  const [notifyChat, setNotifyChat] = useState(false);
  const [notifyForm, setNotifyForm] = useState(false);
  
  const [itemClicked, setItemClicked] = useState("");

  useEffect(() => {
    getNotifications();
  }, [])

  useEffect(() => {
    if (path === itemClicked) {
      setShowMobileMenu(false);
      setItemClicked("");
    }
  }, [showMobileMenu, path, itemClicked]);

  useEffect(() => {
    if (itemClicked === '/conversas') {
      if (path.includes('/conversas')) {
        document.getElementById("sideBarChat").style.display = "block";
      }
    }
  }, [path, itemClicked]);

  const getNotifications = async (method) => {
    const feed = document.getElementById('Feed');
    const chat = document.getElementById('Chat equipe');
    const atendimento = document.getElementById('Central de atendimento');

    const response = (await api.get(`/notifications/30/0`)).data;
    setNotifications(response);

    let responseMenu;

    var count = 0;

    responseMenu = (await api.get('/msgs-at-all-unread')).data;

    if (responseMenu.count > 0) {
      if (!window.location.href.includes('atendimentos-ao-cliente')) {
        if (atendimento) {
          atendimento.style.display = "block";
          setNotifyChat(true);
        }
      }
    }

    response.notificacoes.map(n => {

      if (n.flag_read == 0 && (n.local == 'feed' || n.local == 'comentários do feed' || n.local == 'likepostfeed')) {
        if (!window.location.href.includes('feed')) {
          if (feed) {
            feed.style.display = "block";
          }
          count = count + 1;
        }
      } else if (n.flag_read == 0 && n.local == 'chat') {
        // pushNotification("Nova mensagem no Merx", "Alguem enviou algo... Vai dar uma bizoiada!")
        if (!window.location.href.includes('conversas')) {
          if (chat) {
            chat.style.display = "block";
          }
          count = count + 1;
        }
      } else if (n.flag_read == 0 && n.local == 'Formulário de Contato') {
        if (!window.location.href.includes('formulario-chamado')) {
          if (atendimento) {
            atendimento.style.display = "block";
            setNotifyForm(true);
            count = count + 1;
          }
        }
      }
    })

    if (count == 0) {
      if (feed != null & chat != null) {
        feed.style.display = "none";
        chat.style.display = "none";
        //atendimento.style.display = "none";
      }
    }
  }

  const getNotifyAtendimento = () => {
    setTimeout(function () {
      //Verifica se tem mensagens no chat de atendimento ao cliente
      const chat = document.getElementById('Chat com cliente');
      if (chat) {
        if (notifyChat) {
          chat.style.display = "block";
        } else {
          chat.style.display = "none";
        }
      }
      //Verifica se tem novos formulários de contato
      const form = document.getElementById('Chamados do fale conosco');
      if (form) {
        if (notifyForm) {
          form.style.display = "block";
        } else {
          form.style.display = "none";
        }
      }
    }, 200)
  }

  const getIconNotify = () => {
    setTimeout(function () {
      const contatosSite = document.getElementById('Chamados do fale conosco');
      notifications.notificacoes.map(n => {
        if (n.flag_read == 0 && n.local == 'Formulário de Contato') {
          if (!window.location.href.includes('formulario-chamado')) {
            if (contatosSite) {
              contatosSite.style.display = "block";
            }
          }
        }
      })
    }, 200)
  }

  const readLocal = async (local) => {
    await api.put(`/notifications/all?local=${local}`);
  }

  const scrollFeed = () => {
    if (path == '/feed') {
      const elemento = document.getElementById('form-post');

      if (elemento) elemento.scrollIntoView();
    }
  }

  useEffect(() => {
    setTimeout(function () {
      const chat = document.getElementById('Chat com cliente');
      if (chat) {
        if (notifyChat) {
          chat.style.display = "block";
        } else {
          chat.style.display = "none";
        }
      }
      const form = document.getElementById('Chamados do fale conosco');
      if (form) {
        if (notifyForm) {
          form.style.display = "block";
        } else {
          form.style.display = "none";
        }
      }
    }, 200)
  }, [notifyChat, notifyForm])

  return (
    <>
      <SocketNotificacoes load={getNotifications} enablePushNotification={true} notificationTittle={"Nova mensagem no Merx"} notificationBody={"Você recebeu uma nova mensagem no chat!"} />
      {(showMenu || showMobileMenu) && (
        <>
          <Wrap>
            <div>
              <div className="wrap-icone" onClick={() => hasChanges ?
                (
                  setShowModal(true),
                  sessionGet('planoEmpresa') === 'merxGratis' ? setPathAction('/painel-de-controle') : setPathAction('/feed')
                ) :
                ((sessionGet('planoEmpresa')) === 'merxGratis' ? window.location = "/painel-de-controle" : window.location = "/feed")
              }>
                <img className="icone" src={IconeLogo} />
              </div>
              <div className="wrap-buttons">
                {itens.map(item => (
                  (item.active === true && item.isShow) && (
                    (item.isDropdown) ? (
                      <Button key={item.title} onClick={() => {
                        getNotifyAtendimento();
                        setIsDropdown(true);
                        setItensDropdown(item.itens);
                        (path === item.path ? setShowSubMenu(!showSubMenu) : setShowSubMenu(true))
                        setShowWrapOptions(false);
                        setPath(item.path);
                      }} className={(item.listPath.includes(path)) && 'active'}>
                        {item.icon}
                        <span>{item.title}</span>
                        <span id={item.title} className="notification-menu atendimento"></span>
                        <div className="seta-active"></div>
                      </Button>
                    ) : (
                      <>
                        {item.isShow && (
                          <>
                            {item.isExpand ? (
                              <Button key={item.title} onClick={() => {
                                getIconNotify();
                                setShowWrapOptions(true);
                                setPath(item.path);
                                setIsDropdown(false);
                                setShowSubMenu(false);
                              }} className={(item.listPath.includes(path)) && 'active'}>
                                {item.icon}
                                <span>{item.title}</span>
                                <small>{item.warning}</small>
                                <span id={item.path.replace('/', '')} className="notification-menu-mais"></span>
                                <div className="seta-active"></div>
                              </Button>
                            ) : (
                              /*FEED - CHAT - CONTATO */
                              hasChanges ? (
                                <Button key={item.title} onClick={() => hasChanges && (
                                  setShowModal(true),
                                  setPathAction(item.path),
                                  setPath(item.path),
                                  setIsDropdown(false),
                                  setShowSubMenu(false),
                                  setShowWrapOptions(false),
                                  readLocal(item.title),
                                  setItemClicked(item.listPath[0]),
                                  (item.path == '/feed') && scrollFeed()
                                )} className={(item.listPath.includes(path)) && 'active'}>
                                  {item.icon}
                                  <span id={item.title} className="notification-menu"></span>
                                  <span>{item.title}</span>
                                  <small>{item.warning}</small>
                                  <div className="seta-active"></div>
                                </Button>
                              ) : (
                                <Button key={item.title} to={item.path} onClick={(e) => {
                                  setPath(item.path);
                                  setIsDropdown(false);
                                  setShowSubMenu(false);
                                  setShowWrapOptions(false);
                                  readLocal(item.title);
                                  setItemClicked(item.listPath[0]);
                                  (item.path == '/feed') && scrollFeed();
                                }} className={(item.listPath.includes(path)) && 'active'}>
                                  {item.icon}
                                  <span id={item.title} className="notification-menu"></span>
                                  <span>{item.title}</span>
                                  <small>{item.warning}</small>
                                  <div className="seta-active"></div>
                                </Button>
                              )
                            )}
                          </>
                        )}
                      </>
                    )
                  )
                ))}
              </div>
            </div>
            <div className="wrap-config">
              {itens.filter(i => i.isBottom).map(item => (
                item.path === '/ajuda' ? (
                  hasChanges ? (
                    <Button key={item.title} onClick={() => {
                      setShowModal(true);
                      setPathAction(item.path);

                      setIsDropdown(true);
                      setItensDropdown(item.itens);
                      setPath(item.path);
                      setItemClicked(item.listPath[0]);
                      (path === item.path ? setShowSubMenu(!showSubMenu) : setShowSubMenu(true));
                      setShowWrapOptions(false);
                    }} className={(item.listPath.includes(path)) && 'active'}>
                      {item.icon}
                      <span>{item.title}</span>
                      <small>{item.warning}</small>
                      <div className="seta-active"></div>
                    </Button>
                  ) : (
                    <Button to={item.path} key={item.title} onClick={() => {
                      setIsDropdown(true);
                      setItensDropdown(item.itens);
                      setPath(item.path);
                      setItemClicked(item.listPath[0]);
                      (path === item.path ? setShowSubMenu(!showSubMenu) : setShowSubMenu(true));
                      setShowWrapOptions(false);
                    }} className={(item.listPath.includes(path)) && 'active'}>
                      {item.icon}
                      <span>{item.title}</span>
                      <small>{item.warning}</small>
                      <div className="seta-active"></div>
                    </Button>
                  )
                ) : (
                  <Button key={item.title} onClick={() => {
                    setIsDropdown(true);
                    setItensDropdown(item.itens);
                    setPath(item.path);
                    (path === item.path ? setShowSubMenu(!showSubMenu) : setShowSubMenu(true))
                    setShowWrapOptions(false);
                  }} className={(item.listPath.includes(path)) && 'active'}>
                    {item.icon}
                    <span>{item.title}</span>
                    <small>{item.warning}</small>
                    <div className="seta-active"></div>
                  </Button>
                )
              ))}
            </div>
          </Wrap>
          {/*//*MENU MAIS*/}
          {showWrapOptions && (
            <WrapOptions>
              <button onClick={() => {
                setShowWrapOptions(false);
                setPath(window.location.pathname);
              }} type="button">
                <RiCloseLine />
              </button>
              <ul>
                {itens.filter(i => !i.isBottom && !i.isShow).map(item => (
                  <li>
                    <Button key={item.title} onClick={() => {
                      setIsDropdown(false);
                      setShowSubMenu(false);
                      setShowWrapOptions(false);
                      setPath(item.path);
                    }} to={item.path} className={(item.path === window.location.pathname) && 'active'}>
                      {item.icon}
                      <span id={item.title} className="notification-menu-into-more"></span>
                      <span>{item.title}</span>
                      <small>{item.warning}</small>
                      <div className="seta-active"></div>
                    </Button>
                  </li>
                ))}
              </ul>
            </WrapOptions>
          )}
          {(isDropdown && showSubMenu) &&
            itensDropdown.length > 0 &&
            <Dropdown subMenu={itensDropdown[0].title}>
              <button onClick={() => {
                setShowSubMenu(false);
              }} type="button" className="btn-fechar">
                <RiCloseLine />
              </button>
              {itensDropdown.filter(i => i.active).map(item => (
                hasChanges ? (
                  <Button key={item.title} className='dropdown' onClick={() => hasChanges && (
                    setShowModal(true),
                    setPathAction(item.path),
                    setPath(item.path),
                    setItemClicked(item.path),
                    setShowSubMenu(false)
                  )}>
                    {item.icon}
                    <span id={item.title} className="notification-menu-into-atendimento"></span>
                    <span>{item.title}</span>
                    <small>{item.warning}</small>
                  </Button>
                ) : (
                  <Button key={item.title} className='dropdown' to={item.path} onClick={() => {
                    setPath(item.path);
                    setItemClicked(item.path);
                    setShowSubMenu(false);
                  }}>
                    <div className='wrap-for-warning'>
                    {item.icon}
                    <span id={item.title} className="notification-menu-into-atendimento"></span>
                    <span>{item.title}</span>
                    </div>
                    <small>{item.warning}</small>
                  </Button>
                )
              ))}
            </Dropdown>
          }
        </>
      )
      }
    </>
  );
}
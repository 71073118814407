const userConversa = (state = {}, action) => {
    switch(action.type) {
        case '@userConversa/SET':
            return action.userConversa;
        default:
            return state;
    }
};

export default userConversa;

import styled from 'styled-components';

import colors from '../../../presets/colors';

export const WrapForm = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .form-header {
    border-bottom: 1px solid #d7d7d7;
    padding: 25px;
    
    h2 {
      color: ${colors.texts};
    }
  }

  .form-body {
    height: 100%;
    padding: 25px;

    div:first-child {
      margin-top: 0;
    }
      
    div:last-child {
      margin-bottom: 0;
    }

    @media(max-width: 1366px) {
      padding: 15px;
    }
  }

  .form-footer {
    border-top: 1px solid #d7d7d7;
    padding: 25px;
    display: flex;
    justify-content: center;

    @media(max-width: 1366px) {
      padding: 15px;
    }
  }
`;

import React, { useState, useEffect } from 'react';

import api from '../../services/api-geral';

import { Container } from './styles';

function ModalMentionsFeed({ search, newSearch, markedUsers, setMarkedUsers, setShowModalMarcacoes }) {
  const [users, setUsers] = useState([]);
  const [controllerArrow, setControllerArrow] = useState(0);

  useEffect(() => {
    getUsers();
  }, []);

  // const keypressed = document.addEventListener('keydown', e => {
  //   if (e.key == 'ArrowDown') {
  //     if (controllerArrow < (getUsersSearch().length - 1)) {
  //       setControllerArrow(controllerArrow + 1);
  //     }
  //   }
  //   else if (e.key == 'ArrowUp') {
  //     if (controllerArrow > 0) {
  //       setControllerArrow(controllerArrow - 1);
  //     }
  //   }
  // });

  const getUsers = async () => {
    const response = (await api.get('/users')).data;

    setUsers(response);
  };

  const markUser = user => {
    setMarkedUsers([...markedUsers, user]);

    //newSearch(user.id, user.name);

    setShowModalMarcacoes(false);
  };

  const getUsersSearch = () => {
    return users.filter(user => markedUsers.filter(mUser => user.id === mUser.id).length === 0 && user.setor !== null && user.name.toLowerCase().includes(search.toLowerCase()));
  }

  return (
    <>
      {(getUsersSearch().length > 0) && (
        <Container>
          {getUsersSearch().map((user, index) => (
            <li key={user.name} className={index === controllerArrow ? 'selected' : ''} onClick={() => { markUser(user) }}>{user.name}</li>
          ))
          }
        </Container>
      )}
    </>
  );
}

export default ModalMentionsFeed;
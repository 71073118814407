import React, { useState, useEffect, useLayoutEffect } from 'react';

import api from '../../services/api-geral';

import Page from '../../components/Page';

import { Container } from './styles';
import NPostFeed from '../../components/NPostFeed';
import getParams from '../../util/params';
import { getIdWithoutCripto } from '../../util/formatParamsUrl';
import ModalPostFeed from '../../components/ModalPostFeed';
import ModalShowImage from '../../components/ModalShowImage';

export default function ViewPostFeed() {
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [showModalImage, setShowModalImage] = useState(false);
    const [editedImage, setEditedImage] = useState({});
    const [editedPost, setEditedPost] = useState({});
    const [showExplorer, setShowExplorer] = useState(false);
    const [postType, setPostType] = useState();
    const [pathImage, setPathImage] = useState('');
    const [post, setPost] = useState({});
    const [image, setImage] = useState({});
    const [hasChangesModal, setHasChangesModal] = useState(false);

    useEffect(() => {
        getPost();

    }, []);
    useEffect(() => { }, [post]);

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const id_comment = getIdWithoutCripto(getParams('comment'));
        const params = getParams('comment');
        if (id_comment !== null) {
            setTimeout(() => {
                scroll(id_comment);
            }, 1500);
        }
    }, [])

    const getPost = async () => {
        const id = getIdWithoutCripto(getParams('id'));
        const response = (await api.get(`/posts-single/${id}`)).data;

        setPost(response.post);
        setImage(response.image);
    };

    const deletePost = async (post) => {
        const response = (await api.delete(`/posts/${post.id}`)).data;
    }

    const scroll = (id) => {
        const el = document.getElementById(id);

        if (el !== null) {
            el.scrollIntoView({ behavior: "smooth", block: "start" });
            el.classList.add('comment-blink');
            setTimeout(() => {
                el.classList.remove('comment-blink');
            }, 2500);
        }
    }

    return (
        <>
            {showModalEdit && <ModalPostFeed post={editedPost} showExplorer={showExplorer} image={editedImage} closeModal={() => setShowModalEdit(false)} reload={getPost} getPosts={() => { getPost(); }} type={postType} />}
            {showModalImage && <ModalShowImage img={pathImage} close={() => setShowModalImage(false)} />}
            <Page hasChanges={hasChangesModal} setHasChanges={setHasChangesModal} content={(
                <Container>
                    <div className="content">
                        {post.autor && (
                            <NPostFeed
                                viewmode={true}
                                key={post.id}
                                image={image}
                                post={post}
                                editar={() => { setEditedPost(post); setEditedImage(image); setShowModalEdit(true); setPostType("edit") }}
                                deletar={() => { deletePost(post) }}
                                modalImage={path => { setPathImage(path); setShowModalImage(true); }}
                                setHasChangesModal={setHasChangesModal}
                            />
                        )}
                    </div>
                </Container>
            )} />
        </>
    );
}

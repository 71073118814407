import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 95%;
  padding-bottom: 30px;

  &::-webkit-scrollbar {
      width: 0px;
  }
  
  flex: auto;
  background-color: #f4f4f6;
  position: relative;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  overflow-x: hidden;
  overflow-y: auto;
`;



import Bold from './Bold';
import Italic from './Italic';
import Underline from './Underline';
import JustifyAlign from './JustifyAlign';
import LeftAlign from './LeftAlign';
import CenterAlign from './CenterAlign';
import RightAlign from './RightAlign';
import FontSize from './FontSize';

export const BoldIcon = Bold;
export const ItalicIcon = Italic;
export const UnderlineIcon = Underline;
export const JustifyAlignIcon = JustifyAlign;
export const LeftAlignIcon = LeftAlign;
export const CenterAlignIcon = CenterAlign;
export const RightAlignIcon = RightAlign;
export const FontSizeIcon = FontSize;
import React, { useEffect, useState } from 'react';
import api from '../../services/api-geral';
import { sessionSet, sessionGet, isLogged } from '../../session';
import socket from '../../services/socket';

import { Wrap, Banner, WrapForm, Form, Input, Button, Checkbox } from './styles';
import { MdError, MdDone } from 'react-icons/md';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';

import LogoTask from '../../assets/images/logotask.png';
import LogoMerx from '../../assets/logo_branca.png';
import IconeMerx from '../../assets/icone_logo.png';
import AlertaMerx from '../../components/AlertaMerx';

export default function Login({ history }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [check, setCheck] = useState(0);
  const [showAlertMerx, setShowAlertMerx] = useState(false);
  const [msgAlertMerx, setMsgAlertMerx] = useState('');
  const [msgError, setMsgError] = useState(false);
  const [msg, setMsg] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('connect') === 'true') {
      if (isLogged() && sessionGet('status') != 2) window.location = '/feed';
      else if (isLogged() && sessionGet('status') == 2) window.location = '/nova-senha';
    }

    if (localStorage.getItem('connect') === 'false' || !localStorage.getItem('connect')) {
      localStorage.removeItem('dadosUser');
    }

  }, []);

  const Login = async (e) => {
    e.preventDefault();

    const response = (await api.post('/login', { email, password })).data;

    setMsg(response.error);

    if (!response.error) {
      const dadosUsuario = {
        idUser: response.user.id,
        idEmpresa: response.user.id_empresa,
        tokenUser: response.token,
        emailUser: response.user.email,
        nomeUser: response.user.name,
        admin: response.user.admin,
        folder: response.user.folder,
        status: response.user.status,
        idSetor: response.user.id_setor,
        flagAtendimento: response.user.flag_atendimento,
        modulos: response.user.modulos,
        foto_perfil: response.user.id_file,
        presence_status: response.user.presence_status,
        planoEmpresa: response.user.planoEmpresa,
      };

      sessionSet(dadosUsuario);

      if (dadosUsuario.status != 2) {
        if (localStorage.getItem('redirect')) {
          window.location = localStorage.getItem('redirect');
          localStorage.removeItem("redirect");
        } else {
          if (response.user.planoEmpresa === 'merxGratis') {
            window.location = '/painel-de-controle';
          } else {
            window.location = '/feed';
          }
        }
      } else {
        window.location = '/nova-senha';
      }

    } else {
      let styles = `
        border: solid 2px red;
        line-height: 0;
        border-radius: 10px;
        padding: 0 5px;
        `
      document.getElementById("input-email").setAttribute("style", styles);
      document.getElementById("input-senha").setAttribute("style", styles);
      setMsgError(true);
      setTimeout(() => {
        let offStyle = `
        border-bottom: 2px solid rgba(0, 0, 0, 0.1);
        border-radius: 0;
        padding: 0;
        `
        document.getElementById("input-email").setAttribute("style", offStyle);
        document.getElementById("input-senha").setAttribute("style", offStyle);
        setMsgError(false)
      }, 5000)
    }

  }

  const toggleCheck = () => {
    if (check === 0) {
      setCheck(1)
      localStorage.setItem('connect', true)
    } else {
      setCheck(0)
      localStorage.setItem('connect', false)
    }
  }

  return (
    <Wrap>
      <Banner>
        <div className="title">
          <img src={LogoMerx} className="logo-merx" alt="Logo Merx" title="Merx" onClick={() => window.location = '/'} />
          <img src={LogoTask} className="logo-task" alt="Logo Task" title="Task" onClick={() => window.open('https://task.com.br')} />
        </div>
      </Banner>
      <WrapForm>
        <Form onSubmit={Login}>
          <img src={IconeMerx} className="icone-merx" alt="Ícone Merx" title="Merx" />
          {(msgError) && <div className="error-txt"><MdError /><span>{msg}</span></div>}
          <Input id="input-email">
            <input type="text" id="email" placeholder=" " autoComplete="off" value={email} onChange={e => setEmail(e.target.value.trim())} />
            <label for="email">
              E-mail *
            </label>
          </Input>
          <Input id="input-senha">
            <input
              type={isPasswordVisible ? "text" : "password"}  id="password"  placeholder=" "  autoComplete="off" value={password}  onChange={e => setPassword(e.target.value)}            
            />  
             <div  className='toggle-visibility'  onClick={() => setIsPasswordVisible(!isPasswordVisible)}
            >
              {isPasswordVisible ? <div>
                <FaRegEyeSlash  id="hide-password" />
                <label  className='label-icon' for="hide-password">Esconder senha</label>
              </div>  : <div>
              <FaRegEye  id="show-password"/>
              <label  className='label-icon' for="show-password">Mostrar senha</label>
              </div> }
            </div> 
            <label for="password">
              Senha *
            </label>
        </Input>
          <div className="wrap-utilities">
            <div className="wrap-check" onClick={() => toggleCheck()}>
              <Checkbox>
                {(check === 1) ? <MdDone size="16px" color="#2C76FF" /> : ''}
              </Checkbox>
              <span>Manter conectado</span>
            </div>
            <a href={`/recuperar-senha`}>Esqueceu a senha?</a>
          </div>
          {(showAlertMerx) && (
            <div className="wrap-alert">
              <AlertaMerx
                type='danger'
                style='default'
                text={msgAlertMerx}
                css={['42%', '20%', 'auto', 'auto']}
                css_large_screen={['37%', '5%', 'auto', 'auto']}
                context='in'
              />
            </div>
          )}
          <Button type="submit">
            Entrar
          </Button>
        </Form>
      </WrapForm>
    </Wrap>
  );
}

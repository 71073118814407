import { useEffect } from "react"
import { DeleteButton, IconFile, Message, MessegeContainer, NameUser, RepliedMessageContainer, TextOverflow } from "./styles"
import { AiOutlineCloseCircle } from "react-icons/ai";
import React from "react"
import { RiArrowDownLine, RiFile2Fill } from "react-icons/ri";
import getFiles from "../../util/getFiles";
import { IoCamera } from "react-icons/io5";

export const RepliedMessage = ({setReplyMessage, message, classCustom, id_from, id_to, name_from, name_to, onClick,replied_message, nameFile = null}) => {



    const hasImageAndDescription = replied_message && message.type_file === "image"
    const hasImage = !replied_message && message.type_file === "image"
    const hasFile = message.type_file === "file"

    function removeLineBreaks(texto) {
        return texto.replace(/<br>/g, ' ').replace(/&nbsp;/g, '');
    }


    return (
        <RepliedMessageContainer onClick={onClick} className={(id_from === id_to) ? "you " + classCustom : "me " + classCustom } >

            
            <MessegeContainer>
            <NameUser className={(id_from === id_to) ? "you " : "me " }>{(id_from === id_to) ? name_to : "Você"}</NameUser>

            {
                hasImageAndDescription &&
                <Message>
                    <IoCamera />
                    <p dangerouslySetInnerHTML={{__html: removeLineBreaks(replied_message)}}></p>
                </Message>
            }
            {
                hasImage &&
                <Message>
                    <IoCamera />
                    <p>Foto</p>
                </Message>
            }

            {
                !hasImage && !hasImageAndDescription && 
                <Message>
                    <p dangerouslySetInnerHTML={{__html: removeLineBreaks(replied_message)}}></p>
                </Message>
            }
            {
                hasFile && 
                    <div className="download-file">
                        <IconFile>

                        <RiFile2Fill />
                        </IconFile>
                        <span>{message.name_file}</span>
                        <button ><RiArrowDownLine /></button>
                    </div>
            }
            
            </MessegeContainer>
            {
                message.type_file === "image" &&  <img src={getFiles(message.path_img)}></img>
            }
            { setReplyMessage && 
            <DeleteButton onClick={() => setReplyMessage(false)}>
                <AiOutlineCloseCircle />
            </DeleteButton>}
        </RepliedMessageContainer>
    )
}
import React, { useEffect, useState } from 'react';

import Page from '../../components/Page';

import { BsGearFill } from "react-icons/bs";
import { HiChatAlt2 } from "react-icons/hi";
import { RiLayoutMasonryLine, RiComputerLine, RiMailLine, RiChatSmileLine, RiUserHeartLine } from 'react-icons/ri';

import { Wrap, Scroll, Container, Modulos, Cards, Title } from './styles';

import CardAjuda from '../../components/CardAjuda';

import { config, feed, chat, email, miniSite, atendimento, socials } from './content';

export default function Ajuda() {
  const [tab, setTab] = useState(1);
  const [modulo, setModulo] = useState(config);

  useEffect(() => {
    if (tab === 1) {
      setModulo(config)
    } else if (tab === 2) {
      setModulo(feed)
    } else if (tab === 3) {
      setModulo(chat)
    } else if (tab === 4) {
      setModulo(miniSite)
    } else if (tab === 5) {
      setModulo(email)
    } else if (tab === 6) {
      setModulo(atendimento)
    } else {
      setModulo(socials)
    }
  }, [tab])

  const scrollMobile = () => {
    var elmntToView = document.getElementById("idCard");
    if (window.innerWidth < 825) {
      if (elmntToView) {
        elmntToView.scrollIntoView({ behavior: "smooth" });
      }
    }
  }

  return (
    <Page content={(
      <>
        <Wrap>
          <Title>
            <h1>Ajuda</h1>
            <div className='underline-title'></div>
          </Title>
          <Container>
            <Modulos>
              <div className="modulos">
                <button onClick={() => { setTab(1); scrollMobile() }} className={tab === 1 ? 'active' : ''}><BsGearFill /> CONFIGURAÇÕES</button>
                <button onClick={() => { setTab(2); scrollMobile() }} className={tab === 2 ? 'active' : ''}><RiLayoutMasonryLine /> FEED</button>
                <button onClick={() => { setTab(3); scrollMobile() }} className={tab === 3 ? 'active' : ''}><HiChatAlt2 /> CHAT</button>
                <button onClick={() => { setTab(4); scrollMobile() }} className={tab === 4 ? 'active' : ''}><RiComputerLine /> MINI SITE</button>
                <button onClick={() => { setTab(5); scrollMobile() }} className={tab === 5 ? 'active' : ''}><RiMailLine /> E-MAIL</button>
                <button onClick={() => { setTab(6); scrollMobile() }} className={tab === 6 ? 'active' : ''}><RiChatSmileLine /> CENTRAL DE ATENDIMENTO</button>
                <button onClick={() => { setTab(7); scrollMobile() }} className={tab === 7 ? 'active' : ''}><RiUserHeartLine /> REDES SOCIAIS</button>
              </div>
            </Modulos>
            <Cards id="idCard">
              {modulo.map(modulo => (
                <>
                  <span className="subtitle">{modulo.subTitle}</span>
                  {modulo.cards.map(card => (
                    <CardAjuda
                      id={card.id}
                      height={card.height}
                      title={card.title}
                      content={card.content}
                    />
                  ))}
                </>
              ))}
            </Cards>
          </Container>
        </Wrap>
      </>
    )} />
  );
}

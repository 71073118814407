import styled from "styled-components";
import effects from "../../../presets/effects";

export const Container = styled.div`
  width: 200px;
  background-color: #FFF;
  box-shadow: ${effects.boxshadow};
  padding: 15px;
  z-index: 1060;
  position: absolute;
  top: ${props => props.top};
  right: ${props => props.right};
  bottom: ${props => props.bottom};
  left: ${props => props.left};
  display: none;

  &.show {
    display: block;
  }
  
  p {
    text-align: justify;
  }
`;

export const Indicator = styled.div`
  width: 0;
  height: 0;
  border: 0 solid transparent;
  border-top-width: 12.5px;
  border-bottom-width: 12.5px;
  border-right: 12.5px solid #FFF;
  position: absolute;
  left: -12.5px;
  top: calc(50% - 12.5px);
`;
import React, { useState, useEffect } from 'react';

import CButton from '../ComponentButton';
import QRcode from '../QRcode';

import ReactHtmlParser from 'react-html-parser';
import { isFirefox, isChrome, isEdge } from 'react-device-detect';

import { AiFillQuestionCircle } from "react-icons/ai";
import { IoIosColorPalette } from "react-icons/io";
import { FiMapPin, FiShare2 } from "react-icons/fi";

import { Backdrop, WrapModal, WrapModalView, Container } from './styles';
import { MdClose } from 'react-icons/md';

import { maps, notification, analytics } from './content';

export default function Question({ close, view, content, style }) {

  const [browser, setBrowser] = useState('chrome');
  const [modulo, setModulo] = useState('');

  const fechar = () => {
    close();
  }

  useEffect(() => {
    if (content == 'maps') {
      setModulo(maps);
    } else if (content == 'notification') {
      setModulo(notification);
    } else if (content == 'analytics') {
      setModulo(analytics);
    }
  }, []);

  return (
    <>
      {(view) &&
        <Backdrop onClick={fechar}>
          <WrapModalView className={style}>
            <button onClick={close} className="closebutton"><MdClose /></button>
            <div className="modal">
              <div className="header">
                <IoIosColorPalette />
                {ReactHtmlParser(modulo.title)}
              </div>
              {(content == 'notification') ?
                <>
                  {(isChrome || isFirefox) ?
                    (isChrome ? ReactHtmlParser(modulo.chrome)
                      :
                      isFirefox && ReactHtmlParser(modulo.mozilla)
                    )
                    :
                    ReactHtmlParser(modulo.other)
                  }
                </>
                :
                ReactHtmlParser(modulo.content)
              }
            </div>
          </WrapModalView>
        </Backdrop>
      }
    </>
  );
}

import styled from 'styled-components';

import colors from '../../../presets/colors';
import effects from '../../../presets/effects';

export const Box = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  border: 1px solid rgb(0 0 0 / 20%);
  border-radius: 15px;
  background-color: aqua;

`;

export const Info = styled.div`
  width: 100%;
  display: flex;
  position: relative;

  .info-modal {
    width: 22px;
    height: 22px;
    position: absolute;
    right: 5px;
    top: 10px;

    svg {
      font-size: 22px;
      color: #5E5E5E;
    }

    .custom-info {
      cursor: pointer;
    }

    .alter-height {
      height: 50%;
      left: 50%;
      height: fit-content;

      @media (max-width: 530px) {        
        height: 50% !important;
      }
    }
  }

  .content-textarea {
    width: 100%;
    position: relative;

    label {
      top: -9px;
    }

    #textarea-description {
      padding-right: 30px;
    }
  }
`;
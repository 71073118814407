import styled from 'styled-components';
import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const ButtonLink = styled.a`
  width: 100px; 
  height: 30px;
  font-weight: normal;
  margin-right: 0px; // era 15px
  color:  ${colors.btn_text_primary};
  background-color: ${colors.btn_primary};
  border: none;
  border-radius: 5px;
  font-size: 13px;
  //position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-top: 10px;
  cursor: pointer;

  @media (max-width: 350px) {
    position: absolute;
    top: 70px;
    width: 100%;
    left: 0px;
    height: 40px;
  }

  svg {
    font-size: 30px;
  }
`;


export const Container = styled.div`
  width: 100%;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;  

  a { 
    @media (max-width: 350px) {
      visibility: hidden;
    }    
  }
  button{
    @media (max-width: 350px) {
      visibility: hidden;
    } 
  }
  
`;

export const LoadingWrap = styled.div`
  width: 60px;
  height: 60px;
  margin-top: 30px;
  background-color: none;
  border-radius: 0.75rem;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
	color: ${colors.primary};

  h3 {
    margin-top: 15px;
  }

  svg {
    font-size: 25px;
  }

  .fa-spin {
    -webkit-animation: icon-spin 1s infinite linear;
    animation: icon-spin 1s infinite linear;
  }

  @-webkit-keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }

  @keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
`;
import React, { useState } from 'react';

import { RiArrowDownSLine } from 'react-icons/ri';

import { Container } from './styles';

import ReactHtmlParser from 'react-html-parser';

function CardAjuda({ id, height, title, content }) {
  const [show, setShow] = useState(false);

  const showOnOff = () => {
    var card = document.getElementById(`card${id}`);
    if (show === false) {
      card.classList.add('card-content-active');
      setShow(true);
    } else {
      card.classList.remove('card-content-active');
      setShow(false);
    }
  }

  return (
    <Container height={height}>
      <div className="card-content" onClick={() => { showOnOff() }} id={`card${id}`}>
        <div className='card-title'>
          <h3>{ReactHtmlParser(title)}</h3>
          <button><RiArrowDownSLine /></button>
        </div>
        <div className='card-answer'>
          <hr />
          {ReactHtmlParser(content)}
        </div>
      </div>
    </Container>
  );
}

export default CardAjuda;
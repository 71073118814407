import React from 'react';
import { RiSettings4Line, RiListSettingsLine, RiQuestionAnswerLine, RiBookmarkLine } from "react-icons/ri";
import { IoInfiniteSharp } from "react-icons/io5";
import { MdOutlineNewLabel } from "react-icons/md";

import { Wrap, Button, Separador } from './styles';

export default function TitleAndBreadcrumb({ title, format = '', breadcrumbs, actions }) {
  return (
    <Wrap format={format}>
      <h1>
        {title}
        {(format !== '') ? (format === 'config') ?
          (<div className="icon"> <RiSettings4Line /> <Separador /></div>) :
          (format === 'chat') ?
            (<div className="icon"> <RiQuestionAnswerLine /> <Separador /></div>) :
            (format === 'enabled-tools') ?
              (<div className="icon"> <IoInfiniteSharp /> <Separador /></div>) :
              (format === 'disabled-tools') ?
                (<div className="icon"> <MdOutlineNewLabel /> <Separador /></div>) :
                (format === 'book-mark' )?
                (<div className="icon"> <RiBookmarkLine /> <Separador /></div>) :
                (<div className="icon"> <RiListSettingsLine /> <Separador /></div>) :
          ''
        }
        <small>
          {breadcrumbs.map((item, index) => (
            <>
              <Button to={item.path}>{item.title}</Button>
              {(breadcrumbs.length - 1 !== index) && <span>|</span>}
            </>
          ))}
        </small>
        {/* <Separador /> */}
      </h1>
      <div className='actions'>
        {actions}
      </div>
      {/* <Separador /> */}
    </Wrap>
  );
}

import React, { useState, useEffect } from 'react';
import Geral from '../../services/api-geral';

import Page from '../../components/Page';
import FormFormularioAssunto from '../../components/FormularioAssunto/Form';
import CTable from '../../components/ComponentTable';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";

import { Painel } from '../../styles/content';

import { Container } from './styles';
import { Paginas, PaginaButton } from '../../components/BotoesPaginacao/styles';

export default function FormularioAssunto() {
  const [dadosFormularioAssuntos, setDadosFormularioAssuntos] = useState([]);
  const [offsetAssunto, setOffsetAssunto] = useState(0);
  const [totalAssunto, setTotalAssunto] = useState(0);
  const [paginaAtual, setPaginaAtual] = useState(1)

  const getFormularioAssunto = async () => {
    const response = await Geral.get(`/formulario-assunto?offset=${offsetAssunto}`);
    setDadosFormularioAssuntos(response.data.rows);
    setTotalAssunto(response.data.count);
  };

  useEffect(() => {
    getFormularioAssunto();
  }, [offsetAssunto]);

  const setaOffset = (acao) => {
    if (acao === 'proximo') {
      if (Math.floor(totalAssunto / 10) !== (offsetAssunto / 10)) {
        setOffsetAssunto(offsetAssunto + 10);
        setPaginaAtual(paginaAtual + 1);
      }
    }

    if (acao === 'anterior') {
      if (offsetAssunto > 0) {
        setOffsetAssunto(offsetAssunto - 10);
        setPaginaAtual(paginaAtual - 1);
      }
    }
  }

  return (
    <Page content={(
      <Container>
        <Painel className="painel-assunto">
          <CTable
            titles={['Nome']}
            values={dadosFormularioAssuntos}
            indexes={['id', 'name']}
            indexesSearch={['name']}
            load={getFormularioAssunto}
            FormCustom={FormFormularioAssunto}
            setItem={setDadosFormularioAssuntos}
            actionDelete='/formulario-assunto'
            titlePage='Lista de assuntos'
            breadcrumbPage={[{ title: 'Listas' }]}
            emptyPage='Nenhum registro foi encontrado!'
          />
          <Paginas>
            {(paginaAtual > 1) && (
              <PaginaButton onClick={() => setaOffset('anterior')}>
                <MdKeyboardArrowLeft className="icon" size="20px" />
              </PaginaButton>
            )}
            <span>{paginaAtual}</span>
            {(paginaAtual < Math.ceil(totalAssunto / 10)) && (
              <PaginaButton onClick={() => setaOffset('proximo')}>
                <MdKeyboardArrowRight className="icon" size="20px" />
              </PaginaButton>
            )}
          </Paginas>
        </Painel>
      </Container>
    )} />
  );
}

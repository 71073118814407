import React from 'react';

import Page from '../../components/Page';
import ChatExterno from '../../components/ChatExterno';

import { Container, Section } from './styles';

export default function ConversasExternas() {
  return (
    <Page content={(
      <Container className="container-conversa"> 
        <Section>
          <ChatExterno />
        </Section>
      </Container>
    )} />
  );
}

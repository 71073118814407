import styled from 'styled-components';

import colors from '../../presets/colors';

export const Container = styled.div`
    width: 100%;
    padding: 20px 20px 15px;
    background-color: white;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    border: 1px solid ${colors.border};
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-bottom: 25px;

    @media (max-width: 515px) {
        padding: 18px 10px;
        flex-direction: column;
    }

    hr {
        width: 100%;
        height: 1px;
        border-top: 1px solid ${colors.border};
        margin: 20px 0 10px;
    }
`;

export const Head = styled.form`
    width: 90%;
    display: flex;
    align-items: center;

    @media (max-width: 870px) {
        width: 100%;
    }

    @media (max-width: 515px) {
        width: 100%;
    }

    img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
    }

    .post-button {
        position: relative;
        width: calc(100% - 70px);
        height: 50px;
        margin-left: 13px;
        color: ${colors.texton};
        border-radius: 25px;
        border: 1px solid ${colors.border};
        background-color: ${colors.bgsecondary};
        font-size: 13px;
        padding: 0 20px;
        cursor: pointer;

        @media (min-width: 1024px) {
            font-size: 14px;
        }

        .post-head {
            height: 100%;
            display: flex;
            align-items: center;
            place-content: center;
        }

        .mobile-span {
                display: none;
            }
            .desktop-span {
                display: block;
            }

        @media (max-width: 575px) {
            margin-left: 10px;
        }

        @media (max-width: 515px) {
            font-size: 12px;
            width: 100%;
            .mobile-span {
                display: block;
                position: relative;
                right: 20px;

                @media (max-width: 350px) {
                    font-size: 11px;
                }

                @media (max-width: 287px) {
                    right: 0;
                }
            }
            .desktop-span {
                display: none;
            }
        }
    }
    
    .post-button:hover {
        opacity: 0.7;
    }

    .button-head{
        position: absolute;
        right: -8px;
        top: 0;
        display: flex;
        align-items: center;
        padding: 5px 5px;
        cursor: pointer;
        border-radius: 10px;

        @media (min-width: 871px) {
            display: none;
        }

        &:hover {
            background-color: ${colors.bgsecondary};
            box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
            font-weight: bold;
        }

        .icon {
            background-color: ${colors.primary};
            width: 40px;
            height: 40px;
            border-radius: 50%;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;

            svg {
                font-size: 20px;
            }
        }
    }

    .presence {
        span {
        width: 10px;
        height: 10px;
        display: block;
        position: absolute;
        top: 140px;
        left: 55px;
        border-radius: 50%;
        border: 3px solid white;
        box-sizing: content-box;

        @media (max-width: 515px) {
            left: 45px;
        }

        &.online { background-color: green; }
        &.ausente { background-color: #FFBF00; }
        &.naoPerturbe { background-color: #FF0000; }
    }
    }
`;

export const Buttons = styled.div`
    width: 254px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media (max-width: 870px) {
        width: 20%;
        margin-left: 20px;
        display: none;
    }

    @media (max-width: 515px) {
        margin-left: 0;
        margin-top: 15px;
        width: 100%;
    }
`;

export const Button = styled.div`
    display: flex;
    align-items: center;
    padding: 5px 5px;
    cursor: pointer;
    border-radius: 10px;

    @media (max-width: 870px) {
        padding: 5px;
        display: none;
    }

    @media (max-width: 515px) {
        padding: 0px;
    }

    &:hover {
        background-color: ${colors.bgsecondary};
        box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
        font-weight: bold;
    }

    .icon {
        background-color: ${colors.primary};
        width: 40px;
        height: 40px;
        border-radius: 50%;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;

        svg {
            font-size: 20px;
        }
    }

    span {
        font-size: 13px;

        @media (max-width: 870px) {
            display: none;
        }

        @media (max-width: 515px) {
            display: block;
        }
    }
`;
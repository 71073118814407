import styled from 'styled-components';

import colors from '../../presets/colors';

export const Container = styled.div`
  height: 100%;
`;

export const ContentPanel = styled.div`
  margin: 25px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 575px) {
    padding: 0 15px;
  }
`;

export const ConfigurationForm = styled.form`

`;

export const InputGroup = styled.div`
  width: 300px;

  &:first-child {
    .input-style {
      margin-top: 5px;
    }
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;
import styled from 'styled-components';

import colors from '../../presets/colors';

export const Container = styled.div`
    z-index: 10000;
    position: fixed;
    top: 70px;
    left: 110px;
    width: calc(100% - 110px);
    height: calc(100% - 70px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.3);

    @media (max-width: 1024px) {
        width: 100%;
        left: 0;
    }
    
    .modal-confirma {
        padding: 15px 20px;
        width: 285px;
        height: 105px;
        background-color: white;

        hr {
            border: 1px solid ${colors.border};
            height: 1px;
            width: 97%;
        }

        .head-modal {
            text-align: center;
            padding-bottom: 10px;
        }

        .options-confirma {
            margin-top: 10px;
            display: flex;
            align-items: center;
            place-content: center;

            .buttons {
                display: flex;

                .delete {
                    display: flex;
                    align-items: center;
                    margin-right: 20px;
                    color: white;
                    padding: 5px 10px;
                    border-radius: 5px;
                    background-color: ${colors.primary};
                    cursor: pointer;

                    svg {
                        margin-right: 5px;
                    }
                }

                .cancel {
                    display: flex;
                    align-items: center;
                    color: white;
                    padding: 5px 10px;
                    border-radius: 5px;
                    background-color: ${colors.secondary};
                    cursor: pointer;

                     svg {
                        font-size: 20px;
                     }
                }
            }
        }
    }
`;

import styled from 'styled-components';

import colors from '../../../presets/colors';
import effects from '../../../presets/effects';

export const Toggles = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;

  .whatsapp-li {
    margin: 0 50px;
  }

  li {
    width: 100%;
    background-color: white;
    height: 40px;
    padding: 0 10px;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    cursor: pointer;
    color: ${colors.text_primary};

    &:last-child {
      margin: 0;
    }

    .title {
      display: flex;
      width: calc(100% - 50px);
      height: 100%;
      align-items: center;

      .wrap-icon {
        background-color: white;
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-right: 10px;
        box-shadow: ${effects.boxshadow};

        svg {
          color: ${colors.primary};
          font-size: 30px;
        }
      }

      svg {
        position: absolute;
      }
    }
    
    .toggle-funcionalidade {
      width: 50px;
      height: 25px;
      box-shadow: ${effects.boxshadow};
      background-color: white;
      border-radius: 15px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 5px;
      cursor: pointer;

      .indicador {
        width: 18px;
        height: 18px;
        background-color: #999;
        border-radius:  10px;
      }

      &.loading {
        justify-content: center;
      }
      
      &.active {
        justify-content: flex-end;

        .indicador {
          background-color: #00cc66;
        }
      }
    }
  }
`;

export const Info = styled.div`
  width: 100%;
  position: relative;
  display: flex;

  .info-modal {
    width: 22px;
    height: 22px;
    position: absolute;
    right: 5px;
    top: 10px;

    svg {
      font-size: 22px;
      color: #5E5E5E;
    }
  }
`;
import React, { useEffect } from 'react';
import socket from '../../services/socket';
import { sessionGet } from '../../session';

function SocketCommentsFeed({ loadComments, idPost }) {
    useEffect(() => {
        socket.on('interaction_comments', (id_post) => {

            if (idPost === id_post) {
                loadComments('socket');
            }
        });

        return () => {
            socket.off('interaction_comments');
        }
    }, []);

    return <></>;
}

export default SocketCommentsFeed;
import React, { useState, useEffect } from 'react';

import api from '../../services/api-geral';

import Page from '../../components/Page';
import { sessionGet } from '../../session';

export default function Contatos() {
  const [link, setLink] = useState('https://webmail.merx.app.br/');
  const [owner, setOwner] = useState(sessionGet('admin'));
  const [empresa, setEmpresa] = useState(sessionGet('idEmpresa'));
  const [plano, setPlano] = useState(sessionGet('planoEmpresa'));
  const [userEmail, setUserEmail] = useState(sessionGet('emailUser'));

  useEffect(() => {
    const loadInfos = async () => {
      const empresaEndpoint = `/empresa-endpoint/${empresa}`;
      const response = await api.get(empresaEndpoint);
      const hasDot = response.data.includes('.');
      const isMerxUltra = plano === 'merxUltra';
      const isOwnerZero = owner === 0;

      if (hasDot) {
        setLink(`https://webmail.merx.app.br/?minisite=${response.data}`);
        return;
      }

      if (isMerxUltra) {
        setLink(`https://webmail.merx.app.br/?minisite=${response.data}.com`);
        return;
      }

      if (isOwnerZero) {
        const newLink = `https://webmail.merx.app.br/?minisite=${response.data}`;
        setLink(newLink);
        return;
      }

      // Caso contrário, o proprietário não é igual a zero e o link é definido como padrão.
      setLink('https://webmail.merx.app.br/');
    };

    loadInfos();
  }, []);

  return (
    <Page content={(
      <iframe width="100%" height="100%" frameBorder="0" src={link} title="E-mail Merx">
      </iframe>
    )} />
  );
}
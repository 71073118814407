import React, { useEffect, useState } from 'react';
import ComponentInput from '../../ComponentInput';
import ComponentButton from '../../ComponentButton';
import Question from '../../Question'
import { Painel } from '../../../styles/content';
import apiGeral from '../../../services/api-geral';
import Alerta from '../../../util/Alerta';

import { Info, Toggles } from './styles';
import Swal from 'sweetalert2';

import ModalInformation from '../ModalInformation';

import { RiInformationLine } from 'react-icons/ri';

function Metadados({ setSwitchTab, switchTab, setTabs, data, refresh, doRefresh, reload }) {
  // form states
  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [metaKeywords, setMetaKeywords] = useState('');
  const [googleAnalytics, setGoogleAnalytics] = useState('');
  const [modalInformation, setModalInformation] = useState('');
  const [modalInfo, setModalInfo] = useState(0);
  const [showModal, setShowModal] = useState('');

  useEffect(() => {
    if (refresh !== 0) {
      handleSubmit();
    }
  }, [refresh]);

  var variance = 0;

  useEffect(() => {
    if (switchTab !== 'metadados') {
      if (data.metaDescription !== metaDescription) {
        variance = 1;
      }
      if (data.metaKeywords !== metaKeywords) {
        variance = 1;
      }
      if (variance === 0) {
        setTabs(switchTab);
      } else {
        Swal.fire({
          title: 'Atenção',
          text: 'Você possui alterações não publicadas, continuar mesmo assim?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Descartar',
          cancelButtonText: 'Não'
        }).then(async (result) => {
          if (result.isConfirmed) {
            setTabs(switchTab);
          } else {
            setSwitchTab('metadados');
          }
        });
      }
    }
  }, [switchTab]);

  useEffect(() => {
    setMetaTitle(data.metaTitle);
    setMetaDescription(data.metaDescription);
    setMetaKeywords(data.metaKeywords);
    setGoogleAnalytics(data.googleAnalytics);
  }, [data]);

  const handleSubmit = async e => {
    //e.preventDefault();

    const response = (await apiGeral.post('/page', {
      metaTitle,
      metaDescription,
      metaKeywords,
      googleAnalytics,
      scope: 'metadados'
    })).data;

    if (response.success) {
      variance = 0;
      Alerta.success("Alterações publicadas!");
      reload();
    } else {
      Alerta.error("Ocorreu um erro!", "Tente novamente mais tarde!");
    }

    doRefresh(0);
  };

  const focusTextArea = () => {
    var el1 = document.getElementById('label-description');
    var el2 = document.getElementById('textarea-description');
    el1.style.color = '#8a2387';
    el2.style.borderColor = '#8a2387';
  }

  const blurTextArea = () => {
    var el1 = document.getElementById('label-description');
    var el2 = document.getElementById('textarea-description');
    el1.style.color = 'rgba(0,0,0,0.5)';
    el2.style.borderColor = 'rgba(0,0,0,0.2)';
  }

  const mostrarModal = (val) => {
    setShowModal(val);
  }

  const closeModal = () => {
    setShowModal('');
  }

  return (
    <div className="wrap-panels">
      <Painel className="painel-configuracao-site metadados">
        <form onSubmit={handleSubmit}>
          <div className="infos">
            <Info>
              <ComponentInput classCustom="input-with-information" ctype="text" label="Título da página (meta-title tag)" val={metaTitle} change={setMetaTitle} disabled={true} />
              <div className='info-modal'>
                <RiInformationLine onMouseOver={() => { setModalInformation('show'); setModalInfo(1) }} onMouseOut={() => { setModalInformation(''); setModalInfo(0) }} />
                {modalInfo === 1 && (
                  <ModalInformation modalClass={modalInformation} top='0' left='-200px' content='Esse conteúdo aparecerá na aba do seu navegador e como assunto nos resultados de busca do Google. Pode ser o nome da empresa ou um termo que descreva seu negócio. ' />
                )}
              </div>
            </Info>
            <Info>
              <div className='content-textarea'>
                <label id='label-description' className={'label-text-area'}>Descrição</label>
                <textarea
                  id='textarea-description'
                  maxLength='150'
                  onFocus={() => { focusTextArea() }}
                  onBlur={() => { blurTextArea() }}
                  value={metaDescription}
                  onChange={e => setMetaDescription(e.target.value)}
                  rows="5"
                  placeholder="Description *">
                </textarea>
              </div>
              <div className='info-modal'>
                <RiInformationLine onMouseOver={() => { setModalInformation('show'); setModalInfo(2) }} onMouseOut={() => { setModalInformation(''); setModalInfo(0) }} />
                {modalInfo === 2 && (
                  <ModalInformation modalClass={modalInformation} top='0' left='-200px' content='A descrição (meta-description) deve conter informações básicas sobre seu negócio. Esta frase aparece nos resultados de buscas do Google quando você é pesquisado. Seja criativo e utilize, no máximo, 150 caracteres.' />
                )}
              </div>
            </Info>
            <Info>
              <ComponentInput classCustom="input-with-information" ctype="text" label="Palavras-chave (palavras separadas por vírgula)" val={metaKeywords} change={setMetaKeywords} />
              <div className='info-modal'>
                <RiInformationLine onMouseOver={() => { setModalInformation('show'); setModalInfo(3) }} onMouseOut={() => { setModalInformation(''); setModalInfo(0) }} />
                {modalInfo === 3 && (
                  <ModalInformation modalClass={modalInformation} top='-230px' left='-200px' content='Como as pessoas pesquisam sobre seu negócio no Google? Insira palavras que descrevam seu negócio. Exemplos: curso de maquiagem em BH, maquiagem para noivas, curso de auto maquiagem etc. ' />
                )}
              </div>
            </Info>
            <Info>
              <ComponentInput classCustom="input-with-information" ctype="text" label="Código Google Analytics" val={googleAnalytics} change={setGoogleAnalytics} />
              <div className='info-modal'>
                <RiInformationLine className='custom-info' onClick={() => { mostrarModal('view') }} />
                {(showModal !== '') && (
                  <Question
                    view={'view'}
                    close={closeModal}
                    content={'analytics'}
                    className={'alter-height'}
                    style={'alter-height'}
                  />
                )}

                {modalInfo === 4 && (
                  <ModalInformation modalClass={modalInformation} top='' left='-200px' content='Como as pessoas pesquisam sobre seu negócio no Google? Insira palavras que descrevam seu negócio. Exemplos: curso de maquiagem em BH, maquiagem para noivas, curso de auto maquiagem etc. ' />
                )}
              </div>
            </Info>
          </div>
          {/* <ComponentButton ctype="submit" title="Salvar informações" cstyle="btn-link primary" /> */}
        </form>
      </Painel>
    </div>
  );
}

export default Metadados;
import styled from 'styled-components';

import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Wrap = styled.div`
  width: fit-content;
  height: fit-content;
  max-height: 530px;
  position: fixed;
  z-index: 1081;
  border: 1px solid ${colors.border};
  top: 100px;
  right: 75px;
  background-color: ${colors.bgprimary};
  border-radius: 15px;
  box-shadow: ${effects.boxshadow};
  display: flex;
  flex-direction: column;
  padding: 10px;
  @media(max-width: 500px) {
    width: 300px;
    top: 80px;
    right: 10px;
  }
  h3 {
    text-align: flex-start;
    margin-top: 10px;
    margin-left: 10px;
    font-size: 18px;
    
    @media(max-width: 500px) {
      font-size: 14px;
    }
  }
  
  ul {
    list-style: none;
  }
  @media (max-width: 1536px) {
    right: 25px;
  }
  @media (max-width: 1366px) {
    right: 15px;
  }
`;
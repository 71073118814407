import React, { useState, useEffect } from 'react';

import api from '../../services/api-geral';
import params from '../../util/params';
import { Container, Perfil, Form, ListaTweets, Login } from './styles';
import DirectMessagesTwitter from '../../components/DirectMessagesTwitter';
import CentralMidia from '../CentralMidia';
import { RiTwitterLine } from 'react-icons/ri';
import { urlTwitterLogin } from '../../config'

import Tweet from '../Tweet';

function Tweets() {
  const [credentials, setCredentials] = useState({});
  const [isLogged, setIsLogged] = useState(false);
  const [tweets, setTweets] = useState([]);
  const [status, setStatus] = useState('')
  const [image, setImage] = useState('');

  useEffect(() => {
    refreshLogin();
  }, []);

  useEffect(() => {
    if (credentials.accessToken && credentials !== {} && credentials !== null) {
      getTweets();
      window.setInterval(() => {
        getTweets();
      }, 180000)
      setIsLogged(true);
    } else {
      setIsLogged(false);
    }
  }, [credentials]);

  const refreshLogin = async () => {
    const response = (await api.get('/twitter/refresh')).data;
    setCredentials(response);
  };

  const getTweets = async () => {
    const response = (await api.get('/twitter/statuses')).data;
    setTweets(response);
  };

  const retweet = async (id) => {
    const response = (await api.get(`/twitter/statuses/retweet/${id}`)).data;
    getTweets();
  };

  const unretweet = async (id) => {
    const response = (await api.get(`/twitter/statuses/unretweet/${id}`)).data;
    getTweets();
  };

  const destroy = async (id) => {
    const response = (await api.get(`/twitter/statuses/destroy/${id}`)).data;
    getTweets();
  };

  const favorite = async (id) => {
    const response = (await api.get(`/twitter/favorites/create/${id}`)).data;
    getTweets();
  };

  const unfavorite = async (id) => {
    const response = (await api.get(`/twitter/favorites/destroy/${id}`)).data;
    getTweets();
  };

  const postStatus = async e => {
    e.preventDefault();

    if (image) {
      const response = await api.post('/twitter/post/media', {
        status,
        image
      });

      if (response.status === 200) {
        setStatus('');
        setImage('');
        getTweets();
      } else {
        alert("Ocorreu um erro. Tente novamente mais tarde.");
      }

    } else {
      const response = (await api.post('/twitter/post', {
        status
      })).data;

      setStatus('');
      getTweets();
    }


  };

  const logout = async () => {
    const response = (await api.get('/twitter/logout')).data;
    setIsLogged(false);
    setCredentials(response);
  }

  const login = () => {
    window.location = urlTwitterLogin;
  };

  return (
    <>
      <Container>
        {isLogged ? (
          <>
            {/* <DirectMessagesTwitter /> */}
            <div className="head">
              <Perfil bg={credentials.user.banner_photo} >
                <div className="head">
                  <div className="filter">
                    {/* <RiTwitterLine /> */}
                    <img src={credentials.user.photo} />
                    <div className="names">
                      <h2>{credentials.user.displayname}</h2>
                      <span>@{credentials.user.username}</span>
                    </div>
                    <button onClick={() => logout()} className="logout">
                      Sair
                    </button>
                  </div>
                </div>
                <div className="details">
                  <div className="number">
                    <strong>{credentials.user.following}</strong>
                    <span>Seguindo</span>
                  </div>
                  <div className="number">
                    <strong>{credentials.user.followers}</strong>
                    <span>Seguidores</span>
                  </div>
                </div>
              </Perfil>
              <Form onSubmit={postStatus}>
                <div className="head">
                  <h3>
                    <RiTwitterLine />
                    <span>Publicar Tweet</span>
                  </h3>
                </div>
                <div className="body">
                  <textarea value={status} onChange={e => setStatus(e.target.value)} placeholder="No que está pensando?"></textarea>
                  <div className="wrap-botoes">
                    {/* <span onClick={() => setShowMidias(true)}>Adicionar imagem</span> */}
                    <button className="addImage" type="button" onClick={() => document.getElementById('fileinput').click()}>Adicionar Imagem</button>
                    <button type="submit">Publicar</button>
                    <input id="fileinput" type="file" multiple />
                  </div>
                </div>
              </Form>
            </div>
            <ListaTweets>
              {tweets.map(tweet =>
                <Tweet
                  credentials={credentials}
                  post={tweet}
                  retweet={retweet}
                  unretweet={unretweet}
                  destroy={destroy}
                  favorite={favorite}
                  unfavorite={unfavorite}
                />)}
            </ListaTweets>
          </>
        ) : (
          <Login>
            <div className="panel-login">
              <div className="banner">
                <RiTwitterLine />
                <h1>Atualize seu Twitter pelo MERX</h1>
              </div>
              <div className="form-login">
                <h1>Clique no botão abaixo para permitir o acesso do MERX à sua conta!</h1>
                <p>
                  Conecte sua conta para ter controle das funcionalidades do feed do Twitter como visualizar, criar postagens e interagir com outros posts utilizando o Merx.
                </p>
                <button onClick={() => login()} className="btn-login">
                  <RiTwitterLine />
                  <span>Entrar na minha conta</span>
                </button>
              </div>
            </div>
          </Login>
        )}
      </Container>
    </>
  );
}

export default Tweets;
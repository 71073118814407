export function MentionCustomization(editor) {
  editor.conversion.for('upcast').elementToAttribute({
    view: {
      name: 'a',
      key: 'data-mention-merx',
      classes: 'mention',
      attributes: {
        href: true,
        'data-user-id': true
      }
    },
    model: {
      key: 'mention',
      value: viewItem => {
        const mentionAttribute = editor.plugins.get('Mention').toMentionAttribute(viewItem, {
          link: viewItem.getAttribute('href'),
          userId: viewItem.getAttribute('data-user-id')
        });

        return mentionAttribute;
      }
    },
    converterPriority: 'high'
  });

  editor.conversion.for('downcast').attributeToElement({
    model: 'mention',
    view: (modelAttributeValue, { writer }) => {
      if (!modelAttributeValue) {
        return;
      }

      return writer.createAttributeElement('a', {
        class: 'mention',
        'data-mention-merx': modelAttributeValue.id + `/id:${modelAttributeValue.userId}`,
        'data-user-id': modelAttributeValue.userId,
        'href': modelAttributeValue.link
      }, {
        priority: 20,
        id: modelAttributeValue.uid
      });
    },
    converterPriority: 'high'
  });
}

import styled, { css } from 'styled-components';

import colors from '../../../presets/colors';
import effects from '../../../presets/effects';

export const Toggles = styled.div`
  width: 50%;

  @media (max-width: 1440px) {
    width: 60%;
  }

  @media (min-width: 701px) and (max-width: 750px) {
    width: 150%;
  }

  @media (max-width: 700px) {
    width: 100%;
  }

  .toggles-contato, .toggle-formulario, .toggle-endereco {
    .toggle-funcionalidade {
      width: 45px;
      height: 25px;
      background-color: white;
      border: 1px solid rgb(0 0 0 / 20%);
      border-radius: 15px;
      padding: 0 5px;
      cursor: pointer;
      position: absolute;
      right: 0;
      display: flex;
      align-items: center;

      &.active {
        justify-content: flex-end;

        .indicator {
          background-color: #00cc66;
        }
      }

      .indicator {
        width: 17px;
        height: 17px;
        background-color: #999;
        border-radius: 10px;
      }
  
    }
  }
  
  .button-name{
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        span{
          padding: 0px 10px;
        }
      }
  .toggles-contato {
    display: flex;
    position: relative;

    @media (max-width: 1250px) {
      flex-direction: column;

      .toggle-chat {
        width: 100% !important;
        margin-bottom: 20px;
      }

      .toggle-whatsapp {
        width: 100% !important;
      }
    }

    .info-holder {
      width: 50%;
      position: relative;

      @media (max-width: 1250px) {
        width: 100%;
      }

      &.phone {
        margin-left: 20px;

        @media (max-width: 1250px) {
          margin-left: 0px;
        }
      }
    }

    .toggle-chat {
      width: 100%;
      height: 50px;
      overflow: hidden;
      padding: 8px 20px;
      margin-right: 20px;
      border-radius: 5px;
      background-color: rgba(142,142,142,0.1);
      box-shadow: ${effects.boxshadow};
      transition: all 0.5s;

      &.active {
        height: 150px;
        padding: 20px;
      }

      .toggle-historico {
        display: flex;
        align-items: center;
        position: relative;
        margin-top: 30px;
        width: 100%;
        font-weight: 500;

        .wrap-historico {
          display: flex;
          align-items: center;

          .wrap-icon {
            height: 35px;
            width: 35px;
            border-radius: 50%;
            border: 1px groove rgb(0 0 0 / 8%);
            margin-right: 10px;
            display: flex;
            align-items: center;
            place-content: center;

            svg {
              font-size: 22px;
              color: ${colors.primary};
            }
          }

          span {
            font-weight: 500;

            @media (max-width: 350px) {
              max-width: 100px;
            }
          }
        }
      }
    }

    .toggle-whatsapp {
      width: 100%;
      height: 50px;
      overflow: hidden;
      padding: 8px 20px;
      border-radius: 5px;
      background-color: rgba(142,142,142,0.1);
      box-shadow: ${effects.boxshadow};
      transition: all 0.5s;

      .whatsapp-number {
        display: flex;
        align-items: center;
        margin-top: 30px;

        .input-style {
          border: none;
          margin: 0;
          
          input {
            border-radius: 5px;
          }
        }
      }

      .save-wpp {
        margin-left: 10px;
        border: none;
        height: 48px;
        padding: 0 15px;
        border-radius: 10px;
        color: white;
        background-color: ${colors.primary};
      }

      &.active {
        height: 150px;
        padding: 20px;
      }
    }
  }

  .toggle-endereco {
    width: 100%;
    height: 50px;
    overflow: hidden;
    padding: 8px 20px;
    border-radius: 5px;
    background-color: rgba(142,142,142,0.1);
    box-shadow: ${effects.boxshadow};
    transition: all 0.5s;
    margin-top: 20px;

    .toggle {
      width: calc(50% - 30px);

      @media (max-width: 1250px) {
        width: 100%;
      }
    }

    .address-page {
      display: flex;
      align-items: center;
      margin-top: 30px;

      .input-style {
        margin: 0;
        
        input {
          border-radius: 5px;
        }
      }
    }

    .save-wpp {
      margin-left: 10px;
      border: none;
      height: 48px;
      padding: 0 15px;
      border-radius: 10px;
      color: white;
      background-color: ${colors.primary};
    }

    &.active {
      height: 150px;
      padding: 20px;
    }
  }

  .toggle-formulario {
    width: 100%;
    height: 50px;
    height: auto;
    overflow: hidden;
    padding: 8px 20px;
    margin-top: 20px;
    margin-right: 20px;
    border-radius: 5px;
    background-color: rgba(142,142,142,0.1);
    box-shadow: ${effects.boxshadow};
    transition: all 0.5s;
    position: relative;

    @media (max-width: 335px) {
      height: 65px;

      .toggle {
        .title {
          span {
            width: 75%;
          }
        }
      }
    }

    @media (min-width: 1251px) {
      .toggle {
        width: calc(50% - 30px);
      }
    }

    &.active {
      height: 280px;
      height: auto;
      padding: 20px;

      @media (max-width: 1400px) {
        height: 380px;
        height: auto;
      }

      .actions {
        .search-filter {
          opacity: 1;
        }
      }
    }
    
    .actions {
      position: absolute;
      top: 20px;
      right: 20px;

      .search-filter {
        opacity: 0;

        .search {
          display: flex;
          
          svg {
            height: 35px;
            width: 30px;
            padding: 5px;
            background: white;
            border: solid 1px rgb(0 0 0 / 8%);
            border-right: none;
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
          }

          input {
            width: 100%;
            line-height: 1;
            height: 35px;
            resize: none;
            border: solid 1px rgb(0 0 0 / 8%);
            border-left: none;
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;

          }
        }
      }

      @media (max-width: 1199px) {
        margin-top: 30px;
        position: relative;
        top: auto;
        right: auto;
      }
    }

    .assuntos {
      position: relative;
      margin-top: 10px;

      .select-all {
        width: 100%;
        padding: 0 10px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .assuntos-title {
          position: relative;
        }

        .checkbox {
          margin-right: 10px;
          height: 16px;
          width: 16px;
          border: 1px groove rgb(0 0 0 / 20%);
          cursor: pointer;

          &.active {
            border: none;
            background-color: ${colors.primary};
          }
        }
      }

      .buttons-actions {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;

        .button-add {
          width: 35px;
          height: 25px;
          border-radius: 15px;
          border: none;
          background-color: #67bb49;
          display: flex;
          align-items: center;
          place-content: center;
          margin-right: 15px;
          
          svg {
            color: white;
            font-size: 25px;
          }
        }

        .button-delete {
          width: 35px;
          height: 25px;
          border-radius: 15px;
          border: 1px solid ${colors.primary};
          background-color: white;
          display: flex;
          align-items: center;
          place-content: center;
          transition: all 0.3s;

          &:hover {
            background-color: ${colors.primary};
            
            svg {
              color: white;
            }
          }

          svg {
            color: ${colors.primary};
            font-size: 17px;
          }
        }
      }

      .assuntos-list {
        padding-right: 5px;
        padding: 10px;
        height: 170px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
          background: ${colors.border};
          border-radius: ${effects.radius};
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }

        @media (max-width: 1400px) {
          height: 215px;
        }
      }
    }
  }

  .toggle {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;

    .title {
      display: flex;
      align-items: center;

      .wrap-icon {
        height: 35px;
        width: 35px;
        border-radius: 50%;
        border: 1px groove rgb(0 0 0 / 8%);
        margin-right: 20px;
        display: flex;
        align-items: center;
        place-content: center;

        &.phone {
          svg {
            font-size: 18px;
          }
        }

        svg {
          font-size: 22px;
          color: ${colors.primary};
        }
      }

      span {
        font-weight: 500;
      }
    }
  }
`;

export const Info = styled.div`
  display: flex;
  position: absolute;
  right: 60px;
  top: -2px;

  svg {
    font-size: 18px;
  }

  &.info-assunto {
    right: -40px;
    /* left: 0; */
    top: -8px;
  }

  .info-modal {
    position: relative;
    left: 5px;
    top: 11px;

    @media (max-width: 350px) {
      top: 17px;
    }
  }
  .info-modal-2 {
    position: relative;
    left: 5px;
    top: 11px;

    @media (max-width: 350px) {
      top: 17px;
    }
  }
`;

export const NovoAssunto = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;

  .backdrop {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);;
    width: 100%;
    height: 100%;
    z-index: 1049;
  }

  .modal {
    position: absolute;
    background-color: white;
    border-radius: 15px;
    width: 300px;
    z-index: 1050;

    hr {
      width: 100%;
    }

    .head {
      padding: 15px;
    }

    .content {
      padding: 0 15px;
      margin: -15px 0px;
    }

    .save {
      padding: 10px;
      width: 100%;
      text-align: center;

      button {
        height: 40px;
        padding: 0 20px;
        border: none;
        color: white;
        background-color: ${colors.primary};
        border-radius: 25px;
      }
    }
  }
`

export const Button = styled.button`
  border: none;
  background: white;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  top: 5px;

  svg {
    font-size: 25px;
  }

`
export const WrapCustomInput = styled.div`
  width: 100%;
  display: flex;
  padding: 2px;
  height: 50px;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 25px 0;
  transition: border-color .5s;

  ${props => (props.variant === 'radius') && css`
    border-radius: 10px;
  `}
  
  &:focus-within {
    border-color: ${colors.primary};

    label {
      color: ${colors.primary};
    }
  }

  &.input-senha {
    margin-bottom: 5px !important;
  }

  &.input-with-information {
    input {
      padding-right: 30px;
    }
  }
`;


export const LabelCustomInput = styled.label`
  background-color: white;
  position: absolute;
  top: -10px;
  left: 10px;
  font-size: 14px;
  padding: 0 5px;
  z-index: 1050;
  color: rgba(0, 0, 0, 0.5);
  transition: color .5s;
`;


export const SetorSelector = styled.select`
border: none;
background: transparent;
width: 100%;
padding: 0 8px;
font-size: .8rem;
`
export const SetorOption = styled.option``




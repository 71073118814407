import styled from 'styled-components';

import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Container = styled.li`

  width: calc(25% - 40px);
  height: 60px;
  font-size: 18px;
  font-weight: bold;
  background-color: ${colors.bgprimary};
  padding: 0;
  border: 1px solid ${colors.border};
  border-radius: 5px;
  transition: all .5s;
  cursor: pointer;
  color: ${colors.primary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
  position: relative;

  @media (max-width: 1400px) {
    height: 50px;

    svg {
      font-size: 40px;
    }
  }

  @media (max-width: 720px) {
    width: calc(50% - 40px);
    margin-top: 10px;
    margin-bottom: 10px;
  }

  @media(max-width: 420px) {
    width: calc(100% - 40px);
  }

  &.selected {
    border: 2px solid ${effects.primary};
  }

  &.file {
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.05);
  }

  &.folder {
    border: 0;
    box-shadow: ${effects.boxshadow};
  }

  svg {
    color: ${colors.secondary};
    font-size: 30px;
    margin-right: 20px;
  }
  
  .form-edit-folder {

  }

  .btn-edit {
    font-size: 20px;
    margin: 0;
    height: 100%;
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      @media (max-width: 1400px) {
        margin-right: 10px;  
      }
    }
  }

  .info-item {
    display: flex;
    align-items: center;
    height: 100%;
    width: 90%;
    padding: 0 20px;

    @media (max-width: 1400px) {
      padding: 0 10px;
      font-size: 15px;

      svg {
        font-size: 20px;
        margin-right: 10px;
      }
    }

    &.full {
      width: 100%;
    }
  }

  input {
    height: 30px;
    border-radius: 5px;
    padding: 0;
    font-weight: bold;
    z-index: 1051;
    border: 1px solid ${colors.border};
  }

  .backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1050;
  }

  .options {
    list-style: none;
    padding: 0;
    position: absolute;
    top: calc(100% + 10px);
    right: 10px;
    z-index: 1051;
    width: 200px;

    li {
      padding: 8px 20px;
      background-color: rgba(0, 0, 0, 0.7);
      color: white;
      margin-bottom: 10px;
      font-size: 14px;
      border-radius: 10px;
      display: flex;
      align-items: center;

      svg {
        font-size: 18px;
        height: auto;
        width: auto;
        margin-right: 5px;
      }
    }
  }
  
`;

import styled from 'styled-components';

import colors from '../presets/colors';
import effects from '../presets/effects';

export const Content = styled.div`
  padding: 25px;
`;

export const Container = styled.div`
  display: flex;
  width: 95%;
  margin: 0 auto;
  height: 100%;
`;

export const TituloPagina = styled.h1`
  font-weight: 400;
  margin: 0;
  color: ${colors.texts};
`;

export const Input = styled.input`

`;

export const Painel = styled.div`
  width: 100%;

  &.painel-configuracao,
  &.painel-configuracao-empresa,
  &.painel-configuracao-perfil,
  &.painel-configuracao-senhatemp,
  &.painel-config-notificacoes,
  &.painel-configuracao-links,
  &.painel-configuracao-redes-sociais,
  &.painel-perfil,
  &.painel-configuracao-site,
  &.painel-aparencia {
    width: 350px;
    background-color: #fff;
    padding: 25px 0;
    margin-top: 20px;
    // box-shadow: 0 3px 30px rgba(0,0,0,.1), 0 3px 20px rgba(0,0,0,.1);
    box-shadow: ${effects.boxshadow};
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.painel-configuracao {
    padding: 25px 10px;

    .input-login {
      margin: 0 auto;
    }
  }

  &.painel-configuracao-perfil {
    margin: 0;

    @media (max-width: 575px) {
      padding: 20px !important;
    }
  }

  &.painel-configuracao-empresa {
    flex-direction: column;

    .wrap-input {
      width: 300px;
    }

    @media (max-width: 1024px) {
      .wrap-input {
        width: 100%;
      }

      .wrap-btn {
        width: 100%;
      }
    }
    // .wrap-btn {
    //   display: flex;
    //   justify-content: flex-end;
    //   width: 300px;
    // }
  }

  @media (max-width: 1024px) {
		&.painel-configuracao {
      width: 100%;
    }

    &.painel-configuracao-empresa, &.painel-configuracao-perfil,&.painel-configuracao-senhatemp {
      width: 70%;
      padding: 10px;
      display: block;
    }
	}
  
  @media (max-width: 575px) {
    &.painel-configuracao-empresa, &.painel-configuracao-perfil,&.painel-configuracao-senhatemp {
      width: 100%;
    }
  }
`;

export const Backdrop = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1058;

  &.alter-background {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

export const BackdropCenter = styled.div`
  width: calc(100vw - 110px);
  height: calc(100vh - 90px);
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1058;

  &.alter {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.3);

    @media (max-width: 1024px) {
    z-index: 8050;
    }
  }

  &.alter-no-background {
    position: fixed;
    
    @media (max-width: 1024px) {
    z-index: 8050;
    }
  } 

  @media (max-width: 1024px) {
    width: 100vw;
    height:100vh;
  }

`;
import styled from 'styled-components';
import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Container = styled.div`
	form {
		background-color: white;
		position: absolute;
		right: 25px;
		top: 28px;

		svg { color: #333; }
	}

	.painel-logs {
		height: 74vh;
		overflow-y: scroll;
		margin-top: 20px;

		.scroll > div {
			padding: 10px;
		}

		ul {
			li {
				background-color: white;
				box-shadow: ${effects.boxshadow};
				margin-bottom: 5px;
				padding: 10px;
				border-radius: 5px;

				&:last-child {
					margin: 0
				}

				strong {
					margin-right: 10px;
				}
				
				@media (max-width: 575px) {
					margin-bottom: 10px;
				}
			}

			li.button {
				background-color: transparent;
				border-radius: 0;
				padding: 0;
				box-shadow: none;
				margin-top: 10px;

				button {
					background-color: ${colors.primary};
					height: 40px;
					padding: 0 10px;
					color: white;
					border: 0;
					border-radius: 5px;
				}
			}
		}

		&::-webkit-scrollbar {
			width: 4px;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			background: #f1f1f1;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			background: ${colors.border};
			border-radius: ${effects.radius};
		}

		/* Handle on hover */
		&::-webkit-scrollbar-thumb:hover {
			background: #555;
		}
	} 
	
	@media (max-width: 1024px) {
		height: 100%;
    	overflow: hidden;

		.painel-logs {
			padding-bottom: 30px;
		}

		h1 {
			font-size: 1.3rem;
		}
		form {
			position: relative;
			right: 0px;
			top: 0px;
			margin-bottom: 15px;
			margin-top: 30px;
			width: 300px;
		}
	}

	@media (max-width: 575px) {
		form {
			left: calc(50% - 125px);
			width: 250px;
		}
	}
`;

export const LoadingWrap = styled.div`
  width: 100%;
	position: fixed;
  height: 100vh;
  background-color: none;
  border-radius: 0.75rem;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
	color: ${colors.primary};

  h3 {
    margin-top: 15px;
  }

  svg {
    font-size: 25px;
  }

  .fa-spin {
    -webkit-animation: icon-spin 1s infinite linear;
    animation: icon-spin 1s infinite linear;
  }

  @-webkit-keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }

  @keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
`;
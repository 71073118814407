import React, { useState, useEffect } from 'react';

import { WrapCustomInput, LabelCustomInput, CustomInput } from './styles';

import InputMask from 'react-input-mask';

export default function CInput({ classCustom, type, label, val, change, required, disabled, mask, focused = null, style, reference, maxLength }) {
  const [selectedMask, setSelectedMask] = useState('');
  const [placeHolderInputMask, setPlaceHolderInputMask] = useState('');

  useEffect(() => {
    switch (mask) {
      case 'telephone':
        setSelectedMask('(99) 9999-9999');
        setPlaceHolderInputMask('(__) ____-____');
        break;
      case 'phone':
        setSelectedMask('(99) 99999-9999');
        setPlaceHolderInputMask('(__) _____-____');
        break;
      case 'phoneWpp':
        setSelectedMask('99 (99) 99999-9999');
        setPlaceHolderInputMask('__ (__) _____-____');
        break;
      case 'date':
        setSelectedMask('dd/mm/aaaa');
        setPlaceHolderInputMask('__/__/____');
        break;
      default:
        setSelectedMask('');
        setPlaceHolderInputMask('');
        break;
    }
  }, [mask]);

  return (
    <WrapCustomInput className={`input-style ${classCustom}`} variant={style}>
      <LabelCustomInput>{label}</LabelCustomInput>
      {(selectedMask !== '') ? (
        <InputMask placeholder={placeHolderInputMask} mask={selectedMask} value={val} onChange={e => change(e.target.value)}>
          {(inputProps) => <CustomInput
            type={type}
            required={required}
            ref={reference}
            autoFocus={focused}
            {...inputProps}
          />}
        </InputMask>
      ) : (
        <CustomInput
          type={type}
          required={required}
          disabled={disabled}
          max="9999-12-31"
          value={val}
          ref={reference}
          autoFocus={focused}
          onChange={e => change(e.target.value)}
          maxLength={maxLength}
        />
      )}
    </WrapCustomInput>

  );
}

import React, { useEffect, useState } from 'react';

import { AiOutlineTag } from 'react-icons/ai';
import { HiAtSymbol } from "react-icons/hi";
import { Container } from './styles';

function MentionsFeed({ markedUsers, cclass }) {

    const [modalMentions, setModalMentions] = useState([]);
    const [atMentions, setAtMentions] = useState([]);

    useEffect(() => {
        if (markedUsers.length > 0) {
            let modal = markedUsers.filter(mark => mark.modal === true);
            modal.sort((a, b) => a.name.localeCompare(b.name));
            let at = markedUsers.filter(mark => mark.modal === false);
            at.sort((a, b) => a.name.localeCompare(b.name));
            setModalMentions(modal);
            setAtMentions(at);
        }
    }, [markedUsers])

    return (
        <>
            <Container className={cclass}>
                {modalMentions.length > 0 && <AiOutlineTag />}
                {modalMentions.length > 0 && (modalMentions.map((user, index) => (
                    <span key={user.id}>
                        {user.name}{(index != (modalMentions.length - 1)) && ', '}
                    </span>
                )))}
            </Container>
            <Container className={cclass}>
                {atMentions.length > 0 && <HiAtSymbol />}

                {atMentions.length > 0 && (atMentions.map((user, index) => (
                    <span key={user.id}>
                        {user.name}{(index != (atMentions.length - 1)) && ', '}
                    </span>
                )))}
            </Container>
        </>
    );
}

export default MentionsFeed;
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

import CButton from '../ComponentButton';

import { Backdrop, WrapModal, WrapModalView, AreaToClose } from './styles';
import { MdClose } from 'react-icons/md';

export default function CModal({ form, close, view, hasChangesModal, setHasChangesModal }) {

  // const [viewModal, setViewModal] = useState(false);

  // useEffect(() => {
  //   if (view) {
  //     setViewModal(true)
  //   }
  // }, [view]);

  const showSwal = () => {
    if (hasChangesModal) {
      Swal.fire({
        title: 'Atenção',
        text: 'Você possui alterações não publicadas, continuar mesmo assim?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Descartar',
        cancelButtonText: 'Não'
      }).then(async (result) => {
        if (result.isConfirmed) {
          setHasChangesModal(false);
          close();
        }
      });
    } else {
      close();
    }
  }

  const clickToExit = (e) => {
    showSwal()
  }

  return (
    <Backdrop>
      {(view) ? (
        <WrapModalView className="modal-viewer">
          <CButton
            cstyle='default small closebutton'
            title={(<MdClose />)}
            click={close}
          />
          {form}
        </WrapModalView>
      ) : (
        <WrapModal>
          <CButton
            cstyle='default small closebutton'
            title={(<MdClose />)}
            click={showSwal}
          />
          {form}
        </WrapModal>
      )}
      <AreaToClose onClick={(e) => { clickToExit(e) }} />
    </Backdrop >
  );
}

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as userConversaActions from '../../store/modules/userConversa/actions';
import { bindActionCreators } from 'redux';
import api from '../../services/api-geral';
import socket from '../../services/socket';
import { sessionGet } from '../../session';
import ConversaExterno from '../ConversaExterno';
import ItemConversa from '../ItemConversa';
import getFiles from '../../util/getFiles';
import { getIdWithoutCripto } from '../../util/formatParamsUrl';

import { MdSearch, MdPerson, MdClose } from 'react-icons/md';

import { Wrap, Sidebar, Search, Perfil, Form, ListaConversa } from './styles';

function ChatExterno({ setUserConversa, dispatch }) {
  const [usuarios, setUsuarios] = useState([]);
  const [usuariosFila, setUsuariosFila] = useState([]);
  const [fotoPerfil, setFotoPerfil] = useState();
  const [setor, setSetor] = useState('contato');
  const [pesquisa, setPesquisa] = useState('');
  const [handlePesquisa, setHandlePesquisa] = useState('');
  const [idAtendimento, setIdAtendimento] = useState('');
  const [allowRead, setAllowRead] = useState(false);

  const [ckEditor, SetCkEditor] = useState('');

  const [display, setDisplay] = useState(true);
  const [invDisplay, setInvDisplay] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [smallWidth, setSmallWidth] = useState(false);

  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
    };
    if (width > 1024) {
      setSmallWidth(false);
      setDisplay(true);
    } else {
      setSmallWidth(true);
    }
    window.addEventListener("resize", handleWindowResize);
  }, [width]);

  const checkDisplay = () => {
    if (smallWidth) {
      setDisplay(false);
    }
  }

  const getUsuarios = async () => {
    const response = (await api.get(`/atendimentos-setor/${sessionGet('idSetor')}`)).data;

    let users = [];
    let usersFila = [];
    response.map((atendimento) => {
      if (atendimento.id_atendente === sessionGet('idUser')) {
        users.push(atendimento);
      } else if (atendimento.status === 'fila') {
        usersFila.push(atendimento);
      }
    })
    setUsuarios(users);
    setUsuariosFila(usersFila);
  };

  const getUsuario = async () => {
    const response = (await api.get(`/users/${sessionGet('idUser')}`)).data;
    if (response.id_file) {
      setFotoPerfil(getFiles(response.files.path));
    } else {
      setFotoPerfil('')
    }
  };

  const postAtendimento = async () => {
    /*const response = await api.post('/atendimentos');*/

  }

  useEffect(() => {
    let queryString = window.location.search;
    if (queryString) {
      const urlParams = new URLSearchParams(queryString);
      let id = getIdWithoutCripto(urlParams.get('id_atendimento'));
      let id_atendente = getIdWithoutCripto(urlParams.get('id_contato'));
      id = parseInt(id);
      getUser(id_atendente);
      setIdAtendimento(id);
    }
    setAllowRead(true);
  }, [])

  const getUser = async (id) => {
    const response = (await api.get(`/users/${id}`)).data;
    delete response.setor;
    setUserConversa(response);
  }

  useEffect(() => {
    postAtendimento();
    getUsuarios();
    getUsuario();

    socket.on('userConnectClient', () => {
      window.setTimeout(() => {
        getUsuarios();
      }, 1000)
    });

    socket.on('contatoDisconnect', () => {
      window.setTimeout(() => {
        getUsuarios();
      }, 1000)
    });

    socket.on('userDisconnectClient', () => {
      window.setTimeout(() => {
        getUsuarios();
      }, 1000)
    });

    socket.on('atualiza_status_atendimento', () => {
      getUsuarios();
    });

  }, []);

  const selecionarConversa = async (userConversa, id) => {
    setUserConversa(userConversa);
    const responseAtendimento = (await api.get(`/atendimentos/${id}`)).data;
    setIdAtendimento(responseAtendimento.atendimento.id);

    if (ckEditor) {
      ckEditor.editing.view.focus();
    }
  };

  useEffect(() => {
    let newSearch = pesquisa;
    newSearch = newSearch.toLowerCase();
    newSearch = newSearch.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
    newSearch = newSearch.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
    newSearch = newSearch.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
    newSearch = newSearch.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
    newSearch = newSearch.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
    setPesquisa(newSearch);
  }, [pesquisa])

  const formatName = (name) => {
    var newName = name;

    newName = newName.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
    newName = newName.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
    newName = newName.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
    newName = newName.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
    newName = newName.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');

    return newName;
  }

  return (
    <Wrap>
      <Sidebar style={(display == true) ? { display: 'block' } : { display: 'none' }}>
        <Search>
          {!idAtendimento ? '' : <button className="display-button" onClick={() => { setInvDisplay(false); setDisplay(false); }}><MdClose /></button>}
          <Perfil>
            {(fotoPerfil && !fotoPerfil.error) ? <img src={fotoPerfil} /> : <MdPerson />}
          </Perfil>
          <Form>
            <MdSearch />
            <input placeholder="Pesquise por um contato" value={handlePesquisa} onChange={(e) => { setPesquisa(e.target.value); setHandlePesquisa(e.target.value) }} />
          </Form>
        </Search>
        <ListaConversa>
          {usuariosFila.length > 0 ? <h4>Atendimentos em fila</h4> : ''}
          {
            usuariosFila.length > 0 ? usuariosFila.map(conversa => (
              <>
                <div key={conversa.id} className="conversaClicker">
                  <ItemConversa user={conversa.cliente} type="externo" fila={true} atendimento={conversa} />
                </div>
              </>
            )) :
              ''
          }
          <h4 className="titulos-atendimento">Seus atendimentos</h4>
          {(pesquisa == '') ?
            usuarios.map(conversa => (
              <>
                <div key={conversa.id} className='conversaClicker' onClick={() => { selecionarConversa(conversa.cliente, conversa.id); checkDisplay(); }}>
                  <ItemConversa user={conversa.cliente} type="externo" fila={false} atendimento={conversa} />
                </div>
              </>
            ))
            :
            usuarios.filter((v) => { return formatName(v.cliente.name.toLowerCase()).includes((pesquisa)) }).map((conversa) => (
              <div className="conversaClicker" onClick={() => { selecionarConversa(conversa.cliente, conversa.id); checkDisplay(); }}>
                <ItemConversa user={conversa.cliente} type="externo" fila={false} atendimento={conversa} />
              </div>
            ))
          }
          {usuarios.length === 0 && (
            <div className="atendimento-vazio">
              <span>Ainda não há atendimentos com clientes.</span>
            </div>
          )}
        </ListaConversa>
      </Sidebar>
      <ConversaExterno setorChat={setor} idAtendimentoInterno={idAtendimento} local='interno' allowRead={allowRead} setDisplay={setDisplay} setInvDisplay={setInvDisplay} ckEditor={ckEditor} SetCkEditor={SetCkEditor} />
    </Wrap>
  );
}

const mapDispatchToProps = dispatch => bindActionCreators(userConversaActions, dispatch);

export default connect(null, mapDispatchToProps)(ChatExterno);

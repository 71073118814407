import React from 'react';

import { Container, Head, Body } from './styles';

import { RiHeart2Line, RiChat4Line } from 'react-icons/ri';

import Logo from '../../assets/logo_instagram.png';

function PostsInstagram() {
    return (
        <Container>
            <Head>
                <div className="topbar">
                    <img src={Logo} />

                    <button className="btn-deslogar">Deslogar</button>
                </div>
                <div className="info">
                    <img src="https://instagram.fplu3-1.fna.fbcdn.net/v/t51.2885-19/s150x150/29090285_2079109192314053_3497579022332723200_n.jpg?_nc_ht=instagram.fplu3-1.fna.fbcdn.net&_nc_ohc=G3pYQtFpt6oAX_GfZ0m&oh=c5e4dbd25b0cf35bbe68c9026a4a159c&oe=5FAFF56A" />
                    <div className="desc">
                        <h1>
                            ryandrumond
                        </h1>
                        <ul>
                            <li><strong>11</strong> publicações</li>
                            <li><strong>206</strong> seguidores</li>
                            <li><strong>375</strong> seguindo</li>
                        </ul>
                        <h3>Ryan Drumond</h3>
                    </div>
                </div>
            </Head>
            <Body>
                <div className="post">
                    <img src="https://instagram.fplu3-1.fna.fbcdn.net/v/t51.2885-15/sh0.08/e35/s750x750/116657383_830980990766848_5712988285121720952_n.jpg?_nc_ht=instagram.fplu3-1.fna.fbcdn.net&_nc_cat=104&_nc_ohc=J2Ks0OHbXl8AX-BypzN&_nc_tp=24&oh=a490adbb0143d2755cab410e85bb77b3&oe=5FB13367" />
                    <ul>
                        <li><RiHeart2Line /> <span>120</span></li>
                        <li><RiChat4Line /> <span>2000</span></li>
                    </ul>
                </div>
                <div className="post">
                    <img src="https://instagram.fplu3-1.fna.fbcdn.net/v/t51.2885-15/sh0.08/e35/s750x750/116657383_830980990766848_5712988285121720952_n.jpg?_nc_ht=instagram.fplu3-1.fna.fbcdn.net&_nc_cat=104&_nc_ohc=J2Ks0OHbXl8AX-BypzN&_nc_tp=24&oh=a490adbb0143d2755cab410e85bb77b3&oe=5FB13367" />
                    <ul>
                        <li><RiHeart2Line /> <span>120</span></li>
                        <li><RiChat4Line /> <span>2000</span></li>
                    </ul>
                </div>
                <div className="post">
                    <img src="https://instagram.fplu3-1.fna.fbcdn.net/v/t51.2885-15/sh0.08/e35/s750x750/116657383_830980990766848_5712988285121720952_n.jpg?_nc_ht=instagram.fplu3-1.fna.fbcdn.net&_nc_cat=104&_nc_ohc=J2Ks0OHbXl8AX-BypzN&_nc_tp=24&oh=a490adbb0143d2755cab410e85bb77b3&oe=5FB13367" />
                    <ul>
                        <li><RiHeart2Line /> <span>120</span></li>
                        <li><RiChat4Line /> <span>2000</span></li>
                    </ul>
                </div>
            </Body>
        </Container>
    );
}

export default PostsInstagram;
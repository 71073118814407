import styled from 'styled-components';

import colors from '../../presets/colors';
// import effects from '../../presets/effects';

export const Container = styled.div`
  width: ${props => (props.customWidth) ? `${props.customWidth}` : '50%'};
  font-size: 13px;
  border-radius: 5px;
  margin-bottom: 40px;
  padding: 5px;
  display: flex;
  align-items: center;

  .box-icon{
    display:flex;
    justify-content:flex-start;
    height:100%;
    margin-top:5px;
  }

  &.mail-send {
    width: 100%;
    margin: 0;
    height: 30px;
  }

  &.success {
    color: rgb(13, 99, 13);
    background-color: rgb(227, 255, 227);

    @media (max-width: 575px) {
      width: 90%;
    }
  }

  &.error {
    color: red;
    background-color: rgb(255, 222, 222);
    
    @media (max-width: 575px) {
      width: 90%;
    }
  }

  &.danger {
    color: red;
    background-color: #ffdede;

    @media (max-width: 575px) {
      width: 90%;
    }
  }

  &.info {
    color: rgb(138, 35, 135);
    background-color: rgb(255, 212, 254);

    @media (max-width: 900px) {
      width: 100%;
    }
  }

  &.custom {
    width: 100%;
    margin: ${props => (props.customMargin) ? `${props.customMargin}` : '0'};
  }

  svg {
    font-size: 20px;
    flex: 1;
  }

  .content-alert {
    flex: 8;
    display: flex;
    flex-direction: column;
    padding:5px;
  }

  .second-content{
    font-weight: 500;
    margin-top: 10px ;
    padding:5px;
  }
`;

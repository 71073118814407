import styled from 'styled-components';

import colors from '../../presets/colors';

export const Container = styled.ul`
    flex-wrap: wrap;
    width: 100%;
    //margin-top: 15px;
    list-style: none;
    padding: 0;
    display: flex;

    li {
        background-color: rgba(0, 0, 0, 0.05);
        display: flex;
        align-items: center;
        padding: 0 5px;
        border-radius: 10px;
        height: auto;
        margin-right: 10px;
        margin-top: 10px;

        span {
            font-size: 14px;
        }

        button {
            background-color: ${colors.secondary};
            width: 15px;
            height: 15px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 5px;
            border-radius: 50%;
            border: 0;
            color: white;
        }
    }
`;

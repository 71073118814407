import React, { useEffect, useState } from 'react';
import socket from '../../services/socket';
import { sessionGet } from '../../session';
import { getIdWithoutCripto } from '../../util/formatParamsUrl';
import pushNotification from '../../util/pushNotification';

import NotifySound from '../../assets/sounds/notify2.mp3';

function SocketNotificacoes({ load }) {

    useEffect(() => {

        socket.on('loadNotifications', (notify) => {

            load();

            if (sessionGet('idUser') === notify.id) {

                if (notify.sound) {
                    // if (notify.notf.local == 'chat') {
                    //     pushNotification("Você recebeu uma mensagem no Merx.", "Uma nova mensagem lhe aguarda no chat.")
                    // }
                    if (notify.notf !== null && notify.notf !== undefined && notify.notf !== '') {
                        var url = window.location.href;
                        if (url.includes('conversas')) {
                            var decrypto = url.split('/');
                            decrypto = decrypto[decrypto.length - 1];
                            if (decrypto !== 'conversas') {
                                decrypto = getIdWithoutCripto(decrypto);
                                if (notify.notf.id_autor !== Number(decrypto)) {
                                    const audio = new Audio(NotifySound);
                                    audio.volume = 0.4;
                                    audio.play();
                                } else {
                                    if (document.visibilityState === 'hidden') {
                                        const audio = new Audio(NotifySound);
                                        audio.volume = 0.4;
                                        audio.play();
                                    }
                                }
                            }
                        } else {
                            const audio = new Audio(NotifySound);
                            audio.volume = 0.4;
                            if (notify.notf.local == 'chat') {
                                pushNotification("Você recebeu uma nova mensagem no Merx.", "Novidades lhe aguardam no chat.")
                            }
                            audio.play();
                        }
                    }
                }
            }
        });
        socket.on('getNotifications', (id_user) => {
            if (sessionGet('idUser') === id_user || id_user === 'all') {
                load();
            }
        })

        socket.on('msg_atendimento_atendente', (msg) => {

            const id_user = msg.id_to;
            if (sessionGet('idUser') === id_user || id_user === 'all') {
                load('atendimento');
                const audio = new Audio(NotifySound);
                audio.volume = 0.4;
                audio.play();
            }
        })

        return () => {
            socket.off('loadNotifications');
            socket.off('getNotifications');
            socket.off('msg_atendimento_atendente');
        };
    }, []);

    return <></>;
}

export default SocketNotificacoes;
import styled from 'styled-components';
import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Container = styled.div`
  background-color: ${colors.bgprimary};
  box-shadow: ${effects.boxshadowsm};
  border: 1px solid ${colors.border};
  padding: 15px;
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 1050;
  width: max-content;

  p.name {
    font-size: 1rem;
    font-weight: bold;
  }

  a {
    display: inline-block;
    align-items: center;
    text-decoration: none;
    color: ${colors.primary};

    svg {
      margin-left: 5px;
    }
  }
  span{
    color: ${colors.primary};
  }
`;
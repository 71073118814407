import React, { useEffect, useState } from 'react';

import { MdGroup } from 'react-icons/md';
import Editor from '../Editor';

import api from '../../services/api-geral';
import { sessionGet } from '../../session';
import notify from '../../util/notify';
import AlertaMerx from '../AlertaMerx';

import { Wrap, ImgAndStatus } from './styles';

export default function ChatEspalhar({ listaEnvio, setEspalhar, setDisplay, setInvDisplay }) {
    const [showAlertMerx, setShowAlertMerx] = useState(false);
    const [msgAlertMerx, setMsgAlertMerx] = useState('');

    useEffect(() => {
        document.getElementById("editorEspalhar").focus()
    }, []);

    const sendMessage = async (outPut, imagePost, idVideo, editor) => {

        listaEnvio.map(async user => {
            const dados = {
                'message': outPut,
                'id_to': user,
                'id_from': sessionGet('idUser')
            };

            const responseMensagem = await api.post('/msgs', dados);

            //notify(`Você recebeu uma mensagem de ${sessionGet('nomeUser')}.`, false, user, "chat");

            setMsgAlertMerx('Mensagem para grupo enviada!');
            setShowAlertMerx(true);
            setTimeout(() => {
                setShowAlertMerx(false);
                setEspalhar(false);
                setDisplay(true);
            }, 2000)
        })

        editor.innerHTML = '';
    };

    return (
        <Wrap>
            <div className="info-head">
                <div className="user">
                    <ImgAndStatus>
                        <MdGroup color="#fff" />
                    </ImgAndStatus>
                    <div className="info">
                        <strong>Enviar mensagem para grupo</strong>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className={(showAlertMerx) ? 'right center-box' : 'right'}>
                    {(showAlertMerx) ? (
                        <AlertaMerx
                            type='success'
                            style='default'
                            text={msgAlertMerx}
                            css={['auto', '25px', '25px', 'auto']}
                            context='in'
                        />
                    ) :
                        (
                            <div className="content-editor">
                                <Editor
                                    style="espalhar"
                                    onSubmit={sendMessage}
                                    onCancelSubmit={setEspalhar}
                                    elementId='editorEspalhar'
                                    midias='false'
                                    emoji='false'
                                    textFont='false'
                                    setDisplay={setDisplay}
                                    setInvDisplay={setInvDisplay}
                                />
                            </div>

                        )}
                </div>
            </div>
        </Wrap >
    );
}

import React from 'react';

import { sessionGet } from '../session';

import {
  RiLayoutMasonryLine,
  RiChat1Line,
  RiQuestionnaireLine,
  RiDiscussLine,
  RiMailLine,
  RiKeyboardLine,
  RiUserHeartLine,
  RiBookletLine,
  RiFolderReceivedLine,
  RiSettings4Line,
  RiFileList3Line,
  RiContactsBook2Line,
  RiTeamLine,
  RiGroupLine,
  RiBriefcaseLine,
  RiBuilding2Line,
  RiMailSettingsLine,
  RiUserSettingsLine,
  RiTerminalBoxLine,
  RiFacebookBoxLine,
  RiMoreLine,
  RiChatSmileLine,
  RiTwitterLine,
  RiNotification2Line,
  RiFileHistoryLine,
  RiComputerLine
} from 'react-icons/ri';

import { MdOutlineDashboardCustomize } from 'react-icons/md';
import { FiBell } from 'react-icons/fi';
import { FaRegClipboard } from 'react-icons/fa';

export default [
  {
    title: 'Feed',
    isDropdown: false,
    icon: (<RiLayoutMasonryLine />),
    isShow: true,
    isBottom: false,
    path: '/feed',
    listPath: ['/feed'],
    active: (sessionGet('planoEmpresa')) === 'merxGratis' ? false : true,
    itens: []
  },
  {
    title: 'Início',
    isDropdown: false,
    icon: (<MdOutlineDashboardCustomize />),
    isShow: true,
    isBottom: false,
    path: '/painel-de-controle',
    listPath: ['/painel-de-controle'],
    active: (sessionGet('planoEmpresa')) === 'merxGratis' ? true : false,
    itens: []
  },
  {
    title: 'Chat equipe',
    isDropdown: false,
    icon: (<RiDiscussLine />),
    isShow: true,
    isBottom: false,
    path: '/conversas',
    listPath: ['/conversas'],
    active: (sessionGet('modulos')) ? sessionGet('modulos').includes('1') : '',
    itens: []
  },
  {
    title: 'E-mail',
    isDropdown: false,
    icon: (<RiMailLine />),
    isShow: true,
    isBottom: false,
    path: '/email',
    listPath: ['/email'],
    active: (sessionGet('modulos')) ? sessionGet('modulos').includes('3') : '',
    itens: []
  },
  {
    title: 'Mini site',
    isDropdown: false,
    icon: (<RiComputerLine />),
    isShow: true,
    isBottom: false,
    path: '/pagina-empresarial',
    listPath: ['/pagina-empresarial'],
    active: sessionGet('admin') === 0 || sessionGet('admin') === 1
  },
  {
    title: 'Chamados do site',
    isDropdown: false,
    icon: (<RiKeyboardLine />),
    isShow: false,
    isBottom: false,
    path: '/formulario-chamado',
    active: true,
    listPath: ['/formulario-chamado'],
  },
  {
    title: 'Central de atendimento',
    isDropdown: true,
    icon: (<RiChatSmileLine />),
    isShow: true,
    isBottom: false,
    path: '/atendimentos-ao-cliente',
    listPath: ['/atendimentos-ao-cliente', '/contato', '/historico-atendimento', '/formulario-chamado'],
    active: true,
    itens: [
      {
        title: 'Chamados do fale conosco',
        icon: (<RiKeyboardLine />),
        path: '/formulario-chamado',
        active: true
      },
      {
        title: 'Chat com cliente',
        icon: (<RiChatSmileLine />),
        path: '/atendimentos-ao-cliente',
        active: true
      },
      {
        title: 'Cadastro de contatos',
        icon: (<RiBookletLine />),
        path: '/contato',
        active: sessionGet('admin') === 0 || sessionGet('admin') === 1
      },
      {
        title: 'Histórico do chat',
        icon: (<RiFileHistoryLine />),
        path: '/historico-atendimento',
        active: true
      }
    ]
  },
  // {
  //   title: 'Redes sociais',
  //   isDropdown: true,
  //   icon: (<RiUserHeartLine />),
  //   isShow: true,
  //   isBottom: false,
  //   path: '/sociais',
  //   listPath: ['/sociais', '/facebook', '/twitter'],
  //   active: (sessionGet('modulos')) ? sessionGet('modulos').includes('2') : '',
  //   itens: [
  //     // {
  //     //   title: 'Facebook',
  //     //   icon: (<RiFacebookBoxLine />),
  //     //   path: '/facebook',
  //     //   active: sessionGet('admin') === 0 || sessionGet('admin') === 1 || sessionGet('admin') === 2
  //     // },
  //     {
  //       title: 'Twitter',
  //       icon: (<RiTwitterLine />),
  //       path: '/twitter',
  //       active: sessionGet('admin') === 0 || sessionGet('admin') === 1 || sessionGet('admin') === 2
  //     }
  //   ]
  // },
  {
    title: 'Configurações',
    isDropdown: true,
    active: true,
    path: '/tools',
    icon: (<RiSettings4Line />),
    isShow: false,
    isBottom: true,
    listPath: ['/tools', '/usuarios', '/resgistro-de-lembretes', '/setor', '/cargo', '/meu-perfil', '/logs', '/logs-empresas'],
    itens: [
      {
        title: 'Usuários',
        icon: (<RiTeamLine />),
        path: '/usuarios',
        active: sessionGet('admin') === 0 || sessionGet('admin') === 1
      },
      {
        title: 'Setores',
        icon: (<RiGroupLine />),
        path: '/setor',
        active: sessionGet('admin') === 0 || sessionGet('admin') === 1
      },
      {
        title: 'Meu perfil',
        icon: (<RiUserSettingsLine />),
        path: '/meu-perfil',
        active: true,
      },
      {
        title: 'Notificações',
        icon: (<RiNotification2Line />),
        path: '/configurar-notificacoes',
        active: true
      },
      {
        title: 'Criar lembretes',
        icon: (<FaRegClipboard />),
        warning: sessionGet('planoEmpresa') === 'merxGratis' ?'Disponível até 29/03/24.' : ' ' ,
        path: '/resgistro-de-lembretes',
        active: sessionGet('admin') === 0 || sessionGet('admin') === 1
      },
      {
        title: 'Registro de atividades',
        icon: (<RiTerminalBoxLine />),
        path: '/logs',
        active: sessionGet('admin') === 0 || sessionGet('admin') === 1
      },
      {
        title: 'Registro de empresas',
        icon: (<RiTerminalBoxLine />),
        path: '/logs-empresas',
        active: (sessionGet('admin') === 0 || sessionGet('admin') === 1) && sessionGet('idEmpresa') === 1
      }
    ]
  }, {
    title: 'Ajuda',
    isDropdown: false,
    icon: (<RiQuestionnaireLine />),
    isShow: false,
    isBottom: true,
    path: '/ajuda',
    active: sessionGet('admin') === 0 || sessionGet('admin') === 1 || sessionGet('admin') === 2,
    listPath: ['/ajuda'],
    itens: []
  },
];
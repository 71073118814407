import React, { useState } from 'react';
import TopBar from '../Topbar';
import Menu from '../Menu';

export default function CHeader({ showMenu, setShowMenu, showSubMenu, setShowSubMenu, isDropdown, setIsDropdown, hideMenu = false, hasChanges, setHasChanges, showModal, setShowModal, setPathRedirect }) {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  return (
    <>
      <TopBar
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        showSubMenu={showSubMenu}
        setShowSubMenu={setShowSubMenu}
        isDropdown={isDropdown}
        setIsDropdown={setIsDropdown}
        showMobileMenu={showMobileMenu}
        setShowMobileMenu={setShowMobileMenu}
        fullTopBar={true}
      />
      {!hideMenu && (<Menu
        showMenu={showMenu}
        showSubMenu={showSubMenu}
        setShowSubMenu={setShowSubMenu}
        isDropdown={isDropdown}
        setIsDropdown={setIsDropdown}
        showMobileMenu={showMobileMenu}
        setShowMobileMenu={setShowMobileMenu}
        hasChanges={hasChanges}
        setHasChanges={setHasChanges}
        showModal={showModal}
        setShowModal={setShowModal}
        setPathAction={setPathRedirect}
      />)}
    </>
  );
}

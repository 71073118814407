import styled from 'styled-components';

import { Link } from 'react-router-dom';

import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  
  .title {
    font-size: 40px;

    span { color: #5c77ff; }
  }

  .wrapfeed {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: top;
    background-color: ${colors.bgsecondary};

    /*@media (min-width: 576px) and (max-width: 1024px) {
      height: calc(100vh - 370px);
    }*/

    /*@media (max-width: 575px) {
      height: 550px;
    }*/
  }

  @media (max-width: 1024px) {
    flex-direction: column;

    .paineis {
      width: 100%;
    }

    .wrapfeed {
      width: 100%;
    }
  }


  @media (max-width: 768px) {
    .funcionalidades {
      width: 100%;
    }
  }
`;

export const PainelBtn = styled(Link)`
  width: 100%;
  background-color: white;
  height: 50px;
  box-shadow: ${effects.boxshadow};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  border: 0;
  color: #333;
  margin-bottom: 20px;
  border-radius: 15px;
  padding: 20px;

  svg {
    font-size: 25px;
    color: ${colors.primary};
    margin-right: 10px;
  }

  strong {
    font-size: 16px;
  }

  @media (max-width: 1024px) {
    strong {
      font-size:15px;
    }
  }
`;

import React, { useState, useEffect } from 'react';
import { Wrap } from './styles';

import { 
  BoldIcon, 
  ItalicIcon, 
  UnderlineIcon,
  JustifyAlignIcon,
  LeftAlignIcon,
  CenterAlignIcon,
  RightAlignIcon
} from '../Icons';

export default function Toolbar({ editorId }) {
  const [bold, setBold] = useState(false);
  const [italic, setItalic] = useState(false);
  const [underline, setUnderline] = useState(false);

  const format = (command) => {
    if(command === 'fontSize'){
      document.execCommand(command,false,7);
    } else {
      document.execCommand(command);
    }
    

    // setando estados dos botões
    switch (command) {
      case 'bold':
        setBold(true);
        break;
      case 'italic':
        setItalic(true);
        break;
      case 'underline':
        setUnderline(true);
        break
    }
  }

  //Função para atribuir títulos html para trechos de texto no campo de edição
  const titles = (width) => {
    //Salvando intervalo selecionado
    let range = window.getSelection().getRangeAt(0);
    
    //Buscando nó atual do intervalo
    let node = range.startContainer.parentElement;

    //Se intervalo já possui o título, tornar parágrafo
    if (node.nodeName === width.toUpperCase()) {
      document.execCommand("heading", null, "p");
    }
    //Se intervalo não possui o título, aplicar
    else {
      document.execCommand("heading", null, width);
    }

  }

  // Analisa a posição ou texto selcionado pelo cursor e seta os estados dos estilos aplicados
  const navUpdate = () => {
    const range = window.getSelection().getRangeAt(0); // guardando a área selecionada
    const parentList = []; // guardará uma lista com todos os nós pais da área selecionada

    // variáveis temporárias para auxílio na mudança de estados
    let tempBold = false;
    let tempItalic = false;
    let tempUnderline = false;

    // buscará os pais do nó selecionado recursivamente e adicionará na lista
    function gettingNodeParents(node) {
      parentList.push(node);
      if (node.parentElement) {
        gettingNodeParents(node.parentElement);

      }

    }

    // preenchendo lista de nós
    gettingNodeParents(range.startContainer.parentElement);
    
    // percorrendo lista de nós, é preciso ter pelo menos 1 nó para estilo aplicado
    parentList.forEach((element) => {
      if (element.tagName === 'B' || element.tagName === 'STRONG') tempBold = true;
      if (element.tagName === 'I') tempItalic = true;
      if (element.tagName === 'U') tempUnderline = true;
    });

    // setando estados
    setBold(tempBold);
    setItalic(tempItalic);
    setUnderline(tempUnderline);
  }

  useEffect(() => {
    // buscando div editávl do editor pelo DOM
    let editor = document.getElementById(editorId);

    // configurando gatilho de update de estilos aplicados
    editor.onclick = navUpdate;
    editor.onkeydown = navUpdate;
    editor.onkeyup = navUpdate;
    editor.onkeypress = navUpdate;
    editor.onchange = navUpdate;
  });

  return (
    <Wrap>
      <button title="Negrito" onClick={() => format('bold')}> <BoldIcon fill={bold ? '#4682B4' : null} /> </button>
      <button title="Itálico" onClick={() => format('italic')}> <ItalicIcon fill={italic ? '#4682B4' : null} /> </button>
      <button title="Sublinhado" onClick={() => format('underline')}> <UnderlineIcon fill={underline ? '#4682B4' : null} /> </button>

      <div className="toolbar-divisor" />

      <button title="Justificar" onClick={() => format('justifyFull')} > <JustifyAlignIcon /> </button>
      <button title="Justificar a esquerda" onClick={() => format('justifyLeft')}> <LeftAlignIcon /> </button>
      <button title="Justificar ao centro" onClick={() => format('justifyCenter')}> <CenterAlignIcon /> </button>
      <button title="Justificar a direita" onClick={() => format('justifyRight')} > <RightAlignIcon /> </button>

      {/* <div className="toolbar-divisor" /> */}

      {/* <button title="Fonte" onClick={() => format('fontSize')} > <FontSizeIcon /> </button> */}
    </Wrap>
  );
}

import React, { useState, useEffect } from 'react';

import ApiGeral from '../../services/api-geral';
import { FaSpinner } from "react-icons/fa";
import { toast } from 'react-toastify';
import history from '../../services/history';
import { sessionGet, sessionAdd } from '../../session';
import { MdInfoOutline } from 'react-icons/md';

import Page from '../../components/Page';
import TitleAndBreadcrumb from '../../components/TitleAndBreadcrumb';
import { Painel } from '../../styles/content';
import { Container, Configuracao } from './styles'

import CInput from '../../components/ComponentInput';
import CButton from '../../components/ComponentButton';


export default function NovaSenha() {
    const [senha, setSenha] = useState('')
    const [senhaConfirmar, setSenhaConfirmar] = useState('');
    const [loading] = useState(false);

    useEffect(() => {
        if (sessionGet('status') != 2) window.location = '/feed';
    })

    const trocaSenha = async (e) => {
        e.preventDefault();
        let emailUsuario = sessionGet('emailUser');
        let idUsuario = sessionGet('idUser');
        let response = (await ApiGeral.put('/users', {
            id: idUsuario,
            email: emailUsuario,
            password: senha,
            confPassword: senhaConfirmar
        })).data;
        if (response.type == 'success') {
            toast.success(response.msg);
            sessionAdd('status', '1');
            history.push("/feed");
        }
        else {
            toast.error(response.msg);
        }
    }

    return (
        <Page content={(
            <Container>
                <TitleAndBreadcrumb title='Alterar senha temporaria' breadcrumbs={[{ title: 'Alterar senha temporaria' }]} />

                <Painel className="painel-configuracao-senhatemp">
                    <Configuracao>
                        <form onSubmit={trocaSenha}>
                            <h4>Você está usando uma senha temporária. Assim que entrar novamente no sistema, será preciso trocá-la.</h4>
                            <div className="wrap-input">
                                <div className="helper">
                                    <MdInfoOutline title="A senha precisa ter mais de 6 dígitos, com ao menos um número e uma letra maiúscula." className="icone-ajuda" size="18px" />
                                </div>
                                <CInput val={senha} type='password' label='Senha' change={e => setSenha(e)} />
                            </div>
                            <div className="wrap-input">
                                <CInput val={senhaConfirmar} type='password' label='Confirmar senha' change={e => setSenhaConfirmar(e)} />
                            </div>
                            <div className="div-salvar">
                                {loading ?
                                    (<button className="botao-salvar"><FaSpinner color="#fff" size="25" className="fa-spin loading-enviar" /></button>) :
                                    (
                                        <CButton title='Salvar' cstyle='primary small' />
                                    )
                                }
                            </div>
                        </form>
                    </Configuracao>
                </Painel>
            </Container>
        )} />
    );
}
import React, { useCallback, useEffect, useState } from 'react';
import api from '../../services/api-geral';
import socket from '../../services/socket';
import { sessionGet, clearSession } from '../../session';
import { logout } from '../../session';

import Swal from 'sweetalert2';

import { Container, Head, Content, LoadingWrap, Empty } from './styles';
import { FaRedo, FaHornbill } from "react-icons/fa";
import { RiDiscussLine, RiRefreshLine  } from 'react-icons/ri';

import NPostFeed from '../NPostFeed';
import getFiles from '../../util/getFiles';
import translateDate from '../../util/translateDate';
import { setIdWithCripto } from '../../util/formatParamsUrl';

import ModalPostFeed from '../ModalPostFeed';

import CInput from '../../components/ComponentInput';

import Default from '../../assets/default.png';

import log from '../../util/log';
import ModalShowImage from '../ModalShowImage';

function NPerfilEPosts({ hasChangesModal, setHasChangesModal, setImageId, setImagePath }) {
  const [posts, setPosts] = useState([]);
  const [images, setImages] = useState([]);
  const [page, setPage] = useState(0);
  const [idPerfil, setIdPerfil] = useState('');
  const [countPost, setCountPost] = useState(0);
  const [showExplorer, setShowExplorer] = useState(false);
  const [showBtnLoadPost, setShowBtnLoadPost] = useState(false);

  const [showBtnScroll, setShowBtnScroll] = useState(0);
  const [elementFeed, setElementFeed] = useState('');

  const [postType, setPostType] = useState();

  const [editedImage, setEditedImage] = useState({});
  const [editedPost, setEditedPost] = useState({});

  const [showModalEdit, setShowModalEdit] = useState(false);

  const [showModalImage, setShowModalImage] = useState(false);
  const [pathImage, setPathImage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [imgPerfil, setImgPerfil] = useState('');
  const [hasPhoto, setHasPhoto] = useState(false);

  const [nomeUsuario, setNomeUsuario] = useState('');
  const [emailUsuario, setEmailUsuario] = useState('');
  const [setorUsuario, setSetorUsuario] = useState('');
  const [birthDate, setBirthDate] = useState('');

  const [link, setLink] = useState('');

  // Pega o ID do usuário e carrega os dados do mesmo. Especifico para o ID de cada usuário.
  const getPerfil = async () => {
    // Verficação do ID passado pela URL
    const idEmpresa = sessionGet('idEmpresa');
    const url = window.location.href;
    const validaçaoExistencia = url.split("?", 2)

    if (validaçaoExistencia.length < 2) {
      linkEspecifico = undefined
    } else {
      var id_base64 = url.split("=", 2)
      var linkEspecifico = validaçaoExistencia[1];
      var buff = new Buffer(id_base64[1], 'base64');
      var id_userPerfil = buff.toString('ascii').split('_')[1];
    }

    // Se um ID em base64 foi passado:
    if (linkEspecifico !== undefined) {
      const verificacao = await api.get(`/users/${id_userPerfil}`);
      const response = verificacao.data;

      if (verificacao.data === null) {
        Swal.fire({
          title: 'Aviso!',
          text: "Perfil não encontrado.",
          icon: 'error',
          confirmButtonColor: '#F03233',
          confirmButtonText: 'Ok',
        }).then((result) => {
          if (result.isConfirmed) window.location.href = '/perfil';
        });
      } else {
        if (response.id_empresa === idEmpresa) {
          perfilInfo(response);
          loadPosts();
        } else {
          Swal.fire({
            title: 'Aviso!',
            text: "O perfil a ser visualizado não pertence a empresa associada a essa conta. Deseja desconectar-se e fazer um novo login em uma conta relacionada a empresa do perfil?",
            icon: 'error',
            confirmButtonColor: '#F03233',
            confirmButtonText: 'Sim',
            showDenyButton: true,
            denyButtonColor: '#51A939',
            denyButtonText: 'Não',
          }).then((result) => {
            if (result.isConfirmed) {
              logout();
              deslogar();
            } else if (result.isDenied) {
              window.location.href = '/perfil';
            }
          });
        }
      }
      // Se a URL passada não contem im ID
    } else {
      const response = (await api.get(`/users/${sessionGet('idUser')}`)).data;

      perfilInfo(response);
      loadPosts();
    }

    const response = (await api.get(`/users/${linkEspecifico}`)).data;
  };

  const perfilInfo = (response) => {
    setIdPerfil(response.id)
    setNomeUsuario(response.name);
    setEmailUsuario(response.email);

    response.setor != null ? setSetorUsuario(response.setor.name) : setSetorUsuario("Não informado");
    response.birthDate != null && response.birthDate !== '0000-00-00' ? setBirthDate(formatDateString(response.birthDate)) : setBirthDate("Não informado");

    if (response.id_file) {
      setHasPhoto(true);
      setImgPerfil(response.files.path);
    } else {
      setHasPhoto(false);
      setImgPerfil(Default);
    }
  }

  const loadPosts = useCallback(() => {
    setIsLoading(true);
    
    if (idPerfil) {
      api.get(`/posts/${page}/${idPerfil}`).then(({ data }) => {
        setImages(data.images);
        setCountPost(data.countPosts);
  
        if (page == 0) {
          setPosts([]); setPosts(data.posts);
          setImages([]); setImages(data.images);
        }
        else {
          setPosts([...posts, ...data.posts]);
          setImages([...images, ...data.images]);
        };
  
      }).catch(error => {
        clearSession();
      });
  
      setIsLoading(false);
    }
  }, [idPerfil, page])

  const verifyPaginationButton = () => {
    const numberPages = (countPost / 4);
    var lastPage = 0;
    var pagina = page;

    if (numberPages % 1 === 0) {
        pagina = pagina + 1;
        lastPage = numberPages;
    } else lastPage = parseInt(numberPages);

    if (pagina === lastPage) setShowBtnLoadPost(false);
    else setShowBtnLoadPost(true);
  }

  const changePage = numberPage => setPage(numberPage);

  const deletePost = async (post) => {
    const response = (await api.delete(`/posts/${post.id}`)).data;

    log('excluiu um post na página de perfil.');

    loadPosts();
  }

  const getElementFeed = (e) => {
    setElementFeed(e);

    if (e.scrollTop > 200) {
      setShowBtnScroll(true);
    } else {
      setShowBtnScroll(false);
    }
  }

  const formatDateString = (date) => {
    var newData = translateDate(date);
    return newData;
  }

  async function deslogar() {
    await api.put('/users-islogged', { isLogged: false, scope: 'close', sessionLogin: false })
  }

  useEffect(() => {
    let url = window.location.href;
    let id_form = url.split("=", 2);
    
    setLink(id_form[1]);
  }, []);

  useEffect(() => {
    changePage(0);

    getPerfil();

    socket.emit("entrarSala", { room: `feed${sessionGet('idEmpresa')}` });

    return () => {
      socket.emit('sairSala', { room: `feed${sessionGet('idEmpresa')}` });
    }
  }, []);
  
  useEffect(() => { verifyPaginationButton() }, [countPost, page]);

  //efeito de Carregando...
  useEffect(() => {
    setLoading(true);
    const debounce = setTimeout(() => {
      setLoading(false);
    }, 450)
    return () => clearTimeout(debounce)
  }, []);

  useEffect(() => { loadPosts(); }, [idPerfil, page]);

  return (
    <>
      {showModalEdit &&
        <ModalPostFeed
          post={editedPost}
          showExplorer={showExplorer}
          setShowExplorer={setShowExplorer}
          image={editedImage}
          closeModal={() => { setShowModalEdit(false) }}
          reload={loadPosts}
          getPosts={() => { setPage(0); loadPosts(); }}
          type={postType}
          hasChangesModal={hasChangesModal}
          setHasChangesModal={setHasChangesModal}
          setImageId={setImageId}
          setImagePath={setImagePath}
        />
      }
      {showModalImage && <ModalShowImage img={pathImage} close={() => setShowModalImage(false)} />}
      <Container onScroll={e => getElementFeed(e.target)}>
        <Content>
          <Head>
            {idPerfil != sessionGet('idUser') &&
              <div className='presence'><a href={`/conversas/${setIdWithCripto('user', idPerfil)}`}><RiDiscussLine size={32} className='icon' /></a></div>
            }
            <img src={hasPhoto ? getFiles(imgPerfil) : imgPerfil} />

            <div className='inputArea'>
              <CInput val={nomeUsuario} type='text' label='Nome' style='radius' disabled={true} />
              <CInput val={emailUsuario} type='text' label='Email' style='radius' disabled={true} />
            </div>
            <div className='inputArea'>
              <CInput val={setorUsuario} type='text' label='Setor' style='radius' disabled={true} />
              <CInput val={birthDate} type='text' label='Aniversário' style='radius' disabled={true} />
            </div>
          </Head>
          <div className='posts'>
            {
              loading === true ? <LoadingWrap><FaRedo className="fa-spin" /><h3>Carregando...</h3></LoadingWrap> :
                posts.length !== 0 ?
                  posts.map((post, index) => (
                    <NPostFeed
                      key={post.id}
                      image={images[index]}
                      post={post}
                      editar={() => { setEditedPost(post); setEditedImage(images[index]); setShowModalEdit(true); setPostType("edit") }}
                      deletar={() => { deletePost(post) }}
                      modalImage={path => { setPathImage(path); setShowModalImage(true); }}
                      setHasChangesModal={setHasChangesModal}
                      setImageId={setImageId}
                      setImagePath={setImagePath}
                      perfil={true}
                      reload={loadPosts}
                    />
                  ))
                  :
                  <Empty><FaHornbill className="fa-spin" /><h3>Nenhuma publicação até o momento</h3></Empty>
            }
          </div>

          {loading === false  && countPost > 0 && (showBtnLoadPost) && (
            <div>
              <button className="btn-carregar-posts" onClick={() => changePage(page + 1)}>
                <RiRefreshLine /> <span>Ver mais publicações</span>
              </button>
            </div>
          )}
        </Content>
      </Container>
    </>
  );
}

export default NPerfilEPosts;
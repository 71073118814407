import React, { useState } from 'react';

import Page from '../../components/Page';
import CentralMidia from '../../components/CentralMidia';
import TitleAndBreadcrumb from '../../components/TitleAndBreadcrumb';

import { Container, Section } from './styles';

export default function CentralMidias() {

  return (
    <Page content={(
      <Container>
        <CentralMidia format="page" />
      </Container>
    )} />
  );
}

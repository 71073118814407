import React, { useState, useEffect } from 'react';
import api from '../../services/api-geral';

import { formatDate } from '../../util/formats';
import { sessionGet } from '../../session'

import { Section, Table } from './styles';

import { MdInsertDriveFile } from 'react-icons/md';

import CButton from '../ComponentButton';

export default function ViewForm({ action, close, item, success }) {
  const [id, setId] = useState('');
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [atendimentos, setAtendimentos] = useState([])
  const [conversa, setConversa] = useState([])


  useEffect(() => {
    setId(item.id)
    setNome(item.name)
    setEmail(item.email)
    setTelefone(item.phone)
    carregaAtendimentos(item.id)
  }, [item]);


  const carregaAtendimentos = async (contato) => {
    const response = (await api.get(`/atendimentos/historico/${contato}`)).data.atendimentos;
    setAtendimentos(response);
  };

  const visualizarConversa = async (atendimento) => {
    const response = (await api.get(`/atendimentos/detalhes/${atendimento}`)).data;
    setConversa(response);
  };


  return (
    <>
      <Section>
        <div className="titulo">
          <div className="titulo-identificacao">
            <div className="identificacao">
              <h3>Histórico de atendimento - {nome} | {email} | {telefone}</h3>
            </div>
          </div>
        </div>
        <div className="detalhes">
          <div className="detalhes-tabela">
            <Table>
              <tr>
                <th>Atendente</th>
                <th>Data</th>
                <th>Status</th>
              </tr>
              {
                atendimentos.map(atendimento => (
                  <>
                    <tr>
                      <td>{atendimento.usuario.name}</td>
                      <td>{formatDate(atendimento.createdAt)}</td>
                      <td>{(atendimento.finalizado === true) ? 'Finalizado' : 'Em andamento'}</td>
                      <td>{(atendimento.finalizado === true) ?
                        <CButton click={() => { visualizarConversa(atendimento.id) }} cstyle="default small" title={(<><MdInsertDriveFile /> Conversa</>)} />
                        : ''}
                      </td>
                    </tr>
                  </>
                ))
              }
            </Table>
          </div>
          <div className="detalhes-conversa">
            <div>Conversa</div>
            {
              conversa.map(c => (
                <>
                  <div className={(sessionGet('idUser') !== c.user_id) ? 'userDestaque' : ''}>
                    {formatDate(c.created_at)} {c.name} - {c.message}
                  </div>
                </>
              ))
            }
          </div>

        </div>

      </Section>
    </>
  );
}

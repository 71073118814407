import styled from 'styled-components';

import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Container = styled.div`
    width: 60%;
    height: 98%;
    box-shadow: 0 1px 15px rgb(0 0 0 / 20%), 0 1px 6px rgb(0 0 0 / 20%);
    border-radius: 10px;
    box-align: center;
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    position: absolute;
    padding-bottom: 10px;
    justify-content: center;

    ::-webkit-scrollbar {
      width: 4px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
      background: ${colors.border};
      border-radius: ${effects.radius};
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

  &.perfil {
    border: 0px;
  }

  @media (max-width: 1280px) {
      width: 70%;
  }
  @media (max-width: 1150px) {
      width: 75%;
  }
  @media (max-width: 835px) {
      width: 85%;
  }
  @media (max-width: 600px) {
      width: 90%;
  }
  @media (max-width: 500px) {
      width: 99%;
  }
`;

export const Content = styled.div`
    width: 100%;
    position: relative;
    height: fit-content;
        
    padding-right: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: column nowrap;

    @media (max-width: 1270px) {
        width: 90%;
    }
    @media (max-width: 1150px) {
        width: 90%;
    }
    @media (max-width: 835px) {
        width: 95%;
    }
    @media (max-width: 600px) {
        width: 100%;
    }

    .posts {
      width: 75%;

      @media (max-width: 1270px) {
        width: 80%;
      }
      @media (max-width: 1150px) {
        width: 85%;
      }
      @media (max-width: 835px) {
        width: 90%;
      }
      @media (max-width: 600px) {
        width: 95%;
      }
    }  
      
    .btn-carregar-posts {
        margin-bottom: 50px;
        background-color: ${colors.bgprimary};
        border: 1px solid ${colors.border};
        border-radius: 5px;
        padding: 10px 20px;
        color: ${colors.secondary};
        font-weight: bold;
        letter-spacing: 1px;
        border-radius: 5px;
        font-size: 16px;
        display: flex;
        align-items: center;
        transition: box-shadow .2s;

        svg {
            font-size: 25px;
            margin-right: 10px;
            color: ${colors.primary};
        }

        &:hover {
            box-shadow: ${effects.boxshadow};
        }
    }
`;

export const LoadingWrap = styled.div`
  width: 100%;
  margin-top: 120px;
  margin-bottom: 200px;
  background-color: none;
  border-radius: 0.75rem;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
	color: ${colors.primary};

  h3 {
    margin-top: 15px;
  }

  svg {
    font-size: 25px;
  }

  .fa-spin {
    -webkit-animation: icon-spin 1s infinite linear;
    animation: icon-spin 1s infinite linear;
  }

  @-webkit-keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }

  @keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
`;

export const Head = styled.form`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 7%;

  @media (max-width: 870px) {
      width: 100%;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    padding: 0%;
    width: 100%;
  }

  img {
      width: 190px;
      height: 190px;
      border-radius: 50%;
      object-fit: cover;
  }

  .inputArea {
    padding-left: 30px;
    width: 35%;

    @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    padding: 0%;
    width: 100%;
    }
  }

  .presence {
    display: block;
    position: absolute;
    top: 180px;
    left: 18%;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 8px solid #8a2387;
    box-sizing: content-box;
    background-color: #8a2387;
    a {
      text-decoration: none;
      color: white;
    }
    span {
      top: 50px;
        
      color: white;
        
      padding: 4px;

      @media (max-width: 515px) {
          left: 45px;
      }
    }
    .icon {
      padding-top: 5px;
    }
    @media (max-width: 1366px) {
      left: 20%;
    }
    @media (max-width: 1280px) {
      left: 19%;
    }
    @media (max-width: 1152px) {
      left: 21%;
    }
    @media (max-width: 800px) {
      left: 55%;
    }
    @media (max-width: 600px) {
      left: 30%;
    }
    @media (max-width: 400px) {
      left: 45%;
    }
  }  
`;

export const Empty = styled.div`
  width: 100%;
  margin-top: 120px;
  margin-bottom: 200px;
  background-color: none;
  border-radius: 0.75rem;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
	color: ${colors.primary};

  h3 {
    margin-top: 15px;
  }

  svg {
    font-size: 25px;
  }

  .fa-spin {
    -webkit-animation: icon-spin 5s infinite linear;
    animation: icon-spin 5s infinite linear;
  }

  @-webkit-keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }

  @keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
`;
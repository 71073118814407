import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { RiCheckFill, RiCheckboxBlankCircleFill } from 'react-icons/ri';
import { AiFillQuestionCircle } from "react-icons/ai";

import api from '../../services/api-geral';

import Question from '../../components/Question'
import Page from '../../components/Page';
import CButton from '../../components/ComponentButton';
import TitleAndBreadcrumb from '../../components/TitleAndBreadcrumb';
import InfoMensagem from '../../components/InfoMensagem';

import { Container } from './styles';
import { Painel } from '../../styles/content';

export default function ConfigurarNotificacoes() {
    const [notifySound, setNotifySound] = useState(false);
    const [notifyEmailFeed, setNotifyEmailFeed] = useState(false);
    const [notifyEmailChat, setNotifyEmailChat] = useState(false);
    const [notifyContactForm, setNotifyContactForm] = useState(0);
    const [notifyLikePost, setNotifyLikePost] = useState(0);
    const [notifyLikeComment, setNotifyLikeComment] = useState(0);
    const [notifyCommentPost, setNotifyCommentPost] = useState(0); 
    const [notifyCommentOnComment, setNotifyCommentOnComment] = useState(0);

    const [showAlertMerx, setShowAlertMerx] = useState(false);
    const [showModal, setShowModal] = useState('');

    useEffect(() => { getConfigs() }, []);

    const getConfigs = async () => {
        const response = (await api.get('/notifications/config')).data;

        setNotifySound(!response.notify_sound ? false : response.notify_sound);
        setNotifyEmailChat(!response.notify_email_chat ? false : response.notify_email_chat);
        setNotifyEmailFeed(!response.notify_email_feed ? false : response.notify_email_feed);
        setNotifyContactForm(response.notify_contact_form == null ? 1 : response.notify_contact_form);
        setNotifyLikePost(response.notify_like_post == null ? 1 : response.notify_like_post);
        setNotifyLikeComment(response.notify_like_comment == null ? 1 : response.notify_like_comment);
        setNotifyCommentPost(response.notify_comment_post == null ? 1 : response.notify_comment_post);
        setNotifyCommentOnComment(response.notify_comment_on_comment == null ? 1 : response.notify_comment_on_comment);
    }

    const setConfigs = async () => {
        const response = (await api.put('/notifications/config', {
            notify_sound: notifySound,
            notify_email_chat: notifyEmailChat,
            notify_email_feed: notifyEmailFeed,
            notify_contact_form: notifyContactForm,
            notify_like_post: notifyLikePost,
            notify_like_comment: notifyLikeComment,
            notify_comment_post: notifyCommentPost,
            notify_comment_on_comment: notifyCommentOnComment,
        })).data;

        if (response.success) {
            setShowAlertMerx(true);
            setTimeout(() => { setShowAlertMerx(false) }, 5000);
        }
    };

    const mostrarModal = (val) => {
        setShowModal(val);
        Notification.requestPermission();
    }

    const closeModal = () => {
        setShowModal('');
    }


    return (
        <Page content={(
            <>
                <TitleAndBreadcrumb id="titulo" title='Notificações' breadcrumbs={[]} format='notify' />
                <Container>
                    <Painel className="painel-config-notificacoes">
                        <h3>Configure as notificações que deseja receber</h3>

                        <div className="config-notificacao">
                            <label>Notificações <strong>sonoras:</strong></label>
                            <div className="wrap-options">
                                <div className="option" onClick={() => setNotifySound(true)}>
                                    <div className="check">
                                        {notifySound && <RiCheckboxBlankCircleFill />}
                                    </div>

                                    <span>Sim</span>
                                </div>
                                <div className="option" onClick={() => setNotifySound(false)}>
                                    <div className="check">
                                        {!notifySound && <RiCheckboxBlankCircleFill />}
                                    </div>

                                    <span>Não</span>
                                </div>
                            </div>
                        </div>
                        <div className="config-notificacao">
                            <label>Notificações do <strong>feed</strong> por e-mail:</label>
                            <div className="wrap-options">
                                <div className="option" onClick={() => setNotifyEmailFeed(true)}>
                                    <div className="check">
                                        {notifyEmailFeed && <RiCheckboxBlankCircleFill />}
                                    </div>

                                    <span>Sim</span>
                                </div>
                                <div className="option" onClick={() => setNotifyEmailFeed(false)}>
                                    <div className="check">
                                        {!notifyEmailFeed && <RiCheckboxBlankCircleFill />}
                                    </div>

                                    <span>Não</span>
                                </div>
                            </div>
                        </div>
                        <div className="config-notificacao">
                            <label>Notificações do <strong>chat</strong> por e-mail:</label>
                            <div className="wrap-options">
                                <div className="option" onClick={() => setNotifyEmailChat(true)}>
                                    <div className="check">
                                        {notifyEmailChat && <RiCheckboxBlankCircleFill />}
                                    </div>

                                    <span>Sim</span>
                                </div>
                                <div className="option" onClick={() => setNotifyEmailChat(false)}>
                                    <div className="check">
                                        {!notifyEmailChat && <RiCheckboxBlankCircleFill />}
                                    </div>

                                    <span>Não</span>
                                </div>
                            </div>
                        </div>
                        <div className="config-notificacao">
                            <label>Notificações do <strong>fale conosco</strong>:</label>
                            <div className="wrap-options">
                                <div className="option" onClick={() => setNotifyContactForm(2)}>
                                    <div className="check">
                                        {notifyContactForm == 2 && <RiCheckboxBlankCircleFill />}
                                    </div>

                                    <span>E-mail e Merx</span>
                                </div>
                                <div className="option" onClick={() => setNotifyContactForm(1)}>
                                    <div className="check">
                                        {notifyContactForm == 1 && <RiCheckboxBlankCircleFill />}
                                    </div>

                                    <span>Só Merx</span>
                                </div>
                                <div className="option" onClick={() => setNotifyContactForm(0)}>
                                    <div className="check">
                                        {notifyContactForm == 0 && <RiCheckboxBlankCircleFill />}
                                    </div>

                                    <span>Nenhuma</span>
                                </div>
                            </div>
                        </div>
                        {/* <h3>Notificações de feed por e-mail: </h3> */}
                        <div className="config-notificacao">
                            <label>Notificações de <strong>comentários nos posts do feed</strong>:</label>
                            <div className="wrap-options">
                                <div className="option" onClick={() => setNotifyCommentPost(2)}>
                                    <div className="check">
                                        {notifyCommentPost == 2 && <RiCheckboxBlankCircleFill />}
                                    </div>

                                    <span>E-mail e Merx</span>
                                </div>
                                <div className="option" onClick={() => setNotifyCommentPost(1)}>
                                    <div className="check">
                                        {notifyCommentPost == 1 && <RiCheckboxBlankCircleFill />}
                                    </div>

                                    <span>Só Merx</span>
                                </div>
                                <div className="option" onClick={() => setNotifyCommentPost(0)}>
                                    <div className="check">
                                        {notifyCommentPost == 0 && <RiCheckboxBlankCircleFill />}
                                    </div>

                                    <span>Nenhuma</span>
                                </div>
                            </div>
                        </div>
                        <div className="config-notificacao">
                            <label>Notificações de <strong>comentários nos comentários</strong>:</label>
                            <div className="wrap-options">
                                <div className="option" onClick={() => setNotifyCommentOnComment(2)}>
                                    <div className="check">
                                        {notifyCommentOnComment == 2 && <RiCheckboxBlankCircleFill />}
                                    </div>

                                    <span>E-mail e Merx</span>
                                </div>
                                <div className="option" onClick={() => setNotifyCommentOnComment(1)}>
                                    <div className="check">
                                        {notifyCommentOnComment == 1 && <RiCheckboxBlankCircleFill />}
                                    </div>

                                    <span>Só Merx</span>
                                </div>
                                <div className="option" onClick={() => setNotifyCommentOnComment(0)}>
                                    <div className="check">
                                        {notifyCommentOnComment == 0 && <RiCheckboxBlankCircleFill />}
                                    </div>

                                    <span>Nenhuma</span>
                                </div>
                            </div>
                        </div>
                        <div className="config-notificacao">
                            <label>Notificações de <strong>curtidas nos posts do feed</strong>:</label>
                            <div className="wrap-options">
                                <div className="option" onClick={() => setNotifyLikePost(2)}>
                                    <div className="check">
                                        {notifyLikePost == 2 && <RiCheckboxBlankCircleFill />}
                                    </div>

                                    <span>E-mail e Merx</span>
                                </div>
                                <div className="option" onClick={() => setNotifyLikePost(1)}>
                                    <div className="check">
                                        {notifyLikePost == 1 && <RiCheckboxBlankCircleFill />}
                                    </div>

                                    <span>Só Merx</span>
                                </div>
                                <div className="option" onClick={() => setNotifyLikePost(0)}>
                                    <div className="check">
                                        {notifyLikePost == 0 && <RiCheckboxBlankCircleFill />}
                                    </div>

                                    <span>Nenhuma</span>
                                </div>
                            </div>
                        </div>
                        <div className="config-notificacao">
                            <label>Notificações de <strong>curtidas nos comentários do feed</strong>:</label>
                            <div className="wrap-options">
                                <div className="option" onClick={() => setNotifyLikeComment(2)}>
                                    <div className="check">
                                        {notifyLikeComment == 2 && <RiCheckboxBlankCircleFill />}
                                    </div>

                                    <span>E-mail e Merx</span>
                                </div>
                                <div className="option" onClick={() => setNotifyLikeComment(1)}>
                                    <div className="check">
                                        {notifyLikeComment == 1 && <RiCheckboxBlankCircleFill />}
                                    </div>

                                    <span>Só Merx</span>
                                </div>
                                <div className="option" onClick={() => setNotifyLikeComment(0)}>
                                    <div className="check">
                                        {notifyLikeComment == 0 && <RiCheckboxBlankCircleFill />}
                                    </div>

                                    <span>Nenhuma</span>
                                </div>
                            </div>
                        </div>
                        <div className="config-notificacao">
                            <label>Notificações <strong>no sistema</strong> (habilitação pelo navegador): </label>
                            <div className="wrap-options">
                                <AiFillQuestionCircle onClick={() => { mostrarModal('view') }} />
                                {(showModal !== '') ? (
                                    <Question
                                        view={'view'}
                                        close={closeModal}
                                        content={'notification'}
                                    />
                                ) : ''}
                            </div>
                        </div>
                        {(showAlertMerx) && (
                            <InfoMensagem tipoAlerta="success" conteudoAlerta={'Configurações definidas com sucesso.'} customWidth={'300px'} />
                        )}

                        <CButton click={() => setConfigs()} title='Salvar' cstyle='primary small' />
                    </Painel>
                </Container>
            </>
        )} />
    );
}

export const config = [
  {
    subTitle: 'Merx',
    cards: [
      {
        id: 1,
        title: 'Como recuperar a senha do Merx?',
        content: `<p>Para recuperar a senha de acesso ao Merx, execute os seguintes passos:</p>

        <p>- Acesse na página de login da aplicação (https://merx.app.br/);<br>
        - Clique na opção “Esqueceu a senha?”, localizada abaixo dos campos de login.<br>
        - Informe o endereço de e-mail cadastrado e clique em “Redefinir”.</p>
        
        <p>Você receberá uma mensagem com o passo a passo para que escolha uma nova senha. <br>
        </p>`
      },
      {
        id: 2,
        title: 'Como alterar a senha de acesso ao Merx?',
        content: `<p>Para alterar a senha de acesso ao Merx, execute os seguintes passos:</p>

        <p>- Clique em seu nome de usuário no canto superior direito da tela;<br>
        - Escolha a opção “Meu Perfil”;<br>
        - Clique em “Alterar senha”;<br>
        - Digite senha atual, a nova senha e repita o processo no campo seguinte;<br>
        - Clique em “Alterar senha”.<br>
        </p>`
      },
      {
        id: 3,
        title: 'Onde localizo as últimas notificações do Merx?',
        content: '<p>As últimas atualizações do Merx estão localizadas ao lado do seu nome de usuário, no ícone de sino.</p>',
      },
      {
        id: 4,
        title: 'Como marco as notificações do Merx como lidas?',
        content: `<p>Para marcar as notificações do Merx como lidas, execute os seguintes passos:</p>

        <p>- Na parte superior da tela, ao lado do nome de usuário, clique no ícone do sino;<br>
        - Vai abrir uma pequena tela com as notificações;<br>
        - Ao lado do título “Notificações”, clique na opção “Marcar todas como lidas”;<br>
        </p>`,
      },
      {
        id: 5,
        title: 'Como vejo todas as notificações do Merx?',
        content: `<p>Para ver todas as notificações do Merx, execute os seguintes passos:</p>

        <p>- Na parte superior da tela, ao lado do nome de usuário, clique no ícone do sino;<br>
        - Vai abrir uma pequena tela com as notificações;<br>
        - Direcione-se para o final desta pequena tela e clique na opção “Ver mais”;<br>
        </p>`,
      },
    ]
  },
  {
    subTitle: 'Usuários',
    cards: [
      {
        id: 6,
        title: 'Como adicionar um usuário no Merx?',
        content: `<p>Para adicionar um usuário no Merx, execute os seguintes passos:</p>

        <p>- No menu lateral, localizado a esquerda da tela, clique em “Configurações”;<br>
        - No submenu de “Configurações”, acesse “Usuários”;<br>
        - Na lateral superior direita da tela, clique no botão “Adicionar”;<br>
        - Informe os dados solicitados;<br>
        - Crie uma senha temporária para o usuário;<br>
        - Clique em “Salvar”.<br>
        </p>`
      },
      {
        id: 7,
        title: 'Como desativar um usuário do Merx?',
        content: `<p>Para desativar um usuário no Merx, execute os seguintes passos:</p>

        <p>- No menu lateral, localizado a esquerda da tela, clique em “Configurações”;<br>
        -No submenu de “Configurações”, acesse “Usuários”;<br>
        - Pesquise o usuário que deseja desativar e selecione este usuário;<br>
        - Na lateral superior direita da tela, clique no botão “Desativar”;<br>
        - Em seguida, clique em “Confirmar” para finalizar a ação.<br>
        </p>`
      },
      {
        id: 8,
        title: 'Como editar os dados de um usuário do Merx?',
        content: `<p>Para editar os dados de um usuário no Merx, execute os seguintes passos:</p>

        <p>- No menu lateral, localizado a esquerda da tela, clique em “Configurações”;<br>
        - No submenu de “Configurações”, acesse “Usuários”;<br>
        - Pesquise o usuário que deseja editar e selecione este usuário;<br>
        - Faça as alterações necessárias;<br>
        - Em seguida, clique em “Salvar” para finalizar a ação.<br>
        </p>`
      },
    ]
  },
  {
    subTitle: 'Setores',
    cards: [
      {
        id: 9,
        title: 'Como adicionar um setor no Merx?',
        content: `<p>Para adicionar um setor no Merx, execute os seguintes passos:</p>

        <p>- No menu lateral, clique em “Configurações”;<br>
        - No submenu de “Configurações”, acesse “Setores”;<br>
        - Na lateral superior direita da tela, clique no botão “Adicionar”;<br>
        - Informe o nome do setor;<br>
        - Informe se este setor fará atendimento ao cliente com o chat externo pelo mini site;<br>
        - Clique em “Salvar”.<br>
        </p>`
      },
      {
        id: 10,
        title: 'Como excluir um setor do Merx?',
        content: `<p>Para desativar um setor no Merx, execute os seguintes passos:</p>

        <p>- No menu lateral, clique em “Configurações”;<br>
        - No submenu de “Configurações”, acesse “Setores”;<br>
        - Pesquise o setor que deseja desativar e selecione este setor;<br>
        - Na lateral superior direita da tela, clique no botão “Excluir”;<br>
        - Em seguida, clique em “Confirmar” para finalizar a ação.<br>
        </p>`
      },
    ]
  },
  {
    subTitle: 'Meu perfil',
    cards: [
      {
        id: 11,
        title: 'Como alterar a minha senha no Merx?',
        content: `<p>Para alterar a sua senha no Merx, execute os seguintes passos:</p>

        <p>- No menu lateral, clique no ícone de “Configurações”;<br>
        - No submenu de “Configurações”, acesse “Meu Perfil”;<br>
        - Clique em “Alterar senha”;<br>
        - Altere a senha (de acordo com as regras requeridas);<br>
        - Clique em “Alterar senha”, para confirmar o processo.<br>
        </p>`
      },
      {
        id: 12,
        title: 'Como alterar meu nome de usuário no Merx?',
        content: `<p>Para alterar o seu nome de usuário no Merx, execute os seguintes passos:</p>

        <p>- No menu lateral, clique no ícone de “Configurações”; <br>
        - No submenu de “Configurações” acesse “Meu Perfil”;<br>
        - Altere o nome;<br>
        - Clique em “Salvar dados”.<br>
        </p>`
      },
    ]
  },
  {
    subTitle: 'Notificações',
    cards: [
      {
        id: 13,
        title: 'Para que servem as notificações do Merx?',
        content: `<p>As notificações do Merx são úteis para que não perca nenhuma atividade dentro da plataforma. Você será avisado, pelo Merx ou por e-mail, caso alguém lhe envie uma mensagem por chat ou publique alguma novidade no feed de notícias, por exemplo.</p>`
      },
      {
        id: 14,
        title: 'Quais os tipos de notificações posso configurar?',
        content: `<p>É possível configurar as seguintes notificações no Merx:</p>

        <p>- Notificações sonoras;<br>
        - Notificações do feed por e-mail;<br>
        - Notificações do chat por e-mail;<br>
        - Notificações do fale conosco;<br>
        - Notificações de comentários nos posts do feed;<br>
        - Notificações de curtidas nos posts do feed;<br>
        - Notificações de curtidas nos comentários do feed.<br>
        </p>`
      },
      {
        id: 15,
        title: 'Como configurar as notificações do Merx?',
        content: `<p>Para configurar as notificações do Merx, execute os seguintes passos:</p>

        <p>- No menu lateral, clique no ícone de “Configurações”; <br>
        - No submenu de “Configurações” acesse “Notificações”;<br>
        - Faça as configurações ;<br>
        - Clique em “Salvar dados”.<br>
        </p>`
      },
    ]
  },
  {
    subTitle: 'Registro de atividades',
    cards: [
      {
        id: 16,
        title: 'Para que serve o Registro de atividades do Merx?',
        content: `<p>O Registro de atividades do Merx mostra todas as ações executadas pelos usuários na aplicação.</p>`
      }
    ]
  }
]

export const feed = [
  {
    subTitle: 'Feed',
    cards: [
      {
        id: 1,
        title: 'Como compartilhar um conteúdo no feed do Merx?',
        content: `<p>Para compartilhar um conteúdo no feed do Merx, execute os seguintes passos:</p>

        <p>- No menu lateral, clique no ícone do Feed;<br>
        - Na página central, clique no campo “O que gostaria de compartilhar?”. Abrirá uma tela onde será possível inserir o conteúdo textual desejado;<br>
        - Em seguida, clique em “Publicar”;<br>
        </p>`
      },
      {
        id: 2,
        title: 'Como adicionar imagens nas publicações do feed do Merx?',
        content: `<p>Para adicionar imagens nas publicações do feed do Merx, execute os seguintes passos:</p>

        <p>- No menu lateral, clique no ícone do Feed;<br>
        - Na página central, clique no campo “Imagem”.<br>
        - A tela para carregamento se abrirá. Escolha o arquivo desejado em seu dispositivo e clique em “Abrir”;<br>
        - Inclua o conteúdo textual, se desejar, e clique em “Publicar”.<br>
        </p>`
      },
      {
        id: 3,
        title: 'Como adicionar vídeos nas publicações do feed do Merx?',
        content: `<p>Para adicionar vídeos nas publicações do feed do Merx, execute os seguintes passos:</p>

        <p>- No menu lateral, clique no ícone do Feed;<br>
        - Na página central, clique no campo “O que gostaria de compartilhar?”. Abrirá uma tela, com uma caixa de texto, onde será possível colar o link do vídeo publicado no Youtube/Vimeo;<br>
        - Se desejar, inclua o conteúdo textual;<br>
        - Em seguida, clique em “Publicar”;<br>
        </p>`
      },
      {
        id: 4,
        title: 'Como inserir um texto após adicionar um vídeo do Youtube/Vimeo sem ele se apagar?',
        content: `<p>Para inserir um texto após adicionar um vídeo, sem que ele se apague, execute os seguintes passos:<br>
        - Se o vídeo não estiver com uma borda azul, clique nele;<br>
        - No canto superior esquerdo da borda do vídeo, vai aparecer um círculo azul com uma seta descendo para a esquerda. Clique nela. <br>
        - O vídeo descerá e possibilitará a inserção de conteúdo em sua publicação.<br>
        </p>`
      },
      {
        id: 5,
        title: 'Como marcar pessoas nas publicações feitas no feed do Merx?',
        content: `<p>Existem duas opções para incluir marcação em suas publicações:</p>

        <p>- No menu lateral, clique no ícone do Feed;<br>
        - Na página central, clique no campo “O que gostaria de compartilhar?”. Abrirá uma tela onde será possível inserir o conteúdo textual desejado;</p>
        
        <p>1ª) Ao digitar o conteúdo textual, digite “@” e abrirá um campo para localizar o usuário que deseja marcar.</p>
        
        <p>ou</p>
        
        <p>2ª) Clique na opção “Incluir marcações”, pesquise o nome do usuário no campo correspondente, selecione e clique em “Concluir”.<br>
        </p>`
      },
      {
        id: 6,
        title: 'Como visualizar o perfil de um usuário do Merx?',
        content: `<p>Para acessar o perfil de um usuário que está no Mer, visualizar as principais informações a respeito do contato e as últimas postagens dele, siga os seguintes passos:</p>
        <p>
        - Escolha a ferramenta “Feed;<br>
        - Quando encontrar o usuário que deseja visualizar as informações, passe o mouse em cima do nome;<br>
        - Clique na opção “Ver perfil”.<br>
        </p>`
      },
    ]
  }
]

export const chat = [
  {
    subTitle: 'Chat',
    cards: [
      {
        id: 1,
        title: 'Como enviar uma mensagem pelo chat interno para um colaborador?',
        content: `<p>Para enviar uma mensagem pelo chat interno do Merx para algum colaborador, execute os seguintes passos:</p>

        <p>- No menu lateral, clique no ícone “Chat equipe”; <br>
        - Digite o nome de usuário no campo de pesquisa, para localizá-lo;<br>
        - Clique no usuário desejado;<br>
        - Digite sua mensagem e clique na seta correspondente ao envio.<br>
        </p>`
      },
      {
        id: 2,
        title: 'Como enviar uma imagem pelo chat interno?',
        content: `<p>Para enviar uma imagem pelo chat do Merx, execute os seguintes passos:</p>

        <p>- No menu lateral, clique no ícone “Chat equipe”; <br>
        - Digite o nome de usuário no campo de pesquisa, para localizá-lo;<br>
        - Clique no usuário desejado;<br>
        - Clique no ícone correspondente a uma imagem;<br>
        - Abrirá o quadro para localizar a imagem;<br>
        - Escolha a imagem e clique em abrir;<br>
        - Se desejar, adicione uma legenda e clique na seta correspondente ao envio.<br>
        </p>`
      },
      {
        id: 3,
        title: 'Como verificar histórico de conversas no chat interno?',
        content: `<p>Para verificar o histórico de conversas pelo chat do Merx, execute os seguintes passos:</p>

        <p>- No menu lateral, clique no ícone “Chat”; <br>
        - Digite o nome de usuário no campo de pesquisa, para localizá-lo;<br>
        - Clique no usuário desejado;<br>
        - No canto superior direito, clique no ícone com três pontos;<br>
        - Escolha a opção “Histórico”;<br>
        </p>`
      },
      {
        id: 4,
        title: 'Como apagar uma mensagem do chat?',
        content: `<p>Para apagar uma mensagem que foi enviada para o chat interno de um colaborador, execute os seguintes passos:</p>

        <p>- No menu lateral, clique no ícone “Chat”; <br>
        - Digite o nome de usuário no campo de pesquisa, para localizá-lo;<br>
        - Clique no usuário desejado;<br>
        - Localize a mensagem que deseja excluir;<br>
        - Clique na “seta para baixo” que está no final da mensagem;<br>
        - Clique em “Apagar mensagem”.<br>
        </p>`
      },
      {
        id: 5,
        title: 'Como alterar o status do chat equipe?',
        content: `<p>Para alterar o status do seu “chat equipe”, clique no seu avatar dentro da ferramenta ou em seu nome de usuário, na lateral esquerda da tela. Abrirá um modal chamado “Selecionar status”</p>

        <p>Clique na seta para baixo e escolha entre as opções de Status.</p>`
      },
      {
        id: 6,
        title: 'Como criar um status personalizado para o meu chat?',
        content: `<p>Para criar um status personalizado do seu chat equipe, clique no seu avatar dentro da ferramenta ou em seu nome de usuário, na lateral esquerda da tela. Abrirá um modal chamado “selecionar status”.</p>

        <p>Clique na seta para baixo na opção “Criar status”: <br>
        - Em “Status personalizado” digite o nome que desejar;<br>
        - Abaixo, escolha entre as opções “Disponível”, “Ausente” e “Não perturbe”, para definir a cor das bolinhas do seu    chat, sendo, verde, laranja e vermelho, respectivamente<br>
        </p>`
      },
      {
        id: 7,
        title: 'Como remover um status do chat equipe?',
        content: `<p>Para remover um status personalizado do seu chat equipe, clique no seu avatar dentro da ferramenta ou em seu nome de usuário, na lateral esquerda da tela. Abrirá um modal chamado “selecionar status”. </p>

        <p>Clique na seta para baixo na opção “Criar status”: <br>
        - Em “Remover status”, clique na seta para baixo;<br>
        - Na opção “Selecionar status”, clique na seta para baixo;<br>
        - Selecione o status que deseja deletar;<br>
        - Clique em “Remover”<br>
        </p>`
      },
    ]
  }
]

export const email = [
  {
    subTitle: 'E-mail',
    cards: [
      {
        id: 1,
        title: 'Como logar o meu e-mail no Merx?',
        content: `< p > Para acessar o seu e- mail empresarial no Merx, basta executar os seguintes passos:</ >

      <p>- No menu lateral, escolha a opção “E-mail”;<br>
        - Ao lado, vai aparecer uma tela de login;<br>
          - Informe seu e-mail e senha de acesso;<br>
            - Clique no botão “Entrar”.</p>

          <p>OBS: Só é possível acessar essa ferramenta caso você tenha e-mails disponibilizados pelo Merx. Portanto, não é possível logar com e-mails gratuitos como Gmail ou Yahoo.<br>
          </p>`
      },
      {
        id: 2,
        title: 'Como deslogar do e-mail do Merx?',
        content: `<p>Para deslogar o seu e-mail empresarial no Merx, basta executar os seguintes passos:</p>

          <p>- No menu lateral, escolha a opção “E-mail”;<br>
            - Caso esteja logado, localize o botão “desligar”, localizado no canto inferior esquerdo do ambiente de e-mail;<br>
              - Clique no botão. <br>
              </p>`
      },
    ]
  }
]

export const miniSite = [
  {
    subTitle: 'Meu perfil',
    cards: [
      {
        id: 1,
        title: 'Como escolher/alterar a imagem de perfil da página empresarial?',
        content: `<p>Para escolher ou alterar uma imagem de perfil para a sua página empresarial, basta executar os seguintes passos:</p>

              <p>- No menu lateral, escolha a opção “Mini site”;<br>
                - Dentro da área de configuração, escolha a aba “Meu perfil”;<br>
                  - Em seguida, clique no botão “escolher imagem”;<br>
                    - Clique em “Escolher outra imagem”;<br>
                      - Selecione a parte da imagem que deseja como foto de perfil;<br>
                        - Clique em “Cortar imagem”. <br>
                        </p>`
      },
      {
        id: 2,
        title: 'Como excluir a imagem de perfil do mini site?',
        content: `<p>Para excluir a imagem de perfil para o seu mini site, basta executar os seguintes passos:</p>

                        <p>- No menu lateral, escolha a opção “Mini site”;<br>
                          - Dentro da área de configuração, escolha a aba “Meu Perfil”;<br>
                            - Em seguida, clique no botão “Remover”, que está próximo a imagem do perfil;<br>
                              - Vai abrir uma caixa de confirmação, clique em “Sim, apagar”.<br>
                              </p>`
      },
      {
        id: 3,
        title: 'Como inserir uma borda na imagem de perfil do mini site?',
        content: `<p>Para inserir uma borda na imagem de perfil para o seu mini site, basta executar os seguintes passos:</p>

                              <p>- No menu lateral, escolha a opção “Mini site”;<br>
                                - Dentro da área de configuração, escolha a aba “Meu Perfil”;<br>
                                  - Ao lado da imagem de perfil, encontre o campo “Borda de Perfil” e habilite a função;<br>
                                    - A alteração será salva automaticamente.<br>
                                    </p>`
      },
      {
        id: 4,
        title: 'Como alterar a cor da borda na imagem de perfil do mini site?',
        content: `<p>Para alterar a cor da borda na imagem de perfil para o seu mini site, basta executar os seguintes passos:</p>

                                    <p>- No menu lateral, escolha a opção “Mini site”;<br>
                                      - Dentro da área de configuração, escolha a aba “Meu Perfil”;<br>
                                        - Ao lado da imagem de perfil, encontre o campo “Cor da borda” e clique nele;<br>
                                          - Abrirá uma tabela de cor para escolha;<br>
                                            - Após escolher a cor, clique em “Salvar”, localizado no canto superior direito da tela. <br>
                                            </p>`
      },
      {
        id: 5,
        title: 'Como incluir/alterar o nome de usuário/empresa do perfil do mini site?',
        content: `<p>Para incluir/alterar o nome de usuário/empresa do seu mini site, basta executar os seguintes passos:</p>

                                            <p>- No menu lateral, escolha a opção “Mini site”;<br>
                                              - Dentro da área de configuração, escolha a aba “Meu Perfil”;<br>
                                                - Logo abaixo da imagem de perfil, encontre o campo “Nome de usuário/Empresa” e adicione o nome desejado;<br>
                                                  - Clique em “Salvar”, localizado no canto superior direito da tela.<br>
                                                  </p>`
      },
      {
        id: 6,
        title: 'Como incluir/alterar o título da página do mini site?',
        content: `<p>Para incluir/alterar o título da página do seu mini site, basta executar os seguintes passos:</p>

                                                  <p>- No menu lateral, escolha a opção “Mini site”;<br>
                                                    - Dentro da área de configuração, escolha a aba “Meu Perfil”;<br>
                                                      - Abaixo da imagem de perfil, encontre o campo “Título da página” e adicione o nome desejado;<br>
                                                        - Clique em “Salvar”, localizado no canto superior direito da tela.</p>

                                                      <p>OBS: O conteúdo desse campo será exibido nos resultados dos sites de busca, quando sua página aparecer.<br>
                                                      </p>`
      },
      {
        id: 7,
        title: 'Como incluir/alterar a descrição da página do mini site?',
        content: `<p>Para incluir/alterar a descrição da página do seu mini site, basta executar os seguintes passos:</p>

                                                      <p>- No menu lateral, escolha a opção “Mini site”;<br>
                                                        - Dentro da área de configuração, escolha a aba “Meu Perfil”;<br>
                                                          - Abaixo da imagem de perfil, encontre o campo “Descrição da página” e adicione o conteúdo desejado;<br>
                                                            - Clique em “Salvar”, localizado no canto superior direito da tela.<br>
                                                            </p>`
      },
    ]
  },
  {
    subTitle: 'Aparência',
    cards: [
      {
        id: 8,
        title: 'Como incluir/alterar uma imagem de fundo no mini site?',
        content: `<p>Para incluir/alterar uma imagem de fundo do seu mini site, basta executar os seguintes passos:</p>

                                                            <p>- No menu lateral, escolha a opção “Mini site”;<br>
                                                              - Dentro da área de configuração, escolha a aba “Aparência”;<br>
                                                                - Encontre a seção “Configurações de fundo” e clique na imagem correspondente ao carregamento (seta para cima);<br>
                                                                  - Clique em “Escolher imagem”, localizado no centro da tela;<br>
                                                                    - Abrirá a tela para acessar seu computador e escolher a imagem;<br>
                                                                      - Selecione o corte da imagem necessário;<br>
                                                                        - Clique em “Cortar imagem”;<br>
                                                                          - A alteração será salva automaticamente.<br>
                                                                          </p>`
      },
      {
        id: 9,
        title: 'Como aplicar um filtro na imagem de fundo da página empresarial?',
        content: `<p>Para aplicar um filtro na imagem de fundo do seu mini site, basta executar os seguintes passos:</p>

                                                                          <p>- No menu lateral, escolha a opção “Mini site”;<br>
                                                                            - Dentro da área de configuração, escolha a aba “Aparência”;<br>
                                                                              - Encontre a seção “Configurações de fundo”;<br>
                                                                                - Abaixo da imagem de fundo, encontre a opção “Filtro da imagem de fundo” e clique nele;<br>
                                                                                  - Abrirá uma tabela de cor para escolha;<br>
                                                                                    - Após escolher a cor, clique em “Salvar”, localizado no canto superior direito da tela. <br>
                                                                                    </p>`
      },
      {
        id: 10,
        title: 'Como alterar a cor de fundo da página empresarial?',
        content: `<p>Caso não queira uma imagem de fundo, é possível escolher uma cor de fundo para o seu mini site. Para isso, basta executar os seguintes passos:<br>
                                                                                      - No menu lateral, escolha a opção “Mini site”;<br>
                                                                                        - Dentro da área de configuração, escolha a aba “Aparência”;<br>
                                                                                          - Encontre a seção “Configurações de cores”;<br>
                                                                                            - Certifique-se que não há uma imagem de fundo inserida, para que a opção esteja habilitada. <br>
                                                                                              - Clique na opção “Fundo da página” e clique nele;<br>
                                                                                                - Abrirá uma tabela de cor para escolha;<br>
                                                                                                  - Após escolher a cor, clique em “Salvar”, localizado no canto superior direito da tela.<br>
                                                                                                  </p>`
      },
      {
        id: 11,
        title: 'Como alterar as cores e personalizar o mini site?',
        content: `<p>Para alterar as cores e personalizar o mini site, basta executar os seguintes passos:</p>

                                                                                                  <p>- No menu lateral, escolha a opção “Mini site”;<br>
                                                                                                    - Dentro da área de configuração, escolha a aba “Aparência”;<br>
                                                                                                      - Encontre a seção “Configurações de cores”;<br>
                                                                                                        - Nesta seção é possível alterar cores de títulos, botões e ícones do mini site;<br>
                                                                                                          - Clique na opção que deseja alterar; <br>
                                                                                                            - Abrirá uma tabela de cor para escolha;<br>
                                                                                                              - Após escolher a cor, clique em “Salvar”, localizado no canto superior direito da tela.<br>
                                                                                                              </p>`
      },
      {
        id: 12,
        title: 'Como alterar as cores e personalizar o formulário do “Fale conosco” do mini site?',
        content: `<p>Para alterar as cores do formulário do “Fale conosco” do mini site, basta executar os seguintes passos:</p>

                                                                                                              <p>- No menu lateral, escolha a opção “Mini site”;<br>
                                                                                                                - Dentro da área de configuração, escolha a aba “Aparência”;<br>
                                                                                                                  - Encontre a seção “Configurações de cores”;<br>
                                                                                                                    - Nesta seção é possível alterar cores de fundo e botões do formulário;<br>
                                                                                                                      - Clique na opção que deseja alterar; <br>
                                                                                                                        - Abrirá uma tabela de cor para escolha;<br>
                                                                                                                          - Após escolher a cor, clique em “Salvar”, localizado no canto superior direito da tela.</p>

                                                                                                                        <p>OBS 1: Não há pré-visualização para o formulário do “Fale Conosco”. Para ver as modificações de edição, será preciso acessar o mini site e abrir o formulário.</p>

                                                                                                                        <p>OBS 2:<br>
                                                                                                                          - A opção “Fontes dos botões” altera as cores das fontes do “Fale conosco” e do botão “Enviar”.<br>
                                                                                                                            - A opção “Fonte dos links” altera as cores das fontes dos demais botões.<br>
                                                                                                                            </p>`
      },
    ]
  },
  {
    subTitle: 'Meus links',
    cards: [
      {
        id: 13,
        title: 'Como inserir links no mini site?',
        content: `<p>Para inserir links no mini site, basta executar os seguintes passos:</p>

                                                                                                                            <p>- No menu lateral, escolha a opção “Mini site”;<br>
                                                                                                                              - Dentro da área de configuração, escolha a aba “Meus links”;<br>
                                                                                                                                - Encontre a seção “Insira os links”;<br>
                                                                                                                                  - Informe, nos campos correspondentes, o “Título” e o “Endereço Virtual (URL)”;<br>
                                                                                                                                    - Clique no botão “Adicionar link”. <br>
                                                                                                                                      - A alteração será salva automaticamente.<br>
                                                                                                                                      </p>`
      },
      {
        id: 14,
        title: 'Como alterar a ordem dos links do mini site?',
        content: `<p>Para alterar a ordem dos links no mini site, basta executar os seguintes passos:</p>

                                                                                                                                      <p>- No menu lateral, escolha a opção “Mini site”;<br>
                                                                                                                                        - Dentro da área de configuração, escolha a aba “Meus links”;<br>
                                                                                                                                          - Os links inseridos estão localizados abaixo do botão “Adicionar link”;<br>
                                                                                                                                            - Localize o link que deseja alterar a ordem e clique na setinha à sua frente no sentido desejado;<br>
                                                                                                                                              - A alteração será salva automaticamente.<br>
                                                                                                                                              </p>`
      },
      {
        id: 15,
        title: 'Como excluir links do mini site?',
        content: `<p>Para excluir um link no mini site, basta executar os seguintes passos:</p>

                                                                                                                                              <p>- No menu lateral, escolha a opção “Mini site”;<br>
                                                                                                                                                - Dentro da área de configuração, escolha a aba “Meus links”;<br>
                                                                                                                                                  - Os links inseridos estão localizados abaixo do botão “Adicionar link”;<br>
                                                                                                                                                    - Localize o link que deseja excluir e clique no ícone em formato de “X”, localizado no final do link;<br>
                                                                                                                                                      - A alteração será salva automaticamente.<br>
                                                                                                                                                      </p>`
      },
      {
        id: 16,
        title: 'Como editar um link do mini site? ',
        content: `<p>Para editar um link no mini site, basta executar os seguintes passos:</p>

                                                                                                                                                      <p>- No menu lateral, escolha a opção “Mini site”;<br>
                                                                                                                                                        - Dentro da área de configuração, escolha a aba “Meus links”;<br>
                                                                                                                                                          - Os links inseridos estão localizados abaixo do botão “Adicionar link”;<br>
                                                                                                                                                            - Localize o link que deseja editar e clique no ícone em formato de lápis, localizado no final do link;<br>
                                                                                                                                                              - Clique em salvar, para efetuar a alteração.<br>
                                                                                                                                                              </p>`
      },
    ]
  },
  {
    subTitle: 'Localização',
    cards: [
      {
        id: 17,
        title: 'Como conseguir o iFrame do Google Maps?',
        content: `<p>Para localizar o iframe correspondente ao endereço de sua empresa, basta executar os seguintes passos:</p>

                                                                                                                                                              <p>- No seu motor de busca (Google, Bing, etc) pesquise "Google Maps";<br>
                                                                                                                                                                - Após abrir o Google Maps, na barra de pesquisa no cato superior esquerdo, pesquise o nome da sua empresa;<br>
                                                                                                                                                                  - Após selecionar o local, clique em "Compartilhar" e no modal que abrirá em seguida, clique em "Incorporar um mapa";<br>
                                                                                                                                                                    - Após isso é só clicar em "COPIAR HTML". É esse texto completo que será necessário para anexar um mapa a seu mini site.;<br>
                                                                                                                                                                    </p>`
      },
      {
        id: 18,
        title: 'Como inserir um mapa no mini site?',
        content: `<p>Para inserir um mapa ao mini site, basta executar os seguintes passos:</p>

                                                                                                                                                                    <p>- No menu lateral, escolha a opção “Mini site”;<br>
                                                                                                                                                                      - Dentro da área de configuração, escolha a aba “Localização”;<br>
                                                                                                                                                                        - Encontre a seção “Google Maps”;<br>
                                                                                                                                                                          - Informe, no campo correspondente, o “iFrame”;<br>
                                                                                                                                                                            - Clique em salvar, para efetuar a alteração. <br>
                                                                                                                                                                            </p>`
      },
    ]
  },
  {
    subTitle: 'Redes sociais',
    cards: [
      {
        id: 19,
        title: 'Como inserir uma rede social no mini site?',
        content: `<p>Para inserir uma rede social no mini site, basta executar os seguintes passos:</p>

                                                                                                                                                                            <p>- No menu lateral, escolha a opção “Mini site”;<br>
                                                                                                                                                                              - Dentro da área de configuração, escolha a aba “Redes Sociais”;<br>
                                                                                                                                                                                - Clique no ícone correspondente a rede social desejada;<br>
                                                                                                                                                                                  - Abrirá uma aba para inserir a URL (endereço) da rede;<br>
                                                                                                                                                                                    - Digite ou cole o endereço no campo;<br>
                                                                                                                                                                                      - Clique no botão “Adicionar rede social”;<br>
                                                                                                                                                                                        - A alteração será salva automaticamente.<br>
                                                                                                                                                                                        </p>`
      },
      {
        id: 20,
        title: 'Como alterar a ordem das redes sociais do mini site?',
        content: `<p>Para alterar a ordem das redes sociais no mini site, basta executar os seguintes passos:</p>

                                                                                                                                                                                        <p>- No menu lateral, escolha a opção “Mini site”;<br>
                                                                                                                                                                                          - Dentro da área de configuração, escolha a aba “Redes Sociais”;<br>
                                                                                                                                                                                            - As redes inseridas estão localizadas abaixo do botão “Adicionar rede social”;<br>
                                                                                                                                                                                              - Localize o link da rede social que deseja alterar a ordem e clique na setinha à sua frente no sentido desejado;<br>
                                                                                                                                                                                                - A alteração será salva automaticamente.<br>
                                                                                                                                                                                                </p>`
      },
      {
        id: 21,
        title: 'Como excluir uma rede social da minha página empresarial?',
        content: `<p>Para excluir uma rede social do mini site, basta executar os seguintes passos:</p>

                                                                                                                                                                                                <p>- No menu lateral, escolha a opção “Mini site”;<br>
                                                                                                                                                                                                  - Dentro da área de configuração, escolha a aba “Redes Sociais”;<br>
                                                                                                                                                                                                    - As redes inseridas estão localizadas abaixo do botão “Adicionar rede social”;<br>
                                                                                                                                                                                                      - Localize o link da rede social que deseja excluir e clique no ícone em formato de “X”, localizado no final do link;<br>
                                                                                                                                                                                                        - A alteração será salva automaticamente.<br>
                                                                                                                                                                                                        </p>`
      },
      {
        id: 22,
        title: 'Como editar o endereço de uma rede social da minha página empresarial?',
        content: `<p>Para editar o endereço de uma rede social no mini site, basta executar os seguintes passos:</p>

                                                                                                                                                                                                        <p>- No menu lateral, escolha a opção “Mini site”;<br>
                                                                                                                                                                                                          - Dentro da área de configuração, escolha a aba “Redes Sociais”;<br>
                                                                                                                                                                                                            - As redes inseridas estão localizadas abaixo do botão “Adicionar rede social”;<br>
                                                                                                                                                                                                              - Localize o link da rede social que deseja editar e clique no ícone em formato de lápis, localizado no final do link;<br>
                                                                                                                                                                                                                - Faça a edição desejada;<br>
                                                                                                                                                                                                                  - Clique no botão “Salvar”.<br>
                                                                                                                                                                                                                  </p>`
      },
      {
        id: 23,
        title: 'Como habilitar o WhatsApp para atender meus clientes pelo mini site?',
        content: `<p>Para habilitar o WhatsApp no mini site, basta executar os seguintes passos:</p>

                                                                                                                                                                                                                  <p>- No menu lateral, escolha a opção “Mini site”;<br>
                                                                                                                                                                                                                    - Dentro da área de configuração, escolha a aba “Redes Sociais”;<br>
                                                                                                                                                                                                                      - As redes inseridas estão localizadas abaixo do botão “Adicionar rede social”;<br>
                                                                                                                                                                                                                        - Localize o link do WHatsApp;<br>
                                                                                                                                                                                                                          - Abrirá uma tela para inserir o número de telefone;<br>
                                                                                                                                                                                                                            - Insira o número e clique no botão “Salvar rede social”.<br>
                                                                                                                                                                                                                            </p>`
      },
    ]
  },
  {
    subTitle: 'Contato',
    cards: [
      {
        id: 24,
        title: 'Como habilitar/desabilitar o chat do mini site?',
        content: `<p>Para habilitar/desabilitar o chat no mini site, basta executar os seguintes passos:</p>

                                                                                                                                                                                                                            <p>- No menu lateral, escolha a opção “Mini site”;<br>
                                                                                                                                                                                                                              - Dentro da área de configuração, escolha a aba “Contato”;<br>
                                                                                                                                                                                                                                - Localize o campo chamado “Chat com cliente”;<br>
                                                                                                                                                                                                                                  - Habilite ou desabilite o campo;<br>
                                                                                                                                                                                                                                    - A alteração será salva automaticamente.<br>
                                                                                                                                                                                                                                    </p>`
      },
      {
        id: 25,
        title: 'Como habilitar/desabilitar os históricos de conversa referente aos meus atendimentos via chat com o cliente?',
        content: `<p>Para habilitar/desabilitar os históricos de conversa referente aos atendimentos, basta executar os seguintes passos:</p>

                                                                                                                                                                                                                                    <p>- No menu lateral, escolha a opção “Mini site”;<br>
                                                                                                                                                                                                                                      - Dentro da área de configuração, escolha a aba “Contato”;<br>
                                                                                                                                                                                                                                        - Localize o campo chamado “Histórico de conversa”;<br>
                                                                                                                                                                                                                                          - Habilite ou desabilite o campo;<br>
                                                                                                                                                                                                                                            - A alteração será salva automaticamente.</p>

                                                                                                                                                                                                                                          <p>Observação: Ao habilitar o histórico de conversa, o cliente que você atender pelo “Chat com cliente” poderá receber uma cópia do atendimento.<br>
                                                                                                                                                                                                                                          </p>`
      },
      {
        id: 26,
        title: 'Como incluir/habilitar/desabilitar o telefone de contato no mini site?',
        content: `<p>Para alterar as configurações do telefone de contato no mini site, basta executar os seguintes passos:</p>

                                                                                                                                                                                                                                          <p>- No menu lateral, escolha a opção “Mini site”;<br>
                                                                                                                                                                                                                                            - Dentro da área de configuração, escolha a aba “Contato”;<br>
                                                                                                                                                                                                                                              - Localize o campo chamado “Telefone de contato”;<br>
                                                                                                                                                                                                                                                - Habilite ou desabilite o campo;<br>
                                                                                                                                                                                                                                                  - Insira o telefone desejado;<br>
                                                                                                                                                                                                                                                    - A alteração será salva automaticamente.<br>
                                                                                                                                                                                                                                                    </p>`
      },
      {
        id: 27,
        title: 'Como incluir/habilitar/desabilitar o endereço da minha empresa no mini site?',
        content: `<p>Para alterar as configurações do endereço da empresa no mini site, basta executar os seguintes passos:</p>

                                                                                                                                                                                                                                                    <p>- No menu lateral, escolha a opção “Mini site”;<br>
                                                                                                                                                                                                                                                      - Dentro da área de configuração, escolha a aba “Contato”;<br>
                                                                                                                                                                                                                                                        - Localize o campo chamado “Endereço”;<br>
                                                                                                                                                                                                                                                          - Habilite ou desabilite o campo;<br>
                                                                                                                                                                                                                                                            - Insira o endereço desejado;<br>
                                                                                                                                                                                                                                                              - A alteração será salva automaticamente.<br>
                                                                                                                                                                                                                                                              </p>`
      },
      {
        id: 28,
        title: 'Como habilitar/desabilitar o formulário de contato (Fale conosco) no mini site?',
        content: `<p>Para habilitar/desabilitar o fale conosco no mini site, basta executar os seguintes passos:</p>

                                                                                                                                                                                                                                                              <p>- No menu lateral, escolha a opção “Mini site”;<br>
                                                                                                                                                                                                                                                                - Dentro da área de configuração, escolha a aba “Contato”;<br>
                                                                                                                                                                                                                                                                  - Localize o campo chamado “Fale conosco”;<br>
                                                                                                                                                                                                                                                                    - Habilite ou desabilite o campo;<br>
                                                                                                                                                                                                                                                                      - Insira os assuntos desejados;<br>
                                                                                                                                                                                                                                                                        - A alteração será salva automaticamente.<br>
                                                                                                                                                                                                                                                                        </p>`
      },
      {
        id: 29,
        title: 'Como incluir um assunto para recebimento de mensagens via fale conosco?',
        content: `<p>Para incluir um assunto para recebimento de mensagens via fale conosco, basta executar os seguintes passos:</p>

                                                                                                                                                                                                                                                                        <p>- No menu lateral, escolha a opção “Mini site”;<br>
                                                                                                                                                                                                                                                                          - Dentro da área de configuração, escolha a aba “Contato”;<br>
                                                                                                                                                                                                                                                                            - Localize o campo chamado “Fale conosco”;<br>
                                                                                                                                                                                                                                                                              - Localize o símbolo de “soma” na cor verde;<br>
                                                                                                                                                                                                                                                                                - Ao clicar no botão, abrirá o campo “Cadastrar assunto”;<br>
                                                                                                                                                                                                                                                                                  - Insira o assuntos desejado;<br>
                                                                                                                                                                                                                                                                                    - Clique em “Salvar”.<br>
                                                                                                                                                                                                                                                                                    </p>`
      },
      {
        id: 30,
        title: 'Como alterar um assunto para recebimento de mensagens via fale conosco?',
        content: `<p>Para alterar um assunto para recebimento de mensagens via fale conosco, basta executar os seguintes passos:</p>

                                                                                                                                                                                                                                                                                    <p>- No menu lateral, escolha a opção “Mini site”;<br>
                                                                                                                                                                                                                                                                                      - Dentro da área de configuração, escolha a aba “Contato”;<br>
                                                                                                                                                                                                                                                                                        - Localize o campo chamado “Fale conosco”;<br>
                                                                                                                                                                                                                                                                                          - Localize o assunto que deseja alterar;<br>
                                                                                                                                                                                                                                                                                            - Clique no ícone de lápis em frente ao assunto;<br>
                                                                                                                                                                                                                                                                                              - Ao clicar no lápis, abrirá o campo “Editar assunto”;<br>
                                                                                                                                                                                                                                                                                                - Altere o assunto;<br>
                                                                                                                                                                                                                                                                                                  - Clique em “Salvar”.<br>
                                                                                                                                                                                                                                                                                                  </p>`
      },
      {
        id: 31,
        title: 'Como excluir um assunto para recebimento de mensagens via fale conosco?',
        content: `<p>Para excluir um assunto para recebimento de mensagens via fale conosco, basta executar os seguintes passos:</p>

                                                                                                                                                                                                                                                                                                  <p>- No menu lateral, escolha a opção “Mini site”;<br>
                                                                                                                                                                                                                                                                                                    - Dentro da área de configuração, escolha a aba “Contato”;<br>
                                                                                                                                                                                                                                                                                                      - Localize o campo chamado “Fale conosco”;<br>
                                                                                                                                                                                                                                                                                                        - Localize o assunto que deseja excluir;<br>
                                                                                                                                                                                                                                                                                                          - Clique no ícone de lixeira em frente ao assunto;<br>
                                                                                                                                                                                                                                                                                                            - Ao clicar na lixeira, abrirá uma tela de confirmação;<br>
                                                                                                                                                                                                                                                                                                              - Clique em “Confirmar”.<br>
                                                                                                                                                                                                                                                                                                              </p>`
      },
    ]
  },
  {
    subTitle: 'Para ser encontrado no Google',
    cards: [
      {
        id: 32,
        title: 'Para que serve o título (title) do mini site?',
        content: `<p>O título (title) da sua página pode ser o nome da sua empresa ou um termo/palavra-chave que descreva o seu negócio (Ex: Maquiagem para noivas). O conteúdo inserido no título aparecerá na aba do seu navegador e como assunto nos resultados de busca do Google.</p>`
      },
      {
        id: 33,
        title: 'Como inserir o título do mini site?',
        content: `<p>Para incluir/alterar o título da página do seu mini site, basta executar os seguintes passos:</p>

                                                                                                                                                                                                                                                                                                              <p>- No menu lateral, escolha a opção “Mini site”;<br>
                                                                                                                                                                                                                                                                                                                - Dentro da área de configuração, escolha a aba “Meu Perfil”;<br>
                                                                                                                                                                                                                                                                                                                  - Abaixo da imagem de perfil, encontre o campo “Título da página” e adicione o nome desejado;<br>
                                                                                                                                                                                                                                                                                                                    - Clique em “Salvar”, localizado no canto superior esquerdo da tela.</p>

                                                                                                                                                                                                                                                                                                                  <p>OBS: O conteúdo desse campo será exibido nos resultados dos sites de busca, quando sua página aparecer.<br>
                                                                                                                                                                                                                                                                                                                  </p>`
      },
      {
        id: 34,
        title: 'Para que serve a descrição (meta description) do mini site?',
        content: `<p>A descrição (meta-description) deve conter informações básicas sobre seu negócio. Esta frase aparece nos resultados de buscas do Google quando você é pesquisado. Seja criativo e utilize, no máximo, 150 caracteres.</p>`
      },
      {
        id: 35,
        title: 'Como inserir a descrição (meta description) no mini site?',
        content: `<p>Para inserir a descrição (meta description) no mini site, basta executar os seguintes passos:</p>

                                                                                                                                                                                                                                                                                                                  <p>- No menu lateral, escolha a opção “Mini site”;<br>
                                                                                                                                                                                                                                                                                                                    - Dentro da área de configuração, escolha a aba “Para ser encontrado no Google”;<br>
                                                                                                                                                                                                                                                                                                                      - Localize o campo chamado “Descrição”;<br>
                                                                                                                                                                                                                                                                                                                        - Insira um conteúdo relevante;<br>
                                                                                                                                                                                                                                                                                                                          - Clique em “Salvar” no canto superior esquerdo da tela.<br>
                                                                                                                                                                                                                                                                                                                          </p>`
      },
      {
        id: 36,
        title: 'Para que servem as palavras-chave (keywords) da página empresarial?',
        content: `<p>As palavras-chave servem para indicar do que se trata o seu mini site. A partir dessas palavras seu negócio pode ser encontrado nos buscadores. Insira palavras que descrevam sua empresa. Exemplos: curso de maquiagem em BH, maquiagem para noivas, curso de auto maquiagem etc.</p>`
      },
      {
        id: 37,
        title: 'Como inserir as palavras-chave (keywords) no mini site?',
        content: `<p>Para inserir as palavras-chave (keywords) no mini site, basta executar os seguintes passos:</p>

                                                                                                                                                                                                                                                                                                                          <p>- No menu lateral, escolha a opção “Mini site”;<br>
                                                                                                                                                                                                                                                                                                                            - Dentro da área de configuração, escolha a aba “Para ser encontrado no Google”;<br>
                                                                                                                                                                                                                                                                                                                              - Localize o campo chamado “Palavras-chave”;<br>
                                                                                                                                                                                                                                                                                                                                - Insira um conjunto de palavras-chave relevantes e separados por vírgula;<br>
                                                                                                                                                                                                                                                                                                                                  - Clique em “Salvar” no canto superior esquerdo da tela.<br>
                                                                                                                                                                                                                                                                                                                                  </p>`
      },
    ]
  },
]

export const atendimento = [
  {
    subTitle: 'Chamados do fale conosco',
    cards: [
      {
        id: 1,
        title: 'Como fazer uma pesquisa dos atendimentos (Chamados do fale conosco) que chegaram pelo mini site do Merx?',
        content: `<p>Para fazer uma pesquisa de atendimento, basta executar os seguintes passos:</p>

                                                                                                                                                                                                                                                                                                                                  <p>- No menu lateral, escolha a opção “Central de atendimento”;<br>
                                                                                                                                                                                                                                                                                                                                    - Dentro do submenu, escolha a opção “Chamados do fale conosco”;<br>
                                                                                                                                                                                                                                                                                                                                      - Localize o campo de pesquisa no topo da tela;<br>
                                                                                                                                                                                                                                                                                                                                        - Insira o nome do cliente que deseja pesquisar;<br>
                                                                                                                                                                                                                                                                                                                                        </p>`
      },
      {
        id: 2,
        title: 'Como fazer para ordenar os atendimentos (Chamados do fale conosco) que chegaram pelo mini site do Merx?',
        content: `<p>Para fazer uma pesquisa de atendimento, basta executar os seguintes passos:</p>

                                                                                                                                                                                                                                                                                                                                        <p>- No menu lateral, escolha a opção “Central de atendimento”;<br>
                                                                                                                                                                                                                                                                                                                                          - Dentro do submenu, escolha a opção “Chamados do fale conosco”;<br>
                                                                                                                                                                                                                                                                                                                                            - Localize o campo de ordenar no canto superior direito da tela;<br>
                                                                                                                                                                                                                                                                                                                                              - Escolha a forma de ordenação.<br>
                                                                                                                                                                                                                                                                                                                                              </p>`
      },
      {
        id: 3,
        title: 'Como fazer para visualizar os detalhes dos atendimentos (Chamados do fale conosco) que chegaram pelo mini site do Merx?',
        content: `<p>Para fazer uma pesquisa de atendimento, basta executar os seguintes passos:</p>

                                                                                                                                                                                                                                                                                                                                              <p>- No menu lateral, escolha a opção “Central de atendimento”;<br>
                                                                                                                                                                                                                                                                                                                                                - Dentro do submenu, escolha a opção “Chamados do fale conosco”;<br>
                                                                                                                                                                                                                                                                                                                                                  - Localize o chamado que deseje visualizar;<br>
                                                                                                                                                                                                                                                                                                                                                    - Clique no botão “Visualizar” em frente aos dados do chamado.<br>
                                                                                                                                                                                                                                                                                                                                                    </p>`
      },
      {
        id: 4,
        title: 'Como fazer para responder os atendimentos (Chamados do fale conosco) que chegaram pelo mini site do Merx?',
        content: `<p>Para responder uma mensagem do fale conosco, basta executar os seguintes passos:</p>

                                                                                                                                                                                                                                                                                                                                                    <p>- No menu lateral, escolha a opção “Central de atendimento”;<br>
                                                                                                                                                                                                                                                                                                                                                      - Dentro do submenu, escolha a opção “Chamados do fale conosco”;<br>
                                                                                                                                                                                                                                                                                                                                                        - A mensagem a ser respondida estará no topo, com status “Novo”;<br>
                                                                                                                                                                                                                                                                                                                                                          - Clique no botão “Visualizar” em frente aos dados do chamado;<br>
                                                                                                                                                                                                                                                                                                                                                            - Digite a resposta desejada e clique em “Responder”.</p>

                                                                                                                                                                                                                                                                                                                                                          <p>A outra forma de acessar um novo chamado é clicando no sininho de notificação localizado no topo da página e localizar o novo chamado.<br>
                                                                                                                                                                                                                                                                                                                                                          </p>`
      },
    ]
  },
  {
    subTitle: 'Chat com cliente',
    cards: [
      {
        id: 5,
        title: 'Como fazer para atender um chat com cliente que veio pelo mini site do Merx?',
        content: `<p>Para atender um chat com cliente que veio do mini site, basta clicar em “Aceitar atendimento”, quando aparecer uma caixa no canto inferior direito da sua tela.</p>`
      },
      {
        id: 6,
        title: 'Como fazer para pesquisar os atendimentos online (Chat com cliente) que chegaram pelo mini site do Merx?',
        content: `<p>Para pesquisar um atendimento feito pelo chat com cliente, basta executar os seguintes passos:</p>

                                                                                                                                                                                                                                                                                                                                                          <p>- No menu lateral, escolha a opção “Central de atendimento”;<br>
                                                                                                                                                                                                                                                                                                                                                            - Dentro do submenu, escolha a opção “Chat com cliente”;<br>
                                                                                                                                                                                                                                                                                                                                                              - No canto superior esquerdo da tela, encontre o campo de pesquisa;<br>
                                                                                                                                                                                                                                                                                                                                                                - Digite o nome do cliente para pesquisar.<br>
                                                                                                                                                                                                                                                                                                                                                                </p>`
      },
      {
        id: 7,
        title: 'Como fazer para transferir um atendimento online (Chat com cliente)?',
        content: `<p>Para transferir um atendimento que está fazendo pelo chat com cliente, basta executar os seguintes passos:</p>

                                                                                                                                                                                                                                                                                                                                                                <p>- Na parte superior direita da tela localize o campo “Selecione o setor”;<br>
                                                                                                                                                                                                                                                                                                                                                                  - Encontre o setor desejado;<br>
                                                                                                                                                                                                                                                                                                                                                                    - Clique em transferir.<br>
                                                                                                                                                                                                                                                                                                                                                                    </p>`
      },
      {
        id: 8,
        title: 'Como fazer para finalizar um atendimento online (Chat com cliente)?',
        content: `<p>Para finalizar um atendimento que está fazendo pelo chat com cliente, basta clicar no botão “Finalizar” localizado no canto superior direito da tela.</p>`
      },
    ]
  },
  {
    subTitle: 'Cadastro de contatos',
    cards: [
      {
        id: 9,
        title: 'Como fazer para adicionar um contato de cliente no Merx?',
        content: `<p>Para adicionar um contato de cliente no Merx, basta executar os seguintes passos:</p>

                                                                                                                                                                                                                                                                                                                                                                    <p>- No menu lateral, escolha a opção “Central de atendimento”;<br>
                                                                                                                                                                                                                                                                                                                                                                      - Dentro do submenu, escolha a opção “Cadastro de contatos”;<br>
                                                                                                                                                                                                                                                                                                                                                                        - No canto superior direito da tela, encontre o campo “Adicionar”;<br>
                                                                                                                                                                                                                                                                                                                                                                          - Clique no botão e preencha todos os campos;<br>
                                                                                                                                                                                                                                                                                                                                                                            - Clique em “Salvar”.<br>
                                                                                                                                                                                                                                                                                                                                                                            </p>`
      },
      {
        id: 10,
        title: 'Como fazer para excluir um contato de cliente cadastrado no Merx?',
        content: `<p>Para excluir um contato de cliente cadastrado no Merx, basta executar os seguintes passos:</p>

                                                                                                                                                                                                                                                                                                                                                                            <p>- No menu lateral, escolha a opção “Central de atendimento”;<br>
                                                                                                                                                                                                                                                                                                                                                                              - Dentro do submenu, escolha a opção “Cadastro de contatos”;<br>
                                                                                                                                                                                                                                                                                                                                                                                - No canto superior esquerdo da tela, encontre o campo de pesquisa;<br>
                                                                                                                                                                                                                                                                                                                                                                                  - Digite o nome do cliente para encontrá-lo;<br>
                                                                                                                                                                                                                                                                                                                                                                                    - Clique na caixa de seleção para marcar o cliente que deseja excluir;<br>
                                                                                                                                                                                                                                                                                                                                                                                      - Clique no botão “Excluir” localizado no canto superior direito da tela.<br>
                                                                                                                                                                                                                                                                                                                                                                                      </p>`
      },
      {
        id: 11,
        title: 'Como fazer para pesquisar um contato de cliente cadastrado no Merx?',
        content: `<p>Para pesquisar um contato de cliente cadastrado no Merx, basta executar os seguintes passos:</p>

                                                                                                                                                                                                                                                                                                                                                                                      <p>- No menu lateral, escolha a opção “Central de atendimento”;<br>
                                                                                                                                                                                                                                                                                                                                                                                        - Dentro do submenu, escolha a opção “Cadastro de contatos”;<br>
                                                                                                                                                                                                                                                                                                                                                                                          - No canto superior esquerdo da tela, encontre o campo de pesquisa;<br>
                                                                                                                                                                                                                                                                                                                                                                                            - Digite o nome do cliente para pesquisar. <br>
                                                                                                                                                                                                                                                                                                                                                                                            </p>`
      },
      {
        id: 12,
        title: 'Como fazer para ordenar os contatos de clientes cadastrados no Merx?',
        content: `<p>Para ordenar os contatos de clientes cadastrados no Merx, basta executar os seguintes passos:</p>

                                                                                                                                                                                                                                                                                                                                                                                            <p>- No menu lateral, escolha a opção “Central de atendimento”;<br>
                                                                                                                                                                                                                                                                                                                                                                                              - Dentro do submenu, escolha a opção “Cadastro de contatos”;<br>
                                                                                                                                                                                                                                                                                                                                                                                                - No canto superior direito da tela, encontre o campo “Ordenar por”;<br>
                                                                                                                                                                                                                                                                                                                                                                                                  - Selecione a forma de ordenação desejada.<br>
                                                                                                                                                                                                                                                                                                                                                                                                  </p>`
      },
      {
        id: 13,
        title: 'Como fazer para editar um contato de cliente cadastrado no Merx? ',
        content: `<p>Para editar um contato de cliente cadastrado no Merx, basta executar os seguintes passos:</p>

                                                                                                                                                                                                                                                                                                                                                                                                  <p>- No menu lateral, escolha a opção “Central de atendimento”;<br>
                                                                                                                                                                                                                                                                                                                                                                                                    - Dentro do submenu, escolha a opção “Cadastro de contatos”;<br>
                                                                                                                                                                                                                                                                                                                                                                                                      - No canto superior esquerdo da tela, encontre o campo de pesquisa;<br>
                                                                                                                                                                                                                                                                                                                                                                                                        - Digite o nome do cliente para encontrá-lo;<br>
                                                                                                                                                                                                                                                                                                                                                                                                          - Clique no botão “Editar” localizado à frente dos dados do cliente escolhido;<br>
                                                                                                                                                                                                                                                                                                                                                                                                            - Abrirá uma janela para editar o contato, após realizar as alterações desejadas clique em “Salvar”.<br>
                                                                                                                                                                                                                                                                                                                                                                                                            </p>`
      },
    ]
  },
  {
    subTitle: 'Histórico do chat',
    cards: [
      {
        id: 14,
        title: 'Como localizar um atendimento no histórico de chat?',
        content: `<p>Para localizar um atendimento no histórico do chat, basta executar os seguintes passos:</p>

                                                                                                                                                                                                                                                                                                                                                                                                            <p>- No menu lateral, escolha a opção “Central de atendimento”;<br>
                                                                                                                                                                                                                                                                                                                                                                                                              - Dentro do submenu, escolha a opção “Histórico do chat”;<br>
                                                                                                                                                                                                                                                                                                                                                                                                                - No canto superior esquerdo da tela, encontre o campo de pesquisa chamado “Cliente”;<br>
                                                                                                                                                                                                                                                                                                                                                                                                                  - Digite o nome do cliente para encontrá-lo.<br>
                                                                                                                                                                                                                                                                                                                                                                                                                  </p>`
      },
      {
        id: 15,
        title: 'Como visualizar o atendimento no histórico do chat?',
        content: `<p>Para visualizar um atendimento no histórico do chat, basta executar os seguintes passos:</p>

                                                                                                                                                                                                                                                                                                                                                                                                                  <p>- No menu lateral, escolha a opção “Central de atendimento”;<br>
                                                                                                                                                                                                                                                                                                                                                                                                                    - Dentro do submenu, escolha a opção “Histórico do chat”;<br>
                                                                                                                                                                                                                                                                                                                                                                                                                      - No canto superior esquerdo da tela, encontre o campo de pesquisa chamado “Cliente”;<br>
                                                                                                                                                                                                                                                                                                                                                                                                                        - Digite o nome do cliente para encontrá-lo;<br>
                                                                                                                                                                                                                                                                                                                                                                                                                          - Clique no botão “Visualizar” localizado à frente dos dados do cliente.<br>
                                                                                                                                                                                                                                                                                                                                                                                                                          </p>`
      },
    ]
  },
]

export const socials = [
  {
    subTitle: 'Redes Sociais',
    cards: [
      {
        id: 1,
        title: 'Como logar minha conta do Twitter no Merx?',
        content: `<p>Para acessar o seu perfil do Twitter no Merx, basta seguir os seguintes passos:</p>

                                                                                                                                                                                                                                                                                                                                                                                                                          <p>- No menu lateral, escolha a opção “Redes sociais”;<br>
                                                                                                                                                                                                                                                                                                                                                                                                                            - Escolha a opção “Twitter”. Ao lado, vai aparecer uma tela de login;<br>
                                                                                                                                                                                                                                                                                                                                                                                                                              - Clique no botão “Entrar na minha conta”;<br>
                                                                                                                                                                                                                                                                                                                                                                                                                                - Informe seu e-mail e senha de acesso. <br>
                                                                                                                                                                                                                                                                                                                                                                                                                                  - Clique no botão “Entrar”.<br>
                                                                                                                                                                                                                                                                                                                                                                                                                                  </p>`
      }
    ]
  }
]
import React, { useState, useEffect } from 'react';
import Geral from '../../services/api-geral';

import CTable from '../../components/ComponentTable';
import FormSetor from '../../components/Setor/Form';

import Page from '../../components/Page';
import { Painel } from '../../styles/content';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";

import { Container } from './styles';
import { Paginas, PaginaButton } from '../../components/BotoesPaginacao/styles';

export default function Setor() {
  const [dadosSetor, setDadosSetor] = useState([]);
  const [allDados, setAllDados] = useState([]);
  const [setSetor] = useState({});
  const [offsetSetor, setOffsetSetor] = useState(0);
  const [totalSetor, setTotalSetor] = useState(0);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [pagesTotal, setPagesTotal] = useState(1);
  const [valueSearch, setValueSearch] = useState('');

  const [hasChangesModal, setHasChangesModal] = useState(false);

  const getSetor = async () => {
    const response = await Geral.get(`/setor?offset=${offsetSetor}`);
    setDadosSetor(response.data.rows);
    setTotalSetor(response.data.count);
    const pagesCount = calculatePagesCount(10, response.data.count);
    setPagesTotal(pagesCount);
  };

  useEffect(() => {
    getAllSetores();
  }, [])

  const getAllSetores = async () => {
    const response = await Geral.get(`/setor?offset=null`);
    setAllDados(response.data);
  }

  const calculatePagesCount = (pageSize, totalCount) => {
    // we suppose that if we have 0 items we want 1 empty page
    return totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize);
  };

  useEffect(() => {
    getSetor();
  }, [offsetSetor]);

  const setaOffset = (acao) => {
    if (acao === 'proximo') {
      if (Math.floor(totalSetor / 10) !== (offsetSetor / 10)) {
        setOffsetSetor(offsetSetor + 10);
        setPaginaAtual(paginaAtual + 1);
      }
    }

    if (acao === 'anterior') {
      if (offsetSetor > 0) {
        setOffsetSetor(offsetSetor - 10);
        setPaginaAtual(paginaAtual - 1);
      }
    }
  }

  const refresh = () => {
    getSetor();
    getAllSetores();
  }

  return (
    <Page hasChanges={hasChangesModal} setHasChanges={setHasChangesModal} content={(
      <Container>
        <Painel className="painel-setor">
          <CTable
            titles={['ID', 'Nome']}
            values={dadosSetor}
            allValues={allDados}
            indexes={['id', 'name']}
            indexesSearch={['name']}
            setValueSearch={setValueSearch}
            load={refresh}
            FormCustom={FormSetor}
            setItem={setSetor}
            actionDelete='/setor'
            titlePage='Lista de setores'
            breadcrumbPage={[{ title: 'Listas' }]}
            emptyPage='Nenhum registro foi encontrado!'
            hasChangesModal={hasChangesModal}
            setHasChangesModal={setHasChangesModal}
          />

          {valueSearch === '' &&
            <Paginas>
              <PaginaButton disabled={paginaAtual > 1 ? false : true} onClick={() => { setOffsetSetor(0); setPaginaAtual(1) }}>
                <FaAngleDoubleLeft className="icon" size="20px" />
              </PaginaButton>

              <PaginaButton disabled={paginaAtual > 1 ? false : true} onClick={() => setaOffset('anterior')}>
                <FaAngleLeft className="icon" size="20px" />
              </PaginaButton>

              <span>{paginaAtual} de {pagesTotal} {pagesTotal > 1 ? 'páginas' : 'página'}</span>

              <PaginaButton disabled={(paginaAtual < Math.ceil(totalSetor / 10)) ? false : true} onClick={() => setaOffset('proximo')}>
                <FaAngleRight className="icon" size="20px" />
              </PaginaButton>

              <PaginaButton disabled={paginaAtual !== pagesTotal ? false : true} onClick={() => { setOffsetSetor((pagesTotal - 1) * 10); setPaginaAtual(pagesTotal) }}>
                <FaAngleDoubleRight className="icon" size="20px" />
              </PaginaButton>
            </Paginas>
          }
        </Painel>
      </Container>
    )} />
  );
}

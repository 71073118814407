import styled from 'styled-components';

import colors from '../../presets/colors';

export const Container = styled.div`
  width: calc(100vw - 110px);
  height: calc(100vh - 90px);
  background-color: rgba(0, 0, 0, 0.6);
  padding: 30px;
  z-index: 1053;
  position: fixed;
  top: 90px;
  left: 110px;

  @media (max-width: 1440px) {
    height: calc(100vh - 70px);
    top: 70px;
  }

  @media (max-width: 575px) {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2000;
  }
`;

export const ContentModal = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.bgprimary};
  border-radius: 10px;
  position: relative;
  overflow: auto;
  display: flex;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  #closeModalImageCrop {
    font-size: 25px;
    background-color: transparent;
    border: none;
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    z-index: 2000;

    @media (max-width: 1600px) {
      top: 10px;
      right: 10px;
    }

    @media (max-width: 1024px) {
      top: 5px;
      right: 5px;
    }
  }
`;

export const ContentImage = styled.div`
  width: 75%;
  height: 100%;
  background-color: ${colors.bgsecondary};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-content: ${props => (props.hasImage) ? 'space-between' : 'center'};
  align-items: center;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const ImageCrop = styled.div`
  width: 100%;
  height: calc(100% - 93px);
  display: flex;
  justify-content: center;
  align-items: center;

  /* Melhorar este ajuste de layout */
  .ReactCrop {
    height: 100%;

    div {
      height: 100%;

      img.ReactCrop__image {
        width: 100%;
        height: 100%;
      }
    }
  }
  /* ----------------------------- */
`;

export const ImageCropFunctions = styled.div`
  width: 100%;
  padding: 25px;
  display: flex;
  justify-content: center;

  button {
    font-size: 15px;
    padding: 10px 20px;
    color: ${colors.btn_text_primary};
    background-color: ${colors.btn_primary};
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: center;

    svg {
      margin-left: 5px;
    }
  }

  input {
    display: none;
  }
`;

export const ContentOptionsImage = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const HeaderOptionsCrop = styled.div`
  width: 100%;
  padding: 25px;
  text-align: center;
  border-bottom: 1px solid #d7d7d7;

  @media (max-width: 1600px) {
    padding: 15px;
  }

  @media (max-width: 1280px) {
    padding: 10px;

    h2 {
      font-size: 18px;
    }
  }
`;

export const BodyOptionsCrop = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const ListOptionsCrop = styled.ul`
  width: 100%;
  height: 30%;

  @media (max-width: 1600px) {
    height: 35%;
  }

  @media (max-width: 1024px) {
    height: auto;
  }
`;

export const OptionCrop = styled.div`
  width: 100%;
  height: 25%;
  padding: 0 25px;
  border-bottom: 1px solid #d7d7d7;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1024px) {
    height: 30px;
  }

  label, p {
    font-size: 18px;

    @media (max-width: 1600px) {
      font-size: 16px;
    }

    @media (max-width: 1280px) {
      font-size: 14px;
    }
  }
`;

export const ContentCanvas = styled.div`
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1600px) {
    height: 65%;
  }

  canvas {
    max-width: 100%;
    max-height: 400px;
    object-fit: contain;
    
    @media (max-width: 1600px) {
      max-height: 200px;
    }

    @media (max-width: 1280px) {
      max-height: 150px;
    }

    @media (max-width: 1024px) {
      max-height: 250px;
      padding: 5px;
    }
  }
`;

export const FooterOptionsCrop = styled.div`
  width: 100%;
  border-top: 1px solid #d7d7d7;
  padding: 25px;
  display: flex;
  justify-content: center;

  @media (max-width: 1600px) {
    padding: 15px;
  }

  button {
    font-size: 25px;
    padding: 15px 30px;
    color: ${colors.btn_text_primary};
    background-color: ${colors.btn_primary};
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: center;

    @media (max-width: 1600px) {
      font-size: 20px;
      padding: 10px 20px;
    }

    @media (max-width: 1280px) {
      font-size: 16px;
    }

    svg {
      margin-left: 10px;
    }

    .fa-spin {
      margin-right: 10px;
      -webkit-animation: icon-spin 1s infinite linear;
      animation: icon-spin 1s infinite linear;
    }

    @-webkit-keyframes icon-spin {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }

      100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
      }
    }

    @keyframes icon-spin {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }

      100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
      }
    }
  }
`;
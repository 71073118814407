import styled from 'styled-components';

import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Container = styled.div`
    right: 5px;
    bottom: -400px;
    position: fixed;
    z-index: 1050;
    box-shadow: ${effects.boxshadow};
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    transition: all 0.8s;

    &.opened { bottom: 0; }

    .head {
        background-color: ${colors.twitter};
        color: white;
        width: 350px;
        height: 50px;
        display: flex;
        align-items: center;
        padding: 10px;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        
        .title {
            display: flex;
            align-items: center;

            svg { 
                font-size: 20px;
                margin-right: 10px;
            }
            
            span {
                letter-spacing: 1px;
            }
        }

        button {
            background-color: transparent;
            border: 0;
            color: white;
            font-size: 20px;
            height: 100%;
            display: flex;
            align-items: center;
        }
    }

    .body {
        height: 400px;
    }
`;

export const ListaContatos = styled.ul`
    list-style: none;
    padding: 0 10px;
    overflow-y: auto;
    overflow-x: hidden;

    li {
        display: flex;
        margin: 10px 0;
        text-align: center;
        padding: 10px 0;
        border-top: 1px solid ${colors.border};
        border-bottom: 1px solid ${colors.border};
        cursor: pointer;

        &:first-child {
            border-top: 0;
        }

        &:last-child {
            border-bottom: 0;
        }

        img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-right: 10px;
            box-shadow: ${effects.boxshadow};
        }

        .desc {
            font-size: 13px;
            letter-spacing: 1px;
            display: flex;
            flex-direction: column;
            text-align: flex-start;
            justify-content: center;
            
            strong {
                margin-bottom: 5px;

                span {
                    margin-left: 10px;
                    color: rgba(0, 0, 0, 0.6);
                }
            }
            
            p {
                text-align: left;
            }
        }
    }
`;
import styled from 'styled-components';

import colors from '../../../../presets/colors';
import effects from '../../../../presets/effects';

export const Container = styled.div`
  padding: 0 10px;
  width: 100%;
  height: 40px;
  background-color: #f4f4f6;
  border: ${props => (props.isSelected ? `1px solid ${colors.primary}` : `none`)};
  border-radius: 10px;
  box-shadow: ${effects.boxshadow};
  margin-bottom: 15px;
  position: relative;
  display: flex;
  align-items: center;


  svg {
    color: #4a4a4a;
    font-size: 20px;
    position: absolute;
    right: 10px;
    cursor: pointer;

    &.edit {
      right: 35px;
      color: ${colors.primary};
    }

    &.delete {
      font-size: 18px;
      color: ${colors.primary};
    }
  }

  .info-assunto {
    display: flex;
    align-items: center;
  }

  .checkbox {
    margin-right: 20px;
    height: 16px;
    width: 16px;
    border: 1px groove rgb(0 0 0 / 20%);
    cursor: pointer;

    &.active {
      border: none;
      background-color: ${colors.primary};
    }
  }
  
  span {
    margin-right: 30px;

    @media (max-width: 450px) {
      font-size: 12px;
    }
  }
`;

import React from 'react';

import { ChromePicker  } from 'react-color';

export default function ColorPicker({ cor, changeColor, show }) {
    return (
        <>
            {(show) ? (
                <div className="wrap-color-picker">
                    <ChromePicker onChange={e => changeColor(e)} color={cor} />
                </div>
            ) : '' }
        </>
    );
}


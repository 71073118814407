import React, { useState, useEffect } from 'react';
import Geral from '../../services/api-geral';

import { Painel } from '../../styles/content';

import Page from '../../components/Page';
import CTable from '../../components/ComponentTable';
import FormUsuario from '../../components/Usuario/Form';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";

import { Container } from './styles';
import { Paginas, PaginaButton } from '../../components/BotoesPaginacao/styles';

export default function Usuarios() {
  const [dadosUsuario, setDadosUsuario] = useState([]);
  const [allDados, setAllDados] = useState([]);
  const [offsetUsuarios, setOffsetUsuarios] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0); // Total de usuários daquela empresa (Este estado serve para limitar o número de usuários)
  const [totalUsuarios, setTotalUsuarios] = useState(0); // Total de usuários daquela empresa menos o usuário que está logado (Este estado serve para a paginação funcionar corretamente)
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [pagesTotal, setPagesTotal] = useState(1);
  const [valueSearch, setValueSearch] = useState('');

  const [hasChangesModal, setHasChangesModal] = useState(false);

  const getUsers = async () => {
    const response = await Geral.get(`/users?usuarios=true&offset=${offsetUsuarios}`);
    const responseCount = await Geral.get(`/users?usuarios=all&offset=${offsetUsuarios}&count=true`);

    setDadosUsuario(response.data);
    setTotalUsuarios(responseCount.data);

    const pagesCount = calculatePagesCount(10, responseCount.data);
    setPagesTotal(pagesCount);
  };

  const calculatePagesCount = (pageSize, totalCount) => {
    // we suppose that if we have 0 items we want 1 empty page
    return totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize);
  };

  useEffect(() => {
    getAllUsers();
  }, [])

  const getAllUsers = async () => {
    const response = await Geral.get(`/users?usuarios=everything`);

    setTotalUsers(response.data.length);

    setAllDados(response.data);
  }

  const setaOffset = (acao) => {
    if (acao === 'proximo') {
      if (Math.floor(totalUsuarios / 10) !== (offsetUsuarios / 10)) {
        setOffsetUsuarios(offsetUsuarios + 10);
        setPaginaAtual(paginaAtual + 1);
      }
    }

    if (acao === 'anterior') {
      if (offsetUsuarios > 0) {
        setOffsetUsuarios(offsetUsuarios - 10);
        setPaginaAtual(paginaAtual - 1);
      }
    }
  }

  useEffect(() => {
    getUsers();
  }, [offsetUsuarios]);

  const refresh = () => {
    getUsers();
    getAllUsers();
  }

  return (
    <Page hasChanges={hasChangesModal} setHasChanges={setHasChangesModal} content={(
      <Container className="content">
        <Painel className="painel-usuario">
          <CTable
            titles={['ID', 'Nome', 'E-mail']}
            values={dadosUsuario}
            allValues={allDados}
            indexes={['id', 'name', 'email', 'cargo', 'setor.name']}
            indexesSearch={['name']}
            setValueSearch={setValueSearch}
            load={refresh}
            FormCustom={FormUsuario}
            actionDelete='/users'
            titlePage="Lista de usuários"
            breadcrumbPage={[{ title: 'Listas' }]}
            emptyPage='Nenhum registro foi encontrado!'
            totalUsers={totalUsers}
            hasChangesModal={hasChangesModal}
            setHasChangesModal={setHasChangesModal}
          />

          {valueSearch === '' &&
            <Paginas>
              <PaginaButton disabled={paginaAtual > 1 ? false : true} onClick={() => { setOffsetUsuarios(0); setPaginaAtual(1) }}>
                <FaAngleDoubleLeft className="icon" size="20px" />
              </PaginaButton>

              <PaginaButton disabled={paginaAtual > 1 ? false : true} onClick={() => setaOffset('anterior')}>
                <FaAngleLeft className="icon" size="20px" />
              </PaginaButton>

              <span>{paginaAtual} de {pagesTotal} {pagesTotal > 1 ? 'páginas' : 'página'}</span>

              <PaginaButton disabled={(paginaAtual < Math.ceil(totalUsuarios / 10)) ? false : true} onClick={() => setaOffset('proximo')}>
                <FaAngleRight className="icon" size="20px" />
              </PaginaButton>

              <PaginaButton disabled={paginaAtual !== pagesTotal ? false : true} onClick={() => { setOffsetUsuarios((pagesTotal - 1) * 10); setPaginaAtual(pagesTotal) }}>
                <FaAngleDoubleRight className="icon" size="20px" />
              </PaginaButton>
            </Paginas>
          }
        </Painel>
      </Container>
    )} />
  );
}

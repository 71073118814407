import styled from 'styled-components';

import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1052;
`;

export const WrapModalView = styled.div`
  margin: 20px;
  padding: 50px 10px;
  display: flex;
  flex-direction: column;
  z-index: 4001;
  border-radius: 25px;
  width: 700px;
  height: fit-content;
  background-color: white;
  overflow-y: auto;
  position: relative;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);

  .alter-height {
    height: 50%;
    left: 48%;
  }

  top: 45vh;
  left: 50%;

  @media (max-width: 1024px) {
    margin-right: 25%;
  }

  @media (max-width: 500) {
    height: 50%;
  }

  .closebutton {
    position: absolute;
    background: transparent !important;
    top: 15px;
    right: 10px;
    color: #8a2387;

    border: transparent;
    padding-right: 10px !important;

    svg {
      background: transparent !important;
      border: transparent !important;
      color: #8a2387 !important;
      width: 24px;
      height: 24px;
      margin: 0;
    }
  }  

  @media (max-width: 1366px) {
    padding: 20px 15px;
  }

  @media (max-width: 1024px) {
    padding: 20px 15px;
    width: auto;
  }

  @media (max-width: 450) {
    padding: 30px 25px;
    width: auto;
    overflow-y: scroll;
  }

  .modal {
    overflow-y: auto !important;
    padding-right: 10px !important;

    .header {
      display: flex ;
      place-content: center ;
      text-align: center ;
      height: fit-content;
      margin-bottom: 20px ;

      svg {
        margin-right: 20px;
        font-size: 40px !important;
        color: #8a2387 ;
        cursor: default !important;
        border: transparent !important;
        background-color: transparent !important;
      }

      h3 {
        font-size: 26px;
        color: #8a2387;
        @media (max-width: 500) {
          font-size: 20px;
        }
      }
    }

    .content {
      padding: 0px 50px;
      font-size: 16px;

      @media (max-width: 500) {
        font-size: 12px;
      }

      strong {
        color: ${colors.primary};
      }
    }
  }
  
`;

export const Container = styled.div`
  width: 100%;
  height: 110%;

  margin-top: 15px;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: center;  
  color: #8a2387;
`;


import styled from "styled-components"
import colors from "../../../presets/colors"

export const FilesLayer = styled.div`
width: 100%;
height: 100%;
border: 0.2rem dashed #d4dbde; 
border-radius: 0.5rem;
background-color: #fff;
display: flex;
justify-content: center;

&.dragActive {
  border-color: ${colors.primary};
}

@media (max-width: 678px) {
  display: block;
  margin: 0 auto;
  overflow: auto;
}

`

export const SizeInfo = styled.h3`
width: 100%;
text-align: center;
color: #737677;
font-size: .9rem;
font-weight: 500;
margin-top: .5rem;

@media (max-width: 678px) {
  font-size: 12px;
}
`
export const AnexarContainer = styled.div`
width: 40%;
margin: 0 auto;
display: flex;
flex-direction: column;
justify-content: center;
padding: 1rem;
min-width: 200px;

@media (max-width: 678px) {
  width: 100%;
  min-width: auto;
  font-size: 12px;
}
`

export const FilesContainer = styled.div`
width: 60%;
min-width: 280px;
overflow-y: auto;

@media (max-width: 678px) {
  width: 90%;
  margin: 0 auto;
  min-width: 150px;
}
`

export const UploadInput = styled.input`
margin: 0 auto;
display: none;
`

export const UploadFiles = styled.button`
margin: 0 auto;
padding: 0.375rem 0.75rem;
border: 1px solid #8a2387;
display: flex;
align-items: center;
font-size: .9rem;
border-radius: 0.25rem;
transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
color: ${colors.primary};
background: #FFF;
font-weight: 400;
text-align: center;
    vertical-align: middle;

svg {
  font-size: 18px;
  margin-right: .25rem;
}

&:hover {
  background-color: rgba(138, 35, 135, .75);
  color: #FFF;
}

&:focus {
  box-shadow: 0 0 0 0.2rem rgba(138, 35, 135, .25) !important;
}

@media (max-width: 678px) {
  padding: 0.175rem 0.65rem;
  /* width: 100%; */
  min-width: none;
  font-size: 12px;

  svg {
    font-size: 16px;
  }
}
`

import styled from 'styled-components';

import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Wrap = styled.div`
    width: 100%;
    //width: ${props => (props.local == "externo") ? '100%' : '100%'};
    height: 100%;
    position: relative;
    background: #f5f5f5;
    padding: 25px;

    .atendimento-espera{
        height: 100%;
        width: 100%;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        background: linear-gradient(to bottom right, #5c77fe, #a6abc2);

        span{
            width: 60%;
            text-align:center;
            padding: 20px;
            color: white;
            letter-spacing: 3px;
            font-weight: bold;
        }
    }

    .avaliacao-finalizada{
        height: 100%;
        width: 100%;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        background: linear-gradient(to bottom right, #5c77fe, #a6abc2);

        p{
            width: 60%;
            text-align:center;
            padding: 20px;
            color: white;
            letter-spacing: 3px;
            font-weight: bold;
        }
    }

    .atendimento-finalizado{
        height: 100%;
        width: 100%;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        background: linear-gradient(to bottom right, #5c77fe, #a6abc2);

        p{
            width: 60%;
            text-align:center;
            padding: 20px;
            color: white;
            letter-spacing: 3px;
            font-weight: bold;
        }

        span{
            margin: 10px;
        }

        textarea{
            resize: none;
        }

        button{
            width: 40%;
            height: 40px;
            color: white;
            font-weight: bold;
            font-size: 12px;
            text-transform: uppercase;
            background-color: #2C76FF;
            border-radius: 10px;
            border: 0;
            margin-bottom: 20px;
            -webkit-letter-spacing: 1px;
            -moz-letter-spacing: 1px;
            -ms-letter-spacing: 1px;
            letter-spacing: 1px;
            margin-right: 5%;
        }

    }

    .lista-mensagem {
        height: ${props => (props.local == "externo") ? 'calc(100% - 126px)' : 'calc(100% - 125px)'};
        background-color: rgba(255, 255, 255, 0.03);
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        padding: 20px;
        display: flex;
        flex-direction: column;

        &.draggin {
            height: 0%;
            padding: 0;
        }

        .scroller {
            display: flex;
            flex-direction: column-reverse;
        }

        & > div {
            display: flex;
            flex-direction: column;

            ::-webkit-scrollbar {
                width: 5px;
            }

            /* Track */
            ::-webkit-scrollbar-track {
                background: #c7c7c7;
            }

            /* Handle */
            ::-webkit-scrollbar-thumb {
                background: ${colors.primary};
            }

            /* Handle on hover */
            ::-webkit-scrollbar-thumb:hover {
                background: #4f5e85;
            }
        }

        .me {
            color: ${colors.texton};
            border-radius: 5px;
            padding: 5px 25px;
            align-self: flex-end;
            max-width: 60%;
            overflow-wrap: break-word;
            word-wrap: break-word;
            box-shadow: ${effects.boxshadow};
            margin: 10px;
            min-width: 200px;
            letter-spacing: 1px;

            @media (max-width: 1400px) {
                font-size: 13px;
                font-weight: normal;
            }

            @media (max-width: 575px) {
                font-size: 11px;
                min-width: 140px;
            }

            small {
                display: block;
                font-size: 12px;
                font-weight: normal;
                color: ${colors.texton};
                margin-top: 5px;

                @media (max-width: 1400px) {
                    font-size: 10px;
                }
            }
        }

        .you {
            color: ${colors.texon};
            min-width: 200px;
            background-color: ${colors.bg_from_primary};
            box-shadow: ${effects.boxshadow};
            border-radius: 5px;
            max-width: 60%;
            padding: 5px 25px;
            letter-spacing: 1px;
            align-self: flex-start;
            margin: 10px;
            overflow-wrap: break-word;
            word-wrap: break-word;

            @media (max-width: 1400px) {
                font-size: 13px;
                font-weight: normal;
            }

            @media (max-width: 575px) {
                font-size: 11px;
                min-width: 140px;
            }

            small {
                display: block;
                font-weight: normal;
                font-size: 12px;
                margin-top: 5px;

                @media (max-width: 1400px) {
                    font-size: 10px;
                }
            }
        }

        .me-anexo {
            background-color: #fff;
            border: 1px solid ${colors.primary};
            border-radius: 10px;
            max-width: 60%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px 25px;
            align-self: flex-end;
            color: white;
            overflow-wrap: break-word;
            word-wrap: break-word;
            margin-bottom: 20px;
            margin-right: 20px;

            .titulo{
                font-size: 15px;
                font-weight: bold;
            }

            span {
              color: ${colors.primary};
            }
        }

        .you-anexo {
            color: white;
            background-color: ${colors.primary};
            border-radius: 10px;
            max-width: 60%;
            display:flex;
            flex-direction: column;
            justify-content:center;
            align-items:center;
            border: 1px solid #c7c7c7;
            padding: 10px 25px;
            align-self: flex-start;
            margin-bottom: 20px;
            overflow-wrap: break-word;
            word-wrap: break-word;

            .titulo{
                font-size: 15px;
                font-weight: bold;
            }

            a {
                align-self: flex-start;
                color: #fff;
                font-weight: bold;
                cursor: pointer;

                &:hover{
                    text-decoration: underline;
                }
            }

        }
    }
`;

export const DropzoneContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;

`;

export const InfoHead = styled.div`
        height: 60px;
        border-bottom: 1px solid #d9d9d9;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media(max-width: 600px) {
            height: 70px;
        }

        .reatribuicao-atendimento{
          display:flex;
          justify-content:center;
          align-items:center;

          .select-usuarios{
            width: 100%;

            .select-setor {
                height: 30px;
                border: solid 1px black;
                background: white;
                border-radius: 5px;

                @media(max-width: 600px) {
                    font-size: 12px;
                    height: 25px;
                }

                @media(max-width: 500px) {
                    width: 100px;
                }

                @media(max-width: 430px) {
                    width: 70px;
                }
                @media(max-width: 340px) {
                    width: 50px;
                }
            }
          }

          button{
            justify-content: center;
            display: flex;
            align-items: center;
            padding: 10px;
            margin: 10px 10px;
            cursor: pointer;
            height: 30px;
            border: none;
            border-radius: 5px;
            background: ${colors.primary};
            color: white;
            transition: background 0.2s linear;

            @media (max-width: 600px) {
                font-size: 12px;
                height: 25px;
                margin: 0px 10px;
            }

            @media (max-width: 400px) {
                font-size: 10px;
            }

            &:hover{
              background: #691d66;
            }

            &:disabled {
                background: rgba(0,0,0,0.3);
            }

          }

        }

        .user {
            display: flex;
            align-items: center;

            @media (max-width: 600px) {
                font-size: 12px;
            }

            .back-button {
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: ${colors.primary};
                border: none;
                border-radius: 50%;
                color: white;
                margin-left: 10px;

                svg {
                    font-size: 20px;
                }

                @media (min-width: 1025px) {
                    display: none;
                }
            }  

            .info {
                display: flex;
                flex-direction: column;
                justify-content: center;

                strong {
                    color: ${colors.text_primary};
                    font-weight: normal;
                }

                span {
                    display: block;
                    color: #909090;
                    font-size: 12px;
                }
            }
        }

        .acoes-atendimento {
            display: flex;
            justify-content: end;

            @media(max-width: 600px) {
                flex-direction: column;
            }
        }

        .options-conversa {
            margin-right: 20px;
            font-size: 16px;
            background-color: ${colors.primary};
            color: white;
            border: none;
            border-radius: 5px;
            display: flex;
            outline: nine;
            align-items: center;
            padding: 10px;
            margin: 10px 10px;
            cursor: pointer;
            height: 30px;
            transition: background 0.2s linear;
            width: 90px;
            align-self: flex-end;

            @media (max-width: 600px) {
                width: 76px;
                font-size: 12px;
                height: 25px;
                margin: 0px 10px;
                margin-top: 5px;
            }

            @media (max-width: 400px) {
                width: 67px;
                font-size: 10px;
            }

            &:hover{
                background: #691d66;
            }

            &:disabled {
                background: rgba(0,0,0,0.3);
            }
        }
`;

export const ImgAndStatus = styled.div`
    position: relative;
    background-color: rgba(0, 0, 0, 0.4);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;

    svg {
        display: block;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
    }

    span {
        width: 12px;
        height: 12px;
        display: block;
        background-color: green;
        position: absolute;
        bottom: 0px;
        right: 3px;
        border-radius: 50%;
        border: 3px solid #1d2129;
        box-sizing: content-box;

        &.online {
            background-color: green;
        }

        &.offline {
            background-color: gray;
        }
    }
`;

export const ListaArquivos = styled.div`
    width: 80%;
    height: 70%;
    margin-bottom: 50px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    border: 2px solid rgba(255, 255, 255, 0.05);
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
        color: rgba(255, 255, 255, 0.3);
        font-weight: normal;
        letter-spacing: 1px;
    }
`;

export const WrapCtaPagination = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;

    @keyframes spin {
        from {
            transform:rotate(0deg);
        }
        to {
            transform:rotate(360deg);
        }
    }

    button {
        background-color: white;
        border: 1px solid ${colors.primary};
        font-size: 18px;
        padding: 0 20px;
        height: 40px;
        border-radius: 5px;
        color: ${colors.primary};
        font-weight: normal;
        font-size: 14px;
        box-shadow: ${effects.boxshadow};

        svg {
            animation-name: spin;
            animation-duration: 1000ms;
            animation-iteration-count: infinite;
            animation-timing-function: linear; 
        }
    }
`;

export const Form = styled.form`
    display: flex;
    height: ${props => (props.local == "externo") ? '50px' : '60px'};
    justify-content: center;
    align-items: center;

    .wrap-drop {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        box-shadow: 0 1px 15px rgba(0,0,0,.04), 0 1px 6px rgba(0,0,0,.04);
        background-color: #fff;
    }

    .dropzone {
        height: 100%;
    }

    &.draggin {
        height: calc(100% - 75px);
    }

    .atendimento-fechado {
        
    }

    .input-group {
        width: 100%;
        height: 100%;
        background-color: rgba(255,255,255,0.04);
        border: 1px solid rgba(255,255,255,0.2);
        border-radius: 25px;
        position: relative;


        input {
            width: 100%;
            height: 100%;
            background: transparent;
            border-radius: 25px;
            border: 0;
            padding: 0 55px 0 15px;
            color: ${colors.text_primary};
            font-weight: normal;
            font-size: 15px;
        }

        button {
            width: 26px;
            height: 26px;
            position: absolute;
            background-color: transparent;
            color: rgba(255,255,255,0.5);
            border: 0;
            font-size: 26px;
            right: 15px;
            top: calc(50% - 13px);

            svg {
              color: ${colors.primary};
            }
        }
    }
`;

export const MenuConversa = styled.div`
    position:absolute;
    width: 20%;
    background: #c7c7c7;
    padding: 5px;
    top: 50px;
    right: 25px;
    z-index: 1050;

    button{
        color: #fff;
        background-color: ${colors.primary};
        border: none;
        padding: 10px 20px;
        outline: none;

        &:hover{
            background: rgba(146, 44, 136, .7);
        }
    }
`;

export const Blocked = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1050;

    img { width: 50%; border-radius: 10px; margin-bottom: 50px; }
    h2 { color: ${colors.primary}; }
`;

export const BlockedAlert = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f5f5f5;
    position: absolute;
    padding-top: 30px;
    left: 0;
    z-index: 1050;

    .holder {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;

        @media (max-width: 700px) {
            display: none;
        }
    }

    @media (max-width: 1024px) {

        .holder {
            width: calc(100% - 400px);
            position: absolute;
            left: 400px;
        }
        
    }
`;

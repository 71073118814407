import styled from "styled-components";
import colors from '../../presets/colors';

export const Section = styled.div`
    display: flex;
    flex-direction:column;
    background: #fff;

    .detalhes {
        width: 100%;
        margin-top: 30px;
        border-spacing: 15px;
        display:flex;

        .detalhes-conversa {
            .userDestaque {
                font-weight: bolder;
                color: ${colors.primary};
            }
        }
    }
`;

export const Table = styled.table`
  td {
    text-align: center;
  }

 
`;
import React, { useState, useEffect } from 'react';
import Page from '../../components/Page';
import { RiFacebookCircleFill, RiFacebookFill, RiCheckFill, RiFacebookLine, RiMessage2Line, RiThumbUpLine } from 'react-icons/ri';
import api from '../../services/api-geral';
import { urlFacebookLogin } from '../../config';
import { format } from 'date-fns';
import { Container, Login, Head, Content } from './styles';

function FacebookNew() {

    const [credentials, setCredentials] = useState({});
    const [isLogged, setIsLogged] = useState(false);
    const [feed, setFeed] = useState([]);
    const [pageSelected, setPageSelected] = useState('');
    const [status, setStatus] = useState('');

    useEffect(() => {
        refreshLogin();
    }, []);

    useEffect(() => {
        if (credentials.accessToken && credentials !== {} && credentials !== null) {
            setIsLogged(true);
            getUserFeed();
        } else {
            setIsLogged(false);
        }
    }, [credentials]);

    const refreshLogin = async () => {
        const response = (await api.get('/facebook/refresh')).data;
        setCredentials(response);
    };

    const getUserFeed = async () => {
        const response = (await api.get('/facebook/feed/user')).data;
        setFeed(response);
    };

    const getPageFeed = async (pageId) => {
        setPageSelected(pageId);
        const response = (await api.get(`/facebook/feed/page/${pageId}`)).data;
        setFeed(response);
    };

    const login = () => {
        window.location = urlFacebookLogin;
    };

    const logout = async () => {
        const response = (await api.get('/facebook/logout')).data;
        setIsLogged(false);
        setCredentials(response);
    }

    const postStatus = async e => {
        e.preventDefault();

        const response = (await api.post('/facebook/page/status/post', {
            status,
            pageSelected
        })).data

        setStatus('');
        getPageFeed(pageSelected);
        setPageSelected('');
    };

    return (
        <Page content={(
            <Container>
                {(isLogged) ? (
                    <>
                        <Head>
                            <div className="banner">
                                <RiFacebookFill className="icon" />
                                <div className="filter">
                                    <div className="card" onClick={() => getUserFeed()}>
                                        <img src={credentials.user.picture} />
                                        <h2>{credentials.user.displayname}</h2>
                                    </div>
                                    <button onClick={() => logout()}>Deslogar</button>
                                </div>
                            </div>
                            <form onSubmit={postStatus} className="formulario-postagem">
                                {pageSelected === '' && <div className="blocked"><h3>Selecione uma página para publicar um status!</h3></div>}
                                <div className="head">
                                    <RiFacebookCircleFill className="icon" />
                                    <span>Publique na sua página</span>
                                </div>
                                <div className="content">
                                    <textarea value={status} onChange={e => setStatus(e.target.value)} placeholder="No que você está pensando?"></textarea>
                                    <div className="buttons">
                                        <button type="submit" className="publicar">Publicar</button>
                                    </div>
                                </div>
                            </form>
                        </Head>
                        <Content>
                            <ul className="lista-paginas">
                                <li onClick={() => getUserFeed()}>
                                    <img src={credentials.user.picture} />
                                    <div className="description">
                                        <strong>{credentials.user.displayname}</strong>
                                    </div>
                                    <div className={`checkbox ${pageSelected == '' && 'active'}`}>
                                        {pageSelected == '' && (<RiCheckFill className="icon" />)}
                                    </div>
                                </li>
                                {(credentials.pages) ? (
                                    credentials.pages.map(page => (
                                        <li onClick={() => getPageFeed(page.id)}>
                                            <img src={page.picture} />
                                            <div className="description">
                                                <strong>{page.name}</strong>
                                                <span>{page.description}</span>
                                            </div>
                                            <div className={`checkbox ${pageSelected == page.id && 'active'}`}>
                                                {pageSelected == page.id && (<RiCheckFill className="icon" />)}
                                            </div>
                                        </li>
                                    ))
                                ) : (<span>Tem pagina nao.</span>)}
                            </ul>
                            <ul className="lista-postagens">

                                {feed.map(post => (
                                    <li>
                                        <div className="head">
                                            <img src={credentials.user.picture} />
                                            <div className="info">
                                                <strong>{credentials.user.displayname}</strong>
                                                <small>{post.created_time}</small>
                                            </div>
                                        </div>
                                        <img src={post.picture} className="img-post" />
                                        <p className="text-post">{post.message}</p>
                                        <p>{post.description}</p>
                                        <div className="buttons">
                                            <button><RiMessage2Line /> Comentários
                                                {(post.comments) && <small>({post.comments.summary.total_count})</small>}
                                            </button>
                                            <button><RiThumbUpLine /> Impressões
                                                <small>({post.reactions})</small>
                                            </button>
                                            <button onClick={() => window.open(post.link.url)}><RiFacebookLine /> Ver no Facebook</button>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </Content>
                    </>
                ) : (
                    <Login>
                        <div className="panel-login">
                            <div className="banner">
                                <RiFacebookCircleFill />
                                <h1>Atualize seu Facebook pelo MERX</h1>
                            </div>
                            <div className="form-login">
                                <h1>Clique no botão abaixo para permitir o acesso do MERX à sua conta!</h1>
                                <p>
                                    Conecte sua conta para ter controle das funcionalidades do feed do Facebook como visualizar, criar postagens e interagir com outros posts utilizando o Merx.
                                </p>
                                <button onClick={() => login()} className="btn-login">
                                    <RiFacebookCircleFill />
                                    <span>Entrar na minha conta</span>
                                </button>
                            </div>
                        </div>
                    </Login>
                )}
            </Container>
        )} />
    );
}

export default FacebookNew;
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import log from '../../../util/log';
import ApiGeral from '../../../services/api-geral';

import { WrapForm } from './styles';

import Swal from 'sweetalert2';

import CInput from '../../ComponentInput';
import CButton from '../../ComponentButton';

import AlertaMerx from '../../AlertaMerx';

export default function Form({ item, success, close, setHasChangesModal }) {
  const [name, setName] = useState('');
  const [showAlertMerx, setShowAlertMerx] = useState(false);
  const [msgAlertMerx, setMsgAlertMerx] = useState('');
  const [allowAtendimento, setAllowAtendimento] = useState();

  useEffect(() => {
    setName(item.name);
    if (item.flag_atendimento === true) {
      setAllowAtendimento(1);
    } else {
      setAllowAtendimento(0);
    }
  }, [item]);

  useEffect(() => {
    if (name !== '') {
      setHasChangesModal(true);
    } else {
      setHasChangesModal(false);
    }
  }, [name]);

  const submitForm = async e => {
    e.preventDefault();

    if (item.id) {
      const response = (await ApiGeral.put(`/setor/${item.id}`, { name, allowAtendimento })).data;

      if (response.type === "success") {
        Swal.fire({
          title: 'Sucesso!',
          text: response.msg,
          icon: 'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok'
        })
        log(`editou as informações do setor com nome ${name}.`);
        success();
        close();
      } else {
        Swal.fire({
          title: 'Erro!',
          text: response.msg,
          icon: 'error',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok'
        })
        log(`tentou editar as informações do setor com nome ${name}, mas houve um erro.`);
      }
    } else {
      const response = (await ApiGeral.post('/setor', { name, allowAtendimento })).data;

      if (response.type === "success") {
        Swal.fire({
          title: 'Sucesso!',
          text: response.msg,
          icon: 'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok'
        })
        log(`cadastrou um novo setor com nome ${name.toUpperCase()} (ID: ${response.setor.id}).`);
        success();
        setHasChangesModal(false);
        close();
      } else {
        Swal.fire({
          title: 'Erro!',
          text: response.msg,
          icon: 'error',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok'
        })
        log(`tentou cadastrar um novo setor mas houve um erro.`);
      }
    }
  };

  return (
    <WrapForm onSubmit={submitForm}>
      <div className="form-header">
        <h2>{`${(item.id) ? 'Edição' : 'Cadastro'} de setor`}</h2>
      </div>
      <div className="form-body">
        <CInput val={name} change={e => setName(e)} type='text' label='Nome' required={true} />
        <div>
          <span>Setor disponível para atendimento ao cliente?</span>
          <div className="radio-buttons">
            <div className="radio-label">
              <input type="radio" name="option_atendimento" value={true} onChange={e => setAllowAtendimento(1)} checked={allowAtendimento} required></input>
              <label for="html">Sim</label><br></br>
            </div>
            <div className="radio-label">
              <input type="radio" name="option_atendimento" value={false} onChange={e => setAllowAtendimento(0)} checked={allowAtendimento ? false : true} required></input>
              <label for="css">Não</label><br></br>
            </div>
          </div>
        </div>
      </div>
      <div className="form-footer">
        <CButton title='Salvar' cstyle='primary small' />
      </div>
    </WrapForm>
  );
}

import React, { useState, useEffect } from 'react';

import log from '../../../util/log';
import ApiGeral from '../../../services/api-geral';

import { WrapForm } from './styles';

import CInput from '../../ComponentInput';
import AlertaMerx from '../../AlertaMerx';
import CButton from '../../ComponentButton';

export default function Form({ item, success, close }) {
  const [name, setName] = useState('');
  const [showAlertMerx, setShowAlertMerx] = useState(false);
  const [msgAlertMerx, setMsgAlertMerx] = useState('');

  useEffect(() => {
    setName(item.name);
  }, [item]);

  const submitForm = async e => {
    e.preventDefault();

    if (item.id) {
      const response = (await ApiGeral.put(`/formulario-assunto/${item.id}`, { name })).data;

      if (response.type === "success") {
        log(`editou as informações do assunto com nome ${name}.`);
        success();
        close();
      } else {
        setMsgAlertMerx(response.msg);
        setShowAlertMerx(true);
        setTimeout(() => { setShowAlertMerx(false) }, 5000)
        log(`tentou editar as informações do assunto com nome ${name}, mas houve um erro.`);
      }
    } else {
      const response = (await ApiGeral.post('/formulario-assunto/', { name })).data;

      if (response.type === "success") {
        log(`cadastrou um novo assunto com nome ${name}.`);
        success();
        close();
      } else {
        setMsgAlertMerx(response.msg);
        setShowAlertMerx(true);
        setTimeout(() => { setShowAlertMerx(false) }, 5000)
        log(`tentou cadastrar um novo assunto mas houve um erro.`);
      }
    }
  };

  return (
    <WrapForm onSubmit={submitForm}>
      <h2>{`${(item.id) ? 'Edição' : 'Cadastro'} de assunto`}</h2>
      <CInput val={name} change={e => setName(e)} type='text' label='Nome' required={true} />
      {(showAlertMerx) && (
        <AlertaMerx
          type='danger'
          style='default'
          text={msgAlertMerx}
          css={['auto', '25px', 'auto', 'auto']}
          context='in'
        />
      )}
      <CButton title='Salvar' cstyle='primary small' />
    </WrapForm>
  );
}

import React from 'react';

import { sessionGet } from '../../session';


import LogoTask from '../../assets/images/logotask.png';
import LogoMerx from '../../assets/logo_branca.png';

import { Wrap, Banner, WrapForm, Form, Button } from './styles';

function Erro404() {
  return (
    <Wrap>
      <Banner>
        <div className="title">
          <img src={LogoMerx} className="logo-merx" alt="Logo Merx" title="Merx" onClick={() => window.location = '/'} />
          <img src={LogoTask} className="logo-task" alt="Logo Task" title="Task" onClick={() => window.open('https://task.com.br')} />
          <img src={process.env.PUBLIC_URL + '/404error.png'} className="erro-404" alt="erro 404" />
        </div>
      </Banner>
      <WrapForm>
        <Form>
          <h3>Página não encontrada</h3>
          <img src={process.env.PUBLIC_URL + '/404error.png'} className="erro-404-mobile" alt="erro 404" />
          <div className="messages">
            <span>ERRO 404</span>
            <small>Ops... Parece que um erro ocorreu! :(</small>
          </div>
          <Button href={(sessionGet('planoEmpresa')) === 'merxGratis' ? '/painel-de-controle' : '/feed'}>Voltar ao início</Button>
        </Form>
      </WrapForm>
    </Wrap>
  );
}

export default Erro404;
import React, { useEffect, useState } from 'react';

import { Content, Modal, Container } from './styles';

import api from '../../services/api-geral';

import { RiImageLine, RiFacebookLine, RiInstagramLine, RiTwitterLine, RiWhatsappLine, RiYoutubeLine, RiLinkedinLine } from 'react-icons/ri';
import { FiMessageSquare, FiMapPin } from 'react-icons/fi';
import { AiOutlineMail } from "react-icons/ai";
import { RiSubtractFill, RiCloseFill } from 'react-icons/ri';


import getFiles from '../../util/getFiles';

function PreviewMobile({ tab, socials, chat, whatsapp, hasAddress, address, buttonName, hasMap, map, formulario, links, redesIcones, Cor1, Cor2, Cor3, Cor4, Cor5, Cor6, Cor7, Cor8, Cor9, Cor10, Cor11, Cor12, Cor13, filter, borda, background, foto, title, description, idPage, hasImg, tel }) {
  const [cor1, setCor1] = useState();   // Cor Botão Chat
  const [cor2, setCor2] = useState();   // Cor Fonte dos links
  const [cor3, setCor3] = useState();   // Cor Fundo dos links
  const [cor4, setCor4] = useState();   // Cor Filtro
  const [cor5, setCor5] = useState();   // Cor Borda de perfil
  const [cor6, setCor6] = useState();   // Cor Fundo da página
  const [cor7, setCor7] = useState();   // Cor Botão Fale Conosco
  const [cor8, setCor8] = useState();   // Cor Redes sociais
  const [cor9, setCor9] = useState();   // Cor Título e descrição
  const [cor10, setCor10] = useState(); // Cor Fonte dos botões
  const [cor11, setCor11] = useState(); // Cor Fonte Fale Conosco
  const [cor12, setCor12] = useState(); // Cor Fundo do Fale Conosco
  const [cor13, setCor13] = useState(); // Cor Botão de envio Fale Conosco

  const [filtro, setFiltro] = useState(false);
  const [border, setBorder] = useState(false);
  const [bg, setBg] = useState();
  const [perfil, setPerfil] = useState('');
  const [titulo, setTitulo] = useState();
  const [descricao, setDescricao] = useState();
  const [flagRedes, setFlagRedes] = useState();
  const [flagChat, setFlagChat] = useState();
  const [flagForm, setFlagForm] = useState();
  const [linksPreview, setLinksPreview] = useState([]);
  const [socialNetworks, setSocialNetworks] = useState([]);
  const [hImg, setHImg] = useState('');
  const [wpp, setWpp] = useState();
  const [telefone, setTelefone] = useState('');
  const [flagEndereco, setFlagEndereco] = useState();
  const [endereco, setEndereco] = useState();
  const [stateButtonName, setButtonName] = useState();
  const [flagMapa, setFlagMapa] = useState();
  const [mapa, setMapa] = useState();
  const [previewMapa, setPreviewMapa] = useState();
  const [showMapa, setShowMapa] = useState(false);
  const [showModalFaleConosco, setShowModalFaleConosco] = useState(false);

  const linksComIcones = ["amazon", "americanas", "apple", "deezer", "facebook", "g1", "instagram", "magazineluiza", "mercadolivre", "r7", "shein", "shopee", "spotify", "submarino", "tiktok", "twitter", "twitch", "youtu"]

  const socialIcons = {
    facebook: <RiFacebookLine />,
    instagram: <RiInstagramLine />,
    twitter: <RiTwitterLine />,
    whatsapp: <RiWhatsappLine />,
    youtube: <RiYoutubeLine />,
    linkedin: <RiLinkedinLine />,
    mail: <AiOutlineMail />
  }

  useEffect(() => {
    getPageInfo();
  }, [])

  useEffect(() => {
    if (tab === 'cores') {
      setCor1(Cor1);
      setCor2(Cor2);
      setCor3(Cor3);
      setCor4(Cor4);
      setCor6(Cor6);
      setCor7(Cor7);
      setCor8(Cor8);
      setCor9(Cor9);
      setCor10(Cor10);
      setCor11(Cor11);
      setCor12(Cor12);
      setCor13(Cor13);
    } else if (tab === 'aparencia') {
      setCor5(Cor5);
    } else if (tab === 'chat') {
      setTelefone(tel);
      setEndereco(address);
      setButtonName(buttonName)
      setMapa(getMap());
    } else {
      getPageInfo();
    }
  }, [Cor1, Cor2, Cor3, Cor4, Cor5, Cor6, Cor7, Cor8, Cor9, Cor10, Cor11, Cor12, Cor13, tel, address, buttonName])

  useEffect(() => {
    if (tab === 'cores') {
      if (background !== null) {
        setBg(getFiles(background));
        setFiltro(filter);
      }
      setHImg(hasImg);
    } else if (tab === 'aparencia') {
      setBorder(borda);
      if (foto !== null && foto !== '') {
        setPerfil(getFiles(foto));
      } else {
        setPerfil(null);
      }
      setTitulo(title);
      setDescricao(description);
    } else {
      getPageInfo();
    }
  }, [filter, borda, background, foto, title, description, hasImg])

  useEffect(() => {
    if (socials === true) {
      setFlagRedes(true)
    } else {
      setFlagRedes(false)
    }
  }, [socials])

  useEffect(() => {
    if (whatsapp === true) {
      setWpp(true)
    } else {
      setWpp(false)
    }
  }, [whatsapp])

  useEffect(() => {
    if (hasAddress === true) {
      setFlagEndereco(true);
    } else {
      setFlagEndereco(false);
    }
  }, [hasAddress])

  useEffect(() => {
    if (hasMap === true) {
      setFlagMapa(true);
    } else {
      setFlagMapa(false);
    }
  }, [hasMap])

  useEffect(() => {
    if (chat === true) {
      setFlagChat(true)
    } else {
      setFlagChat(false)
    }
  }, [chat])

  useEffect(() => {
    if (formulario === true) {
      setFlagForm(true)
    } else {
      setFlagForm(false)
    }
  }, [formulario])

  useEffect(() => {
    getLinks();
    getSocialNetworks();
  }, [idPage, links, redesIcones])

  useEffect(() => {
    getMap();
  }, [map])

  const getLinks = async () => {
    if (idPage) {
      const response = (await api.get(`/links/${idPage}`)).data;
      setLinksPreview(response);
      if (response.length >= 3) {
        setShowMapa(false);
      } else { setShowMapa(true); }
    }
  };

  const getMap = async () => {
    const response = (await api.get(`/page`)).data;
    setMapa(response.mapa)
    setPreviewMapa(response.mapa.substring(
      response.mapa.indexOf('"') + 1,
      response.mapa.lastIndexOf(' w') - 1
    ))
  }

  const getSocialNetworks = async () => {
    if (idPage) {
      const response = (await api.get(`/social-networks/${idPage}`)).data;
      setSocialNetworks(response);
    }
  };

  const getPageInfo = async () => {
    const response = (await api.get('page')).data;
    if (response) {
      setCor1(response.colorPrimary);
      setCor2(response.colorSecondary);
      setCor3(response.colorTertiary);
      setCor4(response.colorQuaternary);
      setCor5(response.colorBorder);
      setCor6(response.colorBackground);
      setCor7(response.colorForm);
      setCor8(response.colorNetworks);
      setCor9(response.colorDescription);
      setCor10(response.colorBtnfont);
      setCor11(response.colorFontForm)
      setCor12(response.colorbgform);
      setCor13(response.colorbtnform);
      setFiltro(response.flagBackgroundFilter);
      setBorder(response.flagProfileBorder);
      setWpp(response.flagWhatsapp);
      setFlagEndereco(response.flagEndereco);
      setFlagMapa(response.flagMapa);
      if (response.image_background !== null) {
        setBg(getFiles(response.image_background.path));
        setHImg('image');
      }
      if (response.image_logo !== null) {
        setPerfil(getFiles(response.image_logo.path));
      } else {
        setPerfil(null);
      }
      setTitulo(response.title);
      setDescricao(response.description);
      setFlagRedes(response.flagSocials);
      setFlagChat(response.flagChat);
      setFlagForm(response.flagForm);

      var telf = response.whatsapp;
      setTelefone(telf);
      setEndereco(response.endereco);
      setButtonName(response.buttonName)
      setMapa(response.mapa);
    }
  }

  const checkSocialNetwork = (type) => {
    if (type === 'facebook') {
      return socialIcons.facebook
    } else if (type === 'instagram') {
      return socialIcons.instagram
    } else if (type === 'twitter') {
      return socialIcons.twitter
    } else if (type === 'whatsapp') {
      return socialIcons.whatsapp
    } else if (type === 'youtube') {
      return socialIcons.youtube
    } else if (type === 'mail') {
      return socialIcons.mail
    }
    else {
      return socialIcons.linkedin
    }
  }

  const showMapaFromIcon = () => {
    setShowMapa(!showMapa)
  }

  const iconsName = (link) => {
    let closestMatchIndex = -1;
    let closestMatchLength = 0;

    linksComIcones.forEach((option, index) => {
      if (link.indexOf(option) !== -1 && option.length > closestMatchLength) {
        closestMatchIndex = index;
        closestMatchLength = option.length;
      }
    });
    return linksComIcones[closestMatchIndex]
  }

  const OpenCloseModal = () => {
    setShowModalFaleConosco(!showModalFaleConosco);
  }

  return (
    <Content
      Cor1={cor1}
      Cor2={cor2}
      Cor3={cor3}
      Cor4={cor4}
      Cor5={cor5}
      Cor6={cor6}
      Cor7={cor7}
      Cor8={cor8}
      Cor9={cor9}
      Cor10={cor10}
      filter={filtro}
      borda={border}
      Background={bg}
      hasImg={hImg}
    >
      <div className="background"></div>
      <div id="img-filter" className="preview">
        <div className="content-preview">
          <div className="logo-preview">
            <div className="image-border-preview">
              <div className='image-holder-preview'>
                {(perfil === '' || perfil === undefined || perfil === null) ?
                  <RiImageLine /> : <img src={perfil} />
                }
              </div>
            </div>
          </div>
          <div className="info-preview">
            <span>{titulo}</span>
            <p>{descricao}</p>
          </div>
          <div className="links-preview">
            {linksPreview.length > 0 ?
              linksPreview.map(link => (
                <>
                  <div key={link.title}>
                    {linksComIcones.some(value => link.link.includes(value)) &&
                      (link.flag_icon &&
                        <img src={`https://img.merx.app.br/images/icones-minisite/${iconsName(link.link)}.webp`} />
                      )
                    }
                    <div className='desc-item'>
                      <span className={iconsName(link.link) && (link.flag_icon) ? 'font-link' : ''}>{link.title}</span>
                    </div>
                  </div>

                </>
              )) :
              <>
                <div><span>Exemplo 1</span></div>
                <div><span>Exemplo 2</span></div>
                <div><span>Exemplo 3</span></div>
              </>
            }
            {(flagForm === true) &&
              <div className="link-fale-conosco" onClick={OpenCloseModal}>
                <span className='button-fale-conosco'>{stateButtonName}</span>
                {showModalFaleConosco &&
                  <Modal>
                    <Container Cor11={cor11} Cor12={cor12} Cor13={cor13} Cor9={cor9}>
                      <button className="closeModal" onClick={OpenCloseModal}><RiCloseFill /></button>
                      <h2>{stateButtonName}</h2>
                      <p>Deixe sua mensagem. Em breve, entraremos em contato.</p>
                      <form>
                        <input type="text" placeholder="Nome completo" name="nome" />
                        <input type="text" placeholder="E-mail" name="e-mail" />
                        <input type="phone" placeholder="Telefone" name="telefone" />
                        <select className="select" >
                          <option value={0}>Selecione o assunto</option>
                        </select>
                        <textarea placeholder="Sua mensagem aqui..." rows={5} name="mensagem" />
                        <input type="number" placeholder={"Quanto é 5 + 1? (Pergunta de segurança)"} name="pergunta" />
                        <div className="wrap-buttons">
                          <button disabled>Enviar</button>
                        </div>
                      </form>
                    </Container>
                  </Modal>
                }
              </div>}
            {(flagMapa === true) &&
              <>
                {(showMapa === true) &&
                  <div className="mapa-preview">
                    <iframe title="Google Maps - Localização" src={previewMapa} ></iframe>
                  </div>
                }
                <div className="mapa-icon-preview">
                  <div className="mapa-icon">
                    {!showMapa && <FiMapPin onClick={showMapaFromIcon} />}
                    {showMapa && <RiSubtractFill onClick={showMapaFromIcon} />}
                  </div>
                </div>
              </>
            }
          </div>
          {(flagChat === true) &&
            <div className="chat-preview">
              <FiMessageSquare />
            </div>
          }
          <div className="footer">
            {flagEndereco && <div className="wpp-preview">{endereco}</div>}
            {wpp && <div className="wpp-preview">{telefone}</div>}
            {flagRedes && (
              <div className="redes-preview">
                {socialNetworks.map(social => (
                  checkSocialNetwork(social.type)
                ))}
              </div>)}
          </div>
        </div>
      </div >
      <img src={process.env.PUBLIC_URL + '/celularpreview.png'} />
    </Content >
  );
}

export default PreviewMobile;
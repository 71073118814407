import axios from 'axios';
import { sessionGet } from '../session';
import { apiGeral } from '../config';

export default axios.create({
    headers: {
        'x-access-token': sessionGet('tokenUser') || "",
        'Content-Type': 'multipart/form-data',
    },
    baseURL: apiGeral
});
import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';

import log from '../../../util/log';
import ApiGeral from '../../../services/api-geral';

import { WrapForm } from './styles';

import CInput from '../../ComponentInput';
import CSelect from '../../ComponentSelect';
import CButton from '../../ComponentButton';

import Swal from 'sweetalert2';

export default function Form({ item, success, close, setHasChangesModal }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [font, setFont] = useState('');
  const [fontOptions] = useState([
    { name: 'Chat com cliente' },
    { name: 'Formulário do fale conosco' },
    { name: 'Cadastro' }
  ]);

  useEffect(() => {
    setName(item.name);
    setEmail(item.email);
    setPhone(item.phone);
    setFont(item.font);
  }, [item]);

  useEffect(() => {
    if (name !== undefined || email !== undefined || phone !== undefined || font !== undefined) {
      setHasChangesModal(true);
    } else {
      setHasChangesModal(false);
    }
  }, [name, email, phone, font])

  const submitForm = async e => {

    e.preventDefault();

    if (item.id) {
      const response = (await ApiGeral.put(`/contato/${item.id}`, { name, email, phone, font })).data;

      if (response.type === "success") {
        Swal.fire({
          title: 'Sucesso!',
          text: response.msg,
          icon: 'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok'
        })
        log(`editou as informações do contato com nome ${name}.`);
        success();
        close();
      } else {
        Swal.fire({
          title: 'Erro!',
          text: response.msg,
          icon: 'error',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok'
        })
        log(`tentou editar as informações do contato com nome ${name}, mas houve um erro.`);
      }
    } else {
      const response = (await ApiGeral.post('/contato', { name, email, phone, font })).data;

      if (response.type === "success") {
        Swal.fire({
          title: 'Sucesso!',
          text: response.msg,
          icon: 'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok'
        })
        log(`cadastrou um novo contato com nome ${name}.`);
        success();
        close();
      } else {
        Swal.fire({
          title: 'Erro!',
          text: response.msg,
          icon: 'error',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok'
        })
        log(`tentou cadastrar um novo contato mas houve um erro.`);
      }
    }
  };

  return (
    <WrapForm onSubmit={submitForm}>
      <div className="form-header">
        <h2>{`${(item.id) ? 'Edição' : 'Cadastro'} de contato`}</h2>
      </div>
      <div className="form-body">
        <CInput focused="true" val={name} change={e => setName(e)} type='text' label='Nome' required={true} />
        <CInput val={email} change={e => setEmail(e)} type='text' label='E-mail' required={true} />
        <CInput val={phone} change={e => setPhone(e)} type='text' label='Telefone' required={true} mask="phone" />
        <CSelect val={font} change={e => setFont(e)} items={fontOptions} label='Fonte' arrayFixo={false} required={true} />
      </div>
      <div className="form-footer">
        <CButton type="submit" title='Salvar' cstyle='primary small' />
      </div>
    </WrapForm>
  );
}

import styled from "styled-components";

import colors from "../../presets/colors";
import effects from '../../presets/effects';

export const Container = styled.div`
  height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${colors.border};
    border-radius: ${effects.radius};
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .content-painel {
    margin-top: 25px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 575px) {
      padding: 0 15px;
    }
  }

  .input-style {
    margin: 15px 0;
  }
`;

export const Configuracao = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
`;

export const FormConfiguracao = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .wrap-relative {
    position: relative;
  }

  .wrap-input {
    width: 300px;
  }

  .wrap-btn {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 15px;

    a {
      font-weight: bold;
      text-decoration: none;
      color: ${colors.primary};
      margin-top: 10px;
      display: flex;
    }
  }

  .wrap-alert {
    margin-bottom: 20px;
  }

  .wrap-drop {
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;

    .teste {
      background-color: red;
    }

    .sem-imagem-perfil {
      height: 120px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      width: 120px;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .container-filter {
      height: 120px;
      width: 120px;
      position: relative;

      .img-filter {
        position: absolute;
        background-color: #bdbdce96;
        border-radius: 50%;
        height: 120px;
        width: 120px;
        display: flex;
        opacity: 0;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: opacity 0.2s ease-in-out;

        &:hover {
          opacity: 100;
        }

        span {
          font-size: 12px;
          color: #1a202e;
          font-weight: bold;
        }
      }

      .imagem-perfil {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    #fileinput {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    .wrap-input,
    .wrap-drop {
      width: 100%;
    }

    .content-painel {
    }

    .wrap-btn {
      justify-content: center;
    }
  }
`;

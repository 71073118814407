import React, { useEffect, useState } from 'react';
import emojis from '../../../util/emojis';

import socket from '../../../services/socket';
import { sessionGet } from '../../../session';
import api from '../../../services/api-geral'

import {Emoji, Container, NoteContainer, Content, Title, Noteviewer, AreaToClose } from './styles';
import Option from './Option';
import Lembretes from '../ListaLembretes';
import log from '../../../util/log';
import { RiInformationLine } from 'react-icons/ri';
import ModalEdit from '../ModalEdit';

function LembretesPost() {
  const [noteType, setNoteType] = useState('');
  const [extraType, setExtraType] = useState('');
  const [startDate, setstartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [note, setNote] = useState('');
  const [lembretes, setlembretes] = useState([]);
  const [editedNote, setEditedNote] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [confirmaEdit, setConfirmaEdit] = useState(false);

  const userId = sessionGet('idUser')
  const idEmpresa = sessionGet('idEmpresa');

  // Limita a data end para não ser possivel escolher uma data antes do dia atual
  const today = new Date();
  const date = `${today.getFullYear()}-${String(today.getMonth() + 1)
                .padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;

  const isButtonDisabled = noteType === 'Tipo de lembrete' || !startDate || !endDate || !note || noteType === 'Outro' && !extraType ; 

  useEffect(() => {
    getNotes()
  },[])

  useEffect(() => {
    socket.on('editNote', (reminder) => {
        getNotes()
    });
    return () => {
        socket.off('editNote');
    };
}, []);

  const infoOption = (info) => {
    setNoteType(info)
  }

  const insertEmoji = (emoji) => {
    setNote((prevMessage) => prevMessage + emoji.character);
    handleClickEmoji()
  };
  const handleClickEmoji = () => {
    setIsOpen(!isOpen);
  };
  const clickToExit = (e) => {
    setIsOpen(!isOpen);
	}
  const openEdit = () => {
    setConfirmaEdit(!confirmaEdit);
  };
  const handleStarDate = (event) => {
    const novaData = event.target.value;
    setstartDate(novaData);
  };
  const handleEndDate = (event) => {
    const novaData = event.target.value;
    setEndDate(novaData);
  };

  const getNotes = async () => {
    try {
        const response = await api.get(`/lembretes/${idEmpresa}`).then(({ data }) => {
          setlembretes(data.lembretes)    
        })
    } catch (error) {
        console.error("Error getting data: ", error);
    }            
  }

  const sendingPostId = async (id_post) => {
    try {
      const response = await api.post(`/lembrete-notify`, { id_post, idEmpresa });
     
    } catch (error) {
        console.error("Error posting data: ", error);
    }            
  }
  
  const savePost = async () => {
    try {
        const response = await api.post(`/lembrete`, { userId, idEmpresa, note, noteType, extraType,  endDate, startDate });
        log('criou um lembrete.');
        setNoteType("Tipo de lembrete");
        setstartDate('');
        setEndDate('');
        setNote('');
        setExtraType('');
        getNotes()
        sendingPostId(response.data.id)

    } catch (error) {
        console.error("Error posting data: ", error);
    }            
 
  }

  const deleteLembrete = async (data) => {
    try {
        const response = await api.delete(`/lembrete/${data.id}/${data.id_empresa}`);
        log('deletou um lembrete');

    } catch (error) {
        console.error("Error posting data: ", error);
    }            

  }

  return (
    <>
      
       {confirmaEdit && ( <ModalEdit setConfirmaEdit={setConfirmaEdit} note={editedNote} closeModal={() => { setConfirmaEdit(false) }}/>)}
     <Container >
     {isOpen && <Emoji>
                    <div >
                    <button onClick={handleClickEmoji}>x</button>
                    </div>
                    <ul>
                      {emojis.map((emoji, index) => (
                        <li key={index} onClick={() => insertEmoji(emoji)}>
                          {emoji.character}
                        </li>
                      ))}
                    </ul>
                 <AreaToClose onClick={(e) => { clickToExit(e) }} />
              </Emoji>
              }
        <Content>
          <div className='wrap-content'>
            <NoteContainer  >
              <div className='wrap-for-merxgratis'>
              <Title>Lembrete</Title> 
              { sessionGet('planoEmpresa') === 'merxGratis' ?<small> Essa funcionalidade está disponível no plano grátis até dia 29/03/2024.</small>
                                                            : ' ' }
              </div>
                <div className="text-input">         
                  <textarea 
                    className='text'
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    placeholder="Escreva uma mensagem..."
                    maxLength={150}
                  />
                  <button onClick={handleClickEmoji}>
                  🙂
                  </button>
                </div>                    
                
              <div className="footer">
                <div className="time" >
                  <Title>Tempo Ativo </Title>

                  <div className='date-wrap'>
                    <div className='date-container'>
                    <label htmlFor="">inicio: 
                        <div className='info-wrap'>
                        <RiInformationLine /> 
                        <div className='date-info' >
                          <p> Define quando o lembrete ficara disponivel para os usuarios da empresa visualizarem</p>
                        </div>
                        </div>    
                      </label>
                      <input type="date" value={startDate} className="input-date" onChange={handleStarDate} />
                    </div>
                    <div className='date-container'>
                      <label htmlFor="">final: 
                        <div className='info-wrap'>
                          <RiInformationLine /> 
                            <div className='date-info' >
                              <p> É como um prazo de validade, quando essa data chegar o lembrete será excluído automaticamente</p>
                            </div>
                        </div>
                      </label>
                      <input type="date" min={date}  value={endDate} className="input-date" onChange={handleEndDate} />
                    </div>
                   </div>
          
                </div>
                <div className='wrap'> 

                <div className="type" >
                  <Title>Tipo</Title>
                  <Option TypeOfNote={infoOption} initialValue='Tipo de lembrete'/>
                  {noteType === 'Outro' ? (
                    <> 
                    <input type="text" value={extraType} onChange={(e) => setExtraType(e.target.value)}/>
                    </>  
                  ) :   
                  <> 
                  <input type="button" className="input-nada" value=' ' />
                  </> }
                </div>
                <div className="button-div">
                <button disabled={isButtonDisabled} className={isButtonDisabled ? ' disabled' : 'create-button'} onClick={savePost}  >Criar</button>
                </div>

                </div>
              </div>
            </NoteContainer>
          </div>

          <div className='wrap-notes'>
            {lembretes.length > 0 ?
                  <Noteviewer>
                  {lembretes.map((data) => (
                    <Lembretes key={data.id} lembrete={data} icon={true} dates={true} editNote={() =>{setEditedNote(data);}} deletar={() =>{deleteLembrete(data)}} setConfirmaEdit={openEdit} reload={getNotes}/>
                   ))}
      
                  </Noteviewer>
                  : "Não há nenhum lembrete no momento"
            }
      
          </div>
     
        </Content>
      </Container>
    </>
  );
}

export default LembretesPost;
import styled from 'styled-components';

import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Wrap = styled.section`
    width:100%;
    height:100%;
    display: flex;

    @media(max-width: 1024px) {
        flex-direction: column;
    }
`;

export const Banner = styled.div`
    width: 40vw;
    height: 100vh;
    background-color: ${colors.primary};
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media(max-width: 1024px) {
        display: none;  
    }

    .title {
        width: 60%;
        height: 65vh;
        box-shadow: ${effects.boxshadow};
        background-color: ${colors.primary};
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        @media (max-width: 1440px) {
            height: 85vh;

        }

        .logo-merx {
            width: 100px;
            cursor: pointer;
            position: absolute;
            top: 20px;
            left: 20px;
        }

        .logo-task {
            cursor: pointer;
            width: 75px;
            opacity: 0.75;
            position: absolute;
            left: 25px;
            bottom: 25px;
        }

        .erro-404 {
            width: 300px;

            @media (max-width: 1400px) {
                width: 200px;
            }
        }
    }
`;

export const WrapForm = styled.div`
    width: 60vw;
    height: 100vh;
    display: flex;
    align-items: center;

    @media (max-width: 1024px) {
        width: 100%;
    }
`;


export const Form = styled.form`
    width: 60%;
    height: 65vh;
    box-shadow: ${effects.boxshadow};
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    padding: 50px 100px;

    @media (max-width: 1024px) {
        padding: 20px;
    }

    .erro-404-mobile {
        display: none;
        width: 200px;

        @media (max-width: 1024px) {
            display: block;
        }
    }

    .messages {
      display: flex;
      flex-direction: column;
      text-align: center;

      span {
        font-size: 50px;
        margin-bottom: 10px;
        font-weight: bold;
        color: ${colors.primary};
      }

      small {
          font-size: 14px;
          color: ${colors.primary};
      }
    }

    h3 {
      font-sizE: 25px;
      color: ${colors.primary};
    }

    @media (max-width: 1440px) {
        width: 80%;
        height: 85vh;

        @media (max-width: 1024px) {
            width: 100%;
            box-shadow:  none;

            .wrap-utilities {
                width: 80%;
            }
        }
    }

    @media only screen and (max-width: 801px) and (min-width: 425px)  {
      width: 100%;
    }
`;

export const Input = styled.div`
    background-color: transparent;
    padding: 0 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: bold;
    width: 50%;
    height: 50px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    position: relative;
    margin-bottom: 40px;
    transition: border-color .5s;

    @media (max-width: 1024px) {
        width: 80%;
    }

    input {
        width: 100%;
        height: 100%;
        border: 0;
        background-color: transparent;

        &:not(:placeholder-shown):not(:focus) ~ label {
            top: -15px;
            left: 0;
        }

        &:not(:placeholder-shown) ~ label {
            top: -15px;
            left: 0;
        }
    }

    label {
        position: absolute;
        display: block;
        height: 20px;
        color: ${colors.primary};
        font-weight: bold;
        transition: all 1s;
        top: -15px;
        left: 0;
    }

    &:focus-within label {
        top: -15px;
        color: ${colors.primary};
        left: 0;
    }

    &:focus-within {
        border-color: ${colors.primary};
    }
`;

export const Checkbox = styled.div`
    width: 20px;
    height: 20px;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin-right: 5px;
    cursor: pointer;
`;

export const Button = styled.a`
    width: 180px;
    height: 40px;
    color: white;
    display: flex;
    align-items: center;
    place-content: center;
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;
    background-color: ${colors.primary};
    border-radius: 25px;
    border: 0;
    letter-spacing: 1px;
    margin-bottom: 10px;
`;

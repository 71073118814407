import React, { useEffect, useRef, useState } from 'react';
import api from '../../services/api-geral';
import socket from '../../services/socket';
import { sessionGet, clearSession } from '../../session';

import { ButtonScroll, Container, Side, Content, InputSearch, LoadingWrap, UpdateFeedButton } from './styles';
import { RiRefreshLine } from 'react-icons/ri';
import { FaArrowUp, FaRedo } from "react-icons/fa";
import { MdSearch } from 'react-icons/md';

import NFormFeed from '../NFormFeed';
import NPostFeed from '../NPostFeed';
import SocketPostsFeed from '../../socket/SocketPostsFeed';

import ModalPostFeed from '../ModalPostFeed';
import ModalShowImage from '../ModalShowImage';

import getParams from '../../util/params';

import log from '../../util/log';

function NFeed({ hasChangesModal, setHasChangesModal, setImageId, setImagePath }) {
    const [posts, setPosts] = useState([]);
    const [searchPosts, setSearchPosts] = useState([]);
    const [images, setImages] = useState([]);
    const [imagesSearch, setImagesSearch] = useState([]);
    const [page, setPage] = useState(0);
    const [countPost, setCountPost] = useState(0);
    const [showExplorer, setShowExplorer] = useState(false);

    const [showBtnScroll, setShowBtnScroll] = useState(0);
    const [elementFeed, setElementFeed] = useState('');

    const [postType, setPostType] = useState();

    const [editedImage, setEditedImage] = useState({});
    const [editedPost, setEditedPost] = useState({});

    const [showModalEdit, setShowModalEdit] = useState(false);

    const [showModalImage, setShowModalImage] = useState(false);
    const [pathImage, setPathImage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [search, setSearch] = useState('');
    const [searchAll, setSearchAll] = useState('');
    const [loading, setLoading] = useState(false);
    const [updateFeedButton, setUpdateFeedButton] = useState(false);
    const lastPostRef = useRef()

    useEffect(() => {
        changePage(0);
        socket.emit("entrarSala", { room: `feed${sessionGet('idEmpresa')}` });

        return () => {
            socket.emit('sairSala', { room: `feed${sessionGet('idEmpresa')}` });
        }
    }, []);

    useEffect(() => { loadPosts() }, [page]);


    const loadPosts = () => {
        setIsLoading(true);

        api.get(`/posts/${page}`).then(({ data }) => {
            setImages(data.images);
            setCountPost(data.countPosts);

            if (page === 0) {
                setPosts([]); setPosts(data.posts);
                setImages([]); setImages(data.images);
            }
            else {
                setPosts([...posts, ...data.posts]);
                setImages([...images, ...data.images]);
            };

        }).catch(error => {
            clearSession();
        });

        setIsLoading(false);
    }

    const deletePost = async (post) => {
        const response = (await api.delete(`/posts/${post.id}`)).data;
        log('excluiu um post no feed.');
    }


    const changePage = numberPage => setPage(numberPage);

    const getElementFeed = (e) => {
        setElementFeed(e);

        if (e.scrollTop > 200) {
            setShowBtnScroll(true);
        } else {
            setShowBtnScroll(false);
        }
    }

    const scrollFeed = () => {
        if (elementFeed.scrollTop > 1) elementFeed.scrollTo(0, 0);
    }

    useEffect(() => {
        if (search !== '') {
            setLoading(true);
        }
        const debounce = setTimeout(() => {
            setSearchAll(search);
            if (search !== '') {
                getSearchPosts();
            }
            setLoading(false);
        }, 1500)

        return () => clearTimeout(debounce)
    }, [search])

    const getSearchPosts = async () => {
        const response = (await api.get(`/posts-search/${search}`)).data

        setSearchPosts(response.posts);
        setImagesSearch(response.images);
    }

    useEffect(() => {

        const options = {
            root: null, 
            rootMargin: '0px',
            threshold: 0.2, // 70% de interseção necessária
          };
      
          const callback = (entries, observer) => {
            entries.forEach(entry => {
              if (entry.isIntersecting && posts.length !== countPost) {
                changePage(page + 1)

                const localLastPostRef = lastPostRef.current;
                if (localLastPostRef) {
                    observer.unobserve(localLastPostRef);
                }
              }
            });
          };
      
          const observer = new IntersectionObserver(callback, options);
      
          if (lastPostRef.current) {
            observer.observe(lastPostRef.current);
          }
      
          return () => {
            if (lastPostRef.current) {
              observer.unobserve(lastPostRef.current);
            }
          };
    },[posts])

    return (
        <>
            <SocketPostsFeed setUpdateFeedButton={setUpdateFeedButton} />

            {showModalEdit &&
                <ModalPostFeed
                    post={editedPost}
                    showExplorer={showExplorer}
                    setShowExplorer={setShowExplorer}
                    image={editedImage}
                    closeModal={() => { setShowModalEdit(false) }}
                    reload={loadPosts}
                    getPosts={() => { setPage(0); loadPosts(); }}
                    type={postType}
                    hasChangesModal={hasChangesModal}
                    setHasChangesModal={setHasChangesModal}
                    setImageId={setImageId}
                    setImagePath={setImagePath}
                />
            }

            {showModalImage && <ModalShowImage img={pathImage} close={() => setShowModalImage(false)} />}

            {(showBtnScroll) && (
                <ButtonScroll onClick={() => scrollFeed()}>
                    <FaArrowUp />
                </ButtonScroll>
            )}

            {updateFeedButton && (
                <UpdateFeedButton onClick={() => { scrollFeed(); loadPosts(); setPage(0); setHasChangesModal(false); setUpdateFeedButton(false) }}>
                    <FaArrowUp /> Atualizaçoes no feed
                </UpdateFeedButton>
            )}

            <Container onScroll={e => getElementFeed(e.target)}>
                <Content>
                    <InputSearch>
                        <MdSearch />
                        <input placeholder="Pesquisar no feed..." onChange={e => setSearch(e.target.value)} />
                    </InputSearch>
                    <NFormFeed criar={(sExplorer = false) => { setShowModalEdit(true); setPostType("new"); setEditedImage({}); setShowExplorer(sExplorer); }} setPosts={setPosts} />
                    {loading === true ? <LoadingWrap><FaRedo className="fa-spin" /><h3>Carregando...</h3></LoadingWrap> :
                        searchAll !== '' ?
                            searchPosts.map((post, index) => (
                                <NPostFeed
                                    key={post.id}
                                    image={imagesSearch[index]}
                                    post={post}
                                    editar={() => { setEditedPost(post); setEditedImage(imagesSearch[index]); setShowModalEdit(true); setPostType("edit") }}
                                    deletar={() => { deletePost(post) }}
                                    modalImage={path => { setPathImage(path); setShowModalImage(true); }}
                                    setHasChangesModal={setHasChangesModal}
                                    setImageId={setImageId}
                                    setImagePath={setImagePath}
                                    reload={loadPosts}
                                />
                            )) :
                            posts.map((post, index) => (
                                <NPostFeed
                                    key={post.id}
                                    image={images[index]}
                                    post={post}
                                    editar={() => { setEditedPost(post); setEditedImage(images[index]); setShowModalEdit(true); setPostType("edit") }}
                                    deletar={() => { deletePost(post) }}
                                    modalImage={path => { setPathImage(path); setShowModalImage(true); }}
                                    setHasChangesModal={setHasChangesModal}
                                    setImageId={setImageId}
                                    setImagePath={setImagePath}
                                    reload={loadPosts}
                                    ref={index === posts.length - 2 ? lastPostRef : undefined}
                                />
                            ))
                    }
                </Content>
            </Container>
        </>
    );
}

export default NFeed;
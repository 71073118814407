import styled from 'styled-components';

export const Container = styled.div`

`;

export const Configuracao = styled.div`

.helper {
    display: flex;
    justify-content: flex-end;

    .icone-ajuda {
      position: absolute;
      cursor: pointer;
    }
  }
    form {
        text-align: center;
        padding: 20px;

        .div-salvar {
            display: flex;
            justify-content: center;
        }
    }
`;

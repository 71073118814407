import React, { useEffect, useState, memo } from 'react';
import getExtension from '../../util/getExtension';
import api from '../../services/api-geral';

import {
  RiFolderOpenLine,
  RiFileList2Line,
  RiImage2Line,
  RiCodeBoxLine,
  RiMore2Line,
  RiCheckLine,
  RiDeleteBinLine
} from 'react-icons/ri';

import { Container } from './styles';

function ItemCentralMidia({ item, changeFolder, viewFile, getDiretory, deleteFolder }) {
  const [showOptions, setShowOptions] = useState(false);
  const [title, setTitle] = useState('');
  
  const [icons] = useState({
    'jpg': (<RiImage2Line />),
    'png': (<RiImage2Line />),
    'jpeg': (<RiImage2Line />),
    'pdf': (<RiFileList2Line />),
    'docx': (<RiFileList2Line />),
    'doc': (<RiFileList2Line />),
    'docm': (<RiFileList2Line />),
    'js': (<RiCodeBoxLine />),
    'php': (<RiCodeBoxLine />),
    'css': (<RiCodeBoxLine />),
    'html': (<RiCodeBoxLine />),
    'cs': (<RiCodeBoxLine />),
  });
  
  useEffect(() => {
    setTitle(item.title);
  }, [item]);

  useEffect(() => {
    if(showOptions) {
      document.getElementById('input_title').focus();
    }
  }, [showOptions]);

  const handleChangeTitleFolder = async (e='') => {
    if(e !== '')
      e.preventDefault();

    if(title !== '') {
      const response = (await api.put(`/pastas/${item.id}`, { name: title })).data;
      if(response.type === "success") {
        getDiretory();
        setShowOptions(false);
      } else {
        alert(response.msg);
      }
    } else {
      alert("O nome não pode estar vazio!!");
    }
  };

  return (
    <Container className={item.type}>
      <div onClick={() => item.type === 'folder' ? changeFolder(item.id) : viewFile() } className={`info-item ${item.type === 'file' && 'full'}`}>
        {item.type === 'folder' ? <RiFolderOpenLine /> : icons[getExtension(item)] }
        {!showOptions && item.title}
        {showOptions && <form className="form-edit-folder" onSubmit={handleChangeTitleFolder}><input type="text" id="input_title" onClick={e => e.preventDefault() } value={title} onChange={e => setTitle(e.target.value) } /></form>}
      </div>

      {item.type === 'folder' && item.title != '../' && (
        <label onClick={e => { 
          setShowOptions(true); 
        }} className="btn-edit">
          <RiMore2Line />
        </label>
      )}

      {item.type === 'folder' && showOptions && (
        <>
          <div onClick={() => setShowOptions(false)} className="backdrop"></div>
          <ul className="options">
            <li onClick={() => { deleteFolder(); setShowOptions(false); }}><RiDeleteBinLine /> Excluir pasta</li>
            <li onClick={() => handleChangeTitleFolder() }><RiCheckLine /> Salvar</li>
          </ul>
        </>
      )}
      
    </Container>
  );
}

export default memo(ItemCentralMidia);
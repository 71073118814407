import React, { useState, useEffect } from 'react';

import apiGeral from '../../services/api-geral';

import { sessionGet, logout } from '../../session';

import log from '../../util/log';

import Page from '../../components/Page';
import TitleAndBreadcrumb from '../../components/TitleAndBreadcrumb';
import CInput from '../../components/ComponentInput';
import CButton from '../../components/ComponentButton';
import InfoHelp from '../../components/InfoHelp';
import InfoMensagem from '../../components/InfoMensagem';

import { Container, ContentPanel, ConfigurationForm, InputGroup, ButtonGroup } from './styles';
import { Painel } from '../../styles/content';

export default function AlterarSenha() {
  const [showAlert, setShowAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState(false);
  const [msgAlert, setMsgAlert] = useState(false);

  const [hasChangesModal, setHasChangesModal] = useState(false);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  useEffect(() => {
    if (currentPassword !== '' || newPassword !== '' || confirmNewPassword !== '') {
      setHasChangesModal(true);
    } else {
      setHasChangesModal(false);
    }
  }, [currentPassword, newPassword, confirmNewPassword])

  const updatePassword = async (e) => {
    e.preventDefault();

    if (!currentPassword || !newPassword || !confirmNewPassword) {
      setTypeAlert('danger');
      setMsgAlert('Por favor, preencha todos os campos');
      setShowAlert(true);
      setTimeout(() => { setShowAlert(false) }, 5000);
    } else {
      const response = (await apiGeral.put(`/users`, {
        id: sessionGet('idUser'),
        currentPassword: currentPassword,
        password: newPassword,
        confPassword: confirmNewPassword
      })).data;

      if (response.type === "success") {
        log(`editou as informações do perfil.`);

        setCurrentPassword('');
        setNewPassword('');
        setConfirmNewPassword('');

        setTypeAlert('success');
        setMsgAlert('Seus dados foram atualizados e você será redirecionado...');
        setShowAlert(true);
        setTimeout(() => { logout() }, 5000);
      }
      else {
        setTypeAlert('danger');
        setMsgAlert(response.msg);
        setShowAlert(true);
        setTimeout(() => { setShowAlert(false) }, 5000);
      }
    }
  };

  return (
    <Page hasChanges={hasChangesModal} setHasChanges={setHasChangesModal} content={(
      <Container>
        <TitleAndBreadcrumb title='Alterar senha' breadcrumbs={[]} format='config' />
        <ContentPanel >
          <Painel className="painel-configuracao-perfil">
            <ConfigurationForm onSubmit={e => updatePassword(e)}>
              <InputGroup>
                <CInput val={currentPassword} type='password' label='Senha atual' change={e => setCurrentPassword(e)} style='radius' />
              </InputGroup>
              <InputGroup>
                <CInput classCustom="input-senha" val={newPassword} type='password' label='Nova senha' change={e => setNewPassword(e)} style='radius' />
                <InfoHelp classInfo='full-width' contentInfo="Utilize no mínimo 6 caracteres com ao menos um número e uma letra maiúscula." />
              </InputGroup>
              <InputGroup>
                <CInput val={confirmNewPassword} type='password' label='Confirmar senha' change={e => setConfirmNewPassword(e)} style='radius' />
              </InputGroup>
              <InputGroup>
                {(showAlert) && (
                  <InfoMensagem tipoAlerta={`custom ${typeAlert}`} conteudoAlerta={msgAlert} />
                )}
              </InputGroup>
              <ButtonGroup>
                <CButton ctype='submit' title='Alterar senha' cstyle='primary small' />
              </ButtonGroup>
            </ConfigurationForm>
          </Painel>
        </ContentPanel>
      </Container>
    )} />
  );
}
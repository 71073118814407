import styled from "styled-components";
import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Container = styled.div`
  width: 100%;
  flex: auto;
  background-color: white;
  position: relative;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border: 1px solid ${colors.border};
  border-radius: 5px;
  margin-bottom: 25px;
  padding: 10px 0;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;

  &.perfil {
    border: 0px;
  }

  &.scrolled {
    height: 80vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
			width: 4px;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			background: #f1f1f1;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			background: ${colors.border};
			border-radius: ${effects.radius};
		}

		/* Handle on hover */
		&::-webkit-scrollbar-thumb:hover {
			background: #555;
    }
  }

  .wrap-image {
    width: 100%;
    min-height: 300px;
    background-color: rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: center;

    .image-feed {
      max-width: 100%;
      object-fit: contain;
      max-height: 400px;
      cursor: pointer;
    }

    @media (min-width: 1200px) and (max-width: 1400px) {
      .image-feed {
        height: 300px;
      }
    }

    @media (max-width: 575px) {
      .image-feed {
        width: 80%;
        height: auto;
      }
    }
  }
`;

export const Content = styled.div`
  width: calc(100% - 40px);

  .text-feed {
    margin: 20px 0;
  }

  p {
    text-align: justify;
    font-size: 16px;
    letter-spacing: 0.04rem;
    color: black;
  }

  li {
    list-style-position: inside;
  }

  a {
    font-size: 16px;
    word-break: break-all;
    color: #551aa5;
    text-decoration: none;
  }

  @media (max-width: 575px) {
    a {
      font-size: 14px;
    }
    p {
      font-size: 14px;
    }
  }

  .mention {
    word-break: unset;
    color: #551aa5;
  }

  .custom-mentions {
    margin-bottom: 10px;
  }
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .autor {
    display: flex;
    align-items: center;
    position: relative;

    &:hover {
      .modal-user {
        display: flex;
      }
    }

    .bloco {
      display: inline;
    }
    
    img {
      width: 50px;
      height: 50px;
      margin-right: 10px;
      border-radius: 50%;
    }

    .link-post {
      font-size: 14px;
      color: #2b2a28;
      word-break: normal;
    }

    .editado-link-post {
      font-size: 14px;
      color: #878787;
      word-break: normal;
    }

    .desc {
      display: flex;
      flex-direction: column;

      strong {
        font-size: 16px;
      }

      a {
        text-decoration: none;
        color: black;
      }

      span {
        font-size: 14px;
      }

      @media (max-width: 575px) {
        strong {
          font-size: 14px;
        }

        span {
          font-size: 12px;
        }
      }
    }

    .modal-user {
      display: none;
    }
  }

  .options {
    position: relative;

    button {
      color: ${colors.textoff};
      background: ${colors.bgsecondary};
      border: 0;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: ${colors.bgsecondary};
      }

      svg {
        font-size: 25px;
        color: ${colors.primary};
      }

      span {
        font-size: 16px;
        color: ${colors.secondary};
      }
    }

    .dropdown {
      width: 250px;
      padding: 10px;
      background-color: white;
      border: 1px solid ${colors.border};
      position: absolute;
      right: 0px;
      top: 50px;
      border-radius: 5px;
      z-index: 1050;

      .option {
        display: flex;
        align-items: center;
        transition: all 0.3s;
        padding: 5px;
        border-radius: 5px;
        margin: 5px 0;
        cursor: pointer;

        &:hover {
          background-color: ${colors.bgsecondary};
        }

        .icon {
          background-color: ${colors.bgsecondary};
          width: 35px;
          height: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          margin-right: 10px;

          svg {
            color: ${colors.textoff};
            font-size: 20px;
          }
        }

        .option-saved-info{
          display: none;
          background-color: ${colors.bgprimary};
          box-shadow: ${effects.boxshadowsm};
          border: 1px solid ${colors.border};
          padding: 12px;
          margin-right: 5px;
          position: absolute;
          transform: translate(10%, 80%);
          z-index: 1;
          max-width: 180px;
          p{
            font-weight: 600;
            font-size: 14px;
            text-align: justify;
        }
     }

        &:hover{
          .option-saved-info{
          display: flex;
        }

      }

    }
      
      hr {
        border-top: 1px solid ${colors.border};
        height: 1px;
        width: 100%;
      }
    }
  }
  .saved-container{
    .icon-bookMark{
      cursor: pointer;
      svg {
      font-size: 25px;
      color: ${colors.primary};
     }
    }
    .saved-info{
      display: none;
      background-color: ${colors.bgprimary};
      box-shadow: ${effects.boxshadowsm};
      border: 1px solid ${colors.border};
      padding: 12px;
      margin-right: 5px;
      position: absolute;
      right: 0;
      z-index: 1;
      max-width: 190px;
      p{
        font-weight: 600;
        font-size: 14px;
        text-align: justify;
      }

      
     }

     &:hover{
      .saved-info{
      display: flex;
     }
   }
   }
`;

import React, { useEffect, useState } from 'react';

import Header from '../../components/ComponentHeader';
import { ModalHasChanges } from '../ModalHasChanges';

import { Wrap, Content } from './styles';

export default function Page({ content, hasChanges, setHasChanges, imageId, setImageId, imagePath, setImagePath }) {
  const [showMenu, setShowMenu] = useState(true);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [isDropdown, setIsDropdown] = useState(false);

  const [contentStyle, setContentStyle] = useState('menu');

  const [showModal, setShowModal] = useState(false);

  const [pathRedirect, setPathRedirect] = useState('');

  useEffect(() => {
    if (showSubMenu) {
      setContentStyle('submenu');
    } else if (showMenu && !showSubMenu) {
      setContentStyle('menu');
    } else {
      setContentStyle('none');
    }
  }, [showMenu, showSubMenu, isDropdown]);

  // useEffect(() => {
  //   console.log('Modificações: ' + hasChanges);
  // }, [hasChanges]);

  // useEffect(() => {
  //   console.log('Show Modal: ' + showModal);
  // }, [showModal]);

  return (
    <Wrap>
      <ModalHasChanges
        modalConfirmation={showModal}
        setModalConfirmation={setShowModal}
        setHasChanges={setHasChanges}
        pathRedirect={pathRedirect}
        imageId={imageId}
        imagePath={imagePath}
      />

      <Header
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        showSubMenu={showSubMenu}
        setShowSubMenu={setShowSubMenu}
        isDropdown={isDropdown}
        setIsDropdown={setIsDropdown}
        hasChanges={hasChanges}
        setHasChanges={setHasChanges}
        showModal={showModal}
        setShowModal={setShowModal}
        setPathRedirect={setPathRedirect}
        setImageId={setImageId}
        setImagePath={setImagePath}
      />

      <Content className={`customscroll ${contentStyle}`}>
        {content}
      </Content>
    </Wrap >
  );
}

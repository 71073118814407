import React, { useState, useEffect } from 'react';
import api from '../../services/api-geral';
import socket from '../../services/socket';

import { Container, Head, Buttons, Button } from './styles';
import {
    RiImageLine,
    RiLinksLine,
    RiMapPinUserLine
} from 'react-icons/ri';
import getFiles from '../../util/getFiles';
import { sessionGet } from '../../session';
import Default from '../../assets/default.png';

function NFormFeed({ getPosts, criar }) {
    const [content, setContent] = useState('');
    const [imgPerfil, setImgPerfil] = useState('');
    const [hasPhoto, setHasPhoto] = useState(false);
    const [presence, setPresence] = useState();
    const [customStatus, setCustomStatus] = useState();

    useEffect(() => {
        getFotoPerfil();
        socket.on('customStatusChanged', () => {
            window.setTimeout(() => {
                getFotoPerfil();
            }, 2000)
        });
    }, []);


    const getFotoPerfil = async () => {
        const response = (await api.get(`/users/${sessionGet('idUser')}`)).data;

        setPresence(response.presence_status);
        if (response.statusCustom) {
            setCustomStatus(response.statusCustom.status_color);
        } else {
            setCustomStatus(null);
        }

        if (response.id_file) {
            setHasPhoto(true);
            setImgPerfil(response.files.path);
        } else {
            setHasPhoto(false);
            setImgPerfil(Default);
        }
    };

    return (
        <Container id="form-post">
            <Head>
                <img src={hasPhoto ? getFiles(imgPerfil) : imgPerfil} />
                <div className="presence"><span className={(customStatus != null) ? customStatus : 'online'}></span></div>
                <div className="post-button">
                    <div className="post-head" type="button" onClick={() => criar()} >
                        <span className="mobile-span">Escreva sua mensagem</span><span className="desktop-span">O que gostaria de compartilhar?</span>
                    </div>
                    <div className="button-head" type="button" onClick={() => { criar(true); }}>
                        <div className="icon">
                            <RiImageLine />
                        </div>
                    </div>
                </div>
            </Head>
            <Buttons>
                <Button type="button" onClick={() => { criar(true); }}>
                    <div className="icon">
                        <RiImageLine />
                    </div>
                    <span>Imagem</span>
                </Button>
            </Buttons>
        </Container>
    );
};

export default NFormFeed;
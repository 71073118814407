import React, { useState, useEffect, useRef, forwardRef } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { RiMoreLine, RiEditLine, RiDeleteBin6Line, RiCloseLine, RiBookmarkLine, RiBookmarkFill  } from 'react-icons/ri';
import { format } from 'date-fns';
import { setIdWithCripto } from '../../util/formatParamsUrl';
import getFiles from '../../util/getFiles';
import translateDate from '../../util/translateDate';

import Swal from 'sweetalert2';

import { sessionGet } from '../../session';

import api from '../../services/api-geral';

import Default from '../../assets/default.png';

import NCommentsFeed from '../NCommentsFeed';
import ModalConfirma from '../ModalConfirma';
import MentionsFeed from '../MentionsFeed';
import ModalUser from '../ModalUser';

import { Container, Content, Head } from './styles';

const NPostFeed = forwardRef(({ post, image = null, editar, deletar, modalImage, viewmode = false, setHasChangesModal, setImageId, setImagePath, perfil, reload }, ref) => {
    const [showOptions, setShowOptions] = useState(false);
    const [showModalUser, setShowModalUser] = useState(false);
    const [idPostSaved, setIdPostSaved] = useState([])
    const [confirmaDelete, setConfirmaDelete] = useState(false);
    const [postSaved, setPostSaved] = useState(false);
    const imagemPost = useRef(null);

    const [postText, setPostText] = useState('');

    const [markedUsers, setMarkedUsers] = useState([]);

    const [editado, setEditado] = useState(false);

    const [page, setPage] = useState(0);

    const editPost = () => { editar(); setShowOptions(false); }

    useEffect(() => { getPostSaved (); }, []);
    useEffect(() => { verafingPostsSaved(); }, [idPostSaved]);

    const deletePost = () => {
        deletar();
        setShowOptions(false);
        setConfirmaDelete(false);

        if (viewmode) {
            setTimeout(function () {
                window.location.href = '/feed';
            }, 750)
        }

    };

    const savePost = async () => {
        const responseGET = (await api.get(`/users/${sessionGet('idUser')}`)).data;

        const userId = responseGET.id
        const idEmpresa = sessionGet('idEmpresa');
        const userEmpresaId = idEmpresa
        const postId = post.id;

            try {
                const response = await api.post(`/posts-salvos`, { userId, postId, userEmpresaId });
                if (response.data.func === 'saved') {
                    setPostSaved(true);
            
                } else {
                    setPostSaved(false);  
                    reload()
                }
                
            } catch (error) {
                console.error("Error posting data: ", error);
            }            
         
    }

    const getPostSaved = async () => {
        const idEmpresa = sessionGet('idEmpresa');
        const idUser = sessionGet('idUser');
        
        const response = await (api.get(`posts-salvos/${idUser}/${idEmpresa}`));
        const postData = response.data.posts;
 
        const formattedPosts = postData.map((post) => ({          
                  id_post:post.id_post,
          }))  

        setIdPostSaved(formattedPosts)  
              
    }

    const verafingPostsSaved = () => {

         const postSalvo = idPostSaved.some(item => item.id_post === post.id);

         if (postSalvo) {
            setPostSaved(true);
          } else {
            setPostSaved(false);
          }
    }


    useEffect(() => {
        var postContent = post.content.replace(/&nbsp;/g, ' ');
        postContent = postContent.replace(/<p> <\/p>/g, '<p>&nbsp;</p>');
        postContent = postContent.replace(/<br> <\/p>/g, '<br>&nbsp;</p>');

        //post.content = post.content.replace(/&nbsp;/g, ' ');
        //post.content = post.content.replace(/<p> <\/p>/g, '<p>&nbsp;</p>');
        //post.content = post.content.replace(/<br> <\/p>/g, '<br>&nbsp;</p>');

        var aux = postContent.split(' ');

        aux.map((word, index) => {
            if (word.includes('https://')) {
                if (word.includes('href="')) {
                    var part1 = word.split('">');

                    var part2 = part1[1].split('</a>');

                    if (part2[0].length > 30) {
                        var res = part2[0].substr(0, 30) + "...";
                        const text = part1[0] + '">' + res + "</a>" + part2[1];
                        aux[index] = text;
                    }
                } else if (!word.includes('youtube.com') && !word.includes('youtu.be')) {
                    var link = '';
                    var pre = '';
                    var auxPos = '';
                    var pos = '';
                    var href = '';

                    pre = word.split('https');
                    auxPos = pre[1];
                    pre = pre[0];

                    auxPos = auxPos.split('<');

                    link = 'https' + auxPos[0];
                    href = link;

                    for (let i = 1; i < auxPos.length; i++) {
                        pos = pos + '<' + auxPos[i];
                    }

                    if (link.length > 30) {
                        link = link.substr(0, 30) + "...";
                    }

                    var finalLink = `<a target="_blank" rel="noopener noreferrer" href="${href}">${link}</a>`;

                    finalLink = finalLink.replace(/&nbsp;/g, '');

                    aux[index] = pre + finalLink + pos;
                }
            } else if (word.includes('href=') && word.includes('conversas') && word.includes('@')) {
                //const replacer = word.replace('@', '');
                //aux[index] = 'class="marcacao-link" ' + replacer;
            }
        })

        var textFinal;

        aux.map((word, index) => {
            if (index == 0) {
                textFinal = word;
            } else {
                textFinal = textFinal + " " + word;
            }
        })

        //post.content = textFinal;
        postContent = textFinal;

        setPostText(postContent);
    }, []);

    useEffect(() => {
        const getMentions = async () => {
            /*const response = (await api.get(`/marks/post/${post.id}`)).data;
    
            var array_marks = [];
    
            response.marcacoes.map(mark => {
                array_marks.push(mark.user);
            });
    
            setMarkedUsers(array_marks);*/

            var array_marks = [];

            post.marks.map(mark => {
                array_marks.push({ ...mark.user, modal: mark.modal });
            });

            setMarkedUsers(array_marks);
        };

        getMentions();

    }, [post]);

    const formatDateString = (date) => {
        var data = translateDate(date).split(' ');
        var newData = "";
        if (data.length > 4) {
            if (data[2] === 'sábado' || data[2] === 'domingo') {
                newData = `No último ${data[2]} ${data[3]} ${data[4]}`;
                return newData;
            } else {
                newData = translateDate(date);
                return newData;
            }
        } else {
            newData = translateDate(date);
            return newData;
        }
    }

    useEffect(() => {
        if (post.createdAt === post.updatedAt) {
            setEditado(false);
        } else {
            setEditado(true);
        }
    }, [])

    return (
        <Container ref={ref} id={post.id} className={perfil ? "perfil" : (viewmode) ? 'scrolled' : ''}>
            <Content>
                <Head>
                    <div className="autor">
                        <img src={post.autor.files && post.autor.files != null ? getFiles(post.autor.files.path) : Default} />
                        <div className="desc">
                            <strong><a href={`/perfil?id=${setIdWithCripto('user', post.id_autor)}`}>{post.autor.name}</a></strong>
                            {editado === true
                                ?
                                <div id='bloco'>
                                    <a className="link-post" href={`/feed/single?id=${setIdWithCripto('post', post.id)}`}>{formatDateString(post.createdAt)}&nbsp;</a>
                                    <a className="editado-link-post" href={`/feed/single?id=${setIdWithCripto('post', post.id)}`}>&bull; Editado {(formatDateString(post.updatedAt)).toLowerCase()}</a>
                                </div>
                                :
                                <a className="link-post" href={`/feed/single?id=${setIdWithCripto('post', post.id)}`}>{formatDateString(post.createdAt)}</a>
                            }
                        </div>
                        <ModalUser idUser={post.id_autor} user={post.autor} photoUser={post.autor.files} />
                    </div>
                    {post.id_autor === sessionGet('idUser') ? (
                        <>
                            <div className="options">
                                <button className="btn-options" onClick={() => setShowOptions(!showOptions)}>
                                    {showOptions ? <RiCloseLine /> : <RiMoreLine />}
                                </button>

                                {showOptions && (
                                    <div className="dropdown">
                                        <div className="option" onClick={editPost}>
                                            <div className="icon">
                                                <RiEditLine />
                                            </div>
                                            <span>Editar publicação</span>
                                        </div>
                                        <hr />
                                       <div  onClick={() =>  setTimeout(() => { setShowOptions(!showOptions) }, 500)}>
                                        <div className="option" onClick={savePost}>
                                            <div className="icon"  >
                                            {postSaved ? <RiBookmarkFill /> : <RiBookmarkLine /> }  
                                            </div>
                                            <span>Salvar publicação</span>
                                            
                                            {postSaved && <div className='option-saved-info' >
                                                              <p> Excluir das suas publicações salvas</p>
                                                          </div>}
                                        </div>
                                       </div>
                                        <hr />
                                        <div className="option" onClick={() => setConfirmaDelete(true)}>
                                            <div className="icon">
                                                <RiDeleteBin6Line />
                                            </div>
                                            <span>Excluir publicação</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {confirmaDelete && (<ModalConfirma type='publicação' setConfirmaDelete={setConfirmaDelete} deletar={() => { deletePost(); setTimeout(() => { reload() }, 100) }} setShowOptions={setShowOptions} />)}
                        </>
                    ): (
                        <div className='saved-container'>

                                <div className="icon-bookMark" onClick={savePost}>
                                {postSaved ? <RiBookmarkFill /> : <RiBookmarkLine /> }  
                                </div>
                                <div className='saved-info' >{postSaved ? <p> Excluir das suas publicações salvas</p> : <p> Salvar publicação</p> }</div>

                        </div>
                    ) }
                </Head>
                <div className="text-feed">{ReactHtmlParser(postText)}</div>
                {post.marks.length > 0 && (
                    <MentionsFeed cclass="custom-mentions" markedUsers={markedUsers} />
                )}
            </Content>
            {(post.path_img != null && post.path_img !== '') && (
                <div className="wrap-image">
                    <img ref={imagemPost} id="image_post" className="image-feed" onClick={() => modalImage(getFiles(post.path_img))} src={getFiles(post.path_img)} />
                </div>
            )}
            <NCommentsFeed id_post={post.id} id_autor={post.id_autor} post={post} viewmode={window.location.href.includes('single') ? true : false} setHasChangesModal={setHasChangesModal} setImageId={setImageId} setImagePath={setImagePath} />
        </Container>
    );
});

export default NPostFeed;
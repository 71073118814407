import React, { useState, useEffect } from 'react';

import api from '../../../../services/api-geral';

import { MdPerson, MdPeople, MdSearch } from 'react-icons/md';

import { Wrap, Backdrop, Lista, Item, ImgAndStatus, Content, Form } from './styles';

export default function Autocomplete({ setTargetUserList, targetUser, targetAllUsers }) {
    const [usuarios, setUsuarios] = useState([]);
    const [pesquisa, setPesquisa] = useState('');

    useEffect(() => {
        getUsuarios();
      }, []);

    const getUsuarios = async () => {
        const response = (await api.get('/users?usuarios=true&order=asc')).data;
        setUsuarios(response);
    };

    return (
        <>
        <Backdrop onClick={() => setTargetUserList(false)}/>
        <Wrap>
            <Lista>
                <Form>
                    <MdSearch />
                    <input placeholder="Pesquisar" value={pesquisa} onChange={(e) => setPesquisa(e.target.value)} />
                </Form>
                <Item onClick={() => targetAllUsers(usuarios)}>
                    <ImgAndStatus>
                        <MdPeople color="#fff"/>
                    </ImgAndStatus>
                    <Content>
                        <strong>Marcar todos</strong>
                    </Content>
                </Item>
                {usuarios.filter((v) => { return v.name.toLowerCase().indexOf(pesquisa.toLowerCase()) >= 0 }).map(user => {
                    return (
                        <Item onClick={() => targetUser(user)}>
                            <ImgAndStatus>
                                {(user.foto_perfil) ? <img src={user.nome_arquivo} /> : (
                                <MdPerson color="#fff"/>
                                ) }
                                <span className={(user.presence_status === 1) ? 'online' : 'offline'}></span>
                            </ImgAndStatus>
                            <Content>
                                <strong>{user.name}</strong>
                            </Content>
                        </Item>
                    )
                })}
            </Lista>
        </Wrap>
        </>
    );
}

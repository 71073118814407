import React, { useState, useEffect } from 'react';

import { Container, Modal, Head, Body } from './styles';

import { RiCloseLine } from 'react-icons/ri';
import { RiStarSFill } from 'react-icons/ri';

import api from '../../services/api-geral';
import { formatDate } from '../../util/formats';
import { sessionGet } from '../../session';
import ReactHtmlParser from 'react-html-parser';

function HistoricoModal({ atendimento: atendimentoModal, setAtendimentoModal }) {
  const [status, setStatus] = useState();
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    getMessages();
    if (atendimentoModal.status === 'finalizado' | atendimentoModal.status === 'avaliado') {
      setStatus('finalizado');
    } else if (atendimentoModal.status === 'transferido') {
      setStatus('transferido')
    }
  }, [])

  const getMessages = async () => {
    const response = (await api.get(`/chat-atendimento/messages/${atendimentoModal.id}`)).data;
    setMessages(response);
  }

  const getStars = (nota) => {
    var html = [];
    let i = 0;
    for (i; i < nota; i++) {
      html.push(<RiStarSFill />)
    }
    if (nota > 0) {
      return html;
    } else {
      return 'Não avaliado'
    }
  }

  return (
    <Container>
      <Modal>
        <Head>
          <h2>Histórico</h2>
          <button className="close-button" onClick={() => setAtendimentoModal(null)}><RiCloseLine /></button>
          <div className="info-atendimento">
            <span className="label">
              Cliente:
              <span className="info"> {atendimentoModal.cliente ? atendimentoModal.cliente.name : 'Não definido'}</span>
            </span>
            <span className="label">
              Atendente:
              <span className="info"> {atendimentoModal.atendente.name}</span>
            </span>
            <span className="label">
              Nota:
              <span className="info-nota"> {atendimentoModal.avaliacao > 0 ? getStars(atendimentoModal.avaliacao) : 'Não avaliado'}</span>
            </span>
            <hr className="separador"></hr>
          </div>
        </Head>
        <Body>
          <div className="scroll-holder">
            <div className="mensagens">
              <span className='status'>Atendimento iniciado dia {formatDate(atendimentoModal.data_inicio)}</span>
              <div className="conversa">
                <div className="timeline"></div>
                <div className="msgs">
                  {messages.length > 0 ?
                    messages.map(msg => (
                      (msg.id_from === atendimentoModal.atendente.id ?
                        <span className="from">
                          <span className="dot-from"></span>
                          {msg.id_from === sessionGet('idUser') ? 'Você: ' : `${msg.from.name}: `}
                          <span className="message">
                            {(msg.type_file !== '' && msg.type_file !== null) ?
                              (msg.type_file === 'file') ? ReactHtmlParser("<i>Arquivo</i>") : ReactHtmlParser("<i>Imagem</i>")
                              : ReactHtmlParser(msg.message.replace(/<[^>]*>?/gm, ''))}
                          </span>
                        </span>
                        :
                        <span className="to">
                          <span className="dot-to"></span>
                          {msg.from ? msg.from.name : 'Cliente(não definido)'}: <span className="message">
                            {(msg.type_file !== '' && msg.type_file !== null) ?
                              (msg.type_file === 'file') ? ReactHtmlParser("<i>Arquivo</i>") : ReactHtmlParser("<i>Imagem</i>")
                              : ReactHtmlParser(msg.message.replace(/<[^>]*>?/gm, ''))}
                          </span>
                        </span>)
                    )) : <span className="to">Não houve troca de mensagens</span>}
                </div>
              </div>
              <span className='status'>Atendimento {status} dia {formatDate(atendimentoModal.data_final)}</span>
              {atendimentoModal.comentario !== '0' ? <p>Comentário do cliente: <span className="comentario-cliente">"{atendimentoModal.comentario}"</span></p> : ''}
            </div>
          </div>
        </Body>
      </Modal>
    </Container>
  );
}

export default HistoricoModal;
import styled from 'styled-components';

import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Wrap = styled.div`
  width: 400px;
  height: 80%;
  max-height: 530px;
  position: fixed;
  z-index: 1081;
  border: 1px solid ${colors.border};
  top: 100px;
  right: 50px;
  background-color: ${colors.bgprimary};
  border-radius: 15px;
  box-shadow: ${effects.boxshadow};
  display: flex;
  flex-direction: column;
  padding: 10px;

  @media(max-width: 500px) {
    width: 300px;
    top: 80px;
    right: 10px;
  }

  &.full {
    justify-content: flex-start;
  }

  .no-notifications {
    margin: 10px;
  }

  .header-notifications {
    display: flex;
    justify-content: space-between;
    margin: 10px 18px;

    @media(max-width: 500px) {
      margin: 10px 10px;
    }

    h3 {
      font-size: 18px;
      //text-align: center;
      @media(max-width: 500px) {
        font-size: 14px;
      }
    }
    
    button {
      font-size: 13px;
      background-color: transparent;
      border: 0;
      color: #666;
      text-decoration: underline;
      text-underline-position: under;

      @media(max-width: 500px) {
        font-size: 11px;
      }

      &:hover {
        color: ${colors.primary};
      }
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    overflow-y: auto;

    ::-webkit-scrollbar {
      display: none;
      width: 4px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
      background: ${colors.border};
      border-radius: ${effects.radius};
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    h3 {
      margin-bottom: 10px;
    }

    li {
      background-color: rgba(0, 0, 0, 0.03);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      border-radius: 5px;
      margin-bottom: 10px;
      cursor: pointer;

      &:hover {
        background-color: #ebedeb;
      }

      &.lido {
        background-color: white;

        &:hover {
          background-color: #ebedeb;
        }

        .icon {
          background-color: rgba(0, 0, 0, 0.03);
          color: ${colors.primary};
        }
      }

      .icon {
        background-color: ${colors.primary};
        width: 35px;
        height: 35px;
        color: white;
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
      }

      .desc {
        width: calc(100% - 45px);
        display: flex;
        flex-direction: column;
 
        strong {
          text-transform: uppercase;
          margin-right: 5px;
          color: ${colors.primary};
          font-size: 12px;
        }

        span {
          font-size: 13px;

        }

        small {
          color: #555;
        }
      }
    }
  }

  .wrap-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      margin-left: 10px;
      font-size: 15px;
      background-color: transparent;
      border: 0;
      color: #666;

      &:hover {
        color: ${colors.primary};
      }
    }
  }
`;

import React from 'react';
import { MdOutlinePhonelink, MdEmail } from "react-icons/md";
import { FaHandsHelping, FaUserFriends } from "react-icons/fa";
import { IoChatbubblesSharp } from "react-icons/io5";
import { BiLike, BiSupport } from "react-icons/bi";

import Page from '../../components/Page';
import TitleAndBreadcrumb from '../../components/TitleAndBreadcrumb';
import DashboardItem from '../../components/DashboardItem';

import { Container, WrapItens, WrapButton, Button } from './styles.js';

export default function PainelDeControle() {
  return (
    <Page content={(
      <Container>
        <div>
          <TitleAndBreadcrumb title={'Merx: grátis para sempre'} breadcrumbs={[]} format={'enabled-tools'} />
          <WrapItens className='enabled-tools'>
            <DashboardItem link={"/pagina-empresarial"} icon={<MdOutlinePhonelink />} title='Mini site personalizado' />
            <DashboardItem link={"/email"} icon={<MdEmail />} title='1 e-mail @merx' />
            <DashboardItem link={"/atendimentos-ao-cliente"} icon={<IoChatbubblesSharp />} title='Chat para falar com seu cliente' />
            <DashboardItem link={"mailto:falecom@task.com.br"} icon={<FaHandsHelping />} title='Suporte por e-mail' />
          </WrapItens>
        </div>
        <div>
          <TitleAndBreadcrumb title={'Ative novas ferramentas pelo site'} breadcrumbs={[]} format={'disabled-tools'} />
          <WrapItens className='disabled-tools'>
            <DashboardItem icon={<MdOutlinePhonelink />} title='Mini site com domínio próprio' />
            <DashboardItem icon={<MdEmail />} title='Mais contas de e-mail' />
            <DashboardItem icon={<FaUserFriends />} title='Rede social para equipe' />
            <DashboardItem icon={<IoChatbubblesSharp />} title='Chat para equipe' />
            <DashboardItem icon={<BiLike />} title='Redes sociais integradas ao Merx' />
            <DashboardItem icon={<BiSupport />} title='Suporte por WhatsApp' />
          </WrapItens>
        </div>

        <WrapButton>
          <Button href='https://merx.app.br/contratacao' rel="noopener noreferrer" target='_blank'>Ativar novas ferramentas</Button>
        </WrapButton>
      </Container>
    )} />
  );
}
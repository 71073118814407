import React, { useState, useEffect } from 'react';
import api from '../../services/api-geral';

import {
    RiCloseLine
} from 'react-icons/ri';

import getFiles from '../../util/getFiles';
import renderImg from '../../util/renderImg';
import Default from '../../assets/default.png';

import { Container, Content, Head, Body, AreaToClose } from './styles';

function NModalViewLike({ closeModal, interactions, type }) {

    const [imgPerfil, setImgPerfil] = useState('');
    const [hasPhoto, setHasPhoto] = useState(false);
    const [photos, setPhotos] = useState([]);

    useEffect(() => {
        getFotoPerfil();
    }, []);

    const getFotoPerfil = () => {
        interactions.map(async (fotos) => {
            const response = (await api.get(`/users/${fotos.id_user}`)).data;
            let pic;
            if (response.id_file === null) {
                pic = "default";
            }
            else {
                pic = response.files.path;
            }
            setPhotos(photos => [...photos, { id: response.id, autor: response.name, foto: pic }]);
        })
    };

    return (
        <Container>
            <Content>
                <Head>
                    <h1>{type === "like" ? 'Curtidas' : 'Visualizações'}</h1>
                    <button className="btn-close" onClick={closeModal}><RiCloseLine /></button>
                </Head>
                <Body>
                    {photos.map(like =>
                        <div key={like.id} className="user-LV">
                            <img src={like.foto == "default" ? Default : getFiles(like.foto)}></img>
                            <span>{like.autor}</span>
                        </div>)}
                </Body>
            </Content>
            <AreaToClose onClick={closeModal} />
        </Container>
    );
};

export default NModalViewLike;
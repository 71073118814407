import React from 'react';

import { Wrap } from './styles';

export default function CCheckbox({ click, active, classWrap }) {
  return (
    <Wrap local={classWrap} className={classWrap} >
      <div onClick={click} className={`box ${active}`}></div>
    </Wrap>
  );
}
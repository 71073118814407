import styled from 'styled-components';

import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Item = styled.div`
    width: 100%;
    position: relative;
    //background-color: ${colors.bgprimary};
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 10px 0;

    &:last-child {
        /* border-bottom: 1px solid #d9d9d9; */
    }
`;

export const ImgAndStatus = styled.div`
    position: relative;
    background-color: #fff;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    font-size: 30px;
    display: flex;
    justify-content: center;
    box-shadow: ${effects.boxshadow};
    align-items: center;

    @media (max-width: 1400px) {
        width: 30px;
        height: 30px;  
    }

    svg {
        display: block;
        color: ${colors.primary} !important;
        font-size: 18px;
    }

    img {
        width: 100%;
        height: 100%;
        object-position: center;
        border-radius: 50%;
    }

    span {
        width: 10px;
        height: 10px;
        display: block;
        position: absolute;
        bottom: -5px;
        right: -3px;
        border-radius: 50%;
        border: 3px solid white;
        box-sizing: content-box;

        &.offline { background-color: gray; }
        &.online { background-color: green; }
        &.ausente { background-color: #FFBF00; }
        &.naoPerturbe { background-color: #FF0000; }
    }
`;

export const Content = styled.div`
    width: 75%;

    strong {
        color: ${colors.text_primary};
        display: block;
        font-weight: bold;
        letter-spacing: 0.07rem;
        font-size: 14px;

        @media (max-width: 1400px) {
            font-size: 12px;
        }
    }

    .row {
        display: flex;
        grid-row: span;
        justify-content: space-between;
        
    }

    .user-chat {
        font-size: 13px;
        color: black;
    }

    .setor-chat {
        font-size: 12px;
    }

    .status-user {
        font-size: 12px;
    }
    .status-user-notification {
        font-size: 12px;
        margin-right: 16px;
    }

    .badge-aguardando {
        background-color: #f0ad4e;
        border-radius: 5px;
        padding: 0px 10px;
        display: inline;
        color: #ffff;
        font-weight: 700;
    }

    .badge-atendimento {
        background-color: #337ab7;
        border-radius: 5px;
        padding: 0px 10px;
        display: inline;
        color: #ffff;
        font-weight: 700;
    }

    .badge-finalizado {
        background-color: #5cb85c;
        border-radius: 5px;
        padding: 0px 10px;
        display: inline;
        color: #ffff;
        font-weight: 700;
    }

    .badge-transferido {
        background-color: #5cb85c;
        border-radius: 5px;
        padding: 0px 10px;
        display: inline;
        color: #ffff;
        font-weight: 700;
    }

    .nmr-msgs {
        position: absolute;
        right: 10px;
        top: 20px;
        height: 17px;
        width: 17px;
        font-size: 11px;
        border-radius: 50%;
        background-color: red;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
    }

    span {
        font-size: 13px;
        letter-spacing: 0.04rem;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #909090;
    }

    .time-msgs {
        .time-msg {
            font-size: 11px;
            position: absolute;
            right: 10px;
            top: 0px;
        }
    }
`;
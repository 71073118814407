import styled, { css } from "styled-components";

import colors from "../../../presets/colors";
import effects from "../../../presets/effects";

import { lighten } from "polished";

export const Content = styled.div`
  display: flex;
  height: fit-content;
  flex-direction: column;
  width: 100%;
  height: auto;
  background-color: ${colors.bgsecondary};
  border-radius: 20px;



  ::-webkit-scrollbar {
    width: 0px;
  }
  
  .body {    
    /* padding: 10px 10px 15px; */

    form {
      position: relative;

      .content-form {
        /* margin-top: 15px; */

        .container-ListMarkedUsers{
           margin: 0.5rem;
           margin-top: 0;
        }

        .send-comment {
          position: absolute;
          justify-content: center;
          align-items: center;
          display: flex;
          background-color: ${colors.primary};
          
          right: 10px;
          top: 0;
          margin-top: 2%;

          height: 30px;
          width: 30px;
          font-size: 25px;
          color: white;             
          border-radius: 50%;
          cursor: pointer;
          border: 1px solid ${colors.primary};
          svg {
            height: 20px;
            width: 20px;
            position: absolute;
            top: 50%;
            left: 55%;
            transform: translate(-50%, -50%);
          }
        }       

        .ck-editor {
          /* Div atrás da caixa de texto */ 
          display: flex;
          flex-direction: column-reverse;
          background-color: transparent;
        }

        .ck-toolbar_grouping {
          background-color: transparent;
          border: 0;

        }

        .ck-toolbar__items {
          /* caixa com emojis e opções de formatação de texto */
          border: 0;
          display: flex;
          justify-content: center;
          background-color: transparent;
          width: 50px !important;

          .ck-dropdown {
            &.ck-heading-dropdown {
              .ck-button {
                &.ck-dropdown__button {
                  .ck-button__label {
                    width: auto;
                  }
                }
              }
            }
          }

        .ck.ck-icon {
            height: 15px;
          }

        }

        .ck-dropdown__arrow{
          display: none;
        }

        .ck-dropdown__panel_se{
          width: 300px;
          background-color: white;
        }

        .ck-form__header__label {
          visibility: hidden;
        }

        .ck-form__header__label:after{
          background-color: white;
          content:'Emojis'; 
          visibility: visible;
          display: block;
          position: absolute;
          padding: 5px;
          top: 2px;
        }

        --ck-color-mention-background: white;

        --ck-color-mention-text: #4285a6;

        .ck-content {
          /* Caixa de texto */
          width:100%;
          /* border: 1px solid ${colors.border}; */
          border-radius: 20px;
          background-color: ${colors.bgsecondary};
          padding: 0 15px;
          padding-right: 30px;
          justify-content: flex-start;
          align-items: center;
          color: rgba(0,0,0, 0.7);
          border: none !important;
          box-shadow: none !important;
          
          min-height: 30px;
          max-height: 300px;
          
          @media (max-width: 1500px) {
            min-height: 60px;
          }

          @media (max-width: 550px) {
            min-height: 75px;

          }

          p {
            margin: 9px 0;
            margin-right: 20px;
          }
          
          a { text-decoration: none; }

          li {
            list-style-position: inside;
          }

          ::-webkit-scrollbar {
            width: 4px;
          }

          ::-webkit-scrollbar-track {
            background: #f1f1f1;
          }

          ::-webkit-scrollbar-thumb {
            background: ${colors.border};
            border-radius: ${effects.radius};
          }

          ::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
        }
      }

      textarea {          
          width: 100%;
          font-size: 18px;
          padding: 10px;
          border: 0;
          border-radius: 5px;
          background-color: ${colors.bgsecondary};
          margin-top: 10px;
      }

      .image-preview {
        display: flex;
        justify-content: center;
        padding: 10px;
        
        .content-image-preview {
          width: 175px;
          height: auto;
          position: relative;

          .img-preview {
            width: 100%;
            height: 100%;
            border-radius: 5px;
          }

          .btn-delete-img {
            background-color: ${colors.secondary};
            color: ${colors.btn_text_primary};
            border: none;
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: -12.5px;
            top: -12.5px;
          }
        }
      }

      .form-button {
        background-color: ${colors.primary};
        width: 100%;
        height: 40px;
        color: white;
        border-radius: 5px;
        margin-top: 20px;
        border: 0;
        transition: all 0.3s;

        &:hover {
          background-color: ${lighten(0.09, colors.primary)};
        }

        &:disabled {
          cursor: default;
          opacity: 0.7;
          background-color: ${lighten(0.09, colors.primary)};
        }
      }      
    }

    .img-marker-wrapper{
          position: relative;
          bottom: 5px;
          margin-bottom: 10px;

            @media (max-width: 800px) {
            height: 2rem;
            bottom: 0px;
          }

        }

    .complementaryButtons {       
      position: absolute;
      bottom: 0;             
   
      @media (max-width: 850px) {
        padding-top: 40px;
      }
          
      @media (max-width: 850px) {
        padding-top: 40px;
      }

      &.right {
      right: 70px;

        @media (max-width: 1450px) {
            right: 40px;
            padding-top: 0;
            margin-top: 10px;
            float: left;
            flex-direction: row;
          }

        @media (max-width: 1370px) {
          right: 20px;
          padding-top: 0;
          margin-top: 10px;
          float: left;
          flex-direction: row;
        }

        @media (max-width: 900px) {
          right: 10px;
          padding-top: 0;
          margin-top: 10px;
          float: left;
          flex-direction: row;
        }

        @media (max-width: 500px) {
          padding-top: 0;
          position: absolute;
          top: 20px;
          left: 90px;
          float: left;
          flex-direction: row;
        }

        @media (max-width: 400px) {
          padding-top: 0;
          position: absolute;
          top: 25px;
          left: 52px;
          float: left;
          flex-direction: row;
        }
      }

      &.left {
        left: 70px;

        @media (max-width: 1450px) {
          left: 40px;
          padding-top: 0;
          margin-top: 10px;
          float: left;
          flex-direction: row;
        }

        @media (max-width: 1370px) {
          left: 20px;
          padding-top: 0;
          margin-top: 10px;
          float: left;
          flex-direction: row;
        }


        @media (max-width: 900px) {   
        left: 10px;
        margin-top: 15px;
        padding: 0;       
        flex-direction: row;
        }

        @media (max-width: 500px) {   
        margin-top: 15px;
        padding: 0;       
        position: absolute;
        bottom: 35px;
        left: 90px;
        flex-direction: row;
        }

        @media (max-width: 400px) {   
        margin-top: 15px;
        padding: 0;       
        position: absolute;
        bottom: 35px;
        left: 50px;
        flex-direction: row;
        }        
      }
            
      background-color: transparent;
      border: 0;
      height: 30px;
      border-radius: 10px;
      font-size: 12px;
      display: flex;
      align-items: center;
      color: ${colors.text_primary};
      letter-spacing: 1px;
          
      .icon {
        background-color: ${colors.primary};
        width: 22px;
        height: 22px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        &.left {
          margin-right: 5px;
        }

        &.right {
          margin-right: 5px;
        }

        @media (max-width: 500px) {   
          width: 25px;
          height: 25px !important;
          border-radius: 50%;

          &.left {
          margin-right: 10px;
          margin-left: 0px;
        }

        &.right {
          margin-right: 10px;
          margin-left: -2px;
        }
      }

        svg {
          font-size: 14px;
          color: white;
          @media (max-width: 500px) {   
            font-size: 16px;
          }
        }
      }
      display: flex;
      flex-direction: row;
      align-items: center;
    }     
        
    .img-marker-wrapper-little {
          position: relative;
          bottom: 5px;
          margin-bottom: 10px;

          
          @media (max-width: 550px) {
            height: 2rem;
            bottom: 0px;
          }
        }

    
    .complementaryButtons-little {       
      position: absolute;
      bottom: 0;             

      &.right {
      right: 40px;

        @media (max-width: 700px) {
          right: 20px;
          padding-top: 0;
          margin-top: 10px;
          float: left;
          flex-direction: row;
        }

        @media (max-width: 600px) {
          right: 10px;
          padding-top: 0;
          margin-top: 10px;
          float: left;
          flex-direction: row;
        }

        @media (max-width: 400px) {
          right: 20px;
          padding-top: 0;
          margin-top: 10px;
          float: left;
          flex-direction: row;
        }
      }

      &.left {
        left: 40px;

        @media (max-width: 700px) {   
        left: 20px;
        margin-top: 15px;
        padding: 0;       
        flex-direction: row;
        }

        @media (max-width: 600px) {   
        left: 20px;
        margin-top: 15px;
        padding: 0;       
        flex-direction: row;
        }

        @media (max-width: 400px) {   
          left: 20px;
        margin-top: 15px;
        padding: 0;       
        flex-direction: row;
        }        
      }
            
      background-color: transparent;
      border: 0;
      height: 30px;
      border-radius: 10px;
      font-size: 12px;
      display: flex;
      align-items: center;
      color: ${colors.text_primary};
      letter-spacing: 1px;
          
      .icon-little {
        background-color: ${colors.primary};
        width: 22px;
        height: 22px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        &.left {
          margin-right: 5px;
        }

        &.right {
          margin-right: 5px;
        }

        @media (max-width: 600px) {   
          width: 20px;
          height: 20px !important;
          border-radius: 50%;

          &.left {
          margin-right: 5px;
          margin-left: 0px;
        }

        &.right {
          margin-right: 5px;
          margin-left: 0px;
        }
      }

        svg {
          font-size: 14px;
          color: white;

          @media (max-width: 600px) {   
            font-size: 14px;
          }
        }
      }
      display: flex;
      flex-direction: row;
      align-items: center;
    }  
  }
`;

export const DropzoneContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.isDragReject
      ? css`
          color: ${colors.btn_danger};
          border: 1px solid ${colors.btn_danger};
        `
      : css`
          border: 1px solid ${colors.primary};
          color: ${colors.primary};
        `}

  p {
    font-size: 20px;
  }
`;

export const LoadingWrap = styled.div`
  width: 100%;
  background-color: none;
  border-radius: 0.75rem;
  margin-top: 30px;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
	color: ${colors.primary};

  h3 {
    margin-top: 15px;
  }

  svg {
    font-size: 25px;
  }

  .fa-spin {
    -webkit-animation: icon-spin 1s infinite linear;
    animation: icon-spin 1s infinite linear;
  }

  @-webkit-keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }

  @keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
`;
import styled from "styled-components";
import colors from "../../presets/colors";

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  border-radius: 10px;

  label.newstylelabel {
    top: -12px !important;
    left: 0 !important;
    background-color: transparent !important;
    color: #444 !important;
  }

  .newstyleinput {
    min-height: 43px;
    background-color: white !important;
    border-radius: 10px;
    border: 0 !important;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;

    label {
      top: -25px;
      left: 0;
      background-color: transparent;
      color: #444;
    }

    input {
      border-radius: 10px;
    }
  }

  .input-row {
    .newstyleinput {      
      &.ingroup {
        width: 30%;

        @media (max-width: 1024px) {
          width: 100%;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    height: 100%;
  }

  .titulo {
    display: flex;
    margin: 0 10px;

    @media (max-width: 1024px) {
      /* margin-bottom: 25px; */
    }

    .titulo-identificacao {
      display: flex;
      flex-direction: column;
      width: 100%;
      
      hr {
        background-color: #999;
        margin: 15px 0;
        
        @media(max-width: 1366px) {
          display: none;
        }
      }

      .title {
        display: flex;
      }
      
      .identificacao {
        h3 {
          color: #444;
          font-size: 25px;

          span {
            color: #933791;
          }
        }
      }

      .infos {
        display: flex;
        border-bottom: 2px dashed ${colors.primary};
        /* margin-bottom: 10px; */
        /* background: #efefef; */
        padding: 10px 0;
        
        .info-nome,
        .info-setor,
        .info-assunto {
          width: 30%;
        }

        .info-responsavel {
          width: 40%;
        }

        .info-nome,
        .info-setor,
        .info-assunto,
        .info-responsavel {
          display: flex;
          align-items: center;

          h3 {
            font-weight: normal;
            margin-right: 10px;
            font-size: 18px;
          }

          h4 {
            color: #933791;
            font-size: 18px;
            font-weight: normal;
          }

          @media(max-width: 768px) {
            
            h3, h4 {
              font-size: 14px;
            }
          }
        }

      }
    }
  }

  .detalhes {
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;

    .detalhe-chamado {
      display: flex;
      flex-direction: column;

      margin: 10px;
      flex: 1;

      .inputs {
        display: flex;
        flex-direction: column;

        .input-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;

          .select-chamado {
            margin: 10px 0;
          }

          .label-mensagem  {
            font-size: 14px;
            position: absolute;
            top: -2px !important;
            left: 5px !important;
          }

          .input-mensagem {
            width: 100%;
            max-height: 135px;
            text-align: justify;
            margin: 25px 0 35px;
            padding: 10px;
            overflow-y: auto;
            white-space: pre-line;

            @media(max-width: 1366px) {
              max-height: 80px;
            }
          }
        }

        /* .infos {
          padding: 15px 0;
          display: flex;
          gap: 1rem;
          flex-wrap: wrap;
          flex-direction: column;

          select {
            color: ${colors.primary};
            border: none;
            font-size: 16px;
          }

          @media(max-width: 800px) {
            padding: 0;
            margin-bottom: 25px;
            gap: 0;
            flex-direction: column;
          }

          @media (max-width: 768px) {
            
            select, option {
              font-size: 14px;
            }
          }
        } */

        /* .info {
          display: flex;
          align-items: center;

          h3 {
            font-weight: normal;
            margin-right: 10px;
            font-size: 18px;
          }

          h4 {
            color: #933791;
            font-size: 18px;
            font-weight: normal;
          }

          @media(max-width: 768px) {
            margin-bottom: 0;


            h3, h4, option {
              font-size: 14px;
            }
          }
        } */
      }

      .label-historico {
        width: 175px;
        background-color: white;
        position: relative;
        top: 1.4px;
        left: 10px;
        font-size: 14px;
        padding: 0 5px;
        z-index: 1050;
        color: rgba(0, 0, 0, 0.5);
        transition: color 0.5s;
    }

      .historico {
        height: 100%;
        top: -8.55px;
        display: flex;
        flex-direction: column;
        border: 1px solid rgba(0, 0, 0, 0.2);
        position: relative;
        overflow-y: auto;
        padding: 10px;

        label {
          background-color: white;
          position: absolute;
          top: 0px;
          left: 10px;
          font-size: 14px;
          padding: 0 5px;
          z-index: 1050;
          color: rgba(0, 0, 0, 0.5);
          transition: color 0.5s;
        }

        .historico-box {
          display: flex;
          flex-direction: column;
          padding: 10px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);

          .box-title {
            display: flex;
            justify-content: space-between;
            color: #000;
            font-size: 14px;
            
            span {
              font-weight: bold;
              color: #933791;
            }
          }

          .box-body {
            color: #000;
          }
        }
      }
    }
    .detalhe-comentario {
      display: flex;
      margin: 10px;
      flex: 1;
      flex-direction: column;
      height: 94%;


      .comentario-acao {
        height: 40%;
        display: flex;
        align-items: flex-start;

        .comentario-input {
          width: 100%;
          height: 100%;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: end;

          .button-message {
            width: 100%;
            display: flex;
            place-content: end;
            align-items: center;

            .mail-send {
              margin: 0;
              margin-right: 15px;
            }
          }

          label {
            align-self: start;
            position: relative;
            top: -3px;
            color: #444;
            font-size: 14px;
            margin-bottom: 4px;
          }

          .disabled-abas {
            display: none;
          }

          .abas {
            align-self: start;
            width: 100%;
            /* margin-left: 8px;
            margin-top: 4px; */
                    
            label {
              cursor: pointer;
              border-radius: 6px 6px 0 0;
              height: 100%;
              padding: 0.25rem;
              margin-right: 6px;
              align-self: start;
              color: ${colors.primary};
              font-size: 14px;
              font-weight: 500;
              margin-bottom: 0;

              :hover {
              background-color: #fff;
              }

              &.active {
              background-color: ${colors.primary};
              color: #EEEEEE;
              }
            }
          }

          .textLayer {
            width: 100%;
            height: 100%;
          }

          .button-switch {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
          }

          .resposta-chamado {
            width: 100%;
            height: 100%;
            box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
            border: 0;
            padding: 10px;
            min-height: 43px;
            background-color: white;
            resize: none;
            border-radius:0 0 10px 10px;
          }

          .disabled {
            cursor: not-allowed;
            background-color: #EEEEEE;
          }
        }

      .newbuttoncomments {
        margin-top: 20px;
        background-color: #60c045 !important;
        height: 40px !important;
        border-radius: 10px;

        &.newbuttonOpen {

        background-color: #ffa500 !important;

        }

        svg {
          font-size: 25px !important;
          margin-right: 0 !important;
        }
      }

      .newbuttonClose {
        margin-top: 20px;
        margin-left: 20px;
        background-color: red !important;
        height: 40px !important;
        border-radius: 10px;

        svg {
          font-size: 25px !important;
          margin-right: 0 !important;
        }
      }
    }

    .lista-comentarios {
      height: 53%;
      flex-grow: 5;

      .newstylelabel {
        top: -3px !important;
      }

      .label-scroll {
        width: 100px;
        background-color: white;
        position: relative;
        top: 1.4px;
        left: 10px;
        font-size: 14px;
        padding: 0 5px;
        z-index: 1050;
        color: rgba(0, 0, 0, 0.5);
        transition: color 0.5s;
      }

      .comentarios {
        height: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;
        border: 1px solid rgba(0, 0, 0, 0.2);
        padding: 10px;
        position: relative;
        top: 2px;

        label {
          background-color: white;
          position: absolute;
          top: -10px;
          left: 10px;
          font-size: 14px;
          padding: 0 5px;
          z-index: 1050;
          color: rgba(0, 0, 0, 0.5);
          transition: color 0.5s;
        }

        .comentarios-boxes.reply {
          display: flex;
          flex-direction: column-reverse;
        }
  
        .comentario-box {
          display: flex;
          flex-direction: column;
          padding: 10px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  
          .title-usuario {
            font-weight: bolder;
            color: #933791;
          }
          
          .title-data {
            font-weight: bold;
            color: #60c045;
            font-size: 12px;
          }
  
          .box-title {
            display: flex;
            justify-content: space-between;
            color: #000;
            font-size: 14px;
          }
  
          .box-body {
            color: #000;
            white-space: pre-line;
          }

          .box-attachment {
            align-self: self-end;
            display: flex;
            justify-content: end;
            flex-direction: column;
            gap: 1rem;
            align-items: end;

            p {

              @media (max-width: 575px) {
                font-size: 12px;
              }
            }
          }
        }
      }
    }

      .input {
        background-color: #313742;
        align-self: center;
        display: flex;
        flex-direction: column;
        width: 80%;
        margin-top: 10px;
        color: #6a6a6a;
        padding: 5px;
        border-radius: 3px;

        .label-input {
          transition: all 0.8s;
          font-size: 12px;
        }

        .login-input {
          border: none;
          width: 100%;
          background-color: #313742;
          color: #c7c7c7;
        }

        &:focus-within .label-input {
          text-shadow: 0px 0px 5px #c7c7c7;
          color: #c7c7c7;
        }
      }
    }
  }

  .historico {
        margin: 20px 10px;
        height: 100%;
        top: -8.55px;
        display: flex;
        flex-direction: column;
        border: 1px solid rgba(0, 0, 0, 0.2);
        position: relative;
        overflow-y: auto;
        padding: 10px;

        label {
          background-color: white;
          position: absolute;
          top: 0px;
          left: 10px;
          font-size: 14px;
          padding: 0 5px;
          z-index: 1050;
          color: rgba(0, 0, 0, 0.5);
          transition: color 0.5s;
        }

        .historico-box {
          display: flex;
          flex-direction: column;
          padding: 10px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);

          .box-title {
            display: flex;
            justify-content: space-between;
            color: #000;
            font-size: 14px;
            
            span {
              font-weight: bold;
              color: #933791;
            }
          }

          .box-body {
            color: #000;
          }
        }
      }

  @media (max-width: 800px) {
    .titulo {
      .titulo-identificacao {
        .infos {
          flex-direction: column;
          background: none;

          .info-nome,
          .info-setor,
          .info-assunto,
          .info-responsavel {
            align-items: center;
            width: 100%;
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .titulo {
      .titulo-identificacao {
        .infos {
          background: none;
          
          .info-nome,
          .info-setor,
          .info-assunto,
          .info-responsavel {
            align-items: center;
            width: 100%;
          }
        }
      }
    }
    .detalhes {
      flex-direction: column;
      .detalhe-chamado {
        .inputs {
          .input-row {
            flex-direction: column;
          }
        }
      }
      .detalhe-comentario {
        .comentario-acao {
          flex-direction: column;
          height: calc(100% - 30px);
        }
      }
    }
  }
`
export const SwitchButton = styled.button`
border: 1px solid ${colors.primary};
margin: 0 10px;
color: white;
background-color: ${colors.primary};
border-radius: 20% / 30%;
padding: 10px;

&.disabled {
  display: none;
}
`
export const Infos = styled.div`

  display: flex;
  gap: .5rem;
  flex-wrap: wrap;
  flex-direction: column;

  select {
    color: ${colors.primary};
    border: none;
    font-size: 16px;
  }

  @media(max-width: 800px) {
    padding: 0;
    /* margin-bottom: 25px; */
    gap: 0;
    flex-direction: column;
  }

  
        
`

export const Info = styled.div`
  display: flex;
  align-items: center;
  

  h3 {
    font-weight: normal;
    margin-right: 10px;
    font-size: 16px;
  }

  h4 {
    color: #933791;
    font-size: 16px;
    font-weight: normal;
  }

  select {
    font-size: 16px;
  }

  @media(max-width: 1500px) {
    
    
    h3, h4, option, select {
      font-size: 14px;
    }
    
  }
  
  
  @media(max-width: 1300px) {
    
    
    h3, h4, option, select {
      font-size: 12px;
    }
    
  }
  
  @media(max-width: 1024px) {
    
    
    h3, h4, option, select {
      font-size: 18px;
    }
    
  }

  @media(max-width: 768px) {
    margin-bottom: 0;


    h3, h4, option, select {
      font-size: 16px;
    }

  }
  
  @media(max-width: 575px) {
    margin-bottom: 0;


    h3, h4, option, select {
      font-size: 14px;
    }

  }
  
  `
export const InfosContainer = styled.div`
display: flex;
flex-wrap: wrap;
gap: 1rem;
row-gap: 0.5rem;
height: 40%;
padding: 35px 0;
justify-content: space-between;

@media(max-width: 1024px) {
  padding: 0;
  margin-bottom: 25px;
}

@media(max-width: 768px) {
  flex-direction: column;
  row-gap: 0rem;
}


@media(max-width: 575px) {
  flex-direction: column;
  row-gap: 0rem;
}
`

export const BorderDashed = styled.div`
width: 100%;
margin: 10px 0;
border-bottom: 2px dashed ${colors.primary};
display: none;

@media (max-width: 768px) {
  display: block;
}
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

`

export const InputRow = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between;
  align-items: center; */
  /* height: 50%; */
  height: 57%;
  position: relative;

`

export const InputMensagem = styled.div`
  min-height: 43px;
  background-color: white !important;
  border-radius: 0 0 10px 10px;
  border: 0 !important;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
  width: 100%;
  height: 100%;
  text-align: justify;
  /* margin: 25px 0 35px; */
  padding: 10px 10px 20px;
  overflow-y: auto;
  white-space: pre-line;

  @media(max-width: 1024px) {
  
  min-height: 150px;
}
`

export const ReplyContainer = styled.div`
height: 40%;
flex-grow: 5;
display: flex;
flex-direction: column;
box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
border-radius: 0 0 10px 10px;
background-color: white ;
justify-content: space-between;


`
export const DropContainer = styled.div`
height: 40%;
flex-grow: 1;

/* margin-top: 25px; */
display: flex;
flex-direction: column;
padding: 1rem;


`

export const TextArea = styled.textarea`
  min-height: 43px;
  border: 0 !important;
  width: 100%;
  text-align: justify;
  flex-grow: 2;
  /* margin: 25px 0 35px; */
  /* padding: 10px 10px 20px; */
  padding: 10px;
  overflow-y: auto;
  white-space: pre-line;
  resize: none;

  &.disabled {
    background-color: transparent;
    cursor: not-allowed;

  }

  @media(max-width: 1024px) {
  
  min-height: 150px;
}
`

export const Date = styled.div`
position: absolute;
font-size: 14px;
bottom: 2px;
right: 4px;
`

export const ButtonContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  place-content: end;
  align-items: center;
  gap: 8px;
  justify-content: space-evenly;

  &.closed {
    justify-content: end;
  }

  @media (max-width: 1024px) {
    padding-bottom: 3rem;
  }

  .newbuttoncomments { 
    margin-top: 20px;
        background-color: #00BF63 !important;
        height: 40px !important;
        border-radius: 50px;
        padding-left: 25px !important;
        padding-right: 25px !important;
        font-weight: bold !important;
        box-shadow: 0px 2px 0px 0px rgba(0,151,178,1);

        
        @media (max-width: 400px) {
          font-size: 12px !important;
          padding: 0 14px !important;
        }

        @media (max-width: 300px) {
          font-size: 10px !important;
        }

        &.newbuttonOpen {

        background-color: #ffa500 !important;
        box-shadow: 0px 2px 0px 0px rgba(214,183,60,1);
        justify-self: flex-end;
        }


        svg {
          font-size: 25px !important;
          margin-right: 0 !important;
        }

  }

  .newbuttonClose {
        font-weight: bold !important;
        margin-top: 20px;
        padding-left: 35px !important;
        padding-right: 35px !important;

        background-color: #FF3131 !important;
        height: 40px !important;
        border-radius: 50px;
        box-shadow: 0px 2px 0px 0px rgba(215,59,94,1);

        svg {
          font-size: 25px !important;
          margin-right: 0 !important;
        }

        @media (max-width: 400px) {
          font-size: 12px !important;
          padding: 0 14px !important;
        }

        @media (max-width: 300px) {
          font-size: 10px !important;
        }

  }
`



;


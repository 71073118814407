import styled, { css } from 'styled-components';
import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px;
  position: relative;


  ${props => (props.variant !== 'espalhar') && css`
    border-bottom: 1px solid ${colors.primary};
  `}

  ${props => (props.variant === 'espalhar') && css`
    flex-direction: column;
  `}

  ${props => (props.variant === 'full') && css`
    margin: 10px;
    border-radius: 10px;
    box-shadow: ${effects.boxshadow};
  `}

  ${props => (props.variant !== 'full') && css`
    // border-radius: 10px;
   
  `}

  ${props => (props.expand === true) && css`
    flex-direction: column;
  `}

  .focus {
    background-color: #fff;
  }

  .editor-paper {
    width: 100%;
    font-size: 16px;
    word-wrap: break-word;
    ${props => (props.variant === 'espalhar') && css`
      height: 200px;
      background: #fff;
      padding: 5px;
    `}
    
    @media (max-width: 575px) {
      overflow: hidden;
    }
  }

  .editor-paper[placeholder]:empty:before {
    content: attr(placeholder);
    color: ${colors.primary} 
  }

  .toolbox-teste {
    display: flex;
    ${props => (props.variant === 'espalhar') && css`
      width: 100%;
      justify-content: flex-end;
      margin: 10px 0px;
      
    `}

    .person-icon {
      @media (min-width: 751px) {
        display: none;
      }
      background-color: ${colors.primary};
      height: 35px;
      width: 35px;
      font-size: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      
      svg {
        color: white;
        font-size: 20px;
        margin-right: 0;
      }
    }

    .btn-submit {
      margin-left: 15px;
      ${props => (props.variant === 'espalhar') && css`
        background-color: ${colors.primary};
        font-weight: 700;
        text-transform: uppercase;
        height: 35px;
        color: #fff;
        border: 0;
        padding: 0 40px;
        border-radius: 25px;
        letter-spacing: 1px;
        box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.29);
        transition: background-color .5s;
        cursor: pointer;
        display: flex;
        outline: none;
        align-items: center;

        @media (max-width: 450px) {
          padding: 0 10px;
          font-size: 14px;
        }

        @media (max-width: 300px) {
          font-size: 12px;
        }

        svg {
          font-size: 18px;
          margin-right: 5px;
          color: #fff;
        }
      `}
    }

    .btn-cancelar {
      margin-left: 15px;
      ${props => (props.variant === 'espalhar') && css`
        background-color: ${colors.primary};
        font-weight: 700;
        text-transform: uppercase;
        height: 35px;
        color: #fff;
        border: 0;
        padding: 0 40px;
        border-radius: 25px;
        letter-spacing: 1px;
        box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.29);
        transition: background-color .5s;
        cursor: pointer;
        display: flex;
        outline: none;
        align-items: center;

        @media (max-width: 450px) {
          padding: 0 10px;
          font-size: 14px;
        }

        @media (max-width: 300px) {
          font-size: 12px;
        }

        svg {
          font-size: 18px;
          margin-right: 5px;
          color: #fff;
        }
      `}
    }
    
    ${props => (props.expand === true) && css`
      justify-content: flex-end;
    `}

    

    svg { 
      color: ${colors.primary};
      font-size: 18px;
      margin-right: 5px;
      cursor: pointer;
    }
  }
`

export const EditorAlert = styled.div`
  margin: 10px 0;

  span {
    background-color: ${colors.secondary};
    border-radius: ${effects.radius};
    padding: 2px 10px;
    color: ${colors.textinprimary};
    font-weight: 600;
  }
`

export const WrapAntigo = styled.div`
  width: 100%;
  padding: 0 20px 10px;
  position: relative;

  ${props => (props.variant === 'default') && css`
    padding: 20px 0px 10px;

    @media (max-width: 1440px) {
      padding: 20px 0px 10px 0px;
    }
  `}

  ${props => (props.variant === 'box') && css`
    background-color: ${colors.bgprimary};
    box-shadow: ${effects.boxshadow};
    border-radius: ${effects.radius};
  `}

  .editor-paper {
    background-color: ${colors.textinprimary};
    width: 100%;
    font-size: 16px;
    padding: 20px;
    margin-bottom: 15px;
    border-bottom: 1px solid ${colors.primary};
    word-wrap: break-word;
    height: 200px;

    @media (max-width: 1440px) {
      padding: 5px;
      margin-bottom: 10px;
    }
  }

  .editor-paper[placeholder]:empty:before {
      content: attr(placeholder);
      color: ${colors.primary} 
  }

  .box-alert {
    margin-bottom: 10px;

    span {
      background-color: ${colors.secondary};
      border-radius: ${effects.radius};
      padding: 2px 10px;
      color: ${colors.textinprimary};
      font-weight: 600;
    }
  }

  .tools {
    display: flex;
    justify-content: space-between;

    .midias {
      height: 50px;
      display: flex;

      button {
        background-color: transparent;
        color: ${colors.primary};
        border: 1px solid ${colors.border};
        font-weight: bold;

        svg { color: ${colors.primary} }
      }
    }

    button {
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      font-size: 18px;
      background-color: ${colors.primary};
      color: white;
      border: 0;
      margin-right: 10px;
      border-radius: ${effects.radius};

      @media (max-width: 1368px) {
        height: 35px;
      }

      svg {
        margin-right: 5px;
      }

      span {
        font-size: 16px;
      }
    }
  }

  input.input-post {
    background-color: ${colors.bgsecondary};
    width: 100%;
    height: 50px;
    font-size: 16px;
    padding: 10px;
    border-radius: ${effects.radius};
    margin-bottom: 15px;
    border: 0;

    @media (max-width: 1368px) {
      height: 40px;
    }
  }
`;


export const WrapTargets = styled.div`
  display: flex;
  flex-wrap: wrap;

  .item {
    display: flex;
    align-items: center;
    background: #fff;
    height: 25px;
    padding: 10px;
    margin: 2px;
    border-radius: ${effects.radius};
    
    box-shadow: ${effects.boxshadow};

    span {
      svg {
        cursor: pointer;
        font-size: 12px;
        margin-left: 5px;
        color: ${colors.primary};
      }
    }
  }
`
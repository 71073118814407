import styled from 'styled-components';

import colors from '../../../../presets/colors';
import effects from '../../../../presets/effects';

export const CustomSelect = styled.div`
  position: relative;
  z-index: 1200;
  width: 400px;
  max-width: 100%;
  font-size: 1.15rem;
  color: #000;


  .select-button {
  width: 100%;
  height: 23px;
  font-size: 1.15rem;
  background-color: #fff;
  padding: 1rem 0.75rem;
  border: 1px solid #caced1;
  border-radius: 0.25rem;
  cursor: pointer;



  display: flex;
  justify-content: space-between;
  align-items: center;
 }
 .selected-value {
  font-size: 15px;
  text-align: left;
}

.arrow {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #000;
  transition: transform ease-in-out 0.3s;
  transform: rotate(0deg);

}


&.active .arrow {
  transform: rotate(180deg); /* Rotate when active */
}

@media (max-width: 1280px) {
   margin-top: 1rem;
      
}


`;


export const SelectDropdown = styled.ul`

  position: absolute;
  list-style: none;
  width: 100%;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border: 1px solid #caced1;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  max-height: 200px;
  overflow-y: auto;
  transition: 0.5s ease;

  opacity: 0;
  visibility: hidden;
  transform: scaleY(0);
  transition: all 0.3s ease-in-out; 
  
  ${CustomSelect}.active & {
    opacity: 1;
    visibility: visible;
    transform: scaleY(1);
  }

  li {
  position: relative;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  align-items: center;

  &:hover {
    background-color: #f2f2f2;
  }

    input{
        &[type="radio"] {
        position: absolute;
        left: 0;
        opacity: 0;
    }
   
} 

   label {
    width: 100%;
    padding: 8px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 15px;
    
        &:hover {
        background-color: #f2f2f2;
        }

        &:focus-within {
            background-color: #dfdfdf;
        }
    
   }

  .icon{
    background-color: ${colors.primary};
        width: 30px;
        height: 30px;
        color: white;
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-right: 0.5rem;
      

    img{
        height: 21px;
        width: 16px;
    }
    svg{
        color: white;
    }
  }
 }



    &::-webkit-scrollbar {
    width: 7px;
    }
    &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 25px;
    }

    &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 25px;
    }
 
`;

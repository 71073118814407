import React, { useState, useEffect } from 'react';

import Page from '../../components/Page';
import AtendimentoHistorico from '../../components/AtendimentoHistorico';
import { Container } from './styles';

export default function HistoricoAtendimento() {

  return (
    <>
      <Page content={(
        <Container>
          <AtendimentoHistorico />
        </Container>
      )} />
    </>
  );
}
import styled from 'styled-components';

import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Container = styled.div`
  position: fixed;
  left: 0;
  top: 70px;
  width: 100%;
  height: calc(100% - 70px);
  display: flex;
  place-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.3);
`;

export const Modal = styled.div`
  width: 25%;
  height: 500px;
  padding: 20px;
  border-radius: 20px;
  background-color: white;

  @media (max-width: 1366px) {
    width: 30%;
  }

  @media (max-width: 1024px) {
    width: 40%;
  }

  @media (max-width: 800px) {
    width: 50%;
  }

  @media (max-width: 620px) {
    width: 65%;
  }

  @media (max-width: 500px) {
    width: 80%;
  }


  @media (max-width: 400px) {
    width: 90%;
    height: 450px;
  }
`
export const Head = styled.div`
  position: relative;
  height: 120px;
  padding: 0 10px;

  .close-button {
    position: absolute;
    right: -10px;
    top: -15px;
    background-color: white;
    border: none;
    
    svg {
      font-size: 25px;
    }
  }

  h2 {
    text-align: center;
    color: ${colors.primary}
  }

  .label {
    font-weight: 500;
    color: #2f2f2f;
    display: flex;
  }

  .info {
    margin-left: 5px;
    color: ${colors.primary}
  }

  .info-nota {
    display: flex;
    align-items: center;
    margin-left: 5px;
    color: #e6a525;
  }

  .separador {
    margin: 15px 0;
  }

  .info-atendimento {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
  }

`
export const Body = styled.div`
  width: 100%;
  height: calc(100% - 140px);
  margin-top: 20px;
  border-radius: 10px;
  background-color: #f9f9f9;

    .scroll-holder {
      height: 100%;
      width: 99%;
      overflow-y: auto;

      ::-webkit-scrollbar {
        width: 3px;
      }
  
      ::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      ::-webkit-scrollbar-thumb {
        background: ${colors.border};
        border-radius: ${effects.radius};
      }
    
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }

  .mensagens {
    font-size: 13px;
    padding: 10px;

    .status {
      color: #19b9a1;
      font-weight: 500;
    }
    
    p {
      font-weight: bold;
      margin-top: 5px;

      .comentario-cliente {
        font-weight: normal;
        font-style: italic;
      }
    }


    .conversa {
      margin: 5px 0;
      display: flex;

      .timeline {
        width: 2px;
        margin: 11px 12px 11px 2px;
        background-color: #19b9a1;
      }

      .msgs {
        font-size: 14px;
        display: flex;
        flex-direction: column;

        .from {
          position: relative;
          margin-bottom: 3px;
          font-weight: bold;
          color: ${colors.primary};

          .dot-from {
            position: absolute;
            left: -16px;
            top: 7px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: ${colors.primary};
          }
        }

        .to {
          position: relative;
          margin-bottom: 3px;
          font-weight: bold;
          color: #2B2B2B;

          .dot-to {
            position: absolute;
            left: -16px;
            top: 7px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #2B2B2B;
          }
        }

        .message {
          font-weight: normal;
          color: #2f2f2f;

          i {
            color: blue;
          }
        }
      }
    }
  }
`

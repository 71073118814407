import React from 'react';

import ConversaExterno from '../../components/ConversaExterno'

import {useParams} from "react-router-dom";

export default function EmbedChat() {

  const { empresa } = useParams();

  return (
    <ConversaExterno local='externo' empresa={empresa}/>
  );
}

import styled from 'styled-components';

import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  h1 {
    @media(max-width: 325px) {
      font-size: 1.15em;
    }
  }
`;

export const Head = styled.div`
  margin-top: 15px;

  .separador {
    margin-top: 30px;
    margin-bottom: 5px;
  }
`

export const Filters = styled.div`
  width: 100%;
  padding: 15px 20px;
  display: flex;
  align-items: center;

  @media(max-width: 580px) {
    flex-direction: column;
  }

  .filtering {
    display: flex;
    width: 50%;

    @media(max-width: 580px) {
      width: unset;
    }
    
    @media (max-width: 470px) {
      width: 100%;
    }

    .search {
      display: flex;
      border-radius: 5px;

      @media (max-width: 470px) {
        width: 100%;
      }
      
      svg {
        height: 30px;
        width: 30px;
        padding: 5px;
        background: white;
        border: solid 1px #9a9a9a;
        border-right: none;
        border-radius: 5px 0px 0px 5px;
      }
      input {
        line-height: 1;
        height: 30px;
        resize: none;
        border: solid 1px #9a9a9a;
        border-left: none;
        border-radius: 0px 5px 5px 0px;

        @media (max-width: 470px) {
          width: 100%;
        }
      }
    }

    .refresh-button {
      margin-right: 10px;
      min-height: 30px;
      min-width: 30px;
      border: 1px solid #922c88;
      border-radius: 5px;
      display: flex;
      align-items: center;
      place-content: center;

      svg {
        font-size: 20px;
      }
    }
  }

  .pagination {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media(max-width: 580px) {
      margin-top: 10px;
      width: unset;
    }

    @media (max-width: 470px) {
      width: 100%;
      justify-content: center;
    }

    .select {
      margin-right: 15px;
      border-radius: 5px;
      border: solid 1px #9a9a9a;
      padding: 5px;
    }

    button {
      width: 25px;
      height: 25px;
      padding: 4px;
      margin-left: 10px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
      border: 2px solid #922c88;
      transition: background 0.2s linear;
      transition: color 0.2s linear;
    
      &:hover{
        background: #922c88;
        color: white;
      }
    }
  }
`

export const Body = styled.div`
  width: 100%;
  overflow: auto;
  height: calc(100% - 160px);

  @media (max-width: 580px) {
    height: calc(100% - 160px);
  }

  table, th, td {
    border: groove 1px;
  }
  table {
    margin-top: 8px;
    width: 100%;
    border-collapse: collapse;
  }

  .id-table {
    min-width: 80px;
  }

  .info-table {
    min-width: 180px;

    svg {
      color: #e6a525;
    }
  }

  th {
    height: 50px;
  }

  td {
    font-size: 14px;
    height: 50px;
    text-align: center;
  }

  .abrir-atendimento {
    color: white;
    background: #5cb85c;
    border: none;
    padding: 3px 10px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

    /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${colors.border};
    border-radius: ${effects.radius};
  }

    /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`


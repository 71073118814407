import React, { useState, useEffect } from 'react';

import { Container } from './styles';

import {
    RiDeleteBin6Line,
    RiCloseLine
} from 'react-icons/ri';


function ModalViews({ type, setConfirmaDelete, deletar, setShowOptions }) {

    return (
        <Container>
            <div className="modal-confirma">
                <div className="head-modal">
                    <h4>Excluir {type}?</h4>
                </div>
                <hr></hr>
                <div className="options-confirma">
                    <div className="buttons">
                        <div className="delete" onClick={deletar}><RiDeleteBin6Line /> <span>Excluir</span></div>
                        <div className="cancel" onClick={() => { setConfirmaDelete(false); setShowOptions(false) }}><RiCloseLine /> <span>Cancelar</span></div>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default ModalViews;
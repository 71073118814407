import styled from 'styled-components';

export const Wrap = styled.div`
  margin-left: 5px;
  
  .icon {
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
    
  .name-user{
    padding: 10px;
  }

  img {
    width: 40px;
    height: 40px; 
    border-radius: 50%;  
    object-fit: cover;
  }
`;

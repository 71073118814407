import styled from 'styled-components';
import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Container = styled.div`
  width: ${({ inside, type }) => inside !== true ? type !== 'impar' ? '100%' : 'calc(50% - 10px)' : ''};
  height: ${({ type, inside }) => inside !== true ? type === 'border' ? '40px' : '45px' : ''};
  border: ${({ inside }) => inside !== true ? `1px solid ${colors.border}` : ''};
  margin-bottom: ${({ type, inside }) => inside !== true ? type === 'border' ? '' : '10px' : ''};
  border-radius: ${({ type }) => type === 'border' ? '20px' : '10px'};
  display: flex;
  align-items: center;
  background-color: ${({ hasImg }) => hasImg ? '#c9c9c9' : 'white'};

  svg {
    font-size: 20px;
    margin-left: 5px;
    color: #6E6E6E;
    position: relative;
    top: 2px;
  }

  .info-modal {
    position: relative;
  }

  .color-label {
    font-size: ${({ type }) => type === 'border' ? '13px' : '14px'};
    margin-left: ${({ type }) => type === 'border' ? '10px' : '5px'};
    width: 100%;
    font-weight: 500;
    text-align: center;
    line-height: 16px;

    @media(max-width: 1300px) {
      font-size: 12px;
    }

    @media(max-width: 450px) {
      font-size: 11px;
    }
  }
  
  input {
      border: 0;
      width: calc(100% - 120px);
      margin-right: 10px;
      height: 100%;
      border-radius: 10px;
      padding: 0 10px;
      text-align: end;

    &:disabled {
        background-color: #fafafa;
    }
  }
  
  .wrap-colorpicker {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    button {
      width: ${({ type }) => type === 'border' ? '23px' : '30px'};
      height: ${({ type }) => type === 'border' ? '23px' : '25px'};
      box-shadow: ${effects.boxshadow};
      background-color: ${({ color }) => color.hex};
      border-radius: ${({ type }) => type === 'border' ? '50%' : '10px'};;
      cursor: pointer;
      border: 0;
      margin-right: ${({ type, inside }) => inside !== true ? type === 'border' ? '8px' : '7px' : ''};

      @media (max-width: 450px) {
        width: 25px;
        height: 20px;
      }
    }

    .close-input-color {
      width: 25px;
      height: 25px;
      color: ${colors.btn_text_ok};
      background-color: ${colors.secondary};
      border-radius: 50%;
      position: absolute;
      top: -14px;
      right: -14px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        font-size: 15px;
        color: ${colors.btn_text_ok};
        margin: 0;
        top: 0;
      }
    }

    .colorpicker {
      background-color: white;  
      position: absolute;
      z-index: 1052;
      top: ${({ inside }) => inside === true ? '' : 'calc(100% + 10px)'};
      bottom: ${({ inside }) => inside === true ? 'calc(100% + 10px)' : ''};
      left: -100px;
    }

    .colorpicker-right {
      background-color: white;  
      position: absolute;
      z-index: 1052;
      top: ${({ inside }) => inside === true ? '' : 'calc(100% + 10px)'};
      bottom: ${({ inside }) => inside === true ? 'calc(100% + 10px)' : ''};
      left: -180px;
    }
  }
`;

export const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1051;
`;

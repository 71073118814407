import React from 'react';
import { RiArrowDownLine, RiCloseLine, RiFile2Fill } from "react-icons/ri";
import getFiles from '../../../util/getFiles';
import { Files, FullScreenImg, IconName, Text } from './styles';
import { useState } from 'react';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import { FaTrashAlt } from 'react-icons/fa';
import { MdDownload } from 'react-icons/md';


export const FilesBox = ({path, nameFile, typeFile, classCustom, index, deleteFile}) => {

    const [fullImg, setFullImg] = useState(false)




    const download = () => {
        var url = getFiles(path);
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function () {
            var a = document.createElement('a');
            a.href = window.URL.createObjectURL(xhr.response); // xhr.response is a blob
            a.download = nameFile; // Set the file name.
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            //delete a;
        };
        xhr.open('GET', url);
        xhr.send();
    }

    const hasClassBox = classCustom.includes("box")
    const hasClassSmall = classCustom.includes("small")
    const hasClassText = classCustom.includes("text")

    return (
        <>

            {fullImg &&
            <FullScreenImg>

                    <div className="fullscreen-holder">
                        <div className="open-close-image">
                            <a href={getFiles(fullImg.path)} target="_blank">Ver original</a>
                            <button className="btn-delete-img" type="button" onClick={() => setFullImg(false)}><RiCloseLine /></button>
                        </div>
                        <img alt='name' src={getFiles(fullImg.path)}></img>
                    </div>
                
            </FullScreenImg>
            }
            {
                hasClassBox && <Files className={classCustom} >
                    {typeFile === "image" ? <img alt={nameFile} className="img-inchat" src={getFiles(path)} onClick={() => setFullImg({
                    name: nameFile,
                    path
                    })}></img>
                    : <div className="download-file">
                        <IconName>

                        <RiFile2Fill />
                        <span>{nameFile}</span>
                        </IconName>
                        <button onClick={() => { download() }}><RiArrowDownLine /></button>
                        
                    </div>}
                </Files>
            }
            {
                hasClassSmall && <Files className={classCustom}>
                    <div className="download-file">
                        <IconName>

                        <RiFile2Fill />
                        <span>{nameFile}</span>
                        </IconName>
                        <button onClick={() => deleteFile(index)} className='trash'><FaTrashAlt/> </button>
                    
                    </div>
                </Files>
            }

            {
                hasClassText && <Text onClick={() => { download() }}>
                    <MdDownload /> <p>{nameFile}</p>
                </Text>
            }
        </>
    )
}
import styled from 'styled-components';
import colors from '../../../presets/colors';
import effects from '../../../presets/effects';

export const Container = styled.div`
  width: 100%;
  display: flex;
  place-content: center;

  @media (max-width: 1140px) {
    flex-direction: column;
    align-items: center;
  }

  .aparencia-preview {
    display: flex;

    .color-and-preferences {
      display: flex;

      @media (max-width: 750px) {
        flex-direction: column;
        align-items: center;
      }

      .colorbox {
        width: 450px;
        padding: 10px 15px;
        border-radius: 20px;
        background: white;
        //background-color: rgba(142, 142, 142, 0.1);
        box-shadow: ${effects.boxshadow};

        @media (max-width: 1325px) {
          width: 380px;
        }

        @media (max-width: 1140px) {
          margin-bottom: 30px;
        }

        @media (max-width: 750px) {
          width: 400px;
        }

        @media (max-width: 450px) {
          width: 350px;
        }

        @media (max-width: 375px) {
          width: 300px;
        }

        p {
          font-size: 12px;
          margin: 15px 0px;
          padding: 0 10px;
          text-align: justify;
        }

        &.first {
          width: 400px;
          margin-right: 30px;

          @media(max-width: 1325px) {
            width: 350px;
          }

          @media(max-width: 1200px) {
            width: 300px;
          }

          @media(max-width: 750px) {
            width: 400px;
            margin-right: 0px;
          }

          @media (max-width: 450px) {
            width: 350px;
          }

          @media (max-width: 375px) {
          width: 300px;
        }
        }

        .color-row {
          display: flex;
          position: relative;
          padding-left: 15px;
          justify-content: space-between;
        }
      }

      .color-row-radio {
        padding: 0 15px;
        margin-top: 40px;
        margin-bottom: 50px;

        .radio-row {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          label {
            margin-left: 5px;
            font-size: 13px;
            font-weight: 500;
          }
        }
      }

      .box-header {
        padding: 0 15px;
        position: relative;

        .info-fundo {
          font-size: 13px;
        }

        .help-icon {
          position: absolute;
          right: 10px;
          top: 0px;

          .modal-help-color {
            width: 100vw;
            height: 100vh;
            position: fixed;
            left: 0;
            top: 0;
            display: flex;
            align-items: center;
            place-content: center;
            background-color: rgba(0,0,0,0.7);
            z-index: 3999;

            .backdrop {
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;
              z-index: 4000;
            }

            .modal {
              margin: 20px;
              padding: 50px 60px;
              display: flex;
              flex-direction: column;
              z-index: 4001;
              border-radius: 25px;
              width: 700px;
              height: 500px;
              background: white;
              overflow-y: auto;
              position: relative;

              .close-button {
                width: 30px;
                height: 30px;
                position: absolute;
                border: none;
                right: 10px;
                top: 10px;
                background: transparent;

                @media (max-width: 500px) {
                  right: 5px;
                  top: 5px;
                }
                
                svg {
                  margin: 0;
                  color: #333;
                  background: transparent;
                  font-size: 30px;

                  @media (max-width: 500px) {
                    font-size: 20px;
                  }
                }
              }

              .modal-content {
                overflow-y: auto;
                padding-right: 10px;
              }

              @media (max-width: 700px) {
                padding: 30px 10px;
                padding-right: 0;
              }

              strong {
                color: ${colors.primary};
              }

              .header {
                display: flex;
                place-content: center;
                text-align: center;
                margin-bottom: 20px;

                svg {
                  margin-right: 20px;
                  font-size: 40px;
                  color: ${colors.primary};

                  @media (max-width: 625px) {
                    font-size: 26px;
                    margin-right: 5px;
                  }
                }

                h3 {
                  font-size: 26px;

                  @media (max-width: 625px) {
                    font-size: 18px;
                  }
                }
              }

              .content {
                padding: 0px 60px;
                font-size: 16px;

                @media (max-width: 625px) {
                  padding: 0px 10px;
                }
              }

              &::-webkit-scrollbar {
                width: 4px;
              }
              
              &::-webkit-scrollbar-thumb {
                border-radius: 20px;
                background-color: black;
              }
            }
          }

          svg {
            font-size: 25px;
            color: ${colors.primary};
            cursor: pointer;
          }
        }
      }

      h5 {
        font-size: 16px;
        margin: 20px 0px;
        font-weight: bold;
        color: #333333;
      }

      hr {
        border: 1px solid rgba(48,48,48,.15);
        height: 1px;
        margin-bottom: 5px;
      }

      .aparencia-definicoes {
        padding: 0 15px;
      }

      .content-painel-holder {
        width: 100%;
        display: flex;
        place-content: center;
        margin-bottom: 15px;
      }

      .content-painel {
        width: 100%;
        height: 200px;
        position: relative;

        @media (max-width: 1325px) {
          height: 150px;
        }

        @media (max-width: 1200px) {
          height: 130px;
        }

        .btn-background {
          width: 100%;
          height: 100%;
          font-size: 50px;
          background-color: ${colors.bgsecondary};
          border: 1px solid #d0d0d0;
          border-radius: 10px;
          position: relative;
          overflow: hidden;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;

          .image-filter {
            width: 100%;
            height: 100%;
            background-color: ${colors.bgsecondary};
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
              opacity: .75;
            }
          }

          .filter-in-image {
            position: absolute;
            width: 100%;
            height: 100%;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .remove-background {
          width: 25px;
          height: 25px;
          color: ${colors.btn_text_ok};
          background-color: ${colors.secondary};
          border-radius: 50%;
          position: absolute;
          top: -12.5px;
          right: -12.5px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .filtered {
        background-color: blue;
      }

      #input-company-background-image {
        display: none;
      }
    }
  }
`;

import { apiGeral } from '../config';

export default (path="") => {
    if(path != "") {

        const real_path = path.split('uploads')[1].replace('\\', '/');
        
        return `${apiGeral + real_path}`;
    }

    return "";
}

import styled from 'styled-components';

export const Container = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 3000;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;

    .image-holder {
        width: calc(100vw - 110px);
        height: calc(100vh - 90px);
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 90px;
        left: 110px;

        @media (max-width: 1440px) {
            height: calc(100vh - 70px);
            top: 70px;
        }

        @media (max-width: 1024px) {
            width: 100vw;
            left: 0;
        }

        button {
            background-color: rgba(255, 255, 255, 0.1);
            color: white;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: 0;
            z-index: 10000;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 30px;

            position: fixed;
            top: calc(${({ top }) => top + 'px'} - 50px);
            left: ${({ left }) => left + 'px'};
        }

        a { 
            height: 30px;
            display: flex;
            width: 30px;
            place-content: center;
            align-items: center;
            position: fixed;
            top: calc(${({ topLink }) => topLink + 'px'} - 50px);
            left: ${({ leftLink }) => leftLink + 'px'};
            font-size: 22px;
            z-index: 10000;
            border-radius: 50%;
            background-color: rgba(255, 255, 255, 0.1);
            color: white;
            text-decoration: none;
            margin-right: 10px;
        }
    }

    img {
        max-height: 80%;
        max-width: 80%;
        //min-width: 30%;
        z-index: 1050;
        //position: absolute;

        @media (min-width: 1400px) {
            &.quadrada {
                min-width: 35%;
            }

            &.horizontal {
                min-width: 35%;
            }
        }
    }

    .vertical {
        min-width: unset;
    }

    @media (max-width: 575px) {
        img {
            min-width: 90%;
        }
    }

    @media (min-width: 576px) and (max-width: 1023px) {
        img {
            min-width: 60%;
            top: 120px;
        }
    }

    @media (min-width: 1024px) and (max-width: 1399px) {
        img {
            //min-width: 55%;
            top: 100px;

            &.quadrada {
                min-width: 35%;
            }
        }
    }
`;

export const Backdrop = styled.div`
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
`;

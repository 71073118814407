import React, { useState, useEffect } from 'react';
import {
  RiChatSmileLine,
  RiHistoryLine,
  RiInformationLine,
  RiKeyboardLine,
  RiWhatsappLine
} from 'react-icons/ri';
import { FiMapPin } from "react-icons/fi";
import { BsGearFill, BsSearch, BsPlus, BsPencilFill, BsTrashFill, BsFillTelephoneFill } from "react-icons/bs";
import { MdClose } from 'react-icons/md';

import Assuntos from './Assuntos';

import { sessionGet } from '../../../session';
import api from '../../../services/api-geral';

import Swal from 'sweetalert2';
import Alerta from '../../../util/Alerta';

import { Info, Toggles, NovoAssunto, Button, SetorSelector, SetorOption, LabelCustomInput, WrapCustomInput } from './styles';

import PreviewMobile from '../../PreviewMobile';

import CInput from '../../../components/ComponentInput';

import ModalInformation from '../ModalInformation';

function Chat({ setSwitchTab, switchTab, setTabs, refresh, doRefresh, data, idPage, tab, reload }) {
  const [page, setPage] = useState();
  const [flagChat, setFlagChat] = useState();
  const [flagHistorico, setFlagHistorico] = useState();
  const [flagForm, setFlagForm] = useState();
  const [flagWhatsapp, setFlagWhatsapp] = useState();
  const [flagEndereco, setFlagEndereco] = useState();
  const [modalInformation, setModalInformation] = useState('');
  const [modalInfo, setModalInfo] = useState();
  const [telefoneEmpresa, setTelefoneEmpresa] = useState(() => {
    if (data) {
      return data.whatsapp;
    } else {
      return "";
    }
  });
  const [endereco, setEndereco] = useState(() => {
    if (data) {
      return data.endereco;
    } else {
      return "";
    }
  });
  const [buttonName, setButtonName] = useState(() => {
    if (data) {
      return data.buttonName;
    } else {
      return "Fale Conosco";
    }
  });
  const [selectedArray, setSelectedArray] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [pesquisa, setPesquisa] = useState('');

  const [showChat, setShowChat] = useState(false);
  const [showWpp, setShowWpp] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showEndereco, setShowEndereco] = useState(false);

  const [assuntos, setAssuntos] = useState([]);
  const [nameAssunto, setNameAssunto] = useState('');
  const [assuntoSetor, setAssuntoSetor] = useState(0)
  const [setores, setSetores] = useState([]);

  const [type, setType] = useState('none');
  const [showModal, setShowModal] = useState(false);

  const [idAssunto, setIdAssunto] = useState();
  const [editNameAssunto, setEditNameAssunto] = useState();

  const [phoneMask, setPhoneMask] = useState('phone');


  var variance = 0;

  useEffect(() => {
    if (switchTab !== 'chat') {

      if (data.whatsapp !== telefoneEmpresa) {
        variance = 1;
      }

      if (data.endereco !== endereco) {
        variance = 1;
      }

      if (data.buttonName !== buttonName) {
        variance = 1;
      }

      if (variance === 0) {
        setTabs(switchTab);
      } else {
        Swal.fire({
          title: 'Atenção',
          text: 'Você possui alterações não publicadas, continuar mesmo assim?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Descartar',
          cancelButtonText: 'Não'
        }).then(async (result) => {
          if (result.isConfirmed) {
            setTabs(switchTab);
          } else {
            setSwitchTab('chat');
          }
        });
      }
    }
  }, [switchTab]);

  useEffect(() => {
    if (refresh !== 0) {
      updateWpp();
      updateAdress();
      handleButtonName()
    }
  }, [refresh]);

  useEffect(() => {
    if (flagChat) { setShowChat(true) } else { setShowChat(false) }
    if (flagWhatsapp) { setShowWpp(true) } else { setShowWpp(false) }
    if (flagForm) { setShowForm(true) } else { setShowForm(false) }
    if (flagEndereco) { setShowEndereco(true) } else { setShowEndereco(false) }
  }, [flagWhatsapp, flagChat, flagForm, flagEndereco])

  useEffect(() => {
    loadInfos();
    getAssuntos();
    getDadosEmpresa();
    getSetor();
  }, []);

  /*useEffect(() => {
    handleUpdateFlags();
  }, [flagChat, flagHistorico, flagForm, flagWhatsapp]);*/

  useEffect(() => {
    if (data) {
      setPage(data.id);
    }
  }, [data]);

  const getAssuntos = async () => {
    const response = (await api.get('formulario-assunto-all')).data;
    setAssuntos(response);
  }
  
  const getSetor = async () => {
    const response = (await api.get('/setor?offset=null')).data;
    setSetores(response);
  }

  const addAssunto = async () => {
    const response = (await api.post('/formulario-assunto/', { name: nameAssunto, setor: assuntoSetor === 0 ? null : assuntoSetor })).data;

    if (response.type === 'success') {
      Swal.fire({
        title: 'Sucesso!',
        text: 'Assunto adicionado.',
        icon: 'success',
        showConfirmButton: false,
        timer: 2000,
      })
    } else {
      Swal.fire({
        title: 'Erro!',
        text: 'Já existe um assunto com esse nome.',
        icon: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok'
      })
    }
    setShowModal(false);
    setNameAssunto('');
    getAssuntos();
  }

  const openEdit = (id, name) => {
    setNameAssunto(name);
    setIdAssunto(id);
    setEditNameAssunto(name);
    setShowModal(true);
    setType('edit')
  }

  const editAssunto = async () => {
    const response = (await api.put(`/formulario-assunto/${idAssunto}`, { name: nameAssunto, setor: assuntoSetor === 0 ? null : assuntoSetor })).data;
    if (response.type === 'success') {
      Swal.fire({
        title: 'Sucesso!',
        text: response.msg,
        icon: 'success',
        showConfirmButton: false,
        timer: 2000,
      })
    } else {
      Swal.fire({
        title: 'Erro!',
        text: response.msg,
        icon: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok'
      })
    }
    setNameAssunto('');
    setShowModal(false);
    getAssuntos();
  }

  const deleteAssunto = async (id) => {
    /*selectedArray.map((item, index) => {
      if (index === (selectedArray.length - 1)) {
        id = id + `${item}`
      } else {
        id = id + `${item},`
      }
    })*/
    Swal.fire({
      title: 'Tem certeza?',
      text: 'Você precisa CONFIRMAR para executar esta ação:',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = (await api.delete(`/formulario-assunto/${id}`)).data;
        if (response.type === 'success') {
          Alerta.success(response.msg);
          getAssuntos();
        } else {
          Alerta.error(response.msg);
        }
      }
    });
  }

  const getDadosEmpresa = async () => {
    //const response = (await api.get('/page')).data;
    //setTelefoneEmpresa(response.whatsapp);
    //setEndereco(response.endereco);
  }

  const updateWpp = async () => {
    if (flagWhatsapp === true) {
      if (telefoneEmpresa === '' || telefoneEmpresa === '(__) _____-____') {
        Swal.fire({
          title: 'Erro!',
          text: 'Insira um telefone válido ou desabilite o telefone caso não queira adicionar um.',
          icon: 'error',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok'
        });
        setFlagWhatsapp(false);
        return;
      }
    }

    await api.put('/page-updatephone', {
      telefoneEmpresa
    });

    Swal.fire({
      title: 'Alterações salvas!',
      icon: 'success',
      showConfirmButton: false,
      timer: 2000,
    });
    variance = 0;
    doRefresh(0);
    reload();
  }

  const updateAdress = async () => {
    const response = (await api.put('/page-updateaddress', {
      endereco
    })).data;
    Swal.fire({
      title: 'Alterações salvas!',
      icon: 'success',
      showConfirmButton: false,
      timer: 2000,
    });
    variance = 0;
    doRefresh(0);
    reload();  
  }

  const handleButtonName = async () => {
    const response = (await api.put('/page-buttonname', {
      buttonName
    })).data;
    Swal.fire({
      title: 'Alterações salvas!',
      icon: 'success',
      showConfirmButton: false,
      timer: 2000,
    });
    variance = 0;
    doRefresh(0);
    reload();   
  }

  const loadInfos = async () => {
    const response = (await api.get('/page?scope=flags')).data;

    if (response !== null) {
      setFlagChat(response.flagChat);
      setFlagHistorico(response.flagHistorico);
      setFlagWhatsapp(response.flagWhatsapp);
      setFlagForm(response.flagForm);
      setFlagEndereco(response.flagEndereco);
    }
  };

  const handleUpdateFlags = async (flag, type) => {
    if (type === 'chat') {
      await api.put('/page?scope=flags', {
        flagChat: flag
      });
      setFlagChat(flag);
    } else if (type === 'historico') {
      await api.put('/page?scope=flags', {
        flagHistorico: flag
      });
      setFlagHistorico(flag);
    } else if (type === 'whatsapp') {
      await api.put('/page?scope=flags', {
        flagWhatsapp: flag
      });
      setFlagWhatsapp(flag);
      updateWpp();
    } else if (type === 'form') {
      await api.put('/page?scope=flags', {
        flagForm: flag
      });
      setFlagForm(flag);
    } else if (type === 'endereco') {
      await api.put('/page?scope=flags', {
        flagEndereco: flag
      });
      setFlagEndereco(flag);
      updateAdress();
      console.log("if (type === 'endereco'")

    }
    if (type !== 'whatsapp' && type !== 'endereco') {
      Swal.fire({
        icon: 'success',
        title: 'Alterações salvas',
        showConfirmButton: false,
        timer: 1500
      })
    }
  };

  useEffect(() => {
    if (telefoneEmpresa === '(__) ____-____') {
      setFlagWhatsapp(false);
    }

    var numberPattern = /\d+/g;

    if (telefoneEmpresa) {
      let numbers = telefoneEmpresa.match(numberPattern);

      if (numbers !== null) {
        numbers = numbers.join('');

        if (numbers[2] === '9') {
          setPhoneMask('phone');
        } else {
          setPhoneMask('telephone');
        }
      }
    }
  }, [telefoneEmpresa])

  return (
    <div className="wrap-panels chat">
      <Toggles>
        <div className="toggles-contato">
          <div className="info-holder">
            {modalInfo === 1 && (
              <ModalInformation modalClass={modalInformation} top='120px' right='80px' content='Caso desative este item, o histórico da conversa com o cliente não será enviado para o e-mail do mesmo.' />
            )}
            <div className={`toggle-chat ${showChat ? 'active' : ''}`}>
              <div className="content-toggle-chat">
                <div className="toggle">
                  <div className="title">
                    <div className="wrap-icon">
                      <RiChatSmileLine />
                    </div>
                    <span>Chat com cliente</span>
                  </div>
                  <div onClick={() => handleUpdateFlags(!flagChat, 'chat')} className={`toggle-funcionalidade ${flagChat && 'active'}`}>
                    <div className="indicator"></div>
                  </div>
                </div>
                <div className="toggle-historico">
                  <div className="wrap-historico">
                    <div className="wrap-icon">
                      <RiHistoryLine />
                    </div>
                    <span>Histórico de conversa</span>
                  </div>
                  <Info>
                    <div className='info-modal'>
                      <RiInformationLine onMouseOver={() => { setModalInformation('show'); setModalInfo(1) }} onMouseOut={() => { setModalInformation(''); setModalInfo(0) }} />
                    </div>
                  </Info>
                  <div onClick={() => handleUpdateFlags(!flagHistorico, 'historico')} className={`toggle-funcionalidade ${flagHistorico && 'active'}`}>
                    <div className="indicator"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="info-holder phone">
            {modalInfo === 3 && (
              <ModalInformation modalClass={modalInformation} top='60px' right='80px' content="Antes de habilitar este campo, insira um número de telefone válido." />
            )}
            <div className={`toggle-whatsapp active`}>
              <div className="toggle">
                <div className="title">
                  <div className="wrap-icon phone">
                    <BsFillTelephoneFill />
                  </div>
                  <span>Telefone de contato</span>
                </div>
                <Info>
                  <div className='info-modal'>
                    <RiInformationLine onMouseOver={() => { setModalInformation('show'); setModalInfo(3) }} onMouseOut={() => { setModalInformation(''); setModalInfo(0) }} />
                  </div>
                </Info>
                <div onClick={() => handleUpdateFlags(!flagWhatsapp, 'whatsapp')} className={`toggle-funcionalidade ${flagWhatsapp && 'active'}`}>
                  <div className="indicator"></div>
                </div>
              </div>
              <div className="whatsapp-number">
                <CInput classCustom="company-phone" val={telefoneEmpresa} change={e => { setTelefoneEmpresa(e); }} type='text' mask={phoneMask} style='radius' />
              </div>
            </div>
          </div>
        </div>
        <div className={`toggle-endereco ${showEndereco ? 'active' : ''}`}>
          <div className="toggle">
            <div className="title">
              <div className="wrap-icon">
                <FiMapPin />
              </div>
              <span>Endereço</span>
            </div>
            <div onClick={() => handleUpdateFlags(!flagEndereco, 'endereco')} className={`toggle-funcionalidade ${flagEndereco && 'active'}`}>
              <div className="indicator"></div>
            </div>
          </div>
          <div className="address-page">
            <CInput classCustom="address" val={endereco} change={e => { setEndereco(e); }} type='text' style='radius' />
          </div>
        </div>
        <div className={`toggle-formulario ${showForm ? 'active' : ''}`}>
          <div className="toggle">
            <div className="title">
              <div className="wrap-icon">
                <RiKeyboardLine />
              </div>
              <span>Contato</span>
            </div>
            <div onClick={() => handleUpdateFlags(!flagForm, 'form')} className={`toggle-funcionalidade ${flagForm && 'active'}`}>
              <div className="indicator"></div>
            </div>
          </div>
          <div className="actions">
            <div className="search-filter">
              <div className="search">
                <BsSearch />
                <input placeholder="Assunto" value={pesquisa} onChange={(e) => setPesquisa(e.target.value)}></input>
              </div>
            </div>
          </div>
          <div className='button-name'>
              <span>Nome do botão</span>
            <CInput classCustom="input-button-name" val={buttonName} change={e => { setButtonName(e); }} placeholder="Fale Conosco" type='text' style='radius' />
              {/* <input type="text" /> */}
          </div>
          <div className="assuntos">
            <div className="select-all">
              {/* <div onClick={() => { setSelectAll(!selectAll) }} className={`checkbox ${selectAll ? 'active' : ""}`}></div> */}
              <div className='assuntos-title'>
              {modalInfo === 2 && (
              <ModalInformation modalClass={modalInformation} bottom='0px' right='-250px' content='Chamados criados com assuntos vinculados a um setor notificarão exclusivamente os funcionários pertencentes a esse setor.' />
            )}
              <span>Assuntos</span>
              <Info className='info-assunto'>
                    <div className='info-modal-2'>
                      <RiInformationLine onMouseOver={() => { setModalInformation('show'); setModalInfo(2) }} onMouseOut={() => { setModalInformation(''); setModalInfo(0) }} />
                    </div>
                  </Info>
              </div>
            
            </div>
            <div className="buttons-actions">
              <button onClick={() => { setType('new'); setShowModal(true); setAssuntoSetor(null) }} className="button-add"><BsPlus /></button>
              {showModal && (
                <NovoAssunto >
                  <div className="modal">
                    <Button onClick={() => { setShowModal(false); setNameAssunto('') }}><MdClose /></Button>
                    <div className="head">
                      <h2>{type === 'new' ? 'Cadastrar assunto' : type === 'edit' ? 'Editar assunto' : ''}</h2>
                    </div>
                    <div className="content">
                      <CInput classCustom="company-phone" val={nameAssunto} change={e => setNameAssunto(e)} type='text' label='Nome' style='radius' focused={true} />
                      <WrapCustomInput className={`input-style company-phone`} variant='radius'>
                        <LabelCustomInput for="setor">Setor</LabelCustomInput>
                        <SetorSelector value={assuntoSetor} onChange={(e) => {setAssuntoSetor(parseInt(e.target.value, 10)) }} name='setor'> 

                            <SetorOption value={0}>Nenhum</SetorOption>
                            {setores.map((setor, index) => (
                              <SetorOption key={index} value={setor.id}>{setor.name}</SetorOption>
                            ))}
                        </SetorSelector>
                      </WrapCustomInput>
                    </div>
                    <div className="save">
                      <button onClick={() => { type === 'new' ? addAssunto() : editAssunto() }}>SALVAR</button>
                    </div>
                  </div>
                  <div onClick={() => { setShowModal(false); setNameAssunto('') }} className="backdrop"></div>
                </NovoAssunto>
              )}
              {/* <button onClick={() => { deleteAssunto() }} className="button-delete"><BsTrashFill /></button> */}
            </div>
            <div className="assuntos-list">
              {(pesquisa == '') ?
                <>
                  {assuntos.map((assunto, index) => (
                    <>
                      <Assuntos setSetor={setAssuntoSetor} setor={assunto.id_setor} id={assunto.id} index={index + 1} name={assunto.name} deleteAssunto={deleteAssunto} edit={openEdit} selectAll={selectAll} selectedArray={selectedArray} setSelectedArray={setSelectedArray} />
                    </>
                  ))}</>
                :
                assuntos.filter((v) => { return v.name.toLowerCase().indexOf(pesquisa.toLowerCase()) >= 0 && v.type != 'contato' }).map((assunto, index) => (
                  <Assuntos id={assunto.id} index={index + 1} name={assunto.name} deleteAssunto={deleteAssunto} edit={openEdit} selectAll={selectAll} selectedArray={selectedArray} setSelectedArray={setSelectedArray} />
                ))
              }
            </div>
          </div>
        </div>
      </Toggles>
      <div className="mobile-preview">
        <PreviewMobile idPage={data.id} tab='chat' chat={flagChat} formulario={flagForm} whatsapp={flagWhatsapp} tel={telefoneEmpresa} hasAddress={flagEndereco} address={endereco}  buttonName={buttonName}/>
      </div>
    </div>
  );
}

export default Chat;
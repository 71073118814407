import React, { useEffect, useState } from 'react';

import { Container } from './styles';

import ModalAtendimento from '../ModalAtendimento';
import wNotify from '../../util/wNotify';

import api from '../../services/api-geral';
import socket from '../../services/socket';
import { sessionGet } from '../../session';

function Atendimento() {
  const [atendimentos, setAtendimentos] = useState([]);

  useEffect(() => {
    socket.on('novo_atendimento_na_fila', ({ atendimento: newAtendimento, cliente }) => {
      let atendimento = { ...newAtendimento, cliente: { name: cliente } };
      setAtendimentos([...atendimentos, atendimento]);
      wNotify('Novo atendimento na fila!');
    });

    socket.on('cancelar_notificacoes_ativas', () => {
      setAtendimentos([]);
      checkAtendimentos();
    });

    return () => {
      socket.off('novo_atendimento_na_fila');
      socket.off('cancelar_notificacoes_ativas');
    }
  }, [atendimentos]);

  useEffect(() => {
    if (sessionGet('flagAtendimento') === true) {
      checkAtendimentos();
    }
  }, [])

  const checkAtendimentos = async () => {
    const response = (await api.get(`atendimentos-setor-fila/${sessionGet('idSetor')}`)).data;
    setAtendimentos(response.atendimentos);
  }

  return (
    <>
      <Container>
        {(atendimentos.length > 0) && (
          atendimentos.map(atendimento => (
            <ModalAtendimento atendimento={atendimento} close={() => { setAtendimentos([]) }} />
          ))
        )}
      </Container>
    </>
  );
}

export default Atendimento;
export const maps =
{
  title: '<h3>Adicione um mapa ao mini site</h3>',
  content:
    `<p class="content">
    Pesquise o nome de sua empresa no <strong>Google Maps</strong> e clique no <strong>botão Compartilhar <FiShare2 size={16} /></strong>. Após isso clique em <strong>Incorporar um mapa</strong> e prossiga clicando em <strong>COPIAR LINK</strong>. Agora é só colar o texto copiado na <strong>caixa de texto</strong> nessa página e clicar em <strong>Salvar</strong>.
    </p>
    <p class="content">
      Prontinho! Agora seu mini site já contará com um mini mapa para seus clientes te encontrarem.
    </p>`
}


export const notification =
{
  title: '<h3>Adicione um mapa ao mini site</h3>',
  chrome: `<p class="content">Localize um cadeado no canto superior esquerdo do seu navegador. Em seguida, habilite a opção de notificações.</p>`,
  mozilla: `<p class="content">Localize um ícone de conversa no canto superior esquerdo do seu navegador. Em seguida, habilite a opção de notificações.</p>`,
  other: `<p class="content">Localize um cadeado no canto superior esquerdo do seu navegador. Em seguida, habilite a opção de notificações. Caso não funcione ou não apareça, pesquise como habilitar notificações em seu navegador.</p>`,
}

export const analytics =
{
  title: '<h3 style="color: #8a2387">Para encontrar seu código do Analytics, <br> siga os seguintes passos:</h3>',
  content:
    `
    <p class="content">
    1 - Faça <strong>login</strong> na sua conta do <strong>Google Analytics</strong>.
    </p>
    <p class="content">
    2- Clique em <strong>Administrador</strong>.
    </p>
    <p class="content">
    3 - No topo da coluna <strong>Propriedade</strong>, selecione a sua.
    </p>
    <p class="content">
    4 - Na coluna Propriedade, clique em <strong>Fluxos de dados</strong>.
    </p>
    <p class="content">
    5 - Clique no fluxo de dados para o qual você precisa do ID.
    </p>
    <p class="content">
    6 - Seu ID "G-" aparece na parte superior direita.
    </p>
    <br>
    <p class="content">
    <strong>Atenção</strong>: O prazo para começar a coleta de dados do Analytics é de 24h até 48h.
    </p>
`
}



import styled from 'styled-components';

import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 20px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-x: hidden;
  overflow-y: auto;

  .titulo {
    position: absolute;
    left: 20px;
  }

  &::-webkit-scrollbar {
      width: 4px;
  }

  &::-webkit-scrollbar-track {
      background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
      background: ${colors.border};
      border-radius: ${effects.radius};
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
      background: #555;
  }

  .painel-config-notificacoes {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    margin-bottom: 25px;

    h3 {
      margin-bottom: 25px;
      text-align: center;
    }

    .config-notificacao {
      margin-bottom: 25px;
      padding: 0 10px;

      label {
        font-size: 14px;

        @media (max-width: 1400px) {
          font-size: 12px;
        }
      }

      svg {
        font-size: 25px;
        color: ${colors.primary};
        cursor: pointer;
      }

      .wrap-options {
        display: flex;
        margin: 20px 0;
        justify-content: space-around;

        .option {
          display: flex;
          align-items: center;
          margin-right: 10px;
          cursor: pointer;

          .check {
            width: 17px;
            height: 17px;
            border: 1px solid #ccc;
            border-radius: 50%;
            margin-right: 5px;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
              padding: 1px;
              color: ${colors.primary};
            }
          }

          span {
            font-size: 13px;
          }
        }
      }
    }

    @media (max-width: 1600px) {
      width: 40%;
    }

    @media (max-width: 1400px) {
      width: 50%;
    }

    @media (max-width: 950px) {
      width: 60%;
    }

    @media (max-width: 800px) {
      width: 75%;
    }

    @media (max-width: 600px) {
      width: 95%;
    }
  }
`;

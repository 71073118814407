import React, { useState, useEffect } from 'react';
import api from '../../services/api-geral';

import { Wrap, Banner, WrapForm, Form, Input, SmallAlert, Button } from './styles';
import { FaSpinner } from 'react-icons/fa';
import InfoMensagem from '../../components/InfoMensagem';
import InfoHelp from '../../components/InfoHelp';

import { logout, clearSession } from '../../session';

import LogoTask from '../../assets/images/logotask.png';
import LogoMerx from '../../assets/logo_branca.png';
import IconeMerx from '../../assets/icone_logo.png';

import { useParams } from "react-router-dom";
import { set } from 'date-fns';

export default function RecuperarSenha({ history }) {
  const { token } = useParams();

  const [tokenInvalido, setTokenInvalido] = useState(false);
  const [email, setEmail] = useState('');
  const [senhaRecuperada, setSenhaRecuperada] = useState(false);
  const [senhaAlterada, setSenhaAlterada] = useState(false);
  const [senha, setSenha] = useState('');
  const [senhaConf, setSenhaConf] = useState('');
  const [alert, setAlert] = useState({ status: false, msg: "", success: false, success: false });
  const [loading, setLoading] = useState(false);
  const [showAlertMerx, setShowAlertMerx] = useState(false);
  const [msgAlertMerx, setMsgAlertMerx] = useState('');
  const [tipoAlertMerx, setTipoAlertMerx] = useState('');
  const [time, setTime] = useState(6);

  const [inHover, setHover] = useState(false);
  const [inOtherHover, setOtherHover] = useState(false);

  useEffect(() => {
    if (token) {
      validaToken();
    }
  }, [tokenInvalido]);

  const validaToken = async () => {
    const response = (await api.get(`/resetSenha/${token}`)).data;
    if (response.auth === false) {
      setTokenInvalido(true);
    }
  }

  const recuperar = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = (await api.post('/resetSenha', { userEmail: email })).data;

    if (response.type === "error") {
      //setAlert({status: true, msg: response.msg, success:false});
      setMsgAlertMerx(response.msg);
      setTipoAlertMerx('danger')
      setShowAlertMerx(true);
      setTimeout(() => { setShowAlertMerx(false) }, 5000)
    }
    else {
      if (response.status === 'error') {
        setMsgAlertMerx(response.msg);
        setTipoAlertMerx('danger')

        let styles = `
          border: solid 2px red;
          line-height: 0;
          border-radius: 10px;
          padding: 0 5px;
        `
        document.getElementById("input-email").setAttribute("style", styles);
        setShowAlertMerx(true);

        setTimeout(() => {
          let offStyle = `
            border-bottom: 2px solid rgba(0, 0, 0, 0.1);
            border-radius: 0;
            padding: 0;
          `
          document.getElementById("input-email").setAttribute("style", offStyle);
          setShowAlertMerx(false);
        }, 6000)
      } else {
        setSenhaRecuperada(true);
        setMsgAlertMerx(response.msg);
        setTipoAlertMerx('ok')
        setShowAlertMerx(true);
        setTimeout(() => { setShowAlertMerx(false) }, 7000)
        //setAlert({status: true, msg: response.msg, success:true});
        localStorage.setItem('recover', JSON.stringify(email));
        setTimeout(() => { clearSession(); }, 7000)
      }
    }
    setLoading(false);
  }

  const alterarSenha = async (e) => {
    e.preventDefault();
    setLoading(true);
    const dados = {
      "senha": senha,
      "confirmacaoSenha": senhaConf,
      token
    };
    const response = (await api.put('/users/recuperar', dados)).data;
    if (response.type === "error") {
      setMsgAlertMerx(response.msg);
      setTipoAlertMerx('danger')

      let styles = `
          border: solid 2px red;
          line-height: 0;
          border-radius: 10px;
          padding: 0 5px;
        `
      document.getElementById("input-senha").setAttribute("style", styles);
      document.getElementById("input-conf-senha").setAttribute("style", styles);
      setShowAlertMerx(true);

      setTimeout(() => {
        let offStyle = `
            border-bottom: 2px solid rgba(0, 0, 0, 0.1);
            border-radius: 0;
            padding: 0;
          `
        document.getElementById("input-senha").setAttribute("style", offStyle);
        document.getElementById("input-conf-senha").setAttribute("style", offStyle);
        setShowAlertMerx(false);
      }, 5000)
    }
    else {
      setSenhaRecuperada(true);
      setSenhaAlterada(true);
      setShowAlertMerx(true);
      rodarTempo();

      setTimeout(() => {
        setShowAlertMerx(false);
      }, 6000)
    }

    setLoading(false);
  }

  const rodarTempo = () => {
    const interval = setInterval(() => {
      setTime(time => time - 1);
    }, 1000);
    return () => clearInterval(interval);
  }

  useEffect(() => {
    if (time == 0) {
      logout();
      clearSession();
    }
  }, [time]);

  return (
    <Wrap>
      <Banner>
        <div id="login-left" className="title">
          <img src={LogoMerx} className="logo-merx" alt="Logo Merx" title="Merx" onClick={() => window.location = '/'} />
          <img src={LogoTask} className="logo-task" alt="Logo Task" title="Task" onClick={() => window.open('https://task.com.br')} />
        </div>
      </Banner>
      <WrapForm>
        <Form id="login-right" onSubmit={(token) ? alterarSenha : recuperar}>
          <a href={`/`}><img src={IconeMerx} className="icone-merx" alt="Ícone Merx" title="Merx" /></a>
          <div className="box">
            <div><h2>Redefinição de senha</h2></div>
            {(token) ? '' : <InfoHelp classInfo="full-width" contentInfo='Uma mensagem com as instruções de redefinição será enviada para seu e-mail.' />}
          </div>
          {(token) ?
            (
              (tokenInvalido) ?
                (
                  <div className="msg-senha-recuperada">
                    <span>O link está incorreto ou expirado, favor gerar um link novo</span>
                  </div>
                )
                :
                (
                  <>
                    {(showAlertMerx) && (
                      (senha !== senhaConf) ? (
                        <InfoMensagem tipoAlerta={tipoAlertMerx} conteudoAlerta="As senhas não coincidem."></InfoMensagem>
                      ) : (
                        (senhaAlterada) ? (
                          <InfoMensagem tipoAlerta="success" conteudoAlerta={`Senha alterada com sucesso.`} outroConteudoAlerta={'Você será redirecionado para a página de login.'}></InfoMensagem>
                        ) : (
                          <InfoMensagem tipoAlerta="danger" conteudoAlerta={`A senha está fora do formato requerido. Por favor, verifique-a e tente novamente.`}></InfoMensagem>
                        )
                      )
                    )}

                    <Input id="input-senha">
                      <input type="password" placeholder=" " value={senha} onChange={e => setSenha(e.target.value)} />
                      <label>Digite uma nova senha *</label>
                    </Input>
                    <Input id="input-conf-senha">
                      <input type="password" placeholder=" " value={senhaConf} onChange={e => setSenhaConf(e.target.value)} />
                      <label>Confirme sua senha *</label>
                    </Input>
                    <InfoHelp contentInfo='A senha precisa, obrigatoriamente, conter no mínimo 6 caracteres com pelo menos um número e uma letra maiúscula.' /*listaInfo={['No mínimo 6 caracteres com ao menos um número e uma letra maiúscula.']}*/ />
                  </>
                )
            )
            :
            (
              <>
                {(showAlertMerx) && (
                  (tipoAlertMerx == 'danger') ? (
                    <>
                      {(email == '') ? (
                        <InfoMensagem tipoAlerta={tipoAlertMerx} conteudoAlerta='Digite o e-mail para recuperar a senha.'></InfoMensagem>
                      ) : (
                        <InfoMensagem tipoAlerta={tipoAlertMerx} conteudoAlerta={msgAlertMerx}></InfoMensagem>
                      )}
                    </>
                  ) : (
                    <div className="box-success">
                    <InfoMensagem tipoAlerta="success" conteudoAlerta='Uma mensagem com as instruções de redefinição de senha foi enviada para o seu e-mail.' outroConteudoAlerta='Você será redirecionado para a página de login.'></InfoMensagem>
                    </div>
                  )
                )}        
                <Input id="input-email">
                  <input type="text" placeholder=" " value={email} onChange={e => setEmail(e.target.value)} />
                  <label>E-mail *</label>
                </Input>
              </>
            )
          }

          {(senhaRecuperada) ?
            (
              (senhaAlterada) &&
              (
                <div></div>
              )
            )
            :
            (
              (loading) ?
                (
                  <Button type="submit">
                    <FaSpinner color="#fff" size="20" className="fa-spin" />
                  </Button>
                )
                :
                (
                  (!tokenInvalido) &&
                  (
                    <>
                      {(token) ? <Button type="submit">Salvar nova senha</Button> : <Button type="submit">Redefinir</Button>}
                      <Button onClick={() => window.location = '/'}>
                        Voltar
                      </Button>
                    </>
                  )
                )
            )
          }
        </Form>
      </WrapForm>
    </Wrap >
  );
}

import styled from 'styled-components';
import colors from '../../../../presets/colors';
import effects from '../../../../presets/effects';

export const Wrap = styled.div`
    width: 50%;
    color: ${colors.primary}
    
    .box-image {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        margin: 10px 0;
        box-shadow: ${effects.boxshadow};

        .title {
        display: flex;
        align-items: center;

        span {
            color: ${colors.primary}
            font-size: 15px;
            margin-left: 10px;
        }

        svg { font-size: 30px}
        }

        .action {
        display: flex;
        align-items: center;
        cursor: pointer;
        }
    }
`;

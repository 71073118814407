import styled from "styled-components";

import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Container = styled.div`
  height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: ${colors.border};
        border-radius: ${effects.radius};
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

  .content-painel {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .input-style {
    margin: 15px 0;
  }
`;

export const Configuracao = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const WrapOutClicker = styled.div`
  //width: 100%;
  //height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  // z-index: 1051;*/
`;

export const BoxConfiguracao = styled.div`
  color: #fff;
  background-color: #1a202e;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    color: #c7c7c7;
    margin-bottom: 10px;
  }
`;

export const FormConfiguracao = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .colorpicker {
    z-index: 1052;
  }

  .wrap-btn {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
  }

  .wrap-alert {
    margin-bottom: 20px;
  }

  .wrap-input {
    width: 300px;

    .label-form {
      font-size: 12px;
      transition: all 0.8s;
      display: block;
    }

    .cor {
      input {
        margin-left: 30px;
      }
    }

    .code-embed-chat {
      color: #000;
      word-wrap: break-word;
      margin-bottom: 10px;
    }

    .wrap-color {
      display: flex;
      align-items: center;
      position: relative;

      .btn-color-picker {
        width: 22px;
        height: 20px;
        margin-right: 8px;
        margin-left: 10px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 2px;
        display: inline-block;
        position: absolute;
        z-index: 999;
        border-radius: 10px;
      }
    }

    .input-form {
      color: #c7c7c7;
      background-color: transparent;
      border: none;
      width: 100%;
    }

    .wrap-color-picker {
      z-index: 1052;
      position: absolute;
      top: -100px;
      right: -100px;
    }

    .wrap-drop {
      position: relative;
    }

    .input-group {
      display: flex;

      .wrap-anexo {
        font-size: 20px;
        color: #c7c7c7;
        background-color: transparent;
        border-radius: 2px;
        border: none;
        display: flex;
        padding: 5px;
        position: absolute;
        right: 0;
        margin-top: 35px;
      }
    }

    .wrap-phone {
      margin-bottom: 10px;

      .company-phone {
        margin-bottom: 0;
      }
    }

    &:focus-within .label-form {
      color: #c7c7c7;
      text-shadow: 0px 0px 5px #c7c7c7;
    }
  }

  .wrap-drop {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .sem-imagem-perfil {
      height: 120px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      width: 120px;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .container-filter {
      height: 120px;
      width: 120px;
      position: relative;

      .img-filter {
        position: absolute;
        background-color: #bdbdce96;
        border-radius: 50%;
        height: 120px;
        width: 120px;
        display: flex;
        opacity: 0;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: opacity 0.2s ease-in-out;

        &:hover {
          opacity: 100;
        }

        span {
          font-size: 12px;
          color: #1a202e;
          font-weight: bold;
        }
      }

      .imagem-perfil {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }

  .wrap-relative {
    position: relative;
  }

  .wrap-input {
    width: 300px;

    .helper {
      display: flex;
      justify-content: flex-end;
      position: absolute;
      right: 0;
      height: 100%;
      align-items: center;
      margin: 0 7px;
      z-index: 1100;

      .icone-ajuda {
        position: absolute;
        cursor: pointer;
      }

      .title-custom {
        svg {
          font-size: 20px;
        }
        &:hover {
          cursor: help;
          position: relative;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .wrap-input,
    .wrap-drop {
      width: 100%;
    }

    .wrap-btn {
      justify-content: center;
    }
  }
`;

export const ButtonConfiguracao = styled.button`
  text-transform: uppercase;
  color: #fff;
  background-color: #2c76ff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin-top: 20px;
`;

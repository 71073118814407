import React, { useEffect, useState } from 'react';
import Page from '../../components/Page';
import Chat from '../../components/Chat';
import { useParams } from "react-router-dom";
import { Container, Section } from './styles';
import { getIdWithoutCripto } from '../../util/formatParamsUrl';

export default function Conversas() {
  const { idDestinatario } = useParams();

  return (
    <Page content={(
      <Container className="container-conversa">
        <Section>
          <Chat idDestinatario={idDestinatario ? getIdWithoutCripto(idDestinatario) : null} />
        </Section>
      </Container>
    )} />
  );
}

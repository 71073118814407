import styled, { css } from 'styled-components';

import { Link } from 'react-router-dom';

import colors from '../../presets/colors';

export const Wrap = styled.div`
  width: 100%;
  /* border-bottom: 5px solid ${colors.primary}; */
  /* padding-bottom: 20px; */
  /* margin-bottom: 25px; */

  h1 {
    display: flex;
    align-items: center;
    font-weight: bold;
    color: #727272;

    svg {
      margin-left: 10px
    }

    .icon {
      display: flex;
      position: relative;

      @media(max-width: 575px) {
        svg {
          width: 1.75rem;
          height: 1.75rem;
        }
      }
    }

    small {
      font-size: 14px;
      margin-left: 20px;
      color: ${colors.textoff};

      span { margin: 0 5px;}

      @media(max-width: 768px) {
        display: none;
      }
    }
  }

  @media (max-width: 1440px){
    h1 {
      font-size: 24px;
    }
  }

  @media(max-width: 1024px) {
    h1 { 
      font-size: 1.3rem;
    }
  }

  @media(max-width: 575px) {
    h1 {
      font-size: ${props => (props.format === 'enabled-tools' && props.format === 'disabled-tools') ? '1.3rem' : '1.1rem'};
    }
  }

  @media(max-width: 327px) {
    h1 {
      font-size: ${props => (props.format === 'enabled-tools' && props.format === 'disabled-tools') ? '1.3rem' : '.9rem'};
    }
  }
`;

export const Button = styled(Link)`
  text-decoration: none;
  color: ${colors.textoff};
`;

export const Separador = styled.div`
  border-bottom: 5px solid ${colors.primary};
  border-radius: 10px;
  position: absolute;
  width: 80px;
  margin-left: -80px;
  margin-top: 30px;

  @media (max-width: 1440px) {
    width: 60px;
    margin-left: -60px;
  }

  @media (max-width: 575px) {
    margin-top: ${props => (props.format === 'enabled-tools' && props.format === 'disabled-tools') ? '30px' : '25px'};
  }
`;
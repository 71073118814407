import React from 'react';

import Page from '../../components/Page';

import { Container } from './styles';
import PostsInstagram from '../../components/PostsInstagram';

function Instagram() {
    return (
        <Page content={(
            <Container>
                <PostsInstagram />
            </Container>
        )} />
    );
}

export default Instagram;
import React, { useState, useEffect, memo } from 'react';
import api from '../../services/api-geral';
import socket from '../../services/socket';
import { sessionGet } from '../../session';
import SocketCommentsFeed from '../../socket/SocketCommentsFeed';

import log from '../../util/log';

import { Container, Infos, Interacoes, Comments } from './styles';


import NCommentFeed from '../NCommentFeed';
import ModalCommentPost from '../ModalCommentPost';
import CommentPost from '../CommentPost';
import NModalViewLike from '../NModalViewLike';
import SocketLikesFeed from '../../socket/SocketLikesFeed';
import SocketViewsFeed from '../../socket/SocketViewsFeed';

import ModalShowImage from '../ModalShowImage';
import Comment from './Comment';
import CommentTextBox from './CommentTextBox';
import { RiArrowDownSLine } from 'react-icons/ri';


function CommentOnComment({reload, comment, id_post, id_autor, post, viewmode = false, setHasChangesModal, setImageId, setImagePath }) {
    const [showComments, setShowComments] = useState(true);

    const [page, setPage] = useState(0);
    const [lastPage, setLastPage] = useState(0);
    const [commentsOnComment, setCommentsOnComment] = useState([]);
    const [countComment, setCountComment] = useState(0);
    const [showBtnLoadComment, setShowBtnLoadComment] = useState(false);

    const [countLikes, setCountLikes] = useState(0);
    const [likes, setLikes] = useState([]);
    const [liked, setLiked] = useState(false);

    const [countViews, setCountViews] = useState(0);
    const [views, setViews] = useState([]);

    const [showModalEdit, setShowModalEdit] = useState(false);
    const [editedComment, setEditedComment] = useState({});

    const [type, setType] = useState('');
    const [typeModal, setTypeModal] = useState('');
    const [autorName, setAutorName] = useState('');
    const [bellRing, setBellRing] = useState();


    const [showModalImage, setShowModalImage] = useState(false);

    const [pathImage, setPathImage] = useState('');
    const [editedImage, setEditedImage] = useState({});

    const [uploadComment, setUploadComment] = useState(false);

    // console.log("component CommentsOnComment", commentsOnComment)


    useEffect(() => {
        viewPost();
        if (viewmode === true) { setShowComments(true); }
    }, [viewmode]);
    useEffect(() => {
        verifyLike()
    }, [likes]);
    useEffect(() => { loadCommentsOfComments() }, []);
    useEffect(() => { verifyPaginationButton(); }, [countComment, page]);

    useEffect(() => {
        setCommentsOnComment(comment)
        // console.log("use Effect setCommentsOnComment", commentsOnComment)
        getLikes();
        getViews();
    }, [])

    const handleComments = () => {
        if (showComments === false) {
            changePage(0);
        }

        setShowComments(!showComments);
    }

    const loadCommentsOfComments = async () => {
        const id_father = comment.id;
            
        const response =(await api.get(`/comments/${id_father}`)).data;        

        setCommentsOnComment(response.comments);     
        setCountComment(response.countComments)  
        // console.log("função loadComment CommentsOnComment", commentsOnComment)
        reload()

    }


    const deleteComment = async (comments) => {
        console.log('delete', comments.id)
        const response = (await api.delete(`/comments/${comments.id}`)).data;
        console.log('response  delete', response)

        // loadCommentsOfComments('socket');
        console.log("função delete CommentsOnComment", commentsOnComment)
    }

    const verifyPaginationButton = () => {
        if (viewmode === false) {
            const numberPages = (countComment / 3);
            var lastPage = 0;
            var pagina = page;

            if (numberPages % 1 === 0) {
                pagina = pagina + 1;
                lastPage = numberPages;
            } else lastPage = parseInt(numberPages);

            if (pagina === (lastPage)) setShowBtnLoadComment(false);
            else setShowBtnLoadComment(true);
        } else {
            setShowBtnLoadComment(false);
        }
    }

    const changePage = numberPage => setPage(numberPage);

    const likePost = async () => {
        const response = (await api.post('likes', { id_post })).data;

        if (response.func === 'like') {
            setLiked(true);
            log('curtiu um post no feed.');
        } else {
            setLiked(false);
            log('removeu a curtida em um post no feed.')
        }
    }



    const verifyLike = () => {
        setLiked(false);

        likes.map(like => {
            if (like.user.id === sessionGet('idUser')) {
                setLiked(true);
            }
        });
    }

    const viewPost = async () => {
        if (id_autor !== sessionGet('idUser')) {

            const response = (await api.post('/views', { id_post })).data;

            if (response) {
                log('visualizou um post no feed.');
            }
        }
    }

    const getLikes = async (method) => {
        if (method === 'socket') {
            const response = (await api.get(`/likes/${id_post}`)).data;
            setLikes(response.likes);
            setCountLikes(response.count);
        } else {
            if (post.likes) {
                setLikes(post.likes);
                setCountLikes(post.likes.length);
            }
        }
    }

    const getViews = async (method) => {
        if (method === 'socket') {
            const response = (await api.get(`/views/${id_post}`)).data;
            setViews(response.views);
            setCountViews(response.count);
        } else {
            setViews(post.views);
            setCountViews(post.views.length);
        }
    }

    const markRead = async () => {
        await api.put(`/markread/${id_post}`, {
            method: 'likepostfeed'
        });
    }

    return (
        <>
            <SocketCommentsFeed loadComments={loadCommentsOfComments} idPost={post.id} />
            <SocketCommentsFeed loadComments={loadCommentsOfComments} idPost={post.updatedAt} />
            {showModalEdit && <ModalCommentPost
                id_post={id_post}
                type={typeModal}
                image={editedImage}
                comment={editedComment}
                reload={loadCommentsOfComments}
                uploadComment={uploadComment}
                setUploadComment={setUploadComment}
                setHasChangesModal={setHasChangesModal}
                setImageId={setImageId}
                setImagePath={setImagePath}
                closeModal={() => setShowModalEdit(false)}
            />}
            {showModalImage && <ModalShowImage img={pathImage} close={() => setShowModalImage(false)} />}

            {<SocketLikesFeed getLikes={getLikes} idPost={id_post}></SocketLikesFeed>}
            {<SocketViewsFeed getViews={getViews} idPost={id_post}></SocketViewsFeed>}

            <Container>
        
                        <Comments>
                            {commentsOnComment.length > 0 && (
                                <>
                                    {commentsOnComment.map(comments => 
                                    <Comment
                                        key={comments.id}
                                        autorName={setAutorName}
                                        bellRing={setBellRing}
                                        comment={comments}
                                        openImg={(image) => { setShowModalImage(true); setPathImage(image) }}
                                        editar={(image) => { setEditedComment(comments); setEditedImage(image); setShowModalEdit(true); setTypeModal('edit'); }}
                                        deletar={() => { deleteComment(comments); }} 
                                        reload={loadCommentsOfComments}/>)}
                                    {showBtnLoadComment && <div className="btn-carregar-comments" onClick={() => changePage(page + 1)}>Carregar mais comentários <RiArrowDownSLine /> </div>}

                                </>
                            )}
                            <CommentTextBox
                                id_post={id_post}
                                type={typeModal}
                                image={editedImage}
                                autorName={autorName}
                                bellRing={bellRing}
                                comment={editedComment}
                                commentFatherId={comment.id}
                                commentIdAutorFather={comment.id_autor}
                                closeModal={() => setShowModalEdit(false)}
                                reload={loadCommentsOfComments}
                                uploadComment={uploadComment}
                                setUploadComment={setUploadComment}
                                setHasChangesModal={setHasChangesModal}
                                setImageId={setImageId}
                                setImagePath={setImagePath}
                            />
                        </Comments>
               
            </Container >
        </>
    );
};

export default CommentOnComment;
import React, { useEffect, useState } from 'react';

import bags from '../../../assets/images/LembretesIcones/bags.png';
import off from '../../../assets/images/LembretesIcones/off.png';
import docter from '../../../assets/images/LembretesIcones/docter.png';
import { format } from 'date-fns';


import { GoPin } from 'react-icons/go';
import { AiFillEdit } from 'react-icons/ai';
import { RiCloseFill } from 'react-icons/ri';

import ModalConfirma from '../../ModalConfirma';


export default function Lembretes({lembrete, icon, deletar, editNote,  dates, setConfirmaEdit, reload}) {  
  const [confirmaDelete, setConfirmaDelete] = useState(false);
  const [showOptions, setShowOptions] = useState(false);

  const dateValueStart = lembrete.start.split(" ")[0];
  const dateValueEnd = lembrete.end.split(" ")[0];

  const dataStart = format(new Date(dateValueStart + 'T00:00:00'), 'dd/MM/yyyy');
  const dataEnd = format(new Date(dateValueEnd + 'T00:00:00'), 'dd/MM/yyyy');

  const deleteNote = () => {
    deletar();
    setConfirmaDelete(false);
 };
  const edit = () => {
    editNote()
  };

  return (
    <div className='container'>
         <div className='icon'> 
            {lembrete.type === 'Férias' &&  <img src={bags} alt='' />}
            {lembrete.type === 'Atestado' && <img src={docter} alt='' />}
            {lembrete.type === 'Ausente' &&  <img src={off} alt='' />}
            {lembrete.type === 'Outro' && <GoPin />}
        </div>

        <div className='content'>
        <strong className='title'>{lembrete.type === 'Outro' ? lembrete.type_outro : lembrete.type}</strong>
        <p className='note-text'>{lembrete.note}</p>
        <div className='footer' >
        <small className='author-name'>{lembrete.autor.name}</small>
        {dates && 
              <div className='date'>
                <small >inicio: {dataStart}</small> {" "}
                <small >final: {dataEnd}</small>
              </div>
        }
        </div>

        </div>
        {icon &&         
        <div className='buttons'>
        <button onClick={() => { setConfirmaEdit(true); edit()}}  className="btn-edit" ><AiFillEdit /></button>
        <button onClick={() => {setConfirmaDelete(true)}}  className="btn-delete" ><RiCloseFill /></button>
        {confirmaDelete && (<ModalConfirma type='publicação' setConfirmaDelete={setConfirmaDelete} deletar={() => { deleteNote(); setTimeout(() => { reload() }, 100) }} setShowOptions={setShowOptions}/>)}

        </div>  } 
    
    </div>
  );
};


import styled from 'styled-components';

export const Wrap = styled.div`
  .MuiFormGroup-root {    
    flex-direction: row;
  }
  .MuiTypography-body1 {
    color:#000;
  }
`;

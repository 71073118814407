import api from '../services/api-geral';
import { sessionGet } from '../session';

export default async (auxMsg, status = false, name) => {
    var msg;

    if (status) {
        msg = name + ' ' + auxMsg;

    } else {
        msg = sessionGet('nomeUser') + ' ' + auxMsg;
    }

    await api.post('logs', { msg });
}
import styled from 'styled-components';

export const Container = styled.ul`
  width: 400px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff;
  border: 1px solid #ccc;
  list-style: none;
  z-index: 1060;
  position: fixed;
  
  & > li {
    border-bottom: 1px solid #ccc;
    padding: 10px;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }

    &:last-child {
      border: 0;
    }
  }

  h4 {
    padding: 10px;
  }
`;
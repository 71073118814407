import React, { useState, useEffect, Image, useRef } from 'react';
import api from '../../services/api-geral';
import { BorderDashed, ButtonContainer, Container, Date, DropContainer, Files, FilesLayer, Info, Infos, InfosContainer, InputMensagem, InputRow, ReplyContainer, Section, SizeInfo, SwitchButton, TextArea, UploadFiles, UploadInput } from './styles';
import { ImAttachment } from "react-icons/im";

import Swal from 'sweetalert2';

import CButton from '../ComponentButton';
import CInput from '../ComponentInput';
import CSelect from '../ComponentSelect';
import InfoMensagem from '../InfoMensagem';
import { sessionGet } from '../../session';
import getFiles from '../../util/getFiles';
import { FilesBox } from '../Shared/FilesBox';
import Dropzone from 'react-dropzone';
import Compressor from 'compressorjs';
import apiUpload from '../../services/api-upload';
import { DropzoneContainer } from '../Conversa/styles';
import { DropFiles } from '../Shared/DropFiles';
import { TitleSwitch } from '../Shared/TitleSwitch';

export default function ViewForm({ action, close, item, success }) {
    const [id, setId] = useState('');
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [setor, setSetor] = useState('');
    const [assunto, setAssunto] = useState('');
    const [mensagem, setMensagem] = useState('');
    const [status, setStatus] = useState('');
    const [responsavel, setResponsavel] = useState('');
    const [novoComentario, setNovoComentario] = useState('');
    const [listaComentarios, setListaComentarios] = useState([]);
    const [listaHistorico, setListaHistorico] = useState([]);
    const [date, setDate] = useState('')
    const [files, setFiles] = useState([])
    const [section, setSection] = useState(1)

    // const [statusOptions] = useState([
    //     { id: '1', name: 'Novo' },
    //     { id: '2', name: 'Em andamento' },
    //     { id: '3', name: 'Aguardando cliente' },
    //     { id: '4', name: 'Fechado' },
    // ]);

    const [statusOptions] = useState([
        { id: '2', name: 'Em andamento' },
        { id: '4', name: 'Fechado' },
    ]);

    const [showAlertHistoric, setShowAlertHistoric] = useState(false);
    const [typeAlertHistoric, setTypeAlertHistoric] = useState('');
    const [msgAlertHistoric, setMsgAlertHistoric] = useState('');

    const [typeAlertComments, setTypeAlertComments] = useState('');
    const [msgAlertComments, setMsgAlertComments] = useState('');
    const [showAlertComments, setShowAlertComments] = useState(false);

    const [switchReplyArea, setSwitchReplyArea] = useState(1)

    useEffect(() => {
        setId(item.id)
        setNome(item.nome)
        setEmail(item.email)
        setTelefone(item.telefone)
        setSetor(item.setor)
        setAssunto(item.assunto)
        setMensagem(item.mensagem)
        setStatus(item.id_status)
        setResponsavel(item.responsavel)
        carregaComentarios(item.id)
        carregaHistorico(item.id)
        setDate(item.criado)
    }, [item]);

    useEffect(() => {

    }, [novoComentario]);






    const uploadFiles = async (files, id) => {
        const dados = new FormData();
        const images = []
        const archives = []
        dados.append('modulo', 'fale-conosco');
        dados.append('id_chamado_comentario', id)


        files.map((file) =>
            {
               const typeFile = file.type.split('/')[0]
               if(typeFile === 'image') {
                    images.push(file)
                }

               if(typeFile !== 'image'){
                    archives.push(file)
                }
            }
        )


            archives.forEach(file => {
                dados.append('archive', file)
            })
            images.forEach((file) => {
                dados.append('image', file)
            })

            const response = (await apiUpload.post('/arquivo/upload', dados)).data
            return response
        
    };




    const salvarComentario = async () => {
        const response = await api.post('/chamado-comentario', {
            comentario: novoComentario,
            chamado: id
        })
        
        if (response.data.type === 'success') {
            if(files[0]) {
                const filesApi = await uploadFiles(files, response.data.contato.id)
                const responseMail = await api.post('/formulario-chamado-sendmail', {
                    message: novoComentario,
                    email,
                    nome,
                    id_empresa: sessionGet('idEmpresa'),
                    emailFrom: sessionGet('emailUser'),
                    attachment: filesApi
                });

            }   else {
                    const responseMail = await api.post('/formulario-chamado-sendmail', {
                        message: novoComentario,
                        email,
                        nome,
                        id_empresa: sessionGet('idEmpresa'),
                        emailFrom: sessionGet('emailUser')
                    });
                }   

            setNovoComentario('');
            setFiles([])
            setSwitchReplyArea(1)
            carregaComentarios(id);
            setTypeAlertComments('success');
            setMsgAlertComments(response.data.msg);
            setShowAlertComments(true);
            atualizarStatus('4');
            setTimeout(() => { setShowAlertComments(false) }, 5000);
        } else {
            setTypeAlertComments('danger');
            setMsgAlertComments(response.data.msg);
            setShowAlertComments(true);
            setTimeout(() => { setShowAlertComments(false) }, 5000);
        }
    };

    const carregaComentarios = async (chamado) => {
        const response = await api.get(`/chamado-comentario/${chamado}`);
        setListaComentarios(response.data);
    };

    const carregaHistorico = async (chamado) => {
        const response = await api.get(`/chamado-historico/${chamado}`);
        setListaHistorico(response.data);
    };

    const atualizarStatus = async (status) => {
        setStatus(status)
        setSwitchReplyArea(1)
        const response = await api.post('/chamado-historico', {
            status: status,
            chamado: id
        });

        success();
        setResponsavel(sessionGet('nomeUser'));

        if (response.data.type === 'success') {
            carregaHistorico(id);
            setTypeAlertHistoric('success');
            setMsgAlertHistoric(response.data.msg);
            setShowAlertHistoric(true);
            setTimeout(() => { setShowAlertHistoric(false) }, 5000);
        } else {
            setTypeAlertHistoric('danger');
            setMsgAlertHistoric(response.data.msg);
            setShowAlertHistoric(true);
            setTimeout(() => { setShowAlertHistoric(false) }, 5000);
        }
    };

    const handleClickCloseCall = () => {
        Swal.fire({
            icon: 'question',
            title: 'Tem certeza que deseja encerrar esse chamado?',
            showDenyButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Sim',
            denyButtonText: `Não`,
        }).then((result) => {
            if (result.isConfirmed) {
                atualizarStatus(4)
            }
        })
    }

    const handleClickOpenCall = () => {
        atualizarStatus(2)
    }

    const statusIsClosed = status == "4"
    const statusIsOpen = status == "2"
    const statusIsNew = status == "1"

    return (
        <>
            <Section>
                <TitleSwitch setSection={setSection} section={section} text={[{title: "Chamado:", span : `#${id}`}, {title: "Histórico do chamado"}]}/>
            {section === 1 && <Section>
                <div className="detalhes">
                    <div className="detalhe-chamado">
                        <Container>
                            <InfosContainer>
                                <Infos>
                                    <Info className="info-nome">
                                        <h3>Nome:</h3>
                                        <h4>{nome}</h4>
                                    </Info>
                                    <Info>
                                        <h3>E-mail:</h3>
                                        <h4>{email}</h4>
                                    </Info>
                                    <Info>
                                        <h3>Telefone:</h3>
                                        <h4>{telefone}</h4>
                                    </Info>
                                </Infos>
                                <BorderDashed></BorderDashed>
                                <Infos>
                                    <Info className="info-responsavel">
                                        <h3>Responsável:</h3>
                                        <h4>{responsavel}</h4>
                                    </Info>
                                    <Info>
                                        <h3>Status:</h3>
                                        <select value={status} onChange={e => atualizarStatus(e.target.value)}>
                                            {
                                                statusOptions.map((e) => (
                                                    <option key={e.id} value={e.id}>{e.name}</option>
                                                    ))
                                                }
                                        </select>
                                    </Info>
                                    <Info className="info-setor">
                                        <h3>Setor:</h3>
                                        <h4>{setor}</h4>
                                    </Info>
                                    <Info className="info-assunto">
                                        <h3>Assunto:</h3>
                                        <h4>{assunto}</h4>
                                    </Info>
                                </Infos>
                            </InfosContainer>
                            <InputRow>
                            <TitleSwitch text={[{title: "Mensagem recebida"}]} classCustom={"sub-section"}/>
                                <InputMensagem>
                                    {mensagem}
                                    
                                </InputMensagem>
                                <Date>{`às ${date}`}</Date>
                            </InputRow>
                        </Container>
                    </div>
                    <div className="detalhe-comentario">
                        <TitleSwitch setSection={setSwitchReplyArea} section={switchReplyArea} text={ statusIsOpen ? [{title: "Criar resposta"}, {title: "Respostas enviadas"}] : statusIsNew ? [{title: "Criar resposta"}] : [{title: "Respostas enviadas"}]} classCustom={"sub-section"}/>
                        {console.log(status, switchReplyArea, statusIsOpen, statusIsClosed)}

                        {
                            (statusIsNew || statusIsOpen) && switchReplyArea == 1 &&     
                                <Container >
                                {statusIsClosed &&
                                        <ReplyContainer>

                                            <TextArea disabled className=" disabled"></TextArea>
                                            
                                        </ReplyContainer>

                                        }
                                        {
                                            !statusIsClosed &&
                                            <ReplyContainer>
                                            <TextArea value={novoComentario} onChange={e => setNovoComentario(e.target.value)}></TextArea>
                                                <DropContainer >
                                                    <DropFiles files={files} Size={7.5} setFiles={setFiles}   />
                                                </DropContainer>
                                            </ReplyContainer>
                                        }                        
                                    
                                
                                    <ButtonContainer className={statusIsClosed && "closed"}>
                                        {showAlertComments &&
                                            <InfoMensagem tipoAlerta={`${typeAlertComments} mail-send`} className="mail-send" conteudoAlerta={msgAlertComments} customMargin="25px 0 35px 0" />
                                        }
                                        {(status != 4) ?
                                            <>
                                                <CButton title='Responder' cstyle='primary small newbuttoncomments' click={salvarComentario} files={files}/>
                                                {(status == 2 || status == 1) &&
                                                    <CButton title='Encerrar' cstyle='primary small newbuttonClose' click={handleClickCloseCall} />
                                                }
                                            </>
                                            :
                                            <CButton title='Reabrir chamado' cstyle='primary small newbuttoncomments newbuttonOpen' click={handleClickOpenCall} />
                                        }
                                    </ButtonContainer>
                            </Container>
                        }



                        {         
                            statusIsOpen &&   switchReplyArea === 2 &&                         
                            <div className="lista-comentarios">
                                <InputMensagem className="comentarios ">
                                    <div className="comentarios-boxes reply">
                                        {listaComentarios.map((item, index) => (
                                            <div className="comentario-box">
                                                <div className="box-title">
                                                    <div className="title-usuario">{item.usuario.name}</div>
                                                    <div className="title-data">{item.criado}</div>
                                                </div>
                                                <div className="box-body">
                                                    {item.comentario}
                                                </div>
                                                <div className='box-attachment'>
                                                {item.files.map(file => (
                                                    <FilesBox nameFile={file.name} path={file.path} typeFile={file.type_file} classCustom="text"/>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </InputMensagem>
                            </div>
                        }

                        {         
                        statusIsClosed &&                  
                            <>
                                <div className="lista-comentarios">
                                    <InputMensagem className="comentarios ">
                                        <div className="comentarios-boxes reply">
                                            {listaComentarios.map((item, index) => (
                                                <div className="comentario-box">
                                                    <div className="box-title">
                                                        <div className="title-usuario">{item.usuario.name}</div>
                                                        <div className="title-data">{item.criado}</div>
                                                    </div>
                                                    <div className="box-body">
                                                        {item.comentario}
                                                    </div>
                                                    <div className='box-attachment'>
                                                    {item.files.map(file => (
                                                        <FilesBox nameFile={file.name} path={file.path} typeFile={file.type_file} classCustom="text"/>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </InputMensagem>
                                </div>
                                <ButtonContainer className={statusIsClosed && "closed"}>

                                        <CButton title='Reabrir chamado' cstyle='primary small newbuttoncomments newbuttonOpen' click={handleClickOpenCall} />

                                </ButtonContainer>
                            </>                      
                        }

                    </div>
                </div>
                </Section>}

                {
                    section === 2 && <Section>
                        {showAlertHistoric &&
                            <InfoMensagem tipoAlerta={`${typeAlertHistoric} custom`} conteudoAlerta={msgAlertHistoric} customMargin="0 0 35px 0" />
                        }
                        <div className="historico newstyleinput">
                            <div className="historico-boxes">
                                {listaHistorico.map((item, index) => (
                                    <div className="historico-box">
                                        <div className="box-title">
                                            <div className="title-usuario">
                                                <span>{item.usuario}</span> alterou o status para {item.status} em {item.criado}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Section>
                }
            </Section>
        </>
    );
}

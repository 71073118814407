import styled from "styled-components";

import colors from "../../presets/colors";
import effects from "../../presets/effects";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;

  .title {
    font-size: 40px;

    span {
      color: #5c77ff;
    }
  }
`;

export const WrapAlert = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 575px) {
    width: 100%;
  }
`;

export const Divscroll = styled.div`
    height: 100%;
    padding: 15px;
    margin-bottom: 10px;
    overflow: auto;

    ::-webkit-scrollbar {
      width: 4px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
      background: ${colors.border};
      border-radius: ${effects.radius};
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 0 10px;
  border-collapse: separate;
  border-radius: ${effects.radius};
  position: relative;

  &.tabelaLogEmpresa {    
    tr {
      cursor: default;
      
      &.headertable {
        th {
          flex: .75;

          &:first-child {
            max-width: 20px;
            margin-left: 0px;
          }
          &:nth-child(2), &:nth-child(3) {            
            flex: .90;
            max-width: 350px;
          }
          &:nth-child(4), &:nth-child(5) {
            flex: .65;
            text-align: center;
          } 
          &:nth-child(6), &:nth-child(7), &:nth-child(8), &:nth-child(9) {
            flex: .45;
            text-align: center;
          } 
        }
      }

      &.select {
        border: 0;
      }

      td {
        flex: .75;

        &:first-child {
          min-width: 50px;
          flex: 0;
        }

        &.content-item {
          &:nth-child(2), &:nth-child(3) {
            flex: .90;
            max-width: 350px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          &:nth-child(4), &:nth-child(5){
            flex: .65;
            overflow: hidden;
            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          &:nth-child(6), &:nth-child(7), &:nth-child(8), &:nth-child(9) {
            flex: .45;
            overflow: hidden;
            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
          }         
        }

        &.actionstable {
          button {
            color: ${colors.primary};
          }
        }
      }
    }    
  }

  &.formulario-chamado {
    tr {
      cursor: pointer;
      
      &.headertable {
        th {
          flex: .75;

          &:first-child {
            max-width: 20px;
            margin-left: 0px;
          }
        }
      }

      &.select {
        border: 0;
      }

      td {
        flex: .75;

        &:first-child {
          min-width: 50px;
          flex: 0;
        }

        &.content-item {
          &:nth-child(2) {
            flex: .75;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          &:nth-child(5) {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        &.actionstable {
          button {
            color: ${colors.primary};
          }
        }
      }
    }
  }

  &.contato {
    tr {
      &.headertable {
        th {
          &:nth-child(2), &:nth-child(3) {
            max-width: 300px;
          }
        }
      }

      td {
        &.content-item {
          &:nth-child(3), &:nth-child(4) {
            max-width: 300px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  .select {
    border: 1px solid ${colors.primary};
  }

  tr {
    height: 45px;
    border-radius: ${effects.radius};
    box-shadow: ${effects.boxshadow};
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.headertable {
      height: auto;
      box-shadow: none;
      margin-bottom: 10px;
      
      th {
        font-weight: normal;
        text-align: left;
        color: #727272;
        padding: 0px 10px;
        flex: .75;
        
        &:first-child {
          margin-left: 37px;
          margin-right: 32px;
          max-width: 20px;
        }

        &:last-child {
          height: 20px;
        }
      }
    }

    td {
      padding: 0px 10px;
      flex: .75;

      &.actionscheckbox {
        flex: 0;

        & > div {
          justify-content: flex-start;
        }
      }

      &.content-item {
        &:nth-child(2) {
          min-width: 50px;
          flex: 0;
        }
      }

      &.actionstable {
        display: flex;
        justify-content: flex-end;

        button {
          padding: 0 !important;
          text-transform: unset;
          letter-spacing: normal;
        }
      }
    }
  }

  @media (max-width: 768px) {
    overflow-y: scroll;
    
    tr { 
      td {
        width: 225px;
      }
    }
  }
`;

export const Tools = styled.div`
  // margin-bottom: 25px;

  .wrapbuttons {
    display: flex;
    position: absolute;
    right: 16px;
    top: 30px;

    button {
      margin-right: 10px;
    }

    .checkbox-select-all {
      height: 35px;
      padding: 0 25px;
      font-weight: 500;
      background: ${colors.primary};
      border-radius: 25px;

      .box {
        border: 2px solid #fff;

        &.in {
          background-color: #fff;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    /* @media (max-width: 575px) {
    .wrapbuttons {
      justify-content: center;
      position: relative;
      right: 0;
      top: 0;

      .checkbox-select-all {
        width: 70px;
      }
    }
  } */

    @media (max-width: 575px) {
      .wrapbuttons {
        top: 20px;
        right: 0;
      }
    }
  }
`;

export const WrapPesquisa = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 1024px) {
    margin-top: 20px;
    flex-direction: column;
  }

  span {
    color: ${colors.texton};
    margin-left: 20px;
  }

  .select-ordenacao {
    width: 200px;
    height: 40px;
    margin-left: 20px;
    border: 0;
    box-shadow: ${effects.boxshadow};
    background-color: ${colors.bgprimary};
    border-radius: ${effects.radius};

    label {
      display: none;
    }

    select {
      font-size: 15px;
    }
  }
`;

export const WrapRelatorio = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end; 
  align-items: center;
  margin-right: 2%;

  @media (max-width: 1024px) {
    margin-top: 20px;
    flex-direction: column;
  }

  span {    
    color: ${colors.texton};
    margin-left: 20px;
  }

  a {
    color: #8A2387;
    font-weight: 900;
  }

  strong {    
    font-weight: 700;
    color: #727272;
    font-size: 16px;
  }  
`;

export const WrapRelatorioInferior = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end; 
  align-items: center;
  margin-right: 2%;

  @media (max-width: 1024px) {
    margin-top: 20px;
    flex-direction: column;
  }

  span {    
    color: ${colors.texton};
    margin-left: 20px;
  }

  a {
    color: #8A2387;
    font-weight: 900;
  }

  strong {    
    font-weight: 700;
    color: #727272;
    font-size: 16px;
  }  
`;

export const MiniScroll = styled.div`
  @media (max-width: 1024px) {
    height: 100%;
    padding: 15px;    
    margin-bottom: 10px;
    overflow: auto;
 
    ::-webkit-scrollbar {
      width: 4px;
    }
 
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
 
    ::-webkit-scrollbar-thumb {
      background: ${colors.border};
      border-radius: ${effects.radius};
    }
 
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    }    
`;

export const WrapCheckBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  spam {
    font-weight: bold;
    color: #727272;
    font-size: 14px;
  }

  @media (min-width: 1200px) {
    .checkbox-select-all {
      margin: 0 7px;
    }
  }

  @media (max-width: 1200px) {
    margin-bottom: 15px;

    .checkbox-select-all {
      margin-right: 10px;
    }
  }
`;

import React from 'react';
import { encode } from 'base-64';
import { HiOutlineArrowNarrowRight, HiOutlineUserCircle } from 'react-icons/hi';
import { RiUserSettingsLine } from 'react-icons/ri';

import getFiles from '../../util/getFiles';

import { sessionGet } from '../../session';

import Default from '../../assets/default.png';

import { Container } from './styles';
import { setIdWithCripto } from '../../util/formatParamsUrl';

function ModalUser({ idUser, user, photoUser, chatShortcut }) {
  return (
    <Container className="modal-user">
      {!chatShortcut ?
        <>
          <img src={photoUser && photoUser != null ? getFiles(photoUser.path) : Default} />
          <div>
            <p className="name">{user.name}</p>
            <p>{user.email}</p>
            {idUser !== sessionGet('idUser') ? (
              <>
                <a href={`/perfil?id=${setIdWithCripto('user', idUser)}`}>Ver perfil<HiOutlineUserCircle /></a><span>&nbsp;&nbsp;</span>
                <a href={`/conversas/${setIdWithCripto('user', idUser)}`}>Ver conversa<HiOutlineArrowNarrowRight /></a>
              </>
            ) :
              <>
                <a href='/meu-perfil'>Editar perfil<RiUserSettingsLine /></a><span>&nbsp;&nbsp;</span>
                <a href='/perfil'>Ver perfil<HiOutlineUserCircle /></a>
              </>
            }
          </div>
        </>
        :
        <>
          <div>
            <p className="name">{user.name}</p>
            <p>{user.email}</p>
            <a href={`/perfil?id=${setIdWithCripto('user', idUser)}`}>Ver perfil<HiOutlineUserCircle /></a>
          </div>

        </>}


    </Container>
  );
}

export default ModalUser;
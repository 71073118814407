import React from 'react';

import ListaAniversariantes from '../ListaAniversariantes';

import { Backdrop } from '../../styles/content';
import { Wrap } from './styles';

export default function Aniversariantes({ setShowAniversariantes, dataAniversario }) {
  return (
    <>
      <Backdrop onClick={() => setShowAniversariantes(false)} />
      <Wrap>
        <h3 className="Title">Aniversariantes do mês 🎈</h3>
        <>
          <ul>
            <ListaAniversariantes dataAniversario={dataAniversario} />
          </ul>
        </>
      </Wrap>
    </>
  );
}
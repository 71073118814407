import styled from 'styled-components';

import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Container = styled.a`
  width: 175px;
  height: 175px;
  background-color: ${colors.btn_text_primary};
  box-shadow: ${effects.boxshadow};
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--ck-color-base-text);
  text-decoration: none;

  @media (max-width: 1366px) {
    width: 125px;
    height: 125px;
  }
  
  svg {
    font-size: 4rem;
    color: ${colors.primary};
    margin-bottom: 10px;

    @media (max-width: 1366px) {
      font-size: 3rem;
    }
  }
`;

export const Title = styled.h2`
  font-size: 1.1rem;
  text-align: center;
  font-weight: normal;

  @media (max-width: 1366px) {
    font-size: .9rem;
  }
`;
import React, { useState, useEffect } from 'react';
import { MdAdd, MdInfoOutline } from 'react-icons/md';

import log from '../../util/log';
import node from '../../services/api-geral';
import { sessionGet } from '../../session';

import Page from '../../components/Page';
import CInput from '../../components/ComponentInput';
import CButton from '../../components/ComponentButton';
import ColorPicker from '../../components/ColorPicker';
import TitleAndBreadcrumb from '../../components/TitleAndBreadcrumb';
import CentralMidia from '../../components/CentralMidia';
import InfoMensagem from '../../components/InfoMensagem';
import InfoHelp from '../../components/InfoHelp';

import { Container, FormConfiguracao, WrapOutClicker } from './styles';
import { Painel } from '../../styles/content';

export default function ConfiguracaoEmpresa({ dispatch, outsideClickers, setOutsideClickers }) {
  const [nomeEmpresa, setNomeEmpresa] = useState("");
  const [corPrimaria, setCorPrimaria] = useState("");
  const [corSecundaria, setCorSecundaria] = useState("");
  const [telefoneEmpresa, setTelefoneEmpresa] = useState("");
  const [urlFoto, setUrlFoto] = useState("");
  // const [midiaLogo, setMidiaLogo] = useState("");
  const [srcImagemPerfil, setSrcImagemPerfil] = useState('');
  const [midiaModal, setMidiaModal] = useState(false);
  const [showAlertMerx, setShowAlertMerx] = useState(false);
  const [msgAlertMerx, setMsgAlertMerx] = useState('');
  const [tipoAlertMerx, setTipoAlertMerx] = useState('');
  const [stateColorPickerPrimario, setStateColorPickerPrimario] = useState(false);
  const [stateColorPickerSecundario, setStateColorPickerSecundario] = useState(false);
  const [midias, setMidias] = useState(false);
  const [idMidia, setIdMidia] = useState(false);
  const [inHover, setHover] = useState(false);

  useEffect(() => {
    getEmpresa();
  }, []);

  const getEmpresa = async () => {

    const response = (await node.get(`/empresa/${sessionGet('idEmpresa')}`)).data;

    setNomeEmpresa(response[0].name);
    setCorPrimaria(response[0].cor_primaria);
    setCorSecundaria(response[0].cor_secundaria);
    setTelefoneEmpresa(response[0].telefone_empresa);
    setUrlFoto(response[0].url_foto);
    getImagePerfil(response[0].midia_id);

  };

  const updateEmpresa = async (e) => {
    e.preventDefault();

    const response = (await node.put('/empresa', {
      corPrimaria,
      corSecundaria,
      urlFoto,
      telefoneEmpresa
    })).data;

    if (response.type === 'success') {
      setMsgAlertMerx(response.msg);
      setShowAlertMerx(true);
      setTipoAlertMerx('ok');
      setTimeout(() => { setShowAlertMerx(false) }, 5000);
      document.getElementById('form-empresa').reset();
      log(`editou as informações da empresa.`);
    } else {
      setMsgAlertMerx(response.msg);
      setShowAlertMerx(true);
      setTipoAlertMerx('danger');
      setTimeout(() => { setShowAlertMerx(false) }, 5000);
    }
  }

  const changeColorPrimaria = (color) => {
    setCorPrimaria(color.hex);
  }

  const changeColorSecundaria = (color) => {
    setCorSecundaria(color.hex);
  }

  const hideColorPicker = () => {
    setStateColorPickerPrimario(false);
    setStateColorPickerSecundario(false);
  };

  const handleChangePhoto = async () => {
    const dados = {
      midia_id: idMidia
    };
    const response = (await node.put(`/empresa`, dados)).data;
    getImagePerfil(idMidia);
    setMidias(false);
    getImagePerfil(idMidia);
  };

  const getImagePerfil = async (idMidia) => {
    const response = (await node.get(`/files/${idMidia}`, { responseType: 'blob' })).data;
    setSrcImagemPerfil(window.URL.createObjectURL(response));
  };

  useEffect(() => {
    handleChangePhoto();
  }, [idMidia]);

  return (
    <>
      <CentralMidia files="img" opened={midias} format="plugin" close={() => setMidias(false)} setFile={(id) => setIdMidia(id)} />
      <Page content={(
        <>
          {(stateColorPickerPrimario || stateColorPickerSecundario) ? (
            <WrapOutClicker onClick={() => hideColorPicker()} />
          ) : ''}
          <Container className="content">
            <TitleAndBreadcrumb title='Configuração da empresa' breadcrumbs={[]} format='config' />
            <div className="content-painel">
              <Painel className="painel-configuracao-empresa">
                <FormConfiguracao id="form-empresa" onSubmit={updateEmpresa}>
                  <div className="wrap-drop">
                    {(srcImagemPerfil === '' ?
                      (
                        <div onClick={() => setMidias(true)} title="Selecione para escolher uma imagem" className="sem-imagem-perfil">
                          <MdAdd size="40px"></MdAdd>
                        </div>
                      )
                      :
                      (
                        <div className="container-filter">
                          <div onClick={() => setMidias(true)} className="img-filter">
                            <span>Alterar imagem</span>
                          </div>
                          <img title={urlFoto} src={srcImagemPerfil} className="imagem-perfil"></img>
                        </div>
                      ))}
                  </div>
                  <div className="wrap-input">
                    <CInput val={nomeEmpresa} type='text' label='Nome da empresa' disabled style='radius' />
                  </div>
                  <div className="wrap-input">
                    <div className="wrap-input wrap-relative wrap-phone">
                      <CInput classCustom="company-phone" val={telefoneEmpresa} change={e => setTelefoneEmpresa(e)} type='text' label='Telefone' mask="phoneWpp" style='radius' />
                      <InfoHelp contentInfo='Informe um número de WhatsApp para continuar recebendo mensagens quando nenhum funcionário estiver fazendo atendimento pelo chat.' />
                    </div>
                  </div>
                  <div className="wrap-input">
                    <div className="wrap-color cor">
                      <button type="button" className="btn-color-picker" onClick={() => setStateColorPickerPrimario(!stateColorPickerPrimario)} style={{ background: corPrimaria }} />
                      <CInput val={corPrimaria} type='text' label='Cor Primária' style='radius' />
                    </div>
                  </div>
                  <ColorPicker className="colorpicker" changeColor={changeColorPrimaria} cor={corPrimaria} show={stateColorPickerPrimario} handleShow={() => setStateColorPickerPrimario(false)} />

                  <div className="wrap-input">
                    <div className="wrap-color cor">
                      <button type="button" className="btn-color-picker" onClick={() => setStateColorPickerSecundario(!stateColorPickerSecundario)} style={{ background: corSecundaria }} />
                      <CInput val={corPrimaria} type='text' label='Cor Secundária' style='radius' />
                    </div>
                  </div>

                  <ColorPicker className="colorpicker" handlePrimario={setStateColorPickerPrimario} handleSecundario={setStateColorPickerSecundario} changeColor={changeColorSecundaria} cor={corSecundaria} show={stateColorPickerSecundario} handleShow={() => setStateColorPickerSecundario(false)} />

                  {(showAlertMerx) && (
                    <InfoMensagem tipoAlerta="success" conteudoAlerta={'As configurações da empresa foram atualizadas com sucesso.'} customWidth={'300px'} />
                  )}

                  <div className="wrap-btn">
                    <CButton title='Salvar' cstyle='primary small' />
                  </div>
                </FormConfiguracao>
              </Painel>
            </div>
          </Container>
        </>
      )} />
    </>
  );
}

import styled from 'styled-components';
import colors from '../../../../presets/colors';
import effects from '../../../../presets/effects';

export const Wrap = styled.div`
    position: absolute;
    bottom: 54px;
    height: 200px;
    background: #fff;
    width: 30%;
    left: 0;
    z-index: 1060;
    overflow-y: auto;
    overflow-x: hidden;

    box-shadow: ${effects.boxshadow};
    border-radius: ${effects.radius};

    &::-webkit-scrollbar {
        width: 2px;
        height: 5px;
    }

    &::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
    }

    &::-webkit-scrollbar-thumb {
        background: ${colors.featured};
        border: 0px none #ffffff;
        border-radius: 50px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: ${colors.featured};
    }

    &::-webkit-scrollbar-thumb:active {
        background: ${colors.featured};
    }

    &::-webkit-scrollbar-track {
        background: ${colors.bgprimary};
        border: 0px none #ffffff;
        border-radius: 50px;
    }

    &::-webkit-scrollbar-track:hover {
        background: #1a202e;
    }

    &::-webkit-scrollbar-track:active {
        background: #333333;
    }
    
    &::-webkit-scrollbar-corner {
        background: transparent;
    }
`;

export const Backdrop = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
`;

export const Lista = styled.div`
`;

export const Item = styled.div`
    width: 100%;
    background-color: ${colors.bgprimary};
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 2px 0;
    height: 40px;
    cursor: pointer;
    padding: 5px;

    &:hover { background: ${colors.primary}; }
`;

export const ImgAndStatus = styled.div`
    position: relative;
    background-color: #fff;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    font-size: 30px;
    display: flex;
    justify-content: center;
    box-shadow: ${effects.boxshadow};
    align-items: center;

    @media (max-width: 1400px) {
        width: 30px;
        height: 30px;  
    }

    svg {
        display: block;
        color: ${colors.primary} !important;
        font-size: 18px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
    }

    span {
        width: 10px;
        height: 10px;
        display: block;
        position: absolute;
        bottom: -5px;
        right: -3px;
        border-radius: 50%;
        border: 3px solid white;
        box-sizing: content-box;

        &.online { background-color: green; }
        &.offline { background-color: gray; }
    }
`;

export const Form = styled.div`
    background-color: ${colors.primary_background};
    height: 25px;
    margin-bottom: 10px;
    border-radius: 20px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    box-shadow: ${effects.boxshadow};

    @media (max-width: 1400px) {
        height: 30px;
    }

    svg {
        position: absolute;
        top: calc(20px - 17px);
        left: 10px;
        font-size: 22px;
        color: ${colors.primary};

        @media (max-width: 1400px) {
            top: 5px;
        }
    }

    input {
        background-color: transparent;
        width: 85%;
        height: 100%;
        border: 0;
        color: ${colors.primary};
        font-size: 12px;
        padding-left: 10px;
    }
`;

export const Content = styled.div`
    width: 75%;

    strong {
        color: ${colors.text_primary};
        display: block;
        font-weight: bold;
        letter-spacing: 0.07rem;
        font-size: 14px;

        @media (max-width: 1400px) {
            font-size: 12px;
        }

        &:hover { color: #fff; }
    }
`;

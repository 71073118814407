import styled from 'styled-components';

export const Container = styled.small`
  width: 50%;
  margin-bottom: 20px;
  

  &.full-width {
    width: 100%;
  }

  @media (max-width: 1024px) {
    width: 80%;
  }

  ul {
    li {
      list-style: inside;
    }
  }
`;

import styled from 'styled-components';

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1052;
`;

export const WrapModal = styled.div`
  background-color: white;
  width: 30%;
  border-radius: 10px;
  max-height: calc(100vh - 140px);
  position: absolute;
  left: 35%; 
  top: 110px;

  .closebutton {
    position: absolute;
    top: 27px;
    right: 0;

    svg {
      width: 30px;
      height: 30px;
      margin: 0;

      @media (max-width: 575px) {
        width: 20px;
        height: 20px;
      }
    }

    @media (max-width: 575px) {
      top: 5px;
    }
  }

  @media (max-width: 420px) {
    width: 90%;
    left: 5%;
    top: 100px;
    height: calc(100% - 100px);
  }

  @media (max-width: 1024px) {
    width: 90%;
    left: 5%;
    top: 100px;
    height: calc(100% - 100px);
  }
`;

export const WrapModalView = styled.div`
  background-color: #EFEFEF;
  width: auto;
  height: auto;
  border-radius: 10px;
  position: absolute;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 25px;

  .closebutton {
    position: absolute;
    top: 10px;
    right: 0;
    padding-right: 10px !important;

    svg {
      width: 24px;
      height: 24px;
      margin: 0;
    }
  }  

  @media (max-width: 1366px) {
    padding: 20px 15px;
  }

  @media (max-width: 1024px) {
    padding: 20px 15px;
    width: auto;
  }

  @media (max-width: 450) {
    padding: 30px 25px;
    width: auto;
    overflow-y: scroll;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 110%;

  margin-top: 15px;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: center;  
  color: #8a2387;
`;
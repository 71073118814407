import React, { useState } from 'react';
import { toast } from 'react-toastify';
import api from '../../services/api-geral';

import log from '../../util/log';
import Swal from 'sweetalert2';

import CButton from '../ComponentButton';

import { Form } from './styles';
import { MdDoneAll, MdClose } from 'react-icons/md';
import AlertaMerx from '../AlertaMerx';

export default function ConfirmForm({ action, close, items, success }) {
  const [showAlertMerx, setShowAlertMerx] = useState(false);
  const [msgAlertMerx, setMsgAlertMerx] = useState('');

  const submitConfirm = async e => {
    e.preventDefault();

    const response = (await api.delete(action + `/${items}`)).data;

    if (response.type === "success") {

      items.map(async (id, index) => {

        let type = action.substr(1);

        let name = response.name[index];

        if (type === 'users') {
          type = 'usuário';
        }

        if (type === 'formulario-assunto') {
          type = 'formulário assunto'
        }

        await log(`${type === 'users' ? 'desativou' : 'deletou'} o ` + type + ` com o nome ${name.toUpperCase()} (ID: ${id}).`);
      });
      success();
      close();
      Swal.fire({
        title: 'Sucesso!',
        text: response.msg,
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK',
      });
    } else {
      setMsgAlertMerx(response.msg);
      setShowAlertMerx(true);
      setTimeout(() => { setShowAlertMerx(false) }, 5000)
      items.map(id => {
        log(`tentou deletar o ` + action.substr(1) + ` de id: ${id}, mas houve um erro.`);
      });
    }
  };

  return (
    <Form onSubmit={submitConfirm}>
      <p>Você precisa CONFIRMAR para executar esta ação:</p>
      <div className='wrapbuttons'>
        {(showAlertMerx) && (
          <AlertaMerx
            type='danger'
            style='default'
            text={msgAlertMerx}
            css={['auto', 'auto', '-64px', '139px']}
            context='in'
          />
        )}
        <CButton ctype='submit' cstyle='success small' title={(<><MdDoneAll /> Confirmar</>)} />
        <CButton ctype='button' click={close} cstyle='danger small' title={(<><MdClose /> Cancelar</>)} />
      </div>
    </Form>
  );
}

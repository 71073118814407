import styled from 'styled-components';

import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Container = styled.nav`
    display: flex;
    flex-direction: column;
    position: relative;

    &.me {
        color: ${colors.texton};
            border-radius: 5px;
            align-self: flex-end;
            max-width: 60%;
            overflow-wrap: break-word;
            word-wrap: break-word;
            box-shadow: ${effects.boxshadow};
            margin: 10px;
            min-width: 200px;
            letter-spacing: 1px;

            @media (max-width: 1400px) {
                font-size: 13px;
                font-weight: normal;
            }

            @media (max-width: 575px) {
                font-size: 11px;
                min-width: 140px;
            }

            small {
                display: block;
                font-size: 12px;
                font-weight: normal;
                color: ${colors.texton};
                margin-top: 5px;

                @media (max-width: 1400px) {
                    font-size: 10px;
                }
            }
    }

    &.you {
        color: ${colors.texon};
            min-width: 200px;
            background-color: ${colors.bg_from_primary};
            box-shadow: ${effects.boxshadow};
            border-radius: 5px;
            max-width: 60%;
            letter-spacing: 1px;
            align-self: flex-start;
            margin: 10px;
            overflow-wrap: break-word;
            word-wrap: break-word;

            @media (max-width: 1400px) {
                font-size: 13px;
                font-weight: normal;
            }

            @media (max-width: 575px) {
                font-size: 11px;
                min-width: 140px;
            }

            small {
                display: block;
                font-weight: normal;
                font-size: 12px;
                margin-top: 5px;

                @media (max-width: 1400px) {
                    font-size: 10px;
                }
            }
    }

    &.me-anexo {
            background-color: ${colors.primary};
            border-radius: 10px;
            max-width: 60%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 10px 25px;
            align-self: flex-end;
            color: white;
            overflow-wrap: break-word;
            word-wrap: break-word;
            margin-bottom: 20px;
            margin-right: 20px;

            .titulo{
                font-size: 15px;
                font-weight: bold;
                margin-bottom: 10px;
            }

            span {
              color: ${colors.textinprimary};
            }
          
        }

    &.you-anexo {
        color: white;
        background-color: ${colors.featured};
        border-radius: 10px;
        max-width: 60%;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 10px 25px;
        align-self: flex-start;
        margin-bottom: 20px;
        overflow-wrap: break-word;
        word-wrap: break-word;

        .titulo{
            font-size: 15px;
            font-weight: bold;
            margin-bottom: 10px;
        }

        a {
            align-self: flex-start;
            color: #fff;
            font-weight: bold;
            cursor: pointer;
            margin-top: 10px;

            &:hover{
                text-decoration: underline;
            }
        }
        
    }
    
    
    &.me {
        &:hover {
            .msg-holder {
                & > div {
                    display: flex;
                }
            }
        }

        &:first-child {
            .msg-holder {
                ul {
                    top: -35px;
                }
            }
        }

        @media (max-width: 1024px) {
            .msg-holder {
                p.text-msg {
                    margin-right: 15px;
                }

                .download-file {
                    padding-right: 15px;
                }

                & > div {
                    display: flex;
                }
            }
        }
    }

    &.you {
        &:hover {
            .msg-holder {
                & > .optionBox {
                    display: flex;
                }
            }
        }

        &:first-child {
            .msg-holder {
                ul {
                    top: -35px;
                }
            }
        }

        @media (max-width: 1024px) {
            .msg-holder {
                p.text-msg {
                    margin-right: 15px;
                }

                .download-file {
                    padding-right: 15px;
                }

                & > div {
                    display: flex;
                }
            }
        }
    }

    .msg-holder {
        padding: 8px 25px;

        width: 100%;
        
        .download-file {
            display: flex;
            align-items: center;
            padding: 5px 0px;
            padding-right: 5px;

            svg {
                margin-right: 5px;
                font-size: 25px;
                color: ${colors.primary}
            }

            button {
                padding: 2px;
                margin-left: 10px;
                display: flex;
                align-items: center;
                border-radius: 50%;
                border: solid 1px ${colors.primary};
                background: none;

                &:hover {
                    background: ${colors.primary};
                    
                    svg {
                        color: white;
                    }
                }

                svg {
                    font-size: 20px;
                    margin-right: 0;
                }
            }
        }

        .text-msg {
            overflow-wrap: break-word;
            word-wrap: break-word;
            white-space: ${props => props.hasChatAtendimento && "pre-wrap"};

            li {
                list-style-position: inside;
            }

            img {
                margin-top: 10px;
                width: 280px;

                @media (max-width: 950px) {
                    width: 180px;
                }

                @media (max-width: 575px) {
                    width: 120px;
                }
            }

            a {
                word-break: break-all;
            }

            p {
                max-width: 700px;

                @media (max-width: 1024px) {
                    max-width: 500px;
                }
            }
        }
    }

`;

export const ActionMessages = styled.div`
    background-color: transparent;
    display: none;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;

    svg {
        font-size: 20px;
    }
`;

export const BackgroundModalAction = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1080;
`;

export const ModalAction = styled.ul`
    width: 250px;
    color: #8a2387;
    background-color: #FFF;
    box-shadow: 0 1px 15px rgb(0 0 0 / 20%), 0 1px 6px rgb(0 0 0 / 20%);
    position: absolute;
    top: 25px;
    right: 10px;
    z-index: 1090;

    &.you {
        width: auto;
    }

    @media (min-width: 1367px) {
        /* width: 225px; */
    }

    li {
        padding: 10px 15px;
        list-style: none;
        cursor: pointer;
        display: flex;
        /* justify-content: space-around; */
        align-items: center;


        svg {
            margin-right: 15px;
        }
    }
`;
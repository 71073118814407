import React from 'react';

import { Container, Indicator } from './styles';

function ModalInformation({ modalClass, top, right, bottom, left, content }) {
  return (
    <Container className={modalClass} top={top} right={right} bottom={bottom} left={left}>
      {/* <Indicator /> */}
      <p>{content}</p>
    </Container>
  );
}

export default ModalInformation;
import styled from 'styled-components';

export const Container = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;

  input {
    margin-bottom: 10px;
  }
`;

import styled from 'styled-components';

import colors from '../../presets/colors'

export const Wrap = styled.div`
  width: ${props => (props.local == "checkbox-espalhar") ? '10%' : ''};
  display: flex;
  justify-content: center;
  align-items: center;

  .box {
    width: 15px;
    height: 15px;
    border: 2px solid gray;
    border-radius: 2px;
    cursor: pointer;

    &.in {
      border: 2px solid ${colors.primary};
      background-color: ${colors.primary};
    }
  }
`;

import React, { useState, useEffect } from 'react';

import { Backdrop } from '../../styles/content';
import { Wrap } from './styles';
import api from '../../services/api-geral';

import ListaNotificacoes from '../ListaNotificacoes';

export default function Notifications({ setShowNotificacoes, notifications, loadNotifications }) {

  const readAll = async () => {
    await api.put(`/notifications/all`);
    loadNotifications();
  }

  return (
    <>
      <Backdrop onClick={() => setShowNotificacoes(false)} />
      <Wrap className={notifications.length > 0 ? 'full' : ''}>
        {notifications.length == 0 && <h3 className="no-notifications">Não há novas notificações</h3>}
        {notifications.length > 0 && (
          <>
            <div className="header-notifications">
              <h3>Notificações</h3>
              <button onClick={() => { readAll(); setShowNotificacoes(false) }} type="button" className="cta-ler-todas">
                Marcar todas como lidas
              </button>
            </div>
            <ul>
              <ListaNotificacoes notifications={notifications} />
            </ul>
            <div className="wrap-buttons">
              {(notifications.length === 5) && (
                <button onClick={() => window.location = '/mostrar-notificacoes'} type="button" className="cta-ver-mais">
                  Ver mais
                </button>
              )}
            </div>
          </>
        )}
      </Wrap>
    </>
  );
}

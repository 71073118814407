import React, { useEffect, useState } from 'react';

import bags from '../../../../assets/images/LembretesIcones/bags.png';
import off from '../../../../assets/images/LembretesIcones/off.png';
import docter from '../../../../assets/images/LembretesIcones/docter.png';

import socket from '../../../../services/socket';


import { CustomSelect, SelectDropdown } from './styles';

import { GoPin } from 'react-icons/go';

const Option = ({TypeOfNote, initialValue }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(initialValue);
  
    useEffect(()=> {
      TypeOfNote(selectedValue)
    },[selectedValue])
    
    const handleClick = () => {
      setIsOpen(!isOpen);
    };

    useEffect(() => {
      socket.on('noteTypeDefined', (reminder) => {
        setSelectedValue(null)
      });
      return () => {
          socket.off('noteTypeDefined');
      };
  }, []);
    
    const handleSelectOption = (value) => {
      setSelectedValue(value);
      TypeOfNote(selectedValue)
      setIsOpen(false);
    };

  return (
    <CustomSelect className={isOpen ? 'active' : ''}>
      <div
        className='select-button'
        aria-expanded={isOpen}
        onClick={handleClick}
      >
        <span className='selected-value'>{selectedValue}</span>
        <span className='arrow'></span>
      </div>
      <SelectDropdown role='listbox' id='select-dropdown'>
        <li role='option' onClick={() => handleSelectOption('Férias')}>
          <input type='radio' />
          <label htmlFor=''>
            <div className='icon'>
              <img src={bags} alt='' />
            </div>{' '}
            Férias
          </label>
        </li>
        <li role='option' onClick={() => handleSelectOption('Atestado')}>
          <input type='radio' />
          <label htmlFor=''>
            <div className='icon'>
              <img src={docter} alt='' />
            </div>{' '}
            Atestado
          </label>
        </li>
        <li role='option' onClick={() => handleSelectOption('Ausente')}>
          <input type='radio' />
          <label htmlFor=''>
            <div className='icon'>
              <img src={off} alt='' />
            </div>{' '}
            Ausente
          </label>
        </li>
        <li role='option' onClick={() => handleSelectOption('Outro')}>
          <input type='radio' />
          <label htmlFor=''>
            <div className='icon'>
              <GoPin />
            </div>{' '}
            Outro
          </label>
        </li>
      </SelectDropdown>
    </CustomSelect>
  );
};

export default Option;

import React, { useState, useEffect, useRef } from 'react';
import { RiFacebookLine, RiInstagramLine, RiTwitterLine, RiWhatsappLine, RiYoutubeLine, RiLinkedinLine, RiCloseFill, RiArrowUpSLine, RiArrowDownSLine, RiGlobalLine, RiInformationLine } from 'react-icons/ri';
import { AiFillEdit, AiOutlineMail } from "react-icons/ai";
import Swal from 'sweetalert2';

import Alerta from '../../../util/Alerta';
import api from '../../../services/api-geral';

import ModalEdit from '../ModalEdit';
import PreviewMobile from '../../PreviewMobile';
import ModalInformation from '../ModalInformation';

import ComponentInput from '../../ComponentInput';
import ComponentButton from '../../ComponentButton';

import { Painel } from '../../../styles/content';
import { Toggles, Info } from './styles';

function RedesSociais({ switchTab, setTabs, data, idPage }) {
  const [address, setAddress] = useState('55');
  const [type, setType] = useState('');
  const [position, setPosition] = useState('');

  const [page, setPage] = useState();
  const [socialNetworks, setSocialNetworks] = useState({});

  const [flagSocials, setFlagSocials] = useState();

  const [modalInformation, setModalInformation] = useState('');

  const [showEdit, setShowEdit] = useState('');
  const [idItemEdit, setIdItemEdit] = useState(0);
  const [editText, setEditText] = useState('');

  const inputSocialMedias = useRef();

  const socialIcons = {
    facebook: <RiFacebookLine />,
    instagram: <RiInstagramLine />,
    twitter: <RiTwitterLine />,
    whatsapp: <RiWhatsappLine />,
    youtube: <RiYoutubeLine />,
    linkedin: <RiLinkedinLine />,
    mail: <AiOutlineMail />
  }

  useEffect(() => {
    if (switchTab !== 'redes-sociais') {
      setTabs(switchTab);
    }
  }, [switchTab]);

  /*useEffect(() => {
    handleUpdateFlags();
  }, [flagSocials]);*/

  useEffect(() => {
    if (data)
      getSocialNetworks();
  }, [data]);

  const getSocialNetworks = async () => {
    const response = (await api.get(`/social-networks/${data.id}`)).data;
    setSocialNetworks(response);
    setPosition(response.length + 1);

    const response2 = (await api.get('/page?scope=flags')).data;

    if (response2 !== null) {
      setFlagSocials(response2.flagSocials);
    }
  };

  const handleUpdateFlags = async (flag) => {
    setFlagSocials(flag);
    await api.put('/page?scope=flags', {
      flagSocials: flag
    });
    Swal.fire({
      icon: 'success',
      title: 'Alterações salvas',
      showConfirmButton: false,
      timer: 1500
    })
  };

  const handleSubmitForm = async e => {
    e.preventDefault();

    var socialLink = address;

    if (type === 'whatsapp') {
      var wpp = address.replace(/[^\w\s]/gi, '');
      wpp = wpp.replace(' ', '');
      wpp = wpp.replace(' ', '');
      socialLink = 'https://api.whatsapp.com/send?phone=' + wpp;
    }

    const response = (await api.post('/social-networks', {
      link: socialLink,
      type,
      position,
      id_page: data.id
    })).data;

    if (response.success) {
      Alerta.success("Rede social inserida com sucesso!");
    } else {
      Alerta.error("Ocorreu um erro!", "Tente novamente mais tarde!");
    }

    getSocialNetworks();
  }

  const changePositionSocialNetworks = async (action, item, otherItem) => {
    if (action === 'previous') {
      const responseItem = (await api.put(`/social-networks/${item.id}`, {
        position: item.position - 1
      })).data;

      const responsePreviusItem = (await api.put(`/social-networks/${otherItem.id}`, {
        position: otherItem.position + 1
      })).data;
    } else if (action === 'next') {
      const responseItem = (await api.put(`/social-networks/${item.id}`, {
        position: item.position + 1
      })).data;

      const responseNextItem = (await api.put(`/social-networks/${otherItem.id}`, {
        position: otherItem.position - 1
      })).data;
    }

    getSocialNetworks();
  }

  const deleteSocialNetwork = async (id) => {
    const response = (await api.delete(`/social-networks/${data.id}/${id}`)).data;

    if (response.success) {
      Alerta.success("Rede social removida com sucesso!");
    } else {
      Alerta.error("Ocorreu um erro!", "Tente novamente mais tarde!");
    }

    getSocialNetworks();
  }

  const editSocial = async () => {
    const response = (await api.put(`/social-networks-edit/${idItemEdit}`, { link: editText })).data;

    if (response.success) {
      Alerta.success("Rede social atualizada com sucesso!");
    } else {
      Alerta.error("Ocorreu um erro!", "Tente novamente mais tarde!");
    }

    setShowEdit(false);

    getSocialNetworks();
  }

  const setFocusInput = () => {
    if (inputSocialMedias.current) {
      inputSocialMedias.current.focus();
    }
  }

  return (
    <div className="wrap-panels">
      <div className="redes-sociais">
        <Painel className="painel-configuracao-site">
          <form onSubmit={handleSubmitForm}>
            <Toggles>
              <li>
                <div className="title">
                  <div className="wrap-icon">
                    <RiGlobalLine />
                  </div>
                  <strong>Redes sociais</strong>
                </div>
                <div onClick={() => handleUpdateFlags(!flagSocials)} className={`toggle-funcionalidade ${flagSocials && 'active'}`}>
                  <div className="indicador"></div>
                </div>
              </li>
            </Toggles>
            <label>Escolha a rede social:</label>
            <ul className="type-link">
              <li className={type === 'whatsapp' ? 'whatsapp active' : 'whatsapp'} onClick={() => { setAddress('55 (__) _____-____'); setType('whatsapp'); setFocusInput() }}><RiWhatsappLine /></li>
              <li className={type === 'facebook' ? 'facebook active' : 'facebook'} onClick={() => { setAddress('https://www.facebook.com/'); setType('facebook'); setFocusInput() }}><RiFacebookLine /></li>
              <li className={type === 'instagram' ? 'instagram active' : 'instagram'} onClick={() => { setAddress('https://www.instagram.com/'); setType('instagram'); setFocusInput() }}><RiInstagramLine /></li>
              <li className={type === 'twitter' ? 'twitter active' : 'twitter'} onClick={() => { setAddress('https://twitter.com/'); setType('twitter'); setFocusInput() }}><RiTwitterLine /></li>
              <li className={type === 'youtube' ? 'youtube active' : 'youtube'} onClick={() => { setAddress('https://www.youtube.com/'); setType('youtube'); setFocusInput() }}><RiYoutubeLine /></li>
              <li className={type === 'linkedin' ? 'linkedin active' : 'linkedin'} onClick={() => { setAddress('https://www.linkedin.com/'); setType('linkedin'); setFocusInput() }}><RiLinkedinLine /></li>
              <li className={type === 'mail' ? 'mail active' : 'mail'} onClick={() => { setAddress(''); setType('mail'); setFocusInput() }}><AiOutlineMail /></li>
            </ul>
            {type !== '' && (
              <Info>
                {type === 'whatsapp' ? (
                  <ComponentInput classCustom="input-with-information" mask="phoneWpp" ctype="text" label="Whatsapp" val={address} change={setAddress} required focused={true} reference={inputSocialMedias} />
                ) : type === 'mail' ? (
                  <ComponentInput classCustom="input-with-information" ctype="text" label="E-mail" val={address} change={setAddress} required focused={true} reference={inputSocialMedias} />
                ) : (
                  <ComponentInput classCustom="input-with-information" ctype="text" label="Endereço" val={address} change={setAddress} required focused={true} reference={inputSocialMedias} />
                )}
                <div className='info-modal'>
                  <RiInformationLine onMouseOver={() => setModalInformation('show')} onMouseOut={() => setModalInformation('')} />
                  <ModalInformation modalClass={modalInformation} top='0' left='-200px' content={type === 'whatsapp' ? 'Informe o número de seu Whatsapp aqui' : 'Informe o endereço do seu perfil aqui'} />
                </div>
              </Info>
            )}
            <ComponentButton disabled={type === '' ? true : false} title="Adicionar rede social" cstyle="btn-link primary" />
          </form>
          {showEdit &&
            <ModalEdit
              setShowEdit={setShowEdit}
              id={idItemEdit}
              editText={editText}
              setText={setEditText}
              type='Redes'
              submit={editSocial} />
          }
          <ul className="list-links">
            {(socialNetworks.length) ?
              socialNetworks.map((item, index, arr) => (
                <li key={item.id}>
                  {(socialNetworks.length > 1) &&
                    <div className="arrow-items">
                      {(item.position !== 1) &&
                        <RiArrowUpSLine onClick={() => changePositionSocialNetworks('previous', arr[index], arr[index - 1])} />
                      }
                      {(item.position !== socialNetworks.length) &&
                        <RiArrowDownSLine onClick={() => changePositionSocialNetworks('next', arr[index], arr[index + 1])} />
                      }
                    </div>
                  }
                  <div className="content-item">
                    <div className={`wrap-icon ${item.type}`}>
                      {(socialIcons[item.type])}
                    </div>
                    <div className="info">
                      <strong>{item.title}</strong>
                      <small>{item.link}</small>
                    </div>
                    <div className="buttons">
                      <button className="btn-edit" onClick={() => { setShowEdit(true); setIdItemEdit(item.id); setEditText(item.link); }}><AiFillEdit /></button>
                      <button className="btn-delete" onClick={() => deleteSocialNetwork(item.id)}><RiCloseFill /></button>
                    </div>
                  </div>
                </li>
              )) :
              <li>
                <div className="content-item">
                  <div className="info">
                    <strong>Nenhuma rede social cadastrada!</strong>
                  </div>
                </div>
              </li>
            }
          </ul>
        </Painel>
        <div className="mobile-preview">
          <PreviewMobile socials={flagSocials} idPage={data.id} redesIcones={socialNetworks} />
        </div>
      </div>
    </div>
  );
}

export default RedesSociais;
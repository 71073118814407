import styled from 'styled-components';
import colors from '../../../../presets/colors';

export const Wrap = styled.div`
    display: flex;
    border-radius: 10px;
    background-color: ${colors.primary_background};
    margin: 10px 0;
    padding: 5px;

    button{
        background-color: white;
        border: none;
        width: 30px;
        height: 30px;
        border-radius: 4px;
        transition: all 0.3s;
        cursor: pointer;
        margin-right: 5px;
    }
      
    button:hover{
        background-color: #DCDCDC;
    }
    
    button svg{
        border: none;
        width: 15px;
        height: 10px;
    }
      
    .toolbar-divisor{
        display: block;
        height: 30px;
        width: 1px;
        margin-right: 5px;
        background-color: #DCDCDC;
    }
`;
import React from 'react';
import EmojiPicker from 'emoji-picker-react';

import { Wrap, Backdrop } from './styles';

export default function Emoji({ onEmojiClick, setShowEmojis }) {
  return (
    <>
      <Backdrop onClick={() => setShowEmojis(0)} />
      <Wrap>
        <EmojiPicker 
          onEmojiClick={onEmojiClick} 
          disableSearchBar='true' 
          disableSkinTonePicker='true'
          groupNames={{
            smileys_people: 'smileys e pessoas',
            animals_nature: 'animais e natureza',
            food_drink: 'comida e bebida',
            travel_places: 'viagens e lugares',
            activities: 'atividades',
            objects: 'objetos',
            symbols: 'símbolos',
            flags: 'bandeiras',
            recently_used: 'mais usados',
          }}
        />
      </Wrap>
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { IoMdLink } from 'react-icons/io';
import {
  RiCloseFill,
  RiAddLine,
  RiGlobalLine,
  RiKeyboardLine,
  RiWhatsappLine,
  RiArrowUpSLine,
  RiArrowDownSLine,
  RiAccountCircleLine,
  RiImageLine
} from 'react-icons/ri';

import { AiFillEdit } from "react-icons/ai";

import { Toggles, WrapPanel } from './styles';

import api from '../../../services/api-geral';

import Swal from 'sweetalert2';

import Alerta from '../../../util/Alerta';

import ComponentInput from '../../ComponentInput';
import ComponentButton from '../../ComponentButton';
import ModalEdit from '../ModalEdit';

import PreviewMobile from '../../PreviewMobile';

import { Painel } from '../../../styles/content';

function Definicoes({ switchTab, setTabs, data, flagSocials, setFlagSocials, idPage }) {

  const [title, setTitle] = useState('');
  const [address, setAddress] = useState('');
  const [position, setPosition] = useState('');
  const [showEdit, setShowEdit] = useState('');
  const [idItemEdit, setIdItemEdit] = useState(0);
  const [editText, setEditText] = useState('');
  const [editUrl, setEditUrl] = useState('');

  const [links, setLinks] = useState({});

  const linksComIcones = ["amazon", "americanas", "apple", "deezer", "facebook", "g1", "instagram", "magazineluiza", "mercadolivre", "r7", "shein", "shopee", "spotify", "submarino", "tiktok", "twitter", "twitch", "youtu"]

  useEffect(() => {
    if (switchTab !== 'definicoes') {
      setTabs(switchTab);
    }
  }, [switchTab]);

  useEffect(() => {
    if (data)
      getLinks();
  }, [data]);

  const getLinks = async () => {
    const response = (await api.get(`/links/${data.id}`)).data;
    setLinks(response);
    setPosition(response.length + 1);
  };

  const handleSubmitForm = async e => {
    e.preventDefault();

    const response = (await api.post('/links', {
      title,
      link: address,
      position,
      id_page: data.id
    })).data;

    if (response.success) {
      Alerta.success("Link inserido com sucesso!");
    } else {
      Alerta.error("Ocorreu um erro!", "Tente novamente mais tarde!");
    }

    setTitle('');
    setAddress('');

    getLinks();
  }

  const changePositionLink = async (action, item, otherItem) => {
    if (action === 'previous') {
      const responseItem = (await api.put(`/links/${item.id}`, {
        position: item.position - 1
      })).data;

      const responsePreviusItem = (await api.put(`/links/${otherItem.id}`, {
        position: otherItem.position + 1
      })).data;
    } else if (action === 'next') {
      const responseItem = (await api.put(`/links/${item.id}`, {
        position: item.position + 1
      })).data;

      const responseNextItem = (await api.put(`/links/${otherItem.id}`, {
        position: otherItem.position - 1
      })).data;
    }

    getLinks();
  }

  useEffect(() => {

  }, [editText, editUrl])

  const editLink = async (id) => {
    const response = (await api.put(`/link-edit/${idItemEdit}`, { title: editText, link: editUrl })).data;

    if (response.success) {
      Alerta.success("Link atualizado com sucesso!");
    } else {
      Alerta.error("Ocorreu um erro!", "Tente novamente mais tarde!");
    }

    setShowEdit(false);

    getLinks();
  }

  const deleteLinks = async (id) => {
    const response = (await api.delete(`/links/${data.id}/${id}`)).data;

    if (response.success) {
      Alerta.success("Link removido com sucesso!");
    } else {
      Alerta.error("Ocorreu um erro!", "Tente novamente mais tarde!");
    }

    getLinks();
  }

  const linkWithIcon = (link) => {
    let found = false;
    for (const links of linksComIcones) {
      if (link.includes(links)) {
        found = true;
        break;
      }
    }
    if (found) {
      return true;
    } else {
      return false;
    }
  }

  const iconsName = (link) => {
    let closestMatchIndex = -1;
    let closestMatchLength = 0;

    linksComIcones.forEach((option, index) => {
      if (link.indexOf(option) !== -1 && option.length > closestMatchLength) {
        closestMatchIndex = index;
        closestMatchLength = option.length;
      }
    });
    return linksComIcones[closestMatchIndex]
  }

  const changeIcon = async (type, item) => {
    const response = (await api.put(`/link-icon/${item.id}`, { flag_icon: type })).data;

    if (response.success) {
      Alerta.success("Link atualizado com sucesso!");
    } else {
      Alerta.error("Ocorreu um erro!", "Tente novamente mais tarde!");
    }

    getLinks();
  }

  return (
    <WrapPanel>
      <div className="painel-preview">
        <Painel className="painel-configuracao-site">
          <form onSubmit={handleSubmitForm}>
            <label>Insira os links:</label>
            <ComponentInput ctype="text" label="Título" val={title} change={setTitle} required />
            <ComponentInput ctype="text" label="Endereço virtual (URL)" val={address} change={setAddress} required />
            <ComponentButton title="Adicionar link" cstyle="btn-link primary" />
          </form>
          {showEdit &&
            <ModalEdit
              setShowEdit={setShowEdit}
              id={idItemEdit}
              url={editUrl}
              setUrl={setEditUrl}
              editText={editText}
              setText={setEditText}
              type='Links'
              submit={editLink} />
          }
          <ul className="list-links">
            {(links.length) ?
              links.map((item, index, arr) => (
                <li key={item.id}>
                  {(links.length > 1) &&
                    <div className="arrow-items">
                      {(item.position !== 1) &&
                        <RiArrowUpSLine onClick={() => changePositionLink('previous', arr[index], arr[index - 1])} />
                      }
                      {(item.position !== links.length) &&
                        <RiArrowDownSLine onClick={() => changePositionLink('next', arr[index], arr[index + 1])} />
                      }
                    </div>
                  }
                  <div className="content-item">
                    {linkWithIcon(item.link) ?
                      <div className="wrap-icon">
                        {item.flag_icon ?
                          <>
                            <img src={`https://img.merx.app.br/images/icones-minisite/${iconsName(item.link)}.webp`} />
                            <button className="btn-remove" onClick={() => changeIcon(false, item)}><RiCloseFill /></button>
                          </>
                          :
                          <>
                            <button className="btn-add" onClick={() => changeIcon(true, item)}><RiAddLine /></button>
                            <div className='default'><IoMdLink /></div>
                          </>
                        }
                      </div>
                      :
                      <div className="wrap-icon"><IoMdLink /></div>
                    }
                    <div className="info">
                      <span>{item.title}</span>
                      <small>{item.link}</small>
                    </div>
                    <div className="buttons">
                      <button className="btn-edit" onClick={() => { setShowEdit(true); setIdItemEdit(item.id); setEditText(item.title); setEditUrl(item.link) }}><AiFillEdit /></button>
                      <button className="btn-delete" onClick={() => deleteLinks(item.id)}><RiCloseFill /></button>
                    </div>
                  </div>
                </li>
              )) :
              <li>
                <div className="content-item">
                  <div className="info">
                    <strong>Nenhum link cadastrado!</strong>
                  </div>
                </div>
              </li>
            }
          </ul>
        </Painel>
        <div className="mobile-preview">
          <PreviewMobile redes={flagSocials} links={links} idPage={data.id} />
        </div>
      </div>
    </WrapPanel>
  );
}

export default Definicoes;
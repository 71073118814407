import styled from "styled-components";
import colors from "../../../presets/colors";
import effects from "../../../presets/effects";


export const Files = styled.div`

            color: ${colors.texton};
            border-radius: 5px;
            padding: 8px 15px;
            align-self: flex-end;
            overflow-wrap: break-word;
            word-wrap: break-word;
            box-shadow: ${effects.boxshadow};
            margin: 10px;
            letter-spacing: 1px;

            img {
                cursor: pointer;
                width: 100%;
                max-width: 200px;
                height: auto;
            }

            @media (max-width: 1400px) {
                font-size: 13px;
                font-weight: normal;
            }

            @media (max-width: 575px) {
                font-size: 11px;
            }

            small {
                display: block;
                font-size: 12px;
                font-weight: normal;
                color: ${colors.texton};
                margin-top: 5px;

                @media (max-width: 1400px) {
                    font-size: 10px;
                }
            }

            &.small {
                max-width: none;
                box-shadow: none;
                margin: 0 auto;
                font-size: .8rem;
                padding: 0;

                @media (max-width: 678px) {
                    font-size: 11px;
                }

                svg {
                    @media (max-width: 678px) {
                        font-size: 16px;
                    }
                }
            }

.download-file {
            display: flex;
            align-items: center;
            padding: 5px 0px;
            padding-right: 5px;
            justify-content: space-between;

            svg {
                margin-right: 5px;
                font-size: 25px;
                color: ${colors.primary};
                @media (max-width: 678px) {
                        font-size: 16px;
                    }
                
            }

            button {
                padding: 2px;
                margin-left: 10px;
                display: flex;
                align-items: center;
                border-radius: 50%;
                border: solid 1px ${colors.primary};
                background: none;

                
                &:hover {
                    background: ${colors.primary};
                    
                    svg {
                        color: white;
                    }
                }
                
                svg {
                    font-size: 20px;
                    margin-right: 0;
                    @media (max-width: 678px) {
                        font-size: 16px;
                    }
                }
                &.trash {
                    border: none;
                    &:hover {
                        background: none;
                        transform: scale(1.2);
                        svg {
                        color: ${colors.primary};
                    }
                    }        
                }
            }
            
        }
`
export const Text = styled.div`
    color: ${colors.primary};
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: .5rem;

    svg {
        font-size: 20px;
    }
`

export const IconName = styled.div`
        display: flex;
        align-items: center;

`

export const FullScreenImg = styled.div`
        z-index: 2000;
        position: fixed;
        padding: 20px;
        left: 110px;
        top: 90px;
        background-color: rgba(0, 0, 0, 0.5);
        width: calc(100% - 110px);
        height: calc(100vh - 90px);

        @media (max-width: 1440px) {
            top: 70px;
            width: calc(100% - 110px);
            height: calc(100vh - 70px);
        }

        @media (max-width: 1024px) {
            top: 70px;
            left: 0;
            width: 100%;
            height: calc(100vh - 70px);
        }

        .fullscreen-holder {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
            text-align: center;

            img {
                margin: auto;
                height: auto;
                max-height: 100%;
                width: auto;
                max-width: 100%;
                zoom: 10;
                min-width: 30%;
            }

           
        }

        .open-close-image {
            right: 20px;
            top: 20px;
            display: flex;
            text-align: center;
            position: absolute;

            a {
                font-size: 20px;
                //border: 1px solid white;
                padding: 5px 10px;
                border-radius: 10px;
                background-color: #5cb85c;
                color: white;
                text-decoration: none;
                margin-right: 10px;
            }

            button {
                background-color: ${colors.secondary};
                color: white;
                border: none;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    font-size: 30px;
                }
            }
        }
`

import styled from 'styled-components';

import colors from '../../presets/colors';

export const Wrap = styled.div`
  width: calc(100vw - 100px);
  height: 100vh;
  margin-left: 100px;
  background-color: ${colors.bgsecondary};
  transition: width .5s;

  @media (max-width: 1024px) {
    margin-left: 0;
    width: 100%;
    
    @media (max-width: 991px) {
      width: 100vw;
      margin: 0;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  height: calc(100% - 90px);
  padding: 25px;
  position: fixed;
  top: 90px;
  right: 0;

  @media (max-width: 1440px) {
    height: calc(100% - 70px);
    top: 70px;
  }

  @media (max-width: 1024px) {
    /* position: relative;
    overflow-y: scroll; */
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 30px;
  }

  &.none {
    width: 100%;
  }

  &.menu, &.submenu {
    width: calc(100% - 110px);

    /* @media (max-width: 1440px) {
      width: calc(100% - 100px);
    } */
  }

  /* &.submenu {
    /* width: calc(100% - (230px + 125px));
  } */

  &.none, &.menu, &.submenu {
    @media(max-width: 1024px) {
      width: 100%;
      padding: 10px;
    }
  }
`;
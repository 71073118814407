import React, { useEffect, useState } from 'react';

import api from '../../services/api-geral';
import { MdPerson } from 'react-icons/md';
import socket from '../../services/socket';
import renderImg from '../../util/renderImg';
import { sessionGet } from '../../session';
import getFiles from '../../util/getFiles';
import { getIdWithoutCripto } from '../../util/formatParamsUrl';

import { Item, ImgAndStatus, Content } from './styles';

export default function ItemConversa({ user, type, getUsuarios, fila = false, atendimento }) {

  const [count, setCount] = useState(0);

  useEffect(() => {
    if (window.location.href.includes('atendimentos-ao-cliente')) {
      getUnreadMsgs('all');
    }

    if (window.location.href.includes('conversas')) {
      let url = window.location.href;
      var decrypto = url.split('/');
      decrypto = decrypto[decrypto.length - 1];
      if (decrypto !== 'conversas') {
        decrypto = getIdWithoutCripto(decrypto);
        if (user.id === Number(decrypto)) {
          user.count = 0;
        }
      }
    }
  }, [])

  //CHAT EXTERNO
  useEffect(() => {

    if (window.location.href.includes('atendimentos-ao-cliente')) {
      socket.on('read_msg_atendimento', (id) => {
        if (id === atendimento.id) {
          setCount(0);
        }
      });

      socket.on('msg_atendimento_atendente', (msg) => {
        if (msg.id_atendimento === atendimento.id) {
          getUnreadMsgs(msg.id_atendimento);
        }
      });

      return () => {
        socket.off('read_msg_atendimento');
        socket.off('msg_atendimento_atendente');
      }
    }

  }, []);
  //

  const getUnreadMsgs = async (id = 'all') => {
    if (id === 'all') {
      const response = (await api.get(`/msgs-at-unread/${atendimento.id}`)).data;
      setCount(response.count);
    }

    if (id === atendimento.id) {
      const response = (await api.get(`/msgs-at-unread/${atendimento.id}`)).data;
      setCount(response.count);
    }
  }

  return (
    <Item>
      <ImgAndStatus>
        {(user.files !== "" && user.files !== null && type !== 'externo') ? <img src={getFiles(user.files.path)} /> : (
          <MdPerson color="#fff" />
        )}
        {type !== 'externo' ? <span id={`user${user.id}`} className={(user.presence_status === 1) ? ((user.statusCustom != null) ? user.statusCustom.status_color : 'online') : 'offline'}></span> : ''}
      </ImgAndStatus>
      <Content>
        {
          (type === 'externo') ? (
            <>
              <span>{user.name}</span>
              {count > 0 ? <span className="nmr-msgs">{count}</span> : ''}
              {
                (atendimento) ?
                  (atendimento.status === 'fila') ?
                    <span className="badge-aguardando">Na fila</span>
                    : (atendimento.status === 'ativo') ?
                      <span className="badge-atendimento">Em atendimento</span>
                      : (atendimento.status === 'finalizado' || atendimento.status === 'avaliado') ?
                        <span className="badge-finalizado">Finalizado</span>
                        : <span className="badge-transferido">Transferido</span>
                  : ''
              }
            </>
          ) :
            (
              <>
                <span className="user-chat">{user.name}</span>
                <div className='row'>
                  <span className="setor-chat">{(user.setor) ? user.setor.name : ''}</span>
                  {type !== 'externo' && <span className={(user.count > 0) ? 'status-user-notification' : 'status-user'}>{(user.presence_status === 1) && ((user.statusCustom != null) ? user.statusCustom.status : 'Online')}</span>}
                </div>
                <div className="time-msgs">
                  {user.count > 0 ? <span className="nmr-msgs">{user.count}</span> : ''}
                  <span className="time-msg">{user.msgTime}</span>
                </div>
              </>
            )
        }
      </Content>
    </Item>
  );
}

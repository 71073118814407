import React, { useState, useEffect } from 'react';
import { AiFillQuestionCircle } from "react-icons/ai";
import { IoIosColorPalette } from "react-icons/io";
import { FiMapPin, FiShare2 } from "react-icons/fi";
import { MdClose } from 'react-icons/md';

import { sessionGet } from '../../../session';
import api from '../../../services/api-geral';

import Swal from 'sweetalert2';

import { Info, Toggles } from './styles';

import PreviewMobile from '../../PreviewMobile';
import Question from '../../Question'

import CInput from '../../../components/ComponentInput';

function Mapa({ setSwitchTab, switchTab, setTabs, refresh, data, idPage, tab, reload }) {
  const [showModal, setShowModal] = useState('');
  const [showHelpColor, setShowHelpColor] = useState(false);
  const [page, setPage] = useState();
  const [flagEndereco, setFlagEndereco] = useState();
  const [flagMapa, setFlagMapa] = useState();
  const [endereco, setEndereco] = useState(() => {
    if (data) {
      return data.endereco;
    } else {
      return "";
    }
  });

  const [mapa, setMapa] = useState(() => {
    if (data) {
      return data.mapa;
    } else {
      return "";
    }
  });

  useEffect(() => {
    if (switchTab !== 'mapa') {
      setTabs(switchTab);
    }
  }, [switchTab]);


  const [showEndereco, setShowEndereco] = useState(false);

  const [showMapa, setShowMapa] = useState(false);

  var variance = 0;

  useEffect(() => {
    if (switchTab !== 'mapa') {
      if (data.mapa !== mapa) {
        variance = 1;
      }

      if (variance === 0) {
        setTabs(switchTab);
      } else {
        Swal.fire({
          title: 'Atenção',
          text: 'Você possui alterações não publicadas, continuar mesmo assim?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Descartar',
          cancelButtonText: 'Não'
        }).then(async (result) => {
          if (result.isConfirmed) {
            setTabs(switchTab);
          } else {
            setSwitchTab('mapa');
          }
        });
      }
    }
  }, [switchTab]);

  useEffect(() => {
    if (flagMapa !== 0) { setShowMapa(true) } else { setShowMapa(false) }
  }, [flagMapa])

  useEffect(() => {
    loadInfos();
  }, []);

  useEffect(() => {
    if (data) {
      setPage(data.id);
    }
  }, [data]);

  const loadInfos = async () => {
    const response = (await api.get('/page?scope=flags')).data;
    if (response !== null) {
      setFlagMapa(response.flagMapa);
    }
  };

  const handleUpdateFlags = async (flag) => {
    if (!handleInput(mapa)) {
      Swal.fire({
        title: 'Entrada inválida.',
        text: "Antes de ativar essa funcionalidade é necessário preencher o campo correspondente com um iFrame do Google Maps. Saiba mais clicando no simbolo de interrogação ou acesse a central de ajuda no canto inferior esquerdo.",
        icon: 'warning',
        showConfirmButton: true,
      });
    } else {
      setFlagMapa(flag);
      await api.put('/page?scope=flags', {
        flagMapa: flag
      });
      updateMap();
      setMapa(mapa + " ")
      Swal.fire({
        icon: 'success',
        title: 'Alterações salvas',
        showConfirmButton: false,
        timer: 1500
      })
      reload();
    }
  };

  const handleInput = (mapa) => {
    let value = mapa;
    let re = /(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/g;
    if (!re.test(value)) {
      // campo inválido, retorna false para o formulário não ser submetido
      return false;
    }
    return true;
  }

  const updateMap = async () => {
    if (handleInput(mapa)) {
      const response = (await api.put('/page-updatemap', {
        iFrame: mapa
      })).data;
      Swal.fire({
        title: 'Alterações salvas!',
        icon: 'success',
        showConfirmButton: false,
        timer: 2000,
      });
      setMapa(mapa + " ")
      variance = 0;
      reload();
    } else {
      Swal.fire({
        title: 'Entrada inválida.',
        text: "Para preencher esse capo é necessário um iframe do Google Maps. Caso esteja com dúvidas clique no simbolo de interrogação presente nessa página ou acesse a central de ajuda no canto inferior esquerdo.",
        icon: 'warning',
        showConfirmButton: true,
      });
      variance = 0;
      reload();
    }
  }

  useEffect(() => {
    if (refresh !== 0) {
      updateMap();
    }
  }, [refresh]);

  const mostrarModal = (val) => {
    setShowModal(val);
  }

  const closeModal = () => {
    setShowModal('');
  }
  return (
    <div className="wrap-panels chat">
      <Toggles>
        <div className={`toggle-endereco ${showMapa ? 'active' : ''}`}>
          <div className="toggle">
            <div className="title">
              <div className="wrap-icon">
                <FiMapPin />
              </div>
              <span>Google Maps:</span>
              <div className="wrap-icon">
                <AiFillQuestionCircle onClick={() => { mostrarModal('view') }} />
              </div>
              {(showModal !== '') && (
                <Question
                  view={'view'}
                  close={closeModal}
                  content={'maps'}
                />
              )}
            </div>
            <div onClick={() => handleUpdateFlags(!flagMapa, 'mapa')} className={`toggle-funcionalidade ${flagMapa && 'active'}`}>
              <div className="indicator"></div>
            </div>
          </div>
          <div className="mapa-page">
            <CInput classCustom="address" val={mapa} change={e => { setMapa(e); }} type='text' style='radius' />
          </div>
        </div>
      </Toggles>
      <div className="mobile-preview">
        <PreviewMobile idPage={data.id} tab='chat' hasMap={flagMapa} map={mapa} />
      </div>
    </div>
  );
}

export default Mapa;
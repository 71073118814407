import styled from 'styled-components';

import colors from '../../presets/colors';
import effects from '../../presets/effects';

import hexToRgb from '../../util/hexToRgb';

export const Content = styled.div`
    padding: 0 20px;
    margin-left: 40px;
    padding-right: 0;
    display: flex;
    align-items: center;
    position: relative;

    @media(max-width: 1400px) {
      height: 550px;
      margin-left: 0;
    }

    @media (max-width: 700px) {
      margin-top: 20px;
    }

    img {
      height: 600px;

      @media (max-width: 1400px) {
        height: 550px;
      } 
    }

    .background {
      height: 582px;
      width: 268px;
      position: absolute;
      bottom: 9px;
      left: 35px;
      border-radius: 29px;
      background-image: url(${({ Background }) => Background});
      background-size: cover;
      background-position: center;
      background-color: ${({ Cor6 }) => Cor6};

      @media (max-width: 1400px) {
        height: 534px;
        width: 247px;
        left: 33px;
        bottom: 8px;
      }
    }

    .preview {
      height: 582px;
      width: 268px;
      position: absolute;
      bottom: 9px;
      left: 35px;
      border-radius: 29px;
      background-image: ${({ filter, hasImg, Cor4 }) => (filter === true && hasImg !== '') ? `radial-gradient(circle, rgba(
        ${hexToRgb(Cor4).r}, 
        ${hexToRgb(Cor4).g}, 
        ${hexToRgb(Cor4).b}, 0.9), rgba(0, 0, 0, 0.9))` : ''};

      @media (max-width: 1400px) {
        height: 534px;
        width: 247px;
        left: 33px;
        bottom: 8px;
      }

      .content-preview {
        height: 100%;
        padding: 60px 20px;
        padding-top: 20px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 0;
        }
        
        &::-webkit-scrollbar-thumb {
          background-color: transparent;
        }

        @media (max-width: 1400px) {
          padding: 15px 20px;
        }

        .logo-preview {
          display: flex;
          place-content: center;
          margin-bottom: 20px;

          @media(max-width: 1400px) {
            margin-bottom: 10px;
          }

          .image-border-preview {
            width: 70px;
            height: 70px;
            border-radius: 50%;

            .image-holder-preview {
              height: 70px;
              width: 70px;
              display: flex;
              align-items: center;
              place-content: center;
              background-color: white;
              border-radius: 50%;
              
              svg {
                font-size: 32px;
                color: ${({ Cor6 }) => Cor6};
              }
            }

            img {
              height: 70px;
              width: 70px;
              border-radius: 50%;
              object-position: center;
              object-fit: cover;
              border: ${({ borda, Cor5 }) => borda ? `solid 3px ${Cor5}` : ''}
            }
          }
        }

        .info-preview {
          display: flex;
          flex-direction: column;
          align-items: center;
          place-content: center;
          overflow: hidden;

          span {
            margin-bottom: 5px;
            color: ${({ Cor9 }) => Cor9};
            font-size: 14px;
            font-weight: bold;
            text-align: center;
          }

          p {
            margin-bottom: 15px;
            color: ${({ Cor9 }) => Cor9};
            font-size: 10px;
            text-align: center;
            white-space: pre-wrap;
          }
        }

        .links-preview {
          margin-bottom: 50px;
          padding: 1px;
          padding-right: 3px;
          justify-content: space-evenly;
          
          div {
            height: 30px;
            width: 100%;
            background-color: ${({ Cor3 }) => Cor3};
            border-radius: 5px;
            display: flex;
            align-items: center;
            place-content: center;
            margin-bottom: 5px;

            @media (max-width: 1400px) {
              height: 25px;
            }
            
            span {
              font-size: 10px;
              color: ${({ Cor2 }) => Cor2};
            }

            img {
            float: left;
            width: 25px;
            padding: 0px;
            height: 25px;
            padding: 5px;
            box-sizing: border-box;
            border-radius: 5px;
            mix-blend-mode: normal;
            }
            .desc-item {
              margin: 0 auto;

              .font-link {
                margin-left: -25px;
              }
            }
          }

          .link-fale-conosco {
            margin-top: 10px;
            background-color: ${({ Cor7 }) => Cor7};
            cursor: pointer;

            span {
              color: ${({ Cor10 }) => Cor10};
            }
          }

          .mapa-preview {
            margin-top: 10px;            
            display: block;
            border: 5px;
            box-sizing: border-box;
            border-radius: 8px;
            border-color: white;
            box-shadow: white;
            iframe {
              border-radius: 8px;
              border-color: white;
              box-shadow: white;
              height: 90px;
              width: 100%;
            }

            span {
              color: ${({ Cor10 }) => Cor10};
            }
          }

          .mapa-icon-preview {
            margin-top: 10px;            
            display: block;
            background-color: transparent;


            span {
              color: ${({ Cor10 }) => Cor10};
            }

            .mapa-icon {
              position: absolute;
              width: 35px;
              height: 35px;
              right: 12px;
              bottom: 48px;
              display: flex;
              align-items: center;
              place-content: center;
              border-radius: 50%;
              background-color: ${({ Cor1 }) => Cor1};
              color: ${({ Cor10 }) => Cor10};
              z-index: 10;
              
              svg {
                font-size: 20px;
              }
            }
          }
        }

        .footer {
          width: 100%;
          padding: 10px 30px;
          position: absolute;
          bottom: 0;
          left: 0;
          border-bottom-right-radius: 25px;
          border-bottom-left-radius: 25px;
          background-color: ${({ Cor3 }) => Cor3};

          .wpp-preview {
            text-align: center;
            color: ${({ Cor2 }) => Cor2};
            font-size: 11px;
            margin-bottom: 5px;
          }

          .redes-preview {
            width: 100%;
            display: flex;
            place-content: center;

            svg {
              margin: 0 5px;
              font-size: 18px;
              color: ${({ Cor8 }) => Cor8};
            }
          }
        }

        .chat-preview {
          position: absolute;
          width: 35px;
          height: 35px;
          right: 12px;
          bottom: 12px;
          display: flex;
          align-items: center;
          place-content: center;
          border-radius: 50%;
          background-color: ${({ Cor1 }) => Cor1};;
          color: ${({ Cor10 }) => Cor10};
          z-index: 10;
          
          svg {
            font-size: 20px;
          }
        }

        .formulario-contato {
          position: absolute;
          padding: 5px 10px;
          right: 84px;
          bottom: 125px;
          display: flex;
          align-items: center;
          place-content: center;
          border-radius: 12px;
          background-color: ${({ Cor7 }) => Cor7};;
          color: ${({ Cor10 }) => Cor10};

          @media (max-width: 1400px) {
            right: 70px;
            bottom: 115px;
          }

          svg {
            margin-right: 5px;
          }

          span {
            font-size: 12px;
          }
        }
      }
    }
`;

export const Modal = styled.div`
    height: 585px !important;
    width: 269px !important;
    position: absolute !important;
    border-radius: 29px !important;
    bottom: 9px !important;
    margin-left: 3px;
    background-color: rgba(0, 0, 0, 0.6) !important;
    z-index: 10000;
    top: 0;
    padding: 0 20px !important;
    display: flex !important;
    align-items: center ;

    @media(max-width: 1400px) {
      top: 0 !important;
      height: 535px !important;
      width: 250px !important;
    }
`;

export const Container = styled.div`
  background-color: ${({ Cor12 }) => Cor12} !important;
  flex-direction: column !important;
  width: 90% !important;
  height: fit-content !important;
  overflow-y: scroll !important;
  position: absolute !important;
  box-shadow: red;
  border-radius: 25px !important;
  border-radius: 25px;
  z-index: 1;
  padding: 25px 0px 0px 0px !important;
  align-items: flex-start !important;

    h2 {
      color: ${({ Cor11 }) => Cor11} !important;
      display: inline;
      font-size: 18px;
      padding: 0px 17px;
      border: 0;
      left: 15px;
      font-weight: bold;
    }

    p {
      color: ${({ Cor11 }) => Cor11} !important;
      border: 0;
      display: flex;
      padding: 5px 17px;
      font-size: 12px;
    }

  ::-webkit-scrollbar {
    width: 0px;
  }

  button.closeModal {
    background-color: transparent;
    border: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 20px;
    right: 20px;
    font-size: 20px;
    color: red;
    cursor: pointer;
  }

  form {
    padding: 15px;

    @media (max-width: 1024px) {
      padding: 0;
    }

    select {
      padding-left: 7px;
      margin-bottom: 10px;
      width: 100%;
      height: 40px;
      border: solid 2px #bbe5ff;
      border-radius: 5px;
      font-size: 12px;

      option {
        background-color: white;
      }

      &:focus {
        background-color: #bbe5ff;
      }
    }

    input {
      border: 2px solid #bbe5ff;
      height: 30px;
      width: 100%;
      border-radius: 5px;
      padding: 0 10px;
      font-size: 12px;
      margin-bottom: 10px;
      font-weight: 200;

      /* Remove as setinhas do input number nos navegadores Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Remove as setinhas do input number no navegador Firefox */
      &[type=number] {
        -moz-appearance: textfield;
      }
    }

    textarea {
      border: 2px solid #bbe5ff;
      border-radius: 5px;
      width: 100%;
    }

    .wrap-buttons {
      width: 100%;
      display: flex;   
      justify-content: flex-end !important;
      background-color: transparent !important;

      button { 
        background-color: ${({ Cor13 }) => Cor13} !important;
        width: fit-content;
        font-size: 12px;
        margin: 15px 0px 10px 25px;
        height: 40px;
        border: 0;
        border-radius: 20px;
        padding: 0 20px;

        cursor: pointer;
      }

      button:disabled {
      text-decoration: none;
      color: ${({ Cor9 }) => Cor9};
      opacity: 1.0 !important;
      pointer-events: none !important;
      }
    }
  }

`;
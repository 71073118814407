import React, { useEffect, useState, useRef, useCallback } from 'react';
import Dropzone from 'react-dropzone';
import ScrollToBottom, {useScrollToTop} from 'react-scroll-to-bottom';
import { RiMoreLine, RiCloseLine, RiArrowLeftLine, RiImageLine, RiRefreshLine, RiAttachmentLine, RiFile2Fill } from 'react-icons/ri';
import { MdSend, MdPerson } from 'react-icons/md';
import { FaRedo } from "react-icons/fa";
import Compressor from 'compressorjs';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import Swal from 'sweetalert2';
import { setIdWithCripto } from '../../util/formatParamsUrl';

import emojis from '../../util/emojis';
import log from '../../util/log';
import getFiles from '../../util/getFiles';

import { sessionGet } from '../../session';

import node from '../../services/api-geral';
import socket from '../../services/socket';
import apiUpload from '../../services/api-upload';

import MensagemChat from '../../components/MensagemChat'
import CentralMidia from '../../components/CentralMidia';
import ConversaHistrico from '../ConversaHistorico';

import ImgChat from '../../assets/images/chat.svg';

import CButton from '../ComponentButton';
import { Wrap, ImgAndStatus, Form, Blocked, MenuConversa, DropzoneContainer, WrapCtaPagination, LoadingWrap, ReplyMessageInput, InputContainer } from './styles';
import { RepliedMessage } from '../RepliedMessage';

export default function Conversa({ usuario, setDisplay, setInvDisplay }) {

    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');
    const [idUser, setIdUser] = useState('');
    const [showMenuChat, setShowMenuChat] = useState(false);
    const [midiaModal, setMidiaModal] = useState(false);
    const [historicoModal, setHistoricoModal] = useState(false);
    const [draggin] = useState('');
    const [idUltimaMensagem, setIdUltimaMensagem] = useState(null);
    const [regex] = useState(/(<([^>]+)>)/ig);
    const [ckEditor, SetCkEditor] = useState('');

    const [pathFile, setPathFile] = useState('');
    const [idFile, setIdFile] = useState(null);

    const [pathImg, setPathImg] = useState('');
    const [idImg, setIdImg] = useState(null);
    const [hasImg, setHasImg] = useState(false);
    const [fullImg, setFullImg] = useState(false);
    const [fullImgPath, setFullImgPath] = useState('');
    const [imgExterna, setImgExterna] = useState('');

    const [page, setPage] = useState(0);
    const [countMsgs, setCountMsgs] = useState(0);

    const [typeMsg, setTypeMsg] = useState('msg');
    const [loadPagination, setLoadPagination] = useState(false);
    const inputRef = useRef(null);
    const [legenda, setLegenda] = useState();

    const [fileType, setFileType] = useState('');
    const [fileName, setFileName] = useState('');
    const [replyMessage, setReplyMessage] = useState(false)

    const previewRef = useRef(null);
    const [loading, setLoading] = useState(false);


    const [idMessageHist, setIdMessageHist] = useState(null)
    const messageRef = useRef()


    const messageFocus = () => {
        
        if (messageRef.current) {

            messageRef.current.scrollIntoView({ block: "center", behavior: "smooth" })
            messageRef.current.classList.add('message-blink')
            
            
                setTimeout(() => { 
                    if(messageRef.current) {
 
                        messageRef.current.classList.remove('message-blink') 
                    }

                }, 3000) 
            

        }
    }

    const markRead = async () => {
        await node.put(`/markread/${usuario.id}`, {
            method: 'chat'
        });
    }

    const readMsg = async () => {
        let usuarioTo = usuario.id;

        await node.put(`/notifications/all?local=chat&id=${usuarioTo}`);

        const mensagens = (await node.get(`/msgs/${usuarioTo}/${page}`)).data;
    }

    const closeMidia = () => {
        midiaModal ? setMidiaModal(false) : setMidiaModal(true);
        setShowMenuChat(false)
    };

    const closeHistorico = () => {
        historicoModal ? setHistoricoModal(false) : setHistoricoModal(true);
        setShowMenuChat(false)
    };

    const getMessagesHist = useCallback(async (cpage, cuser = null, usuario, idMessage) => {
        setLoadPagination(true);
        setPage(cpage);
        let usuarioTo = usuario;

        setIdMessageHist(idMessage)

        if (!usuarioTo) {
            return
        }

        const mensagens = (await node.get(`/msgs/${usuarioTo}/${cpage}/${idMessage}`)).data;
    
        setCountMsgs(mensagens.countMsgs);
        setMessages(mensagens.msgs);

        let idMsgAux = 0;
        let ultimaMsgId = 0;
        mensagens.msgs.map((item) => {
            if (item.id > idMsgAux) {
                ultimaMsgId = item.id;
            }
            else {
                idMsgAux = item.id;
            }
        });

        setIdUltimaMensagem(ultimaMsgId);
        setLoadPagination(false);

        setTimeout(messageFocus, 300)

    }, []);

    const getMessages = async (cpage, cuser = null) => {
        setLoadPagination(true);
        setPage(cpage);
        let usuarioTo = usuario.id;

        const mensagens = (await node.get(`/msgs/${usuarioTo}/${cpage}?offset=${null}`)).data;

        setCountMsgs(mensagens.countMsgs);
        setMessages(mensagens.msgs);

        
        if (page == 0) {
            setMessages([]);
            setMessages(mensagens.msgs);
        }

        if (cpage > 0) {
            setMessages([...messages, ...mensagens.msgs]);
        }

        let idMsgAux = 0;
        let ultimaMsgId = 0;
        mensagens.msgs.map((item) => {
            if (item.id > idMsgAux) {
                ultimaMsgId = item.id;
            }
            else {
                idMsgAux = item.id;
            }
        });
        setIdUltimaMensagem(ultimaMsgId);

        setLoadPagination(false);
    }

    const replyMessageChat = (index) => {
        setReplyMessage(messages[index]);

        if (ckEditor) ckEditor.editing.view.focus();
    }

    const copyMessageChat = (index) => {
        const msg = messages[index].message;

        navigator.clipboard.writeText(msg.replace(/<\/?p>/g, ''))
    }

    const getMoreMessagesPagination = async (cpage, offset) => {
        setLoadPagination(true);


        setPage(cpage);

        let usuarioTo = usuario.id;


        const mensagens = (await node.get(`/msgs/${usuarioTo}/${cpage}?offset=${offset}`)).data;

        setMessages([...messages, ...mensagens.msgs]);


        let idMsgAux = 0;
        let ultimaMsgId = 0;
        mensagens.msgs.map((item) => {
            if (item.id > idMsgAux) {
                ultimaMsgId = item.id;
            }
            else {
                idMsgAux = item.id;
            }
        });

        setIdUltimaMensagem(ultimaMsgId);
        setLoadPagination(false);
    } 

    const relacionarImg = async id_mensagem => {
        const response = (await node.put(`/upload/${fileType === 'file' ? idFile : idImg}`, {
            id_mensagem,
        }));
    };


    const handleSubmit = async e => {
        if (e != undefined) {
            e.preventDefault();
        }

        var elmntToView = document.getElementById("scroll-to");
        if (elmntToView) {
            elmntToView.scrollIntoView();
        }

        if (message !== '' || hasImg == true) {
            let usuarioTo = usuario.id;

            if (fileType === 'file') {
                var dados = {
                    'message': message,
                    'id_to': usuarioTo,
                    'id_from': sessionGet('idUser'),
                    'id_file': idFile,
                    'path_img': pathFile,
                    'name_file': fileName,
                    'type_file': fileType
                };
            } else {
                var dados = {
                    'message': message,
                    'id_to': usuarioTo,
                    'id_from': sessionGet('idUser'),
                    'id_file': idImg,
                    'path_img': pathImg,
                    'name_file': fileName,
                    'type_file': fileType,
                    'id_replied_message': replyMessage.id ? replyMessage.id : null
                };
            }

            setIdImg(null);
            setIdFile(null);

            const responseMensagem = await node.post('/msgs', dados);

            log('realizou um envio de mensagem no chat interno.')

            if (typeMsg === 'img') {
                log('realizou um upload de um arquivo no chat interno.')
                relacionarImg(responseMensagem.data.id);
                setTypeMsg('msg');
            }

            setMessages([responseMensagem.data, ...messages]);

            setIdUltimaMensagem(responseMensagem.data.id);

            //notify(`Você recebeu uma mensagem de ${sessionGet('nomeUser')}`, false, usuario.id, "chat");

            setMessage('');
            setPathImg('');
            setPathFile('');
            setReplyMessage(false)
            setFileType('');
            setFileName('');
            setHasImg(false);
        }
    };


    const fileInput = () => {
        document.getElementById('fileinput').click();
    }

    const showPaginationCta = () => messages.length < countMsgs

    const uploadFiles = async files => {

        if (files.length > 0) {

            if (files[0].size < 25000000) {
                var type = files[0].type;
                type = type.split('/');
                type = type[0];

                if (type === 'image') {
                    var text = message.replace(regex, '');
                    text = text.toString();
                    setLegenda(text);

                    const dados = new FormData();

                    dados.append('modulo', 'chat');

                    setFileType('image');

                    if (files != '') {
                        setHasImg(true);
                        setFileName(files[0].name);
                        new Compressor(files[0], {
                            quality: 0.4,
                            convertSize: 0,
                            async success(result) {
                                dados.append('file', result, result.name);
                                const response = (await apiUpload.post('/upload', dados)).data;
                                setPathImg(response.path);
                                // document.getElementById('input-img').focus();
                                setIdImg(response.id);
                                setLoading(false);
                            }
                        });
                    }
                } else {
                    if (files !== '') {
                        setHasImg(true);
                        const dados = new FormData();
                        dados.append('modulo', 'file-chat');
                        setFileType('file');
                        dados.append('file', files[0], files[0].name);
                        setFileName(files[0].name);
                        const response = (await apiUpload.post('/upload', dados)).data;
                        setIdFile(response.id);
                        setPathFile(response.path);
                        setLoading(false);
                    }
                }
            } else {
                Swal.fire({
                    title: 'Erro!',
                    text: 'O arquivo ultrapassa o limite máximo de 25mb.',
                    icon: 'error',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'OK'
                })
            }

        }
    };

    const deleteImage = async (id) => {
        const response = (await apiUpload.delete(`/upload/${id}/${pathImg}`)).data;

        setHasImg(false);
        setIdImg('');
        setPathImg('');
        setFileType('');
        setFileName('');
        ckEditor.editing.view.focus();
    };

    const deleteFile = async (id) => {
        const response = (await apiUpload.delete(`/upload/${id}/${pathFile}`)).data;

        setHasImg(false);
        setIdFile('');
        setPathFile('');
        setFileType('');
        setFileName('');
        ckEditor.editing.view.focus();
    };

    useEffect(() => {
        if (ckEditor) {
            ckEditor.editing.view.focus();
        }
    }, [usuario])

    const handlePasteUpload = async (imageData) => {
        if (previewRef.current === null) {
            setLoading(true);
            uploadFiles(imageData);
        }
    }

    useEffect(() => {

        if (usuario.id > 0) {
            setInvDisplay(true);
        }

        if (usuario.id !== undefined) {
            markRead();
        }

        if (window.innerWidth <= 500 && window.location.href.includes('/conversas/')) {
            setDisplay(false);
        }

        window.addEventListener("beforeunload", () => {
            socket.emit('contatoDisconnectedClient');
        }, false);

    }, [idUser]);

    useEffect(() => {
        socket.on('msg', msg_socket => {
            let idUsuario = usuario.id;

            if (msg_socket.id_from === idUsuario && msg_socket.id_to === sessionGet('idUser')) {
                setPage(0);
                setMessages([msg_socket, ...messages]);
                readMsg();
            }
        });

        socket.on('delete_message_interno', msg_socket => {
            if (msg_socket.id_from === sessionGet('idUser') || msg_socket.id_to === sessionGet('idUser')) {
                let msgs = messages.filter(function (obj) {
                    return obj.id !== msg_socket.id;
                });
                setMessages(msgs);
            }
        });

        return () => {
            socket.off('msg');
            socket.off('delete_message_interno');
        }
    }, [messages]);


    useEffect(() => {
        setPage(0);

        if (usuario) {
            if (usuario.id) {
                setIdUser(usuario.id);
                getMessages(0);
                setReplyMessage(false)
            }

            if (inputRef.current) inputRef.current.focus();
        }

    }, [usuario]);
    




    return (
        <Wrap local="interno">
            {(usuario) ? (
                (usuario.name) ? (
                    <>
                        <div className="info-head">
                            <div className="user">
                                <button onClick={() => { setDisplay(true); setInvDisplay(true) }} className="back-button"><RiArrowLeftLine /></button>
                                <ImgAndStatus>
                                    <div className="presence"><span className={(usuario.presence_status === 1) ? ((usuario.statusCustom != null) ? usuario.statusCustom.status_color : 'online') : 'offline'}></span></div>
                                    {(usuario.files) ? <img src={getFiles(usuario.files.path)} /> : (
                                        <>
                                            <MdPerson color="#fff" />
                                        </>
                                    )}
                                </ImgAndStatus>
                                <div className="info">
                                    {<strong><a href={`/perfil?id=${setIdWithCripto('user', usuario.id)}`}>{usuario.name}</a></strong>}
                                    <span>{(usuario.setor) ? usuario.setor.name : ''}</span>
                                </div>
                            </div>

                            <div>
                                <button onClick={() => setShowMenuChat(!showMenuChat)} className="options-conversa">
                                    <RiMoreLine />
                                </button>
                                {(showMenuChat) && (
                                    <MenuConversa>
                                        <button onClick={() => closeHistorico()}>Histórico </button>
                                    </MenuConversa>
                                )}
                            </div>
                        </div>
                        <Dropzone
                            accept="image/*"
                            onDrop={files => { uploadFiles(files) }}
                            noClick={true}>
                            {({ getRootProps, getInputProps, isDragActive, isDragReject, open }) => (
                                <div className="form-msg"{...getRootProps()}>
                                    {(!isDragActive) ? <> <ScrollToBottom initialScrollBehavior='auto' id="lista-msg" className={`lista-mensagem ${draggin}`}>
                                        <div id="scroller" className="scroller">
                                            {(messages.map((v, index) => (
                                                <>
                                                    <MensagemChat
                                                        messageRef={v.id == idMessageHist ? messageRef : null}
                                                        messageIndex={index}
                                                        key={v.id}
                                                        msgs={messages}
                                                        id_message={v.id}
                                                        message={v.message}
                                                        replied_message={v.replied_message}
                                                        id_from={v.id_from}
                                                        name_from={sessionGet('nomeUser')}
                                                        id_to={usuario.id}
                                                        name_to={usuario.name}
                                                        data={v.createdAt}
                                                        path={v.path_img}
                                                        setFullImg={setFullImg}
                                                        setFullImgPath={setFullImgPath}
                                                        setImgExterna={setImgExterna}
                                                        nameFile={v.name_file}
                                                        typeFile={v.type_file}
                                                        replyMessageChat={replyMessageChat}
                                                        copyMessageChat={copyMessageChat}
                                                        getMessagesHist={getMessagesHist}
                                                        setIdMessage={setIdMessageHist}
                                                    />
                                                </>
                                            )))}                                            
                                            {showPaginationCta() && (
                                                <WrapCtaPagination>
                                                    <button  onClick={() => getMoreMessagesPagination(page + 1, messages.length)}>
                                                        {loadPagination && (<RiRefreshLine />)}
                                                        {!loadPagination && 'Carregar mais mensagens'}
                                                    </button>
                                                </WrapCtaPagination>
                                            )}

                                        </div>
                                        <div id='scroll-to'></div>
                                    </ScrollToBottom>

                                        <Form onSubmit={handleSubmit} className={draggin}>
                                            <InputContainer>

                                                {replyMessage ?<RepliedMessage message={replyMessage} setReplyMessage={setReplyMessage} classCustom='InputChat' id_from={replyMessage.id_from} id_to={usuario.id} replied_message={replyMessage.message} name_from={sessionGet('nomeUser')} name_to={usuario.name} /> : null}
                                                <div className="input-group">
                                                    {/* <input ref={inputRef} placeholder="Escreva uma mensagem..." value={message} onChange={e => setMessage(e.target.value)} required /> */}
                                                    <div className="text-input">
                                                        <CKEditor
                                                            className="cke-editor"
                                                            editor={Editor}
                                                            onReady={editor => {
                                                                editor.editing.view.document.on('paste', (evt, data) => {
                                                                    if (data.dataTransfer.files[0]) {
                                                                        const imageData = data.dataTransfer.files;
                                                                        handlePasteUpload(imageData);
                                                                    }
                                                                });
                                                                editor.plugins.get('SpecialCharacters').addItems('Emoji', emojis);
                                                                editor.model.change(writer => {
                                                                    writer.setSelection(writer.createPositionAt(editor.model.document.getRoot(), 'end'));
                                                                });
                                                                editor.editing.view.focus();
                                                                SetCkEditor(editor);
                                                            }}
                                                            config={{
                                                                placeholder: 'Escreva uma mensagem...',
                                                                toolbar: ['SpecialCharacters'],
                                                                removePlugins: ['TextTransformation'],
                                                                mediaEmbed: {
                                                                    previewsInData: true,
                                                                    removeProviders: ['instagram', 'twitter', 'googleMaps', 'flickr', 'facebook', 'youtube']
                                                                },
                                                                link: {
                                                                    addTargetToExternalLinks: {
                                                                        attributes: {
                                                                            target: "_blank",
                                                                            rel: "noopener noreferrer",
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                            data={message}
                                                            onChange={(event, editor) => {
                                                                setMessage(editor.getData());
                                                                editor.editing.view.document.on('keydown', (evt, data) => {
                                                                    if (data.keyCode == 13 && !data.shiftKey) {
                                                                        document.getElementById('submit-button').click();
                                                                        data.stopPropagation();
                                                                        data.preventDefault();
                                                                        evt.stop();
                                                                    }
                                                                }, { priority: 'highest' });
                                                            }}
                                                            />
                                                    </div>
                                                    <div className="btns-form">
                                                        <button type="button" onClick={() => fileInput()}><RiImageLine /><input id="fileinput" type="file" {...getInputProps()} multiple /></button>
                                                        <button onClick={() => { document.getElementById('input-any-file').click(); }}><RiAttachmentLine /></button>
                                                        <input id="input-any-file" type="file" onChange={e => uploadFiles(e.target.files)} />
                                                        <button id="submit-button" type="submit"><MdSend /></button>
                                                    </div>
                                                </div>
                                            </InputContainer>
                                        </Form>
                                    </> : (isDragReject) ?
                                        <DropzoneContainer isDragActive={isDragActive} isDragReject={isDragReject}>
                                            <p className="file-reject">Arquivo não suportado</p>
                                        </DropzoneContainer> :
                                        <DropzoneContainer isDragActive={isDragActive} isDragReject={isDragReject}>
                                            <p>Solte os arquivos aqui</p>
                                        </DropzoneContainer>
                                    }
                                </div>
                            )}
                        </Dropzone>
                        {(fullImg) &&
                            <div className="full-screen-img">
                                <div className="fullscreen-holder">
                                    <div className="open-close-image">
                                        <a href={getFiles(fullImgPath)} target="_blank">Ver original</a>
                                        <button className="btn-delete-img" type="button" onClick={() => setFullImg(false)}><RiCloseLine /></button>
                                    </div>
                                    <img src={(imgExterna === '') ? getFiles(fullImgPath) : imgExterna}></img>
                                </div>
                            </div>
                        }
                        {(loading) &&
                            <form className="send-image-form">
                                <div className="image-preview">
                                    <div className="content-image-preview">
                                        <LoadingWrap><FaRedo className="fa-spin" /><h3>Carregando...</h3></LoadingWrap>
                                    </div>
                                </div>
                            </form>
                        }
                        {(pathImg != '') &&
                            <>
                                <form onSubmit={handleSubmit} className="send-image-form" ref={previewRef}>
                                    <div className="image-preview">
                                        <div className="content-image-preview">
                                            <img className="img-preview" src={getFiles(pathImg)} />
                                            <button className="btn-delete-img" type="button" onClick={() => deleteImage(idImg, pathImg)}><RiCloseLine /></button>
                                            <button onClick={() => setTypeMsg('img')} className="btn-send-img" type="submit"><MdSend /></button>
                                            <input id="input-img" className="legenda-img" ref={inputRef} placeholder="Adicionar uma legenda..." value={legenda} onChange={e => { setLegenda(e.target.value); setMessage(e.target.value); }} />
                                            <hr className="line-legenda" />
                                        </div>
                                    </div>
                                </form>
                            </>
                        }
                        {(pathFile != '') &&
                            <>
                                <form onSubmit={handleSubmit} className="send-image-form" ref={previewRef}>
                                    < div className="image-preview">
                                        <div className="content-image-preview">
                                            <button className="btn-delete-img" type="button" onClick={() => deleteFile(idFile, pathFile)}><RiCloseLine /></button>
                                            <button onClick={() => setTypeMsg('img')} className="btn-send-img" type="submit"><MdSend /></button>
                                            <div className="file-preview">
                                                <RiFile2Fill />
                                                <span>{fileName}</span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </>
                        }
                    </>
                ) : (
                    <Blocked onClick={() => { setDisplay(true) }}>
                        <div className='holder'>
                            <img src={ImgChat} />
                            <h2>Para ler ou enviar uma mensagem selecione um integrante da sua equipe ao lado.</h2>
                        </div>
                    </Blocked>
                )
            ) : (
                <Blocked>
                    <div className='holder'>
                        <h2>O conteúdo a ser visualizado não pertence a esse perfil.</h2>
                        <h2>Deseja desconectar-se e fazer um novo login?</h2>
                        <div className='btnActions'>
                            <CButton cstyle='danger' title='Sim' click={() => window.location.href = '/'} />
                            <CButton cstyle='success' title='Não' click={() => window.location.href = '/conversas'} />
                        </div>
                    </div>
                </Blocked>
            )}
            {(midiaModal) && (
                <CentralMidia idUltimaMensagem={idUltimaMensagem} idRemetente={sessionGet('idUser')} idDestinatario={idUser} modulo={'chat'} isModal={true} closeMidia={() => { closeMidia(); }} />
            )}
            {(historicoModal) && (
                <ConversaHistrico setIdMessage={setIdMessageHist} getMessagesHist={getMessagesHist} isModal={true} closeHistorico={() => { closeHistorico(); }} usuario={usuario} />
            )}
        </Wrap>
    );
}

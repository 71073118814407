import styled from 'styled-components';

import { Link } from 'react-router-dom';

import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Wrap = styled.div`
  height: 100%;
  overflow-y: auto;
`

export const Scroll = styled.div`
  overflow: scroll;
  height: 100%;
  padding-right: 10px;

  &::-webkit-scrollbar {
      width: 4px;
  }

  &::-webkit-scrollbar-track {
      background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
      background: ${colors.border};
      border-radius: ${effects.radius};
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
      background: #555;
  }
`;

export const Title = styled.div`
  width: 100px;
  display: flex;
  place-content: flex-start;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;

  h1 {
    color: #535353;
  }

  .underline-title {
    width: 70px;
    height: 5px;
    background-color: ${colors.primary};
  }
`

export const Container = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: 1440px) {
    flex-direction: column;
    height: 100%;
  }
`;

export const Modulos = styled.div`
  width: 20%;
  margin-left: 20px;

  @media (max-width: 1750px) {
    width: 25%;
  }

  @media (max-width: 1440px) {
    width: 100%;
    margin-left: 0;
  }

  .modulos {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @media (max-width: 1440px) {
      display: flex;
      justify-content: space-evenly;
    }

    @media (max-width: 825px) {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      justify-items: center;
    }

    @media (max-width: 575px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 475px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 360px) {
      grid-template-columns: repeat(2, 1fr);
    }

    button {
      width: 130px;
      height: 130px;
      display: flex;
      flex-direction: column;
      background-color: white;
      border: none;
      border-radius: 20px;
      box-shadow: 0.25rem 0.25rem rgb(0 0 0 / 8%);
      align-items: center;
      place-content: center;
      margin-bottom: 40px;
      color: #535353;
      font-size: 14px;
      transition: all 0.2s;

      @media (max-width: 1440px) {
        width: 130px;
        height: 130px;
        font-size: 12px;
      }

      @media (max-width: 1280px) {
        width: 100px;
        height: 100px;
        font-size: 11px;
        margin-bottom: 20px;
      }

      @media (max-width: 575px) {
        width: 100px;
        height: 100px;
        font-size: 10px;
      }

      &.active {
        color: ${colors.primary};

        svg {
          color: ${colors.primary};
        }
      }

      &:hover {
        background-color: #e6e6e6;
      }

      svg {
        font-size: 50px;
        color: #535353;
        margin-bottom: 10px;

        @media (max-width: 1280px) {
          font-size: 30px;
        }

        @media (max-width: 500px) {
          font-size: 40px;
        }
      }
    }
  }
`;

export const Cards = styled.div`
  width: 80%;
  margin-right: 20px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1750px) {
    width: 75%;
  }

  @media (max-width: 1440px) {
    width: 100%;
    height: 100%;
    padding: 0 20px;
  }
  
  @media (max-width: 825px) {
    padding-top: 20px;
  }

  @media (max-width: 575px) {
    padding: 0 0 75px 0;
  }

  .subtitle {
    color: ${colors.primary};
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 20px;
  }

  ::-webkit-scrollbar {
      width: 4px;
  }

  ::-webkit-scrollbar-track {
      background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
      background: ${colors.border};
      border-radius: ${effects.radius};
  }

  ::-webkit-scrollbar-thumb:hover {
      background: #555;
  }
`;


import styled from 'styled-components';

import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;

  .backdrop {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }
`;

export const Form = styled.form`
  width: 30%;
  height: 40%;
  position: absolute;
  top: 30%;
  left: 35%;
  background-color: white;
  padding: 30px;
  z-index: 1061;
  box-shadow: ${effects.boxshadow};
  border: 2px solid ${colors.primary};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .btnfechar {
    background-color: transparent;
    border: 0;
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
  }

  p {
    font-size: 20px;
    text-align: center;
  }

  input {
    width: 85%;
    height: 50px;
    margin-top: 20px;
    border: 2px solid ${colors.primary};
    padding: 0 20px;
    border-radius: 10px;
  }

  small {
    color: red;
    margin-top: 10px;
    font-size: 13px;
    text-align: center;
  }

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    
    button { margin: 0 10px; box-shadow: ${effects.boxshadow}}

    .btnsubmit {
      padding: 10px 20px;
      color: white;
      background-color: ${colors.primary};
      font-size: 20px;
      margin-top: 25px;
      border: 0;
      border-radius: 10px;
    }

    .btnlimpar{
      padding: 10px 20px;
      color: ${colors.texton};
      background-color: ${colors.secondary};
      font-size: 20px;
      margin-top: 25px;
      border: 0;
      border-radius: 10px;
    }
  }
`;

import styled from 'styled-components';

export const Info = styled.div`
  width: 100%;
  position: relative;
  display: flex;

  .info-modal {
    width: 22px;
    height: 22px;
    position: absolute;
    right: 5px;
    top: 10px;
    
    svg {
      font-size: 22px;
      color: #5E5E5E;
    }
  }
`;

export const Publish = styled.div`
  width: 100%;
  margin-bottom: 45px;
  padding: 0 20px;
  display: flex;
  place-content: end;

  button {
    background: #67bb49;
    border: none;
    color: white;
    border-radius: 10px;
    padding: 5px 12px;
  }
`
import styled from 'styled-components';

import colors from '../../presets/colors';

export const Wrap = styled.div`
        position: relative;

        .box-all {
            display: flex;
            margin: 5px 0px;
            align-items: center;

            span {
                margin-left: 10px;
                color: ${colors.primary};
                font-weight: bold;
            }
        }
        
        .box-setores {
            display: flex;
            align-items: center;
            color: ${colors.primary};
            font-weight: bold;

            span {
                margin-left: 10px;
            }
        }

        .box-usuarios {
            display: flex;
            margin: 5px 28px;
            align-items: center;

            span {
                margin-left: 10px;
            }
        }

        .btn-close {
            @media (min-width: 751px) {
                display: none;
            }
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 50%;
            background-color: ${colors.primary};
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30px;
            width: 30px;

            svg {
                font-size: 20px;
                color: white;
            }

            @media (max-width: 750px) {
                margin-right: 10px;
            }
        }
`;
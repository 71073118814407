import React from 'react';

import { FaAppleAlt } from "react-icons/fa";

import { WrapCustomSelect, LabelCustomSelect, CustomSelect } from './styles';

export default function CSelect({ label, val, change, items, arrayFixo, cstyle, usingTable, customCase, customSelectedValue, ...rest }) {

  return (
    <WrapCustomSelect className={cstyle}>
      <LabelCustomSelect>{label}</LabelCustomSelect>
      <CustomSelect value={val} onChange={e => change(e.target.value)} {...rest}>
        {

          (usingTable) ? (<option>Ordenar por</option>) : ((customCase) ? (<option>{customSelectedValue}</option>) : (<option disabled></option>))
        }

        {(customCase) ?
          items.map(op => (
            <option value={op.id}>{op.status}</option>
          )) :
          ((arrayFixo) ?
            items.map(op => (
              <option value={op.name}>{op.nameTitle}</option>
            )) :
            items.map(op => (
              <option value={op.id}>{op.name}</option>
            )))
        }

      </CustomSelect>
    </WrapCustomSelect>
  );
}

export default [
    { title: 'Feliz', character: '😊' },
    { title: 'Sorrindo 1', character: '😀' },
    { title: 'Sorrindo 2', character: '😁' },
    { title: 'Rindo', character: '😂' },
    { title: 'Piscando', character: '😉' },
    { title: 'Óculos', character: '😎' },
    { title: 'Apaixonado', character: '😍' },
    { title: 'Surpreso', character: '😮' },
    { title: 'Triste', character: '🙁' },
    { title: 'Bravo', character: '😠' },
    { title: 'Coração', character: '❤️' },
    { title: 'Notebook', character: '💻' },
    { title: 'Beleza', character: '👍' },
    { title: 'Aplausos', character: '👏' },
    { title: 'Apreciar', character: '🙏' },
    { title: 'Festejar', character: '🎉' },
];
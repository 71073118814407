import React, { useEffect, useState, useRef } from 'react';
import api from '../../services/api-geral';
import apiUpload from '../../services/api-upload';
import Default from '../../assets/default.png';
import Compressor from 'compressorjs';
import Swal from 'sweetalert2';
import { TiUserAddOutline } from 'react-icons/ti';

import log from '../../util/log';

import { sessionGet } from '../../session';

import { format } from 'date-fns';
import getFiles from '../../util/getFiles';
import { FaRedo } from "react-icons/fa";
import { setIdWithCripto, getIdWithoutCripto } from '../../util/formatParamsUrl';

import { RiCloseLine, RiImageLine } from 'react-icons/ri';
import { AiOutlineSend } from 'react-icons/ai';
import { Content, DropzoneContainer, LoadingWrap } from './styles';

import NEditorTexto from '../NEditorTexto';
import ModalMentionsFeed from '../ModalMentionsFeed';
import ListMarkedUsers from '../ListMarkedUsers';
import FormMentionsFeed from '../FormMentionsFeed';
import Dropzone from 'react-dropzone';

function CommentPost({ comment = null, id_post, reload, type, uploadComment, setUploadComment, setHasChangesModal, setImageId, setImagePath }) {
  const [content, setContent] = useState('');
  const [ckEditor, SetCkEditor] = useState('');

  const [pathImg, setPathImg] = useState('');
  const [idImg, setIdImg] = useState('');

  const [users, setUsers] = useState([]);
  const [markedUsers, setMarkedUsers] = useState([]);
  const [showMentions, setShowMentions] = useState(false);
  const [showModalMarcacoes, setShowModalMarcacoes] = useState(false);
  const [nameSearch, setNameSearch] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const [hasChangesModal, setHasChangesOnModal] = useState(false);

  const [mentions, setMentions] = useState([]);
  const [classBtn, setClassBtn] = useState('complementaryButtons');
  const [width, setWidth] = useState(window.innerWidth);

  const previewRef = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => { setPathImg(''); }, []);

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (uploadComment) {
      document.getElementById('fileinput').click();
      setUploadComment(false);
    }
  }, [uploadComment]);

  useEffect(() => {
    tratarString();
  }, [content, isSearch]);

  useEffect(() => {
    if ((content !== '' && content !== null) || pathImg !== '' || markedUsers.length !== 0) {
      setHasChangesModal(true);
      setHasChangesOnModal(true);

      if (pathImg) {
        setImageId(idImg);
        setImagePath(pathImg);
      }
    } else {
      setHasChangesOnModal(false);
    }
  }, [content, idImg, pathImg, markedUsers]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  let usuarios = [];

  const tratarString = () => {
      var split = content.split(' ');
      let filtred = split.filter(item => {
        return item.includes('data-mention-merx=\"@');
      })
      let array = [];
      filtred.map(mention => {
        let m = mention.split('"');
        m = m[1].replace('@', '');
        m = m.split('/id:');
        array = [...array, { name: m[0], id: Number(m[1]), modal: false }]
      })

      setMentions(array);
  }

  const getUsers = async () => {

    const response = (await api.get('/users?usuarios')).data;
    response.map(user => {
      usuarios.push({
        id: '@' + user.name.split(' ').join(' '), //Substitui o espaço em branco por um caractere invisível
        userId: user.id,
        name: user.name,
        link: `https://painel.merx.app.br/perfil?id=${setIdWithCripto('user', user.id)}`
      })
    })
    setUsers(usuarios);
  };

  const createComment = async e => {
    e.preventDefault();
    if (content === '' && idImg === '') {
      return;
    }

    const response = (await api.post('/comments', { content, id_post, pathImg })).data;

    log('comentou em um post no feed');

    if (idImg !== '') {
      log('realizou um upload de imagem no feed.')
      await relacionarImg(response.id);
    }

    if (mentions.length > 0 || markedUsers.length > 0) {
      MarkUsers(response.id);
    }

    Swal.fire({
      title: 'Sucesso!',
      text: 'Comentário criado com sucesso!',
      icon: 'success',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
      timer: 2500,
      willClose: () => {
        // setShowModalStatus(false);
        setIdImg('');
        setPathImg('');
        setMarkedUsers([]);
        setHasChangesModal(false);
      }
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        // setShowModalStatus(false);
        setIdImg('');
        setPathImg('');
        setMarkedUsers([]);
        setHasChangesModal(false);
      }
    });

    reload();
    setContent('');
  }

  const relacionarImg = async (id_comment) => {
    const response = (await api.put(`/upload/${idImg}`, {
      id_comment,
      id_post,
    }));
  };

  const upload = async files => {
    if (files) {
      const dados = new FormData();

      dados.append('modulo', 'comments_feed');

      new Compressor(files[0], {
        quality: 0.6,
        convertSize: 0,
        async success(result) {
          dados.append('file', result, result.name);
          const response = (await apiUpload.post('/upload', dados)).data;

          setPathImg(response.path);
          // ckEditor.editing.view.focus();
          setIdImg(response.id);
          setLoading(false);
        }
      });
    }
  };

  const deleteImage = async (id) => {
    const response = (await apiUpload.delete(`/upload/${id}/${pathImg}`)).data;
    const sResponse = (await api.put(`/comments-delete-image/${comment.id}`)).data;

    setIdImg('');
    setPathImg('');

    if (previewRef) { previewRef.current = null; }

    const el = document.getElementById('fileinput');
    if (el) {
      el.value = null;
    }

    setIdImg('');
    setPathImg('');
    ckEditor.editing.view.focus();
  };

  const MarkUsers = async id_comment => {
    let newArrayMarkUsers = [];

    markedUsers.map(mark => {
      newArrayMarkUsers.push({
        id: mark.id,
        name: mark.name,
        modal: true
      })
    })

    mentions.map(mark => {
      newArrayMarkUsers.push({
        id: mark.id,
        name: mark.name,
        modal: false
      })
    })

    const response = (await api.post('/marks', {
      id_comment,
      users: newArrayMarkUsers,
      type: 'comment',
      id_post
    })).data;
  }

  let mark = false;

  const trocarNomeMarcacao = (id, nome) => {
    mark = true;
    const str = content.replace('<p>', '').replace('</p>', '');
    const subStr = str.split('@')[0];
    const newStr = subStr.replace(subStr, `${subStr} <a href='/conversas/${id}'>${nome}</a>&nbsp;`);
    setIsSearch(false);
    setContent(newStr);
  }

  //FUNÇÃO DO PRÓPRIO CKEDITOR PARA LISTAR OS USUÁRIOS NO MODAL DE MENÇÃO
  function getFeedItems(queryText) {
    return new Promise(resolve => {
      setTimeout(() => {
        const itemsToDisplay = usuarios
          .filter(isItemMatching)
          .slice(0, usuarios.length);
        resolve(itemsToDisplay);
      }, 100);
    });

    function isItemMatching(item) {
      const searchString = queryText.toLowerCase();
      return (
        item.name.toLowerCase().includes(searchString) ||
        item.id.toLowerCase().includes(searchString)
      );
    }
  }

  const handlePasteUpload = async (imageData) => {
    if (previewRef.current === null) {
      setLoading(true);
      upload(imageData);
    }
  }

  return (
    <>
      {showMentions && <FormMentionsFeed markedUsers={markedUsers} setMarkedUsers={setMarkedUsers} setShowMentions={setShowMentions} />}
      <Content>
        <div className="body">
          <form onSubmit={createComment}>
            <div className="content-form">
              <Dropzone accept="image/*" onDrop={files => upload(files)} noClick={true}>
                {({ getRootProps, getInputProps, isDragActive, isDragReject, open }) => (
                  <section>
                    <div {...getRootProps()}>
                      {(!isDragActive) ?
                        <>
                          <NEditorTexto
                            id='editorTexto'
                            content={content}
                            setContent={setContent}
                            type={type}
                            SetCkEditor={SetCkEditor}
                            getFeedItems={getFeedItems}
                            handlePasteUpload={handlePasteUpload}
                          />
                          <button id="submit-button" className="send" type='send'>
                            <AiOutlineSend />
                          </button>
                        </>
                        : (isDragReject) ?
                          <DropzoneContainer isDragActive={isDragActive} isDragReject={isDragReject}>
                            <p className="file-reject">Arquivo não suportado</p>
                          </DropzoneContainer> :
                          <DropzoneContainer isDragActive={isDragActive} isDragReject={isDragReject}>
                            <p>Solte os arquivos aqui</p>
                          </DropzoneContainer>
                      }
                      <input id="fileinput" type="file" {...getInputProps()} multiple />
                    </div>
                  </section>
                )}
              </Dropzone>
              {width < 800 ?
                        <>
                        <div className="img-marker-wrapper-little">
                            {(pathImg == '' || pathImg == null) && (
                            <button className="complementaryButtons-little left" onClick={() => document.getElementById('fileinput').click()} type="button" id='botao'>
                                <div className="icon-little left">
                                    <RiImageLine />
                                </div>
                                <span>Anexar</span>
                            </button>
                            )}
                            <button className="complementaryButtons-little right" onClick={() => setShowMentions(true)} type="button">
                                <div className="icon-little right">
                                    <TiUserAddOutline />
                                </div>
                              <span>Marcações</span>
                            </button>
                        </div>
                        </>
                        :
                        <div div className="img-marker-wrapper">
                        {(pathImg == '' || pathImg == null) && (
                        <button className="complementaryButtons left" onClick={() => document.getElementById('fileinput').click()} type="button" id='botao'>
                            <div className="icon left">
                                <RiImageLine />
                            </div>
                            <span>Adicionar imagem</span>
                        </button>
                        )}
                        <button className="complementaryButtons right" onClick={() => setShowMentions(true)} type="button">
                            <div className="icon right">
                                <TiUserAddOutline />
                            </div>
                          <span>Incluir marcações</span>
                        </button>
                      </div>
                    } 
                {/* {(pathImg == '' || pathImg == null) && (
                  <button className="complementaryButtons left" onClick={() => document.getElementById('fileinput').click()} type="button" id='botao'>
                    <div className="icon left">
                      <RiImageLine />
                    </div>
                    <span>Adicionar imagem</span>
                  </button>
                )}
                <button className="complementaryButtons right" onClick={() => setShowMentions(true)} type="button">
                      <div className="icon right">
                        <TiUserAddOutline />
                      </div>
                      <span>Incluir marcações</span>
                </button> */}
             

              {showModalMarcacoes && <ModalMentionsFeed markedUsers={markedUsers} setMarkedUsers={setMarkedUsers} search={nameSearch} newSearch={trocarNomeMarcacao} setShowModalMarcacoes={setShowModalMarcacoes} />}

      
              {(loading) &&
                <LoadingWrap><FaRedo className="fa-spin" /><h3>Carregando...</h3></LoadingWrap>
              }
      
              {(pathImg != '' && pathImg != null) &&
                <div className="image-preview" ref={previewRef}>
                  <div className="content-image-preview">
                    <img className="img-preview" src={getFiles(pathImg)} />
                    <button className="btn-delete-img" type="button" onClick={() => deleteImage(idImg, comment.path_img)}><RiCloseLine /></button>
                  </div>
                </div>
              }
              {mentions.length > 0 &&
              <div className="container-ListMarkedUsers">
                   <ListMarkedUsers mentions={mentions} markedUsers={markedUsers} setMarkedUsers={setMarkedUsers} />
              </div>
              }
            </div>
          </form>
        </div>
      </Content >
    </>
  );
}

export default CommentPost;
import React, { useState, useEffect } from 'react';
import { MdAdd } from 'react-icons/md';
import Compressor from 'compressorjs';

import nodeApi from '../../services/api-geral';
import apiUpload from '../../services/api-upload';

import { sessionGet, sessionAdd, sessionRemove, sessionGetAll } from '../../session';

import log from '../../util/log';
import getFiles from '../../util/getFiles';

import Page from '../../components/Page';
import TitleAndBreadcrumb from '../../components/TitleAndBreadcrumb';
import CInput from '../../components/ComponentInput';
import CButton from '../../components/ComponentButton';
import InfoMensagem from '../../components/InfoMensagem';
import ModalCropImage from '../../components/ModalCropImage';

import { Painel } from '../../styles/content';
import { Container, FormConfiguracao } from './styles';

export default function MeuPerfil() {
  const [showModalCrop, setShowModalCrop] = useState(false);

  const [idImagemPerfil, setIdImagemPerfil] = useState('');
  const [srcImagemPerfil, setSrcImagemPerfil] = useState('');

  const [hasChangesModal, setHasChangesModal] = useState(false);

  const [nomeUsuario, setNomeUsuario] = useState('');
  const [tmpNomeUsuario, setTmpNomeUsuario] = useState('');

  const [emailUsuario, setEmailUsuario] = useState('');
  const [birthDate, setBirthDate] = useState('');

  const [showAlertMerx, setShowAlertMerx] = useState(false);
  const [typeAlert, setTypeAlert] = useState('');
  const [messageAlert, setMessageAlert] = useState('');

  useEffect(() => {
    getUsuario();
  }, []);

  useEffect(() => {
    if (nomeUsuario !== tmpNomeUsuario) {
      setHasChangesModal(true);
    } else {
      setHasChangesModal(false);
    }
  }, [nomeUsuario, tmpNomeUsuario]);

  const getUsuario = async () => {
    const response = (await nodeApi.get(`/users/${sessionGet('idUser')}`)).data;

    setNomeUsuario(response.name);
    setTmpNomeUsuario(response.name);
    setEmailUsuario(response.email);
    if (response.birthDate) setBirthDate(response.birthDate.split(/[\s]+/)[0]);

    if (response.id_file) {
      setIdImagemPerfil(response.id_file)
      setSrcImagemPerfil(response.files.path);
    }
  };

  const updateUsuario = async (e) => {
    e.preventDefault();
    const response = (await nodeApi.put(`/users`, {
      id: sessionGet('idUser'),
      name: nomeUsuario,
      birthDate
    })).data;

    if (response.type == "success") {
      log(`editou as informações do perfil.`);
      setTypeAlert(response.type);
      setMessageAlert('Seus dados foram atualizados.');
      setShowAlertMerx(true);
      setTimeout(() => { setShowAlertMerx(false) }, 5000)
      sessionRemove('nomeUser')
      sessionAdd('nomeUser', response.usuario.name)
    }
    else {
      setTypeAlert(response.type);
      setMessageAlert(response.msg);
      setShowAlertMerx(true);
      setTimeout(() => { setShowAlertMerx(false) }, 5000)
    }
  }

  return (
    <>
      {showModalCrop && <ModalCropImage closeModal={() => setShowModalCrop(false)} modulo='user_profile' idImage={idImagemPerfil} setIdImage={setIdImagemPerfil} srcImage={srcImagemPerfil} setSrcImage={setSrcImagemPerfil} refresh={getUsuario} type='perfil' />}
      <Page hasChanges={hasChangesModal} setHasChanges={setHasChangesModal} content={(
        <Container>
          <TitleAndBreadcrumb title='Meu perfil' breadcrumbs={[]} format='config' />
          <div className="content-painel">
            <Painel className="painel-configuracao-perfil">
              <FormConfiguracao id="form-empresa" onSubmit={e => { updateUsuario(e); }}>
                <div className="wrap-drop">
                  {(srcImagemPerfil === '' ?
                    (
                      <div onClick={() => setShowModalCrop(true)} title="Selecione para escolher uma imagem" className="sem-imagem-perfil">
                        {/* <div onClick={() => document.getElementById('fileinput').click()} title="Selecione para escolher uma imagem" className="sem-imagem-perfil"> */}
                        <MdAdd size="40px"></MdAdd>
                      </div>
                    )
                    :
                    (
                      <div className="container-filter">
                        <div onClick={() => setShowModalCrop(true)} className="img-filter">
                          {/* <div onClick={() => document.getElementById('fileinput').click()} className="img-filter"> */}
                          <span>Alterar imagem</span>
                        </div>
                        <img src={getFiles(srcImagemPerfil)} className="imagem-perfil" />
                      </div>
                    ))}
                </div>
                <div className="wrap-input">
                  <CInput val={nomeUsuario} type='text' label='Nome' change={e => setNomeUsuario(e)} style='radius' />
                </div>
                <div className="wrap-input">
                  <CInput val={emailUsuario} type='text' label='E-mail' change={e => setEmailUsuario(e)} style='radius' disabled />
                </div>
                <div className="wrap-input">
                  <CInput val={birthDate} type='date' label='Data de Nascimento' change={e => setBirthDate(e)} style='radius' required={false} />
                </div>
                <div className="wrap-input">
                  {(showAlertMerx) && (
                    <InfoMensagem tipoAlerta={`custom ${typeAlert}`} conteudoAlerta={messageAlert} />
                  )}
                </div>
                <div className="wrap-btn">
                  <CButton title='Salvar dados' cstyle='primary small' />
                </div>
                <div className="wrap-btn">
                  <a href="/alterar-senha">Alterar senha</a>
                </div>
              </FormConfiguracao>
            </Painel>
          </ div>

        </Container>
      )
      } />
    </>
  );
}
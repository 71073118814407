import React from 'react';
import { MdInfo, MdError } from 'react-icons/md';

import { Container } from './styles';

function InfoMensagem({ tipoAlerta, conteudoAlerta, outroConteudoAlerta, customWidth, customMargin }) {
  return (
    <Container className={tipoAlerta} customWidth={customWidth} customMargin={customMargin}>
      <div className="box-icon">
      {(tipoAlerta == 'success') ? <MdInfo /> : <MdError />}
      </div>
      <div className="content-alert">
        <span>{conteudoAlerta}</span>
        {(outroConteudoAlerta != '' && outroConteudoAlerta != undefined) && <span className="second-content">{outroConteudoAlerta}</span>}
      </div>
    </Container>
  );
}

export default InfoMensagem;
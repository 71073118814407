import styled from 'styled-components'

import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Container = styled.div`
    height: 100%;
    
    .painel-assunto {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: ${colors.border};
        border-radius: ${effects.radius};
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`;

export const Tools = styled.section`
  margin: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .wrap-buttons {
    display: flex;

    h2 {
      color: white;
      display: inline;
      margin-right: 30px;
      text-transform: uppercase;
      font-weight: normal;
    }

    button {
      margin-right: 10px;
      height: 40px;
      border-radius: 20px;
      border: 0;
      padding: 0 20px;
      color: white;
      display: flex;
      align-items: center;
      transition: opacity 0.5s;

      svg {
        font-size: 19px;
        margin-right: 3px;
      }

      &.add {
        background-color: #6e9202;
      }

      &.edit {
        background-color: #5c77ff;

        &[disabled] {
          opacity: 0.5;
          cursor: context-menu;
        }
      }

      &.remove {
        background-color: #ff5050;

        &[disabled] {
          opacity: 0.5;
          cursor: context-menu;
        }
      }
    }
  }

  form {
    background-color: rgba(0, 0, 0, .3);
    width: 25%;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    input {
      background-color: transparent;
      border: 0;
      outline: none;
      color: white;
      width: 90%;
      height: 100%;
      padding: 0 10px;
    }

    svg {
      color: white;
      font-size: 24px;
    }
  }
`;

import styled from 'styled-components';

import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Wrap = styled.section`
    width:100%;
    height:100%;
    display: flex;

    @media(max-width: 1024px) {
        flex-direction: column;
    }
`;

export const Banner = styled.div`
    width: 40vw;
    height: 100vh;
    background-color: ${colors.primary};
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media(max-width: 1024px) {
        display: none;  
    }

    .title {
        width: 60%;
        height: 60vh;
        box-shadow: ${effects.boxshadow};
        background-color: ${colors.primary};
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        @media (max-width: 1440px) {
            height: 90vh;

        }

        @media (min-width: 1441px) {
            height: 75vh;
        }

        .logo-merx {
            width: 200px;
            cursor: pointer;
        }

        .logo-task {
            cursor: pointer;
            width: 75px;
            opacity: 0.75;
            position: absolute;
            left: 25px;
            bottom: 25px;
        }
    }
`;

export const WrapForm = styled.div`
    width: 60vw;
    height: 100vh;
    display: flex;
    align-items: center;

    @media (max-width: 1024px) {
        width: 100%;
    }
`;


export const Form = styled.form`
    width: 60%;
    height: 60vh;
    box-shadow: ${effects.boxshadow};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    @media (min-width: 1441px) {
        height: 75vh;
    }

    .wrap-relative {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        right: 150px;

        .title-custom-pass {
            position: absolute;
            top: -20px;
            right: 0;
        }
    }

    .box {
        width: 50%;
        margin-bottom: 1.5rem;
        display: flex;
        flex-direction: column;
        
        & > small {
         padding: 0px 10px;
         text-align: center;
        }

        div {
            h2{
                text-align: center;
            }
        }

        @media (max-width: 1024px) {
            width: 80%;
            justify-content: center;
        }
    }

    .icone-merx {
        width: 90px;
        margin-bottom: 15px;

        @media (max-width: 1369px) {
            width: 90px;
        }
    }
    .error-txt {
        width: 50%;
        height: 30px;
        margin-top: -20px;
        background-color: #ffdede;
        border-radius: 5px;
        padding-left: 5px;
        display: flex;
        align-items: center;
        color: red;
        font-size: 12px;
        margin-bottom: 40px;

        svg {
            margin-right: 10px;
            font-size: 20px;
        }
    }

    .msg-senha-alterada {
        font-size: 14px;
        border-radius: 10px;
        width: 100%;
        background-color: #e3ffe3;
        color: #0d630d;
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        svg {
            font-size: 80px;
            margin: 0 5px;
        }
    }

    .msg-senha-recuperada {
        padding: 10px;
        font-size: 14px;
        border-radius: 10px;
        width: 50%;
        background-color: #e3ffe3;
        color: #0d630d;
        display: flex;
        align-items: center;

        svg {
            font-size: 80px;
            margin: 0 5px;
        }
    }

    .msg-error{
      width:50%;
      margin: -20px 0 20px;
    }

    .banner-form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 40%;
        height: 110%;
        top: -5%;
        left: 0;
        border-radius: 20px;
        background-color: #1A202E;
        box-shadow: 1px 1px 20px 7px rgba(0,0,0,0.4);


        h1 {
            margin-top: 25px;
            color: white;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
    }

    h2 {
        // width: 50%;
        // margin-right: 5%;
        text-align: left;
    }


    .wrap-utilities {
        width: 50%;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .wrap-check {
            display: flex;
            height: 20px;
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        a {
            color: ${colors.featured};
            text-decoration: none;
        }
    }

    .wrap-alert{
        margin-bottom: 20px;
    }

    .box-success{
        display: flex;
        justify-content: center;
        & > div{
            margin-bottom: 40px;
            span{
                padding: 0px;
            }
        }
    }

    @media (max-width: 1440px) {
        width: 70%;
        height: 90vh;

        @media (max-width: 1024px) {
            width: 100%;
            box-shadow:  none;

            .wrap-utilities {
                width: 80%;
            }
        }
    }

    @media only screen and (max-width: 801px) and (min-width: 425px)  {
      width: 80vw;
    }
`;

export const Input = styled.div`
    background-color: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: bold;
    width: 50%;
    height: 50px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    position: relative;
    margin-bottom: 20px;
    transition: border-color .5s;

    @media (max-width: 1024px) {
        width: 80%;
    }

    &#input-conf-senha {
        margin-bottom: 15px;
    }

    input {
        width: 100%;
        height: 100%;
        border: 0;
        background-color: transparent;

        &:not(:placeholder-shown):not(:focus) ~ label {
            top: -15px;
            left: 0;
        }

        &:not(:placeholder-shown) ~ label {
            top: -15px;
            left: 0;
        }
    }

    label {
        position: absolute;
        display: block;
        height: 20px;
        color: ${colors.primary};
        font-weight: bold;
        transition: all 1s;
        top: -15px;
        left: 0;
    }

    &:focus-within label {
        top: -15px;
        color: ${colors.primary};
        left: 0;
    }

    &:focus-within {
        border-color: ${colors.primary};
    }
`;

export const Button = styled.button`
    width: 50%;
    height: 40px;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    background-color: ${colors.primary};
    border-radius: 10px;
    border: 0;
    letter-spacing: 1px;
    margin-bottom: 10px;

    @media (max-width: 500px) {
        font-size: 12px !important;
    }
`;

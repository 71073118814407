import styled from "styled-components"
import colors from "../../presets/colors"


export const RepliedMessageContainer = styled.div`
    margin: 4px 4px;
    position: relative;
    display: flex;
    cursor: pointer;
    border-radius: 5px;
    
    background-color: rgba(0, 0, 0, 0.15);
    font-size: 14px;
    overflow: hidden;
    justify-content: space-between;

    @media (max-width: 1400px) {
                font-size: 13px;
            }

            @media (max-width: 575px) {
                font-size: 11px;
            }


            
    
    &.InputChat {
        max-width: none;
        width: calc(100% - 170px);
        margin: 10px 9px 0;
    }

    &.you {
        border-left: 5px ${colors.primary} solid;
    }
    
    &.me {
        border-left: 5px #353535 solid;
    }

    img {
        justify-self: end;
        width: 62px;
        height: 62px;

 

        @media (max-width: 1400px) {
            width: 60px;
            height: 60px;
        }
    }

    .download-file {
            display: flex;
            align-items: center;
            padding-right: 5px;

            span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis; 
            }

            svg {
                margin-right: 5px;
                color: ${colors.primary};

                @media (max-width: 1400px) {
                font-size: 13px;
                }

                @media (max-width: 575px) {
                    font-size: 11px;
                    min-width: 10px;
                }
            }

            button {
                margin-left: 10px;
                display: flex;
                align-items: center;
                border-radius: 50%;
                border: solid 1px ${colors.primary};
                background: none;

                

                svg {
                    margin-right: 0;

                    @media (max-width: 1400px) {
                        font-size: 13px;
                    }

                    @media (max-width: 575px) {
                        font-size: 11px;
                    }
                }
            }
        }
`

export const IconFile = styled.div`
min-width: 16px;
display: flex;
align-items: center;
`

export const MessegeContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
    min-width: 130px;
    overflow: hidden;
    
    max-width: 600px;

    @media (max-width: 1200px) {
        max-width: 400px;
    }

    
    
`

export const Message = styled.div`
display: flex;

align-items: center;

p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
}
svg {
    margin-right: 4px;
    font-size: 21px;
}
`

export const DeleteButton = styled.div`
position: absolute;
top: 5px;
right: 5px;
cursor: pointer;
font-size: 16px;
`

export const NameUser = styled.p`
    
    margin-bottom: 4px;
    margin-right: 15px;
    font-weight: 500;

    &.you {
        color: ${colors.primary};
    }
    
    &.me {
        color: #353535;
    }
`

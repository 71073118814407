import React from 'react';

import { Container, Title } from './styles';

function DashboardItem({ icon, title, link }) {
  return (
    <Container href={link}>
      {icon}
      <Title>{title}</Title>
    </Container>
  );
}

export default DashboardItem;
import React, { useEffect } from 'react';
import socket from '../../services/socket';

function SocketMessagesChat({ loadMessages }) {
    useEffect(() => {
        socket.on('manage_messages', () => {
            loadMessages();
        });

        return () => {
            socket.off('manage_messages');
        }
    }, []);

    return <></>;
}

export default SocketMessagesChat;
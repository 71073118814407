import React, { useRef, useState } from "react";
import Dropzone from "react-dropzone"
import Swal from "sweetalert2";
import { FilesBox } from "../FilesBox";
import { AnexarContainer, FilesContainer, FilesLayer, SizeInfo, UploadFiles, UploadInput } from "./styles";
import { ImAttachment } from "react-icons/im";

export const DropFiles = ({files, setFiles, Size}) => {
    const [totalFilesSize, setTotalFilesSize] = useState(0)
    const uploadRef = useRef(0)





    const handleFiles = (files) => {
        const maxSize = Size * 1024 * 1024;
      
        const { newFiles: validFiles, errorFiles: exceedingFiles, filesSize } = files.reduce(
          (acc, file) => {
            const { filesSize, newFiles, errorFiles } = acc;
      
            const newSize = filesSize + file.size;
      
            if (newSize <= maxSize) {
              return {
                filesSize: newSize,
                newFiles: [...newFiles, file],
                errorFiles,
            };
            } else {
              return {
                filesSize,
                newFiles,
                errorFiles: [...errorFiles, file.name],
              };
            }
          },
          { filesSize: totalFilesSize, newFiles: [], errorFiles: [] }
        );
      

        setTotalFilesSize(filesSize)

      
        setFiles((old) => [...old, ...validFiles]);
      
        if (exceedingFiles.length > 0) {
          Swal.fire({
            title: 'Erro!',
            text:
              exceedingFiles.length > 1
                ? `Os arquivos ${exceedingFiles.map((name) => `"${name}"`)} ultrapassam o limite máximo de 25mb.`
                : `O arquivo ${exceedingFiles[0]} ultrapassa o limite máximo de ${Size}mb.`,
            icon: 'error',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK',
          });
        }
      };

      const handleDeleteFile = (index) => {
        const newArray = [...files]

        newArray.splice(index, 1)

        setFiles(newArray)
        setTotalFilesSize(prev => prev - files[0].size)
    }

    const hasFiles = !!files.length


return (
    <Dropzone
        accept=''
        onDrop={files => { handleFiles(files) }}
        noClick={true}
        >
        {({ getRootProps, getInputProps, isDragActive, isDragReject, open }) => (

            <FilesLayer className={isDragActive ? 'dragActive' : ''} {...getRootProps()} >
              <AnexarContainer>

                <UploadInput {...getInputProps()} multiple ref={uploadRef} title="&nbsp;" type='file' ></UploadInput>
                <UploadFiles  onClick={() => uploadRef.current.click()}><ImAttachment /> Anexar arquivo</UploadFiles>
                <SizeInfo>Tamanho máximo é {Size}MB</SizeInfo>
              </AnexarContainer>
              {hasFiles && <FilesContainer>
              {
                    files.map((file, index) => (
                        <FilesBox key={index} index={index} classCustom='small' nameFile={file.name} typeFile={file.type} deleteFile={handleDeleteFile}/>
                    ))
                }
              </FilesContainer>}
            </FilesLayer>
            )}
    </Dropzone>
)
}
import styled from 'styled-components'

import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Container = styled.div`
    height: 100%;

    .painel-setor {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: ${colors.border};
        border-radius: ${effects.radius};
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`;

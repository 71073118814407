import React, { useEffect, useState } from 'react';
import { Modal } from './styles';

import api from '../../services/api-geral';
import socket from '../../services/socket';
import { sessionGet } from '../../session';
import { setIdWithCripto } from '../../util/formatParamsUrl';
import { RiCloseLine, RiLoader4Line } from 'react-icons/ri';

function ModalAtendimento({ atendimento, close }) {
  const [show, setShow] = useState(true);

  const aceitarAtendimento = async () => {
    const response = await api.put(`/atendimentos/${atendimento.id}`, { id_atendente: sessionGet('idUser'), status: 'ativo' }).data;
    setTimeout(() => {
      window.location = `/atendimentos-ao-cliente/?id_atendimento=${setIdWithCripto('atendimento', atendimento.id)}&id_contato=${setIdWithCripto('user', atendimento.id_cliente)}`;
    }, 200)
  }

  return (
    <Modal>
      <button className="close-button" onClick={close}><RiCloseLine /></button>
      <strong><RiLoader4Line className="rotating" /> {atendimento.cliente.name}</strong>
      <small className="sm-setor">Nome do setor</small>
      <span>Caso não aceite, este chamado será repassado para outro atendente em segundos.</span>
      <button onClick={() => aceitarAtendimento()} className='accept-button' type='button'>Aceitar atendimento</button>
    </Modal >
  )
}

export default ModalAtendimento;
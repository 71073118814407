import styled from 'styled-components';
import colors from '../../../presets/colors';
import effects from '../../../presets/effects';

export const Container = styled.div`
  width: 100%;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Link = styled.div`
  width: 100%;
  text-decoration: none;
  color:  ${colors.btn_primary};
  background-color: ${colors.bgprimary};
  box-shadow: ${effects.boxshadow};
  border-radius: 10px;
  font-size: 13px;
  padding: 15px 20px;
  font-weight: bold;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;  // não tinha

  #text {
    border: none;
    background: white;
    width: 100%;
    color: ${colors.btn_primary};
    font-size: 13px;
    font-weight: bold;
  }

  .buttons{
    width: 50%;
    gap: 10px; 
    display: flex;
    justify-content: space-between;
    @media (max-width: 550px) {
    justify-content: space-around;
    position: absolute;
    top: 70px;
    width: 100%;
    left: 0px;
    height: 40px;
  }
  }

`;

export const ButtonLink = styled.button`
  width: 100px;// era 70px
  height: 30px;
  font-weight: normal;
  margin-right: 0px; // era 15px
  color:  ${colors.btn_text_primary};
  background-color: ${colors.btn_primary};
  border: none;
  border-radius: 5px;
  font-size: 13px;
  //position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;  

  svg {
    font-size: 30px;
  }

  &.big {
    width: 200px;    
  }

  @media (max-width: 620px) {
    &.big {
    width: 150px;    
  }
  }
  
`;
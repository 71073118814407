import React, { useEffect } from 'react';
import socket from '../../services/socket';

function SocketViewsPost({ getViews, idPost }) {
    useEffect(() => {
        socket.on('view_post', post => {
            if (idPost === post) {
                getViews('socket');
            }
        });

        return () => {
            socket.off('view_post');
        }
    }, []);

    return <></>;
}

export default SocketViewsPost;
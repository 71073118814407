import React, { useState, ChangeEvent, useCallback } from 'react';
import axios from 'axios';
import api from '../../services/api-geral';
import uploadFiles from '../../util/uploadArquivo';
import getExtension from '../../util/getExtension';
import Highlight from 'react-highlight.js';

import {
  RiFileDownloadLine,
  RiFolderAddLine,
  RiCloseLine,
  RiCheckLine,
  RiDeleteBin5Line
} from 'react-icons/ri';

import { Container, Sidebar, Backdrop } from './styles';
import ItemCentralMidia from '../ItemCentralMidia';
import { sessionGet } from '../../session';
import { useEffect } from 'react';
import renderImg from '../../util/renderImg';
import AlertaMerx from '../AlertaMerx'

function CentralMidia({ format, opened, close, setFile, files = "" }) {
  const [renders] = useState({
    'jpg': 'img',
    'png': 'img',
    'jpeg': 'img',
    'pdf': 'embed',
    'doc': 'blocked',
    'docm': 'blocked',
    'docx': 'blocked',
    'js': 'code',
    'php': 'code',
    'css': 'code',
    'html': 'code',
    'cs': 'code',
  });

  const [inputFolder, setInputFolder] = useState('');
  const [folder, setFolder] = useState(sessionGet('folder'));
  const [mainFolder, setMainFolder] = useState(sessionGet('folder'));
  const [diretory, setDiretory] = useState([]);
  const [showViewer, setShowViewer] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [contentCode, setContentCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [ready, setReady] = useState(false);

  // alert
  const [typeAlert, setTypeAlert] = useState('danger');
  const [msgAlert, setMsgAlert] = useState('');
  const [showAlert, setShowAlert] = useState(false);


  const getDiretory = async () => {
    const response = (await api.get(`/pastas/${folder}`)).data;

    setDiretory(response.diretorio);
  };

  const handleUpload = async (e) => {
    const files = e.target.files;
    const response = await uploadFiles(Array.from(files), folder);

    if (!response) {
      setTypeAlert('danger');
      setMsgAlert('Arquivo muito grande!');
      setShowAlert(true);
      setTimeout(() => { setShowAlert(false) }, 5000)
    } else {
      window.setTimeout(getDiretory, 1000);
    }
  };

  const handleDeleteFile = async id => {
    const response = (await api.delete(`/files/${id}`)).data;
    setTypeAlert(response.type === 'erro' ? 'danger' : response.type);
    setMsgAlert(response.msg);
    setShowAlert(true);
    setTimeout(() => { setShowAlert(false) }, 5000)
    getDiretory();
    setShowViewer(false);
    setSelectedItem({});
  };

  const handleCreateFolder = async e => {
    e.preventDefault();

    if (inputFolder !== '') {
      const response = (await api.post('/pastas', { name: inputFolder, id_pai: folder })).data;
      setTypeAlert(response.type === 'error' ? 'danger' : response.type);
      setMsgAlert(response.msg);
      setShowAlert(true);
      setTimeout(() => { setShowAlert(false) }, 5000)
      getDiretory();
      setInputFolder('');
    } else {
      setTypeAlert('danger');
      setMsgAlert('Título de pasta vazio!');
      setShowAlert(true);
      setTimeout(() => { setShowAlert(false) }, 5000)
    }
  };

  const handleDeleteFolder = async id => {
    const response = (await api.delete(`/pastas/${id}`)).data;

    getDiretory();
  };

  useEffect(() => {
    if (opened) getDiretory();

    if (format === 'page') getDiretory();

  }, [folder, opened]);

  const getContent = async () => {
    const content = await axios?.get(renderImg(selectedItem.filename));

    setContentCode(content.data);
  };

  const resizeImg = async () => {
    const img = new Image();
    img.src = renderImg(selectedItem.filename);
    img.onload = () => {
      if (img.height > 3000) {
        setHeight('60%');
        setWidth('auto');
      } else if (img.width > img.height) {
        setWidth('80%');
        setHeight('auto');
      } else if (img.height > img.width) {
        setHeight('60%');
        setWidth('auto');
      } else {
        setHeight('60%');
        setWidth('auto');
      }
    }
  };

  useEffect(() => {
    getContent();

    if (selectedItem.id) {
      resizeImg();
    }

    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, [selectedItem]);

  useEffect(() => {

    if (ready) {
      getDiretory();
      setReady(false);
    }

  }, [ready]);

  return (
    <>
      {format === 'plugin' && opened && <Backdrop></Backdrop>}
      {(format === 'page' || format === 'plugin' && opened) && (
        <>
          <Container className={format}>

            {format === 'plugin' && (
              <button onClick={() => close()} className="btn-fechar-plugin">
                <RiCloseLine />
              </button>
            )}
            {(showViewer) && (
              <Sidebar className="file-manager">
                <div className="viewer">
                  {!loading && (
                    <>
                      {renders[getExtension(selectedItem, "viewer")] === 'img' && <img width={width} height={height} src={renderImg(selectedItem.filename)} />}
                      {renders[getExtension(selectedItem, "viewer")] === 'blocked' && <h3>Não é possível visualizar esse tipo de arquivo.</h3>}
                      {renders[getExtension(selectedItem, "viewer")] === 'embed' && <embed src={renderImg(selectedItem.filename)} />}
                      {renders[getExtension(selectedItem, "viewer")] === 'code' && (
                        <Highlight language={'js'}>
                          {contentCode}
                        </Highlight>
                      )}
                    </>
                  )}

                  {loading && (
                    <div className="loader">
                      <h3>Um momento enquanto carregamos seu arquivo!</h3>
                    </div>
                  )}
                </div>
                <div className="description">
                  <button onClick={() => { setShowViewer(false); setSelectedItem({}); }} className="btn-fechar">
                    <RiCloseLine />
                  </button>
                  <div className="description-holder">
                    <h2>Informações do arquivo</h2>
                    <form>
                      <label>Título do arquivo:</label>
                      <input type="text" disabled="disabled" value={selectedItem.originalname} />
                      {/* <button className="cta" type="submit">
                      <RiEdit2Line />
                      Editar arquivo
                    </button> */}
                    </form>
                    <div className="separator"></div>
                    {format === 'plugin' && (
                      <button onClick={() => { setFile(selectedItem.id); setSelectedItem({}); setShowViewer(false); }} className="cta btn-use">
                        <RiCheckLine />
                      Utilizar arquivo
                      </button>
                    )}
                    <button onClick={() => handleDeleteFile(selectedItem.id)} className="cta btn-delete">
                      <RiDeleteBin5Line />
                    Excluir arquivo
                  </button>
                  </div>
                </div>
              </Sidebar>
            )}
            <div className="topbar">
              <h2>Central de mídia - {sessionGet('nomeUser')}</h2>

              <div className="separator"></div>
              <ul className="tabs">
                <div className="left">
                  <li className="primary" onClick={() => document.getElementById('input_file').click()}>
                    <RiFileDownloadLine />
                    <label className="txtbtn-midia">Adicionar arquivo</label>
                    <input type="file" id="input_file" onChange={handleUpload} multiple />
                  </li>
                  <div className="separator-btn"></div>
                  <li className="li-separator"></li>
                  <form onSubmit={handleCreateFolder}>
                    <li className="li-input">
                      <input type="text" required value={inputFolder} onChange={e => setInputFolder(e.target.value)} placeholder="Nome da pasta" />
                    </li>
                    <li className="primary">
                      <button type="submit">
                        <RiFolderAddLine />
                        <label className="txtbtn-midia">Criar nova pasta</label>
                      </button>
                    </li>
                  </form>
                </div>
                <div className="right">
                  {(showAlert) && (
                    <AlertaMerx
                      type={typeAlert}
                      style='default'
                      text={msgAlert}
                      css={['auto', '25px', 'auto', 'auto']}
                      context='in'
                    />
                  )}
                </div>
              </ul>
            </div>
            <div className="files">
              <ul className="content">
                {(mainFolder !== folder) && <ItemCentralMidia originalname={'../'} changeFolder={() => setFolder(mainFolder)} item={{ type: 'folder', title: '../' }} />}
                {diretory.filter(item => !item.originalname || renders[getExtension(item, "viewer")] === files || files === "").map(item => <ItemCentralMidia viewFile={() => {
                  setShowViewer(true);
                  setLoading(true);
                  setSelectedItem(item);
                }} changeFolder={() => setFolder(item.id)}
                  deleteFolder={() => handleDeleteFolder(item.id)}
                  item={
                    {
                      id: item.id,
                      type: item.originalname ? 'file' : 'folder',
                      title: item.originalname ?
                        item.originalname.length > 25 ?
                          item.originalname.substr(0, 25) + "..."
                          : item.originalname
                        : item.label.length > 25 ?
                          item.label.substr(0, 25) + "..."
                          : item.label,
                      name: item.originalname
                    }} getDiretory={getDiretory} />)}
              </ul>
            </div>
          </Container>
        </>
      )}
    </>
  );
}

export default CentralMidia;
import React, { useState, useEffect } from 'react';

import getFiles from '../../util/getFiles';

import { Container, Backdrop } from './styles';
import { RiCloseFill } from 'react-icons/ri';
import { AiOutlineSearch } from "react-icons/ai";

function ModalShowImage({ img, close }) {
    const [classImage, setClassImage] = useState('');
    const [leftButton, setLeftButton] = useState(0);
    const [topButton, setTopButton] = useState(0);
    const [leftLink, setLeftLink] = useState(0);
    const [topLink, setTopLink] = useState(0);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleWindowResize = () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        };
        verificaTamanhoImagem();
        window.addEventListener("resize", handleWindowResize);
    }, [width, height]);

    useEffect(() => {
        setTimeout(() => {
            verificaTamanhoImagem();
        }, 100);
    }, []);

    const verificaTamanhoImagem = () => {
        const imagem = document.getElementById("imagem-modal");
        if (imagem) {
            const width = imagem.naturalWidth;
            const height = imagem.naturalHeight;

            if (width == height) {
                setClassImage("quadrada");
            }

            if (height > width) {
                setClassImage("vertical");
            }

            if (width > height) {
                setClassImage("horizontal");
            }

            var rect = imagem.getBoundingClientRect();

            const leftPos = rect.left + imagem.clientWidth - 40;
            const topPos = rect.top + 10;
            const leftLinkPos = rect.left + imagem.clientWidth - 80;
            const topLinkPos = rect.top + 10;

            setTopButton(topPos);
            setLeftButton(leftPos);
            setLeftLink(leftLinkPos);
            setTopLink(topLinkPos);
        }
    }

    return (
        <Container top={topButton} left={leftButton} topLink={topLink} leftLink={leftLink}>
            <div className="image-holder">
                <Backdrop onClick={() => close()}>
                </Backdrop>
                <img src={img} id="imagem-modal" className={classImage} />
                <button onClick={() => close()} title="Fechar"><RiCloseFill /></button>
                <a href={img} target="_blank" title="Ver original"><AiOutlineSearch /></a>
            </div>
        </Container>
    );
}

export default ModalShowImage;
import styled from "styled-components";

import colors from "../../presets/colors";
import effects from "../../presets/effects";

export const Container = styled.div`
  width: 100%;
  padding: 20px 15px 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: ${effects.boxshadow};
  margin-bottom: 20px;

  .body {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      box-shadow: ${effects.boxshadow};
      margin-right: 10px;
    }

    .images-unica {
      width: 100%;
      padding: 0 10px 0;

      .img-post {
        display: flex;
        width: 100%;
        height: auto;
        border-radius: 0;
        margin: 20px auto;
        object-fit: cover;
        object-position: center;
      }
    }

    .images {
      width: 100%;
      margin: 10px 0 0;
      align-self: center;
      padding: 0 10px 0;
      display: grid;
      grid-gap: 10px;
      grid-template-columns: repeat(2, 1fr);

      .img-post {
        display: flex;
        width: 100%;
        height: auto;
        border-radius: 0;
        margin: auto;
        object-fit: cover;
        object-position: center;
      }
    }

    .actions {
      width: 100%;
      justify-content: space-between;
      display: flex;
      margin-top: 15px;
      padding: 0 10px;

      & > span {
        color: ${colors.twitter};
        display: flex;
        align-items: center;
        margin-right: 10px;
        background-color: ${colors.bgsecondary};
        padding: 10px;
        border-radius: 10px;
        cursor: pointer;

        span {
          font-size: 12px;

          @media (max-width: 700px) {
            display: none;
          }
        }

        &.active {
          background-color: ${colors.twitter};
          color: white;

          svg {
            color: white;
          }

          small {
            font-size: 12px;
            color: white;
            font-weight: bold;
          }
        }

        svg {
          color: ${colors.texton};
          font-size: 18px;
          margin-right: 5px;

          @media (max-width: 638px) {
            margin-right: 0;
          }
        }

        strong {
          color: ${colors.texton};
          margin-right: 5px;
        }

        small {
          margin-left: 5px;
          color: ${colors.texton};
        }
      }
    }

    .content-holder {
      width: 100%;
      display: flex;
    }

    .content {
      display: flex;
      flex-direction: column;

      .name {
        display: flex;
        flex-direction: column;

        strong {
          margin-right: 5px;
          display: block;
        }

        small {
          display: block;
          font-weight: bold;
          color: #666;
        }
      }

      p {
        font-weight: normal;

        margin-top: 5px;
      }
    }
  }
`;

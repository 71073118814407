import React, { useEffect, useState } from 'react';
import Toolbar from './components/Toolbar';
import Emoji from './components/Emoji';
import urlToEmbed from '../../util/urlToEmbed';
import Preview from './components/Preview';
import Autocomplete from './components/Autocomplete';

import ModalVideo from '../ModalVideo';
import CentralMidia from '../CentralMidia';
import urlIMG from '../../util/urlImg';
import detailsMidia from '../../util/detailsMidia';


import { Wrap, EditorAlert, WrapTargets } from './styles';


import { MdTextFields, MdPersonPin } from 'react-icons/md';

import {
  FaUserPlus,
  FaYoutube,
  FaImage,
  FaRegPaperPlane,
  FaTrashAlt,
  FaRegSmileWink,
  FaFont,
} from 'react-icons/fa';

export default function Editor({
  onSubmit,
  placeholder,
  value,
  elementId,
  edit = false,
  data,
  changeImage,
  changeVideo,
  style,
  image,
  showMidias,
  midias = true,
  emoji = true,
  textFont = true,
  onCancelSubmit,
  setDisplay,
  setInvDisplay
}) {

  const [formatText, setShowFormatText] = useState(0);
  const [imagePost, setImagePost] = useState(null);
  const [previewImagePost, setPreviewImagePost] = useState(null);
  const [previewVideoPost, setPreviewVideoPost] = useState(null);
  const [showModalVideo, setShowModalVideo] = useState(false);
  const [idVideo, setIdVideo] = useState('');
  const [urlVideo, setUrlVideo] = useState('');
  const [showEmojis, setShowEmojis] = useState(0);
  const [alert, setAlert] = useState(false);
  const [expandDiv, setExpandDiv] = useState(false);
  const [focus, setFocus] = useState(false);
  const [targetUserList, setTargetUserList] = useState(false);
  const [targetUsers, setTargetUsers] = useState([]);

  useEffect(() => {
    document.querySelector(`div#${elementId}`).addEventListener("paste", function (e) {
      e.preventDefault();
      var text = e.clipboardData.getData("text/plain");
      document.execCommand("insertHTML", false, text);
    });

    if (edit) {

      if (data.post_id_midia) {
        if (data.post_id_midia !== '') setImagePost(data.post_id_midia);

        if (data.post_url_video !== '') {
          setUrlVideo(`https://youtube.com/watch?v=${data.post_url_video}`);
          setIdVideo(data.post_url_video);
        }
      }
      if (data.comment_id_midia) {
        if (data.comment_id_midia !== '') {
          setImagePost(data.comment_id_midia);
        }

        if (data.comment_url_video !== '') {
          setUrlVideo(`https://youtube.com/watch?v=${data.comment_url_video}`);
          setIdVideo(data.comment_url_video);
        }
      }
    }
  }, [])

  useEffect(() => {
    if (edit) {
      if (imagePost !== null) {
        changeImage(imagePost)
      }
    }
    previewImage(imagePost)
  }, [imagePost]);

  useEffect(() => {
    setPreviewVideoPost(urlVideo);
  }, [urlVideo]);

  useEffect(() => {
    setImagePost(image);
  }, [image]);

  const onEmojiClick = (event, emojiObject) => {
    let editor = document.getElementById(elementId);
    editor.append(emojiObject.emoji)
    setShowEmojis(0);
  };

  const cancelSubmit = () => { onCancelSubmit(false) }

  // tratando submit do editor
  const handleSubmit = () => {
    const editor = document.getElementById(elementId);
    if (imagePost || idVideo) {
      const { tempText, tempImage, tempVideo } = submitHelper(editor);
      return onSubmit(tempText, tempImage, tempVideo, editor, targetUsers);
    } else if (editor.innerHTML.length > 0) {
      const { tempText, tempImage, tempVideo } = submitHelper(editor);
      return onSubmit(tempText, tempImage, tempVideo, editor, targetUsers);
    } else {
      setAlert(true)
    }
  }

  const submitHelper = (editor) => {
    const tempImage = imagePost;
    let tempVideo = '';
    const tempText = editor.innerHTML;

    let url = tempText.match(/\bhttps?:\/\/\S+/gi);
    if (url !== null) {
      url = url[0].replace('<br>', '');

      if (urlToEmbed(url) !== null) {
        tempVideo = urlToEmbed(url);
      }
    }


    setIdVideo('');
    setUrlVideo('');
    setImagePost(null);
    setShowEmojis(0);
    setShowFormatText(0);
    setAlert(false);
    setTargetUsers([]);
    editor.innerHTML = '';

    return { tempText, tempImage, tempVideo }
  }

  const excluirImagem = async () => {
    setImagePost(null)
    if (edit) {
      changeImage(null)
    }
  };

  const excluirVideo = async () => {
    setIdVideo('')
    setUrlVideo('')
    if (edit) {
      changeVideo(null)
    }
  };

  const previewImage = async (midia) => {
    setPreviewImagePost(await detailsMidia(midia));
  };

  const handleKeyDown = (event) => {
    const editor = document.getElementById(elementId);
    const content = editor.innerHTML;

    if (event.which === 8) {
      if (editor.innerHTML.length < 80) {
        setExpandDiv(false);
      }
    }
  }

  const handleKeyPress = (event) => {
    const editor = document.getElementById(elementId);
    const content = editor.innerHTML;

    if (editor.innerHTML.length > 80) {
      setExpandDiv(true);
    }

    if (event.shiftKey) {
      setExpandDiv(true);
    }

    const rules = [
      '',
      '&nbsp;',
      '<div><br></div><div><br></div>',
      '&nbsp;<div><br></div>',
      '<div>&nbsp;</div><div><br></div>',
      '<div><br></div>'
    ];

    if (event.which === 13 && !event.shiftKey) {
      setExpandDiv(true);
      if (rules.includes(content) === false) {
        setExpandDiv(false);
        handleSubmit()
      } else {
        setAlert(true)
        editor.innerHTML = "";
      }

    }
  }

  const onFocus = (event) => {
    if (event.target.id === 'editorPublicacao') {
      setFocus(true);
      document.getElementById("editorPublicacao").parentNode.parentNode.classList.add('focus-wrap')
    }

  }
  const onBlur = (event) => {
    if (event.target.id === 'editorPublicacao') {
      setFocus(false);
      document.getElementById("editorPublicacao").parentNode.parentNode.classList.remove('focus-wrap')
    }

  }

  const targetUser = (user) => {
    let users = targetUsers;

    const found = users.find(userTarget => userTarget.id === user.id);

    if (!found) {
      users.push({ id: user.id, name: user.name })
      setTargetUsers(users)
      setTargetUserList(false)
    }
  }

  const removeTargetUser = (id) => {
    let itemIndex = targetUsers.filter(target => target.id !== id);
    setTargetUsers(itemIndex);
  }

  const targetAllUsers = (users) => {
    setTargetUsers(users)
    setTargetUserList(false)
  }

  return (
    <>
      {alert && (
        <EditorAlert className="box-alert">
          <span>Digite uma mensagem para continuar.</span>
        </EditorAlert>
      )}

      {(formatText === 1) && <Toolbar editorId={elementId} />}

      {(previewImagePost) && <Preview type="imagem" title={previewImagePost.originalname} action={excluirImagem} />}

      {previewVideoPost && <Preview type="video" title={urlVideo} action={excluirVideo} />}



      <Wrap expand={expandDiv} variant={style}>
        {showModalVideo &&
          <ModalVideo
            urlVideo={urlVideo}
            setIdVideo={setIdVideo}
            setUrlVideo={setUrlVideo}
            closeModal={setShowModalVideo}
          />
        }

        {(showEmojis === 1) && <Emoji onEmojiClick={onEmojiClick} setShowEmojis={setShowEmojis} />}
        {(targetUserList) && <Autocomplete setTargetUserList={setTargetUserList} targetUser={targetUser} targetAllUsers={targetAllUsers} />}

        <div
          id={elementId}
          className={(focus) ? 'editor-paper focus' : 'editor-paper'}
          contentEditable="true"
          designmode="on"
          spellCheck="true"
          placeholder={placeholder}
          dangerouslySetInnerHTML={{ __html: value }}
          onKeyPress={handleKeyPress}
          onKeyDown={handleKeyDown}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        <div className='toolbox-teste'>
          <div className="person-icon" onClick={() => { setDisplay(true); setInvDisplay(true) }}><FaUserPlus /></div>
          {(emoji === true) &&
            (
              <div onClick={() => formatText === 0 ? setShowFormatText(1) : setShowFormatText(0)}><FaFont /></div>
            )}
          {(textFont === true) &&
            (
              <div onClick={() => showEmojis === 0 ? setShowEmojis(1) : setShowEmojis(0)}><FaRegSmileWink /></div>
            )}


          {/*(imagePost === null && midias === true) &&
          (
            <div onClick={() => setShowModalVideo(true)}><FaYoutube /></div>
          )
        */}
          {(idVideo === '' && midias === true) &&
            (
              <div onClick={() => showMidias(true)}><FaImage /></div>
            )
          }

          {(style !== 'espalhar') ?
            (<div className='btn-submit' onClick={handleSubmit}><FaRegPaperPlane /></div>)
            :
            (
              <>
                <div className='btn-cancelar' onClick={() => { cancelSubmit(); setDisplay(true) }}>Cancelar</div>
                <div className='btn-submit' onClick={handleSubmit}><FaRegPaperPlane /> Enviar</div>
              </>
            )
          }
        </div>
      </Wrap>
      <WrapTargets>
        {(targetUsers) &&
          (
            targetUsers.map(user => {
              return (
                <div className='item'>
                  <span>{user.name}</span>
                  <span onClick={() => removeTargetUser(user.id)}><FaTrashAlt /></span>
                </div>

              )
            })
          )
        }
      </WrapTargets>
    </>
  );
}

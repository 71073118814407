import React, { useState, useEffect } from 'react';

import api from '../../services/api-geral';

import { WrapConversaHistorico, WrapHistorico, CloseButton, Tab } from './styles';
import { RiCloseLine } from 'react-icons/ri';

import { MdClose } from 'react-icons/md';

import { sessionGet } from '../../session'

import { formatDate } from '../../util/formats';

import CFormSearch from '../ComponentFormSearch';

import apiUpload from '../../services/api-upload';
import getFiles from '../../util/getFiles';

export default function ConversaHistrico({ isModal = false, closeHistorico, usuario, getMessagesHist }) {

  const [historico, setHistorico] = useState([]);
  const [search, setSearch] = useState('');

  const [hfullImg, setHFullImg] = useState(false);
  const [hfullImgPath, setHFullImgPath] = useState('');

  useEffect(() => {
    visualizarHistorico(usuario.id);
  }, [usuario]);

  const visualizarHistorico = async (id) => {
    const response = (await api.get(`/historico/${id}`)).data;
    setHistorico(response);
  };

  const getImage = async (id) => {
    const response = (await apiUpload.get(`/upload/${id}`));
    if (response.data.length > 0) {
      setHFullImg(true);
      setHFullImgPath(response.data[0].path);
    }
  }
  return (
    <WrapConversaHistorico isModal={isModal}>
      <WrapHistorico isModal={isModal}>
        {(isModal) && (
          <CloseButton onClick={() => closeHistorico()}>
            <MdClose />
            Fechar
          </CloseButton>
        )}
        <Tab>
          <div className="box-search">
            <CFormSearch cstyle='ligth' placeholder="Pesquisar" value={search} change={value => setSearch(value)} />
          </div>
          <div className="detalhes-conversa">
            {
              historico.filter(({ message }) => message.toLowerCase().includes(search.toLowerCase())).map(h => (
                <>
                  <div onClick={() => {getMessagesHist(0, null, usuario.id, h.id);closeHistorico()}} className={(sessionGet('idUser') !== h.user_id) ? 'box userDestaque' : 'box'}>
                    <div className='name'>{h.name}</div>
                    <div className='message'><div dangerouslySetInnerHTML={{__html: h.message}} ></div>
                      {(h.type_file !== null && h.type_file !== '') ?
                        h.type_file === 'file' ? <span>Arquivo</span> :
                        <span onClick={() => getImage(h.id_file)}>Imagem</span> : ''}
                    </div>
                    <div className='date'>{formatDate(h.created_at)}</div> 
                    
                  </div>
                </>
              ))
            }
          </div>
        </Tab>

      </WrapHistorico>
      {(hfullImg) &&
        <div className="full-screen-historico">
          <div className="fs-holder">
            <button className="btn-delete-hst" type="button" onClick={() => setHFullImg(false)}><RiCloseLine /></button>
            <img src={getFiles(hfullImgPath)}></img>
          </div>
        </div>}
    </WrapConversaHistorico>
  );
}

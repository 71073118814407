import styled from 'styled-components';

import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Wrap = styled.div`
    width: 100%;
    //width: ${props => (props.local == "externo") ? '100%' : 'calc(100% - 300px)'};
    height: 100%;
    position: relative;
    background: ${props => (props.local == "externo") ? 'black' : 'white'};
    border-width: 0 1px 0;
    border-color: ${colors.border};
    border-style: solid;

    /*@media (max-width: 1368px) {
        width: ${props => (props.local == "externo") ? '100%' : 'calc(100% - 275px)'};
    }*/

    .atendimento-espera {
        height: 100%;
        width: 100%;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        background: linear-gradient(to bottom right, #5c77fe, #a6abc2);

        span{
            width: 60%;
            text-align:center;
            padding: 20px;
            color: white;
            letter-spacing: 3px;
            font-weight: bold;
        }
    }

    .avaliacao-finalizada{
        height: 100%;
        width: 100%;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        background: linear-gradient(to bottom right, #5c77fe, #a6abc2);

        p{
            width: 60%;
            text-align:center;
            padding: 20px;
            color: white;
            letter-spacing: 3px;
            font-weight: bold;
        }
    }

    .atendimento-finalizado{
        padding: 10px 0;
        width: 100%;
        min-height: 75px;
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background: #5cb85c;
        color: #ffff;
        font-weight: 700;

        @media (max-width: 1400px) {
            min-height: 50px;
        }

        p{
            width: 60%;
            text-align:center;
            padding: 20px;
            color: white;
            letter-spacing: 3px;
            font-weight: bold;
        }
        
        span{
            margin: 10px;
        }

        textarea{
            resize: none;
        }

        button{
            width: 40%;
            height: 40px;
            color: white;
            font-weight: bold;
            font-size: 12px;
            text-transform: uppercase;
            background-color: #2C76FF;
            border-radius: 10px;
            border: 0;
            margin-bottom: 20px;
            -webkit-letter-spacing: 1px;
            -moz-letter-spacing: 1px;
            -ms-letter-spacing: 1px;
            letter-spacing: 1px;
            margin-right: 5%;
        }

    }

    .form-msg {
        width: 100%;
        height: 100%;
    }

    .chat-msg {
        height: 100%;
    }

    .div-chat {
        text-align: center;
    }

    .img-inchat {
        margin-top: 10px;
        width: 280px;

        @media (max-width: 1400px) {
            width: 220px;
        }

        @media (max-width: 950px) {
            width: 180px;
        }

        @media (max-width: 575px) {
            width: 120px;
        }
    }

    .img-inchat:hover{
        cursor: pointer;
    }

    .legenda-img {
        width: 70%;
        margin-top: 30px;
        background-color: #e6e6e6;
        border: none;
    }

    .line-legenda {
        width: 70%;
        margin-top: 5px;
        border: 1px solid ${colors.primary};
    }

    .full-screen-img {
        position: fixed;
        padding: 20px;
        left: 110px;
        top: 90px;
        background-color: rgba(0, 0, 0, 0.5);
        width: calc(100% - 110px);
        height: calc(100vh - 90px);

        @media (max-width: 1440px) {
            top: 70px;
            width: calc(100% - 110px);
            height: calc(100vh - 70px);
        }

        @media (max-width: 1024px) {
            top: 70px;
            left: 0;
            width: 100%;
            height: calc(100vh - 70px);
        }

        .fullscreen-holder {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
            text-align: center;

            img {
                margin: auto;
                height: auto;
                max-height: 100%;
                width: auto;
                max-width: 100%;
                zoom: 10;
                min-width: 30%;
            }

           
        }

        .open-close-image {
            right: 20px;
            top: 20px;
            display: flex;
            text-align: center;
            position: absolute;

            a {
                font-size: 20px;
                //border: 1px solid white;
                padding: 5px 10px;
                border-radius: 10px;
                background-color: #5cb85c;
                color: white;
                text-decoration: none;
                margin-right: 10px;
            }

            button {
                background-color: ${colors.secondary};
                color: white;
                border: none;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    font-size: 30px;
                }
            }
        }
    }

    .image-preview {
        width: 100%;
        height: calc(100% - 75px);
        margin-top: 75px;

        @media (max-width: 1400px) {
            height: calc(100% - 60px);
            margin-top: 60px;

        }

        align-items: center;
        background-color: #e6e6e6;
        
        position: absolute;
        left: 0;
        top: 0;
        
        .content-image-preview {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          height: 100%;
          position: relative;
          align-items: center;

          .file-preview {
              display: flex;
              align-items: center;

              svg {
                  font-size: 22px;
                  margin-right: 5px;
                  color: ${colors.primary};
              }
          }

          .img-preview {
            width: 30%;
            border-radius: 5px;

            @media (max-width: 750px) {
                width: 40%;
            }

            @media (max-width: 575px) {
                width: 60%;
            }
          }

          .btn-delete-img {
            background-color: ${colors.secondary};
            color: white;
            border: none;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 20px;
            top: 20px;

            svg {
                font-size: 30px;
            }
          }
          
          .btn-send-img {
            background-color: ${colors.primary};
            color: white;
            border: none;
            border-radius: 50%;
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 60px;
            bottom: 60px;

            @media (max-width: 750px) {
                width: 50px;
                height: 50px;
                right: 30px;
                bottom: 30px;
            }

            svg {
                font-size: 30px;
            }
          }
        }
      }

    .info-head {
        height: 75px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid ${colors.border};

        @media (max-width: 1400px) {
            height: 60px;
        }

        .user {
            display: flex;
            align-items: center;

            .back-button {
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: ${colors.primary};
                border: none;
                border-radius: 50%;
                color: white;
                margin-left: 10px;

                svg {
                    font-size: 20px;
                }

                @media (min-width: 1025px) {
                    display: none;
                }
            }  

            .presence {
                span {
                    width: 8px;
                    height: 8px;
                    display: block;
                    position: absolute;
                    left: 25px;
                    border-radius: 50%;
                    border: 3px solid white;
                    box-sizing: content-box;

                    @media (min-width: 1401px) {
                        left: 35px;
                    }

                &.offline { background-color: gray; }
                &.online { background-color: green; }
                &.ausente { background-color: #FFBF00; }
                &.naoPerturbe { background-color: #FF0000; }
            }
    }

            .info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 10px;

                .modal-user {
                    display: none;
                    left: 3%;
                    top: 5%;
                 p {
                    font-size: .9rem;
                    margin-top: 0;
                    margin-bottom: 5px;
                 }
                 a {
                    font-size: .9rem;
                 }
                }

                &:hover {
                    .modal-user {
                        padding: 10px;
                        display: flex;
                        top: 42px;
                    }
                }

                strong {
                    color: ${colors.texton};
                    font-size: 16px;
                    font-weight: bold;

                    @media (max-width: 1400px) {
                        font-size: 14px;
                    }

                    a {
                        color: ${colors.texton};
                        font-size: 16px;
                        font-weight: bold;
                        text-decoration: none;

                        @media (max-width: 1400px) {
                            font-size: 14px;
                        }
                    }
                }

                span {
                    display: block;
                    color: ${colors.texton};
                    font-size: 15px;

                    @media (max-width: 1400px) {
                        font-size: 12px;
                    }
                }
            }
        }

        .options-conversa {
            margin-right: 20px;
            color: ${colors.textoff};
            background: ${colors.bgsecondary};
            border: 0;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
      
            svg {
                font-size: 25px;
                color: ${colors.primary};
            }

            span {
                font-size: 16px;
                color: ${colors.secondary};
            }
            
            @media (max-width: 1400px) {
                font-size: 25px;
            }
        }
    }

    .lista-mensagem {
        height: calc(100% - 150px);
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);

        .scroller {
            min-height: 100vh;
            padding-bottom: 20px;
            display: flex;
            flex-direction: column-reverse;
        }

        & > div {
            ::-webkit-scrollbar {
                width: 4px;
            }

            ::-webkit-scrollbar-track {
                background: #f1f1f1;
            }

            ::-webkit-scrollbar-thumb {
                background: ${colors.border};
                border-radius: ${effects.radius};
            }

            ::-webkit-scrollbar-thumb:hover {
                background: #555;
            }
        }

        @media (max-width: 1400px) {
            height: calc(100% - 100px);
        }

        &.draggin {
            height: 0%;
            padding: 0;
        }


        .message-blink {
        background-color: ${colors.primary};
        color: white;

        small {
            color: white;
        }
        
        }
    }

`;

export const ImgAndStatus = styled.div`
    position: relative;
    background-color: ${colors.primary};
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;

    @media (max-width: 1400px) {
        width: 35px;
        height: 35px;
        font-size: 20px;
    } 

    svg {
        display: block;
    }

    .avatar-merx {
        width: 30px;
        height: 30px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
    }

    span {
        width: 12px;
        height: 12px;
        display: block;
        background-color: green;
        position: absolute;
        bottom: 0px;
        right: 3px;
        border-radius: 50%;
        border: 3px solid #1d2129;
        box-sizing: content-box;

        &.online {
            background-color: green;         
        }

        &.offline {
            background-color: gray;
        }
    }
`;

export const ListaArquivos = styled.div`
    width: 80%;
    height: 70%;
    margin-bottom: 50px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    border: 2px solid rgba(255, 255, 255, 0.05);
    display: flex;
    justify-content: center;
    align-items: center;
    
    h1 {
        color: rgba(255, 255, 255, 0.3);
        font-weight: normal;
        letter-spacing: 1px;
    }
`;

export const Form = styled.form`
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    min-height: 75px;
    justify-content: center;
    align-items: center;

    @media (max-width: 1400px) {
        min-height: 50px;
    }

    .wrap-drop {
        width: 100%;
        height: 75px;
        display: flex;
        flex-direction: column;
        background-color: ${colors.bg_from_primary};
        box-shadow: 0 1px 15px rgba(0,0,0,.04), 0 1px 6px rgba(0,0,0,.04);

        @media (max-width: 1400px) {
            height: 50px;
        }

    }

    .send-image-form {
        position: relative !important;
        bottom: unset;
    }
    
    .dropzone {
        height: 100%;
    }

    &.draggin {
        height: calc(100% - 75px);   

        @media (max-width: 1400px) {
            height: calc(100% - 50px); 
        }

    }

    .input-group {
        width: 100%;
        min-height: 75px;
        position: relative;
        background-color: #e6e6e6;
        display: flex;
        align-items: center;
        padding: 10px 0;

        @media (max-width: 1400px) {
            min-height: 50px;
        }

        .text-input {
            width: 100%;
            max-width: calc(100% - 160px);
            display: flex;
            flex-wrap: wrap;
            /* justify-content: center; */
            align-items: center;

            .ck-dropdown__button {
                border-radius: 50%;
                background: #FFFFFF !important;
            }

            .ck-editor {
                width: 100%;
                display: flex;
                flex-direction: row-reverse;
            }

            .ck-editor__top {
                position: absolute;
                right: 7px;
                top: 9px;

                @media (max-width: 1400px) {
                    right: 4px;
                    top: 3px;
                }

                @media (max-width: 450px) {
                    display: none;
                }
            }

            .ck-dropdown__arrow{
                display: none;
            }

            .ck-toolbar{
                border: none;
                border-radius: 50% !important;
                padding: 0;
                background: white;
                height: 30px;
                width: 30px;
                //background-color: #e6e6e6;
            }

            .ck-editor__main{
                width: 100%;
            }

            .ck-content {
                min-height: 50px;
                margin-left: 10px;
                max-width: 100%;
                background-color: white;
                border-radius: 25px !important;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-right: 35px;

                @media (max-width: 450px) {
                    padding-right: 0;
                }

                li {
                    list-style-position: inside;
                }

                p {
                    //overflow: hidden;
                    margin: 0 10px;
                    //display: flex;

                    @media (max-width: 575px) {
                        //margin: 0;
                    }

                    &.ck-placeholder {
                        font-size: 12px;

                        @media(max-width: 370px) {

                            &::before {
                                display: none;
                            }
                        }
                        
                        /*&::before {
                            position: relative;
                            line-height: 12px;
                        }

                        br {
                            display: none;
                        }*/
                    }
                }

                text-area {
                    width: 100%;
                }

                @media (max-width: 1400px) {
                    min-height: 35px;
                }
            }

            .ck-form__header__label {
                visibility: hidden;
            }

            .ck-form__header__label:after{
                content:'Emojis'; 
                visibility: visible;
                display: block;
                position: absolute;
                padding: 5px;
                top: 2px;
            }
        }

        .btns-form {
            min-width: 160px;
            //margin-right: 15px;

            #input-any-file {
                display: none;
            }

            button {
                width: 26px;
                height: 26px;
                background-color: transparent;
                color: rgba(255,255,255,0.5);
                border: 0;
                font-size: 26px;
                margin-left: 20px;

                @media (max-width: 1400px) {
                    font-size: 22px;
                }

                svg {
                    color: ${colors.primary};
                }
            }

        }
    }
`;

export const InputContainer = styled.div`
    width: 100%;
    min-height: 75px;
    position: relative;
    background-color: #e6e6e6;

    @media (max-width: 1400px) {
            min-height: 50px;
        }


`

export const MenuConversa = styled.div`
    position:absolute;
    padding: 5px;
    top: 50px;
    right: 0px;
    z-index: 1050;
    display: flex;
    flex-direction: column;

    button{
        color: #fff;
        background-color: ${colors.primary};
        border: none;
        padding: 10px 20px;
        outline: none;
    }
`;

export const Blocked = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1050;

    .holder {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
    }

    @media (max-width: 1024px) {

        .holder {
            width: calc(100% - 400px);
            position: absolute;
            left: 400px;
        }
        
    }

    @media(max-width: 500px) {
        display: none;
    }

    img { 
        width: 30%; 
        border-radius: 10px; 
        margin-bottom: 50px; 

        @media (max-width: 1024px) {
            width: 60%;
        }
    }
    h2 { 
        color: ${colors.primary};
        text-align: center;

        @media (max-width: 675px) {
            font-size: 15px;
        }
    }

    .btnActions {
        display: flex;
        margin-top: 1rem;

        button:first-child {
            margin-right: 1rem;
        }
    }
`;

export const DropzoneContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;

`;

export const WrapCtaPagination = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;

    @keyframes spin {
        from {
            transform:rotate(0deg);
        }
        to {
            transform:rotate(360deg);
        }
    }

    button {
        background-color: white;
        border: 1px solid ${colors.primary};
        font-size: 18px;
        padding: 0 20px;
        height: 40px;
        border-radius: 5px;
        color: ${colors.primary};
        font-weight: normal;
        font-size: 14px;
        box-shadow: ${effects.boxshadow};

        svg {
            animation-name: spin;
            animation-duration: 1000ms;
            animation-iteration-count: infinite;
            animation-timing-function: linear; 
        }
    }
`;

export const LoadingWrap = styled.div`
  width: 100%;
  background-color: none;
  border-radius: 0.75rem;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
	color: ${colors.primary};

  h3 {
    margin-top: 15px;
  }

  svg {
    font-size: 25px;
  }

  .fa-spin {
    -webkit-animation: icon-spin 1s infinite linear;
    animation: icon-spin 1s infinite linear;
  }

  @-webkit-keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }

  @keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
`;
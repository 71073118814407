import api from '../services/api-geral';
import socket from '../services/socket';

export const room = async (name, method) => {
  if (method === 'join') {
    socket.emit('entrarSala', { room: name });
    socket.onclose = function () {
      setTimeout(setupWebSocket, 1000);
    };
  }

  if (method === 'leave') {
    socket.emit('sairSala', { room: name });
  }

  const setupWebSocket = () => {
    socket.emit('sairSala', { room: name });
    socket.emit('entrarSala', { room: name });
  }
};
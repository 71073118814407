import React, { useState, useEffect } from 'react';
import VizSensor from 'react-visibility-sensor';
import { FiBell, FiMaximize, FiMinimize } from 'react-icons/fi';
import { AiOutlineMenu } from 'react-icons/ai';
import { FaBirthdayCake, FaRegClipboard } from 'react-icons/fa';
import ConfettiExplosion from 'react-confetti-explosion';

import api from '../../services/api-geral';
import socket from '../../services/socket';
import { isLogged, sessionGet, sessionGetAll, clearSession, logout } from '../../session';

import { toggleFullscreen } from '../../util/ui';
import { room } from '../../util/socketFunctions';
import getFiles from '../../util/getFiles';
import log from '../../util/log';

import SocketNotificacoes from '../../socket/SocketNotificacoes';
import SocketConnectClient from '../../socket/SocketConnectClient';
import SocketDisconnectClient from '../../socket/SocketDisconnectClient';

import Notifications from '../Notifications';
import Options from '../Options';
import Aniversariantes from '../Aniversariantes';

import Default from '../../assets/default.png';
import LogoMerx from '../../assets/logo_branca.png';
import NotifySound from '../../assets/sounds/notify2.mp3'
import { Wrap } from './styles';
import ViewLembretes from '../Lembretes/ViewLembretes';

export default function Topbar({ showMenu, setShowMenu, showSubMenu, setShowSubMenu, isDropdown, showMobileMenu, setShowMobileMenu, fullTopBar = false }) {
  //BASE DO COMPONENTE DESENVOLVIDO PELO RYAN
  const [showNotificacoes, setShowNotificacoes] = useState(false);
  const [showOpcoes, setShowOpcoes] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [showNotes, setShowNotes] = useState(false);

  //DADOS DO USUARIO
  const [id] = useState(sessionGet('idUser'));
  const [nome, setNome] = useState('');
  const [dadosUsuario] = useState(sessionGetAll());
  const [srcImagemPerfil, setSrcImagemPerfil] = useState('');
  const [presence, setPresence] = useState();
  const [imgPerfil, setImgPerfil] = useState('');
  const [dataAniversario, setDataAniversario] = useState([]);
  const [lembretes, setlembretes] = useState([]);
  const [lembretesTreated, setlembretesTreated] = useState([]);
  const [statusColor, setStatusColor] = useState('');

  //NOTIFICAÇOES
  const [isNotify, setIsNotify] = useState(false);
  const [countNotificacoes, setCountNotificacoes] = useState(0);
  const [countNotificacoesNotes, setCountNotificacoesNotes] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [noteNotifications, setNoteNotifications] = useState([]);
  
  // VERIFICAR EXISTENCIA DE ANIVERSARIANTES
  const [existeAniversariantes, setExisteAniversariantes] = useState(false);
  const [aniversarianteHoje, setAniversarianteHoje] = useState(false);
  const [isExploding, setIsExploding] = React.useState(false);
  const [showAniversariantes, setShowAniversariantes] = useState(false);
  const [isFirstVisit, setIsFirstVisit] = useState(false);
  const [expirationTime, setExpirationTime] = useState(null);

  //PEGAR A ID DA EMPRESA
  const idEmpresa = sessionGet('idEmpresa');

  //GERENCIAMENTO DAS NOTIFICAÇOES
  useEffect(() => {
    getNotifications();
    getNotes()
    checkUserMobile(); //Descrição na função

    if (!isLogged()) {
      localStorage.setItem('redirect', window.location);
      window.location = '/';
    };
    setNome(sessionGet('nomeUser'));

    getUsuario();
    verifyBirth();

    room(`empresa${sessionGet('idEmpresa')}`, 'join');
    room(`setor${sessionGet('idSetor')}`, 'join');
    room(`usuario${sessionGet('idUser')}`, 'join');

    socket.on('setStatusOn', async () => {
      const response = (await api.put('/users', { id: sessionGet('idUser'), presence_status: 1 })).data;
    });

    socket.on('customStatusChanged', () => {
      window.setTimeout(() => {
        getUsuario();
      }, 2000)
    });

    return () => {
      room(`empresa${sessionGet('idEmpresa')}`, 'leave');
      room(`setor${sessionGet('idSetor')}`, 'leave');
      room(`usuario${sessionGet('idUser')}`, 'leave');
      socket.off('userConnectClient');
      socket.off('userDisconnectClient');
      socket.off('setStatusOn');
    }

  }, []);
  
  useEffect(() => {
    socket.on('newNote', (reminder) => {
        // Handle the new reminder data received from the server
        getNotificationsLembretes()
        const audio = new Audio(NotifySound);
        audio.volume = 0.4;
        audio.play();
        getNotes()
    });
    
    socket.on('editNote', (reminder) => {
      // Handle the new reminder data received from the server
      getNotificationsLembretes()
  });

    return () => {
        socket.off('newNote', 'editNote');
    };
}, []);

  useEffect(() => {
    if (countNotificacoes > 0) changeFavicon(process.env.PUBLIC_URL + '/favicon_notify.ico');
    else changeFavicon(process.env.PUBLIC_URL + '/favicon.ico');
  }, [countNotificacoes]);

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
    };
    checkUserMobile();
    window.addEventListener("resize", handleWindowResize);
  }, [width]);

  const checkUserMobile = () => {
    //Função criada para checar se o usuário esta no celular, caso esteja o menu inicia minimizado;
    if (width <= 1024) {
      setShowMenu(false);
    } else {
      setShowMenu(true);
    }
  }

  function changeFavicon(img) {
    var favicon = document.querySelector('link[rel="shortcut icon"]');

    if (!favicon) {
      favicon = document.createElement('link');
      favicon.setAttribute('rel', 'shortcut icon');
      var head = document.querySelector('head');
      head.appendChild(favicon);
    }

    favicon.setAttribute('type', 'image/png');
    favicon.setAttribute('href', img);
  }

  const getUsuario = async () => {
    const response = (await api.get(`/users/${sessionGet('idUser')}`)).data;
    setPresence(response.presence_status);

    if (response.statusCustom !== null) {
      setStatusColor(response.statusCustom.status_color);
    } else {
      setStatusColor('online')
    }

    if (response.id_file) {
      setImgPerfil(getFiles(response.files.path));
    } else {
      setImgPerfil(Default);
    }
  };

  // <div className="presence"><span className={(usuario.presence_status === 1) ? ((usuario.statusCustom != null) ? usuario.statusCustom.status_color : 'online') : 'offline'}></span></div>

  const getNotes = async () => {
    try {
        const response = await api.get(`/lembretes/${idEmpresa}`).then(({ data }) => {
          const dataAtual = new Date();
          const lembretesAtivos =  data.lembretes.filter((lembrete) => {
            // Converte a data de `start` do lembrete para um objeto Date
            const dataStart = new Date(lembrete.start);
            return  dataAtual  >= dataStart;
          });
          setlembretes(lembretesAtivos)
        })
        getNotificationsLembretes()
    } catch (error) {
        console.error("Error posting data: ", error);
    }            
  
  }

  const getNotificationsLembretes = async () => {
    const response = (await api.get(`/lembrete-notify/${idEmpresa}/${sessionGet('idUser')}/`)).data;
    setNoteNotifications(response.notification)
    setCountNotificacoesNotes(response.notification.length)   
  };

  const getNotifications = async () => {
    const response = (await api.get(`/notifications/5/0`)).data;
    setNotifications(response.notificacoes);
    setCountNotificacoes(response.count_read);
  };

  const verifyBirth = async () => {
    const birthDate = (await api.get(`/users/verify-birthday`)).data;
    birthDate.existsMonth ? setExisteAniversariantes(true) : setExisteAniversariantes(false);
    birthDate.existsDay ? setAniversarianteHoje(true) : setAniversarianteHoje(false);
    const hasVisited = localStorage.getItem('hasVisited');
    if (hasVisited === 'true') {
      setIsFirstVisit(false);
    } else {
      setIsFirstVisit(true);
    }
  }

  // Temporizador para limpar a notificação de aniversariante
  useEffect(() => {
    const checkExpiration = setInterval(() => {
      const currentTime = new Date().getTime();
      if (expirationTime && currentTime > expirationTime) {
        localStorage.setItem('hasVisited', false);
        setExpirationTime(null);
      }
    }, 1000 * 60 * 60 * 24); // Executa a verificação a cada 24 horas

    // Define a hora de expiração inicial
    const expirationDate = new Date();
    expirationDate.setHours(24, 0, 0, 0); // Define a hora do servidor como 24h
    setExpirationTime(expirationDate.getTime());

    // Limpa o intervalo quando o componente é desmontado
    return () => clearInterval(checkExpiration);
  }, [expirationTime]);

  const getDataAniversario = async () => {
    const response = (await api.get(`/users/birthday`)).data;
    setDataAniversario(response.birthDate);
  };

  const mostraNotificacoes = async () => {
    if (showNotificacoes) {
      setShowNotificacoes(false);
    } else {
      setShowNotificacoes(true);
      setShowAniversariantes(false);
      setShowOpcoes(false);
      setShowNotes(false)
    }
  };

  const mostraLembretes = async () => {
    if (showNotes) {
      setShowNotes(false);
    } else {
      setShowNotes(true)
      setShowNotificacoes(false);
      setShowAniversariantes(false);
      setShowOpcoes(false);

    }
  };

  const mostraAniversario = async () => {
    if (showAniversariantes) {
      setShowAniversariantes(false);
      setIsExploding(false);
    } else {
      getDataAniversario();
      setShowAniversariantes(true);
      setShowNotificacoes(false);
      setIsExploding(true);
      setShowOpcoes(false);
      setShowNotes(false)

    }
    localStorage.setItem('hasVisited', true);
  };

  const removerConfete = () => {
    setIsExploding(false);
    setShowAniversariantes(false)
  }


  return (
    <>
      <SocketNotificacoes load={getNotifications} enablePushNotification={true} notificationTittle={"Nova mensagem no Merx"} notificationBody={"Você recebeu uma nova mensagem no chat!"} />
      <SocketConnectClient load={verifyBirth} />
      <SocketDisconnectClient />

      {(showNotificacoes) && <Notifications loadNotifications={getNotifications} setShowNotificacoes={mostraNotificacoes} notifications={notifications} />}
      {(showOpcoes) && <Options setShowOpcoes={setShowOpcoes} />}
      {(showAniversariantes) && <Aniversariantes setShowAniversariantes={mostraAniversario} dataAniversario={dataAniversario} />}
      {(showNotes && <ViewLembretes setShowNotes={setShowNotes} lembretes={lembretes} reload={getNotes} flag={noteNotifications} />)}

      <Wrap fullTopBar={fullTopBar}>
        {/* {!window.location.href.includes('conversas') ? <ChatButton /> : ''} */}
        <div className='left'>
          {/* <button onClick={() => toggleMenus()} className='btnmenu'>
            <FiMenu />
          </button> */}
        </div>
        <div className='middle'>
          <div className='logo'>
            <h1>
              <a onClick={() => { (sessionGet('planoEmpresa')) === 'merxGratis' ? window.location = "/painel-de-controle" : window.location = "/feed" }}>
                <img src={LogoMerx} />
              </a>
            </h1>
          </div>
        </div>
        <div className='sm-right'>
        <button onClick={() => { mostraLembretes(); removerConfete() }} className='btntopbar'>
          {(countNotificacoesNotes > 0) ? <span>{countNotificacoesNotes}</span> : ""}
            <FaRegClipboard />
          </button>
          {existeAniversariantes &&
            <>
              {isExploding && (<ConfettiExplosion height={1000} force={0.4} width={450} particleCount={40} />)}
              <button onClick={() => { mostraAniversario(); setIsFirstVisit(false) }} className='btntopbar'>
                <FaBirthdayCake />
                {(aniversarianteHoje) &&
                  (isFirstVisit) &&
                  <span className="notification-birth-today"></span>
                }
              </button>
            </>
          }
          <button onClick={() => { mostraNotificacoes(); removerConfete() }} className='btntopbar'>
            {(countNotificacoes > 0) ? <span>{countNotificacoes}</span> : ""}
            <FiBell />
          </button>
          <button className="overwatch" onClick={() => { setShowMobileMenu(!showMobileMenu); removerConfete() }}>
            <AiOutlineMenu />
          </button>
          <button onClick={() => { setShowOpcoes(!showOpcoes); removerConfete(); setShowNotificacoes(false) }} className='btnuser'>
            <span>{nome}</span>
            <img src={imgPerfil} />
            <div className="presence"><span className={(presence === 1) ? 'online' : 'offline'}></span></div>
          </button>
        </div>

        <div className='right'>
          <button onClick={() => { mostraLembretes(); removerConfete() }} className='btntopbar'>
          {(countNotificacoesNotes > 0) ? <span>{countNotificacoesNotes}</span> : ""}
            <FaRegClipboard />
          </button>
          {existeAniversariantes &&
            <>
              {isExploding && (<ConfettiExplosion height={1000} force={0.4} width={450} particleCount={40} />)}
              <button onClick={() => { mostraAniversario(); setIsFirstVisit(false) }} className='btntopbar'>
                <FaBirthdayCake />
                {(aniversarianteHoje) &&
                  (isFirstVisit) &&
                  <span className="notification-birth-today"></span>
                }
              </button>
            </>
          }
          <button onClick={() => { toggleFullscreen(fullscreen, setFullscreen); removerConfete() }} className='btntopbar'>
            {(fullscreen) ? <FiMinimize /> : <FiMaximize />}
          </button>
          <button onClick={() => { mostraNotificacoes(); removerConfete() }} className='btntopbar'>
            {(countNotificacoes > 0) ? <span>{countNotificacoes}</span> : ""}
            <FiBell />
          </button>
          <button onClick={() => { setShowOpcoes(!showOpcoes); removerConfete(); setShowNotificacoes(false) }} className='btnuser'>
            <span>{nome}</span>
            <img src={imgPerfil} />
            <div className="presence"><span className={(presence === 1) ? statusColor : 'offline'}></span></div>
          </button>
        </div>
      </Wrap>
    </>
  );
}

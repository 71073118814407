import styled from 'styled-components';

import colors from '../../presets/colors';

export const Container = styled.div`
    width: 100%;
    padding: 10px 25px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    /* border-top: 1px solid ${colors.border}; */
    height: auto;

    @media (max-width: 450px) {
        padding: 10px 15px;
    }


    /* @media (max-width: 450px) {
        width: calc(100% - 25px);
    } */
    .content-wrapper{
        display: flex;
        width: 100%;
    }
    .content-gray{
        background-color: ${colors.bgsecondary};
        width: 100%;
        border-radius: 20px;
        padding: 10px;

        @media (max-width: 1450px) {
          padding-right: 30px;
        }

        @media (max-width: 450px) {
          padding-right: 30px;
        }
    }
    
    img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-right: 10px;
            object-fit: cover;

            @media (max-width: 450px) {
                width: 30px;
                height: 30px;
            }

        }

    a {
        text-decoration: none;
        color: #551aa5;
    }

    .img-comment {
        width: fit-content;
        height: 250px;
        max-width: 100%;
        object-fit: cover;
        object-position: center;
        margin-top: 10px;
        cursor: pointer;
        border: 2px solid ${colors.border};
        border-radius: 5px;
    }

    .mention {
        word-break: unset;
        color: #551aa5;
    }

    .autor {
        display: flex;
        align-items: center;
        position: relative;

        .user-comment {
            display: flex;
            align-items: center;
        
            &:hover {
                .modal-user {
                    padding: 10px;
                    display: flex;
                    top: 42px;
                }
            }

            strong {
                a {
                    color: black;
                }
            }
        }



        strong {
            font-size: 14px;

            small {
                font-weight: normal;
                font-size: 12px;
            }

            span {
                color: #878787;
                font-weight: normal;
                font-size: 12px;
            }
        }

        .modal-user {
            display: none;

            p {
                font-size: .9rem;
                margin-top: 0;
                margin-bottom: 5px;
            }

            a {
                font-size: .9rem;
            }
        }
    }

    p {
        text-align: justify;
        font-size: 14px;
        word-wrap: break-word;

    }


    .actions {
        width: calc(100% - 60px);
        font-size: 14px;
        display: flex;
        align-items: center;
        /* margin-top: 5px; */
        /* margin-bottom: 10px; */
        margin-left: 57px;
        justify-content: space-between;
        /* background-color: rgba(0, 0, 0, 0.03); */
        padding: 5px 10px;
        border-radius: 10px;

        @media (max-width: 450px) {
            margin-left: 35px;
        }

        .left {
            display: flex;

            .open-comment{
                display: flex;
                align-items: center;
                margin-right: 20px;
                background-color: transparent;
                border: 0;
                cursor: pointer;

                &:hover {
                    font-weight: bold;
                }

                @media (max-width: 450px) {
                    width: 130px;
                    margin-right: 0px;
                }
                
                svg {
                    margin-right: 5px;
                    color: ${colors.primary};
                }

                span {
                    font-size: 13px;
                    color: ${colors.primary};
                    @media (max-width: 450px) {
                        font-size: 12px;
                    }
                }
            }
        }

        .cta-like {
            display: flex;
            align-items: center;
            margin-right: 20px;
            background-color: transparent;
            border: 0;
            cursor: pointer;

            &:hover {
                font-weight: bold;
            }
            
            svg {
                margin-right: 5px;
                color: ${colors.primary};
            }

            span {
                font-size: 13px;
                color: ${colors.primary};
                @media (max-width: 450px) {
                        font-size: 12px;
                    }
            }
        }

        .right{
            display: flex;
            justify-content: center;
            .count-likes{
                display: flex;
                align-items: center;
                .likes-comentarios{
                    color: ${colors.primary};
                    margin-right: 2px;
                }
                .wrap-icon{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: ${colors.primary};
                    border-radius: 50%;
                    width: 19px;
                    height: 19px;
    
                    svg {
                    font-size: 12px;
                    color: ${colors.bgprimary};
                }
                }
            }
            .show-likes {
                /* margin-top: 25px; */
                margin-top: 10px;
                position: relative;

                span {
                    color: ${colors.primary};
                    font-size: 11px;
                }     
            }
            .likes {
                position: absolute;
                right: 0;
                display: none;
                bottom: 20px;
                background-color: rgba(0, 0, 0, 0.8);
                padding: 10px;
                border-radius: 5px;
                min-width: 180px;
                flex-direction: column;
                
                &:hover {
                    display: flex;
                }

                & > span {
                    color: white;
                    font-size: 12px;
                    letter-spacing: 1px;
                    margin-bottom: 3px;
                }
            }
            &:hover .likes{
                  display: flex;
                }
        }

    }

    .options{
        position: absolute;
        right: 25px;
        
        @media (max-width: 1450px) {
          right: -25px;
          top: -1px;
        }

        @media (max-width: 1000px) {
          right: -25px;
        }

        button {
            background: none;
            height: 30px;
            width: 30px;

            svg{
                @media (max-width: 450px) {
                font-size: 22px;
                }
            }
        }
    }

    .likes-comentarios:hover {
        cursor: pointer;
    }

`;
import React, { useState, useEffect } from 'react';

import Page from '../../components/Page';
import NFeed from '../../components/NFeed';
import { Container } from './styles';
import CentralMidia from '../../components/CentralMidia';

export default function BemVindo() {
  const [showMidias, setShowMidias] = useState(false);
  const [image, setImage] = useState('');
  const [hasChangesModal, setHasChangesModal] = useState(false);

  const [imageId, setImageId] = useState('');
  const [imagePath, setImagePath] = useState('');

  useEffect(() => {
    setShowMidias(false);
  }, [image]);

  return (
    <>
      <CentralMidia files="img" format="plugin" opened={showMidias} close={() => setShowMidias(false)} setFile={id => setImage(id)} />
      <Page hasChanges={hasChangesModal} setHasChanges={setHasChangesModal} imageId={imageId} setImageId={setImageId} imagePath={imagePath} setImagePath={setImagePath} content={(
        <Container>
          <div className='wrapfeed'>
            <NFeed image={image} showMidias={(option) => setShowMidias(option)} hasChangesModal={hasChangesModal} setHasChangesModal={setHasChangesModal} setImageId={setImageId} setImagePath={setImagePath} />
          </div>
        </Container>
      )} />
    </>
  );
}
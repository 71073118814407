import React, { useState, useEffect, useRef } from 'react';

import Page from '../../components/Page';
import NPerfilPost from '../../components/NPerfilEPosts';

import TitleAndBreadcrumb from '../../components/TitleAndBreadcrumb';

import { Container, Head } from './styles';

export default function PerfilPublico() {
  const [image, setImage] = useState('');
  const [imageId, setImageId] = useState('');
  const [imagePath, setImagePath] = useState('');

  const [showMidias, setShowMidias] = useState(false);
  const [hasChangesModal, setHasChangesModal] = useState(false);

  return (
    <Page content={(
      <>
        <Head>
          <TitleAndBreadcrumb id="titulo" title='Perfil' breadcrumbs={[]} format='enabled-tools' />
        </Head>
        <Container>
          <NPerfilPost
            image={image}
            showMidias={(option) => setShowMidias(option)}
            setHasChangesModal={setHasChangesModal}
            setImageId={setImageId}
            setImagePath={setImagePath}
          />
        </Container>
      </>
    )} />
  );
}
import styled from 'styled-components';

import colors from '../../../presets/colors';
import effects from '../../../presets/effects';

export const Toggles = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  width: 85%;
  justify-content: space-between;

  .whatsapp-li {
    margin: 0 50px;
  }

  li {
    width: 100%;
    background-color: white;
    height: 60px;
    padding: 0 10px;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    cursor: pointer;
    color: ${colors.text_primary};

    &:last-child {
      margin: 0;
    }

    .title {
      display: flex;
      width: calc(100% - 50px);
      height: 100%;
      align-items: center;

      .wrap-icon {
        background-color: white;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-right: 10px;
        box-shadow: ${effects.boxshadow};

        svg {
          color: ${colors.primary};
          font-size: 30px;
        }
      }

      svg {
        position: absolute;
      }
    }
    
    .toggle-funcionalidade {
      width: 50px;
      height: 30px;
      box-shadow: ${effects.boxshadow};
      background-color: white;
      border-radius: 15px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 5px;
      cursor: pointer;

      .indicador {
        width: 20px;
        height: 20px;
        background-color: #999;
        border-radius:  10px;
      }

      &.loading {
        justify-content: center;
      }
      
      &.active {
        justify-content: flex-end;

        .indicador {
          background-color: #00cc66;
        }
      }
    }
  }
`;

export const WrapPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 750px) {
    width: 95%;
  }

  .mobile-preview {
    
    @media (max-width: 750px) {
      width: 100%;
      display: flex;
      place-content: center;
    }
  }

  .definicoes {
    place-content: center !important;
  }

  .painel-preview {
    display: flex;
    align-items: start;
    place-content: center;

    @media (max-width: 750px) {
      flex-direction: column;
    }
  }

  .painel-configuracao-site {
    width: 50%;
    padding: 0px 20px 20px 20px;
    margin: 0;
    justify-content: flex-start;
    flex-direction: column;
    border-radius: unset;
    box-shadow: none;
    background-color: unset;

    @media (max-width: 750px) {
      width: 100%;
    }

    @media(max-width: 575px) {
      padding: 10px;
    }

    &.metadados {
      height: auto;
    }

    &.aparencia {
      width: 45%;
      height: 600px;
      padding: 45px;
      margin: 0px 10px 0px 0px;
      justify-content: center;

      form {
        height: 100%;
        margin: 0;
      }
    }

    h2 {
      text-align: center;
    }

    form {
      width: 100%;
      margin-bottom: 25px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      & > label {
        width: 100%;
      }

      h2 {
        margin-bottom: 25px;
      }

      .wrap-logo {
        width: 100%;
        margin-bottom: 25px;
        display: flex;
        align-items: center;

        @media(max-width: 575px) {
          flex-direction: column;
        }

        .company-profile-settings {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          place-content: center;

          .wrap-preferences {
            width: 100%;
            margin-top: 20px;
            display: flex;
            align-items: center;

            .border-color {
              width: 115px;
              margin-left: 20px;
            }

            .toggle-preference {
              width: 175px;
              background-color: #fafafa;
              height: 35px;
              padding: 0 8px;
              border: 1px solid #d0d0d0;
              border-radius: 20px;
              display: flex;
              align-items: center;
              cursor: pointer;
              color: ${colors.text_primary};

              .title {
                font-size: 10px;
                display: flex;
                height: 100%;
                align-items: center;

                .wrap-icon {
                  background-color: white;
                  width: 20px;
                  height: 20px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 50%;
                  margin-right: 5px;
                  //box-shadow: ${effects.boxshadow};

                  svg {
                    color: ${colors.primary};
                    font-size: 25px;
                  }
                }
                
                svg {
                  position: absolute;
                }
              }
      
              .toggle-funcionalidade {
                position: relative;
                right: -22px;
                width: 35px;
                height: 20px;
                //box-shadow: ${effects.boxshadow};
                background-color: white;
                border: 1px solid #d0d0d0;
                border-radius: 15px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 0 5px;
                cursor: pointer;

                .indicador {
                  width: 15px;
                  height: 15px;
                  background-color: #999;
                  border-radius:  10px;
                }

              &.loading {
                justify-content: center;
              }
        
              &.active {
                justify-content: flex-end;

              .indicador {
                background-color: #00cc66;
              }
            }
          }
        }
      }
    }

        .image {
          width: 125px;
          height: 125px;
          background-color: ${colors.bgsecondary};
          border-radius: 50%;
          position: relative;
          cursor: pointer;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 15px;

          @media(max-width: 575px) {
            margin-bottom: 10px;
          }

          svg { 
            font-size: 30px; 
          }

          .image-filter {
            width: 100%;
            height: 100%;
            background-color: ${colors.bgsecondary};
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
              opacity: .75;
            }
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        #input-company-profile-image {
          display: none;
        }

        .wrap-buttons {
          width: 100%;
          display: flex;
          justify-content: space-around;

          @media(max-width: 1200px) {
            flex-direction: column;
            align-items: flex-end;

            button {
              width: 75%;

              &.btn-primary {
                margin-bottom: 10px;
              }
            }
          }

          @media(max-width: 575px) {
            width: 100%;

            button {
              width: 100%;
            }
          }

          button {
            height: 40px;
            padding: 0 20px;
            border-radius: 25px;
            border: 2px solid ${colors.primary};

            &.btn-primary {
              background-color: ${colors.primary};
              color: white;
            }

            &.btn-secondary {
              margin-left: 20px;
              background-color: white;
              color: ${colors.primary};
              font-weight: bold;
            }
          }
        }
      }

      .infos {
        width: 100%;

        .label-infos {
          margin-bottom: 25px;
        }

        & > label { margin-bottom: 10px; display: block; width: 100%; }

        .input-style {
          height: 40px;
          border-radius: 5px;
          margin: 0 0 20px;
        }

        textarea {
          border: 1px solid rgba(0,0,0,0.2);
          height: 200px;
          border-radius: 5px;
          width: 100%;
          padding: 5px;
          font-size: .8rem;
          margin-bottom: 25px;
        }

        h3 {
          margin-bottom: 25px;
          color: ${colors.text_primary};
        }
      }

      .type-link {
        width: 100%;
        list-style: none;
        display: flex;
        justify-content: flex-start;
        padding: 0;
        margin-bottom: 25px;

        li {
          width: 30px;
          height: 30px;
          color: ${colors.text_primary};
          background-color: #f0f0f0;
          box-shadow: ${effects.boxshadow};
          border-radius: 5px;
          margin-right: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          svg { font-size: 20px; }

          &.active { border: 2px solid gray; padding: 2px; }
          &.facebook { background-color: #0d88f0; color: white; }
          &.instagram { background-color: #ed4956; color: white; }
          &.twitter { background-color: #1da1f2; color: white; }
          &.whatsapp { background-color: #34af23; color: white; }
          &.youtube { background-color: #ff0000; color: white; }
          &.linkedin { background-color: #0a66c2; color: white; }
        }
      }

      label {
        margin-bottom: 20px;
      }

      .input-style {
        height: 40px;
        border-radius: 5px;
        margin: 0 0 20px;
      }

      .btn-link {
        width: auto;
        height: 45px;
        border-radius: 25px;
        padding: 0 20px !important;
        display: flex;
        justify-content: center;
      }
    }

    .list-links {
      width: 100%;
      list-style: none;
      display: flex;
      flex-direction: column;
      padding-right: 5px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 20px;
        background-color: #d0d0d0;
      }

      li {
        display: flex;
        margin-bottom: 10px;

        .arrow-items {
          margin-right: 5px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;

          @media(max-width: 575px) {
            width: 20px;
          }

          svg {
            font-size: 20px;
            color: ${colors.primary};
            cursor: pointer;
          }
        }

        .content-item {
          background-color: white;
          width: 100%;
          border-radius: 5px;
          border: 1px solid #d0d0d0;
          padding: 10px;
          position: relative;
          display: flex;
          align-items: center;

          @media(max-width: 575px) {
            width: calc(100% - 20px);
          }

          .buttons {
            display: flex;
            position: absolute;
            right: 10px;
            background: white;

            .btn-edit {
              width: 30px;
              height: 30px;
              background: none;
              border: 0;
              color: #333333;
              display: flex;
              align-items: center;
              place-content: center;

              svg {
                font-size: 18px;
              }
            }

            .btn-delete {
              width: 30px;
              height: 30px;
              background: none;
              border: 0;
              color: ${colors.btn_danger};
              display: flex;
              align-items: center;
              place-content: center;

              svg {
                font-size: 25px;
              }
            }
          }
          
          .wrap-icon {
            background-color: #f0f0f0;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            margin-right: 10px;
            box-shadow: ${effects.boxshadow};

            @media(max-width: 575px) {
              display: none;
            }

            img {
              mix-blend-mode: multiply;
              width: 40px;
              height: 40px;
            }

            .default {
              margin: 0;
              padding: 0;
              outline: 0;
              box-sizing: border-box;
              align-items: center;
              margin-top: 25%;
            }

            .btn-remove {
              color: red;
              position: absolute;
              margin-left: 35px;
              top: 0;
              background-color: transparent;
              border: transparent;
            }

            .btn-add {
              color: green;
              position: absolute;
              margin-left: 35px;
              top: 0;
              background-color: transparent;
              border: transparent;
            }
            
            svg { font-size: 20px; }

            &.facebook { background-color: #0d88f0; color: white; }
            &.instagram { background-color: #ed4956; color: white; }
            &.twitter { background-color: #1da1f2; color: white; }
            &.whatsapp { background-color: #34af23; color: white; }
            &.youtube { background-color: #ff0000; color: white; }
            &.linkedin { background-color: #0a66c2; color: white; }
          }

          .info {
            display: flex;
            flex-direction: column;

            span {
              font-weight: 500;
              font-size: 14px;
            }
            
            small { font-size: 12px; color: gray; }

            @media(max-width: 575px) {
              overflow: hidden;

              small {
                overflow-wrap: break-word;
              }
            }
          }
        }
      }
    }
  }
`
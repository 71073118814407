import styled from 'styled-components';

import colors from '../../../presets/colors';

export const WrapForm = styled.form`
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .form-header {
    border-bottom: 1px solid #d7d7d7;
    padding: 25px;

    h2 {
      color: ${colors.texts};
    }
  }

  .form-body {
    height: 100%;
    padding: 25px;
    overflow-y: scroll;

    .permissoes {
      border: 1px solid rgba(0, 0, 0, 0.2);
      position:relative;
      padding: 10px;

      .titulo {
        background-color: white;
        position: absolute;
        top: -10px;
        left: 10px;
        font-size: 14px;
        padding: 0 5px;
        z-index: 1050;
        color: rgba(0, 0, 0, 0.5);
        transition: color .5s;
      }
    }
  }

  .form-footer {
    border-top: 1px solid #d7d7d7;
    padding: 25px;
    display: flex;
    justify-content: center;
  }
  
`;

export const Setores = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.2);
  position:relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  label {
    background-color: white;
    position: absolute;
    top: -10px;
    left: 10px;
    font-size: 14px;
    padding: 0 5px;
    z-index: 1050;
    color: rgba(0, 0, 0, 0.5);
    transition: color .5s;
  }

  table {
    width: 100%;
    padding: 10px;
    border-spacing: 0;

    thead, tbody {
      text-align: center;
    }

    thead {
      font-weight:600;
      padding: 10px;
    }

    tbody {
      tr:nth-child(even) {
        background-color: #f2f2f2;
      }
    }

    td {
      border: 0;

      &.actionstable {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .btn-add {
    background: ${colors.btn_primary};
    border-radius: 5px;
    width: 35px;
    height: 35px;
    margin-bottom: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    & > div {
      width: 100%;
      height: 100%;
      margin: 0;
      opacity: 0;
      z-index: 1;

      & > div {
        border-radius: 0;
        height: 100%;
        padding: 0;
        position: absolute;
        top: 0;
      }
    }

    svg {
      color: #fff;
      font-size: 1.7rem;
      position: absolute;
    }
  }
`;

export const ListaSetor = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  em {
    width: 80%;
    color: #000;
    margin-bottom: 5px;
    display: block;
  }

  #table-setor {
    width: 100%;
    color: #000;

    tr {
      background-color: rgba(0,0,0,0.3);
      /* display: flex;
      align-items: center;
      justify-content: center; */

      &.header-table {
        font-weight: bold;

        th {
          padding: 5px 10px;
          background-color: rgba(0,0,0,0.2);
          width: 50%;
        }
      }

      td {
        text-align: center;
        padding: 5px 10px;

        input {
          width: 18px;
          height: 18px;
          margin: 0;
        }

        .ckb {
          border: 2px solid rgba(255, 255, 255, 0.3);
          border-radius: 25px;
          margin-right: 5px;
          width: 15px;
          height: 15px;
          position: relative;
          box-sizing: content-box;
          cursor: pointer;

          &.in::after {
            width: 11px;
            height: 11px;
            position: absolute;
            left: 2px;
            top: 2px;
            content: "";
            background-color: rgba(255,255,255,0.3);
            border-radius: 25px;
          }
        }
      }
    }
  }

  .btn-add {
    background-color: #5c77ff;
    border-radius: 5px;
    width: 35px;
    height: 35px;
    margin-top: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    & > div {
      width: 100%;
      height: 100%;
      margin: 0;
      opacity: 0;
      z-index: 1;

      & > div {
        border-radius: 0;
        height: 100%;
        padding: 0;
        position: absolute;
        top: 0;
      }
    }

    svg {
      color: #fff;
      font-size: 1.7rem;
      position: absolute;
    }
  }
`;

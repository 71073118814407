import React, { useState, useEffect, forwardRef } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { RiFile2Fill, RiArrowDownLine } from 'react-icons/ri';
import { FaReply, FaCopy, FaTrashAlt } from 'react-icons/fa';

import api from '../../services/api-geral';

import { formatDate } from '../../util/formats';
import getFiles from '../../util/getFiles';

import log from '../../util/log';

import Swal from 'sweetalert2';

import { Container, ActionMessages, BackgroundModalAction, ModalAction } from './styles';
import { RepliedMessage } from '../RepliedMessage';

const MensagemChat = forwardRef(({getMessagesHist, messageIndex, replyMessageChat, copyMessageChat, messageRef, msgs, id_message, message, replied_message, id_from, name_from, id_to, name_to, data, path, setFullImg, setFullImgPath, setImgExterna, atendimento = false, nameFile = null, typeFile }) => {
    const [showOptions, setShowOptions] = useState(false);
    const [name, setName] = useState('');

    const pathImg = function (el) {
        setImgExterna(el.src);
        setFullImg(true);
    }

    const isTo = id_from !== id_to

    useEffect(() => {
        const el = document.getElementById(id_message);
        if (el !== null) {
            el.onclick = () => pathImg(el);
        }
    }, [])

    useEffect(() => {
        if (nameFile !== '' && nameFile !== null) {
            if (nameFile.length > 30) {
                var limit = nameFile.substring(0, 30);
                setName(limit + '...');
            } else {
                setName(nameFile);
            }
        }
    }, [msgs])


    const tratarString = (texto) => {
        texto = message;
        texto = texto.replace(/&nbsp;/g, ' ');
        texto = texto.replace(/<p> <\/p>/g, '<p>&nbsp;</p>');
        texto = texto.replace(/<br> <\/p>/g, '<br>&nbsp;</p>');

        var aux = texto.split(' ');

        aux.map((word, index) => {
            if (word.includes('https://')) {
                if (word.includes('href="')) {
                    var part1 = word.split('">');

                    if (part1.length > 1) {
                        var part2 = part1[1].split('</a>');

                        if (part2[0].length > 30) {
                            var res = part2[0].substr(0, 30) + "...";
                            const text = part1[0] + '">' + res + "</a>" + part2[1];
                            aux[index] = text;
                        }
                    }
                } else if (!word.includes('youtube.com') && !word.includes('youtu.be') && !word.includes('src="')) {
                    var link = '';
                    var pre = '';
                    var auxPos = '';
                    var pos = '';
                    var href = '';

                    pre = word.split('https');
                    auxPos = pre[1];
                    pre = pre[0];

                    auxPos = auxPos.split('<');

                    link = 'https' + auxPos[0];
                    href = link;

                    for (let i = 1; i < auxPos.length; i++) {
                        pos = pos + '<' + auxPos[i];
                    }

                    if (link.length > 30) {
                        link = link.substr(0, 30) + "...";
                    }

                    var finalLink = `<a target="_blank" rel="noopener noreferrer" href="${href}">${link}</a>`;

                    finalLink = finalLink.replace(/&nbsp;/g, '');

                    aux[index] = pre + finalLink + pos;
                } else if (word.includes('src="')) {
                    aux[index] = `id=${id_message} ` + word;
                }
            } else if (word.includes('href=') && word.includes('conversas')) {
                aux[index] = 'class="marcacao-link" ' + word;
            } else if (word.includes('src="data:image')) {
                aux[index] = `id=${id_message} ` + word;
            }
        })

        var textFinal;

        aux.map((word, index) => {
            if (index == 0) {
                textFinal = word;
            } else {
                textFinal = textFinal + " " + word;
            }
        })

        texto = textFinal;

        return texto
    }

    useEffect(() => {
        const links = document.querySelectorAll('a[href^="www"]');
        links.forEach((link) => {
            const href = link.getAttribute('href');
            link.setAttribute('href', 'https://' + href);
        });
    }, []);

    const deleteMessage = async () => {
        Swal.fire({
            title: 'Atenção',
            text: 'Tem certeza que deseja excluir a mensagem?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Excluir',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                     
                if (atendimento) {
                    const response = (await api.delete(`/msgs-at/${id_message}`)).data;
                } else {    
                    const response = (await api.delete(`/msgs/${id_message}`)).data;
                }
              
                log('excluiu uma mensagem no chat interno.');
                await new Promise(resolve => setTimeout(resolve, 500));
                Swal.fire({
                    icon: 'success',
                    title: 'Sua mensagem foi excluida',
                    showConfirmButton: false,
                    timer: 1500
                  })
                    return
            } else {
                return
            }
        });
            setShowOptions(false);
    }

 
    const download = () => {
        var url = getFiles(path);
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function () {
            var a = document.createElement('a');
            a.href = window.URL.createObjectURL(xhr.response); // xhr.response is a blob
            a.download = nameFile; // Set the file name.
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            //delete a;
        };
        xhr.open('GET', url);
        xhr.send();
    }

    const replyMessage = () => {
        replyMessageChat(messageIndex);
        
        setShowOptions(false);
    }

    const copyMessage = () => {
        copyMessageChat(messageIndex);

        setShowOptions(false);
    }

    return (
        <Container ref={messageRef} className={isTo ? "me" : "you"} hasChatAtendimento={atendimento}>
            {replied_message ? <RepliedMessage onClick={() => getMessagesHist(0, null, id_to, replied_message.id)} id_from={replied_message.id_from} id_to={id_to} replied_message={replied_message.message} message={replied_message} name_from={name_from} name_to={name_to} /> : null}
            <div className="msg-holder" key={message}>
                {(path !== "" && path !== null && path !== undefined) ? (
                    typeFile === 'image' ? <img onClick={(e) => { setImgExterna(''); setFullImg(true); setFullImgPath(path) }} className="img-inchat" src={getFiles(path)}></img>
                        : <div className="download-file">
                            <RiFile2Fill />
                            <span>{name}</span>
                            <button onClick={() => { download() }}><RiArrowDownLine /></button>
                        </div>
                ) : ''}

                <p className="text-msg" dangerouslySetInnerHTML={{ __html: tratarString(message) }}></p>
                <small>{(data) ? formatDate(data) : ''}</small>
            
                <ActionMessages className={!atendimento && "optionBox"}  onClick={() => setShowOptions(true)}>
                    <MdKeyboardArrowDown />
                </ActionMessages>

                {showOptions && (
                    <>
                        <BackgroundModalAction onClick={() => setShowOptions(false)}/>
                        <ModalAction className={ !isTo && "you"}>
                            { !atendimento && <li onClick={replyMessage}><FaReply />Responder</li> }
                            { message && <li onClick={copyMessage}><FaCopy />Copiar</li> }
                            { isTo && <li onClick={deleteMessage}><FaTrashAlt /> Apagar mensagem</li> }
                        </ModalAction>
                    </>
                )}
            </div>
        </Container>
    );
})

export default MensagemChat;
import React, { useState, useEffect } from 'react';
import ScrollToBottom from 'react-scroll-to-bottom';

import api from '../../services/api-geral';
import { formatDate } from '../../util/formats';

import Page from '../../components/Page';
import TitleAndBreadcrumb from '../../components/TitleAndBreadcrumb';
import CFormSearch from '../../components/ComponentFormSearch';

import { Painel } from '../../styles/content';

import { Container, LoadingWrap } from './styles';
import { FaRedo } from 'react-icons/fa';

export default function Logs() {
	const [logs, setLogs] = useState([]);
	const [allLogs, setAllLogs] = useState([]);
	const [countLogs, setCountLogs] = useState(0);
	const [lastPage, setLastPage] = useState(0);
	const [search, setSearch] = useState('');
	const [searchAll, setSearchAll] = useState('');
	const [loading, setLoading] = useState(false);

	const [page, setPage] = useState(0);

	const [showBtnLoadLogs, setShowBtnLoadLogs] = useState(false);

	useEffect(() => {
		getLogs();
	}, []);

	useEffect(() => {
		verifyPagination();
		getLogs();
	}, [page]);

	const getLogs = async () => {
		const response = (await api.get(`logs/${page}`)).data;
		const responseAll = (await api.get(`logs-all`)).data;
		setAllLogs(responseAll.logs);
		setLogs([...logs, ...response.logs]);
		setCountLogs(response.count);
	};

	const verifyPagination = () => {
		const numberPages = (countLogs / 5);
		var lastPage = 0;
		var pagina = page;

		if (numberPages % 1 === 0) {
			pagina = pagina + 1;
			lastPage = numberPages;
		} else lastPage = parseInt(numberPages);

		if (pagina === lastPage) setShowBtnLoadLogs(false);
		else setShowBtnLoadLogs(true);
	}

	useEffect(() => {
		setLoading(true);
		const debounce = setTimeout(() => {
			setSearchAll(search);
			setLoading(false);
		}, 1500)

		return () => clearTimeout(debounce)
	}, [search])

	const changePage = numberPage => setPage(numberPage);

	return (
		<Page content={(
			<Container>
				<TitleAndBreadcrumb title='Registro de atividades' breadcrumbs={[]} format='config' />

				<CFormSearch className="pesquisa-log" cstyle='ligth' placeholder="Pesquisar" value={search} change={value => setSearch(value)} />

				<Painel className="painel-logs">
					<ul>
						<ScrollToBottom className="scroll">
							{loading === true ? <LoadingWrap><FaRedo className="fa-spin" /><h3>Carregando...</h3></LoadingWrap> :
								(searchAll === '') ?
									<>
										{logs.map(log => (
											<li>
												<strong>{formatDate(log.createdAt)}</strong>
												<span>{log.msg}</span>
											</li>
										))}
										{showBtnLoadLogs && (
											<li className="button">
												<button onClick={() => changePage(page + 1)}>Ver mais</button>
											</li>
										)}
									</>
									:
									allLogs.filter((log) => { return log.msg.toLowerCase().indexOf(searchAll.toLowerCase()) >= 0 }).map((filtrado) => (
										<li>
											<strong>{formatDate(filtrado.createdAt)}</strong>
											<span>{filtrado.msg}</span>
										</li>
									))
							}
						</ScrollToBottom>
					</ul>
				</Painel>
			</Container>
		)} />
	);
}

import Icone from '../assets/icone_logo.png'

export default (title, body, link = '') => {
    Notification.requestPermission();

    var n = new Notification(title, {
        body: body,
        icon: Icone
    });

    if (link !== '') {
        n.addEventListener("click", function () {
            n.close();
            window.focus();
            window.location.href = link;
        });
    }
}
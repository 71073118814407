import styled, { css } from "styled-components";

import colors from "../../presets/colors";
import effects from "../../presets/effects";

import { lighten } from "polished";

export const Container = styled.div`
  width: calc(100% - 110px);
  height: calc(100% - 90px);
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 90px;
  left: 110px;
  z-index: 1050;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1055;

  @media (max-width: 1440px) {
    width: calc(100% - 110px);
    height: calc(100% - 70px);
    top: 70px;
    left: 100px;
  }

  @media (max-width: 1024px) {
    width: 100%;
    left: 0;
  }
`;

export const Content = styled.div`
  max-height: 80%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: 35%;
  background-color: white;
  border: 1px solid ${colors.border};
  box-shadow: ${effects.boxshadow};
  border-radius: 5px;
  z-index: 1200;

  @media (max-width: 1275px) {
    width: 40%;
  }

  @media (max-width: 1000px) {
    width: 50%;
  }

  @media (max-width: 675px) {
    width: 60%;
  }

  @media (max-width: 550px) {
    width: 75%;
  }

  @media (max-width: 420px) {
    width: 85%;
  }

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.border};
    border-radius: ${effects.radius};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .head {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75px;
    padding: 15px 0;
    border-bottom: 1px solid ${colors.border};

    @media (max-width: 450px) {
      height: 60px;
    }

    h1 {
      font-size: 20px;
      letter-spacing: 2px;
      color: ${colors.text_primary};

      @media (max-width: 450px) {
        font-size: 16px;
      }
    }

    .btn-close {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: ${colors.bgsecondary};
      border: 0;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 450px) {
        height: 30px;
        width: 30px;
      }

      svg {
        font-size: 20px;
      }
    }
  }

  .body {
    padding: 10px 10px 20px;

    .autor {
      display: flex;
      align-items: center;

      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 10px;
        object-fit: cover;
      }

      .desc {
        display: flex;
        flex-direction: column;

        strong {
          color: ${colors.text_primary};
          letter-spacing: 1px;
        }

        small {
          color: gray;
          letter-spacing: 1px;
        }
      }
    }

    form {
      position: relative;
      
      .content-form {
        margin-top: 15px;

        .ck-editor {
          display: flex;
          flex-direction: column-reverse;
          border: solid 0.01rem lightgrey;
        }

        .ck-toolbar__items {
          display: flex;
          justify-content: center;

          .ck-dropdown {
            &.ck-heading-dropdown {
              .ck-button {
                &.ck-dropdown__button {
                  .ck-button__label {
                    width: auto;
                  }
                }
              }
            }
          }
        }

        .ck-dropdown__arrow{
          display: none;
        }

        .ck-dropdown__panel_se{
          width: 300px;
        }

        .ck-form__header__label {
          visibility: hidden;
        }

        .ck-form__header__label:after{
          content:'Emojis'; 
          visibility: visible;
          display: block;
          position: absolute;
          padding: 5px;
          top: 2px;
        }

        --ck-color-mention-background: white;

        --ck-color-mention-text: #4285a6;

        .ck-content {
          min-height: 60px;
          max-height: 100px;

          p {
            margin: 9px 0;
          }
          
          a { text-decoration: none; }

          li {
            list-style-position: inside;
          }
        }
      }

      textarea {
          width: 100%;
          font-size: 18px;
          padding: 10px;
          border: 0;
          border-radius: 5px;
          background-color: ${colors.bgsecondary};
          margin-top: 10px;
      }

      .image-preview {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        
        .content-image-preview {
          width: 175px;
          height: auto;
          position: relative;

          .img-preview {
            width: 100%;
            height: 100%;
            border-radius: 5px;
          }

          .btn-delete-img {
            background-color: ${colors.secondary};
            color: ${colors.btn_text_primary};
            border: none;
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: -12.5px;
            top: -12.5px;
          }
        }
      }

      .form-button {
        background-color: ${colors.primary};
        width: 100%;
        height: 40px;
        color: white;
        border-radius: 5px;
        margin-top: 20px;
        border: 0;
        transition: all 0.3s;

        &:hover {
          background-color: ${lighten(0.09, colors.primary)};
        }

        &:disabled {
          cursor: default;
          opacity: 0.7;
          background-color: ${lighten(0.09, colors.primary)};
        }
      }

      .wrap-utils {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 500px) {
          align-items: flex-start;
          flex-direction: column;

          button {
            font-size: 14px;
            margin-bottom: 10px;
          }
        }

        button {
          background-color: transparent;
          border: 0;
          height: 30px;
          border-radius: 10px;
          font-size: 12px;
          display: flex;
          align-items: center;
          color: ${colors.text_primary};
          letter-spacing: 1px;
          margin-right: 5px;
          
          .icon {
            background-color: ${colors.primary};
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;

            svg {
              font-size: 18px;
              color: white;
            }
          }
        }
      }
    }
  }
`;

export const DropzoneContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.isDragReject
      ? css`
          color: ${colors.btn_danger};
          border: 1px solid ${colors.btn_danger};
        `
      : css`
          border: 1px solid ${colors.primary};
          color: ${colors.primary};
        `}

  p {
    font-size: 20px;
  }
`;

export const AreaToClose = styled.div`
  width: calc(100% - 110px);
  height: calc(100% - 90px);
  position: fixed;
  top: 90px;
  left: 110px;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1440px) {
    width: calc(100% - 110px);
    height: calc(100% - 70px);
    top: 70px;
    left: 100px;
  }

  @media (max-width: 1024px) {
    width: 100%;
    left: 0;
  }
`;

export const LoadingWrap = styled.div`
  width: 100%;
  background-color: none;
  border-radius: 0.75rem;
  top: 0;
  left: 0;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
	color: ${colors.primary};

  h3 {
    margin-top: 15px;
  }

  svg {
    font-size: 25px;
  }

  .fa-spin {
    -webkit-animation: icon-spin 1s infinite linear;
    animation: icon-spin 1s infinite linear;
  }

  @-webkit-keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }

  @keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
`;


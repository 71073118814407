import React, { useState, useEffect } from 'react';
import Geral from '../../services/api-geral';
import { logout } from '../../session';

import Swal from 'sweetalert2';

import Page from '../../components/Page';
import CTable from '../../components/ComponentTable';
import FormChamado from '../../components/FormularioChamado/Form';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";

import { Painel } from '../../styles/content';

import { Container } from './styles';
import { Paginas, PaginaButton } from '../../components/BotoesPaginacao/styles';
import { sessionGet } from '../../session';

export default function FormularioChamado() {
  const [dados, setDados] = useState([]);
  const [allDados, setAllDados] = useState([]);
  const [offsetFormularioChamado, setOffsetFormularioChamado] = useState(0);
  const [totalFormularioChamado, setTotalFormularioChamado] = useState(0);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [pagesTotal, setPagesTotal] = useState(1);
  const [valueSearch, setValueSearch] = useState('');

  const [link, setLink] = useState('');

  const getFormularioChamado = async () => {
    const url = window.location.href;
    const ver = url.split("?", 2)
    const linkEspecifico = ver[1];

    if (linkEspecifico !== undefined) {
      const verificacao = await Geral.get(`/verificar-formulario-chamado/${link}`);
      if (verificacao.data.length == 0) {
        Swal.fire({
          title: 'Aviso!',
          text: "O conteúdo a ser visualizado não pertence a esse perfil. Deseja desconectar-se e fazer um novo login?",
          icon: 'error',
          confirmButtonColor: '#F03233',
          confirmButtonText: 'Sim',
          showDenyButton: true,
          denyButtonColor: '#51A939',
          denyButtonText: 'Não',
        }).then((result) => {
          if (result.isConfirmed) {
            logout();
            deslogar();
          } else if (result.isDenied) {
            window.location.href = '/formulario-chamado';
          }
        });
      }
    }
    const response = await Geral.get(`/formulario-chamado?offset=${offsetFormularioChamado}`);
    const responseCount = await Geral.get('/formulario-chamado?count=true');
    setDados(response.data);
    setTotalFormularioChamado(responseCount.data);
    const pagesCount = calculatePagesCount(10, responseCount.data);
    setPagesTotal(pagesCount);
  };

  async function deslogar() {
    await Geral.put('/users-islogged', { isLogged: false, scope: 'close', sessionLogin: false })
  }

  useEffect(() => {
    getAllChamados();
    readNotfChamados();
  }, [])

  const readNotfChamados = async () => {
    const response = await Geral.put(`/notifications/all?local=formchat&id=${sessionGet('userId')}`);
  }

  const getAllChamados = async () => {
    const response = await Geral.get(`/formulario-chamado?offset=null`);
    setAllDados(response.data);
  }

  const calculatePagesCount = (pageSize, totalCount) => {
    // we suppose that if we have 0 items we want 1 empty page
    return totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize);
  };

  const setaOffset = (acao) => {
    if (acao === 'proximo') {
      if (Math.floor(totalFormularioChamado / 10) !== (offsetFormularioChamado / 10)) {
        setOffsetFormularioChamado(offsetFormularioChamado + 10);
        setPaginaAtual(paginaAtual + 1);
      }
    }

    if (acao === 'anterior') {
      if (offsetFormularioChamado > 0) {
        setOffsetFormularioChamado(offsetFormularioChamado - 10);
        setPaginaAtual(paginaAtual - 1);
      }
    }
  }

  useEffect(() => {
    let url = window.location.href;
    let id_form = url.split("=", 2)
    setLink(id_form[1]);
  }, []);

  useEffect(() => {
    getFormularioChamado();
  }, [link])

  useEffect(() => {
    getFormularioChamado();
  }, [offsetFormularioChamado]);

  return (
    <Page content={(
      <Container>
        <Painel className="painel-chamados">
          <CTable
            ambiente='formulario-chamado'
            titles={['ID', 'Nome', 'Status', 'Assunto', 'Responsável', 'Data entrada', 'Última atualização',]}
            values={dados}
            allValues={allDados}
            indexes={['id', 'nome', 'status', 'assunto', 'responsavel', 'criado', 'atualizado']}
            indexesSearch={['nome']}
            setValueSearch={setValueSearch}
            load={getFormularioChamado}
            FormCustom={FormChamado}
            setItem={setDados}
            actionDelete='/setor'
            editable={false}
            titlePage='Chamados do fale conosco'
            breadcrumbPage={[{ title: 'Listas' }]}
            emptyPage='Não há chamados a serem exibidos. Assim que a sua empresa for acionada por algum cliente pelo Fale Conosco do site, os registros aparecerão aqui.'
          />
          {dados.length != 0 && valueSearch === '' &&
            <Paginas>
              <PaginaButton disabled={paginaAtual > 1 ? false : true} onClick={() => { setOffsetFormularioChamado(0); setPaginaAtual(1) }}>
                <FaAngleDoubleLeft className="icon" size="20px" />
              </PaginaButton>

              <PaginaButton disabled={paginaAtual > 1 ? false : true} onClick={() => setaOffset('anterior')}>
                <FaAngleLeft className="icon" size="20px" />
              </PaginaButton>

              <span>{paginaAtual} de {pagesTotal} {pagesTotal > 1 ? 'páginas' : 'página'}</span>

              <PaginaButton disabled={(paginaAtual < Math.ceil(totalFormularioChamado / 10)) ? false : true} onClick={() => setaOffset('proximo')}>
                <FaAngleRight className="icon" size="20px" />
              </PaginaButton>

              <PaginaButton disabled={paginaAtual !== pagesTotal ? false : true} onClick={() => { setOffsetFormularioChamado((pagesTotal - 1) * 10); setPaginaAtual(pagesTotal) }}>
                <FaAngleDoubleRight className="icon" size="20px" />
              </PaginaButton>
            </Paginas>
          }
        </Painel>
      </Container>
    )} />
  );
}

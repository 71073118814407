import React, { useState, useEffect } from "react";
import { format } from "date-fns";

import {
  RiTwitterLine,
  RiHeartFill,
  RiHeart3Line,
  RiShareForwardBoxLine,
} from "react-icons/ri";

import { Container } from "./styles";

function Tweet({
  credentials,
  post,
  retweet,
  unretweet,
  destroy,
  favorite,
  unfavorite,
}) {
  const [midias, setMidias] = useState([]);

  useEffect(() => {
    let auxMidias = [];
    if (post.extended_entities) {
      post.extended_entities.media.map((midia) =>
        auxMidias.push(midia.media_url_https)
      );
      setMidias(auxMidias);
    }
  }, [post]);

  const handleRetweet = () => retweet(post.id_str);

  const handleUnRetweet = () => unretweet(post.id_str);

  const handleDestroy = () => destroy(post.id_str);

  const handleFavorite = () => favorite(post.id_str);

  const handleUnFavorite = () => unfavorite(post.id_str);

  return (
    <Container>
      <div className="body">
        <div className="content-holder">
        <img src={post.user.profile_image_url_https} />
        <div className="content">
          <div className="name">
            <strong>{post.user.name}</strong>
            <small>
              @{post.user.screen_name} -{" "}
              {format(new Date(post.created_at), "dd/MM  HH:mm")}
            </small>
          </div>
          <p>{post.text}</p>
        </div>
        </div>
        {midias.length > 1 ? 
        <div className="images">{midias.map((midia) => (<img className="img-post" src={midia}/>))}</div>
        : 
        <div className="images-unica">{midias.map((midia) => (<img className="img-post" src={midia}/>))}</div>}
        <div className="actions">
          {post.user.id === credentials.user.id && (
            <span onClick={handleDestroy}>Apagar</span>
          )}
          {post.retweeted ? (
            <span className="active" onClick={handleUnRetweet}>
              <RiShareForwardBoxLine /> <span>Retweets</span>{" "}
              <small>({post.retweet_count})</small>
            </span>
          ) : (
            <span onClick={handleRetweet}>
              <RiShareForwardBoxLine /> <span>Retweets</span>{" "}
              <small>({post.retweet_count})</small>
            </span>
          )}
          {post.favorited ? (
            <span className="active" onClick={handleUnFavorite}>
              <RiHeartFill /> <span>Likes</span>{" "}
              <small>({post.favorite_count})</small>{" "}
            </span>
          ) : (
            <span onClick={handleFavorite}>
              <RiHeart3Line /> <span>Likes</span>{" "}
              <small>({post.favorite_count})</small>{" "}
            </span>
          )}
          <span
            onClick={() =>
              window.open(
                `https://twitter.com/maiconkusterk/status/${post.id_str}`
              )
            }
          >
            <RiTwitterLine /> <span>Ver no Twitter</span>
          </span>
        </div>
      </div>
    </Container>
  );
}

export default Tweet;

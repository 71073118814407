import React, { useState, useEffect } from 'react';
import api from '../../services/api-geral';
import socket from '../../services/socket';
import getFiles from '../../util/getFiles';
import { sessionGet } from '../../session';
import Conversa from '../Conversa';
import ChatEspalhar from '../ChatEspalhar';
import ItemConversa from '../ItemConversa';
import ModalStatus from '../ModalStatus'
import SocketMessagesChat from '../../socket/SocketMessagesChat';

import { MdSearch, MdPerson, MdClose } from 'react-icons/md';
import { FaRegPaperPlane } from 'react-icons/fa';

import { Wrap, Sidebar, Search, Perfil, Form, ListaConversa } from './styles';
import { Backdrop, BackdropCenter, BackdropCustom } from '../../styles/content';
import SetorChatEspalhar from '../SetorChatEspalhar';

import translateDate from '../../util/translateDate';

function Chat({ idDestinatario = null }) {

  const [isShow, setIsShow] = useState('');
  const [setores, setSetores] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [srcImagemPerfil, setSrcImagemPerfil] = useState('');
  const [statusClass, setStatusClass] = useState('online');
  const [setor, setSetor] = useState('Setores');
  const [pesquisa, setPesquisa] = useState('');
  const [handlePesquisa, setHandlePesquisa] = useState('');
  const [setorAgrupado, setSetorAgrupado] = useState([]);

  const [userConversa, setUserConversa] = useState({});

  const [display, setDisplay] = useState(true);
  const [invDisplay, setInvDisplay] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [smallWidth, setSmallWidth] = useState(false);

  const [espalhar, setEspalhar] = useState(false);
  const [setorEspalhar, setSetorEspalhar] = useState('');

  const [selects, setSelects] = useState('');

  const [presence, setPresence] = useState();
  const [customStatus, setCustomStatus] = useState();
  const [showModalStatus, setShowModalStatus] = useState(false);
  const [orderStatusMessages, setOrderStatusMessages] = useState({})

  useEffect(() => {
    getConversaUrl();
  }, [])

  const getConversaUrl = async () => {
    //o idDestinatario é usado para quando o usuario clica na notificação do chat, 
    // para que se abra o chat ja na conversa de quem ele clicou na notificação
    if (idDestinatario !== null) {
      const response = (await api.get('/users')).data;
      selecionarConversaAutomatico(response);
    }
  }

  const getUsuarios = async () => {
    const responseUsers = (await api.get('/users')).data;

    var users = responseUsers;

    users.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));

    const response = (await api.post('/msgso', {
      users
    })).data;

    const responseMsgs = (await api.get(`msgs/0`)).data;

    users.forEach((user, index) => {
      if (response.msgs[index] !== null && response.msgs[index] !== undefined) {
        if (user.id === response.msgs[index].id_from || user.id === response.msgs[index].id_to) {
          user["idMsg"] = response.msgs[index].id;
          const count = responseMsgs.allMsgs.filter(function (msg) {
            return msg.id_from == user.id;
          });
          user["count"] = count.length;
          user["msgTime"] = formatDate(response.msgs[index].createdAt);
        }
      } else {
        user["idMsg"] = 0;
        user["msgTime"] = '';
      }
    });

    users.sort((a, b) => parseFloat(b.idMsg) - parseFloat(a.idMsg));
    const usersStatus = {online: [], offline: []}

    users.forEach((user, index) => {
      if(user.presence_status) {
        usersStatus.online.push(user)
      }

      if(!user.presence_status) {
        usersStatus.offline.push(user)
      }
    })
    setOrderStatusMessages(usersStatus)

    setUsuarios(users);

    window.history.replaceState(null, null, window.location.pathname);
  };

  const formatDate = (data) => {
    var newData;
    if (data !== null) {
      newData = translateDate(data);
      if (newData.includes('Hoje')) {
        const parts = newData.split(' ');
        newData = parts[2];
      } else if (newData.includes('Ontem')) {
        newData = 'Ontem'
      } else if (newData.includes('última')) {
        const parts = newData.split(' ');
        newData = parts[2];
      }
    } else {
      newData = '';
    }

    return newData;
  }

  const getUsuario = async () => {
    // ok
    const response = (await api.get(`/users/${sessionGet('idUser')}`)).data;
    setPresence(response.presence_status);
    if (response.statusCustom) {
      setCustomStatus(response.statusCustom.status_color);
    } else {
      setCustomStatus(null);
    }
    if (response.id_file) {
      setSrcImagemPerfil(getFiles(response.files.path));
    } else {
      setSrcImagemPerfil('')
    }
  };

  const geraSetorAgrupado = async () => {
    let grupoSetores = []

    const retornoSetores = (await api.get('/setor')).data;
    setSetores(retornoSetores);
    const retornoUsuarios = (await api.get('/users')).data;

    retornoSetores.map(s => {
      let setorAgrupado = []
      setorAgrupado["setor"] = s.name
      setorAgrupado["usuarios"] = []
      retornoUsuarios.map(u => {
        if (u.id_setor === s.id) {
          setorAgrupado['usuarios'].push(u);
        }

      })

      if (setorAgrupado['usuarios'].length > 0) {
        grupoSetores.push(setorAgrupado)
      }

    })
    setSetorAgrupado(grupoSetores)
  };

  useEffect(() => {
    geraSetorAgrupado();
    getUsuario();
    StatusClass();

    setTimeout(function () {
      getUsuarios();
    }, 200);

    socket.on('setStatusOn', () => {
      setTimeout(() => {
        checkStatus();
      }, 2000);
    });

    socket.on('customStatusChanged', () => {
      window.setTimeout(() => {
        getUsuarios();
        StatusClass();
      }, 2000)
    });

    return () => {
      socket.off('userConnectClient');
      socket.off('userDisconnectClient');
      socket.off('setStatusOn');
      socket.off('customStatusChanged')
    }
  }, []);
  //

  const checkStatus = async () => {
    const res = (await api.get('/users?usuarios=true')).data;

    res.map(user => {
      const el = document.getElementById(`user${user.id}`);

      if (el !== null) {
        if (user.presence_status === 0) {
          el.classList.remove("online");
          el.classList.add("offline");
        } else {
          el.classList.remove("offline");
          if (user.statusCustom) {
            el.classList.add(user.statusCustom.status_color);
          } else {
            el.classList.add("online");
          }
        }
      }
    })
  }

  const selecionarSetor = (title) => {
    setIsShow('');

    setSetor(title);
  };

  const selecionarConversaAutomatico = async (users) => {
    selecionarConversa(idDestinatario, users);

  };

  const selecionarConversa = async (id, users = usuarios) => {

    const response = users.filter((item) => {
      return item.id == id;
    });

    setUserConversa(response[0]);

  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
    };
    if (width > 1024) {
      setSmallWidth(false);
      setDisplay(true);
    } else {
      setSmallWidth(true);
    }
    window.addEventListener("resize", handleWindowResize);
  }, [width]);

  const checkDisplay = () => {
    if (smallWidth) {
      setDisplay(false);
    }
  }

  useEffect(() => {
    socket.on('ordenacao', msg_socket => {
      getUsuarios();
    });

    return () => {
      socket.off('ordenacao');
    }

  }, []);

  useEffect(() => {
    let newSearch = pesquisa;
    newSearch = newSearch.toLowerCase();
    newSearch = newSearch.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
    newSearch = newSearch.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
    newSearch = newSearch.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
    newSearch = newSearch.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
    newSearch = newSearch.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
    setPesquisa(newSearch);
  }, [pesquisa])

  const formatName = (name) => {
    var newName = name;

    newName = newName.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
    newName = newName.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
    newName = newName.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
    newName = newName.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
    newName = newName.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');

    return newName;
  }
  //
  const openModalStatus = () => {
    setShowModalStatus(!showModalStatus);
  }

  const StatusClass = async () => {
    const user = (await api.get(`/users/${sessionGet('idUser')}`)).data;
    if (user.statusCustom) {
      setStatusClass(user.statusCustom.status_color);
    } else {
      setStatusClass('online')
    }
  }

  return (
    <>
      {(showModalStatus) &&
        <>
          <Backdrop onClick={() => openModalStatus()} />
          <ModalStatus setShowModalStatus={setShowModalStatus} custom={true} />
        </>
      }
      <SocketMessagesChat loadMessages={getUsuarios} />

      <Wrap>
        <Sidebar id="sideBarChat" style={(display == true) ? { display: 'block' } : { display: 'none' }}>
          <Search>
            {!window.location.href.includes("/conversas/") ? '' : <button className="display-button" onClick={() => { setInvDisplay(false); setDisplay(false); }}><MdClose /></button>}
            <Perfil onClick={openModalStatus}>
              {(srcImagemPerfil !== '') ? <img src={srcImagemPerfil} /> : <MdPerson />}
              <div className="presence">
                <span id="presenceUser" className={statusClass} ></span>
              </div>
            </Perfil>
            <Form>
              <MdSearch />
              <input placeholder="Pesquisar" value={handlePesquisa} onChange={(e) => { setHandlePesquisa(e.target.value); setPesquisa(e.target.value) }} />
            </Form>
            <div className="actions-group" title="Enviar mensagem para grupo" onClick={() => { setEspalhar(true); }}>
              <FaRegPaperPlane />
            </div>
          </Search>
          {(espalhar) ?
            (
              <ListaConversa>
                <SetorChatEspalhar setores={setorAgrupado} setorAtivo={setorEspalhar} selects={setSelects} setDisplay={setDisplay} />
              </ListaConversa>
            )
            :
            (
              <ListaConversa>
                {(pesquisa == '') ?
                  <>
                    {orderStatusMessages.online && orderStatusMessages.online.map(sa => (
                      <>
                        <div className="conversaClicker" onClick={() => { setUserConversa(sa); sa.count = 0; /*window.location = `/conversas/${setIdWithCripto('user', sa.id)}`;*/ setEspalhar(false); checkDisplay(); }}>
                          <ItemConversa user={sa} getUsuarios={getUsuarios} />
                        </div>
                        <hr></hr>
                      </>
                    ))}
                    {orderStatusMessages.offline && orderStatusMessages.offline.map(sa => (
                        <>
                          <div className="conversaClicker" onClick={() => { setUserConversa(sa); sa.count = 0; /*window.location = `/conversas/${setIdWithCripto('user', sa.id)}`;*/ setEspalhar(false); checkDisplay(); }}>
                            <ItemConversa user={sa} getUsuarios={getUsuarios} />
                          </div>
                          <hr></hr>
                        </>
                      ))
                    }
                  </>
                  :
                  usuarios.filter((v) => { return formatName(v.name.toLowerCase()).includes((pesquisa)) }).map((conversa) => (
                    <div className="conversaClicker" onClick={() => { setUserConversa(conversa); conversa.count = 0;/*window.location = `/conversas/${setIdWithCripto('user', conversa.id)}`*/ }}>
                      <ItemConversa user={conversa} />
                    </div>
                  ))
                }
              </ListaConversa >
            )
          }
        </Sidebar >
        <div style={(invDisplay == true) ? { display: 'block' } : { display: 'none' }} onClick={() => { setDisplay(false); setInvDisplay(false) }} className="invisible-div"></div>
        {
          (espalhar) ?
            (
              <ChatEspalhar listaEnvio={selects} setEspalhar={setEspalhar} setDisplay={setDisplay} setInvDisplay={setInvDisplay} />
            )
            :
            (
              <Conversa setorChat={setor} usuario={userConversa} setUserConversa={setUserConversa} setDisplay={setDisplay} setInvDisplay={setInvDisplay} />
            )
        }
      </Wrap >
    </>
  );
}

export default Chat;

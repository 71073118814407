import styled from 'styled-components';

import colors from '../../presets/colors';

export const WrapConversaHistorico = styled.div`
  width: calc(100% - 110px);
  height: calc(100% - 90px);
  background-color: rgba(0, 0, 0, 0.8);
  position: ${props => props.isModal ? "fixed" : "relative"};
  top: 90px;
  left: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1070;

  .full-screen-historico {
        position: absolute;
        top: 0;
        background-color: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;

        .fs-holder {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
            text-align: center;
            img {
                height: 70%;

            }

            button {
                background-color: ${colors.secondary};
                color: white;
                border: none;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                right: 20px;
                top: 20px; 

                svg {
                    font-size: 30px;
                }
            }
        }
    }

  @media (max-width: 1400px) {
    height: calc(100% - 70px);
    top: 70px;
  }

  @media (max-width: 1024px) {
    width: 100%;
    left: 0;
  }
`;

export const WrapHistorico = styled.div`
  background-color: #fff;
  box-shadow: 0 3px 30px rgba(0,0,0,.1), 0 3px 20px rgba(0,0,0,.1);
  width: ${props => props.isModal ? "90%" : "100%"};
  height: ${props => props.isModal ? "90%" : "100%"};
  padding: 0 25px 25px 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

export const CloseButton = styled.button`
  padding: 5px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background-color: #e0e0e0;
  color: ${colors.primary_dark};
  border-radius: 20px;
  align-self: flex-end;

  svg { margin-right: 5px; }
`;

export const Tab = styled.div`
  width: 100%;
  height: 85%;
  background-color: #e0e0e0;
  position: relative;
  overflow: hidden;
  padding-bottom: 30px;

  .box-search {
    margin: 10px;
  }

  .detalhes-conversa {
    overflow-y: scroll;
    height: 90%;
    padding: 10px;

    .box {
      background-color: white;
      box-shadow: 0 1px 15px rgba(0,0,0,.04), 0 1px 6px rgba(0,0,0,.01);
      margin-bottom: 5px;
      padding: 20px;
      padding-bottom: 32px;
      border-radius: 5px;
      font-size: 14px;
      cursor: pointer;
      position: relative;
      @media (max-width: 750px) {
        font-size: 12px;
      }
    }
    .userDestaque {
        font-weight: bolder;
        color: ${colors.primary};
    }

    .date {
      position: absolute;
      right: 4px;
      bottom: 4px;
      font-size: 12px;
      color: black;
      font-weight: normal;
    }

    .name {
      margin-bottom: 10px;
    }

    .message {
      background-color: rgba(0, 0, 0, 0.08);
      border-radius: 10px;
      padding: 10px 1.5rem;
      font-weight: normal;
      color: black;
      display: flex;
      overflow: hidden;
      word-wrap: break-word;
      hyphens: auto;

      span {
        margin-left: 10px;
      }
      
      @media (max-width: 768px) {
        flex-direction: column;
        padding: 10px;
        
        span {
        margin-left: 0;
      }
      }

    }

    span {
      color: #3489eb;
    }

    span:hover {
      cursor: pointer;
    }
}
`;

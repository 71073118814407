export default {
  'primary_background': '#f4f4f6',
  'primary_dark': '#1A202E',
  'secondary_dark': '#5C77FF',
  'text_primary': '#303030',
  'btn_default': 'transparent',
  'btn_text_default': '#303030',
  'btn_primary': '#8a2387',
  'btn_text_primary': '#FFFFFF',
  'btn_success': '#f27121',
  'btn_text_success': '#FFFFFF',
  'btn_ok': '#60c045',
  'btn_text_ok': '#FFFFFF',
  'btn_warning': '#FF9900',
  'btn_text_warning': '#FFFFFF',
  'btn_danger': '#f24f50',
  'btn_text_danger': '#FFFFFF',
  'textoff': '#222222',
  'textinprimary': 'white',
  'border': 'rgba(48,48,48,.15)',


  // verde
  // 'primary': '#006675',
  // 'alpha_primary': 'rgba(0, 102, 117, 0.15)',
  // 'secondary': '#f24f50',
  // 'featured': '#008c72',
  // 'bgprimary': '#fff',
  // 'bgsecondary': '#f4f4f6',
  // 'texton': '#000',

  // roxo
  'primary': '#8a2387',
  'primary_opacity': '#be66cc',
  'alpha_primary': 'rgba(138, 35, 135, 0.15)',
  'bg_from_primary': '#f1e4f0',
  'secondary': '#e94057',
  'featured': '#f27121',
  'bgprimary': '#fff',
  'bgsecondary': '#f4f4f6',
  'texton': '#000',
  'btnprimary': '#0079bf',
  'btnsuccess': '#60c045',
  'btndanger': '#eb5a46',
  'twitter': '#1da1f2',
  'facebook': '#1877f2',
  'instagram': 'linear-gradient(to bottom left, #872aae, #e00f2e, #efcb00)',
};

import React, { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import { Container, Backdrop } from './styles';
import { TiTimes } from 'react-icons/ti';

import ModalInformation from '../ConfiguracaoSite/ModalInformation';

function InputColor({ id, opened, setOpened, inside = false, color, setColor, type, text, content, right = false, down = false, hasImg, icon }) {
  const [showPicker, setShowPicker] = useState(false);
  const [modalInformation, setModalInformation] = useState('');

  useEffect(() => {
    if (opened === id) {
      setShowPicker(true);
    } else {
      setShowPicker(false);
    }
  }, [opened])

  return (
    <>
      {/* {showPicker && (<Backdrop onClick={() => setShowPicker(false)} />)} */}
      <Container color={color} type={type} inside={inside} down={down} hasImg={hasImg}>
        {/* {(type !== 'border') && (
          inside === false ? (
            <div className='info-modal'>
              <RiInformationLine onMouseOver={() => setModalInformation('show')} onMouseOut={() => setModalInformation('')} />
              <ModalInformation modalClass={modalInformation} top='0' left={right === true ? '-200px' : '40px'} content={content} />
            </div>
          ) : ''
        )} */}
        <div className="wrap-colorpicker">
          <button type="button" onClick={() => setShowPicker(true)}></button>
          {showPicker && (
            <div className={right === false ? "colorpicker" : "colorpicker-right"}>
              <div className="close-input-color" type="button" onClick={() => setShowPicker(false)}>
                <TiTimes />
              </div>
              <SketchPicker color={color} onChange={setColor} disableAlpha={true} />
            </div>
          )}
          <button disabled={hasImg ? true : false} onClick={() => { setShowPicker(true); setOpened(id) }} className="icon">
            {icon}
          </button>
        </div>
        <span className="color-title">{text}</span>
      </Container>
    </>
  );
}

export default InputColor;
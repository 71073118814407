import React, { useState, useCallback, useRef, useEffect } from 'react';
import { MdClose, MdContentCut } from 'react-icons/md';
import { FaFileImage, FaRedo } from 'react-icons/fa';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Compressor from 'compressorjs';

import nodeApi from '../../services/api-geral';
import apiUpload from '../../services/api-upload';

import Alerta from '../../util/Alerta';

import { sessionGet } from '../../session';

import getFiles from '../../util/getFiles';

import { Container, ContentModal, ContentImage, ContentOptionsImage, HeaderOptionsCrop, BodyOptionsCrop, ListOptionsCrop, OptionCrop, ContentCanvas, FooterOptionsCrop, ImageCrop, ImageCropFunctions } from './styles';

function ModalCropImage({ closeModal, modulo, idImage, setIdImage, srcImage, setSrcImage, idPage, refresh, type = 'default' }) {
  const [image, setImage] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: '%', x: 25, y: 25, width: 50, height: 50 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [hasImage, setHasImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resizeMultiplier, setResizeMultiplier] = useState(1);

  useEffect(() => {
    if (type === 'default') {
      setResizeMultiplier(1);
    } else if (type === 'perfil') {
      setResizeMultiplier(0.5);
    }
  }, [resizeMultiplier])

  const onSelectFile = async (e) => {
    const file = e.target.files[0];
    if (e.target.files && e.target.files.length > 0) {
      const result = await checkSize(file);
      if (type === 'perfil') {
        const reader = new FileReader();
        reader.addEventListener('load', () => { setImage(reader.result); setHasImage(true) });
        reader.readAsDataURL(file);
      } else {
        if (result.valid === true) {
          const reader = new FileReader();
          reader.addEventListener('load', () => { setImage(reader.result); setHasImage(true) });
          reader.readAsDataURL(file);
        } else {
          Alerta.error("Ocorreu um erro! O tamanho máximo permitido para uma imagem é de 1920 x 1080 pixels.");
        }
      }
      const el = document.getElementById('input-file-crop');
      if (el) el.value = null;
    }
  };

  const checkSize = (file) => {
    let img = new Image()
    img.src = window.URL.createObjectURL(file)
    const promise = new Promise((resolve, reject) => {
      img.onload = () => {
        if (img.width <= 1920 && img.height <= 1080) {
          resolve({ valid: true, width: img.width, height: img.height });
        } else {
          resolve({ valid: false, width: img.width, height: img.height });
        }
        img.onerror = reject;
      }
    })
    return promise;
  }

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  // Função que corta a imagem e faz o upload da mesma
  const cropImage = (canvas) => {
    const dados = new FormData();
    dados.append('modulo', modulo ? modulo : 'geral');
    dados.append('userId', sessionGet('idUser'));

    canvas.toBlob(
      (blob) => {
        if (blob) {
          setLoading(true);
          new Compressor(blob, {
            quality: 0.5,
            convertSize: 0,
            async success(result) {
              dados.append('file', result, modulo === 'company_profile' ? 'imagem-perfil-empresa.png' : modulo === 'company_background' ? 'imagem-fundo-empresa.png' : 'imagem-perfil.png');

              const response = (await apiUpload.post('/upload', dados)).data;

              if (idImage) {
                await removeImage();
              }

              setIdImage(response.id);
              setSrcImage(response.path);
              closeModal(false);
            }
          });
        } else {
          Alerta.error("Ocorreu um erro!", "Selecione uma área da imagem para continuar");
        }
      }, 'image/png', 1
    );
  };

  // Funão que vai relacionar o ID da imagem com o usuário ou o ID da imagem com página do minisite
  const relateImage = async () => {
    if (modulo === 'company_profile' || modulo === 'company_background') {
      const responseIdPage = (await nodeApi.put(`/upload/${idImage}`, {
        id_page: idPage,
      }));

      handleUpdateImage();
    } else {
      if (idImage) {
        const dados = {
          id: sessionGet('idUser'),
          id_file: idImage
        };

        const response = (await nodeApi.put(`/users`, dados)).data;

        refresh();
      }
    }
  };

  // Função que vai relacionar o ID da imagem com a respectiva coluna de imagem na tabela página
  const handleUpdateImage = async () => {
    if (modulo === 'company_profile') {
      await nodeApi.put('/page?scope=aparencia', {
        id_image_logo: idImage
      });
    } else {
      await nodeApi.put('/page?scope=aparencia', {
        id_image_background: idImage
      });
    }
    refresh();
  };

  const removeImage = async () => {
    const response = (await apiUpload.delete(`/upload/${idImage}/${srcImage}`)).data;
    setIdImage('');
    setSrcImage('');
  };

  // Função que vai receber as opções da imagem (largura, altura, posição vertical e posição horizontal) via react refs e manipulá-los
  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
  }, [completedCrop]);

  // Função que vai fazer uma requisição via http cujo vai receber o src da imagem e transformar para o tipo blob, para que assim a imagem possa ser exibida no crop e por fim ser cortada 
  useEffect(() => {
    if (srcImage) {
      setHasImage(true);

      var request = new XMLHttpRequest();
      request.open('GET', getFiles(srcImage), true);
      request.responseType = 'blob';
      request.onload = function () {
        var reader = new FileReader();
        reader.readAsDataURL(request.response);
        reader.onload = function (e) {
          setImage(e.target.result);
        };
      };
      request.send();
    } else {
      setHasImage(false);
    }
  }, [srcImage]);

  useEffect(() => {
    relateImage();
  }, [idImage]);

  return (
    <Container>
      <ContentModal>
        <button id="closeModalImageCrop" onClick={closeModal}><MdClose /></button>
        <ContentImage hasImage={hasImage}>
          {hasImage &&
            <ImageCrop>
              <ReactCrop
                src={image}
                onImageLoaded={onLoad}
                crop={crop}
                onChange={(c) => setCrop(c)}
                onComplete={(c) => setCompletedCrop(c)}
              />
            </ImageCrop>
          }

          <ImageCropFunctions>
            <button onClick={() => document.getElementById('input-file-crop').click()}>{hasImage ? 'Escolher outra imagem' : 'Escolher imagem'}<FaFileImage /></button>
            <input id="input-file-crop" type="file" accept="image/*" onChange={onSelectFile} />
          </ImageCropFunctions>
        </ContentImage>
        <ContentOptionsImage>
          <HeaderOptionsCrop>
            <h2>Opções de corte</h2>
          </HeaderOptionsCrop>
          <BodyOptionsCrop>
            <ListOptionsCrop>
              <OptionCrop>
                <label>Largura ({completedCrop?.unit ? completedCrop?.unit : 'px'}): </label>
                <p>{completedCrop?.width ? Math.round(completedCrop?.width) : '-'}</p>
              </OptionCrop>
              <OptionCrop>
                <label>Altura ({completedCrop?.unit ? completedCrop?.unit : 'px'}): </label>
                <p>{completedCrop?.height ? Math.round(completedCrop?.height) : '-'}</p>
              </OptionCrop>
              {/* <OptionCrop>
                <label>Posição vertical ({completedCrop?.unit}): </label>
                <p>{completedCrop?.x}</p>
              </OptionCrop>
              <OptionCrop>
                <label>Posição horizontal ({completedCrop?.unit}): </label>
                <p>{completedCrop?.y}</p>
              </OptionCrop> */}
            </ListOptionsCrop>
            <ContentCanvas>
              <canvas
                ref={previewCanvasRef}
                // O arredondamento é importante para que a largura e a altura da tela correspondam/seja um múltiplo de nitidez.
                style={{
                  width: Math.round(completedCrop?.width ?? 0),
                  height: Math.round(completedCrop?.height ?? 0)
                }}
              />
            </ContentCanvas>
          </BodyOptionsCrop>
          <FooterOptionsCrop>
            <button onClick={() => cropImage(previewCanvasRef.current)}>
              {loading ?
                <>
                  <FaRedo className="fa-spin" /> Carregando...
                </> :
                <>
                  Cortar imagem <MdContentCut />
                </>
              }
            </button>
          </FooterOptionsCrop>
        </ContentOptionsImage>
      </ContentModal>
    </Container >
  );
}

export default ModalCropImage;
import React, { useEffect, useState } from 'react';

import { Container, Wrap } from './styles';
import { RiCloseLine } from 'react-icons/ri';
import api from '../../services/api-geral';
import ListMarkedUsers from '../ListMarkedUsers';

function FormMentionsFeed({ markedUsers, setMarkedUsers, setShowMentions, close }) {

    const [search, setSearch] = useState('');
    const [users, setUsers] = useState([]);

    useEffect(() => { getUsers() }, []);

    useEffect(() => {
        const el = document.getElementById('input-mention');
        if (el) {
            el.focus();
        }
    }, []);

    const getUsers = async () => {
        const response = (await api.get('/users')).data;

        setUsers(response);
    };

    const markUser = user => {
        setSearch('');
        setMarkedUsers([...markedUsers, user])

    };

    return (
        <Wrap>
            <Container>
                <h3>Insira o nome do usuário que deseja mencionar:</h3>
                <button className="btn-close" onClick={() => { setMarkedUsers([]); setShowMentions(false) }}><RiCloseLine /></button>
                <form>
                    <div className="input-search">
                        <input id="input-mention" type="text" value={search} onChange={e => setSearch(e.target.value)} placeholder="Digite o nome do usuário" autoComplete="off" />
                        {search !== '' && <ul>{users.filter(user => user.name.toLowerCase().includes(search.toLowerCase())).map(user => (
                            <>
                                {users.filter(user => !markedUsers.includes(user)).filter(user => user.name.toLowerCase().includes(search.toLowerCase())).length > 0 && users != null ? (
                                    <li key={user.id} onClick={() => markUser(user)}>{user.name}</li>
                                ) : <h4>Nenhum usuário encontrado</h4>}
                            </>
                        ))}</ul>}
                    </div>

                    <ListMarkedUsers markedUsers={markedUsers} setMarkedUsers={setMarkedUsers} />

                    <button type="button" onClick={() => setShowMentions(false)}>Concluir</button>
                </form>
            </Container>
        </Wrap>
    );
}

export default FormMentionsFeed;
import React, { useEffect, useState } from 'react';
import { RiInformationLine } from 'react-icons/ri';
import { IoIosAddCircle } from "react-icons/io";
import { MdFileUpload } from "react-icons/md";
import { TiTimes } from 'react-icons/ti';

import { Info, Publish } from './styles';

import Swal from 'sweetalert2';

import Compressor from 'compressorjs';

import api from '../../../services/api-geral';
import apiUpload from '../../../services/api-upload';

import Alerta from '../../../util/Alerta';
import getFiles from '../../../util/getFiles';

import LinkPaginaEmpresarial from '../LinkPaginaEmpresarial';
import PreviewMobile from '../../PreviewMobile';
import ComponentInput from '../../ComponentInput';
import InputColor from '../../InputColor';
import ModalInformation from '../ModalInformation';
import ModalCropImage from '../../ModalCropImage';

import { Painel } from '../../../styles/content';

function Aparencia({ setSwitchTab, switchTab, setTabs, refresh, doRefresh, tab, data, reload, published, setSaved }) {
  const [showModalCrop, setShowModalCrop] = useState(false);

  // form states
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [linkPage, setLinkPage] = useState('');
  const [metaTitle, setMetaTitle] = useState('');

  const [objectColorBorder, setObjectColorBorder] = useState({});
  const [colorBorder, setColorBorder] = useState('ffffff');

  const [flagProfileBorder, setFlagProfileBorder] = useState();

  const [modulo, setModulo] = useState('');

  const [idCompanyProfileImage, setIdCompanyProfileImage] = useState('');
  const [companyProfileImage, setCompanyProfileImage] = useState('');

  const [modalInformation, setModalInformation] = useState('');

  const [modalInfo, setModalInfo] = useState(0);

  const [idPage, setIdPage] = useState('');

  useEffect(() => {
    if (published === true) {
      setSaved(false);
    }
  }, [published])

  useEffect(() => {
    if (refresh !== 0) {
      handleSubmitPreview();
    }
  }, [refresh]);

  var variance = 0;

  useEffect(() => {
    if (switchTab !== 'aparencia') {
      var variance = 0;
      if (data.description !== description) {
        variance = 1;
      }
      if (data.title !== title) {
        variance = 1;
      }
      if (data.colorBorder !== colorBorder) {
        variance = 1;
      }
      if (data.metaTitle !== metaTitle) {
        variance = 1;
      }
      if (variance === 0) {
        setTabs(switchTab);
      } else {
        Swal.fire({
          title: 'Atenção',
          text: 'Você possui alterações não publicadas, continuar mesmo assim?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Descartar',
          cancelButtonText: 'Não'
        }).then(async (result) => {
          if (result.isConfirmed) {
            setTabs(switchTab);
          } else {
            setSwitchTab('aparencia');
          }
        });
      }
    }
  }, [switchTab]);

  useEffect(() => {
    //load();
    loadInfos();
  }, []);

  useEffect(() => {
    if (data != null) {
      setIdPage(data.id);
      setTitle(data.title);
      setMetaTitle(data.metaTitle);
      setDescription(data.description);
      setLinkPage(data.link_page);
      setObjectColorBorder({ hex: data.colorBorder });

      if (data.id_image_logo) {
        setIdCompanyProfileImage(data.id_image_logo)
        setCompanyProfileImage(data.image_logo.path);
      }
    }
  }, [data])

  useEffect(() => {
    setColorBorder(objectColorBorder.hex);
  }, [objectColorBorder])

  const handleUpdateFlags = async (flag, type) => {
    if (type === 'border') {
      await api.put('/page?scope=flags', {
        flagProfileBorder: flag
      });
      setFlagProfileBorder(flag);
    }
    Swal.fire({
      icon: 'success',
      title: 'Alterações salvas',
      showConfirmButton: false,
      timer: 1500
    })
  }

  const loadInfos = async () => {
    const response = (await api.get('/page?scope=flags')).data;

    if (response !== null) {
      setFlagProfileBorder(response.flagProfileBorder);
    }
  };

  const handleSubmitPreview = async e => {
    //e.preventDefault();

    const response = (await api.post('/page', {
      title,
      description,
      metaTitle,
      metaDescription: data.metaDescription,
      metaKeywords: data.metaKeywords,
      colorBorder,
      id_image_logo: idCompanyProfileImage,
      scope: 'metadados'
    })).data;

    if (response.success) {
      setSaved(true);
      Alerta.success("Alterações publicadas!");
      variance = 0;

      reload();
    } else {
      Alerta.error("Ocorreu um erro!", "Tente novamente mais tarde!");
    }

    doRefresh(0);
  }

  const uploadAppereanceImage = async (files) => {

    if (data) {
      const dados = new FormData();

      dados.append('modulo', modulo);

      if (files) {
        new Compressor(files, {
          quality: 0.4,

          async success(result) {
            dados.append('file', result, result.name);

            const response = (await apiUpload.post('/upload', dados)).data;

            if (modulo === 'company_profile') {
              if (idCompanyProfileImage) {
                await removeProfileImage();
              }

              setIdCompanyProfileImage(response.id);
              setCompanyProfileImage(response.path);
            }
          }
        });
      }
    } else {
      Alerta.error("Ocorreu um erro!", "Por favor coloque o título da empresa antes");
    }
  };

  const removeImage = (typeImage) => {
    Swal.fire({
      title: 'Tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, apagar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        if (typeImage === 'profile') {
          removeProfileImage();
        }
        Alerta.success('Dados atualizados!', 'Seu arquivo foi excluído.');
      }
    });
  };

  const removeProfileImage = async () => {
    const response = (await apiUpload.delete(`/upload/${idCompanyProfileImage}/${companyProfileImage}`)).data;
    setIdCompanyProfileImage('');
    setCompanyProfileImage('');
  };

  const focusTextArea = () => {
    var el1 = document.getElementById('label-description');
    var el2 = document.getElementById('textarea-description');
    el1.style.color = '#8a2387';
    el2.style.borderColor = '#8a2387';
  }

  const blurTextArea = () => {
    var el1 = document.getElementById('label-description');
    var el2 = document.getElementById('textarea-description');
    el1.style.color = 'rgba(0,0,0,0.5)';
    el2.style.borderColor = 'rgba(0,0,0,0.2)';
  }

  return (
    <>
      {showModalCrop && <ModalCropImage closeModal={() => setShowModalCrop(false)} modulo={modulo} idImage={idCompanyProfileImage} setIdImage={setIdCompanyProfileImage} srcImage={companyProfileImage} setSrcImage={setCompanyProfileImage} idPage={idPage} refresh={reload} />}

      <div className="wrap-panels">
        <Painel className="painel-configuracao-site aparencia">
          <form onSubmit={handleSubmitPreview}>
            <div className="wrap-logo">
              <div className="image" onClick={() => { setModulo('company_profile'); setShowModalCrop(true) }}>
                {(companyProfileImage === '') ?
                  <IoIosAddCircle /> :
                  <>
                    <div className="image-filter">
                      <IoIosAddCircle />
                    </div>
                    <img src={getFiles(companyProfileImage)} />
                  </>
                }
              </div>
              <div className='info-modal-painel'>
                <RiInformationLine onMouseOver={() => { setModalInformation('show'); setModalInfo(1) }} onMouseOut={() => { setModalInformation(''); setModalInfo(0) }} />
                {modalInfo === 1 && (
                  <ModalInformation modalClass={modalInformation} top='30px' left='-85px' content='Utilize uma imagem atraente para ilustrar o seu negócio. Observe os limites de dimensão (120 x 120 pixels) e tamanho (200 kb) da imagem.' />
                )}
              </div>
              <div className="company-profile-settings">
                <div className="wrap-buttons">
                  <button className="btn-primary" type="button" onClick={() => { setModulo('company_profile'); setShowModalCrop(true) }}>Escolher imagem</button>
                  {(companyProfileImage !== '') &&
                    <button className="btn-secondary" type="button" onClick={() => removeImage('profile')}>Remover</button>
                  }
                </div>
                <div className="wrap-preferences">
                  <div className="toggle-preference">
                    <div className="title">
                      {/* <div className="wrap-icon-border">
                        <RiAccountCircleLine />
                      </div> */}
                      Borda de perfil
                    </div>
                    <div onClick={() => handleUpdateFlags(!flagProfileBorder, 'border')} className={`toggle-funcionalidade ${flagProfileBorder && 'active'}`}>
                      <div className="indicador"></div>
                    </div>
                  </div>
                  <div className="border-color">
                    <InputColor text='Cor da borda' color={objectColorBorder} setColor={setObjectColorBorder} placeholder="Cor" type='border' right={true} />
                  </div>
                </div>
              </div>
            </div>
            <div className="infos">
              <ComponentInput ctype="text" label="Nome de usuário/Empresa" val={title} change={setTitle} />
              <Info>
                <ComponentInput classCustom="input-with-information" ctype="text" label="Título da página" val={metaTitle} change={setMetaTitle} />
                <div className='info-modal'>
                  <RiInformationLine onMouseOver={() => { setModalInformation('show'); setModalInfo(2); }} onMouseOut={() => setModalInformation('')} />
                  {modalInfo === 2 && (
                    <ModalInformation modalClass={modalInformation} top='0' left='-200px' content='Esse conteúdo aparecerá na aba do seu navegador e como assunto nos resultados de busca do Google. Pode ser o nome da empresa ou um termo que descreva seu negócio. ' />
                  )}
                </div>
              </Info>
              <label id="label-description" className="label-description">Descrição da página (Máximo 300 caracteres)</label>
              <textarea
                id="textarea-description"
                onBlur={() => blurTextArea()}
                onFocus={() => focusTextArea()}
                maxLength="300"
                value={description}
                onChange={e => setDescription(e.target.value)}
                rows="5"
                placeholder="Insira uma breve apresentação sobre a sua empresa. " >
              </textarea>
            </div>
            <div className="link-company">
              <LinkPaginaEmpresarial linkPage={linkPage} />
            </div>
          </form>
        </Painel>
        <Painel className="painel-aparencia">
          <div className="mobile-preview">
            <PreviewMobile
              tab={tab}
              Cor5={colorBorder}
              borda={flagProfileBorder}
              foto={companyProfileImage}
              title={title}
              description={description}
              idPage={idPage}
            />
          </div>
        </Painel>
      </div>
    </>
  );
}

export default Aparencia;
import React from 'react';
import api from '../../services/api-geral';
import { encode } from 'base-64'
import {
    RiDiscussLine,
    RiLayoutMasonryLine
} from 'react-icons/ri';
import translateDate from '../../util/translateDate';
import { setIdWithCripto } from '../../util/formatParamsUrl';

function ListaNotificacoes({ notifications }) {

    const read = async (notification) => {

        await api.put(`/notifications/${notification.id}`);

        if (notification.local === 'chat') {
            window.location = `/conversas/${setIdWithCripto('user', notification.id_autor)}`;
        }
        else {
            if (notification.local === 'Formulário de Contato') {
                window.location = `/formulario-chamado?id=${setIdWithCripto('chamado', notification.id_chamado)}`;
            } else if (notification.local === 'feed') {
                window.location = `/feed/single?id=${setIdWithCripto('post', notification.id_post)}&comment=${setIdWithCripto('user', notification.id_comment)}`;
            } else {
                window.location = `/feed/single?id=${setIdWithCripto('post', notification.id_post)}&comment=${setIdWithCripto('user', notification.id_comment)}`;
            }
        }
    };

    return (
        <>
            {
                notifications.map(notification => (
                    <li key={notification.id} onClick={() => read(notification)} className={notification.flag_read ? 'lido' : ''}>
                        <div className="icon" title="feed">
                            {notification.local === 'chat' && <RiDiscussLine />}
                            {notification.local === 'feed' && <RiLayoutMasonryLine />}
                            {notification.local === 'likepostfeed' && <RiLayoutMasonryLine />}
                            {notification.local === 'likecommentfeed' && <RiLayoutMasonryLine />}
                            {notification.local === 'comentários do feed' && <RiLayoutMasonryLine />}
                            {notification.local === 'Formulário de Contato' && <RiLayoutMasonryLine />}
                        </div>
                        <div className="desc">
                            <strong>{notification.local === 'likepostfeed' || notification.local === 'likecommentfeed' ? 'feed' : notification.local}</strong>
                            <span>
                                {notification.local === 'chat' && (
                                    <>
                                        {notification.count > 1 ? `Você recebeu (${notification.count}) mensagens de ` : 'Você recebeu uma nova mensagem de '}
                                        {notification.autor.name}.
                                    </>
                                )}
                                {notification.local === 'likecommentfeed' && `${notification.autor.name} ${notification.mensagem}`}
                                {notification.local === 'feed' && `${notification.autor.name} ${notification.mensagem}`}
                                {notification.local === 'likepostfeed' && `${notification.autor.name} ${notification.mensagem}`}
                                {notification.local === 'comentários do feed' && `${notification.autor.name} ${notification.mensagem}`}
                                {notification.local === 'Formulário de Contato' && `${notification.name} ${notification.mensagem}`}
                            </span>
                            <small>{translateDate(notification.createdAt)}</small>
                        </div>
                    </li>
                ))
            }
        </>
    );
}

export default ListaNotificacoes;
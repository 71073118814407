import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import apiGeral from './services/api-geral';

import Login from './pages/Login';
import Usuarios from './pages/Usuarios';
import Setor from './pages/Setor';
// import Cargo from './pages/Cargo';
import Contato from './pages/Contato';
import Conversas from './pages/Conversas';
import ConversasExternas from './pages/ConversasExternas';
import Email from './pages/Email';
import FacebookNew from './pages/FacebookNew';
import Instagram from './pages/Instagram';
import ConfiguracaoEmpresa from './pages/ConfiguracaoEmpresa';
import EmbedChat from './pages/EmbedChat';
import Ajuda from './pages/Ajuda';
// import PostDetails from './pages/PostDetails';
import FormularioAssunto from './pages/FormularioAssunto';
import FormularioChamado from './pages/FormularioChamado';
import Logs from './pages/Logs';
import LogsEmpresas from './pages/LogsEmpresas';
import RecuperarSenha from './pages/RecuperarSenha';
import NovaSenha from './pages/NovaSenha';
import MeuPerfil from './pages/MeuPerfil';
import PesquisaSatisfacao from './pages/PesquisaSatisfacao';
import CentralMidias from './pages/CentralMidias';
import BemVindo from './pages/BemVindo';
import Twitter from './pages/Twitter';
import MostrarNotificacoes from './pages/MostrarNotificacoes';
import ConfigurarNotificacoes from './pages/ConfigurarNotificacoes';
import ViewPostFeed from './pages/ViewPostFeed';
import ConfiguracaoSite from './pages/ConfiguracaoSite';
import HistoricoAtendimento from './pages/HistoricoAtendimento';
import CheckUserLogged from './pages/CheckarUsuarioLogado';
import CriarEmpresa from './pages/CriarEmpresa';
import AlterarSenha from './pages/AlterarSenha';
import PainelDeControle from './pages/PainelDeControle';
import PerfilPublico from './pages/PerfilPublico';
import Erro404 from './pages/Erro404';
import PublicacoesSalvas from './pages/PublicacoesSalvas';
import RegistroLembretes from './pages/RegistroLembretes';

export default function Routes() {
  useEffect(() => {
    async function checkIsLogged() {
      const response = (await apiGeral.put('/users-islogged', { isLogged: true, scope: 'relogin' })).data;
    }
    checkIsLogged();
  }, [])

  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/painel-de-controle" component={PainelDeControle} exact />
      <Route path="/feed" component={BemVindo} exact />
      <Route path="/ajuda" exact component={Ajuda} />
      <Route path="/conversas/:idDestinatario?" exact component={Conversas} />
      <Route path="/atendimentos-ao-cliente" exact component={ConversasExternas} />
      <Route path="/usuarios" exact component={Usuarios} /> 
      <Route path="/resgistro-de-lembretes" exact component={RegistroLembretes} />
      {/* <Route path="/cargo" exact component={Cargo} /> */}
      <Route path="/setor" exact component={Setor} />
      <Route path="/contato" exact component={Contato} />
      <Route path="/email" exact component={Email} />
      <Route path="/facebook" exact component={FacebookNew} />
      <Route path="/instagram" exact component={Instagram} />
      <Route path="/configuracao-empresa" exact component={ConfiguracaoEmpresa} />
      <Route path="/embed-chat/:empresa" component={EmbedChat} />
      <Route path="/formulario-assunto" exact component={FormularioAssunto} />
      <Route path="/formulario-chamado" exact component={FormularioChamado} />
      <Route path="/meu-perfil" exact component={MeuPerfil} />
      <Route path="/perfil" exact component={PerfilPublico} />
      <Route path="/publicacoes-salvas" exact component={PublicacoesSalvas} />
      <Route path="/logs" exact component={Logs} />
      <Route path="/logs-empresas" exact component={LogsEmpresas} />
      <Route path="/recuperar-senha/:token?" exact component={RecuperarSenha} />
      <Route path="/nova-senha" exact component={NovaSenha} />
      <Route path="/pesquisa-satisfacao/:data" exact component={PesquisaSatisfacao} />
      <Route path="/midias" exact component={CentralMidias} />
      <Route path="/twitter" exact component={Twitter} />
      <Route path="/mostrar-notificacoes" exact component={MostrarNotificacoes} />
      <Route path="/configurar-notificacoes" exact component={ConfigurarNotificacoes} />
      <Route path="/feed/single" component={ViewPostFeed} />
      <Route path="/pagina-empresarial" component={ConfiguracaoSite} />
      <Route path="/historico-atendimento" exact component={HistoricoAtendimento} />
      <Route path="/check-user-logged" exact component={CheckUserLogged} />
      <Route path="/criar-empresa" exact component={CriarEmpresa} />
      <Route path="/alterar-senha" exact component={AlterarSenha} />
      <Route path='*' exact={true} component={Erro404} />
    </Switch >
  );
}

import React from 'react';

import { Container } from './styles';
import { RiCloseLine } from 'react-icons/ri';
import { HiAtSymbol } from "react-icons/hi";

function ListMarkedUsers({ mentions = [], markedUsers = [], setMarkedUsers }) {
    const removeMarkedUser = user => {
        const newMarkeds = markedUsers.filter(u => u.id !== user.id);
        setMarkedUsers(newMarkeds);
    };

    return (
        <>
            <Container>
                {mentions.map(user => <li key={user.name}><span>{user.name}</span></li>)}
            </Container>
            <Container>
                {markedUsers.map(user => <li key={user.name}><span>{user.name}</span><button type="button" onClick={() => removeMarkedUser(user)}><RiCloseLine /></button></li>)}
            </Container>
        </>
    );
}

export default ListMarkedUsers;
import React from 'react';
import { Wrap } from './styles';
import { IoIosInformationCircle } from "react-icons/io";

export default function AlertaMerx({ type, text, style, css, css_large_screen, render, context }) {
    return (
        <Wrap color={type} variant={style} custom={css} custom_large_screen={css_large_screen} context={context}>
            <div className='box-alert'>
                {(style === 'default') && <div className='icon'><IoIosInformationCircle /></div>}
                <div className='header'>

                    {(render === 'senha') ? (
                        <>
                            <span>Utilize no mínimo 6 caracteres com ao menos um número e uma letra maiúscula.</span>
                        </>
                    ) : (
                        <span>{text}</span>
                    )}

                </div>
            </div>
            <div className='footer'>
                {(style === 'default') ? (
                    <span></span>
                ) : (
                    <div className='icon'><IoIosInformationCircle /></div>
                )}

            </div>
        </Wrap>
    );
}

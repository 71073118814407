import styled from 'styled-components';

import effects from '../../presets/effects';
import colors from '../../presets/colors';

export const Container = styled.div`
    .painel-notificacoes {
		height: 74vh;
		overflow-y: scroll;
		margin-top: 20px;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            width: 100%;

            h3 {
            margin-bottom: 10px;
            }

            li {
            background-color: rgba(0, 0, 0, 0.03);
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            border-radius: 5px;
            margin-bottom: 10px;
            cursor: pointer;

            &.lido {
                background-color: white;

                .icon {
                background-color: rgba(0, 0, 0, 0.03);
                color: ${colors.primary};
                }
            }

            .icon {
                background-color: ${colors.primary};
                width: 35px;
                height: 35px;
                color: white;
                font-size: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
            }

            .desc {
                width: calc(100% - 45px);
                display: flex;
                flex-direction: column;
        
                strong {
                text-transform: uppercase;
                margin-right: 5px;
                color: ${colors.primary};
                font-size: 12px;
                }

                span {
                font-size: 13px;

                }

                small {
                color: #555;
                }
            }
            }
        }

        .cta-carregar-notificacoes {
            margin-top: 25px;
            background-color: ${colors.primary};
            padding: 5px 10px;
            color: white;
            border-radius: 5px;
            border: 0;
            box-shadow: ${effects.boxshadow};
        }
    }
`;

import styled from 'styled-components';

import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Container = styled.div`
  z-index: 100000;
  position: fixed;
  right: 30px;
  bottom: 40px;
`;

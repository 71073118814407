import { parseISO, format } from 'date-fns';

export const formatDate = (date) => {
    const auxDate = parseISO(date);
    return format(auxDate, "dd'/'MM'/'yyyy 'às' HH':'mm");
};

export const formatMinDate = (date) => {
    const auxDate = parseISO(date);
    return format(auxDate, "dd'/'MM'/'yyyy");
};

import styled from 'styled-components';

import colors from '../../presets/colors';

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;  
    
    hr {
        border: 1px solid ${colors.border};
        height: 1px;
        width: 97%;
    }
`;

export const Infos = styled.div`
    width: 100%;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dropdown {
        font-size: 16px;
        color: ${colors.primary};
        font-weight: bold;
        position: relative;
        cursor: pointer;

        &:hover > .likes {
            display: flex;
        }

        &:hover > .views {
            display: flex;
        }

        @media (max-width: 575px) {
            font-size: 14px;
        }

        .likes {
            z-index: 1;
            position: absolute;
            left: 0;
            display: none;
            //bottom: -40px;
            background-color: rgba(0, 0, 0, 0.8);
            padding: 10px;
            border-radius: 5px;
            min-width: 180px;
            flex-direction: column;
            
            &:hover {
                display: flex;
            }

            & > span {
                color: white;
                font-size: 12px;
                letter-spacing: 1px;
                margin-bottom: 3px;
            }
        }

        .views {
            z-index: 1;
            position: absolute;
            right: 0;
            display: none;
            //bottom: -40px;
            background-color: rgba(0, 0, 0, 0.8);
            padding: 10px;
            border-radius: 5px;
            min-width: 180px;
            flex-direction: column;
            
            &:hover {
                display: flex;
            }

            & > span {
                color: white;
                font-size: 12px;
                letter-spacing: 1px;
                margin-bottom: 3px;
            }
        }
    }
`;

export const Interacoes = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 10px 0;

    button {
        display: flex;
        align-items: center;
        background-color: transparent;
        border: 0;
        padding: 5px 15px;
        border-radius: 20px;

        &:hover {
            background-color: ${colors.bgsecondary};
            box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
            font-weight: bold;
        }

        @media (max-width: 400px) {
            padding: 5px;
        }

        &.active {
            span {
                color: ${colors.primary};
                font-weight: bold;
            }

            @media (max-width: 400px) {
                background-color: transparent;
                box-shadow: none;
            }
        }

        .icon {
            /* background-color: ${colors.primary}; */
            border-radius: 50%;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 5px;

            @media (max-width: 575px) {
                width: 35px;
                height: 35px;
            }

            svg {
                font-size: 18px;
                color: ${colors.primary};
            }
        }

        span {
            font-size: 14px;
            color: ${colors.textoff};

            @media (max-width: 575px) {
                font-size: 12px;
            }
        }
    }
`;

export const BoxDummy = styled.div`
        background-color: ${colors.bgsecondary};
        width: 101%;
        height: 0.5rem;
        border: none;
`

export const Comments = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid ${colors.border}; 


    .box-make-comment{
        width: 100%;
        height: 100%;
        padding: 1rem;
        /* border-bottom: 1px solid ${colors.border}; */

   
    }

    .wrap-buttons-comments {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 95%;
        margin-bottom: 25px;

        .call-image-modal {
            background-color: ${colors.primary};
            height: 40px;
            width: 40px;
            font-size: 25px;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            cursor: pointer;
        }

        .call-modal {
            width: 95%;
            background-color: ${colors.bgsecondary};
            height: 40px;
            border: 1px solid ${colors.border};
            border-radius: 20px;
            padding: 0 15px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: rgba(0,0,0, 0.7);

            @media (max-width: 1660px) {
                width: 92%;
            }

            @media (max-width: 575px) {
                width: 82%;
            }
        }
    }

    .btn-carregar-comments {
        background-color: ${colors.bgsecondary};
        padding: 5px 10px;
        border-radius: 5px;
        color: ${colors.secondary};
        font-weight: bold;
        align-self: flex-start;
        margin: 20px 0 20px 25px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
`;
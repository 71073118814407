import styled from 'styled-components';
import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  
  img {
    height: 100px;
  }
`;

export const Head = styled.div`
    width: 60%;
    display: flex;
    justify-content: space-between;

    .banner {
        width: 30%;
        height: 200px;
        background-color: ${colors.facebook};
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 40px 0;
        box-shadow: ${effects.boxshadow};
        position: relative;

        .filter {
            width: 100%;
            height: 100%;
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .card {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            img {
                width: 75px;
                height: 75px;
                border-radius: 50%;
                margin-bottom: 10px;
            }

            h2 {
                margin-bottom: 10px;
                font-size: 18px;
                text-transform: uppercase;
                letter-spacing: 2px;
                color: white;
            }

            button {
                padding: 5px 10px;
                border-radius: ${effects.radius};
                border: 0;
                box-shadow: ${effects.boxshadow};
                font-weight: bold;
            }
        }

        .icon {
            font-size: 125px;
            color: white;
            opacity: 0.1;
        }
    }

    .formulario-postagem {
        width: calc(70% - 20px);
        height: 200px;
        background-color: ${colors.bgprimary};
        box-shadow: ${effects.boxshadow};
        position: relative;

        .blocked {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
            background-color: rgba(255, 255, 255, 0.8);
            color: ${colors.textoff};
            display: flex;
            justify-content: center;
            align-items: center;
            letter-spacing: 1px;
        }

        .head {
            height: 40px;
            padding: 0 10px;
            font-size: 16px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid ${colors.border};

            span {
                font-weight: bold;
                letter-spacing: 1px;
            }

            .icon {
                color: ${colors.facebook};
                margin-right: 10px;
            }
        }

        .content {
            width: 100%;
            height: calc(100% - 40px);
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            textarea {
                width: calc(100% - 20px);
                height: calc(100% - 40px);
                padding: 10px;
                border: 0;
                letter-spacing: 1px;
            }

            .buttons {
                width: calc(100% - 20px);
                height: 40px;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                button {
                    background-color: ${colors.facebook};
                    color: white;
                    height: 25px;
                    padding: 0 10px;
                    font-size: 10px;
                    font-weight: bold;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    border-radius: ${effects.radius};
                    border: 0;
                    box-shadow: ${effects.boxshadow};
                }
            }
        }
    }
`;

export const Content = styled.div`
    width: 60%;
    display: flex;
    justify-content: space-between;

    .lista-paginas {
        width: 30%;
        list-style: none;
        padding: 0;
        margin-top: 20px;

        li {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            background-color: white;
            border: 1px solid ${colors.border};
            padding: 10px;
            position: relative;
            cursor: pointer;

            img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                margin-right: 10px;
            }

            .description {
                
            }

            .checkbox {
                width: 16px;
                height: 16px;
                border: 1px solid ${colors.border};
                position: absolute;
                background-color: white;
                top: 0;
                right: 0px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-top: 0;
                border-right: 0;

                &.active {
                    background-color: green;

                    .icon {
                        color: white;
                    }
                }

                .icon {
                    font-size: 20px;
                }
            }
        }
    }

    .lista-postagens {
        width: calc(70% - 20px);
        margin-top: 20px;
        list-style: none;
        padding: 0;

        li {
            width: 100%;
            padding: 10px;
            background-color: white;
            box-shadow: ${effects.boxshadow};
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .head {
                width: 100%;
                display: flex;
                align-items: center;
                margin-bottom: 25px;

                img {
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    margin-right: 10px;
                }

                .info {
                    display: flex;
                    flex-direction: column;
                }
            }

            img.img-post {
                width: 50%;
                height: auto;
            }

            p.text-post {
                text-align: justify;
                margin: 25px 0;
            }

            .buttons {
                width: 100%;
                display: flex;
                align-items: center;
                margin-top: 25px;

                button {
                    background-color: ${colors.bgsecondary};
                    border: 0;
                    padding: 0 10px;
                    height: 30px;
                    border-radius: 5px;
                    color: ${colors.facebook};
                    display: flex;
                    align-items: center;
                    margin-right: 10px;
                    margin-bottom: 10px;

                    svg {
                        font-size: 20px;
                        margin-right: 10px;
                        color: ${colors.texton};
                    }
                }
            }
        }
    }
`;

export const Login = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .panel-login {
        width: 60%;
        height: 70%;
        display: flex;
        box-shadow: ${effects.boxshadow};

        @media (max-width: 1000px) {
            flex-direction: column;
        }

        @media (max-width: 666px) {
            width: 80%;
            height: 80%;
        }

        @media (max-width: 415px) {
            width: 90%;
            height: 90%;
        }

        .banner {
            padding: 10px;
            width: 50%;
            height: 100%;
            background-color: ${colors.facebook};
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: white;
            
            h1 {
                text-align: center;
            }

            svg {
                font-size: 75px;
                margin-bottom: 25px;
            }

            @media (max-width: 1400px) {
                h1 { font-size: 20px }
            }

            @media (max-width: 1000px) {
                height: 40%;
                width: 100%;
            }

            @media (max-width: 415px) {
                height: 35%;
                svg {
                    font-size: 50px;
                    margin-bottom: 15px;
                }
            }
        }

        .form-login {
            width: 50%;
            height: 100%;
            background-color: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @media (max-width: 1000px) {
                height: 60%;
                width: 100%;
            }

            @media (max-width: 415px) {
                height: 65%;
            }

            h1 {
                font-size: 20px;
                width: 80%;
                margin-bottom: 25px;
                text-align: justify;

                @media (max-width: 1400px) {
                    font-size: 16px;
                }

                @media (max-height: 600px) {
                    font-size: 14px;
                    margin-bottom: 10px;
                }
            }

            p {
                width: 80%;
                text-align: justify;
                margin-bottom: 25px;

                @media (max-width: 1400px) {
                    font-size: 14px;
                }

                @media (max-height: 600px) {
                    margin-bottom: 10px;
                }
            }
        }
    }

    button.btn-login {
        border: 0;
        background-color: ${colors.facebook};
        padding: 10px;
        color: white;
        display: flex;
        align-items: center;
        box-shadow: ${effects.boxshadow};

        svg {
            margin-right: 5px;
            font-size: 20px;
        }

        span {
            margin-left: 5px;
        }
    }
`;

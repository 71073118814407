import styled from 'styled-components';

import colors from '../../presets/colors';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 769px) and (max-width: 1024px) {
    justify-content: flex-start;
  }

  @media (max-width: 768px) {
    overflow: auto;
    padding-left: 5px;
  }
`;

export const WrapItens = styled.div`
  width: fit-content;
  margin: 30px 0;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 30px;
  
  &.disabled-tools {
    & > div {
      svg {
        color: #767676;
      }
    }
  }

  @media (min-width: 1600px) and (max-width: 1680px) {
    margin: 15px 0;
  }

  @media (max-width: 1366px) {
    gap: 25px;
  }

  @media (min-width: 1025px) and (max-width: 1280px) {
    margin: 15px 0;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    gap: 30px;
    margin: 40px 0;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 575px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const WrapButton = styled.div`
  display: flex;
  justify-content: center;
`;

export const Button = styled.a`
  height: 4.688rem;
  font-size: 1.45rem;
  font-weight: bold;
  text-decoration: none;
  width: fit-content;
  color: ${colors.btn_text_primary};
  background-image: linear-gradient(to right, #872786, #d63966);
  border: 0px;
  border-radius: 50px;
  padding: 0px 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media(min-width: 1025px) and (max-width: 1366px) {
    height: 3.125rem;
    font-size: 1rem;
    border-radius: 25px;
    margin-top: 15px;
    padding: 0 20px;
  }

  @media(max-width: 575px) {
    height: 3.125rem;
    font-size: 1rem;
    border-radius: 25px;
  }
`;
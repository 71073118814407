import styled from 'styled-components';

import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Wrap = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    background: white;
    border-width: 0 1px 0;
    border-color: ${colors.border};
    border-style: solid;

    .info-head {
        height: 75px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid ${colors.border};

        @media (max-width: 1400px) {
            height: 50px;
        }

        .user {
            display: flex;
            align-items: center;

            .info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 10px;

                strong {
                    color: ${colors.texton};
                    font-size: 16px;
                    font-weight: bold;

                    @media (max-width: 1400px) {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .content {
        display: flex;
        height: calc(100% - 50px);

        .content-editor{
            height: 100%;
            @media (max-width: 1368px) {
                // height: calc(100% - 100px);
                
            }
        }
        
        .box-all {
            display: flex;
            margin: 5px 0px;
            align-items: center;

            span {
                margin-left: 10px;
                color: ${colors.primary};
                font-weight: bold;
            }
        }

        .left {
            // flex-basis: 30%;
            padding: 10px;
            overflow-y: auto;
            overflow-x: hidden;

            .box-setores {
                display: flex;
                align-items: center;
                color: ${colors.primary};
                font-weight: bold;

                span {
                    margin-left: 10px;
                }
            }

            .box-usuarios {
                display: flex;
                margin: 5px 28px;
                align-items: center;

                span {
                    margin-left: 10px;
                }
            }

            &::-webkit-scrollbar {
                width: 2px;
                height: 5px;
            }
        
            &::-webkit-scrollbar-button {
                width: 0px;
                height: 0px;
            }
        
            &::-webkit-scrollbar-thumb {
                background: ${colors.featured};
                border: 0px none #ffffff;
                border-radius: 50px;
            }
        
            &::-webkit-scrollbar-thumb:hover {
                background: ${colors.featured};
            }
        
            &::-webkit-scrollbar-thumb:active {
                background: ${colors.featured};
            }
        
            &::-webkit-scrollbar-track {
                background: ${colors.bgprimary};
                border: 0px none #ffffff;
                border-radius: 50px;
            }
        
            &::-webkit-scrollbar-track:hover {
                background: #1a202e;
            }
        
            &::-webkit-scrollbar-track:active {
                background: #333333;
            }
            
            &::-webkit-scrollbar-corner {
                background: transparent;
            }
        }

        .right {
            background-color: ${colors.bg_from_primary};
            height: 100%;
            // flex-basis: 70%;
            padding: 10px;
            justify-content: center;
            width: 100%;
            display: flex;
            flex-direction: column;

            &.center-box {
                height: 100%;
                align-items: center;
            }
        }
    }
`;

export const ImgAndStatus = styled.div`
    position: relative;
    background-color: rgba(0, 0, 0, 0.4);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;

    @media (max-width: 1400px) {
        width: 35px;
        height: 35px;
        font-size: 20px;
    } 

    svg {
        display: block;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
    }

    span {
        width: 12px;
        height: 12px;
        display: block;
        background-color: green;
        position: absolute;
        bottom: 0px;
        right: 3px;
        border-radius: 50%;
        border: 3px solid #1d2129;
        box-sizing: content-box;

        &.online {
            background-color: green;         
        }

        &.offline {
            background-color: gray;
        }
    }
`;
import styled, { css } from "styled-components";

import colors from '../../../presets/colors';
import effects from '../../../presets/effects';


export const Container = styled.div`
  width: calc(100% - 110px);
  height: calc(100% - 90px);
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 90px;
  left: 110px;
  z-index: 1500;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1440px) {
    width: calc(100% - 110px);
    height: calc(100% - 70px);
    top: 70px;
    left: 100px;
  }

  @media (max-width: 1024px) {
    width: 100%;
    left: 0;
  }
`;

export const AreaToClose = styled.div`
  width: calc(100% - 110px);
  height: calc(100% - 90px);
  position: fixed;
  top: 90px;
  left: 110px;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1440px) {
    width: calc(100% - 110px);
    height: calc(100% - 70px);
    top: 70px;
    left: 100px;
  }

  @media (max-width: 1024px) {
    width: 100%;
    left: 0;
  }
`;
export const AreaToCloseEmoji = styled.div`
  width: calc(100% - 110px);
  height: calc(100% - 90px);
  position: fixed;
  top: 90px;
  left: 110px;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1440px) {
    width: calc(100% - 110px);
    height: calc(100% - 70px);
    top: 70px;
    left: 100px;
  }

  @media (max-width: 1024px) {
    width: 100%;
    left: 0;
  }
`;
export const Content = styled.div`
  height: 450px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: 30%;
  background-color: white;
  border: 1px solid ${colors.border};
  box-shadow: ${effects.boxshadow};
  border-radius: 5px;
  z-index: 1505;
  padding: 2rem;
  padding-top: 1.5rem;
  position: relative;

  @media (max-width: 1600px) {
    width: 40%;
  }

  @media (max-width: 1000px) {
    width: 50%;
  }

  @media (max-width: 675px) {
    width: 60%;
  }

  @media (max-width: 550px) {
    width: 75%;
  }

  @media (max-width: 420px) {
    width: 85%;
  }

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.border};
    border-radius: ${effects.radius};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .exit{
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: ${colors.bgsecondary};
      border: 0;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 450px) {
        height: 30px;
        width: 30px;
      }

      svg {
        font-size: 20px;
      }
  }



`;

export const Emoji = styled.div`
    width: 300px;
    background-color: white;
    position: absolute;
    right: 30%;
    top: 35%;
    z-index: 1506;
    box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,0.075);
    border: 1px solid rgba(48,48,48,.15);
    div{
      display: flex;
      flex-direction: row-reverse;
      button{
      border: none;
      background-color: white;
      margin-right: 5px;
      font-weight: 600;
      }
    }
    ul{
      display: flex;
      flex-wrap: wrap;
      list-style-type: none;
      gap: 3px;
      padding: 5px;
      li{
        cursor: pointer;
      }
      }
    
`;

export const Title = styled.p`
  font-size: 18px;
  margin-left: 5px;
`;

export const NoteContainer = styled.div`
  width: 100%;
  padding-top: 1rem;

  .text-input {
    width: 100%;     
    display: flex;
    align-items: center;
    margin-top: 0.5rem;

    .text{
        resize: none; 
        overflow-y: hidden; 
        height: auto; 
        margin-left: 1rem;
        padding: 10px;
        padding-right: 1.5rem;
        border: 1px solid rgba(48,48,48,.15) ;
        border-radius:10px ;
        font: 15px 'Poppins',sans-serif;
        width:110%;
        display: flex;           
    }

    button{
      background-color: white;
      border: none;
      position: relative;
      right: 1.5rem;
  }
}


  .footer{
    margin: 1rem 0px;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
    height: 100%;

    .time{
      width: 50%;
  
      .date-wrap{
        display: flex;
        flex-direction: column;
        justify-content: center;
       .date-container{
        display: flex;
        flex-direction: column;
     

        label{
        color: #727272;
        display: flex;
        align-items: center;
        font-size: 12px;
        .info-wrap{
          display: flex;
          align-items: center;
          small{
            color: #555;
            font-size: 12px;
            font-weight: 300; 
            margin-left: 5px;

        }
      }


      }
      .input-date{
        width: 100%;
        padding:3px;
        border: 1px solid #c4c4c4;
        border-radius: 5px;
        margin-right: 1rem;
     


      }
      @media (max-width: 1300px) {
    
       }
        
       }
      }


    }


    .wrap{ 
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;

      .type{
        width:100%;  
        p{

        }    
        small{
          font-size: 12px;
          font-weight: 300; 
          margin-left: 5px;
        }   
        & > div{
          margin-top: 0;
        }
      }
      
      input{
            padding: 5px;
            border: 1px solid rgba(48,48,48,.15) ;
            border-radius:10px ;
            margin: auto;
            margin-top: 5px;
            margin-bottom: 5px;

            width: 90%;

            display: flex; 
          }

          .input-nada{
            background-color: transparent;
            border: transparent;
          }
      button {
        border: none;
        color: white;
        width: 7rem;
        height: 2rem;
        border-radius: 5px;
        font-size: 16px;
        margin: auto;

      }

      .create-button{
        background-color: #8a2387;
        opacity: 1;
        cursor: pointer;
      }
      .disabled{
        background-color: #8a2387;
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
`;
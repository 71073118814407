import styled from 'styled-components';

import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Modal = styled.div`
  z-index: 100000;
  margin-top: 15px;
  background-color: ${colors.btn_text_primary};
  width: 350px;
  height: auto;
  border-top: 4px solid ${colors.primary};
  border-radius: 5px;
  box-shadow: ${effects.boxshadow};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 10px 10px;
  color: ${colors.text_primary};
  position: relative;

  @media (max-width: 400px) {
    width: 300px;
  }

  @keyframes rotating {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }

  strong {
    display: flex;
    flex-direction: column;
    align-items: center;

    .rotating {
      animation: rotating 2s linear infinite;
      color: ${colors.primary};
      font-size: 20px;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }

  small {
    margin-bottom: 10px;

    &.sm-setor {
      color: ${colors.primary};
    }

    &:last-child {
      margin-top: 10px;
    }
  }

  span {
    text-align: center;
    font-size: 12px;
    margin-bottom: 10px;
  }

  button {
    padding: 0 20px;
    height: 30px;
    border: 0;
    text-transform: uppercase;
    font-size: 14px;
    background-color: ${colors.primary};
    color: white;
    letter-spacing: 1px;
    border-radius: 5px;
  }

  button.close-button {
    background-color: ${colors.primary_background};
    width: 34px;
    height: 34px;
    cursor: pointer;
    border-radius: 17px;
    border: 0;
    top: 5px;
    right: 5px;
    position: absolute;
    font-size: 20px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.text_primary};
  }
`;


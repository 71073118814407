import React, { useEffect, useState, useRef } from 'react';
import Dropzone from 'react-dropzone';
import node from '../../services/api-geral';
import { apiGeral } from '../../config';
import apiUpload from '../../services/api-upload';
import socket from '../../services/socket';
import { connect } from 'react-redux';
import * as userConversaActions from '../../store/modules/userConversa/actions';
import { RiMoreLine, RiCloseLine, RiArrowLeftLine, RiImageLine, RiRefreshLine, RiAttachmentLine, RiFile2Fill } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { sessionSet } from '../../session';

import Swal from 'sweetalert2';

import Compressor from 'compressorjs';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import emojis from '../../util/emojis';
import MensagemChat from '../../components/MensagemChat'
import getFiles from '../../util/getFiles';

import ScrollToBottom from 'react-scroll-to-bottom';
import { Wrap, ImgAndStatus, Form, Blocked, WrapCtaPagination, DropzoneContainer } from '../Conversa/styles';
import { BlockedAlert, InfoHead } from './styles';
import { MdSend } from 'react-icons/md';
import CustomSelect from '../MaterialUI/CustomSelect';

import { MdPerson } from 'react-icons/md';

import ImgChat from '../../assets/images/chat.svg';
import { room } from '../../util/socketFunctions';
import { sessionGet } from '../../session';
import AlertaMerx from '../AlertaMerx';

function ConversaExterno({ local, setorChat, idAtendimentoInterno, usuario, empresa, setUserConversa, dispatch, allowRead, setDisplay, setInvDisplay, ckEditor, SetCkEditor }) {
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');
    const [regex, setRegex] = useState(/(<([^>]+)>)/ig);
    const [imagemPerfil, setImagemPerfil] = useState('');
    const [idUser, setIdUser] = useState('');
    const [setoresSistema, setSetoresSistema] = useState(''); // Todos os setores para transferência
    const [setorTransferencia, setSetorTransferencia] = useState('') // state que será usado para poder transferir o atendimento
    const [setorConversa, setSetorConversa] = useState('')
    const [esperaAtendimento, setEsperaAtendimento] = useState(true);
    const [idAtendimento, setIdAtendimento] = useState('');
    const [draggin, setDraggin] = useState('');
    const [atendimentos, setAtendimentos] = useState({});
    const [page, setPage] = useState(0);
    const [countMsgs, setCountMsgs] = useState(0);
    const [finalizado, setFinalizado] = useState(false);

    const [pathFile, setPathFile] = useState('');
    const [idFile, setIdFile] = useState(null);

    const [idUltimaMensagem, setIdUltimaMensagem] = useState(0);
    const [loadPagination, setLoadPagination] = useState(false);

    const [typeMsg, setTypeMsg] = useState('msg');
    const [pathImg, setPathImg] = useState('');
    const [idImg, setIdImg] = useState(null);
    const [hasImg, setHasImg] = useState(false);
    const [fullImg, setFullImg] = useState(false);
    const [fullImgPath, setFullImgPath] = useState('');
    const [imgExterna, setImgExterna] = useState('');
    const inputRef = useRef(null);
    const [legenda, setLegenda] = useState();

    const [fileType, setFileType] = useState('');
    const [fileName, setFileName] = useState('');

    const [myAtendimentos, setMyAtendimetos] = useState({});

    useEffect(() => {
        getSetores();
        getAtendimento();
        room(`usuario${sessionGet('idUser')}`, 'join');

        if (window.innerWidth <= 500 && window.location.href.includes('/conversas/')) {
            setDisplay(false);
        }

        if (usuario.id > 0) {
            setInvDisplay(true);
        }

        window.addEventListener("beforeunload", () => {
            updatePresenceStatus(0);
            socket.emit('contatoDisconnectedClient');
        }, false);

        return () => {
            room(`usuario${sessionGet('idUser')}`, 'leave');
        }

    }, [idUser, idAtendimento, idAtendimentoInterno]);

    useEffect(() => {
        socket.on('atendimento_finalizado', () => {
            setFinalizado(true);

            setTimeout(function () {
                window.location = '/atendimentos-ao-cliente';
            }, 2000)
        });

        if (allowRead) {
            socket.on('msg_atendimento', msg_socket => {
                let idUsuario = usuario.id;

                if (msg_socket.id_from === idUsuario && msg_socket.id_to === sessionGet('idUser')) {
                    setPage(0);
                    setMessages([msg_socket, ...messages]);
                    readMsg();
                }
            });
        }

        socket.on('delete_message_atendimento', msg_socket => {
            if (msg_socket.id_from === sessionGet('idUser') || msg_socket.id_to === sessionGet('idUser')) {
                let msgs = messages.filter(function (obj) {
                    return obj.id !== msg_socket.id;
                });
                setMessages(msgs);
            }
        });

        return () => {
            socket.off('msg_atendimento');
            socket.off('atendimento_finalizado');
            socket.off('delete_message_atendimento');
        }
    }, [messages]);

    const readMsg = async () => {
        let usuarioTo = usuario.id;

        await node.put(`/notifications/all?local=chat&id=${usuarioTo}`);

        const mensagens = (await node.get(`/msgs-at/${usuarioTo}/${page}`)).data; //não apagar
    }

    useEffect(() => {
        if (usuario.name) {
            setUserConversa({})
            //FAZ COM QUE NÃO FAÇA REQUISIÇÃO A MAIS DE MENSAGENS
        }
        getAtendimentos()
    }, []);

    useEffect(() => {
        setPage(0);
        if (allowRead) {
            if (usuario.name) {
                getMessages(0);
                setIdUser(usuario.id);
            }
        }
    }, [usuario, idAtendimentoInterno]);

    const updatePresenceStatus = async status => {
        if (local === 'externo') {
            const response = (await node.put(`${apiGeral}/contato/${sessionGet('idUser')}`, { id: sessionGet('idUser'), presence_status: status }, { headers: { 'x-access-token': sessionGet('tokenUser') } })).data;
        }
    };

    const getAtendimento = async () => {
        if (idAtendimentoInterno) {
            const responseId = (await node.get(`/atendimentos-atendimento/${idAtendimentoInterno}`)).data;

            if (responseId.status === 'finalizado' || responseId.status === 'avaliado' || responseId.status === 'transferido') {
                setFinalizado(true);
            } else {
                setFinalizado(false);
            }
        }
    }

    const getSetores = async () => {
        const response = (await node.get(`/setor-atendimento`)).data;
        let setores = response.filter((setor) => setor.id !== sessionGet('idSetor'));
        setSetoresSistema(setores);
    }

    const getMessages = async (cpage, userTo = null) => {

        setLoadPagination(true);
        setPage(cpage);
        let usuarioTo;
        let dados = {};
        let mensagensResponse = '';

        usuarioTo = usuario.id;

        if (local === 'interno' && usuarioTo) {
            mensagensResponse = (await node.get(`/msgs-at/${usuarioTo}/${cpage}`, dados)).data;
        }

        let mensagens = mensagensResponse.msgs;

        setCountMsgs(mensagensResponse.countMsgs);

        setMessages(mensagens);

        if (page == 0) {
            setMessages([]); setMessages(mensagens);
        }

        if (cpage > 0) {
            setMessages([...messages, ...mensagens]);
        }

        let idMsgAux = 0;
        let ultimaMsgId = 0;

        if (mensagens !== '') {
            mensagens.map((item) => {
                if (item.id > idMsgAux) {
                    ultimaMsgId = item.id;
                }
                else {
                    idMsgAux = item.id;
                }
            });
        }

        setIdUltimaMensagem(ultimaMsgId);

        setLoadPagination(false);

        return mensagens;
    }

    const relacionarImg = async id_mensagem => {

        if (fileType === 'file') {
            const response = (await node.put(`/upload/${idFile}`, {
                id_post: '',
                id_mensagem,
                id_comment: '',
            }));
        } else {
            const response = (await node.put(`/upload/${idImg}`, {
                id_post: '',
                id_mensagem,
                id_comment: '',
            }));
        }
    };

    const handleSubmit = async e => {
        if (e != undefined) {
            e.preventDefault();
        }

        if (message !== '' || hasImg == true) {
            let usuarioTo = usuario.id;

            if (fileType === 'file') {
                var dados = {
                    'id_atendimento': idAtendimentoInterno,
                    'message': message,
                    'id_to': usuarioTo,
                    'id_from': sessionGet('idUser'),
                    'id_file': idFile,
                    'path_img': pathFile,
                    'name_file': fileName,
                    'type_file': fileType
                };
            } else {
                var dados = {
                    'id_atendimento': idAtendimentoInterno,
                    'message': message,
                    'id_to': usuarioTo,
                    'id_from': sessionGet('idUser'),
                    'id_file': idImg,
                    'path_img': pathImg,
                    'name_file': fileName,
                    'type_file': fileType
                };
            }

            setIdImg(null);
            setIdFile(null);

            const responseMensagem = await node.post('/msgs-at', dados);

            if (typeMsg == 'img') {
                relacionarImg(responseMensagem.data.id);
                setTypeMsg('msg');
            }

            setMessages([responseMensagem.data, ...messages]);

            setIdUltimaMensagem(responseMensagem.data.id);

            //notify("Você recebeu uma mensagem!", false, usuario.id, "chat");

            setMessage('');
            setPathImg('');
            setPathFile('');
            setFileType('');
            setFileName('');
            setHasImg(false);
        }
    };

    const finalizarAtendimento = async () => {
        let dados = {
            status: 'finalizado',
            id_atendente: sessionGet('idUser'),
            type: 'finalizar'
        }
        const response = await node.put(`/atendimentos/${idAtendimentoInterno}`, dados).data;

        setFinalizado(true);

        setTimeout(function () {
            window.location = '/atendimentos-ao-cliente';
        }, 2000)
    }

    const reatribuiAtendimento = async () => {
        if (setorTransferencia !== '') {
            let dados = {
                status: 'transferido',
                id_atendente: sessionGet('idUser'),
                id_setor: setorTransferencia,
                type: 'transferencia',
            }

            const response = await node.put(`/atendimentos/${idAtendimentoInterno}`, dados);

            if (response.data.type === 'success') {
                toast.success(`O atendimento foi atribuído!`);
            }

            window.location = '/atendimentos-ao-cliente';
        }
    }

    const getAtendimentos = async () => {
        const response = (await node.get(`/atendimentos-setor/${sessionGet('idSetor')}`)).data;
        setAtendimentos(response);
        setMyAtendimetos(response.filter(item => {
            return item.id_atendente === sessionGet('idUser');
        }));
    }

    const fileInput = () => {
        document.getElementById('fileinput').click();
    }

    const uploadFiles = async files => {

        if (files.length > 0) {
            if (files[0].size < 25000000) {
                var type = files[0].type;
                type = type.split('/');
                type = type[0];

                if (type === 'image') {
                    var text = message.replace(regex, '');
                    text = text.toString();
                    setLegenda(text);

                    const dados = new FormData();

                    dados.append('modulo', 'chat');

                    setFileType('image');

                    if (files != '') {
                        setHasImg(true);
                        setFileName(files[0].name);
                        new Compressor(files[0], {
                            quality: 0.4,
                            convertSize: 0,
                            async success(result) {
                                dados.append('file', result, result.name);
                                const response = (await apiUpload.post('/upload', dados)).data;
                                setPathImg(response.path);
                                document.getElementById('input-img').focus();
                                setIdImg(response.id);
                            }
                        });
                    }
                } else {
                    if (files !== '') {
                        setHasImg(true);
                        const dados = new FormData();
                        dados.append('modulo', 'file-chat');
                        setFileType('file');
                        dados.append('file', files[0], files[0].name);
                        setFileName(files[0].name);
                        const response = (await apiUpload.post('/upload', dados)).data;
                        setIdFile(response.id);
                        setPathFile(response.path);
                    }
                }
            } else {
                Swal.fire({
                    title: 'Erro!',
                    text: 'O arquivo ultrapassa o limite máximo de 25mb.',
                    icon: 'error',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'OK'
                })
            }

        }
    };

    const deleteImage = async (id) => {
        const response = (await apiUpload.delete(`/upload/${id}/${pathImg}`)).data;

        setHasImg(false);
        setIdImg('');
        setPathImg('');
        setFileType('');
        setFileName('');
        ckEditor.editing.view.focus();
    };

    const deleteFile = async (id) => {
        const response = (await apiUpload.delete(`/upload/${id}/${pathFile}`)).data;

        setHasImg(false);
        setIdFile('');
        setPathFile('');
        setFileType('');
        setFileName('');
        ckEditor.editing.view.focus();
    };

    const showPaginationCta = () => (countMsgs - ((page + 1) * 10) > 0);

    return (
        <Wrap local={local}>
            {(local !== "externo") && (!usuario.name) ? (
                <>
                    {(myAtendimentos.length > 0) ?
                        (
                            <Blocked onClick={() => { setDisplay(true) }}>
                                <div className="holder">
                                    <img src={ImgChat} />
                                    <h2>Selecione um atendimento ao lado para iniciar.</h2>
                                </div>
                            </Blocked>
                        )
                        :
                        (
                            <BlockedAlert>
                                <div className="holder">
                                    <AlertaMerx
                                        type='info'
                                        text='Não há atendimentos a serem exibidos.'
                                        style='custom'
                                    />
                                </div>
                            </BlockedAlert>
                        )
                    }
                </>
            ) : ''}
            {(local !== 'externo' || !esperaAtendimento) && (
                <>
                    <InfoHead>
                        <div className="user">
                            <button onClick={() => { setDisplay(true); setInvDisplay(true) }} className="back-button"><RiArrowLeftLine /></button>
                            <ImgAndStatus>
                                {(usuario.nome_arquivo) ? <img src={imagemPerfil} /> : (
                                    <>
                                        <img className="avatar-merx" src={process.env.PUBLIC_URL + '/avatarmerx01.webp'} />
                                    </>
                                )}
                            </ImgAndStatus>
                            <div className="info">
                                {<strong>{(local === "externo") ? setorConversa.name : usuario.name}</strong>}
                                <span>{(local === "externo") ? "" : usuario.setor}</span>
                            </div>
                        </div>
                        <div className="acoes-atendimento">
                            {(setoresSistema !== '') && (
                                <div className="reatribuicao-atendimento">
                                    <div className="select-usuarios">
                                        <select className="select-setor" onChange={e => setSetorTransferencia(Number(e.target.value))}>
                                            <option disabled selected value> Selecione o setor</option>
                                            {setoresSistema.map(setor => (
                                                <option value={setor.id}>{setor.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <button onClick={() => reatribuiAtendimento()} disabled={finalizado}>Transferir</button>
                                </div>
                            )}
                            <button onClick={() => finalizarAtendimento()} title="Finalizar atendimento" className="options-conversa" disabled={finalizado}>
                                Finalizar
                            </button>
                        </div>
                    </InfoHead>
                    <Dropzone
                        accept="image/*"
                        onDrop={files => { uploadFiles(files) }}
                        noClick={true}>
                        {({ getRootProps, getInputProps, isDragActive, isDragReject, open }) => (
                            <div className="form-msg"{...getRootProps()}>
                                {(!isDragActive) ? <> <ScrollToBottom id="lista-msg" className={`lista-mensagem ${draggin}`}>
                                    <div id="scroller" className="scroller">
                                        {(messages.map(v => (
                                            <>
                                                <MensagemChat
                                                    msgs={messages}
                                                    id_message={v.id}
                                                    message={v.message}
                                                    id_from={v.id_from}
                                                    id_to={usuario.id}
                                                    data={v.createdAt}
                                                    path={v.path_img}
                                                    setFullImg={setFullImg}
                                                    setFullImgPath={setFullImgPath}
                                                    setImgExterna={setImgExterna}
                                                    atendimento={true}
                                                    nameFile={v.name_file}
                                                    typeFile={v.type_file}
                                                />
                                            </>
                                        )))}
                                        {showPaginationCta() && (
                                            <WrapCtaPagination>
                                                <button onClick={() => getMessages(page + 1)}>
                                                    {loadPagination && (<RiRefreshLine />)}
                                                    {!loadPagination && 'Carregar mais mensagens'}
                                                </button>
                                            </WrapCtaPagination>
                                        )}

                                    </div>
                                </ScrollToBottom>

                                    <Form onSubmit={handleSubmit} className={draggin}>

                                        {/* <input ref={inputRef} placeholder="Escreva uma mensagem..." value={message} onChange={e => setMessage(e.target.value)} required /> */}
                                        {(!finalizado) ?
                                            <>
                                                <div className="input-group">
                                                    <div className="text-input">
                                                        <CKEditor
                                                            className="cke-editor"
                                                            editor={Editor}
                                                            onReady={editor => {
                                                                if (editor) {
                                                                    editor.plugins.get('SpecialCharacters').addItems('Emoji', emojis);
                                                                    editor.model.change(writer => {
                                                                        writer.setSelection(writer.createPositionAt(editor.model.document.getRoot(), 'end'));
                                                                    });
                                                                    editor.editing.view.focus();
                                                                    SetCkEditor(editor);
                                                                }
                                                            }}
                                                            config={{
                                                                placeholder: 'Escreva uma mensagem...',
                                                                toolbar: ['SpecialCharacters'],
                                                                removePlugins: ['TextTransformation'],
                                                                mediaEmbed: {
                                                                    previewsInData: true,
                                                                    removeProviders: ['instagram', 'twitter', 'googleMaps', 'flickr', 'facebook', 'youtube']
                                                                },
                                                                link: {
                                                                    addTargetToExternalLinks: {
                                                                        attributes: {
                                                                            target: "_blank",
                                                                            rel: "noopener noreferrer",
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                            data={message}
                                                            onChange={(event, editor) => {
                                                                setMessage(editor.getData());
                                                                editor.editing.view.document.on('keydown', (evt, data) => {
                                                                    if (data.keyCode == 13 && !data.shiftKey) {
                                                                        document.getElementById('submit-button').click();
                                                                        data.stopPropagation();
                                                                        data.preventDefault();
                                                                        evt.stop();
                                                                    }
                                                                }, { priority: 'highest' });
                                                            }} />
                                                    </div>
                                                    <div className="btns-form">
                                                        <button type="button" onClick={() => fileInput()}><RiImageLine /><input id="fileinput" type="file" {...getInputProps()} multiple /></button>
                                                        <button onClick={() => { document.getElementById('input-any-file').click(); }}><RiAttachmentLine /></button>
                                                        <input id="input-any-file" type="file" accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf" onChange={e => uploadFiles(e.target.files)} />
                                                        <button id="submit-button" type="submit"><MdSend /></button>
                                                    </div>
                                                </div>
                                            </> :
                                            <div className="atendimento-finalizado">
                                                <span>Esse atendimento foi finalizado!</span>
                                            </div>
                                        }
                                    </Form>
                                </> : (isDragReject) ?
                                    <DropzoneContainer isDragActive={isDragActive} isDragReject={isDragReject}>
                                        <p className="file-reject">Arquivo não suportado</p>
                                    </DropzoneContainer> :
                                    <DropzoneContainer isDragActive={isDragActive} isDragReject={isDragReject}>
                                        <p>Solte os arquivos aqui</p>
                                    </DropzoneContainer>
                                }
                            </div>
                        )}
                    </Dropzone>
                    {(fullImg) &&
                        <div className="full-screen-img">
                            <div className="fullscreen-holder">
                                <button className="btn-delete-img" type="button" onClick={() => setFullImg(false)}><RiCloseLine /></button>
                                <img src={(imgExterna === '') ? getFiles(fullImgPath) : imgExterna}></img>
                            </div>
                        </div>
                    }
                    {(pathImg != '') &&
                        <>
                            <form onSubmit={handleSubmit} className="send-image-form">
                                < div className="image-preview">
                                    <div className="content-image-preview">
                                        <img className="img-preview" src={getFiles(pathImg)} />
                                        <button className="btn-delete-img" type="button" onClick={() => deleteImage(idImg, pathImg)}><RiCloseLine /></button>
                                        <button onClick={() => setTypeMsg('img')} className="btn-send-img" type="submit"><MdSend /></button>
                                        <input id="input-img" className="legenda-img" ref={inputRef} placeholder="Adicionar uma legenda..." value={legenda} onChange={e => { setLegenda(e.target.value); setMessage(e.target.value); }} />
                                        <hr className="line-legenda" />
                                    </div>
                                </div>
                            </form>
                        </>
                    }
                    {(pathFile != '') &&
                        <>
                            <form onSubmit={handleSubmit} className="send-image-form">
                                < div className="image-preview">
                                    <div className="content-image-preview">
                                        <button className="btn-delete-img" type="button" onClick={() => deleteFile(idFile, pathFile)}><RiCloseLine /></button>
                                        <button onClick={() => setTypeMsg('img')} className="btn-send-img" type="submit"><MdSend /></button>
                                        <div className="file-preview">
                                            <RiFile2Fill />
                                            <span>{fileName}</span>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </>
                    }
                </>
            )}
        </Wrap>
    );
}

const mapDispatchToProps = dispatch => bindActionCreators(userConversaActions, dispatch);

export default connect(state => ({
    usuario: state.userConversa
}), mapDispatchToProps)(ConversaExterno);

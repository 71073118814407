import React, { useEffect, useState } from 'react';

import { logout, sessionGet } from '../../session';

import { Link } from 'react-router-dom';

import { Backdrop } from '../../styles/content';
import { Wrap, Button } from './styles';

import ModalStatus from '../ModalStatus'

import { RiUserSettingsLine, RiSettings3Line, RiLogoutBoxLine, RiUserLine, RiUserStarLine,RiBookmarkLine } from 'react-icons/ri';
import apiGeral from '../../services/api-geral';

export default function Options({ setShowOpcoes }) {

  const [showModalStatus, setShowModalStatus] = useState(false);
  const [planFree, setPlanFee] = useState(false);

  useEffect(() => {

      if( sessionGet('planoEmpresa') === 'merxGratis'){
        setPlanFee(true)
      } else{
        setPlanFee(false)
      }
  }, []);


 

  async function deslogar() {
    await apiGeral.put('/users-islogged', { isLogged: false, scope: 'close', sessionLogin: false })
  }

  return (
    <>
      {(showModalStatus) ?
        <>
          <Backdrop className='alter-background' onClick={() => setShowOpcoes(false)} />
          {(window.location.pathname !== '/pagina-empresarial') ?
            <ModalStatus setShowModalStatus={setShowModalStatus} />
            :
            <ModalStatus setShowModalStatus={setShowModalStatus} custom={true} />
          }
        </>
        :
        <Backdrop onClick={() => setShowOpcoes(false)} />
      }
      <Wrap>
        <ul className='customscroll'>
          <li>
            <Button to='/perfil'><RiUserLine /> Ver perfil</Button>
          </li>
          <li>
            <Button to='/meu-perfil'><RiUserSettingsLine /> Editar perfil</Button>
          </li>

          { planFree ?  
          ''
          : <li>
          <Button to='/publicacoes-salvas'><RiBookmarkLine /> Publicações Salvas</Button>
          </li> }
         

          <li>
            <Button onClick={() => { setShowModalStatus(true) }}><RiUserStarLine /> Alterar status</Button>
          </li>
          {/* <li>
            <Button onClick={() => { window.location = '/configuracao-empresa'; }}><RiSettings3Line /> Configurações</Button>
          </li> */}
          <li>
            <Button onClick={() => { logout(); deslogar(); }}><RiLogoutBoxLine /> Sair</Button>
          </li>
        </ul>
      </Wrap>
    </>
  );
}

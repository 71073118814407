import React, { useEffect } from 'react';

import api from '../../services/api-geral';
import { sessionGet, isLogged } from '../../session';
//IMPORTA O SCRIPT QUE INICIA UMA THREAD SECUNDÁRIA PRA MANTER O USUÁRIO ONLINE MESMO COM A ABA INATIVA
// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from 'worker-loader!./worker';

function WebWorker() {

  useEffect(() => {

    const worker = new Worker();
    worker.postMessage('workerStart');

    worker.addEventListener('message', () => {
      if (isLogged() && sessionGet('status') != 2) {
        setStatusOn();
      }
    })
  }, [])


  const setStatusOn = async () => {
    const response = (await api.put('/users', { id: sessionGet('idUser'), presence_status: 1 })).data;
  }


  return <></>;
}

export default WebWorker;
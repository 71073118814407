import React, { useState, useEffect } from 'react';

import api from '../../services/api-geral';
import { sessionGet } from '../../session';
import { formatDate } from '../../util/formats';

import { Container, Head, Filters, Body } from './styles';
import TitleAndBreadcrumb from '../../components/TitleAndBreadcrumb';
import HistoricoModal from '../HistoricoModal';
import CustomSelect from '../MaterialUI/CustomSelect';

import { MdRefresh, MdSearch, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { RiStarSFill } from 'react-icons/ri';

function AtendimentoHistorico() {
  const [atendimentoModal, setAtendimentoModal] = useState();
  const [atendimentos, setAtendimentos] = useState([]);
  const [allAtendimentos, setAllAtendimentos] = useState([]);
  const [page, setPage] = useState(0);
  const [countAt, setCountAt] = useState(0);
  const [atualPage, setAtualPage] = useState(1);
  const [inPage, setInPage] = useState(0);
  const [maxPage, setMaxPage] = useState();
  const [option, setOption] = useState(10);
  const [search, setSearch] = useState('');

  useEffect(() => {
    getAtendimentos(0, 'first');
    getAllAtendimentos();
  }, [])

  useEffect(() => {
    getAtendimentos(0, 'reset');
  }, [option])

  const getAtendimentos = async (page = 0, method = 'left') => {
    const response = (await api.get(`/atendimentos-paginados/${page}`, { params: { status: 'finalizado', userId: sessionGet('idUser'), option: option, admin: sessionGet('admin') } })).data;
    setAtendimentos(response.atendimentos);
    if (method === 'right' || method === 'first') {
      setInPage(inPage + response.atendimentos.length);
    } else if (method === 'reset') {
      setPage(0);
      setAtualPage(1);
      setInPage(response.atendimentos.length);
    }
    setCountAt(response.count);
    getMaxPage(response.count)
  }

  const getAllAtendimentos = async () => {
    const response = (await api.get(`/atendimentos-all`, { params: { status: 'finalizado', userId: sessionGet('idUser'), admin: sessionGet('admin') } })).data;
    setAllAtendimentos(response.atendimentos);
  }

  const getMaxPage = (count) => {
    let pages = count / option;
    if (pages <= 1) {
      setMaxPage(0);
    } else {
      pages = Math.ceil(pages);
      pages = pages - 1;
      setMaxPage(pages);
    }
  }

  const changePage = async (method) => {
    if (method === 'right') {
      const newPage = page + 1;
      setAtualPage(atualPage + option)
      setPage(newPage);
      getAtendimentos(newPage, 'right');
    } else {
      const newPage = page - 1;
      setAtualPage(atualPage - option);
      setInPage(inPage - atendimentos.length);
      setPage(newPage);
      getAtendimentos(newPage);
    }
  }

  const getStars = (nota) => {
    var html = [];
    let i = 0;
    for (i; i < nota; i++) {
      html.push(<RiStarSFill />)
    }
    if (nota > 0) {
      return html;
    } else {
      return 'Não avaliado'
    }
  }

  return (
    <Container>
      <Head>
        <TitleAndBreadcrumb title={'Histórico de Atendimento'} breadcrumbs={[]} format={'config-list'} />
        <hr className="separador"></hr>
        <Filters>
          <div className="filtering">
            <div className="search">
              <MdSearch />
              <input onChange={e => { setSearch(e.target.value) }} placeholder="Cliente"></input>
            </div>
          </div>
          <div className="pagination">
            <select className="select" value={option} onChange={e => setOption(Number(e.target.value))}>
              <option value={10}>Exibir: 10</option>
              <option value={25}>Exibir: 25</option>
              <option value={50}>Exibir: 50</option>
              <option value={100}>Exibir: 100</option>
            </select>
            <label>{atualPage} - {inPage} de {countAt}</label>
            <button onClick={() => { changePage('left') }} disabled={page === 0 ? true : false}><MdKeyboardArrowLeft /></button>
            <button onClick={() => { changePage('right') }} disabled={page === maxPage ? true : false}><MdKeyboardArrowRight /></button>
          </div>
        </Filters>
      </Head>
      <Body>
        <table>
          <tr>
            <th className="id-table">ID</th>
            <th>Solicitado</th>
            <th>Finalizado</th>
            <th>Cliente</th>
            <th>Atendente</th>
            <th>Nota</th>
            <th></th>
          </tr>
          {search === '' ?
            atendimentos.map(atendimento => (
              <tr>
                <td>{atendimento.id}</td>
                <td className="info-table">{formatDate(atendimento.data_inicio)}</td>
                <td className="info-table">{formatDate(atendimento.data_final)}</td>
                <td className="info-table">{atendimento.cliente ? atendimento.cliente.name : '-'}</td>
                <td className="info-table">{atendimento.atendente.name}</td>
                <td className="info-table">{getStars(atendimento.avaliacao)}</td>
                <td className="info-table"><button onClick={() => { setAtendimentoModal(atendimento) }} className="abrir-atendimento">Visualizar</button></td>
              </tr>
            )) :
            allAtendimentos.filter((atendimento) => { return atendimento.cliente && atendimento.cliente.name.toLowerCase().indexOf(search.toLowerCase()) >= 0 }).map((filtrado) => (
              <tr>
                <td>{filtrado.id}</td>
                <td className="info-table">{formatDate(filtrado.data_inicio)}</td>
                <td className="info-table">{formatDate(filtrado.data_final)}</td>
                <td className="info-table">{filtrado.cliente ? filtrado.cliente.name : '-'}</td>
                <td className="info-table">{filtrado.atendente.name}</td>
                <td className="info-table">{getStars(filtrado.avaliacao)}</td>
                <td className="info-table"><button onClick={() => { setAtendimentoModal(filtrado) }} className="abrir-atendimento">Visualizar</button></td>
              </tr>
            ))
          }

        </table>
      </Body>
      {(atendimentoModal) && (
        <HistoricoModal atendimento={atendimentoModal} setAtendimentoModal={setAtendimentoModal} />
      )}
    </Container>
  );
}

export default AtendimentoHistorico;
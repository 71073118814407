import styled from 'styled-components';

export const Paginas = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PaginaButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  margin: 10px 10px;
  cursor: pointer;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  border: 2px solid #922c88;
  transition: background 0.2s linear;
  transition: color 0.2s linear;

  &:hover{
    background: #922c88;
    color: white;
  }

  &:disabled {
    color: #b3b3b3;
    border: 2px solid #b3b3b3;

    :hover {
      background: unset;
      border: 2px solid #b3b3b3;
      color: #b3b3b3;
    }
  }
`;

import React, { useState, useEffect } from 'react';
import Geral from '../../services/api-geral';

import Page from '../../components/Page';
import CTable from '../../components/ComponentTable';
import FormContato from '../../components/Contato/Form';

import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";

import { Painel } from '../../styles/content';

import { Container } from './styles';
import { Paginas, PaginaButton } from '../../components/BotoesPaginacao/styles';

export default function Contato() {
  const [dadosContato, setDadosContato] = useState([]);
  const [allDados, setAllDados] = useState([]);
  const [offsetContatos, setOffsetContatos] = useState(0);
  const [totalContatos, setTotalContatos] = useState(0);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [pagesTotal, setPagesTotal] = useState(1);
  const [valueSearch, setValueSearch] = useState('');

  const [hasChangesModal, setHasChangesModal] = useState(false);

  const getContato = async () => {
    const response = (await Geral.get(`/contato?offset=${offsetContatos}`)).data;

    setDadosContato(response.array);
    setTotalContatos(response.count);

    const pagesCount = calculatePagesCount(10, response.count);
    setPagesTotal(pagesCount);

    //let arrayOrdenado = response.array.sort((a, b) => a.name.localeCompare(b.name));
  };

  useEffect(() => {
    getAllContatos();
  }, [])

  const getAllContatos = async () => {
    const response = await Geral.get(`/contato?offset=${null}&atendimento=${false}`);
    setAllDados(response.data);
  }

  const calculatePagesCount = (pageSize, totalCount) => {
    // we suppose that if we have 0 items we want 1 empty page
    return totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize);
  };

  const setaOffset = (acao) => {
    if (acao === 'proximo') {
      if (Math.floor(totalContatos / 10) !== (offsetContatos / 10)) {
        setOffsetContatos(offsetContatos + 10);
        setPaginaAtual(paginaAtual + 1);
      }
    }

    if (acao === 'anterior') {
      if (offsetContatos > 0) {
        setOffsetContatos(offsetContatos - 10);
        setPaginaAtual(paginaAtual - 1);
      }
    }
  }

  useEffect(() => {
    getContato();
  }, [offsetContatos]);

  const refresh = () => {
    getContato();
    getAllContatos();
  }

  return (
    <Page hasChanges={hasChangesModal} setHasChanges={setHasChangesModal} content={(
      <Container>
        <Painel className="painel-contato">
          <CTable
            ambiente='contato'
            titles={['ID', 'Nome', 'E-mail', 'Telefone', 'Fonte']}
            values={dadosContato}
            allValues={allDados}
            indexes={['id', 'name', 'email', 'phone', 'font']}
            indexesSearch={['name']}
            setValueSearch={setValueSearch}
            load={refresh}
            FormCustom={FormContato}
            actionDelete='/contato'
            titlePage='Lista de contatos'
            breadcrumbPage={[{ title: 'Listas' }]}
            emptyPage='Nenhum registro foi encontrado!'
            hasChangesModal={hasChangesModal}
            setHasChangesModal={setHasChangesModal}
          />

          {valueSearch === '' &&
            <Paginas>
              <PaginaButton disabled={paginaAtual > 1 ? false : true} onClick={() => { setOffsetContatos(0); setPaginaAtual(1) }}>
                <FaAngleDoubleLeft className="icon" size="20px" />
              </PaginaButton>

              <PaginaButton disabled={paginaAtual > 1 ? false : true} onClick={() => setaOffset('anterior')}>
                <FaAngleLeft className="icon" size="20px" />
              </PaginaButton>

              <span>{paginaAtual} de {pagesTotal} {pagesTotal > 1 ? 'páginas' : 'página'}</span>

              <PaginaButton disabled={(paginaAtual < Math.ceil(totalContatos / 10)) ? false : true} onClick={() => setaOffset('proximo')}>
                <FaAngleRight className="icon" size="20px" />
              </PaginaButton>

              <PaginaButton disabled={paginaAtual !== pagesTotal ? false : true} onClick={() => { setOffsetContatos((pagesTotal - 1) * 10); setPaginaAtual(pagesTotal) }}>
                <FaAngleDoubleRight className="icon" size="20px" />
              </PaginaButton>
            </Paginas>
          }

        </Painel>
      </Container>
    )} />
  );
}

import { useState } from 'react';
import addNotification from 'react-push-notification';
import IconeLogo from '../assets/icone_logo.png'

export default (title, body) => {
  Notification.requestPermission();

  addNotification({
    title: title,
    message: body,
    theme: 'darkpurple',
    icon: IconeLogo,
    native: true // when using native, your OS will handle theming.
  });
}
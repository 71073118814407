import React, { useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import 'dotenv/config';
import Routes from './routes';
import GlobalStyle from './styles/global';
import history from './services/history';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import WebWorker from './components/WebWorker';
import Atendimento from './components/Atendimento';

import store from './store';
import apiGeral from './services/api-geral';

function App() {

  const [logged, setLogged] = useState();

  useEffect(() => {
    async function checkIsLogged() {
      await apiGeral.put('/users-islogged', { isLogged: false, scope: 'close', sessionLogin: logged })
    }

    setLogged(localStorage.getItem('connect'));
    window.addEventListener("beforeunload", function (e) {
      checkIsLogged();
    });
  }, [])

  return (
    <>
      <WebWorker />
      <Atendimento />
      <Provider store={store}>
        <Router history={history}>
          <GlobalStyle />
          <Routes />
          <ToastContainer position="top-center" />
        </Router>
      </Provider>
    </>
  );
}

export default App;

import React, { useEffect, useState } from 'react';

import { Container } from './styles';

import { AiFillEdit } from "react-icons/ai";
import { BsTrashFill } from "react-icons/bs";
import { MdNature } from 'react-icons/md';

function Assuntos({ id, index, name, deleteAssunto, edit, selectAll, selectedArray, setSelectedArray,setSetor, setor }) {
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (selected === true) {
      var array = selectedArray;
      setSelectedArray([...selectedArray, id]);
    } else if (selected === false) {
      var array = selectedArray;
      var myIndex = array.indexOf(id);
      if (myIndex !== -1) {
        array.splice(myIndex, 1);
        setSelectedArray(array);
      }
    }
  }, [selected])

  useEffect(() => {
    if (selectAll === true) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [selectAll])

  return (
    <Container isSelected={selected}>
      <div className='info-assunto'>
        {/* <div onClick={() => { setSelected(!selected) }} className={`checkbox ${selected ? 'active' : ""}`}></div> */}
        <span>{index}</span>
        <span>{name}</span>
        <AiFillEdit className="edit" onClick={() => {edit(id, name); setSetor(setor)}} />
        <BsTrashFill className="delete" onClick={() => deleteAssunto(id)} />
      </div>
    </Container>
  );
}

export default Assuntos;
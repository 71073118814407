import React, { useState, useEffect } from 'react';

import Page from '../../components/Page';

import TitleAndBreadcrumb from '../../components/TitleAndBreadcrumb';


import { Container, Head } from './styles';
import PostsSalvos from '../../components/SavePosts';

export default function PublicacoesSalvas() {
  const [image, setImage] = useState('');
  const [imageId, setImageId] = useState('');
  const [imagePath, setImagePath] = useState('');

  const [showMidias, setShowMidias] = useState(false);
  const [hasChangesModal, setHasChangesModal] = useState(false);
    
  return (
    <>
      <Page content={(
      <>
        <Head>
          <TitleAndBreadcrumb id="titulo" title='Publicações Salvas' breadcrumbs={[]} format='book-mark' />
        </Head>
        <Container>

        <PostsSalvos 
          image={image}
          showMidias={(option) => setShowMidias(option)}
          setHasChangesModal={setHasChangesModal}
          setImageId={setImageId}
          setImagePath={setImagePath}
        />
 
        </Container>
      </>
    )} />
    </>
  );
}
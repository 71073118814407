import styled from 'styled-components';

import colors from '../../presets/colors';

export const Container = styled.div`
    color: #777;
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 13px;
    flex-wrap: wrap;

    svg {
        font-size: 16px;
        color: ${colors.primary};
        margin-right: 5px;
    }

    span {
        margin-right: 3px;
    }
`;

import React, { useState, useEffect } from 'react';
import { MdAdd, MdInfoOutline } from 'react-icons/md';
import { FaWindowClose } from 'react-icons/fa';

import log from '../../../util/log';

import Swal from 'sweetalert2';

import ApiGeral from '../../../services/api-geral';

import CInput from '../../ComponentInput';
import CSelect from '../../ComponentSelect';
import CButton from '../../ComponentButton';
import Permissoes from '../../Permissoes';
import CustomSelect from '../../MaterialUI/CustomSelect';
import InfoMensagem from '../../InfoMensagem';
import InfoHelp from '../../InfoHelp';

import { WrapForm, ListaSetor, Setores } from './styles';
import { sessionGet, sessionSet, sessionAdd, sessionRemove } from '../../../session';

export default function Form({ item, success, close, setHasChangesModal }) {
  const [cargos, setCargos] = useState([]);
  const [setores, setSetores] = useState([]);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [cargo, setCargo] = useState('2');
  const [setor, setSetor] = useState('');
  const [password, setPassword] = useState('');
  const [confPassword, setConfPassword] = useState('');
  const [usuarioPermissao, setUsuarioPermissao] = useState([]);
  const [setorUsuario, setSetorUsuario] = useState([]);
  const [novoSetor, setNovoSetor] = useState('');
  const [showAlertMerx, setShowAlertMerx] = useState(false);
  const [senhaAlterada, setSenhaAlterada] = useState(false);

  useEffect(() => {
    setName(item.name);
    setEmail(item.email);
    if (item.birthDate) setBirthDate(item.birthDate.split(/[\s]+/)[0]);
    setCargo(item.admin);
    setSetor(item.id_setor);
    getSetores();
  }, [item]);

  useEffect(() => {
    if (novoSetor !== "") {
      async function salvaNovoSetor() {
        const response = await ApiGeral.post(`/setor-usuario`, {
          id: item.id,
          novoSetor
        });

        if (response.data.type === 'success') {
          getSetorUsuario();
        }
      }

      salvaNovoSetor();
    }
  }, [novoSetor]);

  useEffect(() => {
    if (name !== '' || email !== '' || setor !== '' || password !== '' || confPassword !== '') {
      setHasChangesModal(true);
    } else {
      setHasChangesModal(false);
    }
  }, [name, email, setor, password, confPassword]);

  const getSetores = async () => {
    const response = (await ApiGeral.get('/setor')).data;
    setSetores(response);
  };

  const getSetorUsuario = async () => {
    const response = (await ApiGeral.get(`/setor-usuario/${item.id}`)).data;
    setSetorUsuario(response)
  }

  const salvaPermissao = (index) => {
    let permissaoTrocada = [];
    let idPermissao = '';
    let actionPermissao = '';
    usuarioPermissao.map((item, idx) => {
      let auxItem = item;
      if (index === idx) {
        auxItem.action = (auxItem.action === "1") ? "0" : "1";
        idPermissao = item.id
        actionPermissao = auxItem.action
      }
      permissaoTrocada = [...permissaoTrocada, auxItem];
    });

    const response = ApiGeral.put('/usuario-permissao', {
      idPermissao,
      actionPermissao
    });

    setUsuarioPermissao(permissaoTrocada);
  }

  const toggleSetor = (index) => {
    let setorTrocado = [];
    let idSetor = '';
    let isPrincipalSetor = '';
    setorUsuario.map((item, idx) => {
      let auxItem = item;
      if (item.is_principal === "1") {
        item.is_principal = "0";
      }
      if (index === idx) {
        auxItem.is_principal = (auxItem.is_principal === "1") ? "0" : "1";
        idSetor = item.id
        isPrincipalSetor = auxItem.is_principal
      }
      setorTrocado = [...setorTrocado, auxItem];
    });

    const response = ApiGeral.put(`/setor-usuario/${item.id}`, {
      idSetor,
      isPrincipalSetor
    });

    setSetorUsuario(setorTrocado);
  }

  const removerSetor = async (idSetor) => {
    const response = await ApiGeral.delete(`/setor-usuario/${idSetor}`);

    if (response.data.type === 'success') {
      getSetorUsuario();
    }
  }

  const submitForm = async e => {
    e.preventDefault();

    if (item.id) {
      const response = (await ApiGeral.put('/users', {
        id: item.id,
        name,
        email,
        birthDate,
        cargo,
        setor,
        password,
        confPassword
      })).data;

      if (sessionGet('idUser') === item.id) {
        if (sessionGet('idSetor')) {
          sessionRemove('idSetor');
          sessionAdd('idSetor', Number(setor))
        } else {
          sessionAdd('idSetor', Number(setor))
        }
      }

      if (response.type === "success") {
        Swal.fire({
          title: 'Sucesso!',
          text: response.msg,
          icon: 'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok'
        })
        log(`editou as informações do usuário com nome ${name}.`);
        setSenhaAlterada(true);
        success();
        close();
      } else {
        Swal.fire({
          title: 'Erro!',
          text: response.msg,
          icon: 'error',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok'
        })
        log(`tentou editar as informações do usuário com nome ${name}, mas houve um erro.`);
      }
    } else {

      if (cargo === null) {
        cargo = 2;
      }
      const response = (await ApiGeral.post('/users', {
        name,
        email,
        birthDate,
        cargo,
        setor,
        password,
        confPassword
      })).data;

      if (response.type === "success") {
        Swal.fire({
          title: 'Sucesso!',
          text: response.msg,
          icon: 'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok'
        })
        log(`cadastrou um novo usuário com nome ${name}.`);
        setSenhaAlterada(true);
        success();
        setHasChangesModal(false);
        close();
      } else {
        Swal.fire({
          title: 'Erro!',
          text: response.msg,
          icon: 'error',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok'
        })
        log(`tentou cadastrar um novo usuário mas houve um erro.`);
      }
    }
  };

  return (
    <WrapForm onSubmit={submitForm}>
      <div className="form-header">
        <h2>{`${(item.id) ? 'Edição' : 'Cadastro'} de Usuário`}</h2>
      </div>
      <div className="form-body">
        {(item.id) ? <CInput val={name} change={e => setName(e)} type='text' label='Nome' required={true} /> : <CInput val={name} change={e => setName(e)} type='text' label='Nome' required={true} />}
        <CInput val={email} change={e => setEmail(e)} type='text' label='E-mail' required={true} />
        <CInput val={birthDate} change={e => setBirthDate(e)} type='date' label='Data de Nascimento' required={false} />
        {(sessionGet('admin') === 0 && item.admin !== 0) && (
          <CSelect label='Cargo' val={cargo} change={e => setCargo(e)} items={[
            { id: 1, name: 'Administrador' },
            { id: 2, name: 'Usuário' },
          ]} />
        )}
        {sessionGet('admin') !== 2 && (
          <CSelect label='Setor' val={setor} change={e => setSetor(e)} items={setores} />
        )}
        {(item.id) ? (
          sessionGet('admin') === 0 && (
            <>
              <CInput classCustom='input-senha' val={password} change={e => setPassword(e)} type='password' label='Senha' required={(item.id) ? '' : true} />
              <InfoHelp contentInfo="Utilize no mínimo 6 caracteres com ao menos um número e uma letra maiúscula." />
              <CInput val={confPassword} change={e => setConfPassword(e)} type='password' label='Confirmar Senha' required={(item.id) ? '' : true} />
            </>
          )) : (
          <>
            <CInput classCustom='input-senha' val={password} change={e => setPassword(e)} type='password' label='Senha' required={(item.id) ? '' : true} />
            <InfoHelp contentInfo="Utilize no mínimo 6 caracteres com ao menos um número e uma letra maiúscula." />
            <CInput val={confPassword} change={e => setConfPassword(e)} type='password' label='Confirmar Senha' required={(item.id) ? '' : true} />
          </>
        )
        }
      </div>
      <div className="form-footer">
        <CButton title='Salvar' cstyle='primary small' />
      </div>
    </WrapForm>
  );
}

import styled from 'styled-components';
import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 200px;
  margin: 20px 0px;
  position: relative;

  @media (max-width: 1325px) {
    margin: 10px 0px;
  }

  .color-title {
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    color: ${({ hasImg }) => hasImg ? '#d9d9d9' : '#333333'};

    @media(max-width: 1325px) {
      max-width: 100px;
    }

    @media (max-width: 450px) {
      font-size: 11px;
    }

    @media (max-width: 375px) {
      font-size: 10px;
    }
  }

  .icon {
    width: 40px;
    height: 40px;
    position: absolute;
    display: flex;
    place-content: center;
    align-items: center;
    cursor: ${({ hasImg }) => hasImg ? 'unset' : 'pointer'};

    svg {
      color: white;
      font-size: 20px;
      filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.7));

      @media(max-width: 375px) {
        font-size: 15px;
      }
    }
  }

  .info-modal {
    position: absolute;
    top: 43px;
    left: 12px;
  }

  .color-label {
    font-size: ${({ type }) => type === 'border' ? '13px' : '14px'};
    margin-left: ${({ type }) => type === 'border' ? '10px' : '5px'};
    width: 100%;
    font-weight: 500;
    text-align: center;
    line-height: 16px;

    @media(max-width: 1300px) {
      font-size: 12px;
    }
  }
  
  input {
      border: 0;
      width: calc(100% - 120px);
      margin-right: 10px;
      height: 100%;
      border-radius: 10px;
      padding: 0 10px;
      text-align: end;

    &:disabled {
        background-color: #fafafa;
    }
  }
  
  .wrap-colorpicker {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 15px;
    position: relative;

    button {
      width: 40px;
      height: 40px;
      box-shadow: ${effects.boxshadow};
      background-color: ${({ color, hasImg }) => hasImg ? '#d9d9d9' : color.hex};
      border-radius: 50%;
      cursor: ${({ hasImg }) => hasImg ? 'unset' : 'pointer'};
      border: 0;

      @media(max-width: 375px) {
        width: 30px;
        height: 30px;
      }
    }

    .close-input-color {
      width: 25px;
      height: 25px;
      color: ${colors.btn_text_ok};
      background-color: ${colors.secondary};
      border-radius: 50%;
      position: absolute;
      top: -14px;
      right: -14px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        font-size: 15px;
        color: ${colors.btn_text_ok};
        margin: 0;
        top: 0;
      }
    }
    
    .colorpicker {
      background-color: white;  
      position: absolute;
      z-index: 1052;
      top: ${({ inside }) => inside === true ? '' : 'calc(100% + 10px)'};
      top: ${({ down }) => down === true && '-320px'};
      bottom: ${({ inside }) => inside === true ? 'calc(100% + 10px)' : ''};
      left: 0;
    }

    .colorpicker-right {
      background-color: white;  
      position: absolute;
      z-index: 1052;
      top: ${({ inside }) => inside === true ? '' : 'calc(100% + 10px)'};
      top: ${({ down }) => down === true && '-320px'};
      bottom: ${({ inside }) => inside === true ? 'calc(100% + 10px)' : ''};
      left: -180px;
    }
  }
`;

export const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1051;
`;

import React from 'react';

import { Container } from './styles';

function InfoHelp({ classInfo, contentInfo, listaInfo }) {
  return (
    <Container className={classInfo}>
      {contentInfo}
      { (listaInfo != '' && listaInfo != undefined) && 
        listaInfo.map(item => (
          <ul>
            <li>{item}</li>
          </ul>
        ))
      }
    </Container>
  );
}

export default InfoHelp;
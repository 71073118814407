import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

import apiUpload from '../../services/api-upload';
// import api from '../../services/api-geral';

import { Container } from './styles';

export function ModalHasChanges({ modalConfirmation, setModalConfirmation, setHasChanges, pathRedirect, imageId, imagePath }) {
  useEffect(() => {
    if (modalConfirmation) {
      Swal.fire({
        title: 'Atenção',
        text: 'Você possui alterações não publicadas, continuar mesmo assim?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Descartar',
        cancelButtonText: 'Não'
      }).then(async (result) => {
        if (result.isConfirmed) {

          if (imagePath) {
            const response = (await apiUpload.delete(`/upload/${imageId}/${imagePath}`)).data;
          }

          setHasChanges(false);
          setModalConfirmation(false);

          if (pathRedirect) {
            window.location = pathRedirect;
          }

        } else {
          setHasChanges(true);
          setModalConfirmation(false);
        }
      });
    }
  }, [modalConfirmation]);

  return (
    <Container />
  );
}
import styled from 'styled-components';

import colors from '../../presets/colors';

export const Wrap = styled.div`
	width: 100vw;
	height: 100vh;
	position: fixed;
	left: 0;
    top: 0;
	background-color: rgba(0,0,0,0.3);
	z-index: 1000;
	display: flex;
	align-items: center;
	place-content: center;
`

export const Container = styled.div`
    width: 400px;
	height: auto;
    padding: 40px;
    position: relative;
	background-color: white;

    .btn-close {
        background-color: rgba(0, 0, 0, 0.05);
        width: 30px;
        height: 30px;
        border: 0;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 10px;
        right: 10px;
    }

    form {
        margin-top: 25px;

        .input-search {
            width: 100%;
            height: 40px;
            position: relative;

            input {
                width: 100%;
                height: 100%;
                border: 1px solid #ccc;
                padding: 0 10px;
            }

            & > ul {
                position: absolute;
                top: 45px;
				height: 200px;
				overflow-y: auto;
                left: 0;
                width: 100%;
                background-color: #fff;
                padding: 10px;
                list-style: none;
                border: 1px solid #ccc;
                
                & > li {
                    border-bottom: 1px solid #ccc;
                    padding: 10px 0;
                    cursor: pointer;

                    &:last-child {
                        border: 0;
                    }
                }

            }
        }

        & > button {
            width: 100%;
            height: 40px;
            color: white;
            background-color: ${colors.primary};
            border-radius: 10px;
            border: 0;
            margin-top: 25px;
        }
    }
`;

import styled from "styled-components";

import colors from "../../presets/colors";
import effects from "../../presets/effects";

import { lighten } from "polished";

export const Container = styled.div`
  width: calc(100% - 110px);
  height: calc(100% - 90px);
  position: fixed;
  top: 90px;
  left: 110px;
  z-index: 1050;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1440px) {
    width: calc(100% - 110px);
    height: calc(100% - 70px);
    top: 70px;
    left: 100px;
  }

  @media (max-width: 1024px) {
    width: 100%;
    left: 0;
  }
`;

export const Content = styled.div`
  width: 250px;
  background-color: white;
  border: 1px solid ${colors.border};
  box-shadow: ${effects.boxshadow};
  border-radius: 5px;
  z-index: 2000;
`;

export const Head = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  border-bottom: 1px solid ${colors.border};

  @media (max-width: 450px) {
    height: 50px;
  }

  h1 {
    font-size: 20px;
    letter-spacing: 2px;
    color: ${colors.text_primary};

    @media (max-width: 450px) {
      font-size: 16px;
    }
  }

  .btn-close {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: ${colors.bgsecondary};
    border: 0;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 450px) {
      height: 30px;
      width: 30px;
    }

    svg {
      font-size: 20px;
    }
  }
`;

export const Body = styled.div`
  height: 300px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${colors.border};
    border-radius: ${effects.radius};
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .user-LV {
    align-items: center;
    display: flex;
    padding: 10px;

    span {
      font-size: 13px;
    }

    img {
      height: 35px;
      width: 35px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
`;

export const AreaToClose = styled.div`
  width: calc(100% - 110px);
  height: calc(100% - 90px);
  position: fixed;
  top: 90px;
  left: 110px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1440px) {
    width: calc(100% - 110px);
    height: calc(100% - 70px);
    top: 70px;
    left: 100px;
  }

  @media (max-width: 1024px) {
    width: 100%;
    left: 0;
  }
`;

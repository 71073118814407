import styled from 'styled-components';

import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Wrap = styled.section`
  width: ${({ fullTopBar }) => fullTopBar === true ? '100%' : '100%'};
  height: 90px;
  background-color: ${colors.primary};
  box-shadow: ${effects.boxshadow};
  position: fixed;
  //position: ${({ fullTopBar }) => fullTopBar === true ? 'unset' : 'absolute'};
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1090;

  .btnmenu {
    width: 125px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    background-color: transparent;
    border: 0;
    color: ${colors.textinprimary};
    cursor: pointer;

    @media (max-width: 320px) {
        width: 60px
    }
  }

  .left {
    width: 33%;
  }

  .middle {
    width: 33%;
    display: flex;
    justify-content: center;
    place-content: center;

    @media (max-width: 1024px) {
      justify-content: flex-start;
    }

    .logo {
      margin-left: 25px;

      h1 {
        font-size: 30px;
        color: ${colors.textinprimary};
        font-weight: bold;

        a {
          cursor: pointer;
          display: flex;
          font-weight: bold;
          letter-spacing: 2px;

          img { 
            width: 200px;

            @media (max-width: 1440px) {
              width: 150px;
            }

            @media (max-width: 575px) {
              width: 125px;
            }
          }
        }

        span { color: ${colors.primary} }

        @media (max-width: 375px) {
          font-size: 26px;
        }
      }

      @media (max-width: 1024px) {
        margin-left: 45px;
      }
    }
  }

  .overwatch {
    position: absolute !important;
    left: 0;
    top: 22px;
  }

  .right {
    display: flex;
    justify-content: flex-end;
    width: 33%;

    .container-clipBoard{
    color: ${colors.textinprimary};
      background-color: transparent;
      border: 0;
      font-size: 20px;
      margin-right: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;

      @media (max-width: 768px) {
        display: none;
      }
  }

    .notification-birth-today {
      top: 25px !important;
      left: 15px !important;
      position: absolute !important;
      width: 10px !important;
      height: 10px !important;
      background-color: #fc3503 !important;
      border-radius: 50% !important;
      border: 1px solid #fc3503 !important;
    }

    .btntopbar {
      color: ${colors.textinprimary};
      background-color: transparent;
      border: 0;
      font-size: 20px;
      margin-right: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;

      span {
        font-size: 11px;
        background-color: #8bc34a;
        color: #fff;
        border: 1px solid #8bc34a;
        border-radius: 10px;
        position: absolute;
        width: 20px;
        height: 20px;
        text-align: center;
        box-shadow: ${effects.boxshadow};
        font-weight: bold;
        top: 2px;
        left: 20px;
        line-height: 19px;
      }

      @media (max-width: 768px) {
        display: none;
      }
    }

    .btnuser {
      display: flex;
      align-items: center;
      background-color: transparent;
      border: 0;
      font-size: 15px;
      color: ${colors.textinprimary};
      margin-right: 30px;
      cursor: pointer;

      @media (max-width: 320px) {
        margin-right: 10px;
      }

      @media (max-width: 1024px) {
        width: 100%;
        left: 0;
        top: 0;

        span {
          display: none;
        }
      }

      img {
        width: 40px;
        height: 40px; 
        margin-left: 10px;
        border-radius: 50%;  
        object-fit: cover;
      }
    }

    .presence {
        span {
        width: 8px;
        height: 8px;
        display: block;
        position: absolute;
        top: 40px;
        right: 28px;
        border-radius: 50%;
        border: 3px solid white;
        box-sizing: content-box;

        @media (min-width: 1440px) {
          top: 50px;
        }

        &.offline { background-color: gray; }
        &.online { background-color: green; }
        &.ausente { background-color: #FFBF00; }
        &.naoPerturbe { background-color: #FF0000; }
      }
    }
  }

  .sm-right {
    display: none;
    margin-right: 20px;

    .btnuser {
      display: flex;
      align-items: center;
      background-color: transparent;
      border: 0;
      font-size: 15px;
      color: ${colors.textinprimary};
      cursor: pointer;

      @media (max-width: 320px) {
        margin-right: 10px;
      }

      @media (max-width: 1024px) {
        width: 100%;
        left: 0;
        top: 0;

        span {
          display: none;
        }
      }

      img { width: 35px; height: 35px; border-radius: 50%; }
    }

    button {
      position: relative;
      display: flex;
      align-items: center;
      background-color: transparent;
      border: 0;
      font-size:  23px;
      margin-left: 15px;
      color: white; 

      .notification-birth-today {
      top: 25px !important;
      left: 15px !important;
      position: absolute !important;
      width: 10px !important;
      height: 10px !important;
      background-color: #fc3503 !important;
      border-radius: 50% !important;
      border: 1px solid #fc3503 !important;
    }

      span {
        font-size: 11px;
        background-color: #8bc34a;
        color: #fff;
        border: 1px solid #8bc34a;
        border-radius: 10px;
        position: absolute;
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        place-content: center;
        text-align: center;
        box-shadow: ${effects.boxshadow};
        font-weight: bold;
        top: 8px;
        left: 12px;
        line-height: 19px;
      }
    }
  }

  @media (max-width: 1440px) {
    width: ${({ fullTopBar }) => fullTopBar === true ? '100%' : '100%'};
    height: 70px;

    @media (max-width: 1024px) {
      width: 100%;
      margin-left: 0;
      justify-content: space-between;

      .left { display: none; justify-content: flex-start; }
      .middle { width: 50%; justify-content: flex-start; }
      .right { display: none; }
      .sm-right { display: flex; }
    }
  }
`;
import styled from 'styled-components';
import colors from '../../presets/colors';

export const WrapNotificacao = styled.div`
  background-color: ${colors.primary};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding: 10px;

  img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    object-position: top;
    border-radius: 50%;
  }

  svg {
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    padding: 10px;
  }

  .description {
    margin-left: 10px;

    strong { color: white; }
    span { color: white; }
  }
`;

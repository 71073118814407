import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import log from '../../util/log';

import { sessionGet } from '../../session';
import socket from '../../services/socket';
import getFiles from '../../util/getFiles';
import Default from '../../assets/default.png';
import NotificationOnOff from '../../components/NotificationOnOff';

function SocketConnectClient() {
  useEffect(() => {
    socket.on('userConnectClient', async userSocket => {
      if (userSocket.id !== sessionGet('idUser')) {
        //log('entrou no Merx', true, userSocket.name);
        toast.info((
          <NotificationOnOff idFile={userSocket.id_file} imageProfile={userSocket.id_file ? getFiles(userSocket.files.path) : Default} nameUser={userSocket.name} contentStatus="acabou de entrar." />
        ), { position: 'bottom-left', autoClose: 4000 });
      }
    });
  }, []);

  return <></>;
}

export default SocketConnectClient;
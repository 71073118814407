import styled from 'styled-components';

import { Link } from 'react-router-dom';

import colors from '../../presets/colors';
import effects from '../../presets/effects';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`
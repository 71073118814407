import node from '../services/api-geral';
import Compressor from 'compressorjs';

const uploadFiles = async (files, rota) => {
  var form = new FormData();

  var success = true;

  files.map(async file => {
    if (file.type.includes('image')) {
      if (file.size < 2000000) {
        new Compressor(file, {
          quality: 0.6,
          convertSize: 0,
          async success(result) {
            form.append('files', result, result.name);
            const response = await node.post(`/files/${rota}`, form, {
              'Content-Type': 'multipart/form-data'
            });
          }
        });
      } else {
        success = false;
      }
    } else {
      if (file.size < 2000000) {

        form.append('files', file);
        const response = await node.post(`/files/${rota}`, form, {
          'Content-Type': 'multipart/form-data'
        });

      } else {
        success = false;
      }
    }

  });

  return success;
};

export default uploadFiles;
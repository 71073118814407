import styled from 'styled-components';

export const Body = styled.body`
    width: 100%;
    height: 100%;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction: column;

    .texto{
        letter-spacing:1px;

        .um{
            margin-bottom: 15px;
        }
    }

    .input-avaliacao{
        padding: 20px;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
        

        p{
            width: 60%;
            text-align:center;
            padding: 20px;
            letter-spacing: 3px;
            font-weight: bold;
        }
        
        span{
            margin: 10px;
        }

        textarea{
            resize: none;
        }

        button{
            width: 40%;
            height: 40px;
            color: white;
            font-weight: bold;
            font-size: 12px;
            text-transform: uppercase;
            background-color: #2C76FF;
            border-radius: 10px;
            border: 0;
            margin-bottom: 20px;
            -webkit-letter-spacing: 1px;
            -moz-letter-spacing: 1px;
            -ms-letter-spacing: 1px;
            letter-spacing: 1px;
            margin-right: 5%;
        }
    }
    
`;
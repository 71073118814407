import React, { useEffect, useState } from 'react';
import { RiInformationLine } from 'react-icons/ri';
import { MdFileUpload, MdOutlineTitle, MdClose } from "react-icons/md";
import { TiTimes } from 'react-icons/ti';
import { BsChatDotsFill } from "react-icons/bs";
import { AiOutlineContacts, AiOutlineLink, AiFillLike, AiFillPicture, AiFillQuestionCircle } from "react-icons/ai";
import { IoIosColorPalette } from "react-icons/io";
import Swal from 'sweetalert2';
import Compressor from 'compressorjs';

import api from '../../../services/api-geral';
import apiUpload from '../../../services/api-upload';

import Alerta from '../../../util/Alerta';
import getFiles from '../../../util/getFiles';

import PreviewMobile from '../../PreviewMobile';
import InputColor from '../../InputColor';
import InputColorCircle from '../../InputColorCircle';
import ModalInformation from '../ModalInformation';
import ModalCropImage from '../../ModalCropImage';

import { Container } from './styles';

function Cores({ setSwitchTab, switchTab, setTabs, refresh, doRefresh, tab, data, reload, published, setSaved }) {
  const [showModalCrop, setShowModalCrop] = useState(false);

  const [objectColorPrimary, setObjectColorPrimary] = useState({});
  const [colorPrimary, setColorPrimary] = useState('ffffff');

  const [objectColorSecondary, setObjectColorSecondary] = useState({});
  const [colorSecondary, setColorSecondary] = useState('ffffff');

  const [objectColorTertiary, setObjectColorTertiary] = useState({});
  const [colorTertiary, setColorTertiary] = useState('ffffff');

  const [objectColorQuaternary, setObjectColorQuaternary] = useState({});
  const [colorQuaternary, setColorQuaternary] = useState('ffffff');

  const [objectColorForm, setObjectColorForm] = useState({});
  const [colorForm, setColorForm] = useState('ffffff');

  const [objectColorBtnfont, setObjectColorBtnfont] = useState({});
  const [colorBtnfont, setColorBtnfont] = useState('ffffff');

  const [objectColorNetworks, setObjectColorNetworks] = useState({});
  const [colorNetworks, setColorNetworks] = useState('ffffff');

  const [objectColorDescription, setObjectColorDescription] = useState({});
  const [colorDescription, setColorDescription] = useState('ffffff');

  const [objectColorBackground, setObjectColorBackground] = useState({});
  const [colorBackground, setColorBackground] = useState('ffffff');

  const [objectColorFontForm, setObjectColorFontForm] = useState({});
  const [colorFontForm, setColorFontForm] = useState('ffffff');

  const [objectColorbgform, setObjectColorbgform] = useState({});
  const [colorbgform, setColorbgform] = useState('ffffff');

  const [objectColorbtnform, setObjectColorbtnform] = useState({});
  const [colorbtnform, setColorbtnform] = useState('ffffff');

  const [flagBackgroundFilter, setFlagBackgroundFilter] = useState();

  const [idCompanyBackgroundImage, setIdCompanyBackgroundImage] = useState('');
  const [companyBackgroundImage, setCompanyBackgroundImage] = useState('');

  const [modulo, setModulo] = useState('');

  const [modalInformation, setModalInformation] = useState('');

  const [modalInfo, setModalInfo] = useState(0);

  const [idPage, setIdPage] = useState('');

  const [showHelpColor, setShowHelpColor] = useState(false);

  const [opened, setOpened] = useState(0);

  useEffect(() => {
    if (refresh !== 0) {
      handleSubmitPreview();
    }
  }, [refresh]);

  var variance = 0;

  const verifyChangeColor = () => {
    if (data.colorBackground !== colorBackground) {
      return variance = 1;
    }
    if (data.colorBtnfont !== colorBtnfont) {
      return variance = 1;
    }
    if (data.colorDescription !== colorDescription) {
      return variance = 1;
    }
    if (data.colorForm !== colorForm) {
      return variance = 1;
    }
    if (data.colorNetworks !== colorNetworks) {
      return variance = 1;
    }
    if (data.colorPrimary !== colorPrimary) {
      return variance = 1;
    }
    if (data.colorQuaternary !== colorQuaternary) {
      return variance = 1;
    }
    if (data.colorSecondary !== colorSecondary) {
      return variance = 1;
    }
    if (data.colorTertiary !== colorTertiary) {
      return variance = 1;
    }
    if (data.colorbgform !== colorbgform) {
      return variance = 1;
    }
    if (data.colorFontForm !== colorFontForm) {
      return variance = 1;
    }
    if (data.colorbtnform !== colorbtnform) {
      return variance = 1;
    }
  }

  useEffect(() => {
    if (switchTab !== 'cores') {
      verifyChangeColor();
      if (variance === 0) {
        setTabs(switchTab);
      } else {
        Swal.fire({
          title: 'Atenção',
          text: 'Você possui alterações não publicadas, continuar mesmo assim?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Descartar',
          cancelButtonText: 'Não'
        }).then(async (result) => {
          if (result.isConfirmed) {
            setTabs(switchTab);
          } else {
            setSwitchTab('cores');
          }
        });
      }
    }
  }, [switchTab]);

  useEffect(() => {
    //load();
    loadInfos();
  }, []);

  useEffect(() => {

    if (data != null) {
      setIdPage(data.id);
      setObjectColorPrimary({ hex: data.colorPrimary });
      setObjectColorSecondary({ hex: data.colorSecondary });
      setObjectColorTertiary({ hex: data.colorTertiary });
      setObjectColorQuaternary({ hex: data.colorQuaternary });
      setObjectColorForm({ hex: data.colorForm })
      setObjectColorBtnfont({ hex: data.colorBtnfont });
      setObjectColorNetworks({ hex: data.colorNetworks })
      setObjectColorDescription({ hex: data.colorDescription })
      setObjectColorBackground({ hex: data.colorBackground });
      setObjectColorbgform({ hex: data.colorbgform });
      setObjectColorFontForm({ hex: data.colorFontForm });
      setObjectColorbtnform({ hex: data.colorbtnform });

      if (data.id_image_background) {
        setIdCompanyBackgroundImage(data.id_image_background)
        setCompanyBackgroundImage(data.image_background.path);
      }
    }
  }, [data])

  useEffect(() => {
    setColorPrimary(objectColorPrimary.hex);
  }, [objectColorPrimary]);

  useEffect(() => {
    setColorSecondary(objectColorSecondary.hex);
  }, [objectColorSecondary])

  useEffect(() => {
    setColorTertiary(objectColorTertiary.hex);
  }, [objectColorTertiary])

  useEffect(() => {
    setColorQuaternary(objectColorQuaternary.hex);
  }, [objectColorQuaternary])

  useEffect(() => {
    setColorForm(objectColorForm.hex);
  }, [objectColorForm])

  useEffect(() => {
    setColorBtnfont(objectColorBtnfont.hex);
  }, [objectColorBtnfont])

  useEffect(() => {
    setColorNetworks(objectColorNetworks.hex);
  }, [objectColorNetworks])

  useEffect(() => {
    setColorDescription(objectColorDescription.hex);
  }, [objectColorDescription])

  useEffect(() => {
    setColorBackground(objectColorBackground.hex);
  }, [objectColorBackground])

  useEffect(() => {
    setColorbgform(objectColorbgform.hex);
  }, [objectColorbgform])

  useEffect(() => {
    setColorbtnform(objectColorbtnform.hex);
  }, [objectColorbtnform])

  useEffect(() => {
    setColorFontForm(objectColorFontForm.hex);
  }, [objectColorFontForm])

  const handleUpdateFlags = async (flag, type) => {
    if (type === 'bgFilter') {
      await api.put('/page?scope=flags', {
        flagBackgroundFilter: flag
      });
      setFlagBackgroundFilter(flag)
    }
    Swal.fire({
      icon: 'success',
      title: 'Alterações salvas',
      showConfirmButton: false,
      timer: 1500
    })
  }

  const loadInfos = async () => {
    const response = (await api.get('/page?scope=flags')).data;

    if (response !== null) {
      setFlagBackgroundFilter(response.flagBackgroundFilter);
    }
  };

  const handleSubmitPreview = async e => {
    //e.preventDefault();

    const response = (await api.post('/page', {
      colorPrimary,
      colorSecondary,
      colorTertiary,
      colorQuaternary,
      colorForm,
      colorbgform,
      colorFontForm,
      colorbtnform,
      colorBtnfont,
      colorNetworks,
      colorDescription,
      colorBackground,
      id_image_background: idCompanyBackgroundImage
    })).data;

    if (response.success) {
      setSaved(true);
      Alerta.success("Alterações publicadas!");

      variance = 0;

      reload();
    } else {
      Alerta.error("Ocorreu um erro!", "Tente novamente mais tarde!");
    }

    doRefresh(0);
  }


  // const uploadAppereanceImage = async (files) => {
  //   if (data) {
  //     const dados = new FormData();

  //     dados.append('modulo', modulo);

  //     let img = new Image()
  //     img.src = window.URL.createObjectURL(files)
  //     img.onload = () => {
  //       if (img.width <= 1920 && img.height <= 1080) {
  //         if (files) {
  //           new Compressor(files, {
  //             quality: 0.4,

  //             async success(result) {
  //               dados.append('file', result, result.name);

  //               const response = (await apiUpload.post('/upload', dados)).data;

  //               if (modulo === 'company_background') {
  //                 if (idCompanyBackgroundImage) {
  //                   await removeBackgroundImage();
  //                 }

  //                 setIdCompanyBackgroundImage(response.id);
  //                 setCompanyBackgroundImage(response.path);
  //               }
  //             }
  //           });
  //         }
  //       } else {
  //         Alerta.error("Ocorreu um erro! O tamanho máximo permitido para uma imagem é de 1920 x 1080 pixels.");
  //       }
  //     }

  //   } else {
  //     Alerta.error("Ocorreu um erro!", "Por favor coloque o título da empresa antes");
  //   }

  //   const el = document.getElementById('input-company-background-image');
  //   if (el) el.value = null;
  // };

  const removeImage = (typeImage) => {
    Swal.fire({
      title: 'Tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, apagar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        if (typeImage === 'background') {
          removeBackgroundImage();
          const el = document.getElementById('input-company-background-image');
          if (el) el.value = null;
        }
        Alerta.success('Dados atualizados!', 'Seu arquivo foi excluído.');
      }
    });
  };

  const removeBackgroundImage = async () => {
    const response = (await apiUpload.delete(`/upload/${idCompanyBackgroundImage}/${companyBackgroundImage}`)).data;
    setIdCompanyBackgroundImage('');
    setCompanyBackgroundImage('');
    reload();
  };

  const hasChangedColor = () => {
    verifyChangeColor();

    if (variance === 0) {
      setModulo('company_background');
      setShowModalCrop(true);
    } else {
      Swal.fire({
        title: 'Tem certeza?',
        text: 'Você possui alterações não publicadas, continuar mesmo assim?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, continuar!',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          setModulo('company_background');
          setShowModalCrop(true);
        }
      });
    }
  }

  return (
    <>
      {showModalCrop && <ModalCropImage closeModal={() => setShowModalCrop(false)} modulo={modulo} idImage={idCompanyBackgroundImage} setIdImage={setIdCompanyBackgroundImage} srcImage={companyBackgroundImage} setSrcImage={setCompanyBackgroundImage} idPage={idPage} refresh={reload} />}

      <Container>
        <div className="aparencia-preview">
          <div className="color-and-preferences">
            <div className="colorbox first">
              <div className="box-header">
                <h5 className="second-h5">Configurações de fundo</h5>
                <hr />
                <p className="info-fundo">Personalize a aparência do seu mini site. Nesta seção, é possível escolher uma imagem ou cor de fundo, alterar cores de fontes dos textos, links, botões das redes sociais e do Fale Conosco.</p>
              </div>
              <div className='color-row-radio'>
                <div className="radio-row">
                  <label>Escolha uma imagem de fundo:</label>
                </div>
                <div className="content-painel-holder">
                  <div className="content-painel">
                    <div className="btn-background" onClick={() => hasChangedColor()}>
                      {(companyBackgroundImage === '') ?
                        <MdFileUpload /> :
                        <>
                          <div className={`image-filter ${flagBackgroundFilter ? 'filtered' : ''}`}>
                            <MdFileUpload />
                          </div>
                          <div className="filter-in-image"></div>
                          <img src={getFiles(companyBackgroundImage)} />
                        </>
                      }
                    </div>
                    {(companyBackgroundImage !== '') &&
                      <div className="remove-background" type="button" onClick={() => removeImage('background')}>
                        <TiTimes />
                      </div>
                    }
                  </div>
                </div>
              </div>
              {idCompanyBackgroundImage !== '' ? (
                <div className='aparencia-definicoes'>
                  <ul className="lista-funcionalidades">
                    <li>
                      <div className='info-modal-painel'>
                        <RiInformationLine onMouseOver={() => { setModalInformation('show'); setModalInfo(2) }} onMouseOut={() => { setModalInformation(''); setModalInfo(0) }} />
                        {modalInfo === 2 && (
                          <ModalInformation modalClass={modalInformation} top='-100px' left='40px' content='Utilize um filtro em sua imagem. Escolha uma cor que combine com a aparência da sua página.' />
                        )}
                      </div>
                      <div className="title">
                        Filtro da imagem de fundo
                      </div>
                      <div onClick={() => handleUpdateFlags(!flagBackgroundFilter, 'bgFilter')} className={`toggle-funcionalidade ${flagBackgroundFilter && 'active'}`}>
                        <div className="indicador"></div>
                      </div>
                      <InputColor
                        id={1}
                        opened={opened}
                        setOpened={setOpened}
                        inside={true}
                        text='Filtro'
                        content='Utilize um filtro em sua imagem. Escolha uma cor que combine com a aparência da sua página.'
                        color={objectColorQuaternary} setColor={setObjectColorQuaternary}
                        placeholder="Cor quaternária"
                        right={true} />
                    </li>
                  </ul>
                </div>
              ) : <div className='aparencia-definicoes disabled'>
                <ul className="lista-funcionalidades">
                  <li>
                    <div className='info-modal-painel'>
                      <RiInformationLine onMouseOver={() => { setModalInformation('show'); setModalInfo(3) }} onMouseOut={() => { setModalInformation(''); setModalInfo(0) }} />
                      {modalInfo === 3 && (
                        <ModalInformation modalClass={modalInformation} top='-100px' left='40px' content='É necessário uma imagem para ativar o filtro.' />
                      )}
                    </div>
                    <div className="title">
                      Filtro da imagem de fundo
                    </div>
                  </li>
                </ul>
              </div>}
            </div>
            <div className="colorbox">
              <div className="box-header">
                <h5>Configurações de cores</h5>
                <div className="help-icon">
                  <AiFillQuestionCircle onClick={() => { setShowHelpColor(true) }} />
                  {showHelpColor && (
                    <>
                      <div className="modal-help-color">
                        <div onClick={() => { setShowHelpColor(false) }} className="backdrop"></div>
                        <div className="modal">
                          <button onClick={() => setShowHelpColor(false)} className="close-button"><MdClose /></button>
                          <div className="modal-content">
                            <div className="header">
                              <IoIosColorPalette />
                              <h3>Configure a aparência do mini site</h3>
                            </div>
                            <p className="content">
                              Altere as <strong>cores das fontes</strong> (título, descrição, telefone e links), dos <strong>botões</strong> (chat, links e Fale Conosco) e dos <strong>ícones</strong> das redes sociais.
                            </p>
                            <p className="content">
                              A opção <strong>fundo da página</strong> permite definir uma cor para o seu mini site. Essa funcionalidade ficará desativada, caso tenha inserido uma imagem de fundo.
                            </p>
                            <p className="content">
                              Algumas opções de configuração do formulário de <strong>Fale Conosco</strong> (fonte, fundo e botão de envio) não irão aparecer no preview ao lado. Para ver as alterações, acesse o seu mini site e clique no botão fale conosco.
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <hr />
              </div>
              <div className='color-row'>
                <InputColorCircle
                  id={2}
                  opened={opened}
                  setOpened={setOpened}
                  text='Título e descrição'
                  content='Altere as cores do título e da descrição que são os textos de apresentação da sua página.'
                  color={objectColorDescription} setColor={setObjectColorDescription}
                  placeholder="Cor Descrição"
                  icon={<MdOutlineTitle />} />
                <InputColorCircle
                  id={3}
                  opened={opened}
                  setOpened={setOpened}
                  text='Redes sociais'
                  content='Altere as cores dos ícones das redes sociais localizadas abaixo dos links.'
                  color={objectColorNetworks} setColor={setObjectColorNetworks}
                  placeholder="Cor Redes"
                  right={true}
                  icon={<AiFillLike />} />
              </div>
              <div className='color-row'>
                <InputColorCircle
                  id={4}
                  opened={opened}
                  setOpened={setOpened}
                  text='Fonte dos links'
                  content='Altere a cor do texto dos links que estão localizados dentro das caixas retangulares em sua página.'
                  color={objectColorSecondary} setColor={setObjectColorSecondary}
                  placeholder="Cor secundária"
                  icon={<AiOutlineLink />} />
                <InputColorCircle
                  id={5}
                  opened={opened}
                  setOpened={setOpened}
                  text='Fundo dos links'
                  content='Altere a cor de fundo das caixas retangulares onde ficam os links.'
                  color={objectColorTertiary} setColor={setObjectColorTertiary}
                  placeholder="Cor terciária"
                  right={true}
                  icon={<AiOutlineLink />} />
              </div>
              <div className='color-row'>
                <InputColorCircle
                  id={6}
                  opened={opened}
                  setOpened={setOpened}
                  text='Botão Chat'
                  content='Altere a cor do botão do chat localizado no canto inferior direito da tela.'
                  color={objectColorPrimary} setColor={setObjectColorPrimary}
                  placeholder="Cor primária"
                  icon={<BsChatDotsFill />} />
                <InputColorCircle
                  id={7}
                  opened={opened}
                  setOpened={setOpened}
                  text='Fonte dos botões'
                  content='Altere a cor do texto dos links que estão localizados dentro das caixas retangulares em sua página.'
                  color={objectColorBtnfont} setColor={setObjectColorBtnfont}
                  placeholder="Fonte Botões"
                  right={true}
                  icon={<BsChatDotsFill />} />
              </div>
              <div className='color-row'>
                <InputColorCircle
                  id={8}
                  opened={opened}
                  setOpened={setOpened}
                  text='Botão Fale Conosco'
                  content='Altere a cor do botão do Fale Conosco. Note que a fonte deste botão será sempre na cor branca.'
                  color={objectColorForm} setColor={setObjectColorForm}
                  placeholder="Cor formulário"
                  icon={<AiOutlineContacts />} />
                <InputColorCircle
                  id={9}
                  opened={opened}
                  setOpened={setOpened}
                  text='Fonte Fale Conosco'
                  content='Altere as cores dos ícones das redes sociais localizadas abaixo dos links.'
                  color={objectColorFontForm} setColor={setObjectColorFontForm}
                  placeholder="Cor Fonte Formulário"
                  right={true}
                  icon={<AiOutlineContacts />} />
              </div>
              <div className='color-row'>
                <InputColorCircle
                  id={10}
                  opened={opened}
                  setOpened={setOpened}
                  text='Fundo do Fale Conosco'
                  content='Altere a cor do texto dos links que estão localizados dentro das caixas retangulares em sua página.'
                  color={objectColorbgform} setColor={setObjectColorbgform}
                  placeholder="Fundo formulário"
                  down={true}
                  icon={<AiOutlineContacts />} />
                <InputColorCircle
                  id={11}
                  opened={opened}
                  setOpened={setOpened}
                  text='Botão de envio Fale Conosco'
                  content='Esta opção permite inserir uma cor para o fundo da sua página página empresarial, caso não queira uma imagem.'
                  color={objectColorbtnform} setColor={setObjectColorbtnform}
                  placeholder="Botão formulário"
                  right={true}
                  down={true}
                  icon={<AiOutlineContacts />} />
              </div>
              <div className='color-row'>
                <InputColorCircle
                  id={12}
                  opened={opened}
                  setOpened={setOpened}
                  text='Fundo da página'
                  content='Esta opção permite inserir uma cor para o fundo da sua página página empresarial, caso não queira uma imagem.'
                  color={objectColorBackground} setColor={setObjectColorBackground}
                  placeholder="Cor Fundo"
                  icon={<AiFillPicture />}
                  down={true}
                  hasImg={idCompanyBackgroundImage} />
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-preview">
          <PreviewMobile
            tab={tab}
            Cor1={colorPrimary}
            Cor2={colorSecondary}
            Cor3={colorTertiary}
            Cor4={colorQuaternary}
            Cor6={colorBackground}
            Cor7={colorForm}
            Cor8={colorNetworks}
            Cor9={colorDescription}
            Cor10={colorBtnfont}
            Cor11={colorFontForm}
            Cor12={colorbgform}
            Cor13={colorbtnform}
            filter={flagBackgroundFilter}
            background={companyBackgroundImage}
            idPage={idPage}
            hasImg={idCompanyBackgroundImage}
          />
        </div>
      </Container>
    </>
  );
}

export default Cores;
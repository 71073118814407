import React, { useEffect } from 'react';
import socket from '../../services/socket';

function SocketLikesPost({ getLikes, idPost, idComment }) {
    useEffect(() => {

        socket.on('like_post_feed', post => {
            if (idPost === post) {
                getLikes('socket');
            }
        });

        socket.on('like_comment_feed', comment => {
            if (idComment === comment) {
                getLikes('socket');
            }
        })

        return () => {
            socket.off('like_post_feed');
            socket.off('like_comment_feed');
        }
    }, []);

    return <></>;
}

export default SocketLikesPost;
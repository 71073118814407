import styled from 'styled-components';

import colors from '../../../presets/colors';
import effects from '../../../presets/effects';

export const Wrap = styled.div`
  width: 400px;
  min-height: 20%;
  max-height: 530px;
  position: fixed;
  z-index: 1081;
  border: 1px solid ${colors.border};
  top: 100px;
  right: 150px;
  background-color: ${colors.bgprimary};
  border-radius: 15px;
  box-shadow: ${effects.boxshadow};
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow-y: scroll;
  
  ::-webkit-scrollbar {
      display: none;
      width: 4px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
      background: ${colors.border};
      border-radius: ${effects.radius};
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

  @media(max-width: 500px) {
    width: 300px;
    top: 80px;
    right: 10px;
  }

  &.full {
    justify-content: flex-start;
  }

  &.empty{
    width: 350px;

    min-height: 10%;

    .empty-container{
      display: flex;
      justify-content:flex-start;
      text-align: center;
      margin-top: 1rem;
      height: 3rem;
      padding:  0.5rem;
      width: 100%;
      height: auto;
      border-radius: 10px;
      margin-bottom: 15px;


      .icon-wrap{
        background-color: rgba(0, 0, 0, 0.03);
        color: ${colors.primary};
        border-radius: 50%;
        height: 35px;
        width: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
          svg{
    
            font-size: 20px;
            margin-right: 1px;
            margin-bottom: 2px;
          }

      }

      .empty-content{
        display: flex;
        align-items: center;
          p{
            margin-left: 10px;
            font-size: 13px
          }
        }
    }
  }



  .header-notifications {
    display: flex;
    justify-content: space-between;
    margin: 10px 18px;

    @media(max-width: 500px) {
      margin: 10px 10px;
    }

    h3 {
      font-size: 18px;
      //text-align: center;
      @media(max-width: 500px) {
        font-size: 14px;
      }
    }
    
    button {
      font-size: 13px;
      background-color: transparent;
      border: 0;
      color: #666;
      text-decoration: underline;
      text-underline-position: under;

      @media(max-width: 500px) {
        font-size: 11px;
      }

      &:hover {
        color: ${colors.primary};
      }
    }
  }

`;
export const Notes = styled.div`
 display: flex;
 flex-direction: column;
 padding: 0.5rem;
 
 .container{
    padding:  0.5rem;
    width: 100%;
    height: auto;
    background-color: rgba(0,0,0,0.03);
    border-radius: 10px;
    box-shadow: ${effects.boxshadow};
    margin-bottom: 15px;
    position: relative;
    display: flex;
    align-items: center;
  }
  .lido{
    padding:  0.5rem;
    width: 100%;
    height: auto;
    background-color: #f4f4f6;
    border-radius: 10px;
    box-shadow: ${effects.boxshadow};
    margin-bottom: 15px;
    position: relative;
    display: flex;
    align-items: center;
  }

  .icon{
    width: 10%;
    background-color: ${colors.primary};
    width: 36px;
    height: 30px;
    color: white;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 0.5rem;
    margin-left: 0;
      
    img{
        height: 21px;
        width: 16px;
    }
    svg{
        color: white;
    }
  }

  .content{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    overflow: hidden; 
  }

  .buttons {
    display: flex;
    background: white;

    .btn-edit {
      width: 30px;
      height: 30px;
      background-color: #f4f4f6;
      border: 0;
      color: ${colors.primary};
      display: flex;
      align-items: center;
      place-content: center;

      svg {
        font-size: 18px;
      }
    }

    .btn-delete {
      width: 30px;
      height: 30px;
       background-color: #f4f4f6;
      border: 0;
      color: ${colors.primary};
      display: flex;
      align-items: center;
      place-content: center;

      svg {
        font-size: 25px;
      }
    }
  }

.title{
  text-transform: uppercase;
  margin-right: 5px;
  color: ${colors.primary};
  font-size: 12px;
}
      
.author-name{
  color: #555;
  font-size: 12px;
  font-weight: 300;
}
        
.note-text{
  font-size: 13px;
  overflow-wrap: break-word; 
}
`;

